import { useCallback, useEffect, useRef } from 'react'
import { FirebaseAuth } from '../firebase/config'
import { useAppDispatch, useAppSelector } from './store'
import { setSidebarTheme } from '../store/sidebar/thunks'
import { setAllDataThemeThunk } from '../store/theme/thunks'
import { getQueryDocByCollection } from '../helpers/globalHelper'
import { login, startLogout } from '../store/auth'
import verifyResponse from '../helpers/verifyResponse'
import getRolesByUserLogged from '../services/getRolesByUserLogged'

const intervalTime = 600000

export const useCheckAuth = () => {
  const auth = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch()
  const currentUser = useRef(null)
  const status = auth.status

  const getConfigurationRoles = async ({ idToken, committee, profileId, user }) => {
    const response = await getRolesByUserLogged(idToken)

    if (response.success) {
      // Se obtienes los roles si la response fue exitosa
      const roles = response.data.data
      
      const currentRol = auth.currentRol || localStorage.getItem('currentRol')
      //Validar si existe el currentRol en algun item del arreglo de roles
      const rolData = roles?.find(rol => rol.companyId === parseInt(currentRol)) ?? roles?.[0]

      const { uid, email, displayName, photoURL } = user
      //Al iniciar sesión o recargar la página hacer un dispatch del thunk para reactualizar los colores e imágenes
      await dispatch(setAllDataThemeThunk({ idTemplateRoute: rolData?.companyId }))
      //Hacer el dispatch para el sidebar, debe de guardarse el id en algun lugar para reutilizarlo, podría ser el local storage
      await dispatch(setSidebarTheme({}))

      // Función que retorna una promesa que se resuelve después de 1 segundo
      const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

      // Se valida setear el residential id con el ultimo company_id deslogueado
      const newCommittee = {
        ...committee,
        residential_id: rolData?.residentialId,
      }

      // Seteamos el current residential id en el local storage solamente cuando se inicia sesion
      localStorage.setItem('currentResidential', rolData?.residentialId)

      // Esperar 1 segundo antes de hacer el dispatch de login
      await delay(1000);

      dispatch(login({ uid, email, displayName, photoURL, committee: newCommittee, roles, profileId, idToken, status: 'authenticated', rolData }))
      
      return rolData
    }

    verifyResponse(response.data, response.status, '')
    dispatch(startLogout())
    await FirebaseAuth.signOut()
  }

  const getAuthData = useCallback(async (user, updatingToken) => {
    if (auth.status === 'authenticated' && !updatingToken) return

    const committeeData = await getQueryDocByCollection('/committee', "user_id", "==", user.uid)

    // Verify committee
    if (committeeData.length < 1) return dispatch(startLogout('checking-login'))

    // idToken del usuario loggeado
    const idToken = await user.getIdToken(true)

    const profile = await getQueryDocByCollection('/profile', "user_id", "==", user.uid)
    const profileId = profile?.length > 0 ? profile[0].id : ""

    // Si el usuario no tiene un profile_id asignado inicia el proceso de deslogueo
    if (!profileId) return dispatch(startLogout('checking-login'))

    const response = await getConfigurationRoles({
      user: user,
      idToken: idToken,
      profileId: profileId,
      committee: {...committeeData[0]},
    })

    return response
  }, [auth.status])


  useEffect(() => {

    if (auth.status === 'not-authenticated' || auth.status === 'checking-login') return
    //Colocar el listener de firebase una vez para iniciar sesión y luego guardar el user en un ref para usarlo en el setInterval (refrescar el token)
    const unsuscribe = FirebaseAuth.onAuthStateChanged(user => {
      if (!user) {
        return dispatch(startLogout('checking-login'))
      }
      currentUser.current = user
      getAuthData(user, false)
    })
    //El setInterval se debe limpiar cada vez que se termina el tiempo para evitar acumulaciones en la función
    const intervalId = setInterval(async () => {
      if (auth.status !== 'authenticated') return
      getAuthData(currentUser.current, true)
    }, intervalTime)

    return () => {
      unsuscribe()
      clearInterval(intervalId)
    }
  }, [auth, getAuthData])

  return status
}