import axios from "axios"
import { API_URL } from "../../../../config"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const getPlaceOwnerExcelReport = async (companyId, idToken) => {
  try {
    const response = await axios.get(`${API_URL}place/owner/export?company_id=${companyId}`,
      { headers: { Authorization: `Bearer ${idToken}` }, responseType: 'blob' },
    )

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Reporte-usuarios.xlsx");
    document.body.appendChild(link);
    link.click();

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getPlaceOwnerExcelReport