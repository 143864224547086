const EditIcon = ({ color = '#FFFFFF', width = '19', height = '19' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.8333 9.03522C13.4416 9.03522 13.125 9.37193 13.125 9.78665V15.7984C13.125 16.2125 12.8076 16.5499 12.4167 16.5499H2.5C2.10896 16.5499 1.79171 16.2125 1.79171 15.7984V5.27784C1.79171 4.86381 2.10896 4.52642 2.5 4.52642H8.16671C8.5584 4.52642 8.875 4.18972 8.875 3.775C8.875 3.36014 8.5584 3.02344 8.16671 3.02344H2.5C1.32842 3.02344 0.375 4.03492 0.375 5.27784V15.7984C0.375 17.0414 1.32842 18.0528 2.5 18.0528H12.4167C13.5883 18.0528 14.5417 17.0414 14.5417 15.7984V9.78665C14.5417 9.3711 14.225 9.03522 13.8333 9.03522Z" fill={color} />
      <path d="M7.01679 8.3499C6.96724 8.40246 6.93391 8.46934 6.91977 8.54144L6.419 11.1987C6.39566 11.3219 6.43249 11.4489 6.51602 11.5383C6.58333 11.6097 6.67399 11.648 6.76686 11.648C6.78943 11.648 6.8129 11.6458 6.83625 11.6406L9.34023 11.1093C9.40962 11.0942 9.47265 11.0589 9.52155 11.0062L15.1259 5.06062L12.6219 2.4043L7.01679 8.3499Z" fill={color} />
      <path d="M16.8573 0.56711C16.1668 -0.165599 15.0433 -0.165599 14.3533 0.56711L13.373 1.60707L15.877 4.26354L16.8573 3.22344C17.1917 2.86953 17.3758 2.39757 17.3758 1.89562C17.3758 1.39366 17.1917 0.921699 16.8573 0.56711Z" fill={color} />
    </svg>

  )
}

export default EditIcon