import moment from "moment";
import { Button, Divider, Row } from "antd";
import { useState, useEffect, useContext } from "react";
import { useAppSelector } from "../../../../hooks/store";
import useOpenCloseModal from "../../../hooks/useOpenCloseModal";
import InspectionsModal from "../Components/modal/InspectionsModal";
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm";
import { CountDownContext } from "../../../../context/CountDownContext";
import { TICKETS_CODES } from "../../../../constants/modules/tickets/ticketsCodes";

// imagenes o archivos
import { ClockCircleOutlined } from "@ant-design/icons";
import EditIcon from "../../../../assets/icons/EditIcon";

const BarOnHold = () => {
  const [isDateExceeded, setIsDateExceeded] = useState(false)

  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { handleIsOpenCloseModal } = useContext(CountDownContext)
  const { activeFeatures } = useAppSelector(state => state.roles)

  const handleModalOpen = () => {
    handleOpenCloseModal();
    handleIsOpenCloseModal();
  }

  const handleModalClose = () => {
    handleIsOpenCloseModal();
    handleOpenCloseModal();
  }

  const { ticketById, flags } = useAppSelector((state) => state.detailTicketById)
  const TICKET_WORK_SESSIONs = ticketById?.TICKET_WORK_SESSIONs || [];

  const doneWorkSessions = TICKET_WORK_SESSIONs?.filter(
    (session) => !session.done
  ).sort((a, b) => {
    const dateA = new Date(a?.EMPLOYEE_ACTIVITY_SCHEDULE?.date?.split("T")[0]);
    const dateB = new Date(b?.EMPLOYEE_ACTIVITY_SCHEDULE?.date?.split("T")[0]);

    return dateA - dateB;
  });

  const shouldDisplayBar =
    flags?.enabledAlertOnWaitingForConfirmation ||
    flags?.enabledAlertOnWaitingForInspection ||
    flags?.enabledAlertOnWaitingForWorkSession;

  let message = "";
  let date = "";

  const inspectionDateStr =
    ticketById?.TICKET_INSPECTION?.created_at?.split("T")[0] &&
    ticketById?.TICKET_INSPECTION?.created_at?.split("T")[0];
    
  const inspectionDate = new Date(inspectionDateStr);
  const currentDate = new Date();

  useEffect(() => {
    if (currentDate > inspectionDate) {
      setIsDateExceeded(true);
      if (!flags?.enabledAlertOnWaitingForInspection) setIsDateExceeded(false);
    } else {
      setIsDateExceeded(false);
    }
  }, [isDateExceeded]);

  switch (true) {
    case flags?.enabledAlertOnWaitingForConfirmation:
      const dateUtcDisponibility = ticketById?.TICKET_EMPLOYEE?.created_at;
      const dateDisponibility = new Date(dateUtcDisponibility);
      message = "CONFIRMACIÓN DE DISPONIBILIDAD";
      date =
        "Fecha en la que se asignó el ticket: " +
        moment(dateDisponibility).format('DD/MM/YYYY [-] h:mm A');
      break;
    case flags?.enabledAlertOnWaitingForInspection:
      const dateUtcInspection =
        ticketById?.TICKET_INSPECTION_SCHEDULE?.EMPLOYEE_ACTIVITY_SCHEDULE
          ?.date;
      const dateInspection = new Date(dateUtcInspection);
      let timeBeginInspection =
        ticketById?.TICKET_INSPECTION_SCHEDULE?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeBegin
          .split(":")
          .map((time) => parseInt(time));
      timeBeginInspection[0] = timeBeginInspection[0] + 6;
      dateInspection.setUTCHours(...timeBeginInspection);
      message = "INSPECCIÓN";
      date = "Fecha de la inspección " + moment(dateInspection).format('DD/MM/YYYY [-] h:mm A');

      break;
    case flags?.enabledAlertOnWaitingForWorkSession:
      const workSessionsDate =
        moment(doneWorkSessions[0]?.EMPLOYEE_ACTIVITY_SCHEDULE?.date).format('DD/MM/YYYY');
      let timeBeginWorkSesion =
        moment(doneWorkSessions[0]?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeBegin, 'HH:mm:ss').format('hh:mm A')
      message = "JORNADA";
      date =
        "Fecha de inicio de jornada: " +
        workSessionsDate?.toLocaleString() +
        " - " +
        timeBeginWorkSesion;
      break;
    default:
      message = "";
      break;
  }
  if (!shouldDisplayBar) return null;

  return (
    <Row
      justify="center"
      align="middle"
      style={{
        marginTop: "1rem",
        border: "2px solid transparent",
        paddingTop: "16px",
        paddingBottom: "16px",
        marginInline: "2rem",
        borderRadius: "10px",
        background: isDateExceeded ? "#FF9F9F" : "#FFF2CF",
        gap: 16
      }}
    >
      <ClockCircleOutlined
        style={{
          fontSize: "1.3rem",
        }}
      />

      <div style={{ fontSize: "16px", fontWeight: 500 }}>
        {isDateExceeded ? "INSPECCION SIN REALIZAR" : `EN ESPERA DE ${message}`}

        <Divider
          style={{
            background: "#000",
            width: "1px",
            height: "1.5rem",
          }}
          type="vertical"
        />
        <span style={{ marginLeft: "0.5rem" }}>{date}</span>
      </div>
      {(isDateExceeded || flags?.enabledAlertOnWaitingForInspection) && (
        <div className="hide-element" style={{ display: 'flex', justifyContent: 'center' }}>
          <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_UPDATE_INSPECTION_SCHEDULE)?.enabled ? 'No tienes permiso para realizar esta acción' : "Editar inspección"}`}>
            <Button
              disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_UPDATE_INSPECTION_SCHEDULE)?.enabled}
              type="ghost"
              style={{
                position: "relative",
                border: "black solid 1px",
              }}
              onClick={handleModalOpen}
              shape="round"
            >
              <Row>
                <EditIcon color="#000" width="27px" />
                <p style={{ marginLeft: "0.5rem" }}>Editar</p>
              </Row>
            </Button>
          </CustomPopoverConfirm>
        </div>
      )}

      <InspectionsModal isOpenModal={isOpenModal} onCancel={handleModalClose} />
    </Row>
  );
};

export default BarOnHold;
