import { createContext, useState } from "react"

export const CategoriesTicketContext = createContext()

export const CategoriesTicketProvider = ({ children }) => {
  const [subCategorySelected, setSubCategorySelected] = useState({})

  const cleanSubCategorySelected = () => setSubCategorySelected({})

  return (
    <CategoriesTicketContext.Provider value={{ subCategorySelected, setSubCategorySelected, cleanSubCategorySelected }}>
      {children}
    </CategoriesTicketContext.Provider>
  )
}