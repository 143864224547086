import { CustomCollapse } from "../../../../../../../../UI/CustomCollapse";
import { CustomInput } from "../../../../../../../../UI/CustomInput";
import { CustomSelect } from "../../../../../../../../UI/CustomSelect";
import { CustomCheckbox } from "../../../../../../../../UI/CustomCheckbox";
import { dropdownSelectIcon2 } from "../../../../../../assets/icons";
import { useNewCheckList } from "../../../../../hooks/useNewCheckList";

export const GeneralInfo = ({ Form, config }) => {
  const { categories, handleOpenCloseCollapse, activeKey, getCategoriesService } = useNewCheckList();
  //Company_id?
  return (
    <CustomCollapse
      collapseKey={"generalInfo"}
      activeKey={activeKey.find((item) => item.collapseKey === "generalInfo")?.value}
      onChange={() => handleOpenCloseCollapse({ collapseKey: "generalInfo" })}
      defaultActive={"1"}
      className=""
      headerChildren="Información General"
    >
      <Form.Item
        name={"title"}
        label="TÍTULO DE LA TAREA"
        rules={[{ required: true }]}
      >
        <CustomInput type={"TEXT"} />
      </Form.Item>
      <Form.Item
        name={"description"}
        label="DESCRIPCIÓN"
        rules={[{ required: true }]}
      >
        <CustomInput type={"AREA"} />
      </Form.Item>
      <Form.Item
        name={"category_id"}
        label="CATEGORÍA"
        rules={[{ required: true }]}
      >
        <CustomSelect isAsync={true} itemsCount={25} service={getCategoriesService} suffixIcon={dropdownSelectIcon2} />
      </Form.Item>
      <Form.Item
        className="custom-input-label-hidden"
        name={"allow_attach_files"}
        label="Fotografías"
        initialValue={false}
      >
        <CustomCheckbox
          options={[{ label: "Permitir adjuntar fotografías", value: "1" }]}
        />
      </Form.Item>
    </CustomCollapse>
  );
};