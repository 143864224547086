import { Col, Row } from "antd"
import useTypesNews from './../hooks/useTypesNews'
import { CustomSelect } from './../../../UI/CustomSelect'
import { CustomDatePicker } from './../../../UI/CustomDatePicker'
import { setFilters } from "../../../../store/typesNews/typesNewsSlice"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"

const HeaderTypesNewsFilters = () => {
  const dispatch = useAppDispatch()

  const { getAllTypesNewsByAllFilters } = useTypesNews()
  const { filters, idTypesNews } = useAppSelector(state => state.news)

  // Se mapea para que el custom select funcione y muestre las opciones como son requeridas
  const typesNews = idTypesNews?.map((typeNew) => ({ id: typeNew.news_type_id, name: typeNew.name }))

  // Las opciones del select de estados
  const statusTypeNews = [{ id: "1", name: "Activa" }, { id: "0", name: "Inactiva" }]

  const handleOnChangeTypesIdsNews = async (value) => {
    if (!value) {
      await getAllTypesNewsByAllFilters({ ...filters, newsTypeId: null })

      dispatch(setFilters({ ...filters, newsTypeId: null }))
      return
    }

    await getAllTypesNewsByAllFilters({ ...filters, newsTypeId: value })

    dispatch(setFilters({ ...filters, newsTypeId: value }))
  }

  const handleOnChangeStatusTypesNews = async (value) => {
    if (!value) {
      await getAllTypesNewsByAllFilters({ ...filters, active: '' })

      dispatch(setFilters({ ...filters, active: '' }))
      return
    }

    const isActiveTypesNews = value === '1'

    await getAllTypesNewsByAllFilters({ ...filters, active: isActiveTypesNews })

    dispatch(setFilters({ ...filters, active: isActiveTypesNews }))
  }

  const handleOnChangeRangesDates = async (date, dateStrings) => {
    if (!(dateStrings[0]) && !(dateStrings[1])) {
      await getAllTypesNewsByAllFilters({ ...filters, dateBegin: '', dateEnd: '' })
      dispatch(setFilters({ ...filters, dateBegin: '', dateEnd: '' }))
      return
    }

    await getAllTypesNewsByAllFilters({ ...filters, dateBegin: dateStrings[0], dateEnd: dateStrings[1] })
    dispatch(setFilters({ ...filters, dateBegin: dateStrings[0], dateEnd: dateStrings[1] }))
  }

  return <header style={{ marginTop: 25 }}>
    <Row gutter={16}>
      <Col span={5}>
        <CustomSelect
          allowClear
          placeholder="Selecciona el tipo de noticia"
          options={typesNews}
          onChange={handleOnChangeTypesIdsNews}
        />
      </Col>

      <Col span={5}>
        <CustomSelect
          allowClear
          placeholder="Selecciona el estado"
          options={statusTypeNews}
          onChange={handleOnChangeStatusTypesNews}
        />
      </Col>

      <Col span={5}>
        <CustomDatePicker
          format="YYYY-MM-DD"
          allowClear
          type="RANGER"
          onChange={handleOnChangeRangesDates}
        />
      </Col>
    </Row>
  </header>
}

export default HeaderTypesNewsFilters