import { Col, Row } from "antd"
import { useEffect } from "react";
import RolesModules from "./components/RolesModules";
import DetailRoles from "./components/DetailRoles";
import useDetailRoles from "../../hooks/useDetailRoles"
import './styles.css'
import { Navigate } from "react-router-dom";
import DetailRolesHeader from "./components/DetailRolesHeader";

//Componente que muestra la página de administración para los modulos y sus caracteristicas de un rol en específico
const DetailRolesPage = () => {
    const { userRolesTable, modulesRole, featureModulesRole, filterFeatures, activeModuleRole, columns, loading, getInitialData, handleChangeFeatureStatus, handleOnSaveFeatures, handleChangeModuleRolStatus, handleChangeModuleRole, handleChangeTypePermission, handleSearchFeature } = useDetailRoles();
    useEffect(() => {
        if (!Object.keys(userRolesTable.active).length) return
        getInitialData();
    }, []);

    if (!Object.keys(userRolesTable.active).length) return <Navigate to={'/settings/managment-roles/'} />
    return (
        <section className="section-wrapper">
            <DetailRolesHeader
                handleOnSaveFeatures={handleOnSaveFeatures}
                loading={loading}
                activeModuleRole={activeModuleRole}
                userRolesTable={userRolesTable}
            />
            <main style={{ marginTop: 32 }} >
                <Row gutter={32}>
                    <Col span={6} style={{ paddingBottom: '24px' }}>
                        <RolesModules modulesRole={modulesRole} handleChangeModuleRole={handleChangeModuleRole} />
                    </Col>
                    <Col span={18} style={{ paddingBottom: '24px' }}>
                        <DetailRoles
                            justView={userRolesTable?.active?.justView}
                            handleChangeTypePermission={handleChangeTypePermission}
                            handleSearchFeature={handleSearchFeature}
                            handleChangeFeatureStatus={handleChangeFeatureStatus}
                            handleChangeModuleRolStatus={handleChangeModuleRolStatus}
                            loading={loading} columns={columns}
                            featureModulesRole={featureModulesRole}
                            filterFeatures={filterFeatures}
                            activeModuleRole={activeModuleRole}
                        />
                    </Col>
                </Row>
            </main>
        </section>
    )
}

export default DetailRolesPage
