import { useEffect, useState } from "react"
import { useAppSelector } from "../../../../../../../hooks/store"
import { getAllTicketsCategoriesByCompany } from "../../../../../adminCategories/services"
import verifyResponse from "../../../../../../helpers/verifyResponse"
import getAllEmployees from "../../../services/generals/getAllEmployees"
import { createTicketByCheckList } from "../../../services"

const useGeneral = () => {
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])

  const { currentRol, idToken } = useAppSelector(state => state.auth)

  useEffect(() => {
    if (currentRol) {
      getAllDataCategories()
    }
  } , [])

  // Funcion para obtener todas las categorias y subcategorias que se crean dentro de el flujo de admin de categorias
  const getAllDataCategories = async () => {
    const response = await getAllTicketsCategoriesByCompany(currentRol)

    if (response.success) {
      setCategories(response.data.data)
      return
    }

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  // Funcion que devuelve todos los empleados y los muestra en el select
  const getEmployees = async ({ find_value }) => {
    const response = await getAllEmployees({ find_value: find_value, company_id: currentRol, token: idToken })
    return response
  }

  // Funcion encargada de la creacion del ticket desde un checklist
  const onCreateTicketByChecklist = async (formData) => {
    const response = await createTicketByCheckList(formData, idToken)

    verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Ticket creado con éxito' })

    return response
  }

  return {
    getEmployees,
    categories,
    subCategories,
    setSubCategories,
    onCreateTicketByChecklist,
  }
}

export default useGeneral
