import { useAllCheckList } from "../../../../../hooks/useAllCheckList";

export const CheckStatistics = ({ }) => {
  //Hacer dinamico los datos de los div con clase statistic-card  
  const { activeCard, summary, loading, handleActiveCard, } = useAllCheckList();

  return (
    <div className="check-summary">
      <div className="check-summary__first-row">
        {
          summary.sort((a, b) => a.code.localeCompare(b.code)).map((card) => {
            if (card.code !== "paused" && card.code !== "deleted") {
              return (
                <button key={card.code} disabled={loading} onClick={() => { handleActiveCard({ code: card.code }) }} className={`statistic-card ${card.code} ${activeCard === card.code ? 'active' : ''}`}>
                  <p>{card.name}</p>
                  <h2>{card.taskCount < 10 ? `0${card.taskCount}` : card.taskCount}</h2>
                </button>
              )
            }
          })
        }
      </div>
      <div className="check-summary__second-row">
        {
          summary.sort((a, b) => b.code.localeCompare(a.code)).map((card) => {
            if (card.code === "paused" || card.code === "deleted") {

              return (
                <button key={card.code} disabled={loading} onClick={() => { handleActiveCard({ code: card.code }) }} className={`statistic-card ${card.code} ${activeCard === card.code ? 'active' : ''}`}>
                  <p>{card.name}</p>
                  <h2>{card.taskCount < 10 ? `0${card.taskCount}` : card.taskCount}</h2>
                </button>
              )
            }
          })
        }
      </div>
    </div>
  );
};

export default CheckStatistics;
