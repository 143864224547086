import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const companyProvidersPath = "provider"

export const getAllCompanyProviders = async ({
    token,
    company_id = 1,
    formData,
    find_by,
    find_value,
    sort_by,
    sort_order,
    limit = 10,
    skip = 0,
    isToSelect = false
}) => {
    try {
        const params = {
            company_id,
            limit,
            skip,
            find_by,
            find_value,
            sort_by,
            sort_order,
            ...formData
        };

        const response = await axiosCreate.get(`${companyProvidersPath}`, {
            params, timeout: 20000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (isToSelect) {
          const { data } = response

        const newResponse = {
            ...response,
            data: {
            ...response.data,
            data: {
                dataSource: data.data.dataSource.map((activesData) => ({ id: activesData?.id, name: activesData?.name })),
            }
            }
        }
        return ({
            success: true,
            ...newResponse
        })
        }

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
};