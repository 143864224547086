import moment from "moment"
import { useContext } from "react"
import { Button, Row, Space } from "antd"
import useRedirectTo from "../../../../hooks/useRedirectTo"
import CustomPopoverConfirm from "../../../../UI/CustomPopoverConfirm"
import { HandoverChecklistContext } from "../context/HandoverChecklistContext"
import { HANDOVER_CHECKLIST_CODES } from "../../../../../constants/modules/handoverChecklist/handoverChecklistCodes"

// Imagenes o archivos
import EditIcon from "../../../../../assets/icons/EditIcon"
import useCurrentLocation from "../../../../hooks/useCurrentLocation"

const ActionsButtons = ({ handleOpenCloseModal, subCategory, form, getAllHierarchyData, activeFeatures }) => {
  const { param } = useCurrentLocation()
  const { handleRedirectTo } = useRedirectTo()
  const { setChecklistSelected } = useContext(HandoverChecklistContext)
  const disableDetailFeature = !activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_DETAILS_HANDOVER)?.enabled

  const getBuildDataToEdit = (subCategory) => {
    //Prepare Data
    const subCategoryDate = moment(subCategory.activityDate.slice(0, 10))
    const subCategoryTimeBegin = moment(subCategory.activityDate.slice(12, 17), "HH:mm")
    const subCategoryTimeEnd = moment(subCategory.activityDate.slice(23, 28), "HH:mm")
    
    const buildObject = {
      employee_id: subCategory.employeeId,
      date: subCategoryDate,
      time_begin: subCategoryTimeBegin,
      time_end: subCategoryTimeEnd,
      place_id: ({ value: subCategory.placeId, label: subCategory.place }),
    }

    return buildObject
  }

  return (
    <Row justify="end">
      <Space direction="horizontal">
        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_UPDATE_HANDOVER)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Editar checklist de entrega'}`}>
          <Button
            disabled={!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_UPDATE_HANDOVER)?.enabled}
            type="link"
            htmlType="button"
            shape="circle"
            onClick={async () => {
              await getAllHierarchyData()
              form.setFieldsValue(getBuildDataToEdit(subCategory))
              handleOpenCloseModal()
              setChecklistSelected(subCategory)
            }}
            style={{ border: '2px solid var(--primary)' }}
          >
            <EditIcon color="var(--primary)" />
          </Button>
        </CustomPopoverConfirm>
        <CustomPopoverConfirm trigger={'hover'} content={`${disableDetailFeature ? 'No tienes permiso para realizar esta acción' : 'Ver detalles'}`}>
          <Button
            disabled={disableDetailFeature}
            type="link"
            htmlType="button"
            shape="round"
            onClick={() => handleRedirectTo(`/checklist-entrega/${param.checklistId}/${subCategory.id}`)}
            style={{ width: 172, border: `2px solid ${disableDetailFeature ? "#D6D6D6" : "var(--primary)"}` }}
          >
            <p style={{ color: `${disableDetailFeature ? "#D6D6D6" : "var(--primary)"}`, margin: 0 }}>
              <span style={{ fontWeight: 600 }}>{`(${subCategory.failedItemReviews}) `}</span>
              Ver detalles
            </p>
          </Button>
        </CustomPopoverConfirm>

      </Space>
    </Row>
  )
}

export default ActionsButtons