import { Button, Row } from 'antd'
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/Generals/calendar-with-star-icon.svg'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { setCurrentBookingId, setCurrentOptionModalBody } from '../../../../store/bookings/bookingsSlice'
import { getDetailReserve } from '../../../../store/bookings/thunks'
import { BOOKINGS_CODES } from '../../../../constants/modules/bookings/bookingsCodes'
import CustomPopoverConfirm from '../../../UI/CustomPopoverConfirm'

const ActionsButtons = ({
  reserveData,
  setLoadingModal,
  handleOpenCloseModal,
}) => {
  const dispatch = useAppDispatch()
  const { activeFeatures } = useAppSelector(state => state.roles)

  const handleOnClickDetailReserve = async () => {
    setLoadingModal(true)

    dispatch(setCurrentOptionModalBody("1"))
    dispatch(setCurrentBookingId(reserveData.reservation_id))
    const response = await dispatch(getDetailReserve(reserveData.reservation_id))

    // Aqui se devuelve un true en la thunk
    if (response) {
      handleOpenCloseModal()
    }

    setLoadingModal(false)
  }

  return (
    <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === BOOKINGS_CODES.DETAIL_BOOKING)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Detalles de reserva'}`}>
      <Button
        type="link"
        shape="circle"
        htmlType="button"
        disabled={!activeFeatures?.find(feature => feature.feature_code === BOOKINGS_CODES.DETAIL_BOOKING)?.enabled}
        onClick={handleOnClickDetailReserve}
      >
        <Row justify='center' align="middle">
          <CalendarIcon className='btn-svg-icon' />
        </Row>
      </Button>
    </CustomPopoverConfirm>
  )
}

export default ActionsButtons