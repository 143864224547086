import { useContext, useState } from "react"
import { Button, Col, Form, Row } from "antd"
import useAmenities from "../hooks/useAmenities"
import CustomModal from "../../../UI/CustomModal"
import { AmenitiesContext } from "../context/AmenitiesContext"
import deleteIcon from '../../../../assets/icons/Generals/close-red-x-icon.svg'

const DeleteAmenityModal = ({
  isOpenModal,
  handleOpenCloseModal,
}) => {
  const [loading, setLoading] = useState(false)
  
  const { onDeleteAmenity, getAllAmenities } = useAmenities()
  const { currentAmenitySelected, cleanCurrentAmenitySelected } = useContext(AmenitiesContext)

  // Funcion que limpia el contexto
  const handleOnCancelDeleteAmenity = () => {
    handleOpenCloseModal()
    cleanCurrentAmenitySelected()
  }

  // Se vuelve a limpiar contexto y cerrar modal si fue exitosa la respuesta del endpoint
  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      handleOnCancelDeleteAmenity()
      await getAllAmenities()
    }
  }
  
  const onSubmit = async () => {
    setLoading(true)
    
    const response = await onDeleteAmenity(currentAmenitySelected.place_id)

    await onFinish(response)

    setLoading(false)
  }

  return <CustomModal
    destroyOnClose
    isOpenModal={isOpenModal}
    onCancel={handleOpenCloseModal}
  >
    <Form
      layout="vertical"
      name="deleteAmenityForm"
      onFinish={onSubmit}
    >
      <Row justify="center">
        <Col span={24}>
          <Row justify="center">
            <img src={deleteIcon} alt="icon-delete-x-red" style={{ marginBottom: 15 }} />
          </Row>

          <h2 style={{ textAlign: 'center', width: 403, margin: 'auto' }}>
            ¿Seguro que deseas eleminar esta amenidad?
          </h2>

          <Row justify="center" gutter={16} style={{ marginTop: 15 }}>
            <Col>
              <Button
                type="text"
                htmlType="button"
                shape="round"
                className="btn-standard-gray"
                onClick={handleOnCancelDeleteAmenity}
                style={{ width: 150, opacity: loading ? 0.5 : 1 }}
              >
                <span>CANCELAR</span>
              </Button>
            </Col>

            <Col>
              <Button
                type="text"
                htmlType="submit"
                shape="round"
                loading={loading}
                className="btn-standard-green"
                style={{ width: 150 }}
              >
                <span>ELIMINAR</span>
              </Button>
            </Col>

          </Row>
        </Col>
      </Row>
    </Form>
  </CustomModal>
}

export default DeleteAmenityModal