import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import useCurrentLocation from "../../../hooks/useCurrentLocation"
import { menuOptions } from "../constants/menuOptions"
import { getFeaturesThunk } from '../../../../store/roles/thunks'
import { useState } from "react"
import { useFeatureRole } from "../../../hooks/useFeatureRole"

const useMenu = () => {
    const dispatch = useAppDispatch();
    const { handleActualSetFeatures, emptyFeatures, activeFeatures, gettingFeatures } = useFeatureRole({})
    const auth = useAppSelector(state => state.auth)
    const [menuItems, setMenuItems] = useState({ title: '', items: [] })
    const [loading, setLoading] = useState(false)
    const { param, location } = useCurrentLocation()

    //Aqui hacer la validacion de los roles para mostrar las opciones correspondientes
    const getMenuItems = async () => {
        setLoading(true)
        //Se filtra con base a que la opción esté activa en las constants, y que el tipo de rol existe en la propiedad "generalRol"
        let actualMenu = menuOptions[param.menuId]
        if (param?.submenuId) {
            actualMenu = menuOptions[param.menuId].items.find(item => item.submenuName === param.submenuId)?.submenu
        }
        //En este punto llamar al thunk para obtener las features 
        const features = await dispatch(getFeaturesThunk({ featureCodes: actualMenu.featureCodes }))
        let items = actualMenu.items.filter(item => {
            const currentRolName = features.find(feature => feature.feature_code === item.rolName)
            if (currentRolName) {
                item.active = currentRolName.enabled
            }
            return item.generalRol.includes(auth?.typeRol) && item.active
        })

        if (param.menuId === 'modules') {
            const groupedModules = items.reduce((acc, item) => {
                if (acc[item.type]) {
                    acc[item.type].push(item)
                    return acc
                }
                acc[item.type] = [item]
                return acc
            }, {})

            items = groupedModules
        }
        setLoading(false)
        return { title: actualMenu.title, items }
    }

    return { param, location, getMenuItems, setMenuItems, menuItems, loading, setLoading, handleActualSetFeatures, emptyFeatures, activeFeatures, gettingFeatures }
}

export default useMenu