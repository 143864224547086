import "./style.css";
import { useState } from "react";
import { Button, Col, Form, Row } from "antd";
import { CustomSpin } from "../../../UI/CustomSpin.jsx";
import { config } from "../../../constants/constants.js";
import { CustomInput } from "../../../UI/CustomInput.jsx";
import deleteCategory from "../services/deleteCategory.js";
import { useAppSelector } from "../../../../hooks/store.js";
import { CustomNavigate } from "../../../UI/CustomNavigate.jsx";
import CustomTable from "../../../UI/CustomTable/CustomTable.jsx";
import { useFeatureRole } from "../../../hooks/useFeatureRole.js";
import useCheckListCategory from "../hooks/useCheckListCategory.js";
import useOpenCloseModal from "../../../hooks/useOpenCloseModal.js";
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm.jsx";
import EditCategoryModal from "./components/modal/EditCategoryModal.jsx";
import { HANDOVER_CHECKLIST_CODES, HANDOVER_CHECKLIST_CODES_NAMES } from "../../../../constants/modules/handoverChecklist/handoverChecklistCodes.js";

// imagenes o archivos
import EditIcon from "../../../../assets/icons/EditIcon.jsx";
import TrashIcon from "../../../../assets/icons/TrashIcon.jsx";
import useCurrentLocation from "../../../hooks/useCurrentLocation.js";

const CheckListCategoryPage = () => {
  const [formCreate] = Form.useForm()
  const [editCategory, setEditCategory] = useState({})

  const hookCheckListCategory = useCheckListCategory()
  const {
    onSaveChecklistCategory, metadata, categoryData, allCategoryCheckList, setLoading, loading, 
    onDeleteChecklistCategory
  } = hookCheckListCategory
  
  const { param } = useCurrentLocation()
  const { form, isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  const { idToken, currentRol } = useAppSelector((state) => state.auth)
  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: HANDOVER_CHECKLIST_CODES, validate_code: HANDOVER_CHECKLIST_CODES_NAMES.HANDOVER_CHECKLIST_MANAGEMENT_CATEGORIES, returnUrl: '/checklist/admin-entrega' })

  const { columns = [], dataSource = [] } = categoryData
  const { quantity = 0, limit = 10 } = metadata

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      formCreate.resetFields()

      await allCategoryCheckList(param.checklistId)
    }
  }
  
  // Funcion para crear una categoria
  const handleCreateCategory = async (formData) => {
    const data = {
      company_id: currentRol,
      checklist_id: param.checklistId,
      name: formData.name,
    }

    const response = await onSaveChecklistCategory(data)

    await onFinish(response)
  }

  // Funcion para eliminar una categoria
  const handleDeleteCategory = async (id) => {
    setLoading(true)

    const data = {
      company_id: currentRol,
      checklist_id: param.checklistId,
      checklist_category_id: id,
    }
    
    if (param.checklistId) {
      await onDeleteChecklistCategory(data)
      await allCategoryCheckList(param.checklistId)
    }
    
    setLoading(false)
  }

  const handleEditCategory = (record) => {
    setEditCategory(record)
    handleOpenCloseModal()
    form.setFieldsValue({ category: record.category })
  }

  // Funcion para manejar la pagination de la tabla
  const handlePagination = async (currentPage, limit) => {
    setLoading(true)
    
    const pageValue = currentPage
    const newSkip = (pageValue * limit) - limit
    
    if (param.checklistId) {
      await allCategoryCheckList(param.checklistId, newSkip)
    }

    setLoading(false)
  }

  const newColumns = [
    ...columns,
    {
      dataIndex: "actions",
      title: " ",
      width: 125,
      render: (_, record) => (
        <div className="actions-category">
          <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_UPDATE_CATEGORY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Editar categoria'}`}>
            <Button
              disabled={!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_UPDATE_CATEGORY)?.enabled}
              onClick={() => handleEditCategory(record)}
              type="text"
              size="small"
              icon={<EditIcon color="var(--primary)" />}
            />
          </CustomPopoverConfirm>
          <CustomPopoverConfirm trigger={'hover'} placement={'left'} content={`${!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_DELETE_CATEGORY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Eliminar categoria'}`}>
            {'' /**Si no se pone este espacio, no detecta el custompopover que esta aqui dentro */}
            <CustomPopoverConfirm
              disabled={!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_DELETE_CATEGORY)?.enabled}
              popConfirm
              onConfirm={() => handleDeleteCategory(record.id)}
              overlayClassName={"delete-category"}
              title={"¿Desea eliminar esta categoría?"}
              okText={"ELIMINAR"}
              cancelText={"CANCELAR"}
              placement={"bottomRight"}
            >
              <Button disabled={!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_DELETE_CATEGORY)?.enabled} type="text" size="small" icon={<TrashIcon color="#EA5E64" />} />
            </CustomPopoverConfirm>
          </CustomPopoverConfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="check-categories section-wrapper">
      <CustomNavigate title={'CATEGORÍAS'} />
      
      <header>
        <h2>CREAR NUEVA CATEGORÍA</h2>
        
        <Form
          form={formCreate}
          layout="horizontal"
          name="checklistCategoryform"
          disabled={loading}
          onFinish={handleCreateCategory}
          className="new-category"
        >
          <Row gutter={16}>
            <Col span={20}>
              <Form.Item name="name" {...config}>
                <CustomInput
                  placeholder="Nombre de la categoría"
                  className="new-category-input"
                />
              </Form.Item>
            </Col>

            <Col span={4}>
              <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_NEW_CATEGORY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nueva categoria'}`}>
                <Button
                  type="text"
                  shape="round"
                  htmlType="submit"
                  loading={loading}
                  className="btn-standard-green"
                  style={{ width: 200 }}
                  disabled={!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_NEW_CATEGORY)?.enabled}
                >
                  NUEVA CATEGORÍA
                </Button>
              </CustomPopoverConfirm>
            </Col>
          </Row>
        </Form>
      </header>

      <CustomTable
        rowKey={record => record.id}
        className="admincheck-table"
        columns={newColumns ?? []}
        dataSource={dataSource ?? []}
        loading={loading}
        pagination={{
          pageSize: limit,
          total: quantity,
          onChange: async (currentPage) => await handlePagination(currentPage, limit),
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} de ${total}`;
          },
        }}
      />
      
      <EditCategoryModal
        form={form}
        isOpenModal={isOpenModal}
        editCategory={editCategory}
        onCancel={handleOpenCloseModal}
        setEditCategory={setEditCategory}
        allCategoryCheckList={allCategoryCheckList}
      />
    </div>
  );
};

export default CheckListCategoryPage;
