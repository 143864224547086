import axios from "axios";

const deletePlaceHierarchyAdmin = async (URL_DEV, idToken,placeId) => {
  try {
    const response = await axios.delete(`${URL_DEV}/place?place_id=${placeId}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        }
      }
    );

    return {
      success: true,
      ...response,
    };
  } catch (error) {
    return {
      success: false,
      ...error.response,
    };
  }
};

export default deletePlaceHierarchyAdmin;
