import { Button } from "antd"
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settingsIconVariant1.svg'
import CustomDropdown from "../../UI/CustomDropdown/CustomDropdown"
import useRedirectTo from "../../hooks/useRedirectTo"
import './styles.css'

const SettingsMenu = ({ items = [{ label: '', route: '', active: true, disabled: false }] }) => {
    const { handleRedirectTo } = useRedirectTo()

    const menuOptions = items.filter(item => item.active).map((item, index) => ({ label: item.label, key: index, disabled: item.disabled, onClick: () => handleRedirectTo(item.route) }))

    return (
        <CustomDropdown menuOptions={menuOptions}>
            <Button className="btn-settings" icon={<SettingsIcon />}>AJUSTES</Button>
        </CustomDropdown>
    )
}

export default SettingsMenu