
export const LIBRARY_CODES = {
    LIBRARY_MODULE: 'FEAT-400',
    LIBRARY_NEW_DOCUMENT: 'FEAT-401',
    LIBRARY_DELETE_DOCUMENT: 'FEAT-402',
}

export const LIBRARY_CODES_NAMES = {
    LIBRARY_MODULE: 'LIBRARY_MODULE',
    LIBRARY_NEW_DOCUMENT: 'LIBRARY_NEW_DOCUMENT',
    LIBRARY_DELETE_DOCUMENT: 'LIBRARY_DELETE_DOCUMENT',
}