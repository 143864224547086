import { Image, Link, StyleSheet, Text, View } from '@react-pdf/renderer'

const DetailTaskPdf = ({ detailChecklistByIdToPdf, tasksByCategory }) => (
  <View id={detailChecklistByIdToPdf?.checklistInstanceDetail?.place}>
    <Text style={stylesDetailTasks.mainTitle}>
      DETALLES DE ENTREGA DE {detailChecklistByIdToPdf?.checklistInstanceDetail?.place}
    </Text>
    <View style={stylesDetailTasks.descriptionViewContainer}>
      <Text style={stylesDetailTasks.descriptionViewStaticText}>
        Asignado a:
      </Text>
      <Text style={stylesDetailTasks.descriptionViewDynamicText}>
        {' '}
        {detailChecklistByIdToPdf?.checklistInstanceDetail?.employee}
      </Text>

      <Text style={stylesDetailTasks.descriptionViewStaticText}>
        {' '}
        {' '}
        Fecha:
      </Text>
      <Text style={stylesDetailTasks.descriptionViewDynamicText}>
        {' '}
        {`${(detailChecklistByIdToPdf?.checklistInstanceDetail?.session?.date)} - ${(detailChecklistByIdToPdf?.checklistInstanceDetail?.session.timeBegin)}`}
      </Text>
    </View>
  
    <View style={stylesDetailTasks.descriptionContainerCheck}>
      <Text style={stylesDetailTasks.descriptionCheckStaticText}>
        CHECK NO SUPERADOS
      </Text>
      <Text style={stylesDetailTasks.descriptionCheckDynamicText}>
        {` ${(detailChecklistByIdToPdf?.checklistInstanceDetail?.failedReviews) < 10
          ? `0${detailChecklistByIdToPdf?.checklistInstanceDetail?.failedReviews}`
          : `${detailChecklistByIdToPdf?.checklistInstanceDetail?.failedReviews}`}`}
      </Text>
    </View>
  
    {tasksByCategory.map((tasks) => (
      <View key={tasks.id}>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={stylesDetailTasks.subTitleTaskCategory}>{(tasks?.name).toUpperCase() ?? ''}</Text>
          <Text style={{ marginLeft: 15, marginTop: 5 }}>____________________________________</Text>
        </View>
        
        {tasks.tasks.map((task) => (
          <View key={task.key} style={{
            paddingVertical: task.statusCode === 'failed' ? 25 : 0,
            borderTop: `${task.statusCode === 'failed' ? "1px solid #FF5858" : ''}`,
            borderBottom: `${task.statusCode === 'failed' ? "1px solid #FF5858" : ''}`,
          }}>
            <View key={task.key} style={stylesDetailTasks.optionContainerTaskTitle}>
              <Text style={[stylesDetailTasks.optionTaskTitle, { color: `${task.statusCode === 'failed' ? '#FF5858' : ''}` }]}>
                {task.item}
              </Text>
              <Text style={[stylesDetailTasks.optionTaskTitle,{ color: `${task.statusCode !== 'failed' ? '#16A07F' : '#FF5858'}` }]}>
                {(task.statusCode === 'ok' ? 'SUPERADO' : task.status).toUpperCase()}
              </Text>
            </View>

            {task.statusCode === 'failed' && (
              <View key={task.key}>
                <Text style={stylesDetailTasks.optionComment}>{task.review.comment}</Text>
                  {task.review.images.length ? (
                    <View>
                      <Text style={stylesDetailTasks.titleFiles}>IMÁGENES: </Text>
                      <View style={stylesDetailTasks.imagesView}>
                        {task.review.images.map((imgUrl) => (
                          <Image
                            cache={false}
                            key={imgUrl}
                            style={stylesDetailTasks.imageComponent}
                            src={imgUrl}
                          />
                        ))}
                      </View>
                    </View>
                  ) : <Text style={stylesDetailTasks.optionComment}>No hay imágenes añadidas</Text>}
                  {task.review.files.length ? (
                    <View>
                      <Text style={stylesDetailTasks.titleFiles}>ARCHIVOS ADJUNTOS: </Text>
                      <View>
                        {task.review.files.map((fileUrl) => (
                          <Text key={fileUrl}>
                            <Link src={fileUrl}>
                              {fileUrl}
                            </Link>
                          </Text>
                        ))}
                      </View>
                    </View>
                  ) : <Text style={stylesDetailTasks.optionComment}>No hay archivos añadidos</Text>}
              </View>
            )}
          </View>
        ))}
      </View>
    ))}

    {detailChecklistByIdToPdf?.checklistInstanceDetail?.sign_url
      ? <View>
          <Text style={stylesDetailTasks.titleFiles}>Firma: </Text>
          <View style={stylesDetailTasks.imagesView}>
            <Image cache={false} style={stylesDetailTasks.imageComponent} src={detailChecklistByIdToPdf.checklistInstanceDetail.sign_url} />
          </View>
        </View>
      : <Text style={stylesDetailTasks.titleFiles}>No posee firma</Text>
    }
  </View>
)

const stylesDetailTasks = StyleSheet.create({
    mainTitle: {
      fontWeight: 600,
      color: "#232C4A",
      marginBottom: 15,
      fontSize: 24,
      fontFamily: "Poppins"
    },
    descriptionViewContainer: {
      flexDirection: "row",
    },
    descriptionViewStaticText: {
      color: "#545454",
      fontWeight: 500,
      fontSize: 16,
      fontFamily: 'Roboto',
    },
    descriptionViewDynamicText: {
      color: "#696969",
      fontSize: 14,
      fontWeight: 400,
      fontFamily: "Poppins"
    },
    descriptionContainerCheck: {
      width: 269,
      flexDirection: "row",
      alignItems: "center",
      border: "1px solid #FF5858",
      borderRadius: 8,
      marginTop: 15,
      marginBottom: 15,
    }, 
    descriptionCheckStaticText: {
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: 16,
      color: "#545454",
      padding: 16,
    },
    descriptionCheckDynamicText : {
      fontFamily: "Roboto",
      fontWeight: 700,
      fontSize: 25,
      color: "#E82A2A"
    },
    subTitleTaskCategory: {
      color: "#252525",
      fontSize: 14,
      fontFamily: "Poppins",
      fontWeight: 500,
      marginVertical: 15,
    },
    optionTaskTitle: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: 12,
      color: "#4D4D4D",
    },
    optionContainerTaskTitle: {
      marginVertical: 10,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    optionComment: {
      marginVertical: 10,
      color: "#2D2D2D",
      fontSize: 12,
      fontWeight: 400,
      fontFamily: "Poppins",
    },
    imagesView: {
      flexDirection: "row",
      justifyContent: "center",
      border: "1px solid #ADADAD",
      borderRadius: 8,
      minWidth: 396,
    },
    titleFiles: {
      color: "#252525",
      fontSize: 11,
      fontFamily: "Poppins",
      fontWeight: 500,
      marginVertical: 15,
    },
    imageComponent: {
      width: 141,
      height: 141,
      paddingVertical: 15,
      paddingHorizontal: 10,
    }
  })

export default DetailTaskPdf