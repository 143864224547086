import { useEffect } from 'react'
import { Row } from 'antd'
import { useActivityMonitoring } from '../../hooks/useActivityMonotoring'
import { SummaryInfo } from './components/SummaryInfo'
import { CategoryInfo } from './components/CategoryInfo/CategoryInfo'
import './activityMonitoringStyle.css'
import { CustomSpin } from '../../../../../UI/CustomSpin'
import { CustomNavigate } from '../../../../../UI/CustomNavigate'
import { useFeatureRole } from '../../../../../hooks/useFeatureRole'
import { ADMIN_CHECK_CODES, ADMIN_CHECK_CODES_NAMES } from '../../../../../../constants/modules/adminCheck/adminCheckCodes'

export const ActivityMonitoring = () => {

    const { loading, dailyTasks, dateDaily, totalDailyPercent, addZeroToNumber, handleChangeDateDailyTask, getDailyTaskService } = useActivityMonitoring();

    const { loadingFeatures } = useFeatureRole({ feature_codes: ADMIN_CHECK_CODES, validate_code: ADMIN_CHECK_CODES_NAMES.ADMIN_CHECK_ACTIVITY_MONITORING, returnUrl: '/checklist/admin' })
    useEffect(() => {
        getDailyTaskService({ formData: { filter_by: dateDaily } })
    }, [])

    if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

    return (
        <section className="section-wrapper">
            <CustomSpin section spinning={loading}>
                <CustomNavigate title={'MONITOREO DE ACTIVIDADES'} />
                <Row style={{ marginTop: 24, justifyContent: 'center', gap: 64, }} gutter={32}>
                    <SummaryInfo dailyTasks={dailyTasks} dateDaily={dateDaily} totalDailyPercent={totalDailyPercent} addZeroToNumber={addZeroToNumber} handleChangeDateDailyTask={handleChangeDateDailyTask} />
                    <CategoryInfo dailyTasks={dailyTasks} dateDaily={dateDaily} />
                </Row>
            </CustomSpin>
        </section>
    )
}