import axios from "axios";
import { API_URL } from "../../../../config"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const getType = async () => {
  try {
    const response = await axios.get(`${API_URL}ticket/type`)

    return verifyBodyResponse(response, true)
  } catch (errorResponse) {
    return verifyBodyResponse(errorResponse, false)
  }
}

export default getType;
