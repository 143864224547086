import CustomModal from './../../../UI/CustomModal'
import { useAppSelector } from '../../../../hooks/store'
import DetailReserveBody from './bodies/DetailReserveBody'
import ApproveCancelReserveBody from './bodies/ApproveCancelReserveBody'

const DetailReserveModal = ({
  isOpenModal,
  loadingModal,
  handleOpenCloseModal
}) => {
  const { currentOptionModalBody } = useAppSelector(state => state.bookings)

  const verifyBodyPage = (idBody) => {
    let objBodies = {
      "1": <DetailReserveBody />,
      "2": <ApproveCancelReserveBody loadingModal={loadingModal} handleOpenCloseModal={handleOpenCloseModal} />,
      "3": <ApproveCancelReserveBody loadingModal={loadingModal} handleOpenCloseModal={handleOpenCloseModal} />
    }

    let body = objBodies[idBody] || objBodies['1']
    return body
  }

  return (
    <CustomModal
      destroyOnClose
      isOpenModal={isOpenModal}
      onCancel={handleOpenCloseModal}
    >
      {verifyBodyPage(currentOptionModalBody)}
    </CustomModal>
  )
}

export default DetailReserveModal