import { API_URL } from "../../../../../config/index";
import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const rolPath = "role/company/module/status"

//Endpoint de topia que permite recuperar los el estado de un modulo de un rol en específico
export const getRoleModuleStatus = async ({
    token,
    role_id,
    module_id
}) => {
    try {
        const params = {
            role_id,
            module_id
        };

        const response = await axiosCreate.get(`${API_URL}${rolPath}`, {
            params, timeout: 15000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }
};