import axios from "axios"
import { API_URL } from "../../../../config"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const createTicketAdmin = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}ticket/admin`, formData)

    return verifyBodyResponse(response, true)
  } catch (errorResponse) {
    return verifyBodyResponse(errorResponse, false)
  }
};

export default createTicketAdmin;
