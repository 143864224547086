import { useState, createContext } from "react"
import useOpenCloseModal from "../../../../hooks/useOpenCloseModal"

export const HandoverChecklistContext = createContext()

export const HandoverChecklistProvider = ({ children }) => {
  const [loadingTable, setLoadingTable] = useState(false)
  const [checklistSelected, setChecklistSelected] = useState({})

  const { form, isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  
  const cleanChecklistSelected = () => setChecklistSelected({})

  // Estados para el detalle de un checklist
  const [loading, setLoading] = useState(false)
  const [loadingTableDetail, setLoadingTableDetail] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [detailChecklistById, setDetailChecklistById] = useState({})
  const [detailChecklistByIdToPdf, setDetailChecklistByIdToPdf] = useState({})
  const [checklistCategories, setChecklistCategories] = useState([])
  const [checklistStatusReviews, setChecklistStatusReviews] = useState([])
  const [detailCheckPaginationData, setDetailCheckPaginationData] = useState({})

  const value = {
    form, isOpenModal, handleOpenCloseModal,
    checklistSelected, setChecklistSelected, cleanChecklistSelected,
    loadingTable, setLoadingTable,
    loading, setLoading,
    loadingTableDetail, setLoadingTableDetail,
    currentPage, setCurrentPage,
    detailChecklistById, setDetailChecklistById,
    checklistCategories, setChecklistCategories,
    checklistStatusReviews, setChecklistStatusReviews,
    detailCheckPaginationData, setDetailCheckPaginationData,
    detailChecklistByIdToPdf, setDetailChecklistByIdToPdf
  }

  return <HandoverChecklistContext.Provider value={value}>
    {children}
  </HandoverChecklistContext.Provider>
}