import { Col, Row } from "antd"
import { CustomInput } from "../../../../../../../UI/CustomInput"
import useAdminCheckListTasks from "../hooks/useAdminCheckListTasks"
import { useContext } from "react"
import { AdminChecklistTasksContext } from "../context/AdminChecklistTasksContext"

const FiltersAdminChecklistTasksTable = () => {
  const { filters, setFilters } = useContext(AdminChecklistTasksContext)
  
  const { getTasksByCategory } = useAdminCheckListTasks()

  const handleOnChange = async (event) => {
    await getTasksByCategory({ ...filters, name: event.target.value })

    setFilters(state => ({ ...state, name: event.target.value }))
  }

  return (
    <Row
      gutter={16}
      style={{
        marginInline: 1,
        marginTop: 20,
        padding: '24px 8px',
        backgroundColor: '#FFFFFF',
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
      }}
    >
      <Col span={4}>
        <CustomInput
          onChange={handleOnChange}
          placeholder="Escribe un nombre"
        />
      </Col>
    </Row>  )
}

export default FiltersAdminChecklistTasksTable