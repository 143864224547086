import { AdminCheckContextProvider } from "./contexts/AdminCheckContext";
import { AllCheckLists } from "./components/AllCheckLists/AllCheckLists";
import { NewCheckList } from "./components/NewCheckList/NewCheckList";
import { Categories } from "./components/Categories/Categories";
import { HistoryComments } from "./components/HistoryComments/HistoryComments";
import useCurrentLocation from "../../../hooks/useCurrentLocation";
import "./style.css";

export const AdminCheckPage = () => {
  const { param } = useCurrentLocation();

  const verifyPage = (adminCheckId) => {
    switch (adminCheckId) {
      case "new":
        return <NewCheckList />;
      case "categories":
        return <Categories />
      case "comment":
        return <HistoryComments />
      case "edit":
        return <NewCheckList />;
      case "delete":
        return <></>;
      default:
        return <AllCheckLists />;
    }
  };

  return (
    <AdminCheckContextProvider>
      {verifyPage(param.adminCheckId)}
    </AdminCheckContextProvider>
  );
};

export default AdminCheckPage;
