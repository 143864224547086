// WeeklyCalendar.js
import { useState, useCallback, useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { CustomEvent } from "./components/CustomEvent";
import { CustomDayHeader } from "./components/CustomDayHeader";
import {getLuminosity } from "../../../../helpers/randomColor";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/en-gb";
import "../../newCheckListStyle.css";

//Definir los dias de semana segun figma
moment.updateLocale("en", {
  weekdays: "Domingo,Lunes,Martes,Miércoles,Jueves,Viernes,Sábado".split(","),
  weekdaysMin: "D,L,M,X,J,V,S".split(","),
  weekdaysShort: "D,L,M,X,J,V,S".split(","),
  week: {
    dow: 0,
  }
});

const localizer = momentLocalizer(moment);

export const CustomCalendar = ({ events, maxWidth }) => {
  moment.locale("en");
  const calendarComponents = useMemo(
    () => ({
      event: CustomEvent,
      week: {
        header: CustomDayHeader,
      },
    }),
    []
  );

  const [hoveredEvent, setHoveredEvent] = useState(null);

  //Si se quisiera hacer algo al hacer click en un evento, usar esta función
  const onSelectEvent = useCallback((event, e) => {
    setHoveredEvent({
      ...event,
    });
  }, []);

  const eventStyleGetter = (event, start, end, isSelected) => {
    const color = event.color;
    const luminosity = getLuminosity(color);
    const style = {
      backgroundColor: color,
      color: luminosity > 0.5 ? "#000000" : "#ffffff",
    };
    return {
      style
    };
  }

  const reAdjustWeekRange = (date) => {
    const start = moment(date).subtract(1, 'days').startOf('week').add(6, 'days').toDate();
    const end = moment(start).add(6, 'days').endOf('day').toDate();
    return { start, end }
  }

  const initialStartDate = reAdjustWeekRange(new Date()).start;

  return (
    <div style={{ overflowY: "auto", height: "100%", position: "static", maxWidth }}>
      <Calendar
        className="checklist-calendar"        
        showMultiDayTimes={true}
        toolbar={false}
        localizer={localizer}
        events={events}
        showAllDay={false}
        allDayAccessor={false}
        startAccessor="start"
        endAccessor="end"
        popup={false}
        defaultView="week"
        views={["week"]}
        defaultDate={initialStartDate}
        onView={(view) => {

        }}
        step={30} // Intervalo de media hora
        timeslots={1} // Número de franjas por intervalo
        onSelectEvent={(event, e) => onSelectEvent(event, e)}
        formats={{
          weekdayFormat: (date, culture, localizer) =>
            localizer.format(date, "dddd", culture),
        }} //Formato de los días de semana
        components={calendarComponents}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );
};
