const TrashIcon = ({ color = '#FFFFFF', width = '19', height = '16' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 44 Copy 2">
        <g id="borrar">
        <path id="Group" fillRule="evenodd" clipRule="evenodd" d="M5.54187 12.667C5.06675 12.667 4.75 12.3503 4.75 11.8752V7.12391C4.75 6.64878 5.06675 6.33203 5.54187 6.33203C6.017 6.33203 6.33375 6.64878 6.33375 7.12391V11.8752C6.33375 12.3503 6.017 12.667 5.54187 12.667Z" fill={color}/>
        <path id="Group_2" fillRule="evenodd" clipRule="evenodd" d="M8.70789 12.667C8.23277 12.667 7.91602 12.3503 7.91602 11.8752V7.12391C7.91602 6.64878 8.23277 6.33203 8.70789 6.33203C9.18302 6.33203 9.49977 6.64878 9.49977 7.12391V11.8752C9.49977 12.3503 9.18302 12.667 8.70789 12.667Z" fill={color}/>
        <path id="Group_3" fillRule="evenodd" clipRule="evenodd" d="M13.4619 4.74977H0.79188C0.316754 4.74977 0 4.43302 0 3.95789C0 3.48276 0.316754 3.16602 0.79188 3.16602H13.4619C13.9371 3.16602 14.2538 3.48276 14.2538 3.95789C14.2538 4.43302 13.9371 4.74977 13.4619 4.74977Z" fill={color}/>
        <path id="Group_4" fillRule="evenodd" clipRule="evenodd" d="M9.50274 15.836H4.75149C3.00936 15.836 1.58398 14.4107 1.58398 12.6685V3.95789C1.58398 3.48277 1.90073 3.16602 2.37586 3.16602H11.8784C12.3535 3.16602 12.6702 3.48277 12.6702 3.95789V12.6685C12.6702 14.4107 11.2449 15.836 9.50274 15.836ZM3.16797 4.75V12.6688C3.16797 13.5398 3.88066 14.2525 4.75172 14.2525H9.50297C10.374 14.2525 11.0867 13.5398 11.0867 12.6688V4.75H3.16797Z" fill={color}/>
        <path id="Group_5" fillRule="evenodd" clipRule="evenodd" d="M9.50212 4.75126H4.75086C4.27573 4.75126 3.95898 4.4345 3.95898 3.95938V2.37563C3.95898 1.02944 4.98842 0 6.33461 0H7.91836C9.26455 0 10.294 1.02944 10.294 2.37563V3.95938C10.294 4.4345 9.97724 4.75126 9.50212 4.75126ZM5.54297 3.16774H8.71047V2.37586C8.71047 1.90073 8.39372 1.58398 7.9186 1.58398H6.33484C5.85972 1.58398 5.54297 1.90073 5.54297 2.37586V3.16774Z" fill={color}/>
        </g>
        </g>
        </svg>
        
  
    )
  }
  
  export default TrashIcon