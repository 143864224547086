import  { useState } from "react"
import { Avatar, Col, Row } from "antd"
import EmployeeForm from "../EmployeeForm"
import {UserOutlined} from '@ant-design/icons'
import CustomModal from "../../../../UI/CustomModal"
import UploadImg from "../../../../UI/UploadImg/UploadImg"

// CREAR / EDITAR EMPLEADOS
const NewEditEmployee = ({companyId, employeeSelected, visible, onClose, employeeHook}) => {
  const { PROFILE } = employeeSelected
  const [imageUrl, setImageUrl] = useState({})

  return (
    <CustomModal
      isOpenModal={visible}
      onCancel={() => {
        onClose()
        setImageUrl({})
      }}
      title={!employeeSelected.id ? "NUEVO USUARIO" : "EDITAR USUARIO"}
      width={840}
      destroyOnClose
    >
     <Row gutter={20}>
        <Col span={8}>
          <section className="employee-modal-firts">
            <Avatar 
              size={137} 
              style={{marginBottom: '1rem'}}
              icon={<UserOutlined/>}
              src={imageUrl.url || PROFILE?.PROFILE_DETAIL?.image}
            />
   
            <UploadImg 
              title={!employeeSelected.id ? 'Subir fotografía': 'Cambiar fotografía'}
              setImg={setImageUrl}
            />
            <span>Formatos jpg/png de 500x400px</span>
          </section>
        </Col>
        <Col span={16}>
          <EmployeeForm
            companyId={companyId}
            file={imageUrl.file}
            onClose={onClose}
            setImageUrl={setImageUrl}
            employeeSelected={employeeSelected}
            employeeHook={employeeHook}
          />
        </Col>
      </Row>
    </CustomModal>
  );
};

export default NewEditEmployee;
