import { useState } from "react"
import {
  getTicketsThunk,
  StatusTicketsThunk,
  createATicketsAdminThunk,
} from "../../../../store/tickets/thunks"
import {
  getCategoriesTicketThunk,
  getEmployeeThunk,
} from "../../../../store/detailTicket/thunk"
import useCurrentLocation from "../../../hooks/useCurrentLocation"
import { dataToFormData } from "../../../../helpers/dataToFormData"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"

const useTickets = () => {
  const dispatch = useAppDispatch()

  const [skipToExcel, setSkipToExcel] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  
  const { param } = useCurrentLocation()
  const { roles, currentRol } = useAppSelector((state) => state.auth)
  const { activeKey, status, isAssignedTickets } = useAppSelector((state) => state.tickets)

  const roleType = param.typeTickets === 'administrative' ? 'admin' : 'tenant'

  // Funcion que devuelve tanto los status como los tickets (funciona como la peticion inicial porque
  // siempre toma el primer status de un ticket)
  const getAllDataTickets = async () => {
    const response = await getTicketsByStatus();
    
    if (response.success) {
      await getTableTickets({statusId: response.data.data[0].id})
    }

    dispatch(getEmployeeThunk(currentRol))
    dispatch(getCategoriesTicketThunk(currentRol))
  }

  const getTicketsByStatus = async () => {
    const response = await dispatch(StatusTicketsThunk(roleType, currentRol))
    return response
  }

  const getTableTickets = async ({
    statusId,
    skip,
    limit,
    findId,
    assignedTickets
  }) => {
    const response = await dispatch(getTicketsThunk({ assignedTickets, statusId: statusId, role: roleType, skip, limit, findValue: findId }))
  
    return response
  }

  // Funcion que crea un ticket administrativo
  const onSaveTickets = async (formData) => {
    const formDataToSend = dataToFormData({
      category_id: formData.category_id,
      company_id: formData.company_id,
      description: formData.description,
      employee_creator_id: formData.employee_creator_id,
      employee_id: formData.employee_id,
      subcategory_id: formData.subcategory_id,
      title: formData.title
    })

    // Se agregan los archivos al formData por separado
    formData?.files?.map((file) => formDataToSend.append("files", file))
    
    const response = await dispatch(createATicketsAdminThunk(formDataToSend))

    return response
  }

  const changePageHandler = async (currentPag, limit) => {
    const pageValue = currentPag || currentPage
    const newSkip = (pageValue * limit) - limit

    setSkipToExcel(newSkip)
    setCurrentPage(pageValue)

    const currentIdFiltered = status?.find((statusData) => statusData.code === activeKey)

    await getTableTickets({ assignedTickets: isAssignedTickets, statusId: currentIdFiltered?.id, skip: newSkip, limit: limit })
  }

  return {
    roleType,
    roles,
    skipToExcel,
    currentPage,
    setCurrentPage,
    changePageHandler,
    onSaveTickets,
    getAllDataTickets,
    getTableTickets,
    getTicketsByStatus,
  }
}

export default useTickets
