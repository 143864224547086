import { Button, Input, Form } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import EquipmentModal from "./Modal/ModalEquipments/EquipmentModal";
import useOpenCloseModal from "../../../hooks/useOpenCloseModal";
import InventoryOption from "./InventoryOption";
import { useState, useEffect } from "react";
import SuppliesModal from "./Modal/ModalSupplies/SuppliesModal";
import { useDispatch } from "react-redux";
import { setInventoryThunk } from "../../../../store/inventory/thunks";
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm";
import { INVENTORY_CODES, INVENTORY_CODES_NAMES } from "../../../../constants/modules/inventory/inventoryCodes";

const InventoryOptions = ({ inventory, outStock, activeFeatures }) => {
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal();
  const [modal, setModal] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [form] = Form.useForm();
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  const checkEquipmentSupplyFeature = ({ name, code }) => {
    if (name === 'supply') {
      return !activeFeatures?.find(feature => feature.feature_code === INVENTORY_CODES[code])?.enabled
    }
    if (name === 'equipment') {
      return !activeFeatures?.find(feature => feature.feature_code === INVENTORY_CODES[code])?.enabled
    }
  }

  useEffect(() => {
    if (inventory.title === "INSUMO") {
      setModal(true);
      setType("supplies");
      return;
    }

    setModal(false);
    setType("equipments");
  }, [inventory.title]);

  useEffect(() => {
    dispatch(setInventoryThunk(searchWord, type));
  }, [searchWord]);

  const outStockArray = Object.values(outStock);

  const categories = Object.keys(inventory);

  return (
    <div className="inventory_container">
      <div className="inventory_title_container">
        <h3 className="inventory_title"> {inventory.title} </h3>
      </div>
      {categories.length && (
        <Form className="searchBar_container" form={form}>
          <Input
            style={{ fontSize: "1rem" }}
            className="search"
            placeholder="Buscar"
            onChange={(e) => setSearchWord(e.target.value)}
          />

          <div
            style={{
              borderRadius: "0 20px 20px 0",
              padding: "0.5rem",
              border: "0.1px  solid #75757570",
              borderLeft: "none",
              cursor: "pointer",
            }}
          >
            <SearchOutlined style={{ fontSize: "1rem" }} />
          </div>
        </Form>
      )}

      <div className="inventory_list_container">
        <div className="inventory_list">
          {/* {modal && (
            <div>
              {outStockArray.length > 0 && (
                <div>
                  <h2 style={{ color: "red" }}>Insumos faltantes</h2>
                  {outStockArray.map((item, index) => (
                    <div key={index}>
                      <p style={{ color: "red" }}>{item.name}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )} */}
          <InventoryOption checkEquipmentSupplyFeature={checkEquipmentSupplyFeature} inventory={inventory} />
        </div>
      </div>

      <div className="container_btn-add-product">
        <CustomPopoverConfirm
          placement={modal ? 'top' : 'left'}
          trigger={'hover'}
          content={`${(modal ? checkEquipmentSupplyFeature({ name: 'supply', code: INVENTORY_CODES_NAMES.INVENTORY_NEW_SUPPLY }) : checkEquipmentSupplyFeature({ name: 'equipment', code: INVENTORY_CODES_NAMES.INVENTORY_NEW_EQUIPMENT })) ? 'No tienes permiso para realizar esta acción' : modal ? 'Nuevo insumo' : 'Nuevo equipo'}`}
        >
          <Button
            disabled={modal ? checkEquipmentSupplyFeature({ name: 'supply', code: INVENTORY_CODES_NAMES.INVENTORY_NEW_SUPPLY }) : checkEquipmentSupplyFeature({ name: 'equipment', code: INVENTORY_CODES_NAMES.INVENTORY_NEW_EQUIPMENT })}
            onClick={handleOpenCloseModal}
            className="btn-add-product"
            shape="circle"
            icon={<PlusOutlined />}
          />
        </CustomPopoverConfirm>
      </div>

      {modal ? (
        <SuppliesModal isOpenModal={isOpenModal} close={handleOpenCloseModal} />
      ) : (
        <EquipmentModal
          isOpenModal={isOpenModal}
          close={handleOpenCloseModal}
        />
      )}
    </div>
  );
};

export default InventoryOptions;
