import { createContext, useState } from "react"
import useOpenCloseModal from "../../../hooks/useOpenCloseModal"

const defaultValues = {
  checklistsAdmin: [], setChecklistsAdmin: () => {}, checklistAdminClicked: {}, setChecklistAdminClicked: () => {}, 
  handleOpenCloseModal: () => {}, onReset: () => {}, isOpenModal: false, checklistsStatus: [], setChecklistsStatus: () => {},
  currentTabChecklistStatus: '', setCurrentTabChecklistStatus: () => {}, filters: {}, setFilters: () => {},
  loadingTable: false, setLoadingTable: () => {}, metadata: {}, setMetadata: () => {}, currentPage: 1,
  setCurrentPage: () => {}
}

export const ChecklistsAdminContext = createContext(defaultValues)

export const ChecklistsAdminProvider = ({ children }) => {
  const [loadingTable, setLoadingTable] = useState(false)
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    find_value: null
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [metadata, setMetadata] = useState({})

  const [checklistsAdmin, setChecklistsAdmin] = useState([])
  const [checklistsStatus, setChecklistsStatus] = useState([])

  const [checklistAdminClicked, setChecklistAdminClicked] = useState({})
  const [currentTabChecklistStatus, setCurrentTabChecklistStatus] = useState('')

  const { form, isOpenModal, handleOpenCloseModal, onReset } = useOpenCloseModal()

  const value = {
    onReset, form, isOpenModal, handleOpenCloseModal, checklistAdminClicked, setChecklistAdminClicked,
    checklistsAdmin, setChecklistsAdmin, checklistsStatus, setChecklistsStatus, currentTabChecklistStatus,
    setCurrentTabChecklistStatus, filters, setFilters, loadingTable, setLoadingTable, metadata, setMetadata,
    currentPage, setCurrentPage
  }

  return <ChecklistsAdminContext.Provider value={value}>
    {children}
  </ChecklistsAdminContext.Provider>
}