import moment from "moment"
import { Button, Row } from "antd"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { CustomSelect } from "../../../UI/CustomSelect"
import { CustomNavigate } from "../../../UI/CustomNavigate"
import MonthlyIncomePDFReport from "./MonthlyIncomePDFReport"
import { monthsSelect, yearSelect } from "../mocks/selectData"
import { PdfReportIcon, SelectIcon, selectIcon } from "../assets"
import CustomTreeSelect from "../../../UI/CustomSelects/CustomTreeSelect"
import * as ExcelJS from 'exceljs'
import { saveAs } from "file-saver";

import { ReactComponent as ExcelIcon } from '../../../../assets/icons/excel.svg'

export const Header = ({
  pdfData,
  isTopData,
  placesTreeData,
  handleTopTenData,
  handleChangeFilter,
  salesStatementFilters,
}) => {
  const createExcel = async () => {
    const workBook = new ExcelJS.Workbook()
    const workSheet = workBook.addWorksheet('Resultados')

    workSheet.columns = [
        { key: 'id', width: 30, },
        { key: 'place', width: 30, },
        { key: 'createdAt', width: 30 },
        { key: 'hostAdmin', width: 30 },
        { key: 'transactionDate', width: 30 },
        { key: 'quantity', width: 30 },
      ]
    // Titulo
    workSheet.mergeCells('A1:F1')
    workSheet.getCell('A1').value = pdfData?.general?.mall
    workSheet.getCell('A1').alignment = { horizontal: 'center' }
    workSheet.getCell('A1').font = { size: 16, bold: true }

    // Datos generales del usuario que descarga la encuesta
    workSheet.addRow({ id: 'ADMINISTRADOR', place: `${pdfData?.general?.first_name} ${pdfData?.general?.last_name}` })
    workSheet.getCell('A2').font = { bold: true }

    workSheet.addRow([])
    // Se agregan los encabezados de cada columna 
    workSheet.addRow({
      id: 'CÓDIGO',
      place: 'LOCAL',
      createdAt: 'FECHA DE VENTAS',
      hostAdmin: 'ENCARGADO DEL LOCAL',
      transactionDate: 'FECHA DE REGISTRO',
      quantity: 'MONTO'
    })
    workSheet.addRow([])

    // Los estilos para cada encabezado de columna
    workSheet.getCell('A4').font = { bold: true }
    workSheet.getCell('B4').font = { bold: true }
    workSheet.getCell('C4').font = { bold: true }
    workSheet.getCell('D4').font = { bold: true }
    workSheet.getCell('E4').font = { bold: true }
    workSheet.getCell('F4').font = { bold: true }

    pdfData?.table?.forEach((saleData) => {
      workSheet.addRow({
        id: saleData?.code,
        place: saleData?.place_name,
        createdAt: saleData?.created_at,
        hostAdmin: saleData?.profile_name,
        transactionDate: saleData?.transaction_date,
        quantity: saleData?.quantity
      })
    })

    const buffer = await workBook.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: 'application/vmd.openxmlformats-officedocument.spreadsheetml.sheet' })
    saveAs(blob, `${pdfData?.general?.mall}.xlsx`)
  }

  return (
    <header>
      <CustomNavigate title={<>
          DECLARACIÓN DE VENTAS
          <span style={{ fontSize: 18, color: 'var(--primary)', fontWeight: 600 }}>REPORTES</span>
        </>}
      />
      <Row style={{ marginBottom: 24, gap: 16 }} justify="end" align="middle">
        <Button
          onClick={handleTopTenData}
          className={'salesStatement__top-button'}
          style={{ backgroundColor: isTopData ? 'var(--primary)' : '#fff', color: isTopData ? '#fff' : '#000' }}
        >
          Top 10
        </Button>
            
        <CustomTreeSelect
          showSearch
          allowClear
          isToForm={false}
          treeData={placesTreeData}
          suffixIcon={<SelectIcon />}
          dropdownMatchSelectWidth={300}
          placeholder={'Buscar socio comercial'}
          className={'salesStatement__tree-select'}
          onChange={(value) => handleChangeFilter({ value, name: 'place_select' })}
          filterTreeNode={(inputValue, treeNode) => treeNode.title.toLowerCase().includes(inputValue.toLowerCase())}
        />
            
        <CustomSelect
          width="150px"
          filters={false}
          placeholder={'Año'}
          options={yearSelect}
          suffixIcon={selectIcon}
          className={'salesStatement__select'}
          value={salesStatementFilters.year_select}
          onChange={(value) => handleChangeFilter({ value, name: 'year_select' })}
        />
            
        <CustomSelect
          width="150px"
          filters={false}
          placeholder={'Mes'}
          options={monthsSelect}
          suffixIcon={selectIcon}
          className={'salesStatement__select'}
          value={salesStatementFilters.month_select}
          onChange={(value) => handleChangeFilter({ value, name: 'month_select' })}
        />
            
        <PDFDownloadLink
          document={<MonthlyIncomePDFReport isTopData={isTopData} data={pdfData} />}
          fileName={`DECLARACIÓN DE VENTAS ${moment(salesStatementFilters.date).startOf('day').format('MMMM YYYY').toUpperCase()}`}
        >
          <Button
            style={{ border: 'none', boxShadow: 'none', background: 'transparent' }}
            icon={<PdfReportIcon />}
          />
        </PDFDownloadLink>

        <Button
          type="link"
          htmlType="button"
          icon={<ExcelIcon />}
          onClick={createExcel}
          style={{ border: 'none', boxShadow: 'none', background: 'transparent' }}
        />
      </Row>
    </header>
)}

export default Header