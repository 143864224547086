import { getAllCompanyAssets } from '../../services/GET/getAllCompanyAssets'
import { getAllCompanyProviders } from '../../services/GET/getAllCompanyProviders'
import { createCompanyProvider } from '../../services/POST/createCompanyProvider'
import { createUpdateCompanyAsset } from '../../services/POST/createUpdateCompanyAsset'
import { deleteExistingProvider } from '../../services/DELETE/deleteExistingProvider'
import { useActivesMaintenanceContext } from '../../context/ActiveMaintenanceContext'
import { verifyResponse } from '../../../../helpers/verifyResponse'
import { getTableDataHelper } from '../../../../helpers/getTableDataHelper'
import { deleteCompanyAsset } from '../../services/DELETE/deleteCompanyAsset'
import getCompanyAssetStatus from '../../services/GET/getCompanyAssetStatus'
import getCompanyAssetDetail from '../../services/GET/getCompanyAssetDetail'
import getCompanyAssetMaintenanceReport from '../../services/GET/getCompanyAssetMaintenanceReport'
import { useAppSelector } from '../../../../../hooks/store'

export const useActivesMaintenanceServices = () => {
    const { currentRol, idToken } = useAppSelector(state => state.auth)

    const {
      setStatusActivesMaintenance, currentActiveTab, setCurrentActiveTab, setDetailActiveDrawer,
    } = useActivesMaintenanceContext()

    const getAllCompanyAssetsService = async ({ limit, skip, find_by, find_value, sort_by, sort_order, setLoading, company_asset_status_id, isToSelect }) => {
        const res = await getAllCompanyAssets({ idToken: idToken, company_id: currentRol, limit, skip, find_by, find_value, sort_by, sort_order, company_asset_status_id: company_asset_status_id || currentActiveTab, isToSelect })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        if (!status) {
            setLoading && setLoading(false)
            throw new Error('Error en getAllCompanyAssetsService')
        }

        if (isToSelect) {
          return res
        }

        const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata })
        return { data, metadata }
    }

    const getAllCompanyProvidersService = async ({ limit, skip, find_by, find_value, sort_by, sort_order, customSelectData, setLoading, isToSelect }) => {
        const res = await getAllCompanyProviders({ token: idToken, company_id: currentRol, limit, skip, find_by, find_value, sort_by, sort_order, isToSelect })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })

        if (!status) {
            setLoading && setLoading(false)
            throw new Error('Error en getAllCompanyProviderService')
        }
        if (customSelectData) return res

        if (isToSelect) return res
        
        const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata })
        return { data, metadata }
    }

    const createCompanyProviderService = async ({ formData, setLoading }) => {
        const res = await createCompanyProvider({ token: idToken, formData: { ...formData, company_id: currentRol } })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, message: 'El proveedor ha sido creado' })

        if (!status) {
            setLoading && setLoading(false)
            throw new Error('Error en createCompanyProviderService')
        }
        return res.data.data
    }

    const getAllStatusMaintenance = async () => {
      const response = await getCompanyAssetStatus()

      if (response.success) {
        setStatusActivesMaintenance(response.data.data)
        setCurrentActiveTab(response.data.data[0]?.company_asset_status_id)
        return response
      }

      return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
    }

    const getDetailActive = async (company_asset_id) => {
      const response = await getCompanyAssetDetail({ idToken: idToken, company_asset_id })

      if (response.success) {
        setDetailActiveDrawer(response.data.data)
        return response
      }

      return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
    }

    const getActiveReport = async (formData) => {
      const response = await getCompanyAssetMaintenanceReport({ ...formData, company_id: currentRol, idToken: idToken, })

      return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Reporte descargado con éxito' })
    }

    const createUpdateCompanyAssetService = async ({ formData }) => {
        // validar si es editar o crear
        const res = await createUpdateCompanyAsset({ token: idToken, formData })
        
        return verifyResponse({ dataResponse: res.data, statusResponse: res.status, message: 'Activo creado con con éxito' })
    }

    const deleteExistingProviderService = async ({ provider_id, setLoading }) => {
        const res = await deleteExistingProvider({ token: idToken, provider_id })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, message: 'Proveedor eliminado con éxito' })

        if (!status) {
            setLoading && setLoading(false)
            throw new Error('Error en deleteExistingProviderService')
        }
        return res.data.data
    }

    const onDeleteCompanyAsset = async (formData) => {
      const response = await deleteCompanyAsset(formData, idToken)

      return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Activo eliminado con éxito' })
    }

    return {
      getDetailActive, onDeleteCompanyAsset, getAllStatusMaintenance, getAllCompanyAssetsService,
      getAllCompanyProvidersService, createCompanyProviderService, createUpdateCompanyAssetService,
      deleteExistingProviderService, getActiveReport
    }
}