import { createSlice } from '@reduxjs/toolkit';

export const paymentsSlice = createSlice({
    name: 'payments',
    initialState: {
        payments: {
            data: [],
            amenityPending: [],
            amenityPaid: [],
            active: null
        },

        errorMessage: null
    },
    reducers: {
        setPaymentsData: ( state, { payload } ) => {
            state.payments.data = payload;
        },
        setAmenityPaymentsPendingData: ( state, { payload } ) => {
            state.payments.amenityPending = payload;
        },
        setAmenityPaymentsPaidData: ( state, { payload } ) => {
            state.payments.amenityPaid = payload;
        },
        setActivePayment: ( state, { payload } ) => {
            state.payments.active = payload;
        }
    }
});


export const { 
    setPaymentsData,
    setActivePayment,
    setAmenityPaymentsPendingData,
    setAmenityPaymentsPaidData
} = paymentsSlice.actions;