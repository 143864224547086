import { Button, Col, Form, Row } from "antd"
import { useContext, useState } from "react"
import CustomModal from "../../../../UI/CustomModal"
import { CustomInput } from "../../../../UI/CustomInput"
import { config } from "../../../../constants/constants"
import { ChecklistsAdminContext } from "../../context/ChecklistsAdminContext"
import useChecklists from "../../hooks/useChecklists"

const NewEditChecklistAdminModal = () => {
  const [loading, setLoading] = useState(false)

  const { onSaveChecklists, getChecklistsTable } = useChecklists()
  const {
    form, isOpenModal, handleOpenCloseModal, onReset, checklistAdminClicked, setChecklistAdminClicked, filters
  } = useContext(ChecklistsAdminContext)

  const onFinish = async (isSuccessful) => {
    if (isSuccessful) {
      onReset()
      handleOpenCloseModal()
      getChecklistsTable({ ...filters })
    }
  }

  const onSubmit = async (formData) => {
    setLoading(true)

    const isEditOrCreate = checklistAdminClicked?.checklist_id
      ? { checklist_id: checklistAdminClicked?.checklist_id }
      : {}

    const objToSend = {
      ...formData,
      ...isEditOrCreate,
    }

    const response = await onSaveChecklists(objToSend)

    await onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal
      isOpenModal={isOpenModal}
      onCancel={() => {
        setChecklistAdminClicked({})
        handleOpenCloseModal()
      }}
      title={<span 
        style={{ fontWeight: 600, fontSize: 22, display: 'block', width: '100%', textAlign: 'center', color: '#232C4A' }}
      >
        {checklistAdminClicked?.checklist_id ? 'EDITAR CHECKLIST' : 'NUEVO CHECKLIST'}
      </span>}
    >
      <Form
        form={form}
        layout="vertical"
        disabled={loading}
        name="newEditChecktlistAdminForm"
        onFinish={onSubmit}
      >
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <label style={{ fontWeight: 600, fontSize: 12, color: '#626262', marginLeft: 10 }}>NOMBRE</label>

                <Form.Item name="name" {...config}>
                  <CustomInput allowClear placeholder="Escribe un nombre" className="custom-input-variant-1" />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="center">
              <Button
                type="text"
                shape="round"
                htmlType="submit"
                loading={loading}
                disabled={loading}
                className="btn-standard-green"
                style={{ width: 250 }}
              >
                <span>
                  {checklistAdminClicked?.checklist_id ? 'GUARDAR' : 'CREAR'}
                </span>
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default NewEditChecklistAdminModal