import { createContext, useState } from "react";
import useOpenCloseModal from "../../../../../../../hooks/useOpenCloseModal";

export const AdminChecklistTasksContext = createContext()

export const AdminChecklistTasksProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [metadata, setMetadata] = useState({})
  const [taskClicked, setTaskClicked] = useState({})
  const [checklistTasksTable, setChecklistTasksTable] = useState({})
  
  const [filters, setFilters] = useState({})
  const [currentPage, setCurrentPage] = useState(1)

  const { form, isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  const value = {
    filters, setFilters,
    loading, setLoading,
    metadata, setMetadata,
    currentPage, setCurrentPage,
    taskClicked, setTaskClicked,
    checklistTasksTable, setChecklistTasksTable,
    form, isOpenModal, handleOpenCloseModal,
  }

  return <AdminChecklistTasksContext.Provider value={value}>
    {children}
  </AdminChecklistTasksContext.Provider>
}