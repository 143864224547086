import { Col, Row } from "antd"
import { useEffect, useState } from "react"
import useWidgets from "../../hooks/useWidgets"
import { CustomSpin } from "../../../../UI/CustomSpin"
import useRedirectTo from "../../../../hooks/useRedirectTo"
import { useAppSelector } from "../../../../../hooks/store"

const UsersTickets = () => {
  const [loadingTickets, setLoadingTickets] = useState(false)

  const { handleRedirectTo } = useRedirectTo()
  const { usersStatusTickets } = useAppSelector(state => state.home)
  const { getAdminTicketsStatus, getUsersTicketsStatus } = useWidgets()

  useEffect(() => {
    getTickets()
  },[])

  const getTickets = async () => {
    setLoadingTickets(true)
    await getUsersTicketsStatus()
    await getAdminTicketsStatus()
    setLoadingTickets(false)
  }

  // Prepare number tickets
  const usersTickets = usersStatusTickets.length ? usersStatusTickets[0]?.totalTicket : 0
  const inProgressUsersTickets = usersStatusTickets.length ? usersStatusTickets[3]?.totalTicket : 0
  const reviewedUsersTickets = usersStatusTickets.length ? usersStatusTickets[1]?.totalTicket : 0

  // Se mapean todas las opciones de la data inicial
  const optionsModified = optionsData.map((option) => {
    let objStatisticsOptions = {
      1: ({
        ...option,
        dataNum: usersTickets
      }),
      2: ({
        ...option,
        dataNum: inProgressUsersTickets
      }),
      3: ({
        ...option,
        dataNum: reviewedUsersTickets
      }),
      'default': option,
    }
    let statisticsOption = objStatisticsOptions[option.id] || objStatisticsOptions['default']

    return statisticsOption
  })

  if ( loadingTickets) return <CustomSpin section />

  return (
    <Row style={{ backgroundColor: "#FFFFFF", borderRadius: 16, padding: 25, marginBottom: 15 }}>
      <Col span={24}>
        <h3 style={{ fontWeight: 600, fontSize: 22 }}>
          TICKETS DE USUARIOS
        </h3>

        <Row gutter={[24, 24]} wrap>
          {optionsModified?.map((optionData) => <BoxStatistics key={optionData.id} optionData={optionData} handleRedirectTo={handleRedirectTo} /> )}
        </Row>
      </Col>
    </Row>
  )
}

const BoxStatistics = ({ optionData, handleRedirectTo }) => {
  const handleOnClick = () => {
    handleRedirectTo('/tickets/users')
  }

  return (
    <Col
      span={24}
      style={{ color: "#000000", cursor: 'pointer' }}
      onClick={handleOnClick}
      className={optionData.className}
    >
      <Row style={{ backgroundColor: optionData.color, borderRadius: 16 }}>
        <Col span={24} style={{ padding: 10 }}>
          <Row>
            <label style={{ fontWeight: 500, fontSize: 12 }}>
              {optionData.label}
            </label>
          </Row>

          <Row>
            <span style={{ fontSize: 30, fontWeight: 700 }}>
              {optionData.dataNum < 10 ? `0${optionData.dataNum}` : optionData.dataNum}
            </span>
          </Row>

          <Row justify="end">
            <p style={{ fontWeight: 400, fontSize: 12 }}>
              {optionData.description}
            </p>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}

const optionsData = [
  {
    id: 1,
    label: 'SIN REVISAR',
    dataNum: '90%',
    description: '',
    color: '#D4F0E9',
    className: 'ticket-hover-variant-1',
  },
  {
    id: 2,
    label: 'EN PROGRESO',
    dataNum: '32K',
    description: '',
    color: '#C6EAFF',
    className: 'ticket-hover-variant-2',
  },
  {
    id: 3,
    label: 'REVISADOS',
    dataNum: '32K',
    description: '',
    color: '#FFE2B1',
    className: 'ticket-hover-variant-3',
  }
]

export default UsersTickets
