import { Button, Col, Row } from 'antd'
import '../../styles.css'
import { AddDateItem, CalendarIcon, DeleteTableItemIcon, selectIcon } from '../../../assets'
import { CustomSelect } from '../../../../../UI/CustomSelect'
import { validateItemDate, validateUniqueDates } from '../../../helpers/dateHelper'
import moment from 'moment'
import { CustomDatePicker } from '../../../../../UI/CustomDatePicker'
import { CustomInput } from '../../../../../UI/CustomInput'

const ProviderDateItem = ({ Form, handleAddNewDateItem }) => (
    <Form.List rules={[{ required: true, message: 'Es necesaria al menos una fecha de visita' }, {
      validator: async (_, values) => {
        const validateList = validateUniqueDates({ items: values })
        return validateList ? Promise.reject(new Error(validateList)) : Promise.resolve()
      }
    }]} initialValue={Array(1).fill({})} name={'agenda'}>
      {
        (fields, { add, remove }, { errors }) => (
          <Col>
            {
              fields.map((field, index) => (
                <Row key={field.name} className='providerDate' gutter={24} align='middle' justify='start'>
                  <Form.Item noStyle shouldUpdate >
                    {
                      ({ getFieldValue }) => {
                        const date = getFieldValue(['agenda', field.name, 'date'])
                        const updatable = getFieldValue(['agenda', field.name, 'updatable'])
                        const isToday = date ? moment(date.clone()).isSame(moment(), 'day') : false
                        const currentTypeTime = getFieldValue(['agenda', field.name, 'typeTime'])
                        const currentHour = getFieldValue(['agenda', field.name, 'hours'])
                        const actualHour = moment().hours()
                        const actualMinutes = moment().minutes()

                        const hours = Array(12).fill({}).map((_, index) => ({
                          id: index,
                          name: !index ? '12' : index < 10 ? `0${index}` : index.toString(),
                          disabled: isToday && (
                            (actualHour >= 12 && currentTypeTime === 'am') || //Si la hora actual es mayor a 12 y es am ✅
                            (actualHour >= 12 && currentTypeTime === 'pm' && index < actualHour - 12) || //Si la hora actual es mayor o igual a 12 y es pm y si la hora  ✅
                            (actualHour < 12 && currentTypeTime === 'am' && index < actualHour) //Si la hora actual es menor a 12 y es am y la hora  es menor a la hora actual  ✅                                        
                          )
                        }))
                        const minutes = Array(60).fill({}).map((_, index) => ({
                          id: index,
                          name: index < 10 ? `0${index}` : index.toString(),
                          disabled: isToday && (
                            (actualHour < 12 && currentTypeTime === 'am' && currentHour === actualHour && index < actualMinutes) ||
                            (actualHour >= 12 && currentTypeTime === 'pm' && currentHour === (actualHour - 12) && index < actualMinutes)
                          )
                        }))
                        const typeTime = [{ id: 'am', name: 'AM', disabled: isToday && actualHour >= 12 }, { id: 'pm', name: 'PM' },]

                        return (
                          <>
                            <Form.Item rules={[{ required: true, message: 'La fecha es necesaria' }]} name={[field.name, 'date']}>
                              <CustomDatePicker disabled={updatable !== undefined && updatable === false} disabledDateBeforeToday={true} placeholder={'Seleccionar fecha'} suffixIcon={<CalendarIcon />} width='135px' />
                            </Form.Item>
                            <Col>
                              <Form.Item name={[field.name, 'validation_time']} rules={[{
                                validator: async () => {
                                  const validateItem = validateItemDate({ item: getFieldValue(['agenda', field.name]), updatable })
                                  return validateItem ? Promise.reject(new Error(validateItem)) : Promise.resolve()
                                }
                              }]}>
                                <Row>
                                  <Form.Item rules={[{ required: true, message: 'Hora' }]} name={[field.name, 'hours']}>
                                    <CustomSelect disabled={updatable !== undefined && updatable === false ? true : !date} onPopupScroll={() => { }} options={hours} placeholder={'00'} width='60px' suffixIcon={selectIcon} />
                                  </Form.Item >
                                  <Form.Item rules={[{ required: true, message: 'Minutos' }]} name={[field.name, 'minutes']}>
                                    <CustomSelect disabled={updatable !== undefined && updatable === false ? true : !date} onPopupScroll={() => { }} options={minutes} placeholder={'00'} width='60px' suffixIcon={selectIcon} />
                                  </Form.Item>
                                  <Form.Item rules={[{ required: true, message: 'AM/PM' }]} name={[field.name, 'typeTime']}>
                                    <CustomSelect disabled={updatable !== undefined && updatable === false ? true : !date} placeholder={'AM/PM'} width='80px' options={typeTime} suffixIcon={selectIcon} />
                                  </Form.Item>
                                </Row>
                              </Form.Item>
                            </Col>
                          </>
                        )
                      }
                    }
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate >
                    {
                      ({ getFieldValue }) => {
                        const updatable = getFieldValue(['agenda', field.name, 'updatable'])
                        return index && (updatable === true || updatable === undefined) ? <Button style={{ border: 'none', boxShadow: 'none' }} icon={<DeleteTableItemIcon />} onClick={() => remove(field.name)} /> : <></>
                      }
                    }
                  </Form.Item>
                  {index + 1 === fields.length && <Button style={{ border: 'none', boxShadow: 'none' }} icon={<AddDateItem />} onClick={() => { add(); handleAddNewDateItem({ field }) }} />}
                  <Form.Item noStyle name={[field.name, 'updatable']}>
                    <CustomInput hidden={true} />
                  </Form.Item>
                  <Form.Item noStyle name={[field.name, 'id']}>
                    <CustomInput hidden={true} />
                  </Form.Item>
                </Row>
              ))
            }
            <Form.ErrorList errors={errors} />
          </Col>
        )
      }
    </Form.List>
  )

export default ProviderDateItem