import axios from "axios";
const updateCategories = async (
  URL_DEV,
  idTicket,
  idCategory,
  idSubcategory
) => {
  try {
    const categoryUpdated = {
      ticket_id: idTicket,
      category_id: idCategory,
      subcategory_id: idSubcategory,
    };
    const response = await axios.put(`${URL_DEV}/category`, categoryUpdated);

    return {
      success: true,
      ...response,
    };
  } catch (errorResponse) {
    return {
      success: false,
      ...errorResponse.response,
    };
  }
};
export default updateCategories;
