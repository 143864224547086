import BodyPage from "./components/BodyPage"
import HeaderContracts from "./components/HeaderContracts"
import useOpenCloseModal from './../../hooks/useOpenCloseModal'
import './style.css'
import { AdminContractsProvider } from "./context/AdminContractsContext"
import { useFeatureRole } from "../../hooks/useFeatureRole"
import { MANAGEMENT_CONTRACTORS_CODES, MANAGEMENT_CONTRACTORS_CODES_NAMES } from "../../../constants/modules/managamentContractor/managamentContractorCodes"
import { CustomSpin } from "../../UI/CustomSpin"

const AdminContractsPage = () => {
  // instancia de form para resetear formulario programaticamente desde boton header en planos
  const { form, onReset, isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: MANAGEMENT_CONTRACTORS_CODES, validate_code: MANAGEMENT_CONTRACTORS_CODES_NAMES.MANAGEMENT_CONTRACTORS_MAP })

  if(loadingFeatures) return <CustomSpin tip="Cargando módulo" />
  
  return (
    <AdminContractsProvider>
      <section className="wrapper-page">
        <HeaderContracts activeFeatures={activeFeatures} onReset={onReset} handleOpenCloseModal={handleOpenCloseModal} />

        <BodyPage activeFeatures={activeFeatures} form={form} isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
      </section>
    </AdminContractsProvider>
  )
}

export default AdminContractsPage