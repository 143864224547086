import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  detailTypeNew: {},
  typesNews: [],
  idTypesNews: [],
  filters: {
    dateBegin: '',
    dateEnd: '',
    published: true,
    active: '',
    newsTypeId: null,
    skip: 0,
    limit: 10,
  },
  currentActiveTab: '1',
  currentTypeNewClicked: {},
  metadata: {},
  loadingTypesNews: false,
  loadingDetailTypeNew: false,
}

export const typesNewsSlice = createSlice({
  name: 'typesNews',
  initialState,
  reducers: {
    setDetailTypeNew: (state, { payload }) => {
      state.detailTypeNew = payload
    },
    setTypesNews: (state, { payload }) => {
      state.typesNews = payload
    },
    setIdTypesNews: (state, { payload }) => {
      state.idTypesNews = payload
    },
    setFilters: (state, { payload }) => {
      state.filters = payload
    },
    setMetadata: (state, { payload }) => {
      state.metadata = payload
    },
    setLoadingTypesNews: (state, { payload }) => {
      state.loadingTypesNews = payload
    },
    setCurrentActiveTab: (state, { payload }) => {
      state.currentActiveTab = payload
    },
    setCurrentTypeNewClicked: (state, { payload }) => {
      state.currentTypeNewClicked = payload
    },
    setLoadingDetailTypeNew: (state, { payload }) => {
      state.loadingDetailTypeNew = payload
    }
  }
})

export const {
  setFilters,
  setMetadata,
  setTypesNews,
  setIdTypesNews,
  setDetailTypeNew,
  setLoadingTypesNews,
  setCurrentActiveTab,
  setLoadingDetailTypeNew,
  setCurrentTypeNewClicked,
} = typesNewsSlice.actions
