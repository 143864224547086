import { Col, Row } from "antd"
import { CustomSelect } from "../../../../UI/CustomSelect"
import { ticketOfCalenderThunk } from "../../../../../store/tickets/thunks"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store"

const FilterCalender = ({
  calenderDates,
  setEmployeeId,
  setTypeId,
  employeeId,
  typeId,
}) => {
  const dispatch = useAppDispatch()

  const { type } = useAppSelector((state) => state.tickets);
  const { currentRol } = useAppSelector((state) => state.auth)

  const today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let timeBegin = year + "-" + month + "-" + 1;
  let d = new Date(year, month, 0);
  let timeEnd = year + "-" + month + "-" + d.getDate()

  const typesTickets = type?.data?.map((type) => ({ id: type?.id, name: type?.name })) || []

  const employees = useAppSelector((state) => state.detailTicketById.employeeTicket)

  const employeeList = employees?.map((employee) => {
    const smallName = employee?.name.slice(0, 20);
    return {
      id: employee?.id,
      name: smallName,
    };
  });

  const handleCategoryChange = async(value) => {
    const newTimeBegin = calenderDates?.timeBegin
      ? calenderDates?.timeBegin
      : timeBegin;
    const newTimeEnd = calenderDates?.timeEnd
      ? calenderDates?.timeEnd
      : timeEnd;
    setTypeId(value);
    await dispatch(
      ticketOfCalenderThunk({
        timeBegin: newTimeBegin,
        timeEnd: newTimeEnd,
        companyId: currentRol,
        typeId: value,
        employeeId: employeeId,
      })
    );
  };

  const handleEmployeeChange = async(value) => {
    const newTimeBegin = calenderDates?.timeBegin
      ? calenderDates?.timeBegin
      : timeBegin;
    const newTimeEnd = calenderDates?.timeEnd
      ? calenderDates?.timeEnd
      : timeEnd;

    setEmployeeId(value);
    await dispatch(
      ticketOfCalenderThunk({
        timeBegin: newTimeBegin,
        timeEnd: newTimeEnd,
        employeeId: value,
        typeId: typeId,
        companyId: currentRol,
      })
    );
  };

  return (
    <Row gutter={20} justify="end" align="middle">
      <Col>
        <CustomSelect
          allowClear
          width="186px"
          placeholder={"Categorías"}
          options={typesTickets}
          onChange={handleCategoryChange}
        />
      </Col>
      <Col>
        <CustomSelect
          allowClear
          width="186px"
          placeholder={"Empleados"}
          options={employeeList}
          onChange={handleEmployeeChange}
        />
      </Col>
    </Row>
  );
};

export default FilterCalender;
