import { Col, Row, Typography } from "antd"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store"
import { getNoticeTypeDataOfMessage } from "../../../../../helpers/alertHelper"
import { setAlertSelectedDetail, setIsLoading } from "../../../../../store/alerts"
import { ALERTS_CODES } from "../../../../../constants/modules/alerts/alertsCodes"
import { getResidentialsById, getUserDataProfileById } from "../../../../../helpers/globalHelper"

// Imagenes o archivos svg
import CustomPopoverConfirm from "../../../../UI/CustomPopoverConfirm"
import alertDetailIcon from '../../../../../assets/icons/Generals/alert-detail-icon.svg'

const { Text } = Typography

const Alert = ({ alertData, handleOpenCloseDrawer, activeFeatures }) => {
  const dispatch = useAppDispatch()
  const { isLoading } = useAppSelector(state => state.alerts)

  // Se extraen las primeras dos posiciones para validar si es am o pm la fecha de creacion
  const isAmOrPm = parseInt(alertData?.created_time?.slice(0, 2) ?? '0')
  // Se cambia el formato YYYY-MM-DD a YYYY/MM/DD
  const dateFormated = alertData?.created_at?.replaceAll('-', '/')

  const handleOnClickDrawer = async () => {
    dispatch(setIsLoading(true))
    // Se realiza la peticion solamente para ver el detalle de la alerta donde se realiza el click
    const noticeType = await getNoticeTypeDataOfMessage(alertData.notice_type);
    const residentials = await getResidentialsById(alertData.residential);
    const userFrom = await getUserDataProfileById(alertData.user_from);

    const completedAlertData = {
      ...alertData,
      notice_type_data: noticeType,
      residential_data: residentials,
      user_from_data: userFrom,
    }

    // Se realiza el dispatch con la nueva informacion devuelta de firebase ya que es requerida en el drawer
    dispatch(setAlertSelectedDetail(completedAlertData))
    handleOpenCloseDrawer()
    dispatch(setIsLoading(false))
  }

  return (
    <Col span={12} className="alert-hover">
      <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ALERTS_CODES.ALERTS_DETAILS)?.enabled ? 'No tienes permiso para ver los detalles de una alerta' : ''}`}>
        <Row
          align="middle"
          gutter={16}
          style={{ cursor: isLoading ? 'not-allowed' : 'pointer', border: '1px solid #707070', borderRadius: 8, margin: 1, padding: 5 }}
          onClick={activeFeatures?.find(feature => feature.feature_code === ALERTS_CODES.ALERTS_DETAILS)?.enabled ? handleOnClickDrawer : () => { }}
        >
          <Col span={3}>
            <Row justify="center">
              <img src={alertDetailIcon} alt="alert-icon" />
            </Row>
          </Col>

          <Col span={21}>
            <span style={{ color: '#707070', fontWeight: 400, fontSize: 10 }}>
              {dateFormated ? dateFormated : 'No tiene fecha de creación'}
              {' '}
              -
              {' '}
              {alertData?.created_time ? alertData.created_time : 'No tiene tiempo de creación'}
              {' '}
              {isAmOrPm < 12 ? 'am' : 'pm'}
            </span>

            <h3 style={{ color: '#252525', fontWeight: 700, fontSize: 16, margin: 0 }}>
              <Text
                style={{ width: 350 }}
                ellipsis={{
                  tooltip: ((alertData?.address) ? (alertData.address) : '')
                }}
              >
                {alertData?.asunto ? alertData.asunto : ''}

                <span className="circle"></span>

                {alertData?.address ? alertData.address : 'No tiene una dirección asignada'}
              </Text>
            </h3>

            <p style={{ color: '#707070', fontSize: 14, fontWeight: 400, margin: 0 }}>
              <Text
                style={{ width: 250 }}
                ellipsis={{
                  tooltip: ((alertData?.message) ? (alertData.message) : '')
                }}
              >
                {alertData?.message ? alertData.message : 'No escribieron un mensaje'}
              </Text>
            </p>
          </Col>
        </Row>
      </CustomPopoverConfirm>
    </Col>
  )
}

export default Alert
