import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    hierarchyAdminSpaces: [],
    placeTypes: [],
    data:{
        dataSource: [],
        columns:[],
        quantity: 0,
        limit: 0,
        skip: 0,
        rest:0
    },
    currentParentId: null
}

export const hierarchyAdminSpaceSlice = createSlice({
  name: "hierarchyAdminSpace",
  initialState,
  reducers: {
    setInitialDataSource: (state) => {
        state.data.dataSource = [];
        state.data.columns = [];
      },
    setHierarchyAdminSpace: (state, { payload }) => {
        const { data } = payload;
        state.hierarchyAdminSpaces = data
      },
    setPlacesHierarchyAdmin: (state, { payload }) => {
        const { data, metadata } = payload;
        state.data.dataSource = data?.dataSource;
        state.data.columns = data?.columns;
        state.data.quantity = metadata?.quantity;
        state.data.limit = metadata?.limit;
        state.data.skip = metadata?.skip;
        state.data.rest = metadata?.rest;
      },
    setTypePlacesHierarchyAdmin:(state, { payload }) => {
        const { data } = payload;
        state.placeTypes = data
      },
    setCurrentParentId: (state, { payload }) => {
        state.currentParentId = payload;
      },
    },
});

export const {setCurrentParentId, setHierarchyAdminSpace,setCurrentPage, setLastSkip, setInitialDataSource, setPlacesHierarchyAdmin, setTypePlacesHierarchyAdmin } =
  hierarchyAdminSpaceSlice.actions;
