import { Col, Row } from 'antd'
import CustomModal from '../../../../../../../UI/CustomModal'
import '../../../styles.css'

const MoreCommentaryAnswers = ({ isOpenModal, modalData, setIsOpenModal,  }) => {
    //Aqui deben de mostrarse las respuestas de los comentarios cuandos sean mayor a 1 respuesta (actualmente)    
    return (
        <CustomModal className='more-commentary-modal' isOpenModal={isOpenModal} onCancel={() => setIsOpenModal(false)}>
            <Col className="survey-answers">
                <h2 className="survey-answer-question-header">PREGUNTA #{modalData?.order + 1}</h2>
                <span className="survey-answer-question">{modalData?.question}</span>
                {
                    modalData?.answers?.map((answer, index) => (
                        <Row key={index} className="item-answer-commentary">
                            {answer.response}
                        </Row>
                    ))
                }
            </Col>
        </CustomModal>
    )
}

export default MoreCommentaryAnswers