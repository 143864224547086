import { useState } from 'react'
import { Col, Form, Row } from 'antd'
import { CustomSelect } from '../../../UI/CustomSelect'
import { CustomRadioButton } from '../../../UI/CustomRadio/CustomRadioButton'

import '../styles.css'

const AdditionalSpaceForm = ({
  index,
  config,
  userTypes,
  getAllSpacesToSelect,
  getAllParkingToSelect,
  getAllWareHouseToSelect,
}) => {
  const [isOwner, setIsOwner] = useState(true)

  return (
    <Row>
      <Col span={24}>
        <Form.Item name={`type_user_new_space_${index}`} {...config} style={{ margin: 1 }}>
          <CustomRadioButton style={{ flexDirection: 'row' }} options={userTypes} />
        </Form.Item>

        <Form.Item name={`space_${index}`} {...config}>
          <CustomSelect
            placeholder={'Seleccionar espacio'}
            isAsync
            allowClear
            service={getAllSpacesToSelect}
          />
        </Form.Item>
        {isOwner && (
          <Row gutter={10}>
            <Col span={12}>
              <label style={{ color: "#676767", fontSize: "12px" }}>PARQUEO ASIGNADO</label>
              <Form.Item name={`parking_${index}`}>
                <CustomSelect
                  placeholder={'Ejm: 12B, 13B'}
                  isAsync
                  allowClear
                  service={getAllParkingToSelect}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label style={{ color: "#676767", fontSize: "12px" }}>BODEGA PERSONAL</label>
              <Form.Item name={`ware_house_${index}`}>
                <CustomSelect
                  placeholder={'Ejm: bodega12'}
                  allowClear
                  isAsync
                  service={getAllWareHouseToSelect}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  )
}

export default AdditionalSpaceForm
