import { Image, StyleSheet, Text, View } from "@react-pdf/renderer"
import { formatUTCDateInTimeZone } from "../../../helpers/customDateHelpers"

// Build custom pdf to download
const DetailTicketPdf = ({ detailTicketData = {}, statusRoad = [] }) => {
  let createdAt = (detailTicketData?.created_at) ?? ''
  let updatedAt = (detailTicketData?.updated_at) ?? ''

  if (createdAt && updatedAt) {
    createdAt = formatUTCDateInTimeZone({ utcDate: new Date(createdAt) })
    updatedAt = formatUTCDateInTimeZone({ utcDate: new Date(updatedAt) })
  }

  return (
    <View style={{ fontFamily: 'Poppins', textAlign: 'justify' }}>
      <Text style={stylesDetailTicket.mainTitle}>TICKET: {detailTicketData?.code}</Text>
      {(detailTicketData?.TICKET_STATUS?.name === "Cancelado")
        ? (
          <Text>
            Motivo de la cancelación del ticket:
            {detailTicketData?.TICKET_STATUS_LOGs?.[0]?.created_at?.split("T")[0]} -{" "}
            {detailTicketData?.TICKET_STATUS_LOGs?.[0]?.created_at?.split("T")[1]?.split(".")[0]}
          </Text>
        ) : <Text></Text>}

      <View style={stylesDetailTicket.viewGeneralData}>
        {statusRoad?.[0]?.id !== 9 ? (
          <>
            <View>
              <Text style={stylesDetailTicket.titleGeneralData}>
                Local:
              </Text>
              <Text style={stylesDetailTicket.descriptionGeneralData}>
                {detailTicketData?.TICKET_RESIDENTIAL?.houseName}
              </Text>
            </View>

            <View>
              <Text style={stylesDetailTicket.titleGeneralData}>
                Nomenclatura:
              </Text>
              <Text style={stylesDetailTicket.descriptionGeneralData}>
                {detailTicketData?.TICKET_RESIDENTIAL?.nomenclatureName}
              </Text>
            </View>
          </>
        ) : (
          <>
            <View>
              <Text style={stylesDetailTicket.titleGeneralData}>
                Título:
              </Text>
              <Text style={stylesDetailTicket.descriptionGeneralData}>
                {detailTicketData?.TICKET_DETAIL?.title}
              </Text>
            </View>

            <View>
              <Text style={stylesDetailTicket.titleGeneralData}>
                Encargado:
              </Text>
              <Text style={stylesDetailTicket.descriptionGeneralData}>
                {detailTicketData?.TICKET_EMPLOYEE?.EMPLOYEE?.name}
              </Text>
            </View>
          </>
        )}
        <View>
          <Text style={stylesDetailTicket.titleGeneralData}>
            Última actualización:
          </Text>
          <Text style={stylesDetailTicket.descriptionGeneralData}>
            {((updatedAt) || 'Sin actualización')}
          </Text>
        </View>

        <View>
          <Text style={stylesDetailTicket.titleGeneralData}>
            Fecha de creación:
          </Text>
          <Text style={stylesDetailTicket.descriptionGeneralData}>
            {((createdAt) || '')}
          </Text>
        </View>
      </View>

      <View style={stylesDetailTicket.viewDetailTicket}>
        <Text style={stylesDetailTicket.titleDetailTicket}>
          DETALLE DEL TICKET:
        </Text>

        <Text style={stylesDetailTicket.subTitleDetailTicket}>
          Título del ticket:
        </Text>
        <Text style={stylesDetailTicket.descriptionDetailTicket}>
          {detailTicketData?.TICKET_DETAIL?.title}
        </Text>

        <Text style={stylesDetailTicket.subTitleDetailTicket}>
          Descripción:
        </Text>
        <Text style={stylesDetailTicket.descriptionDetailTicket}>
          {detailTicketData?.TICKET_DETAIL?.description}
        </Text>
      </View>

      {detailTicketData?.TICKET_EMPLOYEE
        ? (
          <View>
            <View>
              <Text style={stylesDetailTicket.titleDetailTicket}>
                ASIGNACIÓN:
              </Text>

              <Text style={stylesDetailTicket.subTitleDetailTicket}>
                Estatus:
              </Text>
              <Text style={stylesDetailTicket.descriptionDetailTicket}>
                {detailTicketData?.TICKET_EMPLOYEE?.TICKET_EMPLOYEE_STATUS?.name}
                {' '}
                {formatUTCDateInTimeZone({ utcDate: new Date((detailTicketData?.TICKET_EMPLOYEE?.created_at) ?? '') })}
              </Text>

              <Text style={stylesDetailTicket.subTitleDetailTicket}>
                Departamento:
              </Text>
              <Text style={stylesDetailTicket.descriptionDetailTicket}>
                {detailTicketData?.TICKET_EMPLOYEE?.EMPLOYEE?.EMPLOYEE_DEPARTMENT?.COMPANY_DEPARTMENT?.name}
              </Text>

              <Text style={stylesDetailTicket.subTitleDetailTicket}>
                Personal Asignado:
              </Text>
              <Text style={stylesDetailTicket.descriptionDetailTicket}>
                {detailTicketData?.TICKET_EMPLOYEE?.EMPLOYEE?.name}
              </Text>
            </View>

            {detailTicketData?.TICKET_INSPECTION
              ? (
                <View>
                  <View style={stylesDetailTicket.viewInspectionDetail}>
                    <Text style={stylesDetailTicket.titleDetailTicket}>
                      DETALLES DE INSPECCIÓN: (REQUERIMIENTO ORIGINAL)
                    </Text>

                    <Text style={stylesDetailTicket.dateInspectionDetail}>
                      Fecha:
                      {' '}
                      {formatUTCDateInTimeZone(new Date((detailTicketData?.TICKET_INSPECTION?.created_at) ?? ''))}
                    </Text>

                    <Text style={stylesDetailTicket.subTitleInspectionDetail}>
                      Notas del trabajador
                    </Text>
                    <Text style={stylesDetailTicket.descriptionInspectionDetail}>
                      {detailTicketData?.TICKET_INSPECTION?.description}
                    </Text>

                    <View style={stylesDetailTicket.viewInventoryInspection}>
                      <View style={[stylesDetailTicket.viewInventoryOption, { width: '33.33%' }]}>
                        <Text style={{ marginBottom: 5 }}>Insumos solicitados:</Text>

                        <View>
                          {detailTicketData?.TICKET_INSPECTION?.TICKET_INSPECTION_SUPPLies?.map((supply) => (
                            <View key={supply?.id} style={{ border: "1px solid #C6C6C6", borderRadius: 5, marginVertical: 3 }}>
                              <Text
                                key={supply?.id}
                                style={{ textAlign: 'center', padding: 2 }}
                              >
                                {supply?.quantity} {supply?.Supply?.name} | {detailTicketData?.COMPANY?.COMPANY_DETAIL?.COMPANY_CURRENCY?.symbol}{" "}
                                {supply?.totalCost?.toFixed(2)}
                              </Text>
                            </View>
                          ))}
                        </View>
                      </View>

                      <View style={[stylesDetailTicket.viewInventoryOption, { width: '33.33%' }]}>
                        <Text style={{ marginBottom: 5 }}>Equipo solicitado:</Text>

                        <View>
                          {detailTicketData?.TICKET_INSPECTION?.TICKET_INSPECTION_EQUIPMENTs?.map((equipment) => (
                            <View key={equipment?.id} style={{ border: "1px solid #C6C6C6", borderRadius: 5, marginVertical: 3 }}>
                              <Text key={equipment?.id} style={{ textAlign: 'center', padding: 2 }}>
                                {equipment?.quantity} {equipment?.Equipment?.name}
                              </Text>
                            </View>
                          ))}
                        </View>
                      </View>

                      <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingLeft: 25 }}>
                        <Text>Costo total</Text>

                        <Text style={{ fontWeight: 700, fontSize: 20 }}>
                          {detailTicketData?.COMPANY?.COMPANY_DETAIL?.COMPANY_CURRENCY?.symbol}
                          {(detailTicketData?.TICKET_INSPECTION?.expectedCost)?.toFixed(2)}
                        </Text>
                      </View>
                    </View>

                    <Text style={[stylesDetailTicket.subTitleInspectionDetail, { marginVertical: 5 }]}>
                      Tiempo estimado de reparación:
                      {' '}
                      {(Math.round((detailTicketData?.TICKET_INSPECTION?.expectedMinutes / 60) * 100) / 100)?.toFixed(2)?.replace(".", ":")}
                      {' '}
                      {detailTicketData?.TICKET_INSPECTION?.expectedMinutes < 60 ? "minutos" : "hora(s)"}
                    </Text>

                    <Text style={{ fontSize: 14, fontWeight: 600 }}>Fecha(s) de mantenimiento:</Text>
                    <View>
                      {detailTicketData?.TICKET_INSPECTION?.TICKET_INSPECTION_WORK_SESSIONs?.map((workDay, index) => (
                        <Text key={workDay?.id} style={{ marginVertical: 5, fontWeight: 400, fontSize: 12 }}>
                          - Jornada {index + 1} - (
                          {workDay?.EMPLOYEE_ACTIVITY_SCHEDULE?.date?.split("T")[0]}{" "}
                          {workDay?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeBegin} {" - "}
                          {workDay?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeEnd})
                        </Text>
                      ))}
                    </View>
                  </View>

                  <View>
                    <Text style={stylesDetailTicket.titleDetailTicket}>REQUERIMIENTO APROBADO</Text>

                    <View style={{ marginTop: 8 }}>
                      <View style={[stylesDetailTicket.viewInventoryInspection, { marginVertical: 5 }]}>
                        <View style={[stylesDetailTicket.viewInventoryOption, { width: '33.33%' }]}>
                          <Text style={{ marginBottom: 5 }}>Insumos solicitados:</Text>

                          {(detailTicketData?.TICKET_INSPECTION?.TICKET_INSPECTION_STATUS?.code === 'accepted')
                            ? (
                              detailTicketData?.TICKET_SUPPLies?.map((supply) => (
                                <View key={supply?.id} style={{ border: "1px solid #C6C6C6", borderRadius: 5, marginVertical: 3 }}>
                                  <Text  style={{ padding: 2, textAlign: 'center' }}>
                                    {supply?.quantity} {supply?.Supply?.name} | {detailTicketData?.COMPANY?.COMPANY_DETAIL?.COMPANY_CURRENCY?.symbol}{" "}
                                    {supply?.totalCost?.toFixed(2)}
                                  </Text>
                                </View>
                              ))
                            )
                            : (
                              detailTicketData?.TICKET_INSPECTION?.TICKET_INSPECTION_SUPPLies?.map((supply) => (
                                <Text
                                  key={supply?.id}
                                  style={{
                                    padding: 3,
                                    marginVertical: 3,
                                    color: (supply?.TICKET_INSPECTION_SUPPLY_STATUS?.code === "deleted")
                                      ? "#BBBBBB"
                                      : "#000",
                                    textDecoration: (supply?.TICKET_INSPECTION_SUPPLY_STATUS?.code === "deleted")
                                      ? "line-through"
                                      : "none",
                                  }}
                                >
                                  {supply?.quantity} {supply?.Supply?.name} | {detailTicketData?.COMPANY?.COMPANY_DETAIL?.COMPANY_CURRENCY?.symbol}{" "}
                                  {supply?.totalCost?.toFixed(2)}
                                </Text>
                              ))
                            )
                          }
                        </View>

                        <View style={[stylesDetailTicket.viewInventoryOption, { width: '33.33%' }]}>
                          <Text style={{ marginBottom: 5 }}>Equipo solicitado:</Text>

                          {(detailTicketData?.TICKET_INSPECTION?.TICKET_INSPECTION_STATUS?.code === "accepted")
                            ? (
                              detailTicketData?.TICKET_EQUIPMENTs?.map((equipment) => (
                                <View key={equipment?.id} style={{ border: "1px solid #C6C6C6", borderRadius: 5, marginVertical: 3 }}>
                                  <Text style={{ padding: 2, textAlign: 'center' }}>
                                    {equipment?.quantity} {equipment?.Equipment?.name}
                                  </Text>
                                </View>
                              ))
                            )
                            : (
                              detailTicketData?.TICKET_INSPECTION?.TICKET_INSPECTION_EQUIPMENTs?.map((equipment) => (
                                <Text
                                  key={equipment?.id}
                                  style={{
                                    padding: 3,
                                    marginVertical: 3,
                                    color: (equipment?.TICKET_INSPECTION_EQUIPMENT_STATUS?.code === "deleted")
                                      ? "#BBBBBB"
                                      : "#000",
                                    textDecoration: (equipment?.TICKET_INSPECTION_EQUIPMENT_STATUS?.code === "deleted")
                                      ? "line-through"
                                      : "none",
                                  }}
                                >
                                  {equipment?.quantity} {equipment?.Equipment?.name}
                                </Text>
                              ))
                            )}
                        </View>

                        <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingLeft: 25 }}>
                          <Text>
                            Costo total
                          </Text>
                          <Text style={{ fontWeight: 700, fontSize: 20 }}>
                            {detailTicketData?.COMPANY?.COMPANY_DETAIL?.COMPANY_CURRENCY?.symbol}
                            {(detailTicketData?.TICKET_INSPECTION?.expectedCost)?.toFixed(2)}
                          </Text>
                        </View>
                      </View>

                      <Text style={[stylesDetailTicket.subTitleInspectionDetail, { color: '#444444' }]}>
                        Tiempo estimado de reparación:
                        {' '}
                        {(Math.round((detailTicketData?.TICKET_INSPECTION?.expectedMinutes / 60) * 100) / 100)?.toFixed(2)?.replace(".", ":")}
                        {' '}
                        {detailTicketData?.TICKET_INSPECTION?.expectedMinutes < 60 ? "minutos" : "hora(s)"}
                      </Text>

                      <Text style={{ color: "#444444", fontSize: 14, fontWeight: 600, marginTop: 5 }}>
                        Fecha(s) de mantenimiento:
                      </Text>
                      <View>
                        {(detailTicketData?.TICKET_INSPECTION?.TICKET_INSPECTION_STATUS?.code === "accepted")
                          ? (
                            detailTicketData?.TICKET_WORK_SESSIONs?.map((workDay, index) => (
                              <Text key={workDay?.id} style={{ textDecoration: workDay.done ? "line-through" : "none", marginVertical: 3, fontWeight: 400, fontSize: 12 }}>
                                - Jornada {index + 1} - (
                                {workDay?.EMPLOYEE_ACTIVITY_SCHEDULE?.date?.split("T")[0]}{" "}
                                {workDay?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeBegin} {" - "}
                                {workDay?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeEnd})
                              </Text>
                            ))
                          )
                          : (
                            detailTicketData?.TICKET_INSPECTION?.TICKET_INSPECTION_WORK_SESSIONs?.map((workDay, index) => (
                              <Text
                                key={workDay?.id}
                                style={{
                                  marginVertical: 3,
                                  color: workDay?.EMPLOYEE_ACTIVITY_SCHEDULE?.EMPLOYEE_ACTIVITY_STATUS?.code !== "pending"
                                    ? "#E82A2A"
                                    : "#000000"
                                }}
                              >
                                - Jornada {index + 1} - {" "}
                                {workDay?.EMPLOYEE_ACTIVITY_SCHEDULE?.EMPLOYEE_ACTIVITY_STATUS
                                  ?.code !== "pending" && "Deshabilitada - "}
                                ({workDay?.EMPLOYEE_ACTIVITY_SCHEDULE?.date?.split("T")[0]}{" "}
                                {workDay?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeBegin} {" - "}
                                {workDay?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeEnd})
                              </Text>
                            ))
                          )}
                      </View>
                    </View>
                  </View>
                </View>
              )
              : <Text></Text>}
          </View>
        )
        : <Text></Text>
      }
      {detailTicketData?.TICKET_FINAL_NOTE
        ? (
          <View>
            <Text style={[stylesDetailTicket.titleDetailTicket, { marginBottom: 10 }]}>RESUMEN DEL TICKET</Text>

            <View style={stylesDetailTicket.viewFinalDetails}>
              <View style={{ padding: 15, borderBottom: "1px solid #BDBDBD", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View>
                  <Text style={stylesDetailTicket.subTitleDetailTicket}>
                    Número de jornadas
                  </Text>
                  <Text>{detailTicketData?.TICKET_FINAL_NOTE?.totalWorkSessions}</Text>

                  <Text style={stylesDetailTicket.subTitleDetailTicket}>
                    Tiempo de realización
                  </Text>
                  <Text>
                    {(Math.round((detailTicketData?.TICKET_INSPECTION?.expectedMinutes / 60) * 100) / 100)?.toFixed(2)?.replace(".", ":")}
                    {' '}
                    {detailTicketData?.TICKET_INSPECTION?.expectedMinutes < 60 ? "minutos" : "hora(s)"}
                  </Text>
                </View>

                <View>
                  <Text style={stylesDetailTicket.subTitleDetailTicket}>
                    Notas finales
                  </Text>
                  <Text style={{ fontWeight: 400, color: "#444444" }}>
                    {detailTicketData?.TICKET_FINAL_NOTE?.description}
                  </Text>
                </View>
              </View>

              <View style={{ padding: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View>
                  <Text style={stylesDetailTicket.subTitleDetailTicket}>
                    Costo total
                  </Text>
                  <Text style={{ fontSize: 36, fontWeight: 700 }}>
                    {detailTicketData?.COMPANY?.COMPANY_DETAIL?.COMPANY_CURRENCY?.symbol}
                    {(detailTicketData?.TICKET_FINAL_NOTE?.totalCost)?.toFixed(2)}
                  </Text>
                </View>

                <View>
                  <Text style={stylesDetailTicket.subTitleDetailTicket}>
                    Firma del cliente
                  </Text>
                  <Image cache={false} style={{ maxWidth: 155, maxHeight: 155 }} src={(detailTicketData?.TICKET_FINAL_NOTE?.signImage)} />
                </View>
              </View>
            </View>
          </View>)
        : <Text></Text>}
    </View>
  )
}

const stylesDetailTicket = StyleSheet.create({
  mainTitle: {
    fontWeight: 700,
    color: "#232C4A",
    marginBottom: 10,
    fontSize: 24,
  },
  viewGeneralData: {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 10,
  },
  titleGeneralData: {
    fontWeight: 600,
    color: "#000000",
  },
  descriptionGeneralData: {
    fontWeight: 400,
    color: "#000000",
    marginVertical: 10,
  },
  viewDetailTicket: {
    borderTop: "1px solid #757575",
    color: "#000000",
  },
  titleDetailTicket: {
    fontWeight: 600,
    fontSize: 14,
    color: "#444444",
    paddingTop: 20,
  },
  subTitleDetailTicket: {
    fontWeight: 500,
    fontSize: 12,
    marginVertical: 8,
  },
  descriptionDetailTicket: {
    fontWeight: 400,
    fontSize: 12,
  },
  viewInspectionDetail: {
    color: '#444444'
  },
  subTitleInspectionDetail: {
    fontSize: 14,
    fontWeight: 600,
  },
  dateInspectionDetail: {
    fontSize: 14,
    fontWeight: 400,
    marginVertical: 10,
  },
  descriptionInspectionDetail: {
    fontSize: 12,
    fontWeight: 400,
    marginVertical: 10,
  },
  viewInventoryInspection: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 5,
    fontSize: 12,
    color: '#000000',
    fontWeight: 400
  },
  viewInventoryOption: {
    borderRight: "1px solid #BDBDBD",
    paddingHorizontal: 15
  },
  viewFinalDetails: {
    color: '#000000',
    border: "1px solid #AFAFAF",
    borderStyle: 'dashed',
    borderRadius: 8,
  }
})

export default DetailTicketPdf
