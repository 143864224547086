import { API_URL } from "../../../../../config/index";
import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const rolPath = "role/company/module"

//Endpoint de topia que permite recuperar los modulos activos para una cuenta 
export const getAvailableModulesRoles = async ({
    token,
    company_id,
}) => {
    try {
        const params = {
            company_id,
        };

        const response = await axiosCreate.get(`${API_URL}${rolPath}`, {
            params, timeout: 15000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }
};