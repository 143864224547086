import { useContext } from "react"
import { dataToFormData } from "../../../../helpers/dataToFormData"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { createContract, getAllMapFiles, getAllTicketsByPlace, getContractsFiles, getDetailPlaceTaxData, getPersonHoodTypes, getTypesContract, updateContracts } from "../../../../store/detailContracts/thunks"
import { AdminContractsContext } from "../context/AdminContractsContext"

const useContracts = () => {
  const dispatch = useAppDispatch()

  const { fileListContracts } = useContext(AdminContractsContext)
  const { idClickedPlace } = useAppSelector(state => state.malls)

  const getInitialData = async () => {
    dispatch(await getContractsFiles(idClickedPlace))
    dispatch(await getTypesContract())
  }

  // Funcion para realizar peticiones en cada evento onclick de los botones
  const verifyTypeRequest = async (currentOption) => {
    switch (currentOption) {
      case "1":
        dispatch(await getContractsFiles(idClickedPlace))
        dispatch(await getTypesContract())
        break
      case "2":
        dispatch(await getDetailPlaceTaxData(idClickedPlace))
        dispatch(await getPersonHoodTypes())
        break
      case "3":
        dispatch(await getAllTicketsByPlace({ placeId: idClickedPlace }))
        break
      case "4":
        dispatch(await getAllMapFiles(idClickedPlace))
        break
      default:
        break
    }
  }

  const onSaveContract = async (formData) => {
    const isPlaceFileId = formData?.place_file_id ? { place_file_id: formData.place_file_id }  : {}
    const functionToExecute = formData?.place_file_id ? updateContracts : createContract

    const dataToSend = dataToFormData({
      ...isPlaceFileId,
      name: formData.name,
      date_begin: (formData.date_begin).format("YYYY-MM-DD"),
      date_end: (formData.date_end).format("YYYY-MM-DD"),
      file_sub_type_id: formData?.file_sub_type_id[0]?.value || formData?.file_sub_type_id,
      file: fileListContracts[0]?.originFileObj ? fileListContracts[0].originFileObj : undefined,
      place_id: idClickedPlace,
      place_m2_size: parseFloat(formData.place_m2_size)
    })

    if (formData?.place_per_m2_maintenance_price) {
      dataToSend.append('place_per_m2_maintenance_price', parseFloat(formData.place_per_m2_maintenance_price))
    }
    
    if (formData?.place_maintenance_increase_percentage) {
      dataToSend.append('place_maintenance_increase_percentage', parseFloat(formData.place_maintenance_increase_percentage))
    }

    if (formData?.place_rent_increase_percentage) {
      dataToSend.append('place_rent_increase_percentage', parseFloat(formData.place_rent_increase_percentage))
    }

    if (formData?.place_per_m2_rent_price) {
      dataToSend.append('place_per_m2_rent_price', parseFloat(formData.place_per_m2_rent_price))
    }

    if (formData?.place_variable_rent) {
      dataToSend.append('place_variable_rent', parseFloat(formData.place_variable_rent))
    }

    const response = await dispatch(functionToExecute(dataToSend))

    return response
  }

  const getAllContractsFiles = async () => {
    dispatch(await getContractsFiles(idClickedPlace))
  }

  return {
    onSaveContract,
    getInitialData,
    verifyTypeRequest,
    getAllContractsFiles
  }
}

export default useContracts