import { Col, Row } from "antd"
import { CaretDownOutlined } from "@ant-design/icons"
import DetailBuildingLevel from "./DetailBuildingLevel"
import { setIdLevelSelected } from "../../../../store/malls/mallsSlice"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import CustomTreeSelect from "../../../UI/CustomSelects/CustomTreeSelect"
import { getPlaceAnalytics, getSvgsElementsToBuilMap } from "../../../../store/malls/thunks"

const HeaderActions = () => {
  const dispatch = useAppDispatch()

  const { companyName } = useAppSelector(state => state.auth)
  const { treeSelectData } = useAppSelector(state => state.malls)
    
  // Evento onChange para cambiar de mapa y actualizar el detalle del nivel y de los elementos svg
  const handleOnChangeSketchs = async (idBuildingSketch) => {
    dispatch(setIdLevelSelected(idBuildingSketch))

    if (idBuildingSketch) {
      await dispatch(await getSvgsElementsToBuilMap(idBuildingSketch))
      await dispatch(await getPlaceAnalytics(idBuildingSketch))
    }
  }

  return (
    <header>
      <Row gutter={16} align="middle">
        <Col span={8}>
          <h3 style={{ fontWeight: 700, fontSize: 16, fontFamily: 'Roboto', color: "#1C2C44" }}>
            CC {companyName}
          </h3>

          <CustomTreeSelect
            allowClear
            suffixIcon={<CaretDownOutlined style={{ color: '#818181' }} />}
            isToForm={false}
            name="place_id"
            treeData={treeSelectData ?? []}
            className="standard-select-mall"
            defaultValue={(treeSelectData[0]?.value) ?? ''}
            placeholder="Por favor seleccione un nivel"
            onChange={handleOnChangeSketchs}
          />
        </Col>

        <Col span={12} offset={4}>
          <DetailBuildingLevel />
        </Col>
      </Row>
    </header>
  )
}

export default HeaderActions