import axios from "axios";

const createNewPlaceHierarchyAdmin = async (URL_DEV,idToken, formData) => {
  try {
    const response = await axios.post(`${URL_DEV}/place`,
    formData,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    return {
      success: true,
      ...response
    };
  } catch (error) {
    return {
      success: false,
      ...error.response
    };
  }
};

export default createNewPlaceHierarchyAdmin;
