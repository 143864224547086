import { Row, Spin } from "antd";

const SpinLoader = ({ size = "middle", label = 'Cargando datos...' }) => (
  <div className="spin-loader">
    <Row justify="center">
      <Spin tip={label} size={size} />
    </Row>
  </div>
);

export default SpinLoader;
