import { useEffect, useState } from "react"
import {
  Col,
  Row,
  Dropdown,
  Menu,
  Space,
} from "antd"
import { CustomSelect } from '../UI/CustomSelect'
import useRedirecto from '../hooks/useRedirectTo'
// import useOpenCloseModal from "../hooks/useOpenCloseModal"
import { setInitialState } from "../../store/malls/mallsSlice"
import { useAppDispatch, useAppSelector } from "../../hooks/store"
// import { getQueryDocByCollection } from "../../helpers/globalHelper";
import { getAccessActiveVisitors } from "../../helpers/accessHelper"
// import { getMobileStatus } from "../../helpers/agentTrackerHelper";
// import AgentTrackMapModal from "../components/modal/AgentTrackMapModal";
import { changeUserRol, checkingAuthentication, startLogout } from "../../store/auth"

// archivos o imagenes svg
import { DownOutlined } from "@ant-design/icons"
import avatar from "../../assets/icons_png/user-profile.png"

import './style.css'

export const NavBar = () => {
  const dispatch = useAppDispatch()
  const { roles, profileId, committee } = useAppSelector(state => state.auth)
  const { themeColors } = useAppSelector((state) => state.theme)
  const currentCompanyId = useAppSelector((state) => state?.auth?.currentRol)
  
  const [rolesVerified, setRolesVerified] = useState([])
  // const [searchBarUsers, setSearchBarUsers] = useState([])
  // const [userSearchList, setUserSearchList] = useState([])
  const [activeVisitorList, setActiveVisitorList] = useState([])
  const [dropdownBackgrounds, setDropdownBackgrounds] = useState([{ bgColor: '#FFF', color: "#000" }])
  // const [agentData, setAgentData] = useState([])
  
  const { handleRedirectTo } = useRedirecto()
  // const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  // Prepare data
  useEffect(() => {
    const intervalVisitors = setInterval(() => getActiveVisitorList(), 300000)

    if (roles) {
      getActiveVisitorList()
      const rolesToSelect = roles ? roles?.map((company) => ({
        ...company,
        id: company.companyId,
        name: company.companyName
      })) : []
      setRolesVerified(rolesToSelect)
    }

    return () => clearInterval(intervalVisitors)
  }, [roles])

  const handleChangeRoleApp = async (value, option) => {
    dispatch(changeUserRol({
      currentRol: value,
      typeRol: option.companyType,
      companyName: option.companyName,
      handleRedirectTo,
      residential_id: option.residentialId,
      currentEmployee: option.employeeId,
      profileId: profileId,
      currencySymbol: option.currencySymbol
    }))
    dispatch(checkingAuthentication())
    handleRedirectTo('/')
    dispatch(setInitialState())
  }

  // useEffect(() => {
  //   /* const getAdminData = async (id) => {
  //     const adminD = await getUserDataProfileById(id).then((user) => setAdminData(user))
  //   } */

  //   // getAgentData();
  //   //getAdminData(id);
  // }, []);

  const getActiveVisitorList = async () => {
    const response = await getAccessActiveVisitors(committee?.residential_id, new Date())

    // Filtramos que los usuarios no esten repetidos en el array
    const uniqueUsersArray = response.filter((user, index) => index === response.findIndex(object => user.profile_id === object.profile_id))

    setActiveVisitorList(uniqueUsersArray)
  }

  // const getAgentData = async () => {
  //   const agentD = await getMobileStatus().then((result) =>
  //     setAgentData(result)
  //   );
  // };

  const handleLogout = () => {
    dispatch(startLogout('checking-login'));
  };

  // useEffect(() => {
  //   let reportUserProfiles = getQueryDocByCollection(
  //     "profile",
  //     "residentialsIds",
  //     "array-contains",
  //     "Yd5MV7jkA0MjQeCmzKrZ" // Tuscania ID
  //   ).then((searchBarUsers) => setSearchBarUsers(searchBarUsers));
  // }, []);

  // searchBarUsers.map(async (item, index) => {
  //   /* userSearchList.push({ label: item.name, value: item.id }); */
  //   let fullname = item.name + " " + item.lastname;

  //   userSearchList.push(
  //     <Option key={index} value={item.id} label={fullname}>
  //       {/* <a href={"/reporte-actividad/" + item.id}>{fullname}</a> */}
  //       {fullname}
  //     </Option>
  //   );
  //   return item;
  // });

  const handleChangeBackground = ({ key, bgColor, color }) => () => {
    setDropdownBackgrounds({ ...dropdownBackgrounds, [key]: { bgColor, color } })
  }

  const menuItems = [{
    key: 0,
    label: "Cerrar sesión",
    onClick: handleLogout,
  }]

  const menu = (
    <Menu
      className="custom-navbar-menu"
      style={{ backgroundColor: "#FFF" }}
      items={menuItems.map((item) => ({
        key: item.key,
        style: { backgroundColor: dropdownBackgrounds[item.key].bgColor, color: dropdownBackgrounds[item.key].color },
        onMouseEnter: handleChangeBackground({ key: item.key, bgColor: themeColors.primary.primary, color: '#FFF' }),
        onMouseLeave: handleChangeBackground({ key: item.key, bgColor: '#FFF', color: "#000" }),
        label: (
          <button onClick={item.onClick}>
            {item.label}
          </button>
        ),
      }))}
    />
  );

  return (
    <>
      <Row>
        <Col span={24}>
          <div className="navbar">
            <Row gutter={16}>
              <Col span={5} >
                <CustomSelect
                  showSearch={false}
                  value={parseInt(currentCompanyId)}
                  options={rolesVerified}
                  onSelect={handleChangeRoleApp}
                />
              </Col>
              {/* <Col span={11} sm={5} className="search-navbar">
                <div className="container-search">
                   <Select
                    placeholder="Buscar por nombre"
                    style={{
                      width: "100%",
                    }}
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(id) => {
                      goToUser(id);
                    }}
                  >
                    {userSearchList}
                  </Select>
                </div>
              </Col>
              <Col span={4} sm={7} className="option-navbar track">
                {/* <span
                  className="item"
                  style={{ cursor: "pointer" }}
                  onClick={() => 
                    handleOpenCloseModal()
                  }
                >
                  <img
                    className="tracking"
                    src="/assets/images/tracking-icon.png"
                    alt=""
                  />
                  Track seguridad
                </span>
              </Col> */}
              <Col span={4} offset={10} className="option-navbar visited">
                <span className="item">
                  Visitantes del día
                  {activeVisitorList ? (
                    <span className="counter">
                      {activeVisitorList.length}
                    </span>
                  ) : (
                    <span className="counter">
                      0
                    </span>
                  )}
                </span>
              </Col>
              <Col span={5} className="option-navbar">
                <span className="item">
                  <Dropdown className="custom-navbar-dropdown" trigger={["click"]} overlay={menu}>
                    <button onClick={(e) => e.preventDefault()}>
                      <Space>
                        Administrador
                        <img
                          className="img-profile"
                          src={avatar}
                          alt=""
                        />
                        <DownOutlined />
                      </Space>
                    </button>
                  </Dropdown>
                </span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {/* <AgentTrackMapModal
        agents={agentData}
        isOpenModal={isOpenModal}
        onCancel={handleOpenCloseModal}
      /> */}
    </>
  );
};
