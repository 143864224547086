import DetailTaskPdf from "../DetailTaskPdf"
import { Button, Col, Row, Space } from "antd"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { useContext, useEffect, useState } from "react"
import { CustomSelect } from "../../../../../../../UI/CustomSelect"
import useDetailChecklist from "../../../../hooks/useDetailChecklist"
import { CustomNavigate } from "../../../../../../../UI/CustomNavigate"
import CustomFilePdf from "../../../../../../../UI/CustomFiles/CustomFilePdf"
import useCurrentLocation from "../../../../../../../hooks/useCurrentLocation"
import { HandoverChecklistContext } from "../../../../context/HandoverChecklistContext"

//imagenes o svg
import SignIcon from '../../../../../../../../assets/icons/Generals/sign-icon.svg'
import { ArrowsAltOutlined, PrinterOutlined, ShrinkOutlined } from "@ant-design/icons"

const HeaderActionsDetails = ({
  handleOpenCloseModal,
  isCurrentBody,
  handleCurrentBody,
  handleExpandAll,
  handleCollapseAll,
}) => {
  const [statusIdSelected, setStatusIdSelected] = useState()
  const [categoryIdSelected, setCategoryIdSelected] = useState()

  const { param } = useCurrentLocation()

  const {
    loading,
    checklistCategories,
    detailChecklistById,
    setLoadingTableDetail,
    checklistStatusReviews,
    detailChecklistByIdToPdf,
  } = useContext(HandoverChecklistContext)

  const { getAllData, getDetailChecklistById } = useDetailChecklist()

  const isChecklistSign = Boolean(detailChecklistById?.checklistInstanceDetail?.sign_url)

  useEffect(() => {
    getAllData()
  }, [])

  const tasksByCategory = (checklistCategories?.map((category) => {
    const filteredTasksByCategory = detailChecklistByIdToPdf?.dataSource?.filter((item) => item.category === category.name)
    
    return {
      ...category,
      tasks: filteredTasksByCategory,
    }
  }))

  //Prepare data
  const statusToSelect = checklistStatusReviews.map((status) => ({ id: status.id, name: status.name }))
  const categoriesToSelect = checklistCategories.map((category) => ({ id: category.id, name: category.name }))

  const handleOnChangeSelectCategory = async (categoryId) => {
    setLoadingTableDetail(true)
    setCategoryIdSelected(categoryId)
    await getDetailChecklistById(param.checklistTaskId, categoryId, statusIdSelected)
    setLoadingTableDetail(false)
  }

  const handleOnChangeSelectStatus = async (statusId) => {
    setLoadingTableDetail(true)
    setStatusIdSelected(statusId)
    await getDetailChecklistById(param.checklistTaskId, categoryIdSelected, statusId)
    setLoadingTableDetail(false)
  }

  if (loading) return null

  return <header>
    <Row>
      <Col span={24}>
        <CustomNavigate customRedirectTo={isCurrentBody} customCondition={handleCurrentBody} title={
          <h2 style={{ fontWeight: 600, fontSize: 24, margin:0 }}>
            {isCurrentBody ? 'CREAR TICKET GENERAL' : 'DETALLES DE ENTREGA'} /
            {" "}
            <span style={{ color: "var(--primary)" }}>{detailChecklistById?.checklistInstanceDetail?.place}</span>
          </h2>
        }/>

        <Row justify="space-between">
          <Col>
            <Space direction="horizontal">
              <p style={{ fontSize: 16, color: "#545454", fontWeight: 500, marginLeft: 50 }}>
                Asignado a:
                <span style={{ fontWeight: 400, fontSize: 14, color: "#696969" }}>
                  {' '}
                  {detailChecklistById?.checklistInstanceDetail?.employee}
                </span>
              </p>

              <p style={{ fontWeight: 500, fontSize: 16 }}>
                Fecha:
                {' '}
                <span style={{ fontWeight: 400, fontSize: 14, color: "#696969" }}>
                  {(detailChecklistById?.checklistInstanceDetail?.session?.date)} -
                  {' '}
                  {(detailChecklistById?.checklistInstanceDetail?.session?.timeBegin)}
                </span>
              </p>
            </Space>
          </Col>

          <Col>
            <Space direction="horizontal">
              <Button
                type="link"
                shape="round"
                loading={loading}
                onClick={handleOpenCloseModal}
                disabled={!isChecklistSign}
                style={{
                  height:40,
                  width:136,
                  border: '1px solid var(--primary',
                  opacity: (!isChecklistSign) ? 0.5 : 1
                }}
                icon={<img src={SignIcon} alt="sign-icon" />}
              >
                <span style={{ color: "#404040", fontWeight: 500, fontSize: 14 }}>VER FIRMA</span>
              </Button>

              {loading ? <span>Creando documento...</span> : <PDFDownloadLink
                document={
                  <CustomFilePdf footer multiplePages>
                    <DetailTaskPdf detailChecklistByIdToPdf={detailChecklistByIdToPdf} tasksByCategory={tasksByCategory} />
                  </CustomFilePdf>}
                fileName={`Checklist de entrega - ${((detailChecklistByIdToPdf?.checklistInstanceDetail?.place) ?? '')} - ${(detailChecklistByIdToPdf?.checklistInstanceDetail?.session?.date)}.pdf`}
              >
                {({ loading }) => (
                  <Button
                    type="link"
                    shape="round"
                    loading={loading}
                    style={{ height:40, width:126, border: '1px solid var(--primary' }}
                    icon={<PrinterOutlined style={{ color: "#000000", fontSize: 16 }} />}
                  >
                    <span style={{ color: "#404040", fontWeight: 500, fontSize: 14 }}>IMPRIMIR</span>
                  </Button>
                )}
              </PDFDownloadLink>
              }
            </Space>
          </Col>
        </Row>

        {!isCurrentBody && <Row gutter={18} align="bottom">
          <Col span={6}>
            <div style={{ fontFamily: 'Roboto', display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#FFFFFF", borderRadius: 8, padding: "16px 24px" }}>
              <label style={{ color: "#545454", fontWeight: 500, marginRight: 10, fontSize: 18 }}>
                CHECK NO SUPERADOS
              </label>
              <span style={{ fontWeight: 700, fontSize: 25, color: "#E82A2A" }}>
                {detailChecklistById?.checklistInstanceDetail?.failedReviews < 10
                  ? `0${detailChecklistById?.checklistInstanceDetail?.failedReviews}`
                  : `${detailChecklistById?.checklistInstanceDetail?.failedReviews}`}
              </span>
            </div>
          </Col>

          <Col span={6}>
            <Row justify="center">
              <CustomSelect
                allowClear
                size="middle"
                options={categoriesToSelect ?? []}
                className='standard-select'
                placeholder="Seleccione una categoría"
                onChange={handleOnChangeSelectCategory}
              />
            </Row>
          </Col>

          <Col span={6}>
            <Row justify="center">
              <CustomSelect
                allowClear
                size="middle"
                options={statusToSelect ?? []}
                className="standard-select"
                placeholder="Selecciones una opción"
                onChange={handleOnChangeSelectStatus}
              />
            </Row>
          </Col>
          <Col span={6}>
            <Row justify="center">
              <Space direction="horizontal">
                <Button
                  type="text"
                  shape="round"
                  onClick={handleCollapseAll}
                  style={{ width: 155, backgroundColor: "#FFFFFF", boxShadow: '0px 10px 14px 0px #0000000D', border: 'none' }}
                  icon={<ShrinkOutlined style={{ color: "var(--primary)", fontSize: 16 }} />}
                >
                  <span style={{ color: "var(--primary)", fontWeight: 500, fontSize: 14 }}>CONTRAER</span>
                </Button>

                <Button
                  type="text"
                  onClick={handleExpandAll}
                  shape="round"
                  style={{ width: 155, backgroundColor: "#FFFFFF", boxShadow: '0px 10px 14px 0px #0000000D', border: 'none' }}
                  icon={<ArrowsAltOutlined style={{ color: "var(--primary)", fontSize: 16 }} />}
                >
                  <span style={{ color: "var(--primary)", fontWeight: 500, fontSize: 14 }}>EXPANDIR</span>
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>}
      </Col>
    </Row>
  </header>
}


export default HeaderActionsDetails
