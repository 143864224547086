import axios from "axios"

const getConversationByIdTicket = async (idTicket) => {
  try {
    const response = await axios.get(`https://domo.topiaapp.com/api/conversations/ticket?ticket_id=${idTicket}`)

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getConversationByIdTicket