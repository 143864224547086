import { useEffect } from 'react';
import { Button, Input } from 'antd'
import CustomModal from '../../../../../UI/CustomModal';
import { useCategories } from '../../hooks/useCategories';
import { CustomSpin } from '../../../../../UI/CustomSpin';
import { CustomInput } from '../../../../../UI/CustomInput';
import { CustomNavigate } from '../../../../../UI/CustomNavigate';
import CustomTable from '../../../../../UI/CustomTable/CustomTable';
import { useFeatureRole } from '../../../../../hooks/useFeatureRole';
import CustomPopoverConfirm from '../../../../../UI/CustomPopoverConfirm';
import { ADMIN_CHECK_CODES, ADMIN_CHECK_CODES_NAMES } from '../../../../../../constants/modules/adminCheck/adminCheckCodes';

import './categoryStyle.css'

export const Categories = () => {
    // Lógica del componente
    const {
        activeFeatures, loading, columns, categories, limitTable, quantityTable, currentPageTable, openModal,
        FormCreate, FormEdit, formCreate, formEdit, onCreateCategory, onUpdateCategory, setOpenModal,
        getCategoriesService, handleChangeTable,
    } = useCategories()
    
    const { loadingFeatures } = useFeatureRole({ feature_codes: ADMIN_CHECK_CODES, validate_code: ADMIN_CHECK_CODES_NAMES.ADMIN_CHECK_MANAGEMENT_TASK_CATEGORIES, returnUrl: '/checklist/admin' })
    
    useEffect(() => {
        getCategoriesService({});
    }, [])

    if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

    return (
        <section className='section-wrapper' style={{ height: '100vh' }}>
            <CustomSpin tip="Cargando" size="large" customIcon={false} section={true} spinning={loading}>
                <CustomNavigate title={'CATEGORÍAS'} />
                <div className='category__create-category'>
                    <h2>CREAR NUEVA CATEGORÍA</h2>
                    <FormCreate form={formCreate} onFinish={onCreateCategory} className='category__create-category__form'>
                        <FormCreate.Item rules={[{ required: true, message: 'El nombre no puede estar vacío' }]} name={'name'}>
                            <CustomInput
                                allowClear
                                type={'TEXT'}
                                onChange={(e) => { }}
                                placeholder="Nombre de la categoría"
                            />
                        </FormCreate.Item>
                        <div style={{ width: 250 }} >
                            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_NEW_CATEGORY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nueva categoría'}`}>
                                <Button disabled={!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_NEW_CATEGORY)?.enabled} loading={loading} className='btn-standard-green' htmlType='submit' shape="round">
                                    NUEVA CATEGORÍA
                                </Button>
                            </CustomPopoverConfirm>
                        </div>
                    </FormCreate>
                </div>
                <div>
                    <CustomTable
                        className='admincheck-table'
                        columns={columns}
                        dataSource={categories}
                        pagination={{
                            defaultCurrent: 1,
                            pageSize: limitTable || undefined,
                            total: quantityTable || undefined,
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} de ${total}`;
                            },
                            defaultPageSize: limitTable || 10,
                            current: currentPageTable || undefined,
                            position: ["bottomRight"],
                        }}
                        onChange={handleChangeTable}
                    />
                </div>
                <CustomModal wrapClassName="category__edit-modal" className='' title={'EDITAR CATEGORÍA'} destroyOnClose={true} isOpenModal={openModal} onCancel={() => setOpenModal((prev) => !prev)}>
                    <FormEdit preserve={false} className='category__edit-modal__form' onFinish={onUpdateCategory} form={formEdit}>
                        <FormEdit.Item name="category_id" noStyle>
                            <Input hidden />
                        </FormEdit.Item>
                        <FormEdit.Item rules={[{ required: true, message: 'El nombre no puede estar vacío' }]} name={'name'}>
                            <CustomInput placeholder={'Limpieza general...'} type={'TEXT'} />
                        </FormEdit.Item>
                        <Button loading={loading} htmlType='submit' className='btn-standard-green' shape='round'>ACTUALIZAR</Button>
                    </FormEdit>
                </CustomModal>
            </CustomSpin>
        </section>
    );
};