import { Badge } from "antd";
import { grayStatusIcon, greenStatusIcon, redStatusIcon } from "../assets";

export const statusColor = [
    {
        id: '#10B48C',
        name: <div><Badge color="#10B48C" />Acceso Programado</div>
    },
    {
        id: '#FE797E',
        name: <div><Badge color="#FE797E" />Acceso no registrado</div>
    },
    {
        id: '#BDBDBD',
        name: <div><Badge color="#BDBDBD" />Acceso registrado</div>
    },
]

export const statusColorObj = {
    '#10B48C': { name: 'Acceso Programado', url: greenStatusIcon },
    '#FE797E': { name: 'Acceso no registrado', url: redStatusIcon },
    '#BDBDBD': { name: 'Acceso registrado', url: grayStatusIcon },
}