import { createMaintenanceReport } from '../../services/POST/createMaintenanceReport'
import { getProviderById } from '../../services/GET/getProviderById'
import verifyResponse from '../../../../helpers/verifyResponse'
import { useAppSelector } from '../../../../../hooks/store'
import updateCompanyAssetReport from '../../services/PUT/updateCompanyAssetReport'

export const useMaintenanceReportServices = () => {
    const { idToken } = useAppSelector(state => state.auth)

    const createMaintenanceReportService = async ({ formData, setLoading }) => {
        const res = await createMaintenanceReport({ token: idToken, formData })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        if (!status) {
            setLoading && setLoading(false)
            throw new Error('Error')
        }
        return res.data.data
    }

    //  este servicio ya no
    const getProviderByIdService = async ({ provider_id, setLoading }) => {
        const res = await getProviderById({ token: idToken, provider_id })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        if (!status) {
            setLoading && setLoading(false)
            throw new Error('Error')
        }
        return res.data.data
    }

    const updateCompanyActiveReport = async (formData) => {
      const response = await updateCompanyAssetReport(formData, idToken)

      return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Reporte de activo actualizado o creado con éxito' })
    }

    return {
        updateCompanyActiveReport,
        createMaintenanceReportService,
        getProviderByIdService,
    }
}