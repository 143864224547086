import { getExcelTemplate, postUploadExcel } from "../services";
import verifyResponse from "../../../../../helpers/verifyResponse";
import { useState } from "react";

const useGeneral = () => {
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const getTemplateExcel = async () => {
    setIsDownloadLoading(true);

    const response = await getExcelTemplate();

    setIsDownloadLoading(false);

    return verifyResponse(
      response.data,
      response.status,
      "Template descargado con exito"
    );
  };

  const uploadExcel = async (formData) => {
    const response = await postUploadExcel(formData);

    return verifyResponse(
      response.data,
      response.status,
      "Excel subido con exito"
    );
  };

  return {
    isDownloadLoading,
    uploadExcel,
    getTemplateExcel,
  };
};

export default useGeneral;
