import axios from "axios";
import { API_URL } from "../../../../../../../config";

const tasksPath = "tasks";

export const createNewTask = async ({ token, formData }) => {

  try {
    const response = await axios.post(`${API_URL}${tasksPath}`, formData, {
      timeout: 10000,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      success: true,
      ...response,
    };
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        ...error.response,
      };
    }
    if (error.request) {
      return {
        success: false,
        status: error.code,
        data: {
          errors: [{ msg: error.message }],
        },
      };
    }
  }
};
