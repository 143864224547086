import { Form, Switch } from "antd"

const CustomSwitch = ({
  checked,
  className,
  disabled,
  loading,
  size,
  onChange,
  onClick,
  name,
  isToForm = false,
  defaultChecked = false,
  checkedChildren,
  unCheckedChildren
}) => {
  const { Item } = Form

  let body = (
    <Switch
      checked={checked}
      className={className}
      disabled={disabled}
      defaultChecked={defaultChecked}
      checkedChildren={checkedChildren}
      unCheckedChildren={unCheckedChildren}
      size={size}
      onChange={onChange}
      onClick={onClick}
      loading={loading}
    />
  )

  if (isToForm) (
    body = <Item name={name} valuePropName="checked">
      {body}
    </Item>
  )

  return body
}

export default CustomSwitch
