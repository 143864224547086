export const ADMIN_CHECK_CODES = {
    ADMIN_CHECK_MODULE: 'FEAT-290',
    ADMIN_CHECK_EDIT_TASK: 'FEAT-111',
    ADMIN_CHECK_COMMENTS: 'FEAT-113',
    ADMIN_CHECK_NEW_CATEGORY: 'FEAT-117',
    ADMIN_CHECK_UPDATE_CATEGORY: 'FEAT-119',
    ADMIN_CHECK_DELETE_CATEGORY: 'FEAT-120',
    ADMIN_CHECK_NEW_TASK: 'FEAT-133',
    ADMIN_CHECK_ACTIVITY_MONITORING: 'FEAT-263',
    ADMIN_CHECK_MANAGEMENT_TASK_CATEGORIES: 'FEAT-298',
    ADMIN_CHECK_DELETE_TASK: 'FEAT-299',
}

export const ADMIN_CHECK_CODES_NAMES = {
    ADMIN_CHECK_MODULE: 'ADMIN_CHECK_MODULE',
    ADMIN_CHECK_EDIT_TASK: 'ADMIN_CHECK_EDIT_TASK',
    ADMIN_CHECK_COMMENTS: 'ADMIN_CHECK_COMMENTS',
    ADMIN_CHECK_NEW_CATEGORY: 'ADMIN_CHECK_NEW_CATEGORY',
    ADMIN_CHECK_UPDATE_CATEGORY: 'ADMIN_CHECK_UPDATE_CATEGORY',
    ADMIN_CHECK_DELETE_CATEGORY: 'ADMIN_CHECK_DELETE_CATEGORY',
    ADMIN_CHECK_NEW_TASK: 'ADMIN_CHECK_NEW_TASK',
    ADMIN_CHECK_ACTIVITY_MONITORING: 'ADMIN_CHECK_ACTIVITY_MONITORING',
    ADMIN_CHECK_MANAGEMENT_TASK_CATEGORIES: 'ADMIN_CHECK_MANAGEMENT_TASK_CATEGORIES',
    ADMIN_CHECK_DELETE_TASK: 'ADMIN_CHECK_DELETE_TASK',
}