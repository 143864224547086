import { useContext } from "react"
import { createUpdateOrnamentChecklistItem, deleteOrnamentChecklistItem, getOrnamentChecklistItemsByCategory } from "../../../services"
import { AdminChecklistTasksContext } from "../context/AdminChecklistTasksContext"
import verifyResponse from "../../../../../../../helpers/verifyResponse"
import { useAppSelector } from "../../../../../../../../hooks/store"
import useCurrentLocation from "../../../../../../../hooks/useCurrentLocation"

const useAdminCheckListTasks = () => {
  const { idToken } = useAppSelector(state => state.auth)

  const {
    filters,
    setFilters,
    setLoading,
    currentPage,
    setCurrentPage,
    setMetadata,
    setChecklistTasksTable,
  } = useContext(AdminChecklistTasksContext)

  const { param } = useCurrentLocation()

  const getInitialData = async () => {
    const response = await getTasksByCategory({ ...filters, checklistCategoryId: param.checklistCategoryId })

    if (response.success) {
      setFilters(state => ({ ...state, checklistCategoryId: param.checklistCategoryId }))
    }
  }

  const getTasksByCategory = async ({
    checklistCategoryId,
    name,
    skip,
    limit,
    sortOrder,
    sortBy,
  }) => {
    setLoading(true)
    const response = await getOrnamentChecklistItemsByCategory({
      checklistCategoryId,
      name,
      skip,
      limit,
      sortOrder,
      sortBy,
      idToken,
    })

    if (response.success) {
      setChecklistTasksTable(response.data.data)
      setMetadata(response.data.metadata)
      setLoading(false)

      return response
    }

    setLoading(false)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  const onDeleteChecklistItem = async (formData) => {
    const response = await deleteOrnamentChecklistItem(formData, idToken)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Tarea eliminada con éxito' })
  }

  const onSaveChecklistItem = async (formData) => {
    const response = await createUpdateOrnamentChecklistItem(formData, idToken)

    const messageToShow = formData?.checklist_item_id ? 'editada' : 'creada'

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: `Tarea ${messageToShow} con éxito` })
  }

  const changePageHandler = async (currentPag, limit) => {
    const pageValue = currentPag || currentPage
    const newSkip = (pageValue * limit) - limit
    
    setCurrentPage(pageValue)

    await getTasksByCategory({ ...filters, skip: newSkip, limit })
    
    setFilters(state => ({ ...state, skip: newSkip, limit }))
  }

  return {
    getInitialData,
    changePageHandler,
    getTasksByCategory,
    onSaveChecklistItem,
    onDeleteChecklistItem,
  }
}

export default useAdminCheckListTasks