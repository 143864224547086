import { Col, Row } from "antd"
import { useAppSelector } from "../../../../hooks/store"
import useRedirectTo from "../../../hooks/useRedirectTo"

const DetailBuildingLevel = () => {
  const { handleRedirectTo } = useRedirectTo()
  const { placeAnalyticsData } = useAppSelector(state => state.malls)

  // Prepare data
  const totalPlaces = (placeAnalyticsData?.totalPlaces) ?? '00'
  const emptyPlaces = (placeAnalyticsData?.emptyPlaces) ?? '00'
  const endingContracts = (placeAnalyticsData?.endingContractPlaces) ?? '00'

  return (
    <Row>
      <Col span={24}>
        <Row
          align="middle"
          justify="space-between"
          className="detail-level-hover"
          style={{ border: '1px solid #B5B5B5', borderRadius: 8, backgroundColor: "#FFFFFF" }}
        >
          <Col
            span={8}
            style={{ borderRight: "1px solid #B5B5B5", padding: "12px 0", cursor: 'pointer' }}
            onClick={() => handleRedirectTo('/settings/users-profiles')}
          >
            <Row justify="center">
              <label style={{ color: "#252525", fontSize: 12, fontWeight: 500 }}>TOTAL LOCALES</label>
            </Row>
            <Row justify="center">
              <p style={{ color: "#252525", fontSize: 22, fontWeight: 600, margin: 0 }}>
                {(placeAnalyticsData?.totalPlaces) < 10
                  ? `0${placeAnalyticsData?.totalPlaces}`
                  : totalPlaces
                }
              </p>
            </Row>
          </Col>
          <Col
            span={8}
            style={{ borderRight: "1px solid #B5B5B5", padding: "12px 0", cursor: 'pointer' }}
            onClick={() => handleRedirectTo('/settings/managment-spaces')}
          >
            <Row justify="center">
              <label style={{ color: "#252525", fontSize: 12, fontWeight: 500 }}>LOCALES VACIOS</label>
            </Row>
            <Row justify="center">
              <p style={{ color: "#252525", fontSize: 22, fontWeight: 600, margin: 0 }}>
                {(placeAnalyticsData?.emptyPlaces) < 10
                  ? `0${placeAnalyticsData?.emptyPlaces}`
                  : emptyPlaces
                }
              </p>
            </Row>
          </Col>
          <Col span={8} style={{ padding: "12px 0" }}>
            <Row justify="center">
              <label style={{ color: "#252525", fontSize: 12, fontWeight: 500 }}>CONTRATOS POR VENCER</label>
            </Row>
            <Row justify="center">
              <p style={{ color: "#252525", fontSize: 22, fontWeight: 600, margin: 0 }}>
                {(placeAnalyticsData?.endingContractPlaces) < 10
                  ? `0${placeAnalyticsData?.endingContractPlaces}`
                  : endingContracts
                }
              </p>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default DetailBuildingLevel