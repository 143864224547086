import { Row, Col, Button, Divider, Input } from "antd";
import { CustomTimePicker } from "../../../../../../../../UI/CustomTimePicker";
import { emptyScheduleIcon, SavedChecklistIcon, } from "../../../../../../../../assets/icons";
import { useNewCheckList } from "../../../../../../../hooks/useNewCheckList";
import { daysMock, statusDay } from "../../../../../../../mocks/daysMock";
import { CustomCheckbox } from "../../../../../../../../../../UI/CustomCheckbox";
import moment from "moment";

export const ScheduleInfo = ({ field }) => {
  const {
    Form,
    form,
    handleChangeTimePicker,
    handleGeneralSchedule,
    handleDeleteWeekDay,
    handleSavedWeekDay,
    handleSelectWeekDay
  } = useNewCheckList();

  const generalSchedule = form.getFieldValue('schedule')[field.name]?.generalSchedule;

  const dayName = () => {
    const days = form.getFieldValue('schedule')[field.name]?.days;
    const dayName = days !== undefined ? days.find((day) => day.status === statusDay.SELECTED || day.status === statusDay.UNSAVED)?.fullDay : undefined;
    return (
      <>{generalSchedule?.length ? 'Todos los días' : dayName ? dayName : 'Selecciona un día'}</>
    )
  }

  const disabledInputs = () => {
    const days = form.getFieldValue('schedule')[field.name]?.days;
    const allSave = days !== undefined ? days.every((day) => (!day.notMap ? day.status === statusDay.SAVED : true)) : undefined;
    const disabled = days !== undefined ? days.find((day) => day.status === statusDay.SELECTED || day.status === statusDay.UNSAVED) : undefined;
    if (allSave) return false;
    if (disabled) return false;
    return true;

  }
  const days = JSON.parse(JSON.stringify(daysMock));   //Copia profunda para evitar modificar el array local para cada formlist de los botones
  return (
    <Col className="newChecklist__repeat-schedule">
      <Row className="newChecklist__repeat-schedule__init">
        <h4>REPETIR CADA</h4>
        <Form.Item
          style={{ width: "auto", margin: 0 }}
          name={[field.name, "generalSchedule"]}
          initialValue={[]}
        >
          <CustomCheckbox
            options={[{ label: "Todos los días", value: "1" }]}
            onChange={() => handleGeneralSchedule({ field })}
          />
        </Form.Item>
      </Row>
      <Row>
        {/**Validar si hay al menos un item en la lista con el estado SAVED */}

        <Form.List rules={[
          {
            validator: async (_, value) => {
              //Obtener los datos del mismo empleado si existe
              const selectedDay = value.some((day) => day.status === statusDay.SAVED || day.previousStatus === statusDay.SAVED);

              const sameEmployee = form.getFieldValue("schedule").map((item, index) => {
                const employee_id = item.employee_id === form.getFieldValue('schedule')[field.name].employee_id;
                const excludeIndex = index !== field.name
                if (employee_id && excludeIndex) {
                  return { days: item.days };
                }
              }).filter((item) => item !== undefined);

              //Recorrer todos los items de sameEmployee que y recorrer luego los days que tiene cada uno,
              //usar flatMap para devolver un solo array
              const error = sameEmployee.flatMap(employee => employee.days.map((item, index) => {
                if (index === employee.days.length - 1 && (item.time_begin === '' || item.time_end === '')) return false;

                const date1 = moment(item.time_begin);
                const date2 = moment(item.time_end)
                const date3 = moment(value[index].time_begin);
                const date4 = moment(value[index].time_end);
                const date5 = moment(value[!index ? employee.days.length - 2 : index - 1].time_begin);
                const date6 = moment(value[!index ? employee.days.length - 2 : index - 1].time_end);
                if (
                  ((date1.isValid()) && (date1.isBetween(date3, date4, undefined, '[)'))) ||
                  ((date1.isValid()) && (date1.isBetween(date5, date6, undefined, '[)'))) ||
                  ((date2.isValid()) && (date2.isBetween(date3, date4, undefined, '(]'))) ||
                  ((date2.isValid()) && (date2.isBetween(date5, date6, undefined, '(]'))) ||
                  ((date3.isValid()) && (date3.isBetween(date1, date2, undefined, '[)'))) ||
                  ((date3.isValid()) && (date3.isBetween(date5, date6, undefined, '[)'))) ||
                  ((date4.isValid()) && (date4.isBetween(date1, date2, undefined, '(]'))) ||
                  ((date4.isValid()) && (date4.isBetween(date5, date6, undefined, '(]'))) ||
                  ((date5.isValid()) && (date5.isBetween(date1, date2, undefined, '[)'))) ||
                  ((date5.isValid()) && (date5.isBetween(date3, date4, undefined, '[)'))) ||
                  ((date6.isValid()) && (date6.isBetween(date1, date2, undefined, '(]'))) ||
                  ((date6.isValid()) && (date6.isBetween(date3, date4, undefined, '(]')))
                ) {                  
                  return true;
                }
              })).filter((item) => item !== undefined);

              if (selectedDay) {
                //Comprobar si dentro del objeto days los time_begin y time_end son iguales                

                if (!error?.includes(true)) return Promise.resolve();
                return Promise.reject(new Error('Los horarios no pueden ser iguales o intersectarse si son del mismo empleado'));
              }
              return Promise.reject(new Error('Guarda al menos un día para el turno del empleado'));
            }
          }
        ]} name={[field.name, 'days']} initialValue={days}>
          {(fields, { add, remove }, { errors }) => (
            <div style={{ width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: 8, marginTop: 8 }}>
                {fields.map((subField) => {
                  const item = form.getFieldValue('schedule')[field.name].days[subField.name];
                  return (
                    <div key={subField.key} style={{ display: item.notMap ? 'none' : 'static' }}>
                      <Form.Item noStyle name={[subField.name, 'id']} initialValue={null}>
                        <Input hidden />
                      </Form.Item>
                      {!item.notMap && (<Button
                        disabled={generalSchedule?.length}
                        onClick={() => handleSelectWeekDay({ field, subField })}
                        style={
                          {
                            backgroundColor: item.status === statusDay.SAVED ? 'var(--primary)' : item.status === statusDay.UNSAVED ? '#FBC42C' : item.status === statusDay.SELECTED ? '#2C8BFB' : '#F5F8FF',
                            borderColor: 'transparent',
                            color: item.status === statusDay.NONE ? '#000' : '#fff',
                          }}
                        shape="circle">
                        {item.day}
                      </Button>)}
                    </div>
                  )
                })
                }
              </div>
              <Form.ErrorList errors={errors}></Form.ErrorList>
              <div id={`newChecklist_schedule_${field.name}_days`} /> {/*Generar un div para un error del Form.List*/}
            </div>
          )}
        </Form.List>
      </Row>
      <Divider
        className="custom-divider"
        orientation="left"
        orientationMargin={0}
      >
        {dayName()}
      </Divider>
      <Row gutter={16}>
        <Col className="standard-flex-center" span={6}>
          {/**Ahora los time picker deben de actualizarse segun el día seleccionado**/}
          <Form.Item
            {...field}
            name={[field.name, "time_begin"]}
            key={[field.key, "time_begin"]}
            label="Desde"
          >
            <CustomTimePicker
              disabled={disabledInputs()}
              allowClear={false}
              init={true}
              placeholder={"Hora de Inicio"}
              onChange={() => {
                handleChangeTimePicker({ field, timePicker: "time_begin" });
              }}
            />
          </Form.Item>
        </Col>
        <Col className="standard-flex-center" span={6}>
          {/**Este item se volvera a renderizar a partir del time picker time_begin */}
          <Form.Item
            shouldUpdate={(prev, cur) => {
              return (
                prev["schedule"][field.name]?.time_begin !==
                cur["schedule"][field.name]?.time_begin
              );
            }}
            noStyle
          >
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  {...field}
                  name={[field.name, "time_end"]}
                  key={[field.key, "time_end"]}
                  label="Hasta"
                >
                  <CustomTimePicker
                    onChange={() => {
                      handleChangeTimePicker({ field, timePicker: "time_end" });
                    }}
                    disabled={
                      disabledInputs()
                    }
                    startHour={moment(
                      getFieldValue("schedule")[field.name]?.time_begin
                    ).hours()}
                    startMinute={moment(
                      getFieldValue("schedule")[field.name]?.time_begin
                    ).minutes()}
                    placeholder={"Hora de Fin"}
                    init={false}
                    allowClear={false}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Form.Item
          shouldUpdate={(prev, cur) => {
            return (
              prev["schedule"][field.name]?.generalSchedule !==
              cur["schedule"][field.name]?.generalSchedule
            );
          }}
          noStyle
        >
          {({ getFieldValue }) => {
            return (
              <>
                {!getFieldValue("schedule")[field.name]?.generalSchedule[0] && (
                  <>
                    <Col className="standard-flex-center" span={10}>
                      <Button
                        disabled={disabledInputs()}
                        className="btn-standard-white"
                        style={{ width: "100%" }}
                        shape="round"
                        icon={<SavedChecklistIcon className="btn-svg-icon" />}
                        onClick={() => { handleSavedWeekDay({ field }) }}
                      >
                        Guardar Horario
                      </Button>
                    </Col>
                    <Col span={2} className="standard-flex-center">
                      <Button
                        disabled={disabledInputs()}
                        className="standard-flex-center"
                        onClick={() => { handleDeleteWeekDay({ field }) }}
                        icon={<img src={emptyScheduleIcon} alt="empty-schedule" />}
                        style={{
                          border: "none",
                          marginBottom: 0,
                        }}
                      />
                    </Col>
                  </>
                )}
              </>
            );
          }}
        </Form.Item>
      </Row>
    </Col>
  );
};
