import { Col, Image, Row } from "antd"
import { useContext } from "react"
import { ChecklistResultsContext } from "../context/ChecklistResultsContext"

const ChecklistCategoryDetail = () => {
  const { checklistResultsDetails } = useContext(ChecklistResultsContext)

  return (
    <Row>
      <Col span={24}>
        <h3 style={{ fontWeight: 600, color: '#232C4A', fontSize: 16 }}>
          {checklistResultsDetails?.general?.category_name}
        </h3>

        <Row>
          <label style={{ color: '#16A07F', fontWeight: 400, fontSize: 16, marginBottom: 20 }}>
            Detalle de categoría
          </label>
        </Row>

        <Row>
          <Col>
            <label style={{ color: '#232C4A', fontWeight: 600, fontSize: 14 }}>
              Comentario
            </label>

            <p style={{ color: '#404040', fontSize: 12, fontWeight: 400, marginTop: 10, maxHeight: 200, overflow:'auto', marginBottom: 20 }}>
              {checklistResultsDetails?.review?.comment
                ? checklistResultsDetails?.review?.comment
                : "No se agregó un comentario"
              }
            </p>
          </Col>
        </Row>

        <Row style={{ marginBottom: 20 }}>
          <Col>
            <label style={{ color: '#232C4A', fontWeight: 600, fontSize: 14 }}>
              Fotografías
            </label>

            <Row wrap={false} gutter={[16, 16]} style={{ overflow: 'auto', whiteSpace: 'nowrap', marginTop: 10 }}>
              {checklistResultsDetails?.review?.images?.length > 0
                ? (checklistResultsDetails.review.images.map((imageData, index) => (
                  <Col key={`${imageData}-${index}`} >
                    <Image
                      src={imageData}
                      width={88}
                      height={89}
                      style={{ borderRadius: 8, objectFit: 'cover' }} alt={`image-${index}`}
                    />
                  </Col>)))
                : <p style={{ paddingLeft: 8, color: '#404040', fontSize: 12, fontWeight: 400 }}>
                    No se agregaron imagenes
                  </p>
              }
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label style={{ color: '#232C4A', fontWeight: 600, fontSize: 14 }}>
              Detalles de categoría
            </label>

            <ul style={{ listStyleType: 'none', padding: 0, marginTop: 10 }}>
              {checklistResultsDetails?.item_quantities?.map((itemQuantity, index) => (
                <li key={`${itemQuantity.name}-${index}`}>
                  <Row justify="space-between" style={{ margin: '5px 0' }}>
                    <label style={{ textTransform: 'uppercase', fontWeight: 400, fontSize: 12, color: '#545454' }}>
                      {itemQuantity.name}
                    </label>

                    <span style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: 11, color: '#252525' }}>
                      {itemQuantity.status_count}
                    </span>
                  </Row>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ChecklistCategoryDetail
