
export const TICKETS_CODES = {
    TICKETS_MODULE: 'FEAT-253',
    TICKETS_USERS_TICKETS: 'FEAT-8',
    TICKETS_DETAIL_TICKET: 'FEAT-18',
    TICKETS_REJECT_STATUS_TICKET: 'FEAT-19',
    TICKETS_REVIEWED_STATUS_TICKET: 'FEAT-20',
    TICKETS_CATEGORY_TICKET: 'FEAT-22',
    TICKETS_UPDATE_INSPECTION_SCHEDULE: 'FEAT-24',
    TICKETS_UPDATE_REQUESTED_INVENTORY: 'FEAT-26',
    TICKETS_APPROVE_INSPECTION: 'FEAT-27',
    TICKETS_UPDATE_WORKDAY: 'FEAT-28',
    TICKETS_FINISH_TICKET: 'FEAT-157',
    TICKETS_ADMIN_TICKETS: 'FEAT-254',
    TICKETS_NEW_ADMIN_TICKET: 'FEAT-260',
    TICKETS_CALENDAR: 'FEAT-304',
    TICKETS_ASSIGN_TICKET: 'FEAT-336',
    TICKETS_DOCUMENTS_TICKET: 'FEAT-337',
    TICKETS_CHAT_TICKET: 'FEAT-338',
    TICKETS_MANAGEMENT_CATEGORIES: 'FEAT-436',
    TICKETS_NEW_CATEGORY_TICKET: 'FEAT-437',
    TICKETS_NEW_SUBCATEGORY_TICKET: 'FEAT-438',
    TICKETS_UPDATE_SUBCATEGORY_TICKET: 'FEAT-439',
    TICKETS_DELETE_SUBCATEGORY_TICKET: 'FEAT-440',
}

export const TICKETS_CODES_NAME = {
    TICKETS_MODULE: 'TICKETS_MODULE',
    TICKETS_USERS_TICKETS: 'TICKETS_USERS_TICKETS',
    TICKETS_DETAIL_TICKET: 'TICKETS_DETAIL_TICKET',
    TICKETS_REJECT_STATUS_TICKET: 'TICKETS_REJECT_STATUS_TICKET',
    TICKETS_REVIEWED_STATUS_TICKET: 'TICKETS_REVIEWED_STATUS_TICKET',
    TICKETS_CATEGORY_TICKET: 'TICKETS_CATEGORY_TICKET',
    TICKETS_UPDATE_INSPECTION_SCHEDULE: 'TICKETS_UPDATE_INSPECTION_SCHEDULE',
    TICKETS_UPDATE_REQUESTED_INVENTORY: 'TICKETS_UPDATE_REQUESTED_INVENTORY',
    TICKETS_APPROVE_INSPECTION: 'TICKETS_APPROVE_INSPECTION',
    TICKETS_UPDATE_WORKDAY: 'TICKETS_UPDATE_WORKDAY',
    TICKETS_FINISH_TICKET: 'TICKETS_FINISH_TICKET',
    TICKETS_ADMIN_TICKETS: 'TICKETS_ADMIN_TICKETS',
    TICKETS_NEW_ADMIN_TICKET: 'TICKETS_NEW_ADMIN_TICKET',
    TICKETS_CALENDAR: 'TICKETS_CALENDAR',
    TICKETS_ASSIGN_TICKET: 'TICKETS_ASSIGN_TICKET',
    TICKETS_DOCUMENTS_TICKET: 'TICKETS_DOCUMENTS_TICKET',
    TICKETS_CHAT_TICKET: 'TICKETS_CHAT_TICKET',
    TICKETS_MANAGEMENT_CATEGORIES: 'TICKETS_MANAGEMENT_CATEGORIES',
    TICKETS_NEW_CATEGORY_TICKET: 'TICKETS_NEW_CATEGORY_TICKET',
    TICKETS_NEW_SUBCATEGORY_TICKET: 'TICKETS_NEW_SUBCATEGORY_TICKET',
    TICKETS_UPDATE_SUBCATEGORY_TICKET: 'TICKETS_UPDATE_SUBCATEGORY_TICKET',
    TICKETS_DELETE_SUBCATEGORY_TICKET: 'TICKETS_DELETE_SUBCATEGORY_TICKET',
}