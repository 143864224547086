import { Progress } from "antd";
import { showDecimalsNumber } from "../../../helpers/showDecimalsNumber";

export const CustomProgress = ({
  textColor = "#E4B228",
  className = 'custom-progress',
  fontSize = 60,
  width = 450,
  height = 450,
  marginBottom = -225,
  percent = 0,
  strokeColor = "#FBC42C",
  trailColor = "#FEEDBE",
  type = 'dashboard',
  customText,
  strokeLinecap = 'square'
}) => (
  <Progress
    width={width}
    style={{ maxWidth: width, width: width, marginBottom, height }}
    className={className === 'custom-progress' ? `custom-progress ${type === 'dashboard' ? 'dashboard' : ''}` : className}
    showInfo={true}
    format={(percent) => (
      <span style={{ color: textColor, fontWeight: 600, fontSize }}>{customText ? customText : `${isNaN(percent) ? 0 : percent}%`}</span>
    )}

    strokeColor={{ "0%": strokeColor, "100%": strokeColor }}
    type={type}
    strokeLinecap={strokeLinecap}
    trailColor={trailColor}
    percent={isNaN(percent) ? 0 : showDecimalsNumber({ number: percent })}
    gapDegree={type === 'dashboard' ? 180 : 0}
  />
);
