import '../style.css'
import CustomDrawer from "../../../../UI/CustomDrawer"

const AboutUs = ({ isOpenDrawer, handleOpenCloseDrawer }) => (
  <aside>
    <CustomDrawer
      width={450}
      placement="right" 
      isOpenDrawer={isOpenDrawer}
      onClose={handleOpenCloseDrawer}
    >
      <header>
        <h3 style={{ color: '#232C4A' }}>
          BIENVENIDO A <br />
          <span style={{ fontWeight: 500, fontSize: "22px", color: 'var(--primary)' }}>TOPIA CONDOMINIOS</span>
        </h3>
      </header>
      <p style={{ color: '#707070' }}>
        Para iniciar la implementación de los servicios Topia, es necesario contar
        con la nomenclatura de su residencial, para garantizará el proceso de registro
        de cada residente.
      </p>

      <h4 style={{ fontWeight: 500, color: 'var(--primary)' }}>¿Qué es una zona?</h4>
      <p style={{ color: '#707070' }}>
        Una zona puede ser una área, una calle, una senda, un pasaje o un grupo especifico de residecias.
      </p>

      <h4 style={{ fontWeight: 500, color: 'var(--primary)' }}>Alcance</h4>
      <p style={{ color: '#707070' }}>
        La sumatoria de casas por zona debe ser el mismo que el número total de casas de la residencial.
      </p>

      <h4 style={{ fontWeight: 500, color: 'var(--primary)' }}>¿Tengo que agregar todas las zonas de una vez?</h4>
      <p style={{ color: '#707070' }}>
        Puedes ir guardando cada zona que agreges y al finalizar dar al boton "Finalizar".
      </p>

      <h4 style={{ fontWeight: 500, color: 'var(--primary)' }}>¿Y luego que sigue?</h4>
      <p style={{ color: '#707070' }}>
        Una vez ingresadas todas las zonas de la residencial, se procederá al envío de mensajes MSN a cada
        usuario residencial, con el enlace web para que completen su formulario de registro.
      </p>
    </CustomDrawer>
  </aside>
)

export default AboutUs
