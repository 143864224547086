import axios from "axios"
import { API_URL } from "../../../../config"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const getOrnamentChecklistInstances = async ({
  idToken,
  companyId,
  checklistId,
  employeeId,
  dateBegin,
  dateEnd,
  checklistIdStatus,
  skip,
  limit,
  sortOrder,
  sortBy
}) => {
  try {
    const isChecklistId = checklistId ? `&checklist_id=${checklistId}` : ''
    const isEmployeeId = employeeId ? `&employee_id=${employeeId}` : ''
    const isDateBegin = dateBegin ? `&date_begin=${dateBegin}` : ''
    const isDateEnd = dateEnd ? `&date_end=${dateEnd}` : ''
    const isChecklistIdStatus = checklistIdStatus ? `&checklist_instance_status_id=${checklistIdStatus}` : ''
    const isSkip = skip ? `&skip=${skip}` : ''
    const isLimit = limit ? `&limit=${limit}` : ''
    const isSortOrder = sortOrder ? `&sort_order=${sortOrder}` : ''
    const isSortBy = sortBy ? `&sort_by=${sortBy}` : ''

    const response = await axios.get(`${API_URL}checklist/type/ornament/instance?company_id=${companyId}${isChecklistId}${isEmployeeId}${isDateBegin}${isDateEnd}${isChecklistIdStatus}${isSkip}${isLimit}${isSortOrder}${isSortBy}`,
      { headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000 }
    )

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getOrnamentChecklistInstances