import { Dropdown, Menu, Row } from "antd"
import { useContext } from "react"
import { AdminChecklistTasksContext } from "../context/AdminChecklistTasksContext"

const MoreActions = ({
  dataChecklistAdminItem,
  handleOpenCloseModalDelete,
}) => {
  const { form, handleOpenCloseModal, setTaskClicked } = useContext(AdminChecklistTasksContext)

  const ItemLabel = ({ item, index }) => (
    <div className="more-options" onClick={() => item.action(index)}>
      <img src={item.icon} alt={item.name} />
      <span>{item.name}</span>
    </div>
  )

  const items = [
    {
      name: "Eliminar",
      // disabled: featuresEnabled.fireEmployee,
      icon: "/assets/icons/basura.svg",
      action: () => {
        handleOpenCloseModalDelete()
        setTaskClicked(dataChecklistAdminItem)
      },
    },
    {
      name: "Editar",
      // disabled: featuresEnabled.updateEmployee,
      icon: "/assets/icons/editar.svg",
      action: () => {
        handleOpenCloseModal()
        setTaskClicked(dataChecklistAdminItem)
        form.setFieldsValue({ name: dataChecklistAdminItem?.name })
      },
    }
  ]

  const menu = (
    <Menu
      style={{
        width: 175,
      }}
      items={items.map((item, index) => ({
        disabled: item.disabled,
        key: index,
        label: <ItemLabel item={item} index={index} />,
      }))}
    />
  );

  return (
    <Row justify="end" align="middle">
      <Dropdown trigger={["click"]} overlay={menu}>
        <img
          src="/assets/icons/more-icon.svg"
          alt="mas"
          style={{
          cursor: "pointer",
          }}
        />
      </Dropdown>
    </Row>
  )
}

export default MoreActions