import { useState } from "react"
import { Button, Col, Row } from "antd"
import CustomModal from "../../../../UI/CustomModal"
import useImageChecklist from "../../hooks/useImageChecklist"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store"
import { setChecklistClicked } from "../../../../../store/adminChecklist/adminChecklistSlice"

//imagenes o archivos
import Warning from '../../../../../assets/icons/waring.svg'

const DeleteTestModal = ({
  isOpenModal,
  handleOpenCloseModal
}) => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)

  const { filters, checklistClicked } = useAppSelector(state => state.adminCheck)
  const { deleteChecklistInstance, getChecklistsInstances } = useImageChecklist()

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      handleOpenCloseModal()
      await getChecklistsInstances({ ...filters })
    }
  }

  const onDeleteTest = async () => {
    setLoading(true)

    const response = await deleteChecklistInstance({ checklist_instance_id: checklistClicked.checklist_instance_id })

    await onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal
      destroyOnClose
      isOpenModal={isOpenModal}
      onCancel={handleOpenCloseModal}
    >
      <Row>
        <Col span={24}>
          <Row justify="center">
            <img src={Warning} alt="x-icon" />
          </Row>

          <Row justify="center">
            <label style={{ textAlign: 'center', width: 416, margin: '25px 0', color: '#373737', fontWeight: 500, fontSize: 25 }}>
              ¿Seguro que deseas eliminar esta evaluación?
            </label>
          </Row>

          <Row justify="center" align="middle" gutter={16}>
            <Col span={8}>
              <Row justify="center">
                <Button
                  type="text"
                  shape="round"
                  htmlType="button"
                  onClick={() => {
                    dispatch(setChecklistClicked({}))
                    handleOpenCloseModal()
                  }}
                  disabled={loading}
                  loading={loading}
                  style={{ height: 40, width: 132, border: 0, padding: '0px 32px 0px 32px' }}
                >
                  <span style={{ fontSize: 14, fontWeight: 500, color: '#404040' }}>
                    CANCELAR
                  </span>
                </Button>
              </Row>
            </Col>

            <Col span={8}>
              <Row justify="center">
                <Button
                  type="text"
                  shape="round"
                  htmlType="button"
                  disabled={loading}
                  loading={loading}
                  onClick={onDeleteTest}
                  style={{ height: 40, width: 132, padding: '0px 32px 0px 32px', backgroundColor: '#FF5858' }}
                >
                  <span style={{ fontSize: 14, fontWeight: 500, color: '#FFFFFF' }}>
                    ELIMINAR
                  </span>
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </CustomModal>
  )
}

export default DeleteTestModal