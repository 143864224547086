import { onCreateOrUpdate, onUpdateDocWithRef, onDeleteDoc } from "../../helpers/globalHelper";
import { getSurveysHelper, getSurveysResponseHelper, getSurveysQuestionHelper, getAllUserResidential, getProfilesSurveysHelper, getActiveSurveyHelper } from "../../helpers/surveysHelper";
import { createSurveys, resetSurveys, setActiveSurveys, setLoading, setSurvey } from "./surveysSlice";
import moment from 'moment';

export const getSurveys = () => {
    return async (dispatch, getState) => {

        dispatch(setLoading({ status: true }))
        const { committee } = getState().auth;
        const allUserResidential = await getAllUserResidential(committee.residential_id);
        const surveys = await getSurveysHelper(committee.residential_id);

        //Obtener todas las preguntas y respuestas de las encuestas en una promesa
        const surveysPromise = surveys.map(async (survey) => {
            const questions = await getSurveysQuestionHelper(survey.id)
            const res = await getSurveysResponseHelper(committee.residential_id, survey.id)
            const answeredSurveySet = new Set(res.map(response => response.profile_id))
            const answeredSurveyCount = answeredSurveySet.size

            return {
                ...survey,
                respuesta: res,
                allUserResidential,
                encuestasContestadas: answeredSurveyCount,
                preguntas: questions
            }
        })
        const processedSurveys = await Promise.all(surveysPromise)

        //Obtener los perfiles de las respuestas, sin repeticion
        const profileIds = [...new Set(processedSurveys.flatMap((survey) => survey.respuesta.map((answer) => answer.profile_id)))]
        const profilePromise = profileIds.map((profile_id) => getProfilesSurveysHelper(profile_id))
        const profiles = await Promise.all(profilePromise)
        const profilesById = profiles.reduce((acc, profile) => {
            acc[profile.id] = profile
            return acc
        }, {})

        const surveysWithProfiles = processedSurveys.map((survey) => {
            return {
                ...survey,
                profiles: [...new Set(survey.respuesta.map((answer) => profilesById[answer.profile_id]))]
            }
        })
        dispatch(setSurvey(surveysWithProfiles))
        dispatch(setLoading({ status: false }))
    }
}

export const getActiveSurveys = (surveys_id) => {
    return async (dispatch, getState) => {
        const { committee } = getState().auth;

        const [allUserResidential, activeSurveyRes, questions, answersRes] = await Promise.all([
            await getAllUserResidential(committee.residential_id),
            await getActiveSurveyHelper(surveys_id),
            await getSurveysQuestionHelper(surveys_id),
            await getSurveysResponseHelper(committee.residential_id, surveys_id)
        ])


        const answeredSurveySet = new Set(answersRes.map(response => response.profile_id))
        const answeredSurveyCount = answeredSurveySet.size

        const prevSurvey = {
            ...activeSurveyRes,
            respuesta: answersRes,
            allUserResidential,
            encuestasContestadas: answeredSurveyCount,
            preguntas: questions
        }

        const profileIds = [...new Set(prevSurvey.respuesta.map((answer) => answer.profile_id))]
        const profilePromise = profileIds.map((profile_id) => getProfilesSurveysHelper(profile_id))
        const profiles = await Promise.all(profilePromise)
        const profilesById = profiles.reduce((acc, profile) => {
            acc[profile.id] = profile
            return acc
        }, {})

        const activeSurvey = {
            ...prevSurvey,
            profiles: [...new Set(prevSurvey.respuesta.map((answer) => profilesById[answer.profile_id]))]
        }
        dispatch(setActiveSurveys(activeSurvey))
        return activeSurvey
    }
}

export const setSurveys = (data) => {
    return async (dispatch, getState) => {
        const { committee, uid } = getState().auth;

        const listPreguntas = data.listPreguntas;
        const listPreguntasEliminadas = data.listPreguntasEliminadas;

        if (data.eraser) {
            delete data.startDate
            delete data.endDate
        }

        data.createdBy = uid;
        data.residential_id = committee.residential_id;
        data.created_at = moment(new Date(), 'DD/MM/YYYY').toDate();
        data.active = true;

        let idSurveys = ""
        //Si la encuesta no se ha creado se llama onCreateOrUpdate con la data de la encuesta
        if (data.id === "") {
            delete data.id
            const createdSurveys = await onCreateOrUpdate(`/surveys/`, data);
            idSurveys = createdSurveys.id
        }
        //Si existe id en la data a enviar es porque se va a actualizar una encuesta
        // Esto es cuando se guarda en un borrador la encuesta previamente
        else {
            idSurveys = data.id
            await onUpdateDocWithRef(`/surveys/${idSurveys}`, data);
        }

        //Se llama onDeleteDoc para eliminar preguntas de acuerdo al objeto de la data
        //Si viene vació no se llama el bucle while
        let z = 0;
        while (z < listPreguntasEliminadas.length) {
            await onDeleteDoc(`/surveys/` + idSurveys + `/questions`, listPreguntasEliminadas[z].idR);
            ++z;
        }

        const questions = []
        let i = 0;

        //Si el objeto listaPregunta viene con más de un elemento, se va añadiendo cada pregunta a la encuesta
        //En caso de tener un idR se entiende que se está actualizando una pregunta y no creando
        while (i < listPreguntas.length) {
            const itemQuestion = {}
            itemQuestion.order = i
            itemQuestion.type = listPreguntas[i].type
            itemQuestion.question = listPreguntas[i].question
            if (listPreguntas[i].type === "option") {
                const option = []
                let j = 0;
                while (j < listPreguntas[i].opciones.length) {
                    option.push(listPreguntas[i].opciones[j].valor)
                    ++j;
                }
                itemQuestion.option = option
            }


            if (listPreguntas[i].idR === 0) {
                const createdSurveysQ = await onCreateOrUpdate(`/surveys/` + idSurveys + `/questions`, itemQuestion);
                itemQuestion.idR = createdSurveysQ.id

            } else {
                await onUpdateDocWithRef(`/surveys/` + idSurveys + `/questions/` + listPreguntas[i].idR, itemQuestion);
                itemQuestion.idR = listPreguntas[i].idR
            }
            questions.push(itemQuestion)
            ++i;
        }

        data.id = idSurveys;
        data.preguntas = questions
        data.respuesta = []
        data.opcionesGrafica = []
        dispatch(createSurveys(data));
    }
}

export const setLoadingSurveys = ({ status }) => {
    return async (dispatch) => {
        dispatch(setLoading({ status }))
    }
}

export const clearSurveysData = () => {
    return async (dispatch) => dispatch(resetSurveys())
}