import React, { useState, useEffect } from "react";
import CustomModal from "../../../../UI/CustomModal";
import { Button, Form, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { putCategoriesTicketThunk } from "../../../../../store/detailTicket/thunk";

const CategoryModal = ({ onCancel, isOpenModal, ticketById }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [subcategories, setSubcategories] = useState([]);
  const ticketCategories = useSelector(
    (state) => state.detailTicketById.ticketCategory
  );
  const dispatch = useDispatch();
  const categories = ticketCategories.map((category) => {
    return {
      value: category?.id,
      label: category?.name,
    };
  });

  const handleCategoryChange = (value) => {
    const selectedCategory = ticketCategories?.find(
      (category) => category?.id === value
    );

    if (selectedCategory) {
      const subcategoriesForSelectedCategory =
        selectedCategory.TICKET_SUBCATEGORies?.map((subcategory) => {
          return {
            value: subcategory?.id,
            label: subcategory?.name,
          };
        });

      setSubcategories(subcategoriesForSelectedCategory || []);
    } else {
      setSubcategories([]);
    }

    form.resetFields(["subcategoria"]);
  };

  const onFinish = (values) => {
    setLoading(true);
    const categoryId = values?.category || ticketById?.TICKET_CATEGORY?.id;
    const subcategoriaId =
      values?.subcategoria || ticketById?.TICKET_SUBCATEGORY?.id;
    dispatch(
      putCategoriesTicketThunk(ticketById?.id, categoryId, subcategoriaId)
    );
    onCancel();
    form.resetFields();
    setLoading(false);
  };

  const rules = {
    required: { required: true, message: "Este campo es obligatorio" },
  };

  useEffect(() => {
    form.setFieldsValue({
      category: ticketById?.categoryId,
      subcategoria: ticketById?.TICKET_SUBCATEGORY?.id,
    });

    const selectedCategory = ticketCategories?.find(
      (category) => category?.id === ticketById?.categoryId
    );

    if (selectedCategory) {
      const subcategoriesForSelectedCategory =
        selectedCategory.TICKET_SUBCATEGORies?.map((subcategory) => {
          return {
            value: subcategory?.id,
            label: subcategory?.name,
          };
        });

      setSubcategories(subcategoriesForSelectedCategory || []);
    }
  }, [ticketById, form, ticketCategories]);

  return (
    <CustomModal onCancel={onCancel} isOpenModal={isOpenModal}>
      <div>
        <h3 style={{ textAlign: "center", color: "#232C4A", fontSize: "22px" }}>
          CATALOGAR TICKET
        </h3>
        <Form
          className="standard-form"
          onFinish={onFinish}
          disabled={loading}
          form={form}
          layout="vertical"
        >
          <Row align="middle" justify="center">
            <div style={{ width: "50%" }}>
              <label style={{ color: "#676767", fontSize: "12px" }}>
                Categoría
              </label>
              <Form.Item name="category" rules={[rules.required]}>
                <Select
                  placeholder="Seleccionar Categoria"
                  onChange={handleCategoryChange}
                  options={categories}
                ></Select>
              </Form.Item>
            </div>
          </Row>
          <Row align="middle" justify="center">
            <div style={{ width: "50%" }}>
              <label style={{ color: "#676767", fontSize: "12px" }}>
                Subcategoría
              </label>
              <Form.Item name="subcategoria" rules={[rules.required]}>
                <Select
                  allowClear={true}
                  placeholder="Seleccionar Subcategoria"
                  options={subcategories}
                ></Select>
              </Form.Item>
            </div>
          </Row>

          <Row style={{ marginTop: "1rem" }} justify="center">
            <Form.Item>
              <Button
                className="btn-standard-green"
                shape="round"
                type="ghost"
                htmlType="submit"
              >
                <span>GUARDAR</span>
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
    </CustomModal>
  );
};

export default CategoryModal;
