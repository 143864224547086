import verifyResponse from "../../topia/helpers/verifyResponse"
import { setBookingsTableData, setCurrentActiveTab, setDetailBooking, setLoadingReserves, setMetaData, setStatusBookings, setStatusBookingsCalendar } from "./bookingsSlice"
import { getAllAmenitiesByCompanyId, getAllBookingStatusByCompanyId, getAllBookingsByStatusAndCompany, getAllUsersProfileByCompanyId, getCalendarReservationStatus, getDetailReserveByReserveId, getReservationsCalendar, getReservationsExcel, updateStatusReserveApprove, updateStatusReserveCancel } from "../../topia/pages/bookings/services"

// Get funtions
export const getAllReservationStatus = () => async (dispatch, getState) => {
  // El current rol es el company Id
  const { currentRol, idToken } = getState().auth
  // Validacion de que solamente si existe un companyId realice la peticion
  const response = currentRol ? await getAllBookingStatusByCompanyId(currentRol, idToken) : null

  if (response.success) {
    dispatch(setCurrentActiveTab(response.data.data[0].reservation_code))
    dispatch(setStatusBookings(response.data.data))
    return response
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getAmenitiesByCompanyId = ({ find_by, find_value }) => async (dispatch, getState) => {
  const { currentRol, idToken } = getState().auth

  const response = await getAllAmenitiesByCompanyId({ companyId: currentRol, idToken: idToken, find_by: find_by, find_value: find_value })

  if (response.success) {
    return response
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getUsersProfileByCompanyId = ({ find_by, find_value, limit, skip, setLoading }) => async (dispatch, getState) => {
  const { currentRol, idToken } = getState().auth
  setLoading && setLoading(true)
  const response = await getAllUsersProfileByCompanyId({ companyId: currentRol, idToken: idToken, limit: limit, skip: skip, find_by: find_by, find_value: find_value })

  if (response.success) {
    return response
  }
  setLoading && setLoading(false)
  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getAllReservations = ({
  reservationCode,
  limit,
  skip,
  sortBy,
  sortOrder,
  placeId,
  profileId,
  dateBegin,
  dateEnd,
}) => async (dispatch, getState) => {
  dispatch(setLoadingReserves(true))
  // El current rol es el company Id
  const { currentRol, idToken } = getState().auth

  const response = currentRol ? await getAllBookingsByStatusAndCompany({
    idToken: idToken,
    companyId: currentRol,
    reservationCode: reservationCode,
    limit: limit,
    skip: skip,
    sortBy: sortBy,
    sortOrder: sortOrder,
    placeId: placeId,
    profileId: profileId,
    dateBegin: dateBegin,
    dateEnd: dateEnd,
  }) : null

  if (response.success) {
    dispatch(setBookingsTableData(response.data.data))
    dispatch(setMetaData({
      limit: response.data.metadata.limit,
      skip: response.data.metadata.skip,
      quantity: response.data.metadata.quantity,
      rest: response.data.metadata.rest
    }))
    dispatch(setLoadingReserves(false))
    return true
  }

  dispatch(setLoadingReserves(false))
  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getDetailReserve = (reservationId) => async (dispatch, getState) => {
  const { idToken } = getState().auth
  const response = await getDetailReserveByReserveId(reservationId, idToken)

  if (response.success) {
    dispatch(setDetailBooking(response.data.data))
    return true
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

// Update functions
export const updateToApproveStatusReserve = (formData) => async (dispatch, getState) => {
  const { idToken } = getState().auth
  const response = await updateStatusReserveApprove(formData, idToken)

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Reserva aprobada con éxito' })
}

export const updateToCancelStatusReserve = (formData) => async (dispatch, getState) => {
  const { idToken } = getState().auth

  const response = await updateStatusReserveCancel(formData, idToken)

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Reserva cancelada con éxito' })
}

export const getBookingsCalendarStatus = () => async (dispatch, getState) => {
  const { idToken } = getState().auth

  const response = await getCalendarReservationStatus(idToken)

  if (response.success) {
    dispatch(setStatusBookingsCalendar(response.data.data))

    return
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getExcelData = ({
  date_begin, date_end
}) => async (dispatch, getState) => {
  const { idToken, currentRol } = getState().auth

  const response = await getReservationsExcel({ company_id: currentRol, idToken, date_begin, date_end })

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Excel descargado con éxito' })
}

export const getBookingsCalendar = ({
  date_begin, date_end, reservation_status_id, place_id, find_value
}) => async (dispatch, getState) => {
  dispatch(setLoadingReserves(true))
  const { idToken, currentRol } = getState().auth

  const response = await getReservationsCalendar({ company_id: currentRol, idToken, date_begin, date_end, reservation_status_id, place_id, find_value })

  if (response.success) {
    dispatch(setBookingsTableData(response.data.data))

    dispatch(setLoadingReserves(false))
    return
  }

  dispatch(setLoadingReserves(false))

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}