import moment from "moment"
import { Col, Row, Typography } from "antd"
import { useEffect, useState } from "react"
import useWidgets from "../../hooks/useWidgets"
import { useAppSelector } from "../../../../../hooks/store"
import useRedirectTo from "../../../../hooks/useRedirectTo"
import { CustomSpin } from "../../../../UI/CustomSpin"

const { Text } = Typography

const TodayAccessProviders = () => {
  const [loadingAccess, setLoadingAccess] = useState(false)

  const { handleRedirectTo } = useRedirectTo()
  const { getAccessProviders } = useWidgets()
  const { accessProviders } = useAppSelector(state => state.home)

  useEffect(() => {
    getAccess()
  }, [])

  const getAccess = async () => {
    setLoadingAccess(true)

    await getAccessProviders()
    
    setLoadingAccess(false)
  }

  if (loadingAccess) return <CustomSpin section />

  return (
    <Row style={{ marginBottom: 25 }}>
      <Col span={24} style={{ backgroundColor: "#FFFFFF", padding: 25, borderRadius: 16 }}>
        <h3 style={{ fontWeight: 600, fontSize: 20 }}>
          ACCESOS PROVEEDORES
        </h3>
        
        <Row gutter={[0, 12]}>
          {accessProviders?.length > 0
            ? accessProviders?.map((providerData) => <Provider key={providerData.event_id} providerData={providerData} handleRedirectTo={handleRedirectTo} />)
            : <span style={{ fontWeight: 500 }}>No hay accesos programados</span>
          }
        </Row>
      </Col>
    </Row>
  )
}

const Provider = ({ providerData, handleRedirectTo }) => (
  <Col
    span={24}
    style={{
      cursor: 'pointer',
      color: '#000000',
    }}
    onClick={() => handleRedirectTo('/access/providers-access')}
  >
    <Row>
      <Col span={16}>
        <Row align="middle">
          <span style={{ backgroundColor: providerData.status_color, marginRight: 4 }} className="circle"></span>

          <span style={{ fontWeight: 500 }}>
            <Text
              style={{ width: 150 }}
              ellipsis={{
                tooltip: ((providerData?.provider_name) ? (providerData.provider_name) : '')
              }}
            >
              {providerData.provider_name}
            </Text>
          </span>    
        </Row>
      </Col>

      <Col span={8}>
        <Row justify="end">
          <span style={{ fontWeight:500 }}>{moment(providerData.date).utcOffset(0).startOf('day').format('YYYY-MM-DD')}</span>
        </Row>
      </Col>
    </Row>
  </Col>
)

export default TodayAccessProviders