import { Col, Row } from 'antd'
import { useEffect, useState} from 'react'
import SuppliesTask from './detatilTask/SuppliesTask'
import EquipmentTask from './detatilTask/EquipmentTask'
import MaintenanceDay from './detatilTask/MaintenanceDay'
import { useAppSelector } from '../../../../../../../hooks/store'

const DetailTasksEmployee = () => {
  const [countWorkDay  ,setCountWorkDay] = useState(0)
  const [inventoryData, setInventoryData] = useState([])
  const [countSupplies  ,setCountSupplies] = useState(0)
  const [countEquipments  ,setCountEquipments] = useState(0)

  const { currencySymbol } = useAppSelector(state => state.auth)

  const inventories = useAppSelector((state) => state?.inventory)

  useEffect(() => {
    if (inventories && typeof inventories === 'object') {
      const categories = Object.keys(inventories.slice(0, inventories.length - 1)); 
      const newData = categories.map(category => ({
        items: inventories[category]
      }))
      
      setInventoryData(newData)
    }
  }, [inventories])

  return (
    <Row
      justify='center'
      gutter={42}  
      style={{background:'#fff', padding:'2rem',marginInline:'0.1rem', borderRadius:'8px', marginTop:'2rem', minHeight:'389px'}}
    >
      <Col span={8} style={{position:'relative'}}>
        <p style={{fontSize:'16px', fontWeight:'bold'}}>Jornadas de mantenimiento {`(${countWorkDay})`}</p>
        <MaintenanceDay setCountWorkDay={setCountWorkDay}/>
      </Col>
      <Col span={8} style={{position:'relative'}}>
        <p style={{fontSize:'16px', fontWeight:'bold'}}>Insumos solicitados {`(${countSupplies})`} </p>
        <SuppliesTask currency={currencySymbol ?? "$"} setCountSupplies={setCountSupplies} inventoryData={inventoryData}/>
      </Col>
      <Col span={8} style={{position:'relative'}}>
        <p style={{fontSize:'16px', fontWeight:'bold'}}>Equipo solicitados {`(${countEquipments})`} </p>
        <EquipmentTask inventoryData={inventoryData} setCountEquipments={setCountEquipments}/>
      </Col>    
    </Row>
  )
}

export default DetailTasksEmployee
