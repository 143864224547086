import { Image, Text, View } from "@react-pdf/renderer"
import { styles } from "./styles"
import { statusColorObj } from "../../../mocks/statusColor"
import { integroLogo1, integroLogo2 } from '../../../assets'
import { truncateText } from "../../../helpers/stringHelper"
import CustomFilePdf from "../../../../../UI/CustomFiles/CustomFilePdf"
import moment from "moment"

const MonthlyIncomePDFReport = ({ data = { table: [], general: {} } }) => (
    <CustomFilePdf size='letter' multiplePages footer footerBar={true}>
        <View fixed style={{ ...styles.headerInfo, marginBottom: 40 }}>
            <View style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <Text style={styles.headerInfoTitle}>
                    REPORTE DE ACCESO DE PROVEEDORES
                </Text>
                <Text style={{ ...styles.headerInfoDetails, fontWeight: 'semibold' }}>
                    {moment().startOf('day').format('dddd DD [DE] MMMM [DEL] YYYY').toUpperCase()}
                </Text>
            </View>
            <Image style={{ width: 200, position: 'absolute', right: '5%', top: '30%' }} src={integroLogo1} />
        </View>
        {data && data.table.length ? <>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '45%' }}>
                        <Text style={{ ...styles.tableCell, textAlign: 'left', paddingLeft: '40px' }}>PROVEEDOR</Text>
                    </View>
                    <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '35%' }}>
                        <Text style={{ ...styles.tableCell, textAlign: 'left' }}>FECHA</Text>
                    </View>
                    <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '20%' }}>
                        <Text style={{ ...styles.tableCell, textAlign: 'left' }}>ESTADO</Text>
                    </View>
                </View>
                {/**DATOS */}
                {
                    data.table.sort((a, b) => new Date(a.date) - new Date(b.date)).map((item, index) => (
                        <View break={index && index % 17 === 0} key={index} style={{ ...styles.tableRow, backgroundColor: index % 2 === 0 && '#F3F3F3' }}>
                            <View style={{ ...styles.tableCol, width: '45%' }}>
                                <Text style={{ ...styles.tableCell, textAlign: 'left', paddingLeft: 40 }}>{truncateText({ text: item.provider_name, maxLength: 40 })}</Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '35%' }}>
                                <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{moment(item.date).utcOffset(0).format('dddd [-] DD/MM/YYYY').toUpperCase()}</Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '20%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row', gap: 4 }}>
                                <Image src={statusColorObj[item.status_color]?.url} style={{ width: 5, height: 5 }} />
                                <Text style={{ ...styles.tableCell, textAlign: 'left' }}>{statusColorObj[item.status_color]?.name}</Text>
                            </View>
                        </View>
                    ))
                }
            </View>
        </> : <View />
        }
        <Image src={integroLogo2} fixed style={{ position: 'absolute', height: 180, top: '50%', left: '40%', width: 250, opacity: 0.10, transform: 'translate(-50%,-50%)' }} />{/*MARCA DE AGUA DE INTEGRO */}
        <View fixed style={{ backgroundColor: '#252525', height: '9px', position: 'absolute', top: 16, width: 560, marginHorizontal: 24 }} />
        <View fixed style={{ position: 'absolute', height: '9px', backgroundColor: '#252525', bottom: 16, width: 560, marginHorizontal: 24 }} />
    </CustomFilePdf>
)

export default MonthlyIncomePDFReport