import { Upload, message } from "antd";
//Verifica y devuelve la funcion que deberia de ejecutarse por cada tipo de archivo que se desee enviar.
export const verifyMessageTypeFile = (fileData) => {
  let typeFilesObj = {
    'image/png': () => handleAddImage(fileData),
    'image/jpg': () => handleAddImage(fileData),
    'image/jpeg': () => handleAddImage(fileData),
    'application/pdf': () => handleAddFile(fileData),
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': () => handleAddFile(fileData),
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': () => handleAddFile(fileData),
    'default': () => errorType()
  }
  let file = typeFilesObj[fileData.type] || typeFilesObj['default']
  return file()
}

export const verifyTypeIconBannerNew = (fileData) => {
  let typeFilesObj = {
    'image/png': () => handleAddImage(fileData),
    'image/jpg': () => handleAddImage(fileData),
    'image/jpeg': () => handleAddImage(fileData),
    'default': () => errorType()
  }
  let file = typeFilesObj[fileData.type] || typeFilesObj['default']
  return file()
}

// Function to verify type file allowed in new map in contracts flow
export const verifyTypeMapFile = (fileData) => {
  let typeFilesObj = {
    'application/pdf': () => handleAddFileWithoutRestrictions(fileData),
    'application/x-zip-compressed': () => handleAddFileWithoutRestrictions(fileData),
    'default': () => errorType()
  }
  let file = typeFilesObj[fileData.type] || typeFilesObj['default']
  return file()
}

// Function to verify type file allowed in new map in contracts flow
export const verifyTypeNewsFiles = (fileData) => {
  let typeFilesObj = {
    'application/pdf': () => handleAddFile(fileData),
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': () => handleAddFile(fileData),
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': () => handleAddFile(fileData),
    'default': () => errorType()
  }
  let file = typeFilesObj[fileData.type] || typeFilesObj['default']
  return file()
}

// Function to verify type file allowed in new contract in contracts flow
export const verifyTypeContractFile = (fileData) => {
  let typeFilesObj = {
    'application/pdf': () => handleAddFileWithoutRestrictions(fileData),
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': () => handleAddFileWithoutRestrictions(fileData),
    'default': () => errorType()
  }
  let file = typeFilesObj[fileData.type] || typeFilesObj['default']
  return file()
}

export const errorType = () => {
  message.error('El tipo de archivo no es válido')

  return Upload.LIST_IGNORE
}

export const handleAddImage = (file) => {
  const isIMG = file.type.includes("png", 5) || file.type.includes("jpeg", 5) || file.type.includes("jpg", 5)

  const isLt2M = file.size / 1024 / 1024 < 10
  if (!isLt2M) {
    message.error("El archivo debe pesar menos de 10MB!")
  }

  return (isLt2M && isIMG) || Upload.LIST_IGNORE
}

export const handleAddFile = (file) => {
  const isValidFileType
    = file.type === 'application/pdf'
    || file.type === 'application/x-zip-compressed'
    || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("El archivo debe pesar menos de 10MB!")
  }
  return (isLt2M && isValidFileType) || Upload.LIST_IGNORE
}

// Reutilizar la misma funcion pasandole parametro de size para usarlo en la conversion y mensaje de error
// Y unificar una sola funcion
export const handleAddFileWithoutRestrictions = (file) => {
  const isValidFileType
    = file.type === 'application/pdf'
    || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

  // Se pasa de bytes to megabytes
  const isLt2M = ((file.size / 1024) / 1024) < 30;
  if (!isLt2M) {
    message.error("El archivo debe pesar menos de 30MB!")
  }

  return (isLt2M && isValidFileType) || Upload.LIST_IGNORE
}
