import axios from "axios"

const getAllMessagesByConversationId = async (skip, limit, idConversation) => {
  try {
    const response = await axios.get(`https://domo.topiaapp.com/api/conversations/messages/?conversation_id=${idConversation}&skip=${skip}&limit=${limit}`)

    return {
      success: true,
      ...response
    }
  } catch (error) {
      return {
        success: false,
        ...error.response
      }
  }
}

export default getAllMessagesByConversationId
