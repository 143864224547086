import { useContext, useEffect, useState } from "react"
import { useAppSelector } from './../../../../hooks/store'
import { AmenitiesContext } from "../context/AmenitiesContext"
import { verifyResponse } from './../../../helpers/verifyResponse'
import { deleteAmenityByAmenityId, getAllAmenitiesByCompanyId, getDetailAmenity, updateAmenityStatusByAmenityId } from "../services"

const useAmenities = () => {
  const [loading, setLoading] = useState(false)
  const [metadata, setMetadata] = useState({})
  const [currentPage, setCurrentPage] = useState(1)

  const { currentRol, idToken } = useAppSelector(state => state.auth)
  const {
    setDetailAmenity,
    setAmenities,
    setSchedulesAmenity,
    setRestrictions,
    setCosts,
    setFileList,
    setCurrentValueRadio,
    setValueCostTime,
    setValueStandardCost,
    setValueInviteesCost,
    setValueInviteesAllowed
  } = useContext(AmenitiesContext)

  useEffect(() => {
    if (currentRol && idToken) {
      getAllAmenities()
    }
  }, [])

  // Obtener todas las amenidades
  const getAllAmenities = async (skip, limit) => {
    setLoading(true)
    const response = await getAllAmenitiesByCompanyId({ companyId: currentRol, idToken: idToken, skip, limit })

    if (response.success) {
      const dataSource = transformData(response.data.data)
      setAmenities(dataSource)
      setMetadata(response.data.metadata)
      setLoading(false)
      return
    }

    setLoading(false)
    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  const transformData = (amenitiesData) => {
    const dataSource = amenitiesData?.map((amenityData) => ({
      ...amenityData,
      amenity_name: amenityData?.name,
    }))

    return dataSource
  }

  // Obtener todo el detalle de una amenidad
  const getAllDetailAmenityData = async (placeId) => {
    const response = await getDetailAmenity(placeId, idToken)

    if (response.success) {
      setDetailAmenity(response.data.data)

      const typeCodeRadio = response.data.data?.AMENITY_COST?.INVITEE?.cost_invitee_type_code === 'range'
        ? 2
        : 1

      setCurrentValueRadio(typeCodeRadio)

      // fill states for switch
      setValueStandardCost(response.data.data?.AMENITY_COST?.DEFAULT?.default_cost_enabled)
      setValueCostTime(response.data.data?.AMENITY_COST?.DURATION?.duration_cost_enabled)
      setValueInviteesCost(response.data.data?.AMENITY_COST?.INVITEE?.cost_invitee_enabled)
      setValueInviteesAllowed(response.data.data?.AMENITY_SETTING?.INVITEE?.invitee_enabled)

      // Lleno todos la data para el custom component
      const newDataFileList = response.data.data?.AMENITY_DATA?.FILE?.cover.map((file, index) => ({
        uid: crypto.randomUUID(),
        name: `Archivo adjuntado anteriormente ${index}`,
        status: 'done',
        url: file,
        type: 'image/',
      }))

      setFileList(newDataFileList)

      const newRestrictions = response.data.data?.AMENITY_CONSTRAINT?.map((restriction, index) => ({ ...restriction, id: index }))
      setRestrictions(newRestrictions)

      const newCosts = response.data.data?.AMENITY_COST?.RANGE?.map((cost, index) => ({ ...cost, id: index + 1 }))
      
      if (newCosts.length) {
        setCosts(newCosts)
      }

      const newSchedules = response.data.data?.AMENITY_SCHEDULE?.map((day) => ({ ...day, label: verifyDay(day.day) }))
      setSchedulesAmenity(newSchedules)

      return true
    }

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  const verifyDay = (dayId) => {
    let objDays = {
      0: 'Domingo',
      1: 'Lunes',
      2: 'Martes',
      3: 'Miércoles',
      4: 'Jueves',
      5: 'Viernes',
      6: 'Sábado',
      'Default': 'Error'
    }

    let day = objDays[dayId] || objDays['default']
    return day
  }

  // Actualizar status de una amenidad
  const onUpdateAmenityStatus = async (placeId) => {
    setLoading(true)
    const response = await updateAmenityStatusByAmenityId(({ place_id: placeId }), idToken)

    setLoading(false)
    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Estatus de amenidad actualizado con éxito' })
  }

  // Eliminar una amenidad
  const onDeleteAmenity = async (placeId) => {
    setLoading(true)
    const response = await deleteAmenityByAmenityId(placeId, idToken)

    if (response.success) {
      setLoading(false)
      return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Amenidad eliminada con éxito' })
    }

    setLoading(false)
    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  //Paginacion
  const changePageHandler = async (currentPag, limit) => {
    const pageValue = currentPag || currentPage
    const newSkip = (pageValue * limit) - limit
    setCurrentPage(pageValue)
    await getAllAmenities(newSkip, limit)
  }

  return {
    loading,
    metadata,
    currentPage,
    getAllAmenities,
    onDeleteAmenity,
    changePageHandler,
    onUpdateAmenityStatus,
    getAllDetailAmenityData,
  }
}

export default useAmenities