import { getAlertsHelper } from "../../helpers/alertHelper"

import {
    setAlertsActive,
    setAlertsArchive,
} from '.'


export const getAlerts = ({ isLimit, startDate, endDate }) => async (dispatch, getState) => {
    const { committee } = getState().auth

    const alerts = await getAlertsHelper({ residential_id: committee?.residential_id, isLimit: isLimit, startDate: startDate, endDate: endDate })

    // Se separan las alertas archivadas de las activas
    const archivedAlerts = Array.isArray(alerts) ? alerts?.filter((alertData) => alertData.archivar === true) : []

    const activeAlerts = Array.isArray(alerts) ? alerts?.filter((alertData) => alertData.archivar === false) : []

    dispatch(setAlertsActive(activeAlerts))
    dispatch(setAlertsArchive(archivedAlerts))

    return activeAlerts
}