import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    status: 'checking', // 'checking', 'not-authenticated', 'authenticated', 'checking-login'
    uid: null,
    email: null,
    displayName: null,
    photoURL: null,
    errorMessage: null,
    committee: null,
    rolResidential: null,
    currentRol: localStorage.getItem('currentRol') ?? 0,
    typeRol: localStorage.getItem('typeRol') ?? 0,
    currencySymbol: localStorage.getItem('currencySymbol') ?? '',
    roles: [],
    profileId: '',
    idToken: '',
    companyName: localStorage.getItem('companyName') ?? '',
    currentEmployee: localStorage.getItem('currentEmployee') ?? 0,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, { payload }) => {
            state.status = payload.status || 'checking' // 'checking', 'not-authenticated', 'authenticated', 'checking-login'
            state.uid = payload.uid
            state.email = payload.email
            state.displayName = payload.displayName
            state.photoURL = payload.photoURL
            state.errorMessage = null
            state.committee = payload.committee
            state.rolResidential = payload.rolResidential
            state.roles = payload.roles
            if ((!localStorage.getItem('currentRol') || !localStorage.getItem('typeRol')) && payload?.roles?.length) {
                localStorage.setItem('currentRol', payload?.rolData?.companyId)
                localStorage.setItem('currencySymbol', payload?.rolData?.currencySymbol)
                localStorage.setItem('typeRol', payload?.rolData?.companyType);
                localStorage.setItem('companyName', payload?.rolData?.companyName);
                state.currencySymbol = payload?.rolData?.currencySymbol
                state.currentRol = payload?.rolData?.companyId
                state.typeRol = payload?.rolData?.companyType
                state.companyName = payload?.rolData?.companyName
            }
            state.profileId = payload.profileId;
            state.idToken = payload.idToken;
        },
        logout: (state, { payload }) => {
            state.status = payload; // 'checking', 'not-authenticated', 'authenticated', 'checking-login'
            state.uid = null;
            state.email = null;
            state.displayName = null;
            state.photoURL = null;
            state.errorMessage = payload?.errorMessage;
            state.committee = null;
            state.rolResidential = null;
            state.roles = [];
            state.currencySymbol = '';
            state.typeRol = 0;
            state.profileId = '';
            state.idToken = '';
        },
        checkingCredentials: (state, { payload }) => {
            state.status = 'checking';
        },
        changeAuthenticationStatus: (state, { payload }) => {
            state.status = payload
        },
        changeCurrentRol: (state, { payload }) => {
            localStorage.setItem('currentRol', payload)
            state.currentRol = payload
        },
        authComplete: (state) => {
            state.status = 'authenticated';
        },
        changeRol: (state, { payload }) => {
            state.currentRol = payload?.currentRol;
            state.committee.residential_id = payload?.residential_id;
            state.typeRol = payload?.typeRol;
            state.currentEmployee = payload?.currentEmployee;
            state.companyName = payload?.companyName
            state.profileId = payload.profileId
            state.currencySymbol = payload.currencySymbol
        },
    }
});


// Action creators are generated for each case reducer function
export const { login, logout, checkingCredentials, changeRol, authComplete, changeAuthenticationStatus, changeCurrentRol } = authSlice.actions;