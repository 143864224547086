import moment from "moment"
import { AppBanner } from "./AppBanner"
import { ColorPicker } from "./ColorPicker"
import { useContext, useState } from "react"
import useTypesNews from "../../hooks/useTypesNews"
import { InfoCircleOutlined } from "@ant-design/icons"
import CustomSwitch from "../../../../UI/CustomSwitch"
import { CustomInput } from "../../../../UI/CustomInput"
import { config } from "../../../../constants/constants"
import { useAppSelector } from "../../../../../hooks/store"
import useRedirectTo from "../../../../hooks/useRedirectTo"
import { TypesNewsContext } from "../../context/TypesNewsContext"
import { CustomDatePicker } from './../../../../UI/CustomDatePicker'
import { Row, Form, Col, Divider, Button, Upload, Checkbox } from "antd"
import { CustomRadioButton } from "../../../checklist/UI/CustomRadioButton"
import { verifyTypeIconBannerNew } from "../../../../helpers/customUploadHelper"
import { CustomTimePicker } from './../../../../UI/CustomTimePicker/CustomTimePicker'
import { CustomUploadFile } from './../../../../UI/CustomUploadFile/CustomUploadFile'

const TypeNewBodyForm = () => {
  const { Item } = Form

  const [loading, setLoading] = useState(false)

  const { onSaveTypesNews } = useTypesNews()
  const { handleRedirectTo } = useRedirectTo()
  const { idTypesNews } = useAppSelector(state => state.news)

  const {
    form, onReset, color, setColor,
    fileListImg, setFileListImg, fileListFiles, setFileListFiles,
    isUrl, setIsUrl, isPublishedButton, setIsPublishedButton,
    title, setTitle, icon, setIcon,
    iconFile, setIconFile, valueRadio, setValueRadio,
    valueRadioDateEnd, setValueRadioDateEnd
  } = useContext(TypesNewsContext)

  const newTypesNews = idTypesNews?.map((typeNew) => ({ label: typeNew?.name?.toUpperCase(), value: typeNew?.news_type_id }))

  const handleOnChangeRadio = (e) => {
    setValueRadio(e.target.value)
  }

  const handleOnChangeRadioDateEnd = (e) => {
    setValueRadioDateEnd(e.target.value)
  }

  const onSelectedIcon = (files) => {
    const reader = new FileReader()

    reader.onloadend = function () {
      // Se enviar la url para el componente Image y renderice
			setIcon(reader.result)
		};

    reader.readAsDataURL(files.file.originFileObj)

		setIconFile(files.file.originFileObj)
	}

  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0)
  }

  const onFinish = async (isSuccessfull) => {
    if(isSuccessfull) {
      handleRedirectTo('/news')
      onReset()
    }
  }

  const onSubmit = async (formData) => {
    setLoading(true)
    // Formatear fechas
    const dateBegin = moment(formData?.date_begin).format("YYYY-MM-DD")
    const dateEnd = moment(formData?.date_end).format("YYYY-MM-DD")
    const timeBegin = moment(formData.time_begin_date).format("HH:mmZ")
    const timeEnd = moment(formData.time_end_date).format("HH:mmZ")

    const finalStartDate = `${dateBegin}T${timeBegin}`
    const finalEndDate = `${dateEnd}T${timeEnd}`

    let objToSend = {
      ...formData,
      icon: iconFile ? iconFile : null,
      color: color,
      header: fileListImg,
      show_banner: formData?.show_banner?.length === 0 ? false : true,
      published: isPublishedButton,
      date_begin: finalStartDate,
      date_end: formData?.date_end ? finalEndDate : null,
      action_link: formData?.action_link ? formData.action_link : null,
      new_files: formData?.file?.fileList ? formData.file.fileList : null,
      old_files: fileListFiles,
      news_type_id: formData?.news_type_id ? formData.news_type_id : newTypesNews[0]?.value
    }

    if (valueRadio === 2 ) {
      objToSend = {
        ...formData,
        icon: iconFile ? iconFile : null,
        color: color,
        header: fileListImg,
        show_banner: formData?.show_banner?.length === 0 ? false : true,
        published: isPublishedButton,
        date_begin: finalStartDate,
        date_end: finalEndDate,
        action_link: formData?.action_link ? formData.action_link : null,
        date: moment(formData.date).format("YYYY-MM-DD"),
        time_begin: moment(formData.time_begin).format("HH:mm"),
        time_end: moment(formData.time_end).format("HH:mm"),
        new_files: formData?.file?.fileList ? formData.file.fileList : null,
        old_files: fileListFiles,
        news_type_id: formData?.news_type_id ? formData.news_type_id : newTypesNews[0]?.value
      }
    }

    const response = await onSaveTypesNews(objToSend)

    await onFinish(response)

    setLoading(false)
  }

  return (<Row className="section-wrapper" style={{ backgroundColor: '#FFFFFF', borderRadius: 8 }}>
    <Form
      form={form}
      layout="vertical"
      name="createEditTypeNew"
      onFinish={onSubmit}
      disabled={loading}
    >
      <Row gutter={16}>
        <Col span={16}>
          <Row>
            <h3 style={{ fontWeight: 600, fontSize: 18, color: '#252525' }}>
              TIPO DE PUBLICACIÓN
            </h3>
          </Row>

          <Row align="middle" style={{ margin: '15px 0' }}>
            <Item name="news_type_id" noStyle>
              <CustomRadioButton
                value={valueRadio}
                options={newTypesNews}
                className="custom-radio"
                style={{ flexDirection: 'row' }}
                onChange={handleOnChangeRadio}
              />
            </Item>
          </Row>

          <Divider />

          <Row>
            <h3 style={{ fontWeight: 600, fontSize: 18, color: '#252525' }}>
              DETALLES GENERALES
            </h3>
          </Row>

          <Row>
            <Col span={24}>
              <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto' }}>
                TÍTULO
              </label>

              <Item name="title" {...config} noStyle>
                <CustomInput
                  maxLength={150}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Ingresa el título de la publicación"
                />
              </Item>

              <Row align="middle" gutter={8} style={{ padding: '5px 0 25px' }}>
                <Col>
                  <InfoCircleOutlined />
                </Col>

                <Col>
                  <span style={{ color: '#707070', fontWeight: 400, fontFamily: 'Roboto' }}>
                    Máximo de caracteres 150, 75 visibles en Banner.
                  </span>
                </Col>
              </Row>

              {valueRadio === 2 && (
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <Row gutter={16}>
                          <Col span={12}>
                            <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto' }}>
                              FECHA
                            </label>
                            <Item name="date" {...config}>
                              <CustomDatePicker allowClear />
                            </Item>
                          </Col>

                          <Col span={6}>
                            <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto' }}>
                              HORA DE INICIO
                            </label>
                            <Item name="time_begin" {...config}>
                              <CustomTimePicker allowClear />
                            </Item>
                          </Col>

                          <Col span={6}>
                            <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto' }}>
                              HORA DE FIN
                            </label>
                            <Item name="time_end" {...config}>
                              <CustomTimePicker allowClear />
                            </Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto' }}>
                          LUGAR
                        </label>

                        <Item name="place" {...config}>
                          <CustomInput allowClear placeholder="Ingresa la ubicación del evento" />
                        </Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto' }}>
                DESCRIPCIÓN
              </label>

              <Item name="description">
                <CustomInput
                  type="AREA"
                  maxLength={2000}
                  autoSize={{ minRows: 4, maxRows: 10 }}
                  placeholder="Proporciona detalles del evento"
                />
              </Item>
            </Col>
          </Row>

          {valueRadio === 2 && (
            <Row>
              <Col span={24}>
                <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto' }}>
                  ¿QUIEN INVITA AL EVENTO?
                </label>

                <Item name="invite_from" {...config}>
                  <CustomInput
                    allowClear
                    placeholder="Especifica el organizador"
                  />
                </Item>
              </Col>
            </Row>
          )}

          <Divider style={{ backgroundColor: '#AFAFAF' }} />

          <Row gutter={8} align="bottom">
            <Col span={20}>
              <h3 style={{ fontWeight: 600, fontSize: 18, color: '#252525' }}>
                INFORMACIÓN ADICIONAL
              </h3>

              <p>
                El uso de este enlace es responsabilidad exclusiva del administrador.
                La aplicación no se hace responsable del contenido ni de la forma en que se utilice este link.
              </p>
            </Col>

            <Col span={4}>
              <Row justify="end">
                <CustomSwitch checked={isUrl} onChange={() => setIsUrl(state => !state)} />
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto', opacity: !isUrl ? 0.5 : 1 }}>
                URL EXTERNA
              </label>

              <Item
                name="action_link"
                rules={[{
                  pattern: /(https?:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(?:\/[^\s]*)?)/, // your regex pattern here
                  message: 'Debe de cumplir el formato URL',
                  }]
                }
              >
                <CustomInput disabled={!isUrl} allowClear placeholder="Pega tu enlace aquí" />
              </Item>
            </Col>
          </Row>

          <Divider style={{ backgroundColor: '#AFAFAF' }} />

          <Row>
            <Col span={24}>
              <h3 style={{ fontWeight: 600, fontSize: 18, color: '#252525' }}>
                CONFIGURACIÓN DE VISIBILIDAD
              </h3>

              <p style={{ textAlign: 'justify' }}>
                Este apartado permite definir cuándo la publicación estará disponible para el público. 
                Aquí se configuran tanto la fecha como la hora en que la publicación será visible.
              </p>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto' }}>
                INICIO DE PUBLICACIÓN
              </label>
              
              <Row gutter={16} style={{ marginTop: 15 }}>
                <Col span={8}>
                  <Item name="date_begin" {...config}>
                    <CustomDatePicker placeholder="Seleccionar fecha" allowClear />
                  </Item>
                </Col>

                <Col span={6}>
                  <Item name="time_begin_date" {...config}>
                    <CustomTimePicker allowClear />
                  </Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto' }}>
                FIN DE PUBLICACIÓN
              </label>

              <Row gutter={16} align="bottom">
                <Col>
                  <CustomRadioButton
                    value={valueRadioDateEnd}
                    className="custom-radio"
                    onChange={handleOnChangeRadioDateEnd}
                    options={[{ label: 'Nunca', value: 0 }, { label: 'El día', value: 1 }]}
                  />
                </Col>

                <Col span={8}>
                  <Item name="date_end">
                    <CustomDatePicker placeholder="Seleccionar fecha" allowClear disabled={valueRadioDateEnd === 0} />
                  </Item>
                </Col>

                <Col span={6}>
                  <Item name="time_end_date">
                    <CustomTimePicker allowClear disabled={valueRadioDateEnd === 0} />
                  </Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            <Col span={24}>
              <Row>
                <Col>
                  <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto' }}>
                    PREVIEW EN BANNER
                  </label>

                  <AppBanner
                    icon={icon}
                    bannerColor={color}
                    title={title}
                  />

                  <Item name="show_banner">
                    <Checkbox.Group
                      options={[{ label: "MOSTRAR BANNER", value: "1" }]}
                    />
                  </Item>
                </Col>
              </Row>

              <Row>
                <Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
                  <h3 style={{ fontWeight: 600, fontSize: 18, color: '#252525' }}>
                    PERSONALIZACIÓN
                  </h3>
                  
                  <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto' }}>
                    COLOR
                  </label>

                	<ColorPicker onPick={(color) => setColor(color)} defaultColor={color}/>
                </Col>
              </Row>

              <Row style={{ marginTop: 15 }}>
                <Col>
                  <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto' }}>
                    ICONO
                  </label>

                  <Row>
                    <Item name="icon">
                      <Upload
                        fileList={[]}
                        maxCount={1}
                        customRequest={dummyRequest}
                        beforeUpload={verifyTypeIconBannerNew}
                        onChange={onSelectedIcon}
                        accept=".jpg, .png, .jpeg"
                      >
                        <Button
                          type="text"
                          htmlType="button"
                          style={{ width: 196, border: '1px solid #D2D2D2', borderRadius: 8 }}
                        >
                          <span style={{ fontFamily: 'Roboto', fontWeight: 500, color: '#383838', fontSize: 12 }}>
                            Selecciona una imagen
                          </span>
                        </Button>
                      </Upload>
                    </Item>
                  </Row>
                </Col>
              </Row>

              <Divider style={{ backgroundColor: '#AFAFAF' }} />

              <Row>
                <Col span={24}>
                  <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto' }}>
                    IMAGEN PRINCIPAL
                  </label>

                  <Item name='header' rules={[{ validator: async (_, value) => { return fileListImg?.length ? Promise.resolve() : Promise.reject(new Error('Debe haber un archivo o imagen a subir')) } }]}>
                    <CustomUploadFile fixedWidth multipleFiles justImages maxCount={1} fileList={fileListImg} setFileList={setFileListImg} />
                  </Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <label style={{ fontSize: 12, color: '#252525', fontWeight: 600, fontFamily: 'Roboto' }}>
                    ARCHIVOS ADICIONALES
                  </label>
  
                  <Row justify="center" style={{ marginTop: 15 }}>
                    <Col span={24}>
                      <Item name="file" style={{ width: '100%' }}>
                        <Upload
                          multiple
                          maxCount={4}
                          fileList={fileListFiles}
                          listType="picture"
                          onChange={(file) => setFileListFiles(file.fileList) }
                          accept=".doc, .docx, .pdf, .png, .jpeg, .jpg, .xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          beforeUpload={() => { return false }}
                        >
                          <Button
                            type="text"
                            htmlType="button"
                            style={{ width: '100%', backgroundColor: '#FBFBFB', border: '1px solid #D2D2D2', borderRadius: 8 }}>
                            <span style={{ fontFamily: 'Roboto', fontSize: 12, fontWeight: 500 }}>
                              Subir archivos
                            </span>
                          </Button>
                        </Upload>
                      </Item>
                    
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      
      <Row justify="center" gutter={16} style={{ margin: '25px 0 0' }}>
        <Col>
          <Button
            type="text"
            shape="round"
            htmlType="submit"
            loading={loading}
            onClick={() => setIsPublishedButton(false)}
            style={{ backgroundColor: '#CEB457', width: 303, height: 40 }}
          >
            <span style={{ fontWeight: 500, fontSize: 14, color: '#FFFFFF' }}>
              GUARDAR Y CONTINUAR DESPUÉS
            </span>
          </Button>
        </Col>

        <Col>
          <Button
            type="text"
            shape="round"
            htmlType="submit"
            loading={loading}
            onClick={() => setIsPublishedButton(true)}
            className="btn-standard-green"
          >
            <span>PUBLICAR NOTICIA</span>
          </Button>
        </Col>
      </Row>
    </Form>
  </Row>
  )
}

export default TypeNewBodyForm