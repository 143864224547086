import axios from "axios";
import { API_URL } from "../../../../config"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const createNewOwner = async (formData, idToken) => {
  try {
    const response = await axios.post(`${API_URL}profile/user`,
      formData,
      { headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000 }
    )

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default createNewOwner
