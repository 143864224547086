import axios from "axios"

const addFileAttachment = async (conversationId, profileId, formData) => {
  try {
    const response = await axios.post(`https://domo.topiaapp.com/api/conversations/messages/files/add?conversation_id=${conversationId}&profile_id=${profileId}`,
    formData,
    {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
    }}
    )

    return {
      success: true,
      ...response
    }
    
  } catch (error) {
      return {
        success: false,
        ...error.response
      }
  }
}

export default addFileAttachment