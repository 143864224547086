import { axiosCreate } from "../../../../config/index";
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse";

const deleteCategory = async (idToken, data) => {
  try {    
    const response = await axiosCreate.delete('checklist/category', {
      headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000,
      data
    })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
};

export default deleteCategory;
