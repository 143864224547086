import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  status: [],
  data: {},
  role: "",
  calender: {},
  type: {},
  activityEmployee: {},
  loading: false,
  loadingTable: false,
  activeKey: '',
  isAssignedTickets: false
}

export const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setInitialTickets: (state) => {
      state.data.tickets = [];
      state.data.columns = [];
    },

    setTicketsStatus: (state, { payload }) => {
      state.status = payload;
    },
    setTickets: (state, { payload }) => {
      const { data, metadata } = payload;
      state.data.tickets = data?.dataSource;
      state.data.columns = data?.columns;
      state.data.quantity = metadata?.quantity;
      state.data.limit = metadata?.limit;
      state.data.skip = metadata?.skip;
      state.data.rest = metadata?.rest;
    },
    setRoleTickets: (state, { payload }) => {
      state.role = payload;
    },
    setCalender: (state, { payload }) => {
      state.calender = payload;
    },
    setType: (state, { payload }) => {
      state.type = payload;
    },
    setActivityEmployee: (state, { payload }) => {
      state.activityEmployee = payload;
    },
    setLoading: (state, {payload}) => {
      state.loading = payload
    },
    setLoadingTable: (state, { payload }) => {
      state.loadingChangeTabs = payload
    },
    clearCalendar: (state) => {
      state.calender = {}
      state.loading = true
    },
    setActiveKey: (state, { payload }) => {
      state.activeKey = payload
    },
    setIsAssignedTickets: (state, { payload }) => {
      state.isAssignedTickets = payload
    }
  },
});

export const {
  setTicketsStatus,
  setTickets,
  setRoleTickets,
  setCalender,
  setType,
  setInitialTickets,
  setActivityEmployee,
  setLoading,
  setLoadingTable,
  clearCalendar,
  setActiveKey,
  setIsAssignedTickets,
} = ticketsSlice.actions;