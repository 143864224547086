import { useState } from "react"
import { CustomSpin } from "../../UI/CustomSpin"
import EmptyComponent from "../../UI/EmptyComponent"
import CalendarBookings from "./components/CalendarBookings"
import { useFeatureRole } from "../../hooks/useFeatureRole"
import DetailReserveModal from "./modals/DetailReserveModal"
import useOpenCloseModal from "../../hooks/useOpenCloseModal"
import StatusBookingsTabs from "./components/StatusBookingsTabs"
import HeaderBookingActions from "./components/HeaderBookingActions"
import { BOOKINGS_CODES, BOOKINGS_CODES_NAMES } from "../../../constants/modules/bookings/bookingsCodes"

import './bookingStyle.css'

const BookingPage = () => {
  const [currentBody, setCurrentBody] = useState('tableBody')
  const [loadingModal, setLoadingModal] = useState(false)
  
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { loadingFeatures } = useFeatureRole({ feature_codes: BOOKINGS_CODES, validate_code: BOOKINGS_CODES_NAMES.BOOKING_MODULE, returnUrl: '/menu/modules' })

  const verifyBody = (currentActiveIdBody) => {
    let objBodies = {
      'calendarBody': <CalendarBookings handleOpenCloseModal={handleOpenCloseModal} />,
      'tableBody': (
          <>
            <StatusBookingsTabs currentBody={currentBody} setLoadingModal={setLoadingModal} handleOpenCloseModal={handleOpenCloseModal} />
          </>
        ),
      'default': <EmptyComponent label="Hubo un error por favor refresque la página" />,
    }

    let currentBodyBooking = objBodies[currentActiveIdBody] || objBodies['default']
    return currentBodyBooking
  }

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (
    <section className="section-wrapper">
      <HeaderBookingActions currentBody={currentBody} setCurrentBody={setCurrentBody} />

      {verifyBody(currentBody)}

      <DetailReserveModal loadingModal={loadingModal} isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
    </section>
  )
}

export default BookingPage
