import { TypesNewsProvider } from "./context/TypesNewsContext"
import useCurrentLocation from "../../hooks/useCurrentLocation"
import StatusTypesNewsTabs from "./components/StatusTypesNewsTabs"
import HeaderTypesNewsActions from "./components/HeaderTypesNewsActions"
import NewEditTypeNewPage from "./components/typeNew/NewEditTypeNewPage"

import './styles.css'
import { useFeatureRole } from "../../hooks/useFeatureRole"
import { CustomSpin } from "../../UI/CustomSpin"
import { NEWS_CODES, NEWS_CODES_NAMES } from "../../../constants/modules/news/newsCodes"

const TypesNewsPage = () => {
  const { param } = useCurrentLocation()

  const verifyBodyPage = (idNew) => {
    let body = <MainTypesNewsPage />

    if (idNew) body = <NewEditTypeNewPage />

    return body
  }

  return (
    <TypesNewsProvider>
      <section className="section-wrapper">
        {verifyBodyPage(param.idNew)}
      </section>
    </TypesNewsProvider>
  )
}

const MainTypesNewsPage = () => {
  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: NEWS_CODES, validate_code: NEWS_CODES_NAMES.NEWS_MODULE, returnUrl: '/menu/modules' })
  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (
    <>
      <HeaderTypesNewsActions activeFeatures={activeFeatures} />

      <StatusTypesNewsTabs activeFeatures={activeFeatures} />
    </>
  )
}

export default TypesNewsPage