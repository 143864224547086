import axios from "axios"

const updateMapFile = async (formData, idToken) => {
  try {
    const response = await axios.put('https://domo.topiaapp.com/api/place/file/type/map/last',
      formData,
      { headers: { Authorization: `Bearer ${idToken}` } }
    )

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default updateMapFile