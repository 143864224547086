import { useState } from "react";
import useFormLogic from "../../hooks/useFormLogic"
import CustomModal from "../../../../UI/CustomModal"
import { Button, Col, Form, Row, Select } from "antd"
import { CustomInput } from "../../../../UI/CustomInput"
import { useAppSelector } from "../../../../../hooks/store"
import { getAllHerarchyPath } from "../../../../helpers/customSelectHelper"
import CustomTreeSelect from "../../../../UI/CustomSelects/CustomTreeSelect"
import useHierarchyAdministration from "../../hooks/useHierarchyAdministration"

// Imagenes o archivos svg
import imgNull from '../../../../../assets/icons/empty.svg'

import '../../style.css';

const NewEditHierarchyModal  = ({ onClose, isOpenModal, form, setEditPlace, editPlace }) => {
  const { Item } = Form
  const config = { rules: [{ required: true, message: "Por favor llenar el campo" }] }
  
  const [selectedValue,setSelectedValue] = useState("")
  
  const { handleDelete, onSave, loading } = useFormLogic()
  const { getDetailHierarchyAdmin } = useHierarchyAdministration()
  const { hierarchyAdminSpaces, placeTypes} = useAppSelector((state) => state?.hierarchyAdminSpace)
  
  const handleOnChange = (value) => setSelectedValue(value)
  const path = getAllHerarchyPath(hierarchyAdminSpaces, selectedValue)
  
  // Funcion para escoger el icono segun el ID
  const verifyTypeIcon = (iconId) => {
    if ( !placeTypes ) return
    
    const imageToRender = placeTypes?.find((typeHierarchy)=> typeHierarchy.id === iconId)
    
    return <img style={{ width:20, height:20, marginBottom:'1rem' }} src={imageToRender?.image || imgNull  } alt="icon-place" />
  }
  
  // Agrega iconos a lado del lable del select
  const Label = ({ name, id }) => (
    <Row justify="space-between">
      <Row align="middle">
        {verifyTypeIcon(id)}
        <p style={{ marginLeft: "1rem" }}>{name}</p>
      </Row>
    </Row>
  )

  const types = placeTypes.map((types) => ({
    value: types?.id,
    label: <Label name={types.name} id= {types?.id} />,
  }))

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      form.resetFields()
      await getDetailHierarchyAdmin()
      onClose()
      setEditPlace(null)
    }
}

  const onSubmit = async (formData) => {
    const response = await onSave(formData, editPlace)

    await onFinish(response.success)

  }

  return (
    <CustomModal
      destroyonClose
      onCancel={() => {
        setEditPlace(null)
        onClose()
      }}
      isOpenModal={isOpenModal}
    >
      <h2 style={{ textAlign: "center", color: '232C4A' }}>
        {editPlace ? "EDITAR ESPACIO" : "AGREGAR ESPACIO"}
      </h2>

      <Form
        name="newEditForm"
        className="standard-form"
        onFinish={onSubmit}
        disabled={loading}
        form={form}
        layout="vertical"
      >
        <Row>
          <Col span={24}>
            <label style={{ color: "#676767", fontSize: "12px" }}>TIPO DE ESPACIO</label>
            <Item {...config} name="type">
              <Select className="standard-form" placeholder="TIPO DE ESPACIO"  options={types} />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label style={{ color: "#676767", fontSize: "12px" }}>NOMBRE DE LA ZONA:</label>
            <Item {...config} name="name">
              <CustomInput className="standar-input" placeholder={"NOMBRE DE LA ZONA:"} />
            </Item>
          </Col>
        </Row>

        {editPlace && (
          <Row>
            <Col span={24}>
              <Row justify="space-between">
                <Col span={14}>
                  <label style={{ color: "#676767", fontSize: "12px" }}>NUEVA UBICACIÓN</label>
                </Col>
                <Col span={10}>
                  <label style={{ color: "var(--primary)", fontSize:'12px' }}>{path?.join(' > ')}</label>
                </Col>
              </Row>
              <CustomTreeSelect
                allowClear
                name="parent_Id"
                config={config}
                style={{ marginTop: 12 }}
                treeData={hierarchyAdminSpaces}
                placeholder="SELECCIONE LA NUEVA UBICACIÓN"
                onChange={handleOnChange}
              />
            </Col>
          </Row>
        )}

        <Row justify="center">
          <Button loading={loading} className="btn-standard-green" style={{ width: 250 }} shape="round" type="text" htmlType="submit">
            <span>{editPlace ? "GUARDAR CAMBIOS" : "AGREGAR"}</span>
          </Button>
        </Row>

        {editPlace && (
          <Row justify="center">
            <Button loading={loading} onClick={() => handleDelete(editPlace, onClose)} type="text" style={{ border: 'none' }}>
              <span style={{ color: '#FF3030' }}>
                Eliminar espacio
              </span>
            </Button>
          </Row>
        )}
    </Form>
    </CustomModal>
  )
}

export default NewEditHierarchyModal
