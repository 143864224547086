import { Button } from 'antd'
import { useContext } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import useRedirectTo from '../../../hooks/useRedirectTo'
import { CustomNavigate } from './../../../UI/CustomNavigate'
import { TypesNewsContext } from '../context/TypesNewsContext'
import { useAppDispatch } from '../../../../hooks/store'
import { setDetailTypeNew } from '../../../../store/typesNews/typesNewsSlice'
import CustomPopoverConfirm from '../../../UI/CustomPopoverConfirm'
import { NEWS_CODES } from '../../../../constants/modules/news/newsCodes'

const HeaderTypesNewsActions = ({ activeFeatures }) => {
  const dispatch = useAppDispatch()

  const { handleRedirectTo } = useRedirectTo()
  const { onReset, onCleanAllStates } = useContext(TypesNewsContext)

  const onClickButton = () => {
    dispatch(setDetailTypeNew({}))
    onReset()
    onCleanAllStates()
    handleRedirectTo('/news/new')
  }

  return <header>
    <CustomNavigate title="NOTICIAS Y EVENTOS">
      <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === NEWS_CODES.NEWS_NEW_NEWS)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nueva noticia'}`}>
        <Button
          disabled={!activeFeatures?.find(feature => feature.feature_code === NEWS_CODES.NEWS_NEW_NEWS)?.enabled}
          type="text"
          shape="round"
          htmlType="button"
          className='btn-standard-green'
          style={{ width: 250 }}
          onClick={onClickButton}
          icon={<PlusCircleOutlined style={{ fontSize: 24 }} />}
        >
          <span>NUEVA NOTICIA</span>
        </Button>
      </CustomPopoverConfirm>
    </CustomNavigate>
  </header>
}

export default HeaderTypesNewsActions
