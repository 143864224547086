import CustomModal from "../../../../UI/CustomModal"
import SurveyHeader from "./components/SurveyHeader"
import SurveyAnswers from "./components/SurveyAnswers/SurveyAnswers"
import './styles.css'
import { useEffect } from "react"

export const ResumeSurvey = ({ dispatch, getActiveSurveys, chartData, surveysDetail, opcionesGrafica, modalVisible, handleStatusModal, downloadSurvey }) => {

    useEffect(() => {
        if (modalVisible) {
            dispatch(getActiveSurveys(surveysDetail.id))
            const intervalId = setInterval(() => {
                dispatch(getActiveSurveys(surveysDetail.id))
            }, 5000)
            return () => clearInterval(intervalId)
        }
    }, [modalVisible, dispatch, surveysDetail.id]);

    return (
        <CustomModal
            className='resume-survey'
            title={
                <SurveyHeader surveysDetail={surveysDetail} downloadSurvey={downloadSurvey} />
            }
            isOpenModal={modalVisible}
            onCancel={() => handleStatusModal(false)}
            footer={null}
            width={"85%"}
        >
            <div className="survey-answers">
                {
                    surveysDetail && <SurveyAnswers chartData={chartData} surveysDetail={surveysDetail} opcionesGrafica={opcionesGrafica} />
                }
            </div>
        </CustomModal>
    )
}

export default ResumeSurvey