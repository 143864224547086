import { useEffect, useState } from "react";
import { Button, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { deleteCategoryThunk, getAllCategoriesThunk, } from "../../../../../store/inventory/thunks";
import ModalAddCategory from "./ModalCategories/ModalAddCategory";
import useOpenCloseModal from "../../../../hooks/useOpenCloseModal";
import EditCategoryModal from "./ModalCategories/EditCategoryModal";
import verifyResponse from "../../../../../helpers/verifyResponse";
import EditIcon from "../../../../../assets/icons/EditIcon";
import CustomPopoverConfirm from "../../../../UI/CustomPopoverConfirm";
import { INVENTORY_CODES } from "../../../../../constants/modules/inventory/inventoryCodes";

const CategoryInventory = ({ errorMessage, setErrorMessage, activeFeatures }) => {
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal();
  const [OpenEdit, setOpenEdit] = useState(false);
  const [category, setCategory] = useState(null);

  const dispatch = useDispatch();
  const categories = useSelector((state) => state.inventoryCategories);
  const handleClose = () => setOpenEdit((state) => !state);
  const onDelete = async (id) => {
    const response = await dispatch(deleteCategoryThunk(id));
    if (response.success) {
      verifyResponse(
        response.data,
        response.status,
        "Categoria eliminada con exito"
      );
      setErrorMessage(false);
    } else {
      setErrorMessage(true);
    }
  };

  const onEditCategory = (categorySelected) => {
    setOpenEdit(true);
    setCategory(categorySelected);
  };

  useEffect(() => {
    dispatch(getAllCategoriesThunk());
  }, [dispatch]);

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>EDITAR CATEGORIAS</h3>
      <div>
        <div style={{ height: "15rem", overflow: "auto", marginBlock: "1rem" }}>
          {categories?.map((category, i) => (
            <Row
              justify="space-between"
              align="middle"
              key={category?.id}
              style={{
                border: "1px solid #BFBFBF",
                borderRadius: "20px",
                padding: "0 2rem",
                marginBottom: "0.8rem",
              }}
            >
              <p style={{ paddingTop: "1rem", fontSize: "14px" }}>
                {category?.name}
              </p>
              <Row>
                <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === INVENTORY_CODES.INVENTORY_UPDATE_CATEGORY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Actualizar subcategoría'}`}>
                  <Button
                    disabled={!activeFeatures?.find(feature => feature.feature_code === INVENTORY_CODES.INVENTORY_UPDATE_CATEGORY)?.enabled}
                    type="ghost"
                    style={{ border: "none" }}
                    onClick={() => onEditCategory(category)}
                  >
                    <EditIcon color="var(--primary)" width="27px" />
                  </Button>
                </CustomPopoverConfirm>
                <CustomPopoverConfirm placement={'left'} trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === INVENTORY_CODES.INVENTORY_DELETE_CATEGORY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Eliminar subcategoría'}`}>
                  <Button
                    disabled={!activeFeatures?.find(feature => feature.feature_code === INVENTORY_CODES.INVENTORY_DELETE_CATEGORY)?.enabled}
                    type="ghost"
                    style={{ marginLeft: "0.5rem", border: "none" }}
                    icon={<DeleteOutlined style={{ fontSize: "19px" }} />}
                    danger
                    onClick={() => onDelete(category?.id)}
                  />
                </CustomPopoverConfirm>
              </Row>
            </Row>
          ))}
        </div>
        <hr style={{ marginBlock: "1rem" }} />
        <Row justify="center" style={{ width: '100%' }}>
          <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === INVENTORY_CODES.INVENTORY_NEW_CATEGORY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nueva subcategoría'}`}>
            <Button
              className="btn-new-category-inventory"
              disabled={!activeFeatures?.find(feature => feature.feature_code === INVENTORY_CODES.INVENTORY_NEW_CATEGORY)?.enabled}
              onClick={handleOpenCloseModal}             
              shape="round"
            >
              Agregar nueva categoria
              <PlusOutlined
                style={{
                  width: 24,
                  height: 24,
                  display: 'flex',
                  alignItems: 'center',
                  border: " 1px solid #000",
                  borderRadius: "100%",
                  paddingInline: "0.25rem",
                }}
              />
            </Button>
          </CustomPopoverConfirm>
        </Row>

        {errorMessage && (
          <p
            style={{
              fontSize: "14px",
              borderRadius: "17px",
              padding: "1rem",
              color: "#6F5151",
              background: "#FADADA",
              marginTop: "5rem",
            }}
          >
            Para poder eliminar una categoría, primero deberás eliminar los
            insumos o equipos que contenga
          </p>
        )}
      </div>
      <ModalAddCategory
        onCancel={handleOpenCloseModal}
        isOpenModal={isOpenModal}
      />
      <EditCategoryModal
        category={category}
        onCancel={handleClose}
        isOpenModal={OpenEdit}
      />
    </div>
  );
};

export default CategoryInventory;
