import { Calendar } from 'antd'
import { customLocale } from '../../mocks/calendarLocale'
import DateCellRender from './components/DateCellRender'
import HeaderCalendar from './components/HeaderCalendar'
import '../styles.css'

const AccessProvidersCalendar = ({ handleChangeAccessStatus, data, handleOpenCloseItem, handleChangeMonthCalendar, providerService, handleProviderChange, providersTable, currentParams, activeFeatures }) => (
  <Calendar headerRender={({ value, onChange }) => <HeaderCalendar data={data} providersTable={providersTable} providerService={providerService} handleProviderChange={handleProviderChange} handleChangeMonthCalendar={handleChangeMonthCalendar} value={value} onChange={onChange} handleChangeAccessStatus={handleChangeAccessStatus} currentParams={currentParams} />}
    dateFullCellRender={(value) => DateCellRender({ value, data, handleOpenCloseItem, currentParams, activeFeatures })}
    className='accessProviderCalendar'
    locale={customLocale}
  />
)
export default AccessProvidersCalendar