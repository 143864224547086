import moment from "moment"
import { useAccessProvidersServices } from "./useAccessProvidersServices"
import { Form } from 'antd'

export const useNewAccessProvider = ({ handleOpenCloseModal, getCalendarData, setLoading, accessDetail }) => {

  const { createAccessProviderService, updateAccessProviderService } = useAccessProvidersServices()
  const [form] = Form.useForm()

  //Dependiendo de si existe una propiedad llamada edit, se tomará un servicio para actualizar o para crear el acceso del proveedor y para eliminar la propiedad event_id de los values del formulario
  //Si es undefined la descripción se elimina del objeto del formulario
  //La propiedad de agenda que se enviará al servicio se  creará utilizando la fecha, y las horas, minutos y tipo de tiempo en los selects del Form.List del formulario
  //Además si no son undefined la propiedad id o updatable en el formulario se agregarán de lo contrario no
  //Se llama al servicio correspondiente, se vuelve a actualizar la data del calendario y se hace un reset al formulario, finalmente cerrando el modal 
  const onSubmit = async ({ values }) => {
    const service = accessDetail?.edit ? updateAccessProviderService : createAccessProviderService
    if (!values.description) delete values.description
    if (!accessDetail?.edit) delete values.event_id
    const newAgenda = values.agenda.map(item => {
      const date = moment(item.date).startOf('day').format('YYYY-MM-DD')
      const time_begin = moment(`${item.typeTime === 'pm' ? item.hours + 12 : item.hours}:${item.minutes}:00`, 'HH:mm:ss').format('HH:mm:ss')
      return {
        ...(item.id ? { id: item.id } : {}),
        ...(item.updatable ? { updatable: item.updatable } : {}),
        date,
        time_begin,
      }
    })

    values.agenda = newAgenda
    await service({ formData: values, setLoading })
    await getCalendarData({})
    form.resetFields()
    handleOpenCloseModal({ name: 'newAccessProviderModal', visible: false })
  }

  //La función se va a ejecutar cuando se edite un evento desde el drawer
  //El array de agenda se va a descomponenr en typeTime, hours y minutes utilizando la fecha del evento y sumandole el time_begin, indicandole el desfase de 0 horas (UTC)
  //Se va a crear el objeto de formData, utilizando lo que existe en el accessDetail y añadiendo la nueva propiedad de agenda. Finalmente se van a establecer los nuevos valores en el formulario
  const getInitialFormData = async () => {
    const newAgenda = accessDetail.agenda.map(item => {
      const date = moment(item?.date).utcOffset(0)
      const time = date.add((moment.duration(item?.time_begin, 'HH:mm:ss'))).utcOffset(0)
      return {
        id: item?.id,
        updatable: item.updatable,
        date: date,
        typeTime: time.hours() >= 12 ? 'pm' : 'am',
        hours: time.hours() >= 12 ? time.hours() - 12 : time.hours(),
        minutes: time.minutes()
      }
    })
    const formData = {
      event_id: accessDetail?.event_id,
      provider_id: accessDetail?.provider?.provider_id,
      event_reason_id: accessDetail?.reason?.reason_id,
      receiver_name: accessDetail?.receiver_name,
      agenda: newAgenda,
      description: accessDetail?.event_description
    }
    form.setFieldsValue(formData)
  }

  //La función se utiliza en el Form.List cuando se agrega una nueva fecha, donde se obtiene todos los items de la agenda y el  item actual antes del que se está creando, este viene undefined,
  //Se va a hacer un nuevo objeto que será igual al item actual
  //Se va a hacer una copia de la fecha del elemento actual pero sumandole 1 día extra, si existe,  y se añadirá al item nuevo
  //Se añade el nuevo objeto al nuevo item de la agenda, cuyo indice será el indice del item actual más uno y por ultimo se actualiza el valor en el formulario
  const handleAddNewDateItem = ({ field }) => {
    const allAgendaItems = form.getFieldValue('agenda')
    const actualAgendaItem = form.getFieldValue(['agenda', field.name])
    const newAgendaItem = { ...actualAgendaItem }
    const newDate = actualAgendaItem.date ? actualAgendaItem.date.clone().startOf('day').add(1, 'day') : undefined
    newAgendaItem.date = newDate
    
    allAgendaItems[field.name + 1] = newAgendaItem
    form.setFieldsValue({
      agenda: allAgendaItems
    })
  }

  return { onSubmit, Form, form, getInitialFormData, handleAddNewDateItem }
}

export default useNewAccessProvider