import axios from "axios"

const getPlacesHierarchyAdmin = async (URL_DEV, companyId, idToken, parentId = "", limit, newSkip, sortOrder, sortOrderBy= 'type') => {
  try {
    const isParentId = parentId ? `&parent_id=${parentId}` : ''
    const isSortOrder = sortOrder ? `&sort_order=${sortOrder}` : ''
    const isSortOrderBy = sortOrderBy ? `&sort_by=${sortOrderBy}` : ''
    const url = `${URL_DEV}/place/parent/?company_id=${companyId}&limit=${limit}&skip=${newSkip}${isParentId}${isSortOrder}${isSortOrderBy}`;
   
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${idToken}` }
    });

    return {
      success: true,
      ...response
    };
  } catch (error) {
    return {
      success: false,
      ...error.response
    };
  }
};

export default getPlacesHierarchyAdmin;
