import axios from "axios"

const postNewMapFile = async (formData, idToken) => {
  try {
    const response = await axios.post('https://domo.topiaapp.com/api/place/file/type/map',
      formData,
      { headers: { Authorization: `Bearer ${idToken}` } }
    )

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default postNewMapFile