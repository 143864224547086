import { useRef, useState } from "react"
import { useUserRolesContext } from "../context/UserRolesContext"
import useDetailRolesServices from "./useDetailRolesServices"
import { Navigate } from "react-router-dom"
import { userDetailRolesTableColumnsPrev } from "../helpers/useDetailRolesTableHelper"
import { collectFeatureIds, concatUniqueIds, excludeIds, featuresIdHierarchy, filterData, validateHierarchyIds } from "../helpers/filterDataHelper"

//Custom hook para exportar las funciones para la página de detalles de roles
const useDetailRoles = () => {
    const { userRolesTable, setLoading, loading } = useUserRolesContext()
    const [modulesRole, setModulesRole] = useState({ active: '', data: [] })
    const [featureModulesRole, setFeatureModulesRole] = useState({ columns: [], dataSource: [], selectedRows: [], allSelectedRows: [], hierarchyData: [] })
    const [activeModuleRole, setActiveModuleRole] = useState('');
    const [filterFeatures, setFilterFeatures] = useState({ type: '', name: '' })
    const currentModuleData = useRef({ columns: [], dataSource: [], selectedRows: [], allSelectedRows: [] });
    const { getAvailableFeaturesRolesService, getAvailableModulesRolesService, getRoleModuleStatusService, updateFeaturesRoleService, updateRoleStatusService } = useDetailRolesServices();

    const columns = userDetailRolesTableColumnsPrev({ justView: userRolesTable.active.justView, columns: featureModulesRole.columns })
    //Permite a partir del id del modulo obtener la nuevas caracterisitcas correspondientes a este modulo y obtener el estado en que se encuentra este
    const handleChangeModuleRole = async ({ module_id }) => {
        const initialModuleFeature = await getAvailableFeaturesRolesService({ role_id: userRolesTable.active.role_id, module_id, setLoading })
        const statusModuleRole = await getRoleModuleStatusService({ role_id: userRolesTable.active.role_id, module_id, setLoading })
        //Guardar los datos iniciales en una referencia ya que no necesita actualizarse en el renderizado
        currentModuleData.current = initialModuleFeature;
        setFeatureModulesRole(prev => ({ ...initialModuleFeature, allSelectedRows: [], hierarchyData: featuresIdHierarchy(initialModuleFeature.dataSource) }))
        setActiveModuleRole({ module_id, status: statusModuleRole?.role_module_status })
        setModulesRole(prev => ({ ...prev, active: module_id }))
        setLoading(false)
    }

    //Cambia el estado de un modulo apartir del id del modulo y llamando al servicio correspondiente
    const handleChangeModuleRolStatus = async ({ module_id }) => {
        await updateRoleStatusService({ formData: { role_id: userRolesTable.active.role_id, module_id }, setLoading })
        //Actualiza los datos de nuevo de las features y el estado del mismo
        await handleChangeModuleRole({ module_id })
    }

    //Habilita o deshabilita una feature de la tabla, donde el selectedRowKeys son el nuevo arreglo de keys que ha sido modificado
    const handleChangeFeatureStatus = ({ selectedRowKeys }) => {
        setFeatureModulesRole(prev => ({ ...prev, selectedRows: selectedRowKeys }))
    }

    //Se utiliza ya sea para filtrar por funcionalidad o por tipo de instancia donde se obtienen todas las keys y se validan con los helpers para procurar que se mantenga el arreglo de keys 
    //aunque se cambie el estado de una feature cuando se tiene uno o ambos filtros activos
    const handleChangeTypePermission = ({ value, type }) => {
        const keys = concatUniqueIds(featureModulesRole.allSelectedRows, featureModulesRole.selectedRows)
        const allKeys = validateHierarchyIds(featureModulesRole.hierarchyData.data, keys)

        setFilterFeatures(prev => ({ ...prev, [type]: value }))
        //En caso de que no haya ningun filtro activo retornara los datos iniciales de las features y las keys correspondientes que esten activas
        if (!value && Object.values(filterFeatures).some(item => !item)) {
            currentModuleData.current.selectedRows = allKeys
            return setFeatureModulesRole(prev => ({ ...prev, dataSource: currentModuleData.current.dataSource, selectedRows: allKeys, allSelectedRows: [] }))
        }
        //Si se esta cambiando entre opcion del select se debe de excluir ademas las keys de la opcion anterior
        const newData = filterData({ data: currentModuleData.current.dataSource, searchItems: { ...filterFeatures, [type]: value } })
        //Almacenar las nuevas selecteRowKeys
        //Sin embargo, verificar si existen en el selectedRowKeys inicial
        const newSelectedRowKeys = collectFeatureIds(newData).filter(item => allKeys.includes(item))
        //Almacenar las keys excluyendo estas que se van a modificar
        const excludeSelectedRowKeys = excludeIds(featureModulesRole.selectedRows, newSelectedRowKeys)
        //Del las keys anterioes excluidas, quitar las nuevas keys pero añdair las keys activas anteriores para no perder informacion
        const newAllKeys = [...featureModulesRole.allSelectedRows].filter(item => !newSelectedRowKeys.includes(item)).concat(excludeSelectedRowKeys);
        setFeatureModulesRole(prev => ({ ...prev, dataSource: newData, selectedRows: newData.length ? newSelectedRowKeys : prev.selectedRows, allSelectedRows: newData.length ? newAllKeys : prev.allSelectedRows }))
    }

    //Handle que permite guardar los cambios de las caracteristicas de un modulo, recuperando todas las keys despues de los cambios, sin importar si se estaba filtrando por algun tipo de instancia o funcionalidad
    const handleOnSaveFeatures = async () => {
        const allKeys = concatUniqueIds(featureModulesRole.allSelectedRows, featureModulesRole.selectedRows)
        const formData = {
            role_id: userRolesTable.active.role_id,
            module_id: modulesRole.active,
            feature_ids: allKeys
        }
        await updateFeaturesRoleService({ setLoading, formData })
        //Luego de actualizar las features, vuelve a actualizar los datos desde el endpoint
        await handleChangeModuleRole({ module_id: modulesRole.active })
    }

    //Obtiene todos los datos iniciales al cargar la página, en caso de no existir el estado de la tabla de roles se retorna a la página anterior
    const getInitialData = async () => {
        const modules = await getAvailableModulesRolesService({ company_id: userRolesTable.active?.company_id, setLoading })
        const orderedModules = modules.sort((a, b) => a.module_id - b.module_id)
        if (!orderedModules.length) { //Si no hay elementos es porque no hay modulos disponibles y se cancela la obtencion de datos
            return setLoading(false)
        }
        await handleChangeModuleRole({ module_id: orderedModules[0].module_id })
        setModulesRole({ data: orderedModules, active: orderedModules[0]?.module_id })
        setLoading(false)
    }

    return {
        userRolesTable,
        activeModuleRole,
        featureModulesRole,
        filterFeatures,
        modulesRole,
        loading,
        columns,
        handleChangeModuleRolStatus,
        handleChangeModuleRole,
        handleChangeFeatureStatus,
        handleChangeTypePermission,
        handleOnSaveFeatures,
        getInitialData
    }
}

export default useDetailRoles