//ADMÓN AMENIDADES ✅

export const amenitiesModule = {
    feature_code: 'FEAT-387',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-388',
            enabled: false,
        },
        {
            feature_code: 'FEAT-389',
            enabled: false,
        },
        {
            feature_code: 'FEAT-390',
            enabled: false,
        },
        {
            feature_code: 'FEAT-391',
            enabled: false,
        },
    ]
}