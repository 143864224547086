import { useEffect, useContext } from "react"
import { CountDownContext } from "../../context/CountDownContext"

const useCountDown = (
  functionToExecute,
  initialTime,
  timeLeft,
  setTimeLeft
) => {
  const { isModalOpen } = useContext(CountDownContext)

  useEffect(() => {
    let interval
    if (!isModalOpen) {
      const updateTimeLeft = () => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1)
        } else {
          clearInterval(interval)
          if (functionToExecute) functionToExecute()
          setTimeLeft(initialTime)
        }
      };

      interval = setInterval(updateTimeLeft, 1000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [functionToExecute, initialTime, timeLeft, setTimeLeft, isModalOpen])
}

export default useCountDown
