import moment from "moment"
import {ReactComponent as QuestionIcon} from '../assets/icons/questionIcon.svg'
import arrowRightIcon from '../assets/icons/arrowRightIcon.svg'
import { useSurveyContext } from "../contexts/SurveyContext"
import { useSurveyFormContext } from "../contexts/SurveyFormContext"

const useSurveysTabs = () => {

    const { surveys, dispatch, setActiveSurveys, setLoadingSurveys, getActiveSurveys, setModalVisible } = useSurveyContext()
    const { form, setFormModal } = useSurveyFormContext()
    const handleActiveDetail = async (data) => {
        dispatch(setActiveSurveys(data));
    }

    const handleGetResults = async () => {
        dispatch(setLoadingSurveys({ status: true }))
        const activeSurvey = await dispatch(getActiveSurveys(surveys.active.id))
        dispatch(setLoadingSurveys({ status: false }))
        dispatch(setActiveSurveys(activeSurvey));
        setModalVisible(true)
    }

    const handleEdit = async (data) => {
        //Modificar la data para poder colocarla en cada formulario  
        const formData = {
            active: data.active,
            id: data.id,
            name: data.name,
            descripcion: data.descripcion,
            eraser: data.eraser,
            listPreguntas: data?.preguntas?.map((item) => ({
                ...item,
                opciones: item?.option?.map((item, index) => ({ valor: item, posicion: index }))
            })).map(({ opciones, option, ...item }) => opciones !== undefined ? { ...item, opciones } : { ...item }),
            listaPreguntasEliminadas: [],
            tipoEncuesta: data.tipoEncuesta,
            startDate: data.startDate ? moment(data.startDate, 'YYYY-MM-DD') : undefined,
            endDate: data.endDate ? moment(data.endDate, 'YYYY-MM-DD') : undefined
        }
        const formDataFiltered = Object.entries(formData).reduce((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = value;
            }
            return acc
        }, {})
        form.setFieldsValue(formDataFiltered)
        setFormModal(true)
    }

    const finishedSurveys = surveys?.data.filter((survey) => !survey.eraser)
    const eraserSurveys = surveys?.data.filter((survey) => survey.eraser)

    const tabItems = [{
        label: 'FINALIZADAS',
        children: finishedSurveys.map((survey, index) => (
            <li key={index} onClick={() => { handleActiveDetail(survey) }} className={surveys?.active === null ? "primary-card-card" : surveys?.active.id === survey.id ? "primary-card-card selectedSurvery" : "primary-card-card"}>
                <QuestionIcon className="btn-svg-icon-variant2" />
                <div className='primary-card-info'>
                    <span>ENCUESTA SOBRE:</span><br />
                    <h3>{survey.name}</h3>                </div>
                {surveys?.active?.id === survey?.id && <img className='shape-next' src={arrowRightIcon} alt="" />}
            </li>
        ))
    },
    {
        label: 'EN BORRADOR',
        children: eraserSurveys.map((survey, index) => (
            <li key={index} onClick={() => { handleEdit(survey) }} className={surveys?.active === null ? "primary-card-card" : surveys?.active.id === survey.id ? "primary-card-card selectedSurvery" : "primary-card-card"}>
                <QuestionIcon className="btn-svg-icon-variant2" />
                <div className='primary-card-info'>
                    <span>ENCUESTA SOBRE:</span><br />
                    <h3>{survey.name}</h3>
                </div>
                {surveys?.active?.id === survey?.id && <img className='shape-next' src={arrowRightIcon} alt="" />}
            </li>
        ))
    }
    ]
    return (
        {
            tabItems,
            handleGetResults,
        }
    )
}

export default useSurveysTabs