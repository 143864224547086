import { Row } from "antd"
import useAlerts from "../hooks/useAlerts"
import { setFilters } from "../../../../store/alerts"
import { useAppDispatch } from "../../../../hooks/store"
import { CustomNavigate } from "../../../UI/CustomNavigate"
import { CustomDatePicker } from "../../../UI/CustomDatePicker"

const HeaderActionsAlerts = ({ setLoading }) => {
  const dispatch = useAppDispatch()

  const { getAllAlerts } = useAlerts()

  const handleOnChange = async (dates) => {
    setLoading(true)
    // Si no hay fechas seleccionadas no se realiza la peticion
    if (!dates) {
      await getAllAlerts({ isLimit: false })
      
      setLoading(false)
      dispatch(setFilters({}))
      return
    }

    dispatch(setFilters({ startDate: dates[0], endDate: dates[1] }))

    await getAllAlerts({ isLimit: false, startDate: dates[0], endDate: dates[1] })
    
    setLoading(false)
  }

  return (
    <header>
      <CustomNavigate title="ALERTAS RECIBIDAS">
        <Row justify="end" align="middle">
          <CustomDatePicker width="300px" allowClear type="RANGER" onChange={handleOnChange} />
        </Row>
      </CustomNavigate>
    </header>
  )
}

export default HeaderActionsAlerts
