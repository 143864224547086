import { useState } from "react"
import { Button, Col, Row } from "antd"

// Para este custom component es necesario pasarle un use state porque es necesario tener ese estado 
// en el componente padre y asi cambiar o no el body component que se desea
const CustomSwitchButton = ({
  setCurrentBody,
  labelButtonOne = 'Dashboard',
  labelButtonTwo = 'Locales',
  labelStateOne = 'messaging',
  labelStateTwo = 'buildings',
}) => {
  // Estado para controlar el cambio de colores de los botones
  const [isActive, setIsActive] = useState(false)

  return (
    <div>
      <Row
        justify="space-between"
        style={{ background: "#FFFFFF", borderRadius: 50, width: 309, padding:2 }}
      >
        <Col span={12}>
          <Button
            htmlType="button"
            type="text"
            shape="round"
            style={{ width: '100%', background: isActive ? 'var(--primary)' : '' }}
            onClick={() => {
              setCurrentBody(labelStateOne)
              setIsActive(true)
            }} // Cambiara el mismo estado que se le mandara por props
          >
            <span
              style={{ color: isActive ? '#FFFFFF' : '', fontWeight: isActive ? 500 : 400, fontSize: 14, fontFamily: 'Roboto' }}
            >
              {labelButtonOne}
            </span>
          </Button>
        </Col>

        <Col span={12}>
          <Button
            htmlType="button"
            type="text"
            shape="round"
            style={{ width: '100%', background: !isActive ? 'var(--primary)' : '' }}
            onClick={() => {
              setCurrentBody(labelStateTwo)
              setIsActive(false)
            }} // Cambiara el mismo estado que se le mandara por props
          >
            <span
              style={{ color: !isActive ? '#FFFFFF' : '', fontWeight: !isActive ? 500 : 400, fontSize: 14, fontFamily: 'Roboto' }}
            >
              {labelButtonTwo}
            </span>  
          </Button>
        </Col>

      </Row>
    </div>
  )
}

export default CustomSwitchButton