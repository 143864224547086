import { Button, Row } from "antd";
import { useState } from "react";
import { CustomDatePicker } from "../../../../../../../../UI/CustomDatePicker";
import { CustomSelect } from "../../../../../../../../UI/CustomSelect";
import { CustomInput } from "../../../../../../../../UI/CustomInput";
import { searchIcon } from "../../../../../../assets/icons";
import { useAllCheckList } from "../../../../../hooks/useAllCheckList";
import moment from "moment";

const timesData = ["HOY", "MAÑANA", "LA SEMANA"];

const CheckFilters = ({ Form, form }) => {
  const [timeActive, setTimeActive] = useState(timesData[0]);
  const { getEmployeesService, getCategoriesService } = useAllCheckList();
  
  return (
    <div className="filters-container">
      <div className="filters-buttons">
        <div>
          {timesData.map((time) => (
            <Button
              key={time}
              onClick={() => {
                setTimeActive(time);
                if (time === "HOY") {
                  const initDay = moment().startOf("day");
                  const nextDay = moment().startOf("day").add(1, 'day');
                  form.setFieldsValue({ dates: [initDay, nextDay] });
                  return
                }
                if (time === "MAÑANA") {
                  const initDay = moment().startOf("day").add(1, 'day');
                  const nextDay = moment().startOf("day").add(2, 'day');
                  form.setFieldsValue({ dates: [initDay, nextDay] });
                  return;
                }
                if (time === "LA SEMANA") {
                  const initWeek = moment().startOf("week");
                  const endWeek = moment().endOf("week");

                  form.setFieldsValue({
                    dates: [initWeek, endWeek]
                  });
                  return;
                }
              }}
              className={`${timeActive === time && "time-active"}`}
            >
              {time}
            </Button>
          ))}
        </div>
        <Form.Item name="dates" noStyle>
          <CustomDatePicker
            placeholder={['Desde', 'Hasta']}
            allowClear={true}
            type={"RANGER"}
          />
        </Form.Item>
      </div>
      <div>
        <Form.Item name="name" noStyle>
          <CustomInput
            type={"TEXT"}
            suffixIcon={searchIcon}
            placeholder={"Buscar por nombre de tarea"}
          />
        </Form.Item>
      </div>
      <div className="filters-selects">
        <Form.Item name="category_id" noStyle>
          <CustomSelect itemsCount={25} isAsync={true} service={getCategoriesService} allowClear={true} placeholder="Todas las categorias" />
        </Form.Item>
        <Form.Item name="employee_id" noStyle>
          <CustomSelect isAsync service={getEmployeesService}  allowClear placeholder="Todos los empleados" />
        </Form.Item>
      </div>
      <Row align="center">
        <Button
          style={{ width: "155px" }}
          className="btn-standard-white"
          shape="round"
          htmlType="submit"
        >
          BUSCAR
        </Button>
      </Row>
    </div>
  );
};

export default CheckFilters;
