import { axiosCreate } from "../../../../../config/index"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const getCompanyAssetReportDetail = async ({
  idToken,
  company_asset_maintenance_id
}) => {
  try {
    const params = { company_asset_maintenance_id }

    const response = await axiosCreate.get('company/asset/report/detail', {
        params,
        timeout: 20000,
        headers: { Authorization: `Bearer ${idToken}`}
    })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getCompanyAssetReportDetail