import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const accessProvidersPath = 'event/provider-access'

export const updateAccessProvider = async ({
    token,
    formData
}) => {
    try {
        const response = await axiosCreate.put(`${accessProvidersPath}`, formData, {
            timeout: 10000, headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
}