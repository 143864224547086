import axios from "axios"
import { verifyBodyResponse } from "../../../../../helpers/verifyBodyResponse"
import { API_URL } from "../../../../../../config"

const getOrnamentChecklistCategoryDetail = async (instanceCategoryId, idToken) => {
  try {
    const response = await axios.get(`${API_URL}checklist/type/ornament/instance/category/detail?checklist_instance_category_id=${instanceCategoryId}`, 
      { headers: { Authorization: `Bearer ${idToken}` } }
    )

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getOrnamentChecklistCategoryDetail