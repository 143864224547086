import { useState } from "react"
import useSalesStatementServices from "./useSalesStatementServices"
import { defaulStateTable } from "../mocks/defaultStates"
import { salesStatementTableColumnsPrev, salesStatementTableFilters } from "../helpers/salesStatementTableHelper"
import { changeTableParams, getColumnsFilter } from "../../../helpers/customTableHelper"
import moment from "moment"
import { useAppSelector } from "../../../../hooks/store"

const topLimit = 10

export const useSalesStatement = () => {
    const { getAllMonthIncomesService, getPlacesService } = useSalesStatementServices()
    const auth = useAppSelector(state => state.auth)

    const [loading, setLoading] = useState(false)
    const [isTopData, setIsTopData] = useState(false)
    const [salesStatementTable, setSalesStatementTable] = useState(structuredClone(defaulStateTable))
    const [salesStatementFilters, setSalesStatementFilters] = useState({ place_id: undefined, date: moment().startOf('month').format('YYYY-MM-DD'), year_select: moment().year().toString(), month_select: moment().month().toString() })
    const [placesTreeData, setPlacesTreeData] = useState([])

    const pdfData = {
        table: salesStatementTable.dataTable,
        general: {
            first_name: auth?.committee?.name,
            last_name: auth?.committee?.lastname,
            mall: auth?.companyName,
            topLimit
        }
    }

    const paginationTable = {
        pageSize: salesStatementTable.metadata.limit,
        total: salesStatementTable.metadata.quantity,
        defaultPageSize: salesStatementTable.metadata.limit,
        current: salesStatementTable.currentPage,
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
        position: ["bottomRight"]
    }

    const serviceParams = { previousData: salesStatementTable.dataTable, actualLimit: salesStatementTable.metadata.limit, limit: salesStatementTable.metadata.limit, currentPage: salesStatementTable.currentPage, skip: 0 }

    const getInitialData = async () => {
        const placesData = await getPlacesService({ setLoading })
        const { data, metadata } = await getAllMonthIncomesService({ setLoading, ...serviceParams, date: salesStatementFilters.date, limit: topLimit })
        setSalesStatementTable(prev => ({
            ...prev,
            currentPage: 1,
            dataTable: data.dataSource,
            columns: data.columns,
            metadata,
            lastSkip: 0
        }))
        setPlacesTreeData(placesData)
    }

    const columns = salesStatementTableColumnsPrev({ columns: salesStatementTable.columns }).map(column => ({
        ...column,
        ...getColumnsFilter(column.dataIndex, salesStatementTableFilters({ currentParams: salesStatementTable.currentParams }))
    }))

    //Esta función es utilizada cuando el boton de Top 10 es clickeado, donde se comprueba el estado isTopData
    //Si es falso, es porque no estaba activo por lo que se reemplaza el limit en la solicitud del servicio y el quantity en el metadata se reemplaza por la constante topLimit, además de que la data para la tabla esta limitada al top limit
    //Si es verdadero, es porque esta activo, y establece un limit de 25 para obtener 25 elementos  y el metadata a guardar es el que proviene del servicio, por lo que el quantity es el que indica el endpoint
    const handleTopTenData = async () => {
        const { data, metadata } = await getAllMonthIncomesService({ setLoading, ...serviceParams, ...salesStatementTable.currentParams, date: salesStatementFilters.date, place_id: salesStatementFilters.place_id, limit: topLimit, skip: 0 })
        setSalesStatementTable(prev => ({
            ...prev,
            currentPage: 1,
            metadata: { ...metadata, quantity: !isTopData ? topLimit : metadata.quantity },
            dataTable: !isTopData ? data.dataSource.slice(0, topLimit) : data.dataSource,
        }))
        setIsTopData(prev => !prev)
    }

    //La función es utilizada en el onChange del treeSelect y los selects normales para filtrar por socio comercial, año y mes, recibiendo como parámetros el valor y el nombre del select que es afectado por el evento onChange    
    // Establece variaables para extraer el valor almacenado en el estado del mes, año y socio comercial seleccionado
    //Dependiendo del select que este cambiando va a establecerse un nuevo valor, ya sea para la date o para el place_id
    //Se hace la petición al endpoint con los parámetros neuvos, además de que igual se verifica si esta activo o no el botón de top 10, para modificar el limit
    //Luego se actualiza el estado de los filtros con los nuevos parámetros, igual el estado de la tabla con la nueva data, pero tomando en cuenta siempre si está activo el boton de top 10
    //En caso de que si esté activo, limitará la data a la cantidad del topLimit, al igual que modifcar la información del quantity en la metadata
    const handleChangeFilter = async ({ value, name }) => {
        let currentMonth_Select = salesStatementFilters.month_select
        let currentYear_Select = salesStatementFilters.year_select
        let date = moment(`${salesStatementFilters.year_select}-01-01`).add(salesStatementFilters.month_select, 'months').format('YYYY-MM-DD')
        let place_id = salesStatementFilters.place_id
        if (name === 'year_select') {
            date = moment(`${value}-01-01`).add(salesStatementFilters.month_select, 'months').format('YYYY-MM-DD')
            currentYear_Select = value
        }
        if (name === 'month_select') {
            date = moment(`${salesStatementFilters.year_select}-01-01`).add(value, 'months').format('YYYY-MM-DD')
            currentMonth_Select = value
        }
        if (name === 'place_select') {
            place_id = value
        }

        const { data, metadata } = await getAllMonthIncomesService({ setLoading, ...serviceParams, ...salesStatementTable.currentParams, date, place_id, limit: topLimit })
        setSalesStatementFilters(prev => ({ ...prev, date, place_id, year_select: currentYear_Select, month_select: currentMonth_Select }))
        setSalesStatementTable(prev => ({
            ...prev,
            currentPage: 1,
            dataTable: isTopData ? data.dataSource.slice(0, topLimit) : data.dataSource,
            metadata: { ...metadata, quantity: isTopData ? topLimit : metadata.quantity },
            lastSkip: 0
        }))
    }

    //Esta funcion se utiliza en la tabla para determinar si la paginación el sorter o los filtros internos de la tabla han cambiado
    //Se define en un objeto otherParams los parámetros externos a la tabla que será utilizado luego
    //Con la función changeTableParams se obtienen los nuevos parámetros y se cambia la paginación, en caso de no existir nuevos parámetros se retorna la función
    //Se consulta al servicio con los parámetros nuevos y se establece el nuevo estado de la tabla, donde el lastskip cambiará debidio a nuevos datos    
    //Se verifica si el boton de top 10 está activo, en caso de que si esté activo, limitará la data a la cantidad del topLimit, al igual que modifcar la información del quantity en la metadata
    const handleChangeTable = async ({ pagination, filters, sorter }) => {
        const otherParams = {
            place_id: salesStatementFilters.place_id,
            date: salesStatementFilters.date
        }

        const tableParams = changeTableParams({ serviceParams, pagination, filters, sorter, otherParams, state: salesStatementTable, setState: setSalesStatementTable })
        if (!tableParams) return
        const { data, metadata } = await getAllMonthIncomesService({ setLoading, ...tableParams.finalParams })
        setSalesStatementTable(prev => ({
            ...prev,
            dataTable: isTopData ? data.dataSource.slice(0, topLimit) : data.dataSource,
            metadata: { ...metadata, quantity: isTopData ? topLimit : metadata.quantity },
            currentParams: tableParams.newParams,
            lastSkip: tableParams.finalParams.skip
        }))
    }

    return { getInitialData, handleChangeTable, handleChangeFilter, handleTopTenData, columns, placesTreeData, loading, salesStatementTable, salesStatementFilters, pdfData, isTopData, paginationTable }
}

export default useSalesStatement