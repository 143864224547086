import { useContext } from "react"
import { Col, Form, Row } from "antd"
import { config } from '../../../../../../constants/constants'
import { CustomInput } from './../../../../../../UI/CustomInput'
import { AmenitiesContext } from "../../../../context/AmenitiesContext"
import CustomTreeSelect from './../../../../../../UI/CustomSelects/CustomTreeSelect'
import { CustomUploadFile } from './../../../../../../UI/CustomUploadFile/CustomUploadFile'

const GeneralAmenityData = () => {
  const { Item } = Form

  const { fileList, setFileList, placesAndZonesTreeSelect } = useContext(AmenitiesContext)

  return (
    <section style={{ backgroundColor: '#FFFFFF', borderRadius: 16, padding: '50px 25px' }}>
      <Row>
        <Col span={24}>
          <h2 style={{ fontWeight: 600, fontSize: 20 }}>
            DATOS GENERALES DE LA AMENIDAD
          </h2>

          <Row gutter={16}>
            <Col span={12}>
              <label style={{ fontWeight: 400, fontSize: 12, color: '#252525' }}>
                NOMBRE DE LA AMENIDAD
              </label>

              <Item name="amenity_name" {...config}>
                <CustomInput className="custom-input-amenity" placeholder="Escribe el nombre de la amenidad" />
              </Item>

              <label style={{ fontWeight: 400, fontSize: 12, color: '#252525' }}>
                UBICACIÓN DE LA AMENIDAD
              </label>

              <CustomTreeSelect
                isToForm
                treeData={placesAndZonesTreeSelect}
                className="standard-tree-select-mall"
                name="parent_id"
                config={config}
                placeholder="Seleccione una ubicación"
              />
            
              <label style={{ fontWeight: 400, fontSize: 12, color: '#252525' }}>
                DESCRIPCIÓN
              </label>

              <Item name="description">
                <CustomInput maxLength={1000} className="custom-textarea-amenity" autoSize={{ minRows: 4, maxRows: 10 }} type="AREA" placeholder="Puedes escribir aquí una descripción de la amenidad" />
              </Item>
            </Col>

            <Col span={12}>
              <label style={{ fontWeight: 400, fontSize: 12, color: '#252525' }}>
                IMAGEN DE PORTADA
              </label>
              <Row justify="center">
                <Item name='file' rules={[{ validator: async (_, value) => { return fileList?.length ? Promise.resolve() : Promise.reject(new Error('Debe haber un archivo o imagen a subir')) } }]}>
                  <CustomUploadFile fixedWidth multipleFiles justImages className="custom-upload-file-amenity" fileList={fileList} setFileList={setFileList} />
                </Item>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  )
}

export default GeneralAmenityData