import { Col, Row } from 'antd'
import ContainerTabs from './components/ContainerTabs'
import HeaderActionsAlerts from './components/HeaderActionsAlerts'
import { useState } from 'react'
import { useFeatureRole } from '../../hooks/useFeatureRole'
import { ALERTS_CODES, ALERTS_CODES_NAMES } from '../../../constants/modules/alerts/alertsCodes'
import { CustomSpin } from '../../UI/CustomSpin'

export const AlertsReceivedPage = () => {
  const [loading, setLoading] = useState(false)

  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: ALERTS_CODES, validate_code: ALERTS_CODES_NAMES.ALERTS_MODULE })

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (
    <section className="section-wrapper">
      <HeaderActionsAlerts setLoading={setLoading} />
      <Row>
        <Col span={24}>
          <ContainerTabs activeFeatures={activeFeatures} loading={loading} setLoading={setLoading} />
        </Col>
      </Row>
    </section>
  )
}


