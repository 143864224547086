import { AccessVisitorsTab } from './AccessVisitorsTab'
// import { AccessResidentsTab } from './AccessResidentsTab'
import CustomTabs from '../../../UI/CustomTabs/CustomTabs'
import { AccessScheduleVisitorsTab } from './AccessScheduleVisitorsTab'

import '../style.css'

const AccessTabs = ({ buscarFechas, activeFeatures }) => {
  const accessOptions = [
    // { label: 'RESIDENTES', children: <AccessResidentsTab /> },
    { label: 'VISITANTES', children: <AccessVisitorsTab activeFeatures={activeFeatures} /> },
    { label: 'VISITANTES PROGRAMADOS', children: <AccessScheduleVisitorsTab activeFeatures={activeFeatures} /> },
  ]

  return (
    <CustomTabs className='custom-tabs-variant-1' defaultActiveKey="0" items={accessOptions} onChange={buscarFechas} />
  )
}

export default AccessTabs