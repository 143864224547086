import { useContext } from "react"
import { Button, Col, Row } from "antd"
import EmptyComponent from "../../../../UI/EmptyComponent"
import { useAppSelector } from "../../../../../hooks/store"
import EditIcon from "../../../../../assets/icons/EditIcon"
import NewEditSiteMapModal from "../modals/NewEditSiteMapModal"
import { AdminContractsContext } from "../../context/AdminContractsContext"
import { formatUTCDateInTimeZone } from "../../../../helpers/customDateHelpers"
import PdfFileIcon from "../../../../../assets/icons/Generals/ContractsFlow/PdfFileIcon"
import DownloadIcon from '../../../../../assets/icons/Generals/ContractsFlow/downloadDownArrow.svg'
import MapIcon from '../../../../../assets/icons/Generals/ContractsFlow/emptyMapsIcon.svg'

const SpecificationsBody = ({ form, isOpenModal, handleOpenCloseModal }) => {
  const { setFileList, setMapDataSelected } = useContext(AdminContractsContext)

  const { specifications } = useAppSelector(state => state.detailContracts)

  // Estas opciones son el formato 24/01/2024, 6:08 p. m.
  const customOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true, // Use 12-hour clock
  }

  const handleEditMapModal = (localData) => {
    setMapDataSelected(localData)
    setFileList([{ uid: '-1', name: 'Archivo adjuntado anteriormente', status: 'done', url: localData?.PLACE_FILE?.file }])
    handleOpenCloseModal()
    form.setFieldsValue({
      name: localData?.PLACE_FILE?.name,
      description: localData?.description,
      updated_by: localData?.updatedBy,
      general_specs: localData?.generalSpecs,
      file: [{ uid: '-1', name: 'Archivo adjuntado anteriormente', status: 'done', url: localData?.PLACE_FILE?.file }]
    })
  }

  if (!(specifications?.data?.placeFileDetail?.length)) return (
    <>
      <section style={{
        width: "100%",
        height: 196,
        padding: 40,
        backgroundColor: "#FFFFFF",
        borderRadius: 8,
        margin: "15px 0",
        boxShadow: "0px 6px 7px 0px #9AB9D440",
      }}>
        <EmptyComponent height="" Icon={<img src={MapIcon} alt="empty-contracts-icon" />} label="NO EXISTEN PLANOS" />
      </section>
      <NewEditSiteMapModal form={form} isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
    </>
  )

  return (
    <Row>
      <Col span={24}>
        {specifications?.data?.placeFileDetail?.map((specificationData, index) => (
          <section key={specificationData.id} style={{
            width: "100%",
            padding: 40,
            backgroundColor: "#FFFFFF",
            borderRadius: 8,
            margin: "15px 0",
            boxShadow: "0px 6px 7px 0px #9AB9D440",
          }}>
            <Row gutter={24}>
              <Col span={16}>
                <h3 style={{ fontSize: 14, fontWeight: 400, color: "#8C8C8C" }}>
                  TÍTULO
                </h3>
                
                <h2>
                  {specificationData?.PLACE_FILE?.name} - actualización {' '}
                    {formatUTCDateInTimeZone({
                      utcDate: (specificationData?.PLACE_FILE?.created_at) ? (new Date(specificationData?.PLACE_FILE?.created_at)) : new Date(),
                      customOptions: customOptions,
                    })}
                </h2>

                <p style={{ fontWeight: 400, fontSize: 14, color: "#232C4A" }}>
                  Última actualización: {' '}
                    <span style={{ color: "var(--primary)" }}>
                      {formatUTCDateInTimeZone({
                        utcDate: (specificationData?.PLACE_FILE?.created_at) ? (new Date(specificationData?.PLACE_FILE?.created_at)) : new Date(),
                        customOptions: customOptions,
                      })}
                    </span>
                </p>

                <label style={{ fontSize: 14, fontWeight: 400, color: "#8C8C8C" }}>
                  DESCRIPCIÓN DE CAMBIOS
                </label>
                <p style={{ fontSize: 14, fontWeight: 400 }}>
                  {specificationData?.description}
                </p>

                <label style={{ fontSize: 14, fontWeight: 400, color: "#8C8C8C" }}>
                  ENCARGADO DE APROBACIÓN
                </label>
                <p style={{ fontSize: 14, fontWeight: 400 }}>
                  {specificationData?.updatedBy}
                </p>

                <label style={{ fontSize: 14, fontWeight: 400, color: "#8C8C8C" }}>
                  ESPECIFICACIONES GENERALES:
                </label>
                <p style={{ fontSize: 14, fontWeight: 400 }}>{specificationData?.generalSpecs}</p>
              </Col>

              <Col span={8} style={{ position: "relative" }}>
                {index === 0 && <div style={{ position: "absolute", top: 0, right: 0 }}>
                  <Button
                    type="text"
                    shape="round"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      width: 111,
                      height: 25,
                      backgroundColor: "#FFFFFF",
                      border: "1px solid var(--primary)",
                    }}
                    icon={<EditIcon color="var(--primary)" width="16px" />}
                    onClick={() => handleEditMapModal(specificationData)}
                  >
                    <span style={{ fontSize: 12, fontWeight: 400, color: "#616161" }}>Editar</span>
                  </Button>
                </div>}

                <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                  <Col span={24} style={{ width: 286, border: "1px solid #ADADAD", borderRadius: 8, padding: 15 }}>
                    <label style={{ fontSize: 14, fontWeight: 600, color: "#232C4A" }}>
                      PLANOS DEL LOCAL
                    </label>

                    <Row align="middle" justify="space-between" style={{ marginTop: 25 }}>
                      <Col>
                        <PdfFileIcon />
                      </Col>

                      <Col>
                        <span style={{ fontWeight: 400, fontSize: 14 }}>
                          Planos arquitectónicos
                        </span>
                      </Col>

                      <Col>
                        <Button
                          type="link"
                          htmlType="button"
                        >
                          <a
                            href={specificationData?.PLACE_FILE?.file}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <img src={DownloadIcon} alt="download-icon" />
                          </a>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Col>
            </Row>
          </section>
        ))}
      </Col>
      <NewEditSiteMapModal form={form} isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
    </Row>
  )
}

export default SpecificationsBody