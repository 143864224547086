import { createSlice } from '@reduxjs/toolkit';

export const accessSlice = createSlice({
    name: 'access',
    initialState: {
        residents: {
            data: [],
            active: null,
            loadResidente:true,
            loadVisitante:true
        },
        visitors: {
            data: [],
            active: null,
            loadResidente:true,
        },
        scheduledVisitors: {
            data: [],
            active: null,
            loadResidente:true,
        },
        errorMessage: null
    },
    reducers: {
        setResidentsData: ( state, { payload } ) => {
            state.residents.data = payload;
            state.residents.loadResidente = false;
            state.visitors.loadResidente = false;
            state.scheduledVisitors.loadResidente = false;
        },
        setLoadResidente: ( state, { payload } ) => {
            state.residents.loadResidente = true;
            state.visitors.loadResidente = true;
            state.scheduledVisitors.loadResidente = true;
        },
        setVisitorsData: ( state, { payload } ) => {
            state.visitors.data = payload;
            state.visitors.loadResidente = false;
        },
        setScheduledVisitors: ( state, { payload } ) => {
            state.scheduledVisitors.data = payload;
            state.scheduledVisitors.loadResidente = false
        },


        setActiveResident: ( state, { payload } ) => {
            state.residents.active = payload;
        },
        setActiveVisitor: ( state, { payload } ) => {
            state.visitors.active = payload;
        },
        setActiveScheduleVisitor: ( state, { payload } ) => {
            state.scheduledVisitors.active = payload;
        },
    }
});


export const { 
    setResidentsData,
    setVisitorsData,
    setScheduledVisitors,
    setActiveResident,
    setActiveVisitor,
    setActiveScheduleVisitor,
    setLoadResidente
} = accessSlice.actions;