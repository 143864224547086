import { Button, Row } from 'antd';
import React, { useState, useEffect } from 'react';

const SuppliesSelected = ({ supply, selectedItems, setSelectedItems }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const existingItem = selectedItems.find(item => item.id === supply.id);
        if (existingItem) {
            setCount(existingItem.quantity);
        }
    }, [selectedItems, supply.id]);

    const handleIncrement = () => {
        if (count < supply.stock) {
            const newCount = count + 1;
            setCount(newCount);
            updateSelectedItemCount(newCount);
        }
    };
    
    const handleDecrement = () => {
        if (count > 0) {
            const newCount = count - 1;
            setCount(newCount);
            updateSelectedItemCount(newCount);
        }
    };

    const updateSelectedItemCount = (newCount) => {
        if (newCount > 0) {
            const existingItemIndex = selectedItems.findIndex(item => item.id === supply.id);
            if (existingItemIndex !== -1) {
                const updatedItems = [...selectedItems];
                updatedItems[existingItemIndex].quantity = newCount;
                setSelectedItems(updatedItems);
            } else {
                setSelectedItems([...selectedItems, { id: supply.id,name: supply.name , quantity: newCount, price: supply.price }]);
            }
        } else {
            const updatedItems = selectedItems.filter(item => item.id !== supply.id);
            setSelectedItems(updatedItems);
        }
    };

    return (
        <Row
            style={{
                border: "1px solid #DBDBDB",
                borderRadius: "30px",
                marginBottom: "0.4rem",
                position: "relative",
            }}
            justify="center"
        >
            <Button type="ghost" style={{ border: "none", opacity: !count && 0 }} onClick={handleDecrement} disabled={count <= 0}>
                -
            </Button>
            <p style={{ position: "absolute", top: 5 }}>{count}</p>
            <Button type="ghost"  style={{ border: "none", opacity: count >= supply.stock ? 0 : 1 }} onClick={handleIncrement} disabled={count >= supply.stock}>
                +
            </Button>
        </Row>
    );
};

export default SuppliesSelected;
