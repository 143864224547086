import moment from "moment"
import { useSurveyContext } from "../contexts/SurveyContext"
import { useSurveyFormContext } from "../contexts/SurveyFormContext"
import { formData } from "../mocks/formData"

export const useSurveysForm = () => {

    const { setLoadingSurveys, setSurveys, dispatch, loading } = useSurveyContext()
    const { initialFormData, saveTempRef, form, Form, formModal, formRef, currentStep, listPreguntas, listPreguntasEliminadas, setListPreguntasEliminadas, setCurrentStep, setFormModal, setListPreguntas } = useSurveyFormContext()

    const onSubmitForm = async (values) => {
        const formData = values
        setListPreguntas((prev) => ({ ...prev, ...formData })) //Para cuando se pasa de un step a otro
        const data = { ...listPreguntas, ...formData, listPreguntasEliminadas }
        data.listPreguntas = data.listPreguntas && data.listPreguntas.map(({ opciones, ...question }) => question.type === 'option' ? { ...question, opciones } : question)

        if (saveTempRef.current) {
            data.eraser = true
        }
        if (currentStep === 3) {
            data.startDate = moment(data.startDate).startOf('day').toDate()
            data.endDate = moment(data.endDate).endOf('day').toDate()
            data.eraser = false
        }
        if (!saveTempRef.current && currentStep !== 3) return setCurrentStep((prev) => prev + 1)
        saveTempRef.current = false
        dispatch(setLoadingSurveys({ status: true }))
        await dispatch(setSurveys(data))
        return handleCloseForm()
    }

    const handleChangeQuestionType = ({ field, value }) => {
        const questions = form.getFieldValue('listPreguntas')
        const question = form.getFieldValue('listPreguntas')[field.name]
        if (question.type !== 'option') {
            delete question.opciones
        }
        if (question.type === 'option') {
            question.opciones = [{}]
        }
        questions[field.name] = question
        form.setFieldsValue({
            listPreguntas: questions
        })
    }

    const handleChangeDatePicker = (value) => {
        form.setFieldsValue({
            endDate: value
        })
    }

    const handleRemoveQuestion = (field) => {
        const deletedQuestion = form.getFieldValue('listPreguntas')[field.name]
        if (!deletedQuestion.idR) return
        setListPreguntasEliminadas((prev) => [...prev, deletedQuestion])
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        formRef.current.submit()
    }

    const handleCloseForm = () => {
        dispatch(setLoadingSurveys({ status: false }))
        setFormModal(false)
        setListPreguntas({})
        setListPreguntasEliminadas([])
        setCurrentStep(1)
        form.setFieldsValue(formData)
    }

    const config = {
        rules: [{ required: true, message: 'Por favor, llenar el campo' }]
    }

    return (
        {
            handleChangeQuestionType, handleChangeDatePicker, handleCloseForm, handleRemoveQuestion, handleSubmit, setCurrentStep, onSubmitForm, setListPreguntasEliminadas, setFormModal,
            loading, config, initialFormData, saveTempRef, formModal, form, Form, formRef, currentStep, listPreguntas, listPreguntasEliminadas,
        }
    )
}

export default useSurveysForm