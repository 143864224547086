import { useContext } from "react"
import { Button, Row } from "antd"
import { UsersContext } from "../../context/UsersContext"
import { useAppSelector } from "../../../../../hooks/store"
import { CustomNavigate } from "../../../../UI/CustomNavigate"
import CustomPopoverConfirm from "../../../../UI/CustomPopoverConfirm"
import { USERS_CODES } from "../../../../../constants/modules/user/userCodes"

// imagenes o archivos
import newUser from '../../../../../assets/icons/addNewUser.svg'

const UsersHeaderActions = ({ activeFeatures }) => {
  const { companyName } = useAppSelector(state => state.auth)

  const { form, handleOpenCloseModal, setUserSpaces, setUserClicked } = useContext(UsersContext)

  const handleOnChangeNewEditUser = async () => {
    form.resetFields()
    handleOpenCloseModal()
    setUserSpaces([])
    setUserClicked({})
  }

  return (
    <header style={{ marginBottom: 35 }}>
      <h2 style={{ fontSize: 14, color: 'var(--primary)', fontWeight: 600 }}>
        ADMINISTRACIÓN DE USUARIOS
      </h2>

      <CustomNavigate title={companyName}>
        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === USERS_CODES.USERS_NEW_USER)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nuevo usuario'}`}>
          <Button
            disabled={!activeFeatures?.find(feature => feature.feature_code === USERS_CODES.USERS_NEW_USER)?.enabled}
            type="button"
            shape="round"
            htmlType="button"
            onClick={handleOnChangeNewEditUser}
            className="btn-standard-green"
            style={{ width: 250 }}
          >
            <Row>
              <img style={{ position: 'relative', right: 15 }} src={newUser} alt="img-user" />
              
              <span>
                NUEVO USUARIO
              </span>
            </Row>
          </Button>
        </CustomPopoverConfirm>
      </CustomNavigate>
    </header>
  )
}

export default UsersHeaderActions