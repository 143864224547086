import { useState } from "react"
import { useAppSelector } from "../../../../hooks/store"
import { getConversationsByProfileId } from "../services"
import verifyResponse from "../../../helpers/verifyResponse"

const useChatsList = () => {
  const [loading, setLoading] = useState(false)
  const [metadata, setMetadata] = useState({})
  const [conversationsList, setConversationsList] = useState([])
  
  const { currentRol, idToken, profileId } = useAppSelector(state => state.auth)

  const getAllConversations = async (skip = 0, limit = 10) => {
    setLoading(true)
    const response = await getConversationsByProfileId({ companyId: currentRol,  profileId: profileId, skip: skip, limit: limit, idToken: idToken })

    if (response.success) {
      setConversationsList(response.data.data)
      setMetadata(response.data.metadata)
      setLoading(false)
      return true
    }

    setLoading(false)
    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  return {
    loading,
    metadata,
    conversationsList,
    setConversationsList,
    getAllConversations
  }
}

export default useChatsList