import { Button, Checkbox } from "antd";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import WorkDayModal from "./modal/WorkDayModal";
import useOpenCloseModal from "../../../hooks/useOpenCloseModal";
import { CountDownContext } from "../../../../context/CountDownContext";
import EditIcon from "../../../../assets/icons/EditIcon";
import moment from "moment";
import { useAppSelector } from "../../../../hooks/store";
import { TICKETS_CODES } from "../../../../constants/modules/tickets/ticketsCodes";
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm";

const WorkDay = () => {
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal();
  const { ticketById, flags } = useSelector((state) => state.detailTicketById);
  const { handleIsOpenCloseModal } = useContext(CountDownContext);
  const { activeFeatures } = useAppSelector(state => state.roles)
  const handleModalOpen = () => {
    handleOpenCloseModal();
    handleIsOpenCloseModal();
  };
  const handleModalClose = () => {
    handleIsOpenCloseModal();
    handleOpenCloseModal();
  };
  const workSessionsInspection =
    ticketById?.TICKET_INSPECTION?.TICKET_INSPECTION_WORK_SESSIONs || [];
  const workSessions = ticketById?.TICKET_WORK_SESSIONs;
  const minutes = ticketById?.TICKET_INSPECTION?.expectedMinutes;
  const timeResult = (Math.round((minutes / 60) * 100) / 100)
    .toFixed(2)
    .replace(".", ":");

  return (
    <div>
      <p>
        Tiempo estimado de reparación: {timeResult}{" "}
        {minutes < 60 ? "minutos" : "hora(s)"}
      </p>
      <p>Fecha(s) de mantenimiento:</p>
      {ticketById?.TICKET_INSPECTION?.TICKET_INSPECTION_STATUS?.code ===
        "accepted" ? (
        <ul>
          {workSessions?.slice()?.map((item, index) => (
            <li
              key={item?.id}
              style={{ textDecoration: item.done ? "line-through" : "none" }}
            >
              Jornada {index + 1} - (
              {moment(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.date).format('DD/MM/YYYY')}{" "}
              {moment(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeBegin, 'HH:mm:ss').format('h:mm A')} {" - "}
              {moment(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeEnd, 'HH:mm:ss').format('h:mm A')})
              <Checkbox
                style={{ marginLeft: "1rem" }}
                disabled
                checked={item.done ? true : false}
              />
            </li>
          ))}
        </ul>
      ) : (
        <ul>
          {workSessionsInspection?.slice()?.map((item, index) => (
            <li
              style={{
                color:
                  item?.EMPLOYEE_ACTIVITY_SCHEDULE?.EMPLOYEE_ACTIVITY_STATUS
                    ?.code !== "pending"
                    ? "red"
                    : "black",
              }}
              key={item?.id}
            >
              Jornada {index + 1} -{" "}
              {item?.EMPLOYEE_ACTIVITY_SCHEDULE?.EMPLOYEE_ACTIVITY_STATUS
                ?.code !== "pending" && "Deshabilitada - "}
              ({moment(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.date).format('DD/MM/YYYY')}{" "}
              {moment(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeBegin, 'HH:mm:ss').format('h:mm A')} {" - "}
              {moment(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeEnd, 'HH:mm:ss').format('h:mm A')})
            </li>
          ))}
        </ul>
      )}
      {flags?.enabledButtonApproveInventory && (
        <div >
          <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_UPDATE_WORKDAY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Editar'}`}>
            <Button
              disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_UPDATE_WORKDAY)?.enabled}
              onClick={handleModalOpen}
              style={{
                border: "none",
                position: "absolute",
                right: "1rem",
                top: "1rem",
              }}
              type="ghost"
            >
              <EditIcon color="var(--primary)" width="20px" />
            </Button>
          </CustomPopoverConfirm>
        </div>
      )}

      <WorkDayModal isOpenModal={isOpenModal} onCancel={handleModalClose} />
    </div>
  );
};

export default WorkDay;
