import React, { useState } from "react";
import { Row, Button, Input } from "antd";
import CustomModal from "../../../../../../../UI/CustomModal";
import EquipmentSelected from "./modalComponents/EquipmentSelected";

const EquipmentTaskModal = ({setSave, onCancel, isOpenModal, inventoryData, setDataItems, setSearchWord}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const itemData = Object.values(inventoryData[1]?.items || {});
  const allItemNames = itemData?.flatMap((item) => {
    if (Array.isArray(item)) {
      return item.map((item) => item);
    }
    return [];
  });
  
  const handleSave = () => {
    setSave(true);
    setDataItems(selectedItems);
    onCancel();
  }

  return (
    <CustomModal destroyOnClose onCancel={onCancel} isOpenModal={isOpenModal}>
      <h3
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "22px",
          marginBottom: "2rem",
          color: "#232C4A",
        }}
      >
        ASIGNAR EQUIPO
      </h3>
      <Input
            style={{ fontSize: "1rem", width:'100%' }}
            placeholder="Buscar"
            onChange={(e) => setSearchWord(e.target.value)}
          />
      <div style={{ marginTop: "1rem", maxHeight: "300px", overflowY: "auto" }}>
      {allItemNames?.map((item, i) => ( 
        <Row key={i}
          justify="space-between"
          align="middle"
          style={{
            border: "2px solid #DBDBDB",
            marginBottom: "0.5rem",
            paddingInline: "2rem",
            paddingTop: "0.5rem",
            borderRadius: "4px",
          }}
        >
          <p style={{ paddingTop: "0.2rem" }}>{item.name}</p>
          <EquipmentSelected equipment={item} selectedItems={selectedItems} setSelectedItems={setSelectedItems}/>
        </Row>
      ))}
      </div>
      <Row justify="center" style={{ marginTop: "3rem" }}>
        <Button onClick={handleSave} className="btn-standard-green" shape="round" type="ghost">
          <span>GUARDAR</span>
        </Button>
      </Row>
    </CustomModal>
  );
};

export default EquipmentTaskModal;
