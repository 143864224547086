import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const placePath = "place/tree"

export const getPlaces = async ({
    token,
    company_id = 1,
    parent_id,
}) => {
    try {
        const params = {
            company_id,
            parent_id
        };

        const response = await axiosCreate.get(`${placePath}`, {
            params, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
};