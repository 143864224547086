import { Col, Row } from "antd"
import { getDetailPlaceById } from "../../../../store/malls/thunks"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import BuildingComponent from "../../../../assets/icons/BuildingsSketch/BuildingComponent"
import { setIdClickedPlace } from "../../../../store/malls/mallsSlice"

const SketchComponent = ({
  handleOpenCloseDrawer
}) => {
  const dispatch = useAppDispatch()

  const { idLevelSelected } = useAppSelector(state => state.malls)
  
  const onFinish = (isSuccessfull) => {
    if (isSuccessfull) {
      handleOpenCloseDrawer()
    }
  }

  const handleOnClickToGetLocalId = async (event) => {
    dispatch(setIdClickedPlace((event.target.id).toString()))
    const localId = ((event.target.id).toString()) ?? ''

    const response = await dispatch(await getDetailPlaceById(localId))

    onFinish(response)
  }

  return (
    <Row>
      <Col span={24}>
        {/* Componente padre de todos los mapas que realizara el verifyBuildingsketch cuando cambie el select */}
        <Row justify="center" align="middle" style={{ marginTop: 25 }}>
          <BuildingComponent idLevelSelected={idLevelSelected} handleOnClickToGetLocalId={handleOnClickToGetLocalId} />
        </Row>
      </Col>
    </Row> 
  )
}

export default SketchComponent