import { API_URL } from "../../../../../config/index";
import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const rolPath = "role/company"

//Endpoint de topia que permite recuperar los roles de una cuenta con propiedades para crear una tabla
export const getRoles = async ({
    token,
    company_id = 1,
    sort_order,
    sort_by,
    find_value,
    find_by,
    limit = 7,
    skip = 0,
}) => {
    try {
        const params = {
            company_id,
            sort_by,
            sort_order,
            find_by,
            find_value,
            limit,
            skip,
        };

        const response = await axiosCreate.get(`${API_URL}${rolPath}`, {
            params, timeout: 15000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }
};