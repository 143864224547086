import { useSurveyContext } from "../contexts/SurveyContext";
import * as ExcelJS from 'exceljs'
import { saveAs } from "file-saver";

export const useSurveysCharts = () => {

  const { surveys, opcionesGrafica, modalVisible } = useSurveyContext()

  const actualSurvey = surveys.active || {}
  //Crear el dataset que pueda ser usado con la librería de Chartjs
  const questionsAndAnswers = actualSurvey?.preguntas?.map(question => {
    const answerMatched = actualSurvey?.respuesta?.filter(answer => answer.question_id === question.idR).map(answer => {
      if (question.type === 'qualify') {
        return ({ //Si el tipo de pregunta es de valoración, verificar si la respuesta lleva ".0", ya que en algunos casos desde firebase se devuelve un numero entero
          ...answer,
          response: answer?.response?.length === 1 ? answer?.response?.concat('.0') : answer?.response
        })
      }
      return answer
    })
    return {
      ...question,
      answers: answerMatched
    }
  })

  const profileMap = actualSurvey?.profiles?.reduce((acc, profile) => {
    acc[profile.id] = profile
    return acc
  }, {})

  const chartData = questionsAndAnswers?.map(question => {
    const answerWithProfile = question?.answers?.map(answer => {
      return {
        ...answer,
        profile: profileMap[answer.profile_id]
      }
    });
    return {
      ...question,
      answers: answerWithProfile
    }
  }).sort((a, b) => a.order - b.order)

  const downloadSurvey = async () => {

    const workBook = new ExcelJS.Workbook();
    const workSheet = workBook.addWorksheet(`Resultados`)
    workSheet.columns = [
      { key: 'id', width: 30, },
      { key: 'name', width: 50, },
      { key: 'answer', width: 50, },
    ]
    workSheet.mergeCells('A1:C1')
    workSheet.getCell('A1').value = actualSurvey.name
    workSheet.getCell('A1').alignment = { horizontal: 'center' }
    workSheet.getCell('A1').font = { size: 16, bold: true }
    workSheet.addRow({ id: 'INICIO', name: actualSurvey.startDate })
    workSheet.addRow({ id: 'FINALIZACIÓN', name: actualSurvey.endDate, })
    workSheet.addRow({ id: 'ENCUESTAS CONTESTADAS', name: actualSurvey.encuestasContestadas })
    workSheet.getCell('A2').font = { bold: true }
    workSheet.getCell('A3').font = { bold: true }
    workSheet.getCell('A4').font = { bold: true }

    chartData.forEach((question, index) => {
      workSheet.addRow([])
      workSheet.addRow([`Pregunta #${question.order + 1}: ${question.question}`]).font = { bold: true, size: 14 }
      workSheet.addRow([])
      workSheet.addRow({ id: 'ID', name: 'Nombre', answer: 'Respuesta' })

      question.answers.forEach(answer => {
        workSheet.addRow({
          id: answer.profile_id,
          name: `${answer.profile.name} ${answer.profile.lastname}`,
          answer: answer.response
        })
      })
    })

    const buffer = await workBook.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: 'application/vmd.openxmlformats-officedocument.spreadsheetml.sheet' })
    saveAs(blob, `${actualSurvey.name}.xlsx`)
  }

  return (
    {
      downloadSurvey,
      opcionesGrafica,
      modalVisible,
      chartData,
    }
  )
}

export default useSurveysCharts