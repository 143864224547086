import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  detailBooking: {},
  statusBookings: [],
  bookingsTableData: {},
  amenitiesBooking: [],
  statusBookingsCalendar: [],
  filtersCalendar: {
    date_begin: '',
    date_end: '',
  },
  filters: {
    sortBy: '',
    sortOrder: '',
    placeId: '',
    profileId: '',
    dateBegin: '',
    dateEnd: '',
  },
  metadata: {
    rest: null,
    quantity: null,
    limit: null,
    skip: null,
  },
  loadingReserves: false,
  currentActiveTab: '',
  currentOptionModalBody: '',
  currentBookingId: 0,
}

export const bookingsSlice = createSlice({
  name: "bookingsSliceByCompanyId",
  initialState,
  reducers: {
    setDetailBooking: (state, { payload }) => {
      return {
        ...state,
        detailBooking: payload,
      }
    },
    setStatusBookings: (state, { payload }) => {
      return {
        ...state,
        statusBookings: payload
      }
    },
    setBookingsTableData: (state, { payload }) => {
      return {
        ...state,
        bookingsTableData: payload
      }
    },
    setAmenitiesBooking: (state, { payload }) => {
      return {
        ...state,
        amenitiesBooking: payload
      }
    },
    setAllFilters: (state, { payload }) => {
      return {
        ...state,
        filters: payload
      }
    },
    setMetaData: (state, { payload }) => {
      return {
        ...state,
        metadata: payload
      }
    },
    setCurrentActiveTab: (state, { payload }) => {
      return {
        ...state,
        currentActiveTab: payload
      }
    },
    setCurrentOptionModalBody: (state, { payload }) => {
      return {
        ...state,
        currentOptionModalBody: payload
      }
    },
    setCurrentBookingId: (state, { payload }) => {
      return {
        ...state,
        currentBookingId: payload
      }
    },
    setLoadingReserves: (state, { payload }) => {
      return {
        ...state,
        loadingReserves: payload
      }
    },
    setFiltersCalendar: (state, { payload }) => {
      state.filtersCalendar = payload
    },
    setStatusBookingsCalendar: (state, { payload }) => {
      state.statusBookingsCalendar = payload
    }
  },
});

export const {
  setMetaData,
  setAllFilters,
  setDetailBooking,
  setStatusBookings,
  setLoadingReserves,
  setFiltersCalendar,
  setAmenitiesBooking,
  setCurrentActiveTab,
  setCurrentBookingId,
  setBookingsTableData,
  setStatusBookingsCalendar,
  setCurrentOptionModalBody,
} = bookingsSlice.actions;
