import { notification, Typography } from "antd";
import { savedIcon, continueIcon, deletedIcon, pausedIcon, SavedIcon, ContinueIcon, PausedIcon, DeletedIcon } from './assets'
import './styles.css'
import { truncateText } from "../../helpers/stringHelper";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Text, Paragraph } = Typography

const defaultIcons = {
  saved: <SavedIcon />,
  continue: <ContinueIcon />,
  paused: <PausedIcon />,
  deleted: <DeletedIcon />,
  error: <CloseCircleOutlined className="error-icon" />
}

const statusTheme = {
  saved: '',
  info: 'paused', //Updated
  error: 'deleted',
  warning: 'warning',
}

export const customNotificationMessage = ({
  defaultIcon,
  icon,
  message,
  description,
  top,
  bottom,
  status = 'info',
  className = `custom-notification-2 ${statusTheme[status]}`,
  notificationType = "open",
  placement = "top",
  duration = 0,
}) => {

  const args = {
    message: <Text ellipsis>{message}</Text>,
    description: <Paragraph style={{ textAlign: description?.length > 40 ? 'left' : 'center' }} ellipsis={{ rows: 2 }}>{truncateText({ text: description, maxLength: 90 })}</Paragraph>,
    duration: duration,
    icon: defaultIcon ? defaultIcons[defaultIcon] : icon ? <img src={icon} alt="icon-notification" /> : undefined,
    placement,
    className,
    top,
    bottom,
  };
  notification.config({ maxCount: 2 }) //Dos notificaciones como máximo al mismo tiempo
  notification[notificationType](args);
};
