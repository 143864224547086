import esLanguage from 'antd/es/calendar/locale/es_ES';

export const customLocale = {
    ...esLanguage,
    lang: {
      ...esLanguage.lang,
      dayFormat: 'D',
      shortWeekDays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      shortMonths: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ]
    },
  }