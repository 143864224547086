import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

export const deleteCompanyAsset = async (formData, idToken) => {
  try {
    const response = await axiosCreate.delete('company/asset',
      { timeout: 20000, data: formData, headers: { Authorization: `Bearer ${idToken}` } }
    )

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}
