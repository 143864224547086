
export const ALERTS_CODES = {
    ALERTS_MODULE: 'FEAT-351',
    ALERTS_TABLE: 'FEAT-352',
    ALERTS_DETAILS: 'FEAT-353',
}

export const ALERTS_CODES_NAMES = {
    ALERTS_MODULE: 'ALERTS_MODULE',
    ALERTS_TABLE: 'ALERTS_TABLE',
    ALERTS_DETAILS: 'ALERTS_DETAILS',
}