import { API_URL, axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const rolPath = "role/company/status"

//Endpoint de topia con el que se actualiza el estado de un de un rol, con el id del rol e indicando el estado si es true o false
export const updateRoleStatus = async ({
    token,
    formData
}) => {
    try {
        const response = await axiosCreate.put(`${API_URL}${rolPath}`, formData, {
            timeout: 10000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }
};