import axios from "axios";
import { API_URL } from "../../../../../../config";

const tasksPath = "tasks/monitoring/category"

export const getCategoryDailyTask = async ({
    token,
    company_id = 1,
    limit = 7,
    skip = 0,
    formData,
  }) => {
    try {
      const params = {
        company_id,  
        limit,
        skip,      
        ...formData  
      };     
  
      const response = await axios.get(`${API_URL}${tasksPath}`, { params, timeout:10000, headers:{
        Authorization: `Bearer ${token}`
      }});
  
      return {
        success: true,
        ...response,
      };
    } catch (error) {
      if(error.response)
      {
        return {
          success: false,
          ...error.response,
        };
      }
      if(error.request)
      {
        return {
          success: false,
          status: error.code,
          data: {
            errors: [{ msg: error.message }],
          },
        };
      }
    }
  };