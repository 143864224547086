import { useContext, useEffect } from "react"
import { Col, Image, Row, Table } from "antd"
import FileIcon from "../../../../../../../assets/icons/FileIcon"
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons"
import useDetailChecklist from "../../../hooks/useDetailChecklist"
import CustomTable from "../../../../../../UI/CustomTable/CustomTable"
import { HandoverChecklistContext } from "../../../context/HandoverChecklistContext"

const DetailsChecklistTable = ({
  expandedRowKeys,
  handleRowExpand,
  setDataSource,
}) => {
  const {
    loadingTable,
    setLoadingTable,
    currentPage,
    detailChecklistById, 
    detailCheckPaginationData,
  } = useContext(HandoverChecklistContext)

  const { changePageHandler } = useDetailChecklist()

  const { columns, dataSource } = detailChecklistById
  const { quantity } = detailCheckPaginationData

  // Prepare data
  const styledColumns = columns?.map((column) => ({
    ...column,
    title: <span style={{ textTransform: "uppercase"}}>{column.title}</span>,
    render: (text, record) => (
      <span style={{ textTransform: "uppercase", color:`${((column.dataIndex === 'status') && (record.statusCode === 'failed')) ? '#E82A2A' : '#4D4D4D'}` ,fontWeight: 500, fontSize: 15, fontFamily: "Poppins" }}>
        {text}
      </span>
    )}
  ))

  columns && styledColumns.push(Table.EXPAND_COLUMN)

  useEffect(() => {
    if (dataSource) setDataSource(dataSource)
  }, [])

  return (
    <Row>
      <Col span={24}>
        <CustomTable
          className="custom-table-variant-4"
          expandedRowKeys={expandedRowKeys}
          handleRowExpand={handleRowExpand}
          loading={loadingTable}
          columns={styledColumns ?? []}
          dataSource={dataSource ?? []}
          rowKey={record => record.key}
          pagination={{
            total: quantity,
            pageSize: 10,
            onChange: async (current) => {
              setLoadingTable(true)
              await changePageHandler(current, '', '', 10)
              setLoadingTable(false)
            },
            current: currentPage,
            showTotal: (total, range) => {
              return `${range[0]}-${range[1]} de ${total}`;
            }
          }}
          expandable={{
            expandRowByClick: true,
            expandIcon: ({ expanded, onExpand, record }) => (<>
              {expanded
                ? <CaretDownFilled style={{ color: "#818181" }} onClick={e => onExpand(record, e)} />
                : <CaretUpFilled style={{ color: "#818181" }} onClick={e => onExpand(record, e)} />}
            </>),
            expandedRowRender: (record) => <DetailExpandableRow record={record} />
          }}
        />
      </Col>
    </Row>
  )
}

const DetailExpandableRow = ({ record }) => {
  const isImagesAdded = !(record.review.images.length)
  const isFilesAdded = !(record.review.files.length)

  return (
    <Row>
      <Col span={24}>
        <Row gutter={32}>
          <Col span={12}>
            <h4 style={{ fontWeight: 500, color: "#252525" }}>
              OBSERVACIÓN:
            </h4>
            <div style={{
              height: 135, 
              backgroundColor: "#F6F6F6",
              borderRadius: 8,
              color: "#626262",
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <p style={{ textAlign: "justify" }}>{(record?.review?.comment) ? record.review.comment : "No hay texto añadido"}</p>
            </div>
          </Col>
          <Col span={12}>
            <h4 style={{ fontWeight: 500, color: "#252525" }}>
              IMÁGENES:
            </h4>
            <Row gutter={16} justify="center" align="middle" style={{
              backgroundColor: "#F6F6F6",
              borderRadius: 8,
              height: 135,
              overflow: `${((isImagesAdded) && (isFilesAdded)) ? '' : 'auto'}`,
            }}>
              {!(isImagesAdded) && (
                record.review.images.map((img, index) => (
                  <Col key={`${img}${index}`}>
                    <Image
                      alt={`${img}`}
                      src={`${img}`}
                      width={'100%'}
                      height={'100%'}
                      style={{ maxWidth: 100, maxHeight: 115, cursor: "pointer", margin: 10, objectFit: "cover" }}
                    />
                  </Col>
                ))
              )}
              {!(isFilesAdded) && (
                record.review.files.map((file, index) => (
                  <Col key={`${file}${index}`}>
                    <FileIcon urlFile={file} />
                  </Col>
                ))
              )}
              {((isImagesAdded) && (isFilesAdded)) && (
                <p style={{ color: "#626262", padding: 10 }}>
                  No hay documentos añadidos
                </p>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
)}

export default DetailsChecklistTable
