import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import useRedirectTo from "./useRedirectTo"
import { modulesFeatures } from "../../constants/modules/modules"
import { useAppDispatch, useAppSelector } from "../../hooks/store"
import { getChildrenFeatureCodes } from "../helpers/featureRoleHelper"
import { setCurrentModuleRoleThunk, getFeaturesThunk, setEmptyModuleFeatures } from '../../store/roles/thunks'

//Este hook hace posible la validación de cada uno de los módulos del dashboard, verificando en el endpoint si
// es permitido ver o no dichos módulos y así permitiendo acceder a ellos
export const useFeatureRole = ({
  feature_codes, validate_code, defaultCheck = true, returnUrl = '/menu/modules', emptyArray = false, cb = () => { },
}) => {
    const dispatch = useAppDispatch()
    const [loadingFeatures, setLoadingFeatures] = useState(true)
    const [gettingFeatures, setGettingFeatures] = useState(true)
    const { activeFeatures } = useAppSelector(state => state.roles)
    const { handleRedirectTo } = useRedirectTo()

    //Permite establecer el modulo activo en la store a partir de la feature_code, y luego redirige al modulo
    // correspondiente u otra ruta si se deseara
    const handleActualSetFeatures = async ({ feature_code, url }) => {
        dispatch(setCurrentModuleRoleThunk({ feature_code }))
        handleRedirectTo(url)
    }

    //A partir de una feature_code, que normalemte es la del modulo, se obtiene el resto de feature_codes
    // con ayuda del helper para así enviar al endpoint todas las feature_codes relacionadas a dicho modulo
    const getModuleFeatures = async (feature_code) => {
        setGettingFeatures(true)
        const featureCodes = getChildrenFeatureCodes({ featuresArray: modulesFeatures, feature_code })
        await dispatch(getFeaturesThunk({ featureCodes }))
        setGettingFeatures(false)
    }

    //Se llama al thunk para vaciar las feature_codes activas actualmente, ocurre cuando se desmonta el
    // modulo/pagina que las utilizaba
    const emptyFeatures = async () => {
        dispatch(setEmptyModuleFeatures())
    }

    //Verifica de forma local si la feature se encuentra dentro de las features_codes que se obtuvieron
    // en la data del endpoint y si esta está activa o no
    const checkAvailableFeature = ({ featureCodes, code }) => {
        return !activeFeatures?.find(feature => feature.feature_code === featureCodes[code])?.enabled
    }

    //Comprueba primero si el arreglo de features activas tiene elementos y que este permitido llamar al
    // endpoint (con el booleano gettingFeature)
    const initialDefaultFeatures = async () => {
        if (!activeFeatures.length && gettingFeatures) {
            getModuleFeatures(feature_codes[validate_code])
        }

        // Si ya hay elementos en el arreglo de features activas, entonces se actualiza el booleano de
        // loadingFeatures para eliminar el CustomSpin que se utiliza al inicio de los módulos
        // y luego se comprueba si esta habilitado el modulo con los datos obtenidos, en caso de que no,
        // se muestra una notificacion, se vacia el arreglo en la store y se retorna a otra ruta que se especifique
        if (activeFeatures.length && !gettingFeatures) {
            setLoadingFeatures(false)
            if (!activeFeatures.find(feature => feature.feature_code === feature_codes[validate_code])?.enabled) {
                toast.info('No tienes permiso para acceder a este módulo')
                emptyFeatures()
                return handleRedirectTo(returnUrl)
            }
        }
    }

    //Se ejecuta al montar el componente, verificando que exista las feature_codes, el validate_code y que
    // el booleano este activo, ya que este indica que se quiere utilizar el useEffect de este hook
    useEffect(() => {
        if (!defaultCheck || !feature_codes || !validate_code) return
        initialDefaultFeatures()
        //Cuando se desmonta el modulo, verifica que hayan elementos en el arreglo, que no se esten obteniendo
        // datos del endpoint y que el booleano sea falto
        //y vacia los datos del arreglo y ejecuta un callback que se proporcione al custom hook, el cual es opcional
        return () => {
            if (activeFeatures.length && !gettingFeatures && !emptyArray) {
                cb()
                emptyFeatures()
            }
        }
    }, [activeFeatures, gettingFeatures])

    return {
        handleActualSetFeatures,
        handleRedirectTo,
        getModuleFeatures,
        emptyFeatures,
        initialDefaultFeatures,
        setLoadingFeatures,
        setGettingFeatures,
        checkAvailableFeature,
        gettingFeatures,
        loadingFeatures,
        activeFeatures
    }
}