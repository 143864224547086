import { useState, createContext } from "react"

export const AdminContractsContext = createContext()

export const AdminContractsProvider = ({ children }) => {
  const [contractSelected, setContractSelected] = useState({})
  const [mapDataSelected, setMapDataSelected] = useState({})
  const [fileList, setFileList] = useState([])
  const [isVariableRent, setIsVariableRent] = useState([])
  const [fileListContracts, setFileListContracts] = useState([])

  const cleanFileList = () => setFileList([])
  const cleanMapDataSelected = () => setMapDataSelected({})
  const cleanFileListContracts = () => setFileListContracts([])

  const value = {
    mapDataSelected,
    fileList,
    setFileList,
    cleanFileList,
    setMapDataSelected,
    cleanMapDataSelected,
    isVariableRent,
    setIsVariableRent,
    fileListContracts,
    setFileListContracts,
    cleanFileListContracts,
    contractSelected,
    setContractSelected
  }

  return <AdminContractsContext.Provider value={value}>
    {children}
  </AdminContractsContext.Provider>
}