export const ACCESS_PROVIDERS_CODES = {
    ACCESS_PROVIDERS_MODULE: 'FEAT-413',
    ACCESS_PROVIDERS_NEW_ACCESS: 'FEAT-414',
    ACCESS_PROVIDERS_DETAIL_ACCESS: 'FEAT-415',
    ACCESS_PROVIDERS_MANAGEMENT_PROVIDERS: 'FEAT-416',
    ACCESS_PROVIDERS_MANAGEMENT_REASON_VISITS: 'FEAT-417',
    ACCESS_PROVIDERS_UPDATE_ACCESS: 'FEAT-419',
    ACCESS_PROVIDERS_CANCEL_ACCESS: 'FEAT-420',
    ACCESS_PROVIDERS_NEW_PROVIDER: 'FEAT-421',
    ACCESS_PROVIDERS_DELETE_PROVIDER: 'FEAT-422',
    ACCESS_PROVIDERS_NEW_REASON_VISIT: 'FEAT-423',
    ACCESS_PROVIDERS_DELETE_REASON_VISIT: 'FEAT-424',
}

export const ACCESS_PROVIDERS_CODES_NAMES = {
    ACCESS_PROVIDERS_MODULE: 'ACCESS_PROVIDERS_MODULE',
    ACCESS_PROVIDERS_NEW_ACCESS: 'ACCESS_PROVIDERS_NEW_ACCESS',
    ACCESS_PROVIDERS_DETAIL_ACCESS: 'ACCESS_PROVIDERS_DETAIL_ACCESS',
    ACCESS_PROVIDERS_MANAGEMENT_PROVIDERS: 'ACCESS_PROVIDERS_MANAGEMENT_PROVIDERS',
    ACCESS_PROVIDERS_MANAGEMENT_REASON_VISITS: 'ACCESS_PROVIDERS_MANAGEMENT_REASON_VISITS',
    ACCESS_PROVIDERS_UPDATE_ACCESS: 'ACCESS_PROVIDERS_UPDATE_ACCESS',
    ACCESS_PROVIDERS_CANCEL_ACCESS: 'ACCESS_PROVIDERS_CANCEL_ACCESS',
    ACCESS_PROVIDERS_NEW_PROVIDER: 'ACCESS_PROVIDERS_NEW_PROVIDER',
    ACCESS_PROVIDERS_DELETE_PROVIDER: 'ACCESS_PROVIDERS_DELETE_PROVIDER',
    ACCESS_PROVIDERS_NEW_REASON_VISIT: 'ACCESS_PROVIDERS_NEW_REASON_VISIT',
    ACCESS_PROVIDERS_DELETE_REASON_VISIT: 'ACCESS_PROVIDERS_DELETE_REASON_VISIT',
}