import axios from "axios"

const createNewUserHolder = async (formData) => {
  try {
    const response = await axios.post('https://domo.topiaapp.com/api/residential/nomenclature/house/titular', {
      ...formData,
      phone_number: formData.phone_number.toString(),
      phone_area: formData.phone_area.toString(),
    })

    return {
      success: true,
      ...response,
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default createNewUserHolder
