import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const reasonVisitPath = 'event/company/reason'

export const createReasonVisit = async ({
    token,
    formData
}) => {
    try {
        const response = await axiosCreate.post(`${reasonVisitPath}`, formData, {
            timeout: 10000, headers: {
                Authorization: `Bearer ${token}`,                
            },
        });

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
}