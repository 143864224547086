import { GoIcon } from "../assets"

const MenuItem = ({ item, handleActualSetFeatures }) => {
    const Icon = item.image
    return (
        <div className="menu__item" onClick={() => handleActualSetFeatures({ feature_code: item?.rolName, url: item.url })}>
            <Icon className='menu_item-icon' />
            <span>{item.name}</span>
            <GoIcon className="menu_item-go" />
        </div>
    )
}

export default MenuItem