import { Button } from "antd"
import defaultDocIcon from '../assets/defaulDoc.svg'

const NoFiles = ({ acceptFilesText, handleOpenDialogFile, justImages, getFileSize, maxSizeInBytes }) => {
    return (
        <div className='custom-upload-file__no-file'>
            <img className='default-icon' src={defaultDocIcon} alt="default-icon" />
            <small>Archivos soportados ({acceptFilesText})</small>
            <strong>Arrastra y suelta archivos aquí</strong>
            <Button onClick={handleOpenDialogFile} shape='round'> {justImages ? 'SUBIR FOTOGRAFÍAS' : 'SELECCIONAR ARCHIVO'}</Button>
            <small>Tamaño máximo: {getFileSize(maxSizeInBytes, 0)}</small>
        </div>
    )
}

export default NoFiles