import AlertsTable from './AlertsTable'
import CustomTabs from '../../../UI/CustomTabs/CustomTabs'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { setCurrentAlertTab } from '../../../../store/alerts'
import useAlerts from '../hooks/useAlerts'
import EmptyComponent from '../../../UI/EmptyComponent'
import { ALERTS_CODES } from '../../../../constants/modules/alerts/alertsCodes'

const ContainerTabs = ({ loading, setLoading, activeFeatures }) => {
  const dispatch = useAppDispatch()
  const { filters } = useAppSelector(state => state.alerts)

  const { getAllAlerts } = useAlerts()

  const alertsItems = [
    { label: 'ALERTAS ACTIVAS', children: !activeFeatures?.find(feature => feature.feature_code === ALERTS_CODES.ALERTS_TABLE)?.enabled ? <EmptyComponent label={'No tienes permiso para ver estos datos'} />  : <AlertsTable activeFeatures={activeFeatures} loading={loading} setLoading={setLoading} /> },
    { label: 'ALERTAS ARCHIVADAS', children: !activeFeatures?.find(feature => feature.feature_code === ALERTS_CODES.ALERTS_TABLE)?.enabled ? <EmptyComponent label={'No tienes permiso para ver estos datos'} /> :  <AlertsTable activeFeatures={activeFeatures} loading={loading} setLoading={setLoading} /> }
  ]

  const handleOnChangeTab = async (value) => {
    setLoading(true)

    dispatch(setCurrentAlertTab(value))
    await getAllAlerts({ ...filters, isLimit: false })

    setLoading(false)
  }
  
  return <CustomTabs items={alertsItems} onChange={handleOnChangeTab} defaultActiveKey="0" className='custom-tabs-variant-1' />
}

export default ContainerTabs
