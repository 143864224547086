import { axiosCreate } from "../../../../config/index"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const getCalendarReservationStatus = async (idToken) => {
  try {
    const response = await axiosCreate.get('reservation/status', {
      headers: { Authorization: `Bearer ${idToken}` },
      timeout: 20000
    })

    return verifyBodyResponse(response, true)    
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getCalendarReservationStatus