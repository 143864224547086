
export const EMPLOYEE_CODES = {
    EMPLOYEE_MODULE: 'FEAT-279',
    EMPLOYEE_DETAILS: 'FEAT-41',
    EMPLOYEE_NEW_EMPLOYEE: 'FEAT-42',
    EMPLOYEE_UPDATE_EMPLOYEE: 'FEAT-43',
    EMPLOYEE_INABILITY_EMPLOYEE: 'FEAT-44',
    EMPLOYEE_FIRE_EMPLOYEE: 'FEAT-45',
}

export const EMPLOYEE_CODES_NAMES = {
    EMPLOYEE_MODULE: 'EMPLOYEE_MODULE',
    EMPLOYEE_DETAILS: 'EMPLOYEE_DETAILS',
    EMPLOYEE_NEW_EMPLOYEE: 'EMPLOYEE_NEW_EMPLOYEE',
    EMPLOYEE_UPDATE_EMPLOYEE: 'EMPLOYEE_UPDATE_EMPLOYEE',
    EMPLOYEE_INABILITY_EMPLOYEE: 'EMPLOYEE_INABILITY_EMPLOYEE',
    EMPLOYEE_FIRE_EMPLOYEE: 'EMPLOYEE_FIRE_EMPLOYEE',
}