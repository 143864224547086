
export const libraryTableFilters = ({ currentParams }) => ({
    startPublishAt: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams.sort_by === 'publish_date' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null
    }),
    endPublishAt: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams.sort_by === 'expire_date' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null
    }),
    name: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams.sort_by === 'name' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null
    }),
    default: () => ({})
})