import { Dropdown, Menu } from "antd"
import './styles.css'

const menu = ({ menuOptions = [] }) => (
  <Menu items={menuOptions} />
);

const CustomDropdown = ({ menuOptions, children, placement = 'bottomRight', arrow = true }) => {
  return (
    <Dropdown arrow={arrow} placement={placement} trigger={['click']} className="custom-dropdown" overlayClassName="custom-dropdown-overlay" overlay={menu({ menuOptions })}>
      {children}
    </Dropdown>
  )
}

export default CustomDropdown