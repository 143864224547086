import { Popover } from "antd";
import moment from "moment";

export const CustomEvent = ({ event }) => {
  const day = moment(event.start).format("dddd").toString();
  const startHour = moment(event.start).format("h:mm a").toString();
  const endHour = moment(event.end).format("h:mm a").toString();

  return (
    <Popover
      zIndex={15}
      trigger={"hover"}
      placement="left"
      overlayClassName="custom-popover"
      arrowPointAtCenter={true}
      autoAdjustOverflow={true}
      content={
        <div>
          <h4>{event?.title}</h4>
          <p>Día: {day}</p>
          <p>Inicio: {startHour}</p>
          <p>Fin: {endHour}</p>
        </div>
      }
    >
      <div title="" className="rbc-event-content">
        <div>{event.title}</div>
        <div style={{ fontSize: 11 }}>
          {startHour} - {endHour}
        </div>
      </div>
    </Popover>
  );
};
