import docxIcon from '../UI/CustomUploadFile/assets/filesIcon/docxIcon.png'
import xlsxIcon from '../UI/CustomUploadFile/assets/filesIcon/xlsxIcon.png'
import pptxIcon from '../UI/CustomUploadFile/assets/filesIcon/pptxIcon.png'
import pdfIcon from '../UI/CustomUploadFile/assets/filesIcon/pdfIcon.png'
// Primero encontramos la extension del archivo, es decir, el tipo del archivo
export const verifyTypesFiles = (urlFile, defaultStringsFileTypes = ['pdf', 'xlsx', 'xls', 'csv', 'docx', 'doc', 'pptx', 'ppt', 'jpg', 'png', 'jpeg', 'webp']) => {
  const lastIndexDot = urlFile?.lastIndexOf('.')
  const extension = urlFile?.substring(lastIndexDot + 1)
  const typeFileFound = defaultStringsFileTypes.find(type => type === extension) || ''
  return typeFileFound
}

// Luego de encontrar el tipo de extension del archivo usamos esta funcion para retornar el svg
export const verifyTypeFile = (fileType, width, height) => {
  switch (fileType) {
    case 'pdf': return <PdfFile width={width} height={height} />
    case 'xlsx': return <ExcelFile width={width} height={height} />
    case 'xls': return <ExcelFile width={width} height={height} />
    case 'csv': return <ExcelFile width={width} height={height} />
    case 'docx': return <WordFile width={width} height={height} />
    case 'doc': return <WordFile width={width} height={height} />
    case 'pptx': return <PowerPointFile width={width} height={height} />
    case 'ppt': return <PowerPointFile width={width} height={height} />
    default: return <DefaultFile width={width} height={height} />
  }
}

//Funcion que retorna el svg directamente no como un React component
export const verifyTypeFilePng = {
  pdf: pdfIcon,
  doc: docxIcon,
  docx: docxIcon,
  xls: xlsxIcon,
  xlsx: xlsxIcon,
  ppt: pptxIcon,
  pptx: pptxIcon
}

const DefaultFile = ({ width = '65', height = '65' }) => (
  <svg width={width} height={height} viewBox="0 0 89 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.378906" y="0.337891" width="87.758" height="73.3244" fill="white" />
    <rect x="0.378906" y="0.337891" width="87.758" height="73.3244" stroke="#D0D0D0" strokeWidth="0.5" strokeMiterlimit="0" strokeLinejoin="bevel" />
    <mask id="mask0_2534_15916" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="89" height="74">
      <rect x="0.378906" y="0.337891" width="87.758" height="73.3244" fill="white" stroke="white" strokeWidth="0.5" />
    </mask>
    <g mask="url(#mask0_2534_15916)">
    </g>
    <path d="M45.2344 18H32.5156C31.2385 18 30.2031 19.0354 30.2031 20.3125V52.6875C30.2031 53.9646 31.2385 55 32.5156 55H54.4844C55.7615 55 56.7969 53.9646 56.7969 52.6875V29.5631L45.2344 18ZM54.4844 30.521V30.7188H44.0781V20.3125H44.277L54.4844 30.521ZM32.5156 52.6875V20.3125H41.7656V33.0312H54.4844V52.6875H32.5156Z" fill="#C9C9C9" />
  </svg>
)

const WordFile = ({ width= '65', height = '65' }) => (
  <svg width={width} height={height} viewBox="0 0 31 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M29.8506 10.8273L22.8535 4.25V9.09138C22.8535 10.0531 23.6772 10.8273 24.7002 10.8273H29.8506Z" fill="white" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.3395 2.48828H4.01273C2.98966 2.48828 2.16602 3.2625 2.16602 4.2242V36.2642C2.16602 37.2259 2.98966 38.0002 4.01273 38.0002H26.4899C27.513 38.0002 28.3367 37.2259 28.3367 36.2642V9.06558" fill="#E2E2E2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.1735 1H2.84672C1.82364 1 1 1.77422 1 2.73592V34.776C1 35.7377 1.82364 36.5119 2.84672 36.5119H25.3239C26.347 36.5119 27.1707 35.7377 27.1707 34.776V7.57729" fill="#2A7FFF" />
    <path d="M20.1735 1H2.84672C1.82364 1 1 1.77422 1 2.73592V34.776C1 35.7377 1.82364 36.5119 2.84672 36.5119H25.3239C26.347 36.5119 27.1707 35.7377 27.1707 34.776V7.57729" stroke="#2A7FFF" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M27.171 7.57729L20.1738 1V5.84138C20.1738 6.80308 20.9975 7.57729 22.0205 7.57729H27.171Z" fill="#A6C5FB" stroke="#A6C5FB" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.3992 12.4864C13.0948 12.4824 12.8116 12.6327 12.6581 12.8799C12.5046 13.1271 12.5046 13.4328 12.6581 13.68C12.8116 13.9272 13.0948 14.0776 13.3992 14.0735L22.6746 14.0735C22.9791 14.0776 23.2623 13.9272 23.4157 13.68C23.5692 13.4328 23.5692 13.1271 23.4157 12.8799C23.2623 12.6327 22.9791 12.4824 22.6746 12.4864L13.3992 12.4864ZM6.44803 17.2345C6.14357 17.2304 5.86038 17.3808 5.70691 17.628C5.55343 17.8752 5.55343 18.1809 5.70691 18.4281C5.86038 18.6753 6.14357 18.8256 6.44803 18.8216H22.6751C22.9795 18.8256 23.2627 18.6753 23.4162 18.4281C23.5697 18.1809 23.5697 17.8752 23.4162 17.628C23.2627 17.3808 22.9795 17.2304 22.6751 17.2345H6.44803ZM6.44803 21.9825C6.14357 21.9784 5.86038 22.1288 5.70691 22.376C5.55343 22.6232 5.55343 22.9289 5.70691 23.1761C5.86038 23.4233 6.14357 23.5737 6.44803 23.5696H22.6751C22.9795 23.5737 23.2627 23.4233 23.4162 23.1761C23.5697 22.9289 23.5697 22.6232 23.4162 22.376C23.2627 22.1288 22.9795 21.9784 22.6751 21.9825H6.44803ZM6.44803 26.7305C6.14357 26.7265 5.86038 26.8769 5.70691 27.1241C5.55343 27.3712 5.55343 27.677 5.70691 27.9242C5.86038 28.1713 6.14357 28.3217 6.44803 28.3177H22.6751C22.9795 28.3217 23.2627 28.1714 23.4162 27.9242C23.5697 27.677 23.5697 27.3712 23.4162 27.1241C23.2627 26.8769 22.9795 26.7265 22.6751 26.7305H6.44803Z" fill="white" />
  </svg>
)

const ExcelFile = ({ width = '65', height = '65' }) => (
  <svg width={width} height={height} viewBox="0 0 31 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M29.8507 10.8273L22.8535 4.25V9.09138C22.8535 10.0531 23.6772 10.8273 24.7002 10.8273H29.8507Z" fill="white" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.3396 2.48828H4.01274C2.98966 2.48828 2.16602 3.2625 2.16602 4.2242V36.2642C2.16602 37.2259 2.98966 38.0002 4.01274 38.0002H26.49C27.5131 38.0002 28.3368 37.2259 28.3368 36.2642V9.06558" fill="#E2E2E2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.1736 1H2.84673C1.82365 1 1 1.77422 1 2.73592V34.776C1 35.7377 1.82365 36.5119 2.84673 36.5119H25.324C26.3471 36.5119 27.1707 35.7377 27.1707 34.776V7.57729" fill="#18A05E" />
    <path d="M20.1736 1H2.84673C1.82365 1 1 1.77422 1 2.73592V34.776C1 35.7377 1.82365 36.5119 2.84673 36.5119H25.324C26.3471 36.5119 27.1707 35.7377 27.1707 34.776V7.57729" stroke="#18A05E" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.4714 32.3873H21.6833C23.1904 32.3873 24.4121 31.1656 24.4121 29.6585V17.51C24.4121 16.003 23.1904 14.7812 21.6833 14.7812H6.97488C5.46781 14.7812 4.24609 16.003 4.24609 17.51V29.6585C4.24609 31.1656 5.46781 32.3873 6.97488 32.3873H10.4769V32.5146H11.8413V32.3873H17.107V32.4946H18.4714V32.3873ZM17.107 16.1456H11.8413V20.0192H17.107V16.1456ZM18.4714 16.1456V20.0192H23.0477V17.51C23.0477 16.7565 22.4369 16.1456 21.6833 16.1456H18.4714ZM17.107 21.3836H11.8413V25.8618H17.107V21.3836ZM18.4714 25.8618V21.3836H23.0477V25.8618H18.4714ZM17.107 27.2262H11.8413V31.0229H17.107V27.2262ZM18.4714 31.0229V27.2262H23.0477V29.6585C23.0477 30.4121 22.4369 31.0229 21.6833 31.0229H18.4714ZM6.97488 16.1456H10.4769V20.0192H5.61049V17.51C5.61049 16.7565 6.22135 16.1456 6.97488 16.1456ZM5.61049 21.3836H10.4769V25.8618H5.61049V21.3836ZM5.61049 27.2262H10.4769V31.0229H6.97488C6.22135 31.0229 5.61049 30.4121 5.61049 29.6585V27.2262Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M27.171 7.57729L20.1738 1V5.84138C20.1738 6.80308 20.9975 7.57729 22.0206 7.57729H27.171Z" fill="#8DD0AF" stroke="#8DD0AF" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const PdfFile = ({ width = '65', height= '65' }) => (
  <svg width={width} height={height} viewBox="0 0 31 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M29.8507 10.8273L22.8535 4.25V9.09138C22.8535 10.0531 23.6772 10.8273 24.7002 10.8273H29.8507Z" fill="white" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.3396 2.48828H4.01274C2.98966 2.48828 2.16602 3.2625 2.16602 4.2242V36.2642C2.16602 37.2259 2.98966 38.0002 4.01274 38.0002H26.49C27.5131 38.0002 28.3368 37.2259 28.3368 36.2642V9.06558" fill="#E2E2E2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.1736 1H2.84673C1.82365 1 1 1.77422 1 2.73592V34.776C1 35.7377 1.82365 36.5119 2.84673 36.5119H25.324C26.3471 36.5119 27.1707 35.7377 27.1707 34.776V7.57729" fill="#F15642" />
    <path d="M20.1736 1H2.84673C1.82365 1 1 1.77422 1 2.73592V34.776C1 35.7377 1.82365 36.5119 2.84673 36.5119H25.324C26.3471 36.5119 27.1707 35.7377 27.1707 34.776V7.57729" stroke="#F15642" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M27.171 7.57729L20.1738 1V5.84138C20.1738 6.80308 20.9975 7.57729 22.0206 7.57729H27.171Z" fill="#FFA99E" stroke="#FFA99E" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.10352 18.6697C7.6711 18.5878 8.27144 18.5469 8.90451 18.5469C9.72315 18.5469 10.3371 18.7106 10.7464 19.0381C11.1558 19.3601 11.3604 19.8294 11.3604 20.4461C11.3604 21.1283 11.1612 21.644 10.7628 21.9933C10.3699 22.3426 9.78591 22.5173 9.01094 22.5173C8.6944 22.5173 8.43516 22.5063 8.23323 22.4845V24.6048H7.10352V18.6697ZM8.23323 21.6004C8.44062 21.6331 8.66438 21.6495 8.90451 21.6495C9.34658 21.6495 9.68222 21.554 9.91143 21.363C10.1461 21.1665 10.2634 20.8882 10.2634 20.528C10.2634 19.8021 9.81047 19.4392 8.90451 19.4392C8.65892 19.4392 8.43516 19.4556 8.23323 19.4883V21.6004Z" fill="white" />
    <path d="M17.0166 21.5349C17.0166 22.5555 16.7437 23.3359 16.198 23.8762C15.6577 24.4165 14.8854 24.6866 13.8812 24.6866C13.2973 24.6866 12.7515 24.6457 12.244 24.5638V18.6697C12.7515 18.5878 13.2973 18.5469 13.8812 18.5469C14.88 18.5469 15.6522 18.8088 16.198 19.3328C16.7437 19.8512 17.0166 20.5853 17.0166 21.5349ZM15.9278 21.5349C15.9278 20.1541 15.2647 19.4637 13.9385 19.4637C13.7312 19.4637 13.5429 19.4801 13.3737 19.5129V23.7206C13.5429 23.7534 13.7312 23.7698 13.9385 23.7698C14.6044 23.7698 15.101 23.5869 15.4285 23.2213C15.7614 22.8556 15.9278 22.2935 15.9278 21.5349Z" fill="white" />
    <path d="M17.944 18.6287H21.5706V19.5538H19.0901V21.1256H21.4069V22.0097H19.0901V24.6048H17.944V18.6287Z" fill="white" />
  </svg>
)

const PowerPointFile = ({ width = '21', height = '27' }) => (
  <svg width={width} height={height} viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M20.7643 7.67121L15.9258 3.12305V6.47083C15.9258 7.13584 16.4953 7.67121 17.2028 7.67121H20.7643Z" fill="white" stroke="white" strokeWidth="0.21875" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.8756 1.9043H2.89418C2.18673 1.9043 1.61719 2.43966 1.61719 3.10467V25.2602C1.61719 25.9252 2.18673 26.4606 2.89418 26.4606H18.437C19.1445 26.4606 19.714 25.9252 19.714 25.2602V6.45246" fill="#E2E2E2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.0709 0.875H2.0895C1.38204 0.875 0.8125 1.41037 0.8125 2.07538V24.2309C0.8125 24.8959 1.38204 25.4313 2.0895 25.4313H17.6324C18.3398 25.4313 18.9094 24.8959 18.9094 24.2309V5.42316" fill="#CD4F2E" />
    <path d="M14.0709 0.875H2.0895C1.38204 0.875 0.8125 1.41037 0.8125 2.07538V24.2309C0.8125 24.8959 1.38204 25.4313 2.0895 25.4313H17.6324C18.3398 25.4313 18.9094 24.8959 18.9094 24.2309V5.42316" stroke="#CD4F2E" strokeWidth="0.21875" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.9088 5.42316L14.0703 0.875V4.22279C14.0703 4.88779 14.6399 5.42316 15.3473 5.42316H18.9088Z" fill="#F78B67" stroke="#F78B67" strokeWidth="0.21875" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.5974 8.96974C10.6002 8.62834 9.51955 8.61694 8.51536 8.93722C7.51116 9.25751 6.63671 9.89247 6.02131 10.7482C5.40592 11.6039 5.08227 12.635 5.09822 13.6889C5.11417 14.7428 5.46888 15.7636 6.10989 16.6003C6.7509 17.437 7.64418 18.0453 8.6576 18.335C9.67103 18.6247 10.7508 18.5806 11.7372 18.2092C12.7236 17.8378 13.5643 17.1588 14.135 16.2726C14.7056 15.3864 14.9759 14.34 14.9059 13.2883L10.0072 13.6146L11.5974 8.96974Z" stroke="white" strokeWidth="0.875" />
    <path d="M4.70703 20.0957H15.9008" stroke="white" strokeWidth="0.875" strokeLinecap="round" />
    <path d="M14.3655 10.1285C14.7122 10.6113 14.9344 11.1813 15.0107 11.7821L12.0627 12.0068L13.0604 9.04246C13.5734 9.27922 14.0234 9.65213 14.3655 10.1285Z" stroke="white" strokeWidth="0.4375" />
  </svg>

)