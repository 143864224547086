import { useContext, useState } from "react"
import { Button, Col, Form, Row } from "antd"
import CustomModal from "../../../../../../UI/CustomModal"
import useAdminChecklist from "../../hooks/useAdminChecklist"
import { config } from "../../../../../../constants/constants"
import { CustomInput } from "../../../../../../UI/CustomInput"
import { AdminChecklistContext } from "../../context/AdminChecklistContext"

const EditChecklistModal = ({
  form,
  isOpenModal,
  handleOpenCloseModal
}) => {
  const [loading, setLoading] = useState(false)

  const { filters } = useContext(AdminChecklistContext)
  const { onSaveOrnamentChecklist, getChecklistCategories, getDefaultAdminCheck } = useAdminChecklist()

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      handleOpenCloseModal()
      await getChecklistCategories({ ...filters })
      await getDefaultAdminCheck()
    }
  }

  const onSubmit = async (formData) => {
    setLoading(true)
    
    const response = await onSaveOrnamentChecklist(formData)

    await onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal
      destroyOnClose
      title={<h3 style={{ margin: 0 }}>EDITAR NOMBRE DE CHECKLIST</h3>}
      isOpenModal={isOpenModal}
      onCancel={handleOpenCloseModal}
    >
      <Form
        form={form}
        layout="vertical"
        name="editChecklistAdminForm"
        onFinish={onSubmit}
        disabled={loading}
      >
        <label style={{ fontWeight: 600, color: '#626262', fontSize: 12 }}>
          NOMBRE
        </label>

        <Form.Item name="name" {...config}>
          <CustomInput placeholder="Escribe un nombre" />  
        </Form.Item>

        <Row justify="end" gutter={16} align="middle">
          <Col>
            <Button
              type="text"
              shape="round"
              htmlType="button"
              disabled={loading}
              loading={loading}
              style={{ border: 0, width: 132, height: 42 }}
              onClick={handleOpenCloseModal}
            >
              <span style={{ fontWeight: 500, fontSize: 14, color: '#404040' }}>CANCELAR</span>
            </Button>
          </Col>

          <Col>
            <Button
              type="text"
              shape="round"
              htmlType="submit"
              disabled={loading}
              loading={loading}
              style={{ border: 0, width: 132 }}
              className="btn-standard-green"
            >
              <span>GUARDAR</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default EditChecklistModal