// Funcion para validar que tag devolver en los mapas
export const verifyTypeTag = ({ element, onClick, index }) => {
  let objTagsTypes = {
    "path": <path key={`${element.placeId}-${index}`} id={element.placeId} d={element.d} x={element.x} y={element.y} width={element.width} height={element.height} stroke={element.stroke} fill={element.fill} onClick={onClick} style={{ cursor: 'pointer' }} />,
    "rect": <rect key={`${element.placeId}-${index}`} id={element.placeId} d={element.d} x={element.x} y={element.y} width={element.width} height={element.height} stroke={element.stroke} fill={element.fill} onClick={onClick} style={{ cursor: 'pointer' }} />,
    "default": <path />
  }

  let tag = objTagsTypes[element.svgType] || objTagsTypes["default"]
  return tag
}