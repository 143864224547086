const PdfFileIcon = ({ color = '' }) => {
  return (
    <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.7145 7.37475L15.1758 3.1084V6.24875C15.1758 6.87256 15.71 7.37475 16.3737 7.37475H19.7145Z" fill="white" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.1927 1.96582H2.95373C2.29011 1.96582 1.75586 2.46802 1.75586 3.09182V23.8746C1.75586 24.4984 2.29011 25.0006 2.95373 25.0006H17.5335C18.1972 25.0006 18.7314 24.4984 18.7314 23.8746V6.23217" fill="#E2E2E2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.4369 1H2.19787C1.53425 1 1 1.5022 1 2.126V22.9087C1 23.5325 1.53425 24.0347 2.19787 24.0347H16.7777C17.4413 24.0347 17.9756 23.5325 17.9756 22.9087V5.26635" fill="#EA5E64"/>
        <path d="M13.4369 1H2.19787C1.53425 1 1 1.5022 1 2.126V22.9087C1 23.5325 1.53425 24.0347 2.19787 24.0347H16.7777C17.4413 24.0347 17.9756 23.5325 17.9756 22.9087V5.26635" stroke="#EA5E64" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.77359 12.1707C7.77359 12.7791 7.36886 13.33 6.33918 13.33H5.54162V15H5V11H6.33918C7.31529 11 7.77359 11.5165 7.77359 12.1707ZM5.54162 12.8996H6.33918C6.95818 12.8996 7.22006 12.6184 7.22006 12.1707C7.22006 11.7059 6.95818 11.4304 6.33918 11.4304H5.54162V12.8996Z" fill="white"/>
        <path d="M9.73833 11C11.1013 11 11.9405 11.769 11.9405 13.0086C11.9405 14.2482 11.1013 15 9.73833 15H8.44676V11H9.73833ZM8.98838 14.5696H9.73833C10.8216 14.5696 11.387 13.9842 11.387 13.0086C11.387 12.033 10.8216 11.4247 9.73833 11.4247H8.98838V14.5696Z" fill="white"/>
        <path d="M12.6549 15V11H15V11.4247H13.1966V12.7733H14.6607V13.198H13.1966V15H12.6549Z" fill="white"/>
        <path d="M7.77359 12.1707C7.77359 12.7791 7.36886 13.33 6.33918 13.33H5.54162V15H5V11H6.33918C7.31529 11 7.77359 11.5165 7.77359 12.1707ZM5.54162 12.8996H6.33918C6.95818 12.8996 7.22006 12.6184 7.22006 12.1707C7.22006 11.7059 6.95818 11.4304 6.33918 11.4304H5.54162V12.8996Z" stroke="white" strokeWidth="0.2"/>
        <path d="M9.73833 11C11.1013 11 11.9405 11.769 11.9405 13.0086C11.9405 14.2482 11.1013 15 9.73833 15H8.44676V11H9.73833ZM8.98838 14.5696H9.73833C10.8216 14.5696 11.387 13.9842 11.387 13.0086C11.387 12.033 10.8216 11.4247 9.73833 11.4247H8.98838V14.5696Z" stroke="white" strokeWidth="0.2"/>
        <path d="M12.6549 15V11H15V11.4247H13.1966V12.7733H14.6607V13.198H13.1966V15H12.6549Z" stroke="white" strokeWidth="0.2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.9762 5.26635L13.4375 1V4.14035C13.4375 4.76416 13.9718 5.26635 14.6354 5.26635H17.9762Z" fill="#FFAEB1" stroke="#FFAEB1" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default PdfFileIcon
