import { Button, Row } from "antd"
import CustomPopoverConfirm from "../../../../../../../UI/CustomPopoverConfirm"
import { HANDOVER_CHECKLIST_CODES } from "../../../../../../../../constants/modules/handoverChecklist/handoverChecklistCodes"

const FooterActionsDetails = ({ activeFeatures, handleCurrentBody }) => {
  const disableDetailFeature = !activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_CREATE_TICKET)?.enabled

  return (
    <Row justify="center" align="middle" style={{ backgroundColor: "#FFFFFF", height: 90, paddingInline: 25 }}>
      <CustomPopoverConfirm trigger={'hover'} content={disableDetailFeature ? 'No tienes permiso para realizar esta acción' : 'Crear ticket'}>
        <Button
          type="button"
          shape="round"
          className="btn-standard-green"
          onClick={handleCurrentBody}
          style={{ width: 288 }}
          disabled={disableDetailFeature}
        >
          <span>CREAR TICKET GENERAL</span>
        </Button>
      </CustomPopoverConfirm>
    </Row>
)}

export default FooterActionsDetails
