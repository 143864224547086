import { cloneElement } from "react";

//Retorna un componente de react que clona el checkbox que se utiliza en el row selection de la tabla de detalles de rol, a partir del originNode y se le asigna propiedades adicionales al componente
export const renderCustomCheckbox = (checked, record, index, originNode, active) => {
    //Se clona el nodo original del checkbox de la tabla para asi prevalecer las funciones originales y añader propiedades adicionales al componente
    const NewCheckbox = ({ originNode, ...props }) => cloneElement(originNode, props)
    return (
        <NewCheckbox
            originNode={originNode}
            disabled={!active.status}
            checked={checked}
            className="detailRoles-checkbox"
        />
    )
};