import { useEffect } from "react";
import CheckHeader from "./components/CheckHeader";
import CheckSummary from "./components/CheckSummary/CheckSummary";
import CheckTable from "./components/CheckTable";
import "../../style.css";
import { useAllCheckList } from "../../hooks/useAllCheckList";
import { useFeatureRole } from "../../../../../hooks/useFeatureRole";
import { ADMIN_CHECK_CODES, ADMIN_CHECK_CODES_NAMES } from "../../../../../../constants/modules/adminCheck/adminCheckCodes";
import { CustomSpin } from "../../../../../UI/CustomSpin";

export const AllCheckLists = () => {
  const {
    activeFeatures,
    getAllTaskService,
    getSummaryTasksService,
  } = useAllCheckList();
  const { loadingFeatures } = useFeatureRole({ feature_codes: ADMIN_CHECK_CODES, validate_code: ADMIN_CHECK_CODES_NAMES.ADMIN_CHECK_MODULE, returnUrl: '/modules' })

  useEffect(() => {
    getAllTaskService({});
    getSummaryTasksService({});
  }, []); 

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (
    <section className="check-admin-page">
      <CheckHeader activeFeatures={activeFeatures} />
      <CheckSummary />
      <CheckTable />
    </section>
  );
};
