import { useEffect } from "react"
import { Button, Row } from "antd"
import useLibrary from "../hooks/useLibrary"
import searchIcon from '../assets/searchIcon.svg'
import { CustomInput } from '../../../UI/CustomInput'
import useLibraryTable from "../hooks/useLibraryTable"
import UploadDocumentModal from "./UploadDocumentModal"
import DeleteDocumentModal from "./DeleteDocumentModal"
import CustomTabs from "../../../UI/CustomTabs/CustomTabs"
import { CustomNavigate } from "../../../UI/CustomNavigate"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import { ReactComponent as UploadDocumentIcon } from '../assets/uploadDocumentIcon.svg'

import './styles.css'
import { LIBRARY_CODES } from "../../../../constants/modules/library/libraryCodes"
import { CustomSpin } from "../../../UI/CustomSpin"

const Library = () => {
  const { handleChangeDatePicker, handleSearch, onSubmit, fileList, setFileList, getInitialData, loading, quantityLibrary } = useLibrary()
  const { tabItems, handleOpenCloseModal, showDeleteDocumentModal, showNewDocumentModal, currentDocument, loadingFeatures, activeFeatures } = useLibraryTable()


  useEffect(() => {
    getInitialData()
  }, []);

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />
  return (
    <section className="section-wrapper library-module">
      <CustomNavigate title={<>
        BIBLIOTECA DE DOCUMENTOS

        <small style={{ fontWeight: 400, fontSize: 14 }}>
          Comparte hasta 30 documentos con tus usuarios residenciales
        </small></>}>

        <Row className="library-container" style={{ gap: 16 }} align="middle">
          <CustomInput onChange={handleSearch} placeholder={'Buscar documento'} suffixIcon={searchIcon} />

          <CustomPopoverConfirm trigger={'hover'} content={`${quantityLibrary >= 30 ? 'La cantidad máxima de archivos permitidos es de 30' : 'Subir un archivo nuevo'}`}>
            <div>
              <Button disabled={quantityLibrary >= 30 || (!activeFeatures?.find(feature => feature.feature_code === LIBRARY_CODES.LIBRARY_NEW_DOCUMENT)?.enabled) ? true : false} onClick={handleOpenCloseModal} style={{ maxWidth: 250 }} className="btn-standard-green" shape="round" icon={<UploadDocumentIcon />}>
                NUEVO DOCUMENTO
              </Button>
            </div>
          </CustomPopoverConfirm>
        </Row>
      </CustomNavigate>

      <CustomTabs className="custom-tabs-variant-1" items={tabItems} />

      <DeleteDocumentModal loading={loading} isOpenModal={showDeleteDocumentModal} handleOpenCloseModal={handleOpenCloseModal} onSubmit={onSubmit} currentDocument={currentDocument} />

      <UploadDocumentModal loading={loading} handleChangeDatePicker={handleChangeDatePicker} isOpenModal={showNewDocumentModal} handelOpenCloseModal={handleOpenCloseModal} onSubmit={onSubmit} setFileList={setFileList} fileList={fileList} />
    </section>
  )
}

export default Library
