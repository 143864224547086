import { useAppSelector } from '../../../../hooks/store'
import { getTableDataHelper } from '../../../helpers/getTableDataHelper'
import verifyResponse from '../../../helpers/verifyResponse'
import { getAllMonthIncomes, getPlaces } from '../services'

export const useSalesStatementServices = () => {

    const auth = useAppSelector(state => state.auth)

    const getAllMonthIncomesService = async ({ setLoading, date, place_id, sort_by, sort_order, limit, skip }) => {
        setLoading && setLoading(true)
        const res = await getAllMonthIncomes({ token: auth.idToken, company_id: auth.currentRol, date, place_id, sort_by, sort_order, limit, skip })
        const statusRes = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        setLoading && setLoading(false)
        if (!statusRes) {
            throw new Error('Error in getAllMonthIncomesService ')
        }

        return getTableDataHelper({ data: res.data.data, metadata: res.data.metadata })       
    }

    const getPlacesService = async ({ setLoading }) => {
        setLoading && setLoading(true)
        const res = await getPlaces({ token: auth.idToken, company_id: auth.currentRol })
        const statusRes = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        setLoading && setLoading(false)
        if (!statusRes) {
            throw new Error('Error in getAllMonthIncomesService ')
        }
        return res.data.data
    }

    return { getAllMonthIncomesService, getPlacesService }
}

export default useSalesStatementServices 