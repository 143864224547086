import CheckFilters from './components/CheckFilters'
import CheckStatistics from './components/CheckStatistics'
import { CustomSpin } from '../../../../../../../UI/CustomSpin'
import { useAllCheckList } from '../../../../hooks/useAllCheckList'

const CheckSummary = () => {
  const { Form, form, loading, onSubmit } = useAllCheckList()
  return (
    <section>
      <CustomSpin spinning={loading} size='middle' tip="Filtrando">
        <Form form={form} className='check-filters' name="filters_checklist" layout='horizontal' onFinish={onSubmit}>
          <CheckFilters Form={Form} form={form} />
          <CheckStatistics Form={Form} />
        </Form>
      </CustomSpin>
    </section>
)}

export default CheckSummary
