import { useContext } from "react"
import { Col, Image, Row } from "antd"
import CustomModal from "../../../../../../../UI/CustomModal"
import { HandoverChecklistContext } from "../../../../context/HandoverChecklistContext"

const ChecklistSignModal = ({
  isOpenModal,
  handleOpenCloseModal,
}) => {
  const { detailChecklistById } = useContext(HandoverChecklistContext)

  return (
    <CustomModal
      destroyOnClose
      isOpenModal={isOpenModal}
      onCancel={handleOpenCloseModal}
      title={<h3 style={{ margin: 0, color: '#232C4A', fontSize: 22, fontWeight: 600 }}>FIRMA DE FINALIZACIÓN</h3>}
    >
      <Row gutter={16} style={{ marginBottom: 25 }}>
        <Col span={6}>
          <Row>
            <label style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: 12, color: '#545454' }}>
              Espacio:
            </label>
          </Row>

          <span style={{ fontSize: 12, color: '#696969' }}>
            {detailChecklistById?.checklistInstanceDetail?.place}
          </span>
        </Col>

        <Col span={6}>
          <Row>
            <label style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: 12, color: '#545454' }}>
              Asignado:
            </label>
          </Row>

          <span style={{ fontSize: 12, color: '#696969' }}>
            {detailChecklistById?.checklistInstanceDetail?.employee}
          </span>
        </Col>

        <Col span={6}>
          <Row>
            <label style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: 12, color: '#545454' }}>
              Fecha:
            </label>
          </Row>

          <span style={{ fontSize: 12, color: '#696969' }}>
            {detailChecklistById?.checklistInstanceDetail?.session?.date}
          </span>
        </Col>

        <Col span={6}>
          <Row>
            <label style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: 12, color: '#545454' }}>
              Hora:
            </label>
          </Row>

          <span style={{ fontSize: 12, color: '#696969' }}>
            {detailChecklistById?.checklistInstanceDetail?.session?.timeBegin}
          </span>
        </Col>
      </Row>
      
      <Row>
        <Col span={24}>
          <Image
            alt="sign-user-image"
            style={{ borderRadius: 8 }}
            width={"100%"}
            src={detailChecklistById?.checklistInstanceDetail?.sign_url}
          />
        </Col>
      </Row>
    </CustomModal>
  )
}

export default ChecklistSignModal
