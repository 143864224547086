import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Col, Row, Typography, Image, Carousel } from "antd";
import { emptyFilesIcon } from '../../../../assets/icons/'
import FileIcon from "../../../../../../../assets/icons/FileIcon";

const { Paragraph } = Typography;


const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <ArrowLeftOutlined
    {...props}
    className={
      "slick-prev slick-arrow" +
      (currentSlide === 0 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  />
);
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <ArrowRightOutlined
    {...props}
    className={
      "slick-next slick-arrow" +
      (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  />
);

export const Comment = ({
  employee_name = "Carmen Guzmán",
  message = "Encontré unas cerámicas rotas en el parqueo",
  pictures,
  files,
}) => {

  //Para mostrar 3 imagenes por slide...
  const slides = [];
  for (let i = 0; i < pictures.length; i += 3) {
    slides.push(pictures.slice(i, i + 3).map((picture) => ({ ...picture, type: 'image' })));
  }

  for (let i = 0; i < files.length; i += 3) {
    slides.push(files.slice(i, i + 3).map((file) => ({ ...file, type: 'file' })));
  }

  if(slides.some((item) => item.some(item2 => item2.file === ''))) return

  return (
    <Col className="comments__comment">
      <strong>{employee_name}</strong>
      <Row gutter={40}>
        <Col span={12}>
          <p>OBSERVACIÓN:</p>
          <Row className="comments_comment__item-wrapper">
            <Paragraph
              ellipsis={{ rows: 3, expandable: true, symbol: "Mostrar más" }}
            >
              <span>{message}</span>
            </Paragraph>
          </Row>
        </Col>
        <Col span={12}>
          <p>ARCHIVOS:</p>
          <Row className="comments_comment__item-wrapper images">
            {
              !slides.length ? (
                <Row align="middle" justify="center" style={{ flexDirection: 'column' }}>
                  <img src={emptyFilesIcon} alt="empty" />
                  <p>No hay archivos adjuntos</p>
                </Row>
              ) : <Carousel
                draggable={true}
                arrows={true}
                prevArrow={ <SlickArrowLeft /> }
                nextArrow={<SlickArrowRight /> }
                slidesToScroll={1}
                dots={false}
                style={{ width: "100%", height: "100%" }}
              >
                {slides?.map((slide, index) => {
                  return (
                    <Row key={index} className="images">
                      {slide?.map((element, index) => {

                        if (element.type === 'image' && element.file !== "") {
                          return (
                            <Image
                              style={{ borderRadius: 8 }}
                              key={element.image_id}
                              height={160}
                              src={element.file}
                              alt={`image-${element.id}`}
                            />
                          );
                        }
                        if (element.type === 'file') {
                          return <FileIcon key={element.file_id} width={100} urlFile={element.file} />
                        }
                        return <></>
                      })}
                    </Row>
                  );
                })}
              </Carousel>
            }
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
