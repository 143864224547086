import useRedirectTo from "../../../../hooks/useRedirectTo"
import useCurrentLocation from "../../../../hooks/useCurrentLocation"
import { getDailyTasks } from "../services/GET/getDailyTasks";
import { verifyResponse } from "../../../../helpers/verifyResponse";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import 'moment/locale/es';

export const useActivityMonitoring = () => {
    moment.locale('es');

    const auth = useSelector((state) => state.auth);
    const currentCompanyId = useSelector((state) => state?.auth?.currentRol)
    const { param } = useCurrentLocation();
    const { handleRedirectTo } = useRedirectTo();

    const [loading, setLoading] = useState(false)
    const [dailyTasks, setDailyTasks] = useState({});
    const [dateDaily, setDateDaily] = useState(sessionStorage.getItem('activityMonitoringDate') ? sessionStorage.getItem('activityMonitoringDate') : 'today')

    const getDailyTaskService = async ({ formData }) => {
        setLoading(true);
        const res = await getDailyTasks({ token: auth.idToken, formData, company_id: currentCompanyId});
        const status = verifyResponse({
            dataResponse: res.data,
            statusResponse: res.status,
            showToast: false,
        });
        setLoading(false);
        if (status) {            
            setDailyTasks(res.data.data);
            return res.data.data;
        }
        throw new Error("Error");
    }

    const handleChangeDateDailyTask = async ({ date }) => {        
        setDateDaily(date);
        await getDailyTaskService({ formData: { filter_by: date } })
        sessionStorage.setItem('activityMonitoringDate', date)

        // setDateDaily(moment(date).format('YYYY-MM-DD'));
        // await getDailyTaskService({ formData: { date: moment(date).format('YYYY-MM-DD') } })
        // sessionStorage.setItem('activityMonitoringDate', moment(date).format('YYYY-MM-DD'))
    }

    const addZeroToNumber = (number) => {
        if (!number) return '00';
        return number < 10 ? `0${number}` : number;
    }


    const totalDailyPercent = dailyTasks.summary ? ((dailyTasks.summary.completed / (dailyTasks.summary.in_progress + dailyTasks.summary.delayed)) * 100) : 0;

    return { loading, param, dailyTasks, totalDailyPercent, dateDaily, addZeroToNumber, handleChangeDateDailyTask, handleRedirectTo, getDailyTaskService };
}