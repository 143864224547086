import CustomDrawer from './../../../../UI/CustomDrawer'
import { Button, Col, Image, Row, Typography } from 'antd'
import bgEvent from '../../../../../assets/icons_png/bg_default_event.jpg'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { setCurrentTypeNewClicked, setDetailTypeNew } from '../../../../../store/typesNews/typesNewsSlice'

// imagenes o archivos
import { ArrowLeftOutlined } from '@ant-design/icons'
import user from '../../../../../assets/icons/Generals/typesNews/user-detail-new.svg'
import clock from '../../../../../assets/icons/Generals/typesNews/clock-detail-new.svg'
import calendar from '../../../../../assets/icons/Generals/typesNews/calendar-detail-new.svg'
import location from '../../../../../assets/icons/Generals/typesNews/location-detail-new.svg'

const { Text } = Typography

const DetailTypeNewDrawer = ({
  isOpenDrawer,
  handleOpenCloseDrawer
}) => {
  const dispatch = useAppDispatch()

  const { detailTypeNew, currentTypeNewClicked } = useAppSelector(state => state.news)

  return <CustomDrawer
    isOpenDrawer={isOpenDrawer}
    onClose={() => {
      handleOpenCloseDrawer()
      dispatch(setCurrentTypeNewClicked({}))
      dispatch(setDetailTypeNew({}))
    }}
    closeIcon={<ArrowLeftOutlined style={{ color: "#FFFFFF", fontSize: 18 }} />}
    className="custom-drawer-type-new"
    title="DETALLE DE NOTICIA"
    width={450}
  >
    <Row>
      <Col span={24}>
        <Row style={{ position: 'relative', zIndex: 1 }}>
          <Col span={24}>
            {(currentTypeNewClicked?.type !== 'Notificacion') && <Image
              alt='header-img-type-new'
              src={detailTypeNew?.header || bgEvent}
              width={'100%'}
              height={184}
              style={{ objectFit: 'cover', zIndex: 1 }}
            />}

            <Row
              style={{
                marginTop: (currentTypeNewClicked?.type !== 'Notificacion') ? '-40px' : '20px',
                marginInline: 20,
                zIndex: 2,
                position: 'absolute',
                backgroundColor: "#FFFFFF",
                borderRadius: 8,
                boxShadow:'0px 2px 15px 0px #53535333',
              }}
            >
              <Col
                span={24}
                style={{
                  padding: '25px 15px'
                }}
              >
                <Row>
                  <p
                    style={{
                      margin: 0,
                      backgroundColor: detailTypeNew?.NEWS_TYPE?.background_color,
                      borderRadius: 50,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 500,
                        color: detailTypeNew?.NEWS_TYPE?.text_color,
                        padding: '4px 12px 4px 12px',
                      }}
                    >
                      {detailTypeNew?.NEWS_TYPE?.name}
                    </span>
                  </p>
                </Row>

                <Row style={{ margin: '10px 0' }}>
                  <Col span={24}>
                    <h3 style={{ margin: 0, fontWeight: 600, fontSize: 22, color: '#3C5D8F' }}>
                      {detailTypeNew?.title}
                    </h3>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <p style={{ margin: 0, color: '#707070', fontWeight: 400, fontSize: 12 }}>
                      {detailTypeNew?.created_at}
                    </p>
                  </Col>
                </Row>

                {((currentTypeNewClicked?.type !== 'Notificacion')
                  && ((detailTypeNew?.NEWS_MEDIA?.NEWS_IMAGES?.length !== 0)
                      ? <Row justify='center' gutter={4} style={{ overflow: 'auto', maxHeight: 85, margin: '25px 0' }}>
                          {detailTypeNew?.NEWS_MEDIA?.NEWS_IMAGES?.map((imageData, index) => (
                            <Col key={`img-${imageData}-${index}`} span={12}>
                              <Image src={imageData} width={'100%'} height={85} alt={`img-${index}`} style={{ borderRadius: 8 }} />
                            </Col>
                          ))}
                      </Row>
                      : null
                      ))
                }
                
                {currentTypeNewClicked?.type === "Evento"
                  && <Row>
                        <Col span={24}>
                          <Row align="top" gutter={16} style={{ margin: '10px 0' }}>
                            <Col>
                              <img src={calendar} alt="calendar-icon" />
                            </Col>

                            <Col>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label style={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>
                                  FECHA
                                </label>

                                <span style={{ fontSize: 12, fontWeight: 500, color: '#505050' }}>
                                  {detailTypeNew?.NEWS_INVITE?.date}
                                </span>
                              </div>
                            </Col>
                          </Row>

                          <Row align="top" gutter={16} style={{ margin: '10px 0' }}>
                            <Col>
                              <img src={clock} alt="clock-icon" />
                            </Col>

                            <Col>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label style={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>
                                  HORARIO
                                </label>

                                <span style={{ fontSize: 12, fontWeight: 500, color: '#505050' }}>
                                  {detailTypeNew?.NEWS_INVITE?.schedule}
                                </span>
                              </div>
                              </Col>
                          </Row>

                          <Row align="top" gutter={16} style={{ margin: '10px 0' }}>
                            <Col>
                              <img src={location} alt="location-icon" />
                            </Col>

                            <Col>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label style={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>
                                  LUGAR
                                </label>

                                <span style={{ fontSize: 12, fontWeight: 500, color: '#505050' }}>
                                  {detailTypeNew?.NEWS_INVITE?.place}
                                </span>
                              </div>
                            </Col>
                          </Row>

                          <Row align="top" gutter={16} style={{ margin: '10px 0' }}>
                            <Col>
                              <img src={user} alt="user-icon" />
                            </Col>

                            <Col>
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label style={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>
                                  INVITA
                                </label>

                                <span style={{ fontSize: 12, fontWeight: 500, color: '#505050' }}>
                                  {detailTypeNew?.NEWS_INVITE?.host}
                                </span>
                              </div>
                            </Col>
                          </Row>
                      </Col>
                    </Row>
                }

                <Row style={{ marginTop: 25 }}>
                  <Col span={24}>
                    <label style={{ color: '#252525', fontSize: 12, fontWeight: 500 }}>
                      DESCRIPCIÓN
                    </label>

                    <p style={{ margin: '10px 0', fontWeight: 400, fontSize: 13, color: '#5C5C5C', textAlign: 'justify' }}>
                      {detailTypeNew?.description}
                    </p>
                  </Col>
                </Row>

                {currentTypeNewClicked?.type !== 'Notificacion' && <Row style={{ margin: '15px 0' }}>
                    <Col span={24}>
                      <Button
                        type="text"
                        htmlType="button"
                        disabled={!(detailTypeNew?.action_link)}
                        style={{ border: '1px solid #252525', borderRadius: 8, width: '100%' }}
                      >
                        <a href={detailTypeNew?.action_link}>
                          <span style={{ fontWeight: 400, fontSize: 12 }}>
                            {!(detailTypeNew?.action_link) ? "No posee link" : "Más información"}
                          </span>
                        </a>
                      </Button>
                    </Col>
                  </Row>
                }

                {currentTypeNewClicked?.type !== 'Notificacion'
                  && ((detailTypeNew?.NEWS_MEDIA?.NEWS_FILES?.length !== 0)
                        ? <Row justify='center' align='middle' gutter={[8,8]} style={{ margin: '25px 0' }}>
                            <label style={{ fontWeight: 500, fontSize: 12 }}>ARCHIVOS ADICIONALES</label>
                            {detailTypeNew?.NEWS_MEDIA?.NEWS_FILES?.map((fileData, index) => (
                              <Col
                                span={24}
                                key={`file-${fileData}-${index}`}
                                style={{ border: '1px solid #707070', borderRadius: 8 }}
                              >
                                <Row gutter={8} align='middle' style={{ padding: '10px 20px' }}>
                                  <Col>
                                    <Image src={fileData.icon} width={28} height={28} />
                                  </Col>

                                  <Col>
                                    <a
                                      target='_blank'
                                      rel='noreferrer'
                                      href={fileData.url}
                                      style={{ padding: '16px 8px', fontWeight: 500, fontSize: 12, color: '#707070' }}
                                    >
                                      <Text
                                        style={{ width: 218 }}
                                        ellipsis={{ tooltip: fileData?.name ?? '' }}
                                      >
                                        {fileData?.name}
                                      </Text>
                                    </a>
                                  </Col>

                                </Row>
                              </Col>
                            ))}
                          </Row>
                        : <Row justify='center' style={{ marginTop: 15 }}>
                            <span>No hay archivos adjuntos</span>
                          </Row>
                    )
                }

              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  </CustomDrawer>
}

export default DetailTypeNewDrawer
