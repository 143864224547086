import { Button } from 'antd'
import topiaLogo from '../../assets/logos/topiaLogo-white.svg'
import alertIcon from './assets/alert.svg'
import { ReactComponent as GooglePlayIcon } from './assets/googlePlayIcon.svg'
import { ReactComponent as AppleStoreIcon } from './assets/appleStoreIcon.svg'
import heaven from './assets/heaven.svg'
import './styles.css'

export const MobileAlert = () => {
  return (
    <>
      <main className='no-code-container'>
        <img id='no-code-container__heaven' src={heaven} width={200} alt='heaven-icon' />
        <div>
          <img id='no-code-container__alert' src={alertIcon} alt="alert-icon" />
          <h1>¡OOPS!</h1>
          <p>Solo puedes acceder a esta página desde una computadora</p>
          <div>
            <small className='no-code-container__try-apps' >Prueba descargando la app en:</small>
            <div className='no-code-container__apps-btns'>
              <Button type='text' href='https://play.google.com/store/apps/details?id=com.topiaappadmin.app' target='_blank' icon={<GooglePlayIcon />}>
                <div>
                  <small>GET IT ON</small>
                  <p>Google Play</p>
                </div>
              </Button>
              <Button type='text' href='https://apps.apple.com/sv/app/topia-admin/id6470135392?l=en-GB' target='_blank' icon={<AppleStoreIcon />} >
                <div>
                  <small>Download on the</small>
                  <p>App Store</p>
                </div>
              </Button>
            </div>
          </div>
        </div>
        <img id='no-code-container__logo' src={topiaLogo} alt="topia-logo" />
      </main>
    </>
  )
}

export default MobileAlert
