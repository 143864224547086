import { useEffect } from 'react'
import useAlerts from '../hooks/useAlerts'
import AlertActionsButtons from './AlertActionsButtons'
import { useAppSelector } from '../../../../hooks/store'
import DetailAlertDrawer from './drawer/DetailAlertDrawer'
import CustomTable from '../../../UI/CustomTable/CustomTable'
import useOpenCloseModal from '../../../hooks/useOpenCloseModal'
import { columnsAlertTable } from "../../../../helpers/alertHelper"

const AlertsTable = ({ loading, setLoading, activeFeatures }) => {
  const { getAllAlerts } = useAlerts()
  const { alertsActive, alertsArchive, currentAlertTab } = useAppSelector( state => state.alerts )
  // Se utiliza el mismo customhook ya que el fin es el mismo de abrir o cerrar algun componente
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  // Cambiamos la data entre las alertas activas o archivadas dependiendo de la tab que este selccionada
  const currentDataSource = currentAlertTab === '1' ? alertsArchive : alertsActive
  
  // Se realiza la peticion inicial de las alertas dependiendo del residential_id seleccionado
  useEffect(() => {
    getAllAlerts({ isLimit: false })
  }, [])

  // Se agrega la columna de detalle para poder manipular la drawer
  const newColumns = [
    ...columnsAlertTable ?? [],
    {
      title: "DETALLE",
      dataIndex: 'actions',
      key: 'actions',
      align: "center",
      render: (_, record) => <AlertActionsButtons activeFeatures={activeFeatures} alertData={record} setLoading={setLoading} handleOpenCloseDrawer={handleOpenCloseModal} />
    }
  ]

  return <>
    <CustomTable
      loading={loading}
      className='custom-ant-table'
      rowKey={record => `${record?.address}+${record?.created_time}`}
      columns={newColumns ?? []}
      dataSource={currentDataSource ?? []}
      pagination={{
        showTotal: (total, range) => {
          return `${range[0]}-${range[1]} de ${total}`;
        },
      }}
    />

    <DetailAlertDrawer isOpenDrawer={isOpenModal} handleOpenCloseDrawer={handleOpenCloseModal} />
  </>
}

export default AlertsTable
