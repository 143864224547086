import moment from "moment"
import { useState } from "react"
import { Button, Col, Form, Row } from "antd"
import CustomModal from "../../../../UI/CustomModal"
import { config } from "../../../../constants/constants"
import { CustomSelect } from "../../../../UI/CustomSelect"
import useImageChecklist from "../../hooks/useImageChecklist"
import { CustomDatePicker } from "../../../../UI/CustomDatePicker"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store"
import { setChecklistClicked } from "../../../../../store/adminChecklist/adminChecklistSlice"

const NewEditTestModal = ({
  form,
  isOpenModal,
  handleOpenCloseModal
}) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)

  const { filters, checklistClicked, activeOrnamentChecklist } = useAppSelector(state => state.adminCheck)
  const { createUpdateChecklist, getChecklistsInstances, getAllEmployees } = useImageChecklist()

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      handleOpenCloseModal()
      await getChecklistsInstances({ ...filters })
    }
  }

  const onSubmit = async (formData) => {
    setLoading(true)

    const isEditChecklist = checklistClicked?.checklist_instance_id
      ? { checklist_instance_id: checklistClicked.checklist_instance_id }
      : {}

    const objToSend = {
      ...formData,
      ...isEditChecklist,
      checklist_id: activeOrnamentChecklist[0]?.checklist_id,
      date: moment(formData.date).format('YYYY-MM-DD')
    }

    const response = await createUpdateChecklist(objToSend)

    await onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal
      destroyOnClose
      title={<h3 className="title-modal" style={{ margin: 0 }}>{checklistClicked?.checklist_instance_id ? 'EDITAR EVALUACIÓN' : 'NUEVA EVALUACIÓN'}</h3>}
      isOpenModal={isOpenModal}
      onCancel={() => {
        dispatch(setChecklistClicked({}))
        handleOpenCloseModal()
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="newEditTestForm"
        onFinish={onSubmit}
        disabled={loading}
      >
        <Row>
          <Col span={24}>
            <label style={{ fontWeight: 600, fontSize: 12, color: '#626262' }}>
              EMPLEADO
            </label>
            <Form.Item name="employee_id" {...config}>
              <CustomSelect
                isAsync
                allowClear
                service={getAllEmployees}
                placeholder="Seleccione un empleado"
              />
            </Form.Item>

            <label style={{ fontWeight: 600, fontSize: 12, color: '#626262' }}>
              FECHA DE EVALUACIÓN
            </label>
            
            <Form.Item name="date" {...config}>
              <CustomDatePicker allowClear />
            </Form.Item>

            <Row gutter={16} justify="end">
              <Col>
                <Button
                  htmlType="button"
                  type="text"
                  shape="round"
                  className="btn-standard-white"
                  style={{ width: 138 }}
                  disabled={loading}
                  onClick={() => {
                    dispatch(setChecklistClicked({}))
                    handleOpenCloseModal()
                  }}
                >
                  <span>CANCELAR</span>
                </Button>
              </Col>

              <Col>
                <Button
                  htmlType="submit"
                  type="text"
                  shape="round"
                  className="btn-standard-green"
                  disabled={loading}
                  loading={loading}
                  style={{ width: 138 }}
                >
                  <span>GUARDAR</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default NewEditTestModal
