import verifyResponse from "../../topia/helpers/verifyResponse"
import { getDetailSpaceByPlaceId, getPlacesTreeByCompanyId, getTagsElementsByPlaceId } from "../../topia/pages/buildingSketchs/services"
import getPlaceAnalyticsByPlaceId from "../../topia/pages/buildingSketchs/services/general/getPlaceAnalyticsByPlaceId"
import { setClickedPlaceData, setCurrentTreeSelectData, setPlaceAnalyticsData, setTagsElements } from "./mallsSlice"

// get funtions
export const getPlaceAnalytics = async (placeId) => async (dispatch, getState) => {
  const { idToken } = getState().auth
  const response = await getPlaceAnalyticsByPlaceId(placeId, idToken)

  if (response.success) {
    dispatch(setPlaceAnalyticsData(response.data.data))
    return true
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getTreeSelectLevels = async (companyId) => async (dispatch, getState) => {
  const { idToken } = getState().auth
  const response = await getPlacesTreeByCompanyId(companyId, idToken)

  if (response.success) {
    dispatch(setCurrentTreeSelectData(response.data.data))
    return response
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getSvgsElementsToBuilMap = async (placeId) => async (dispatch, getState) => {
  const { idToken } = getState().auth
  const response = await getTagsElementsByPlaceId(placeId, idToken)

  if (response.success) {
    dispatch(setTagsElements(response.data.data))
    return true
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getDetailPlaceById = async (placeId) => async (dispatch, getState) => {
  const { idToken } = getState().auth
  const response = await getDetailSpaceByPlaceId(placeId, idToken)

  if (response.success) {
    dispatch(setClickedPlaceData(response.data.data))
    return true
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}