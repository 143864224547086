import { Col, Row } from "antd"
import useSurveysForm from "../../../hooks/useSurveysForm"
import { CustomSelect } from "../../../../../UI/CustomSelect"
import { CustomDatePicker } from "../../../../../UI/CustomDatePicker"
import locale from 'antd/es/date-picker/locale/es_ES';

const StepThree = () => {

    const { onSubmitForm, handleChangeDatePicker, initialFormData, formRef, form, config, Form } = useSurveysForm()

    return (
        <Form
            className="survey-step-three"
            name="survey-step-three"
            layout="vertical"
            autoComplete="off"
            initialValues={initialFormData}
            form={form}
            ref={formRef}
            onFinish={onSubmitForm}>
            <Form.Item {...config} name={'idTipo'} label='TIPO DE ENCUESTA:'>
                <CustomSelect options={[{ id: 'obligatoria', name: 'Obligatoria' }, { id: 'opcional', name: 'Opcional' }]} />
            </Form.Item>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item {...config} label={'FECHA DE PUBLICACIÓN'} name={'startDate'}>
                        <CustomDatePicker locale={locale}  onChange={handleChangeDatePicker} disabledDateBeforeToday={true} className={'custom-date-picker right-icon'} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item noStyle shouldUpdate={(prev, cur) => {
                        return (
                            prev.startDate !==
                            cur.startDate
                        );
                    }}>
                        {
                            ({ getFieldValue }) => {
                                const startDate = getFieldValue('startDate')
                                return <Form.Item {...config} label={'FECHA DE EXPIRACIÓN'} name={'endDate'} >
                                    <CustomDatePicker locale={locale} disabledDateDependsOnStartDate={startDate} className={'custom-date-picker right-icon'} />
                                </Form.Item>
                            }
                        }
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default StepThree