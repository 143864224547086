import { Button, Col, Row } from "antd"
import { CustomNavigate } from "../../../../../UI/CustomNavigate"
import { PlusCircleOutlined } from "@ant-design/icons"
import { useContext } from "react"
import { AdminChecklistContext } from "../context/AdminChecklistContext"

const HeaderAdminChecklist = ({
  formChecklist,
  handleOpenCloseModal,
  handleOpenCloseCategoryModal,
}) => {
  const { form, defaultChecklistData, setChecklistCategoryClicked } = useContext(AdminChecklistContext)

  return (<>
    <CustomNavigate title="ADMINISTRACIÓN DE CHECKLIST">
      <Row align="middle" gutter={16}>
        <Col>
          <Button
            type="text"
            htmlType="button"
            shape="round"
            onClick={() => {
              formChecklist.setFieldsValue({ name: defaultChecklistData?.name })
              handleOpenCloseModal()
            }}
            style={{ height: 40, width: 190, border: '1px solid #404040' }}
          >
            <span style={{ fontWeight: 500, fontSize: 14, color: '#404040' }}>
              EDITAR CHECKLIST
            </span>
          </Button>
        </Col>

        <Col>
          <Button
            type="text"
            htmlType="button"
            shape="round"
            style={{ width: 221 }}
            onClick={() => {
              form.resetFields()
              handleOpenCloseCategoryModal()
              setChecklistCategoryClicked({})
            }}
            className="btn-standard-green"
          >
            <Row gutter={16}>
              <Col>
                <span>NUEVA CATEGORÍA</span>
              </Col>

              <Col>
                <PlusCircleOutlined style={{ fontSize: 13, lineHeight: 0 }} />
              </Col>
            </Row>
          </Button>
        </Col>
      </Row>
    </CustomNavigate>

    <h3 style={{ color: '#0D9C7A', fontSize: 16, fontWeight: 600, marginLeft: 50, marginBottom: 0 }}>
      {defaultChecklistData?.name}
    </h3>
  </>
  )
}

export default HeaderAdminChecklist