import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  alertsActive: [],
  alertsArchive: [],
  isLoading: false,
  currentAlertTab: '',
  alertSelectedDetail: {},
  filters: {}
}

export const alertSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setAlertsActive: ( state, { payload } ) => {
      state.alertsActive = payload ?? [];
    },
    setAlertsArchive: ( state, { payload } ) => {
      state.alertsArchive = payload ?? [];
    },
    setAlertSelectedDetail: ( state, { payload }) => {
      state.alertSelectedDetail = payload
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setCurrentAlertTab: (state, { payload }) => {
      state.currentAlertTab = payload
    },
    setFilters: (state, { payload }) => {
      state.filters = payload
    }
  }
})

export const {
    setFilters,
    setIsLoading,
    setAlertsActive,
    setAlertsArchive,
    setCurrentAlertTab,
    setAlertSelectedDetail,
} = alertSlice.actions
