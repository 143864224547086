import { useContext } from "react"
import { UsersContext } from "../context/UsersContext"
import { useAppSelector } from "../../../../hooks/store"
import verifyResponse from "../../../helpers/verifyResponse"
import { dataToFormData } from "../../../../helpers/dataToFormData"
import { filterUserPlaces, replacePlacesHelper } from "../helpers/usersHelpers"
import { createNewOwner, getAllSpaces, getAllWareHouses, getAvailablesParking, getPlaceAvailabilityOption, getPlaceOwnerDetail,getPlaceOwnerExcelReport,getPlaceOwnerTypes,getPlaceOwnerUpdateDetail, getPlaceOwnersByCompanyId, getUserTypes, updateOwner } from "../services"

const useUsers = () => {
  const { idToken, currentRol } = useAppSelector(state => state.auth)

  const {
    typeUserSelected, userClicked,
    setLoading, setMetadata,
    setLoadingExcel, setUserClicked,
    setTypesOwner, setOwnerPlaces,
    setUsersTableData, setUserDetailData,
  } = useContext(UsersContext)

  const onPrepareUsersData = async (data, userData) =>{
    const response = await filterUserPlaces(data, userData)
    
    const formData = userData?.profile?.profile_id ? { 
      company_id: currentRol,
      phone_area: data.phone_area,
      phone_number: data.phone_number,
      first_name: data.name,
      last_name: data.last_name,
      mail: data.email,
      profile_id: userData?.profile?.profile_id,
      place_owner_type_id: userData?.places[0]?.place_owner_type_id,
      places: await replacePlacesHelper(response,userData)
    } : {   
      company_id: currentRol,
      phone_area: data.phone_area,
      phone_number: data.phone_number,
      first_name: data.name,
      last_name: data.last_name,
      mail: data.email,
      place_owner_type_id: data.type_user,
      file: data.type_user === 2 ? data.file.file.originFileObj : null,
      places: JSON.stringify([
        {
          place_id: data.space_,
          dependencies: [
            {
              place_id: data.parking_
            },
            {
              place_id: data.ware_house_
            }
          ]
        }
      ])
    }

    return formData
  }

  const onSaveUser = async (formData) => {
    const functionToExecute = userClicked?.profile?.profile_id ? updateOwner : createNewOwner
    const messageToShow = userClicked?.profile?.profile_id ? 'Se editó el usuario con éxito' : 'Se creó el usuario con éxito'
    
    const dataToForm = userClicked?.profile?.profile_id ? formData : dataToFormData(formData)

    const response = await functionToExecute(dataToForm , idToken)
    
    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: messageToShow })
  }

  const getDetailUserData = async (placeId) => {
    setLoading(true)
    const response = await getPlaceOwnerDetail(placeId, idToken)

    if (response.success) {
      setUserDetailData(response.data.data)
      setLoading(false)

      return true
    }

    setLoading(false)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  const getUsersTableData = async ({
    findValue,
    ownerProfileId,
    sortBy,
    sortOrder,
    skip,
    limit,
    availableCode,
    ownerTypeId
  }) => {
    setLoading(true)

    const response = await getPlaceOwnersByCompanyId({
      companyId: currentRol,
      findValue,
      ownerProfileId,
      sortBy,
      sortOrder,
      skip,
      limit,
      idToken,
      availableCode,
      ownerTypeId
    })

    if (response.success) {
      setUsersTableData(response.data.data)
      setMetadata(response.data.metadata)
      setLoading(false)

      return true
    }

    setLoading(false)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  const getDetailUserToUpdate = async (profileId) => {
    setLoading(true)

    const response = await getPlaceOwnerUpdateDetail({ companyId: currentRol, profileId, idToken })

    if (response.success) {
      setUserClicked(response.data.data)
      setLoading(false)

      return response
    }

    setLoading(false)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  const getExcelReport = async () => {
    setLoadingExcel(true)
    const response = await getPlaceOwnerExcelReport(currentRol, idToken)

    if (response.success) {
      setLoadingExcel(false)
      return response
    }

    setLoadingExcel(false)
    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  const getAllUserTypes = async () => {
    const response = await getUserTypes(idToken)

    return response
  }

  const getTypesOwner = async () => {
    const response = await getPlaceOwnerTypes(idToken)

    if (response.success) {
      setTypesOwner(response.data.data)
      
      return true
    }

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  const getAvailabilityOptions = async () => {
    const response = await getPlaceAvailabilityOption(idToken)

    if (response.success) {
      setOwnerPlaces(response.data.data)

      return true
    }

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  // Funciones encargadas de realizar las peticiones para los selects de parqueos, bodegas y espacios
  const getAllParkingToSelect = async ({ find_value }) => {
    return getAvailablesParking({ companyId: currentRol, idToken, find_value })
  }

  const getAllSpacesToSelect = async ({ find_value }) => {
    return getAllSpaces({ companyId: currentRol, idToken, find_value, profileType: typeUserSelected })
  }

  const getAllWareHouseToSelect = async ({ find_value }) => {
    return getAllWareHouses({ companyId: currentRol, idToken, find_value })
  }

  return {
    onSaveUser,
    onPrepareUsersData,
    getTypesOwner,
    getAllUserTypes,
    getExcelReport,
    getDetailUserData,
    getUsersTableData,
    getDetailUserToUpdate,
    getAvailabilityOptions,
    getAllParkingToSelect,
    getAllSpacesToSelect,
    getAllWareHouseToSelect,
  }
}

export default useUsers