import { Button, Menu, Row } from "antd";
import React from "react";

const QuantityInventory = ({ name, stock, quantityUpdate }) => {
  const borderBottom = stock === 1 ? "none" : "1px solid #000";
  const renderButtons = () => {
    const buttons = [];
    for (let i = 1; i <= stock; i++) {
      buttons.push(
        <Button
          key={i}
          style={{
            border: "none",
            borderBottom: borderBottom,
            width: "80%",
          }}
          onClick={() => handleButtonClick(i)}
        >
          {i}
        </Button>
      );
    }
    return buttons;
  };

  const handleButtonClick = (quantity) => {
    quantityUpdate(name, quantity);
  };
  return (
    <Menu style={{ borderRadius: "10px", padding: "2rem", width: "15rem" }}>
      <Row justify="center">
        <p style={{ fontSize: "13px", fontWeight: "bold" }}>CANTIDAD</p>
      </Row>
      <div style={{ height: "15vh", overflow: "auto" }}>
        <Row justify="center">{renderButtons()}</Row>
      </div>
    </Menu>
  );
};

export default QuantityInventory;
