import { useState } from "react"
import useTickets from "../../hooks/useTickets"
import CustomModal from "../../../../UI/CustomModal"
import { Button, Input, Form, Row, Select } from "antd"
import { useAppSelector } from "../../../../../hooks/store"
import CustomUploadFile from "../../../../UI/CustomUploadFile/CustomUploadFile"

import { ReactComponent as Assign} from "../../../../../assets/icons/assign.svg"

const CreateTaskModal = ({ onClose, isOpenModal }) => {
  const { TextArea } = Input
  const [form] = Form.useForm()
  const config = { rules: [{ required: true, message: "Por favor llenar el campo" }] }

  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [subcategories, setSubcategories] = useState([])

  const { onSaveTickets, getAllDataTickets } = useTickets()

  const { currentRol, currentEmployee } = useAppSelector((state) => state.auth)
  const employees = useAppSelector((state) => state.detailTicketById.employeeTicket)
  const ticketCategories = useAppSelector((state) => state.detailTicketById.ticketCategory)
  
  const categories = ticketCategories?.map((category) => ({ value: category?.id, label: category?.name }))

  const handleCategoryChange = (value) => {
    const selectedCategory = ticketCategories?.find(
      (category) => category?.id === value
    )

    if (selectedCategory) {
      setSubcategories(
        selectedCategory.TICKET_SUBCATEGORies?.map((subcategory) => {
          return {
            value: subcategory?.id,
            label: subcategory?.name,
          };
        })
      );
    }
    form.resetFields(["subcategoria"]);
  };

  const Label = ({ name }) => (
    <Row justify="space-between">
      <Row align="middle">
        <Assign />
        <p style={{ marginLeft: "1rem" }}>{name}</p>
      </Row>
    </Row>
  );


  const employeeList = employees?.map((employee) => {
    const smallName = employee?.name.slice(0, 20)

    return {
      value: employee?.id,
      label: <Label name={smallName} />,
    }
  })

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      onClose()
      form.resetFields()
      setFileList([])
      await getAllDataTickets()
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)

    // Mapeamos a que solamente queden los Files en el array sin demas informacion basura
    const newFileList = fileList?.map((file) => file.originFileObj)

    // Construimos y agregamos el objeto que se enviara
    const objToSend = {
      ...data,
      files: newFileList,
      company_id: currentRol,
      employee_creator_id: currentEmployee,
    }

    const response = await onSaveTickets(objToSend)

    await onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal
      destroyOnClose
      onCancel={() => {
        onClose()
        setFileList([])
        form.resetFields()
      }}
      isOpenModal={isOpenModal}
    >
      <h2 style={{ textAlign: "center" }}> CREAR TAREA </h2>
      <Form
        className="standard-form"
        onFinish={onSubmit}
        disabled={loading}
        form={form}
        layout="vertical"
      >
        <Row align="middle" justify="space-around">
          <div style={{ width: "45%" }}>
            <label style={{ color: "#676767", fontSize: "12px" }}>
              Categoría
            </label>
            <Form.Item
              {...config}
              name="category_id"
            >
              <Select
                className="standard-form"
                placeholder={"Seleccionar Categoría"}
                onChange={handleCategoryChange}
                options={categories}
              ></Select>
            </Form.Item>
          </div>
          <div style={{ width: "45%" }}>
            <label style={{ color: "#676767", fontSize: "12px" }}>
              Subcategoría
            </label>
            <Form.Item
              {...config}
              name="subcategory_id"
            >
              <Select
                placeholder={"Seleccionar Subcategoría"}
                options={subcategories}
              ></Select>
            </Form.Item>
          </div>
        </Row>
        <Row align="middle" justify="space-around">
          <div style={{ width: "45%" }}>
            <label style={{ color: "#676767", fontSize: "12px" }}>
              Responsable
            </label>
            <Form.Item
              {...config}
              name="employee_id"
            >
              <Select
                allowClear
                options={employeeList}
                className="standard-form"
                placeholder={"Responsable"}
              ></Select>
            </Form.Item>
          </div>
          <div style={{ width: "45%" }}>
            <label style={{ color: "#676767", fontSize: "12px" }}>Título</label>
            <Form.Item
              {...config}
              name="title"
            >
              <Input
                style={{
                  fontSize: "14px",
                  borderRadius: "5px",
                  background: "#F6F6F6",
                }}
                placeholder={"Título"}
              />
            </Form.Item>
          </div>
        </Row>
        <label
          style={{ color: "#676767", fontSize: "12px", marginLeft: "1rem" }}
        >
          Descripción
        </label>
        <Row justify="center">
          <Form.Item
            {...config}
            name="description"
            style={{ width: "95%" }}
          >
            <TextArea
              style={{
                fontSize: "14px",
                borderRadius: "5px",
                background: "#F6F6F6",
              }}
              placeholder={"Descripción"}
            />
          </Form.Item>
        </Row>

        <Row justify="center">
          <Form.Item name='files' style={{ width:'100%' }} rules={[{ validator: async (_, value) => { return fileList?.length ? Promise.resolve() : Promise.reject(new Error('Debe haber un archivo o imagen a subir')) } }]}>
            <CustomUploadFile
              fixedWidth
              multipleFiles
              width={'100%'}
              height={225}
              minHeight={225}
              className="custom-upload-file-amenity"
              fileList={fileList}
              setFileList={setFileList}
            />
          </Form.Item>
        </Row>

        <Row justify="center">
          <Form.Item>
            <Button
              type="text"
              shape="round"
              htmlType="submit"
              loading={loading}
              className="btn-standard-green"
            >
              <span>CREAR Y ASIGNAR TAREA</span>
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default CreateTaskModal;
