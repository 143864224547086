import { useContext, useEffect } from "react"
import { AmenitiesContext } from "../../../context/AmenitiesContext"
import { createUpdateAmenity, getAllZonesAndClusterByCompanyId } from "../../../services"
import verifyResponse from "../../../../../helpers/verifyResponse"
import { useAppSelector } from "../../../../../../hooks/store"

const useAmenity = () => {
  const { currentRol, idToken } = useAppSelector(state => state.auth)
  const { setPlacesAndZonesTreeSelect } = useContext(AmenitiesContext)

  useEffect(() => {
    if (currentRol && idToken) {
      getAllPlacesTreeData()
    }
  }, [])

  // Actualizar o crear una amenidad
  const onSaveAmenity = async (formData) => {
    const response = await createUpdateAmenity(formData, idToken)

    if (response.success) {
      return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Información de la amenidad guardada' })
    }

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  // Obtener data del treeSelect para el formulario inicial
  const getAllPlacesTreeData = async () => {
    const response = await getAllZonesAndClusterByCompanyId(currentRol, idToken)

    if (response.success) {
      setPlacesAndZonesTreeSelect(response.data.data)
      return
    }

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  return {
    onSaveAmenity,
    getAllPlacesTreeData
  }
}

export default useAmenity