//ACCESO DE PROVEEDORES ✅

export const accessProvidersModule = {
    feature_code: 'FEAT-413',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-414',
            enabled: false,
        },
        {
            feature_code: 'FEAT-415',
            enabled: false,
        },
        {
            feature_code: 'FEAT-416',
            enabled: false,
        },
        {
            feature_code: 'FEAT-417',
            enabled: false,
        },
        {
            feature_code: 'FEAT-419',
            enabled: false,
        },
        {
            feature_code: 'FEAT-420',
            enabled: false,
        },
        {
            feature_code: 'FEAT-421',
            enabled: false,
        },
        {
            feature_code: 'FEAT-422',
            enabled: false,
        },
        {
            feature_code: 'FEAT-423',
            enabled: false,
        },
        {
            feature_code: 'FEAT-424',
            enabled: false,
        },
    ]
}