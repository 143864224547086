import axios from "axios";
import { dataToFormData } from "../../../../helpers/dataToFormData";

const addNewImage = async (URL_DEV, file, ticketId) => {
  try {
    const ticketAdmin = {
      file: file,
      ticket_id: ticketId,
    };
    const formData = dataToFormData(ticketAdmin);
    const response = await axios.post(`${URL_DEV}/detail/image`, formData);

    return {
      success: true,
      ...response,
    };
  } catch (errorResponse) {
    return {
      success: false,
      ...errorResponse.response,
    };
  }
};

export default addNewImage;
