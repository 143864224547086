import axios from "axios";
const resolvedTicket = async (URL_DEV, ticketId, employeeId) => {
  try {
    const resolved = {
      ticket_id: ticketId,
      employee_id: employeeId,
    };
    const response = await axios.put(`${URL_DEV}/resolve`, resolved);

    return {
      success: true,
      ...response,
    };
  } catch (errorResponse) {
    return {
      success: false,
      ...errorResponse.response,
    };
  }
};
export default resolvedTicket;
