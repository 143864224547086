import useUsers from "../../hooks/useUsers"
import { useContext, useState } from "react"
import CustomModal from "../../../../UI/CustomModal"
import { PlusCircleOutlined } from "@ant-design/icons"
import { CustomInput } from "../../../../UI/CustomInput"
import AdditionalSpaceForm from '../AdditionalSpaceForm'
import { UsersContext } from "../../context/UsersContext"
import { CustomSelect } from "../../../../UI/CustomSelect"
import { useAppSelector } from "../../../../../hooks/store"
import { Button, Col, Divider, Form, Row, Upload } from "antd"
import { verifyTypeContractFile } from "../../../../helpers/customUploadHelper"
import { config, configToEigthPhoneNumber, configToThreePhoneNumber } from "../../../../constants/constants"

// imagenes o archivos
import AddPlusIcon from "../../../../../assets/icons/AddPlusIcon"

const NewEditUserModal = () => {  
  const [loading, setLoading] = useState(false)
  
  const {
    form, filters, setUserClicked,
    isOpenModal, onCancel,
    userClicked, typesOwner,
    userSpaces, setUserSpaces,
    typeUserSelected, setTypeUserSelected
  } = useContext(UsersContext)
  
  const { typeRol } = useAppSelector(state => state.auth)
  const {
    onPrepareUsersData, onSaveUser,
    getUsersTableData, getAllParkingToSelect,
    getAllSpacesToSelect, getAllWareHouseToSelect
  } = useUsers()

  const { places } = userClicked

  const userTypesToSelect = typesOwner?.map((typeUser) => ({ id: typeUser.owner_type_id, name: typeUser.name }))
  const userTypesToRadio = typesOwner?.map((typeUser) => ({ value: typeUser.owner_type_id, label: typeUser.name }))

    // Eventos para agregar mas espacios 
    const handleAddSpace = () => {
      setUserSpaces([...userSpaces, {}])
    }
  
    // Funcion para subir archivos 
    const dummyRequest = async ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    };
  
    const handleTypeUser = async (typeUser) => {
      const typeOwnerUser = typeUser === 1 ? 'titular' : 'tenant'
      setTypeUserSelected(typeOwnerUser)
    }
  
    const onFinish = async (isSuccessfull) => {
      if (isSuccessfull) {
        await getUsersTableData({ ...filters, skip: 0, limit: 10 })
        onCancel()
        setUserSpaces([])
        setUserClicked({})
      }
    }
  
    // Funcion que se ejecuta al ejecutar el btn de la modal para crear o editar
    const onSubmit = async (formData) => {
      setLoading(true)
  
      const prepareDataToBuild = {
        ...formData,
        phone_area: (formData.phone_area).toString(),
        phone_number: (formData.phone_number).toString()
      }
  
      const buildFormData = await onPrepareUsersData(prepareDataToBuild, userClicked)
  
      const response = await onSaveUser(buildFormData)
  
      await onFinish(response)
      setLoading(false)
    }
  
    return (
      <CustomModal
        destroyOnClose
        onCancel={() => {
          onCancel()
          setUserSpaces([])
          setUserClicked({})
        }}
        isOpenModal={isOpenModal}
        title={<h2 style={{ textAlign: "center", fontSize: 22, margin: 0, fontWeight: 600 }}>
          {userClicked?.profile?.profile_id ? "EDITAR USUARIO PROPIETARIO" : 'NUEVO USUARIO'}
        </h2>}
      >
        <Form
          form={form}
          layout="vertical"
          disabled={loading}
          name="newEditUserForm"
          className="standard-form-user"
          onFinish={onSubmit}
        >
          {!(userClicked?.profile?.profile_id ) && (
            <>
              <label style={{ color: "#676767", fontSize: "12px" }}>
                TIPO DE USUARIO
              </label>
              <Form.Item
                {...config}
                name="type_user"
              >
                <CustomSelect
                  allowClear
                  placeholder='Seleccionar tipo de usuario'
                  optionFilterProp={"value"}
                  onChange={handleTypeUser}
                  options={userTypesToSelect}
                />
              </Form.Item>
            </>
          )}
  
          <Row gutter={10} align="middle">
            <Col span={12}>
              <Row gutter={5} align="middle">
                <Col span={8} >
                  <label style={{ color: "#676767", fontSize: "12px" }}>
                    TELÉFONO
                  </label>
                  <Form.Item
                    {...configToThreePhoneNumber}
                    name="phone_area"
                  >
                    <CustomInput
                      className="prefix-phone-area"
                      type="NUMBER"
                      nullInputNumberText={null}
                      prefix="+"
                      precision={0}
                      placeholder="503"
                      maxLength={3}
                      min={0}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ marginTop: 22 }} span={16}>
                  <Form.Item
                    {...configToEigthPhoneNumber}
                    name="phone_number"
                  >
                    <CustomInput
                      type="NUMBER"
                      nullInputNumberText={null}
                      precision={0}
                      min={0}
                      placeholder={"Número de teléfono"}
                      maxLength={8}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <label style={{ color: "#676767", fontSize: "12px" }}>
                NOMBRE
              </label>
              <Form.Item
                {...config}
                name="name"
              >
                <CustomInput
                  placeholder="Escribe tus nombres"
                />
              </Form.Item>
  
              <label style={{ color: "#676767", fontSize: "12px" }}>
                PARQUEO ASIGNADO
              </label>
              <Form.Item
                name="parking_"
              >
                <CustomSelect
                  isAsync
                  allowClear
                  service={getAllParkingToSelect}
                  placeholder={'Parqueo Asignado'}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label style={{ color: "#676767", fontSize: "12px" }}>
                CORREO ELECTRÓNICO
              </label>
              <Form.Item
                {...config}
                name="email"
              >
                <CustomInput
                  placeholder={"xxxx@gmail.com"}
                />
              </Form.Item>
  
              <label style={{ color: "#676767", fontSize: "12px" }}>
                APELLIDO
              </label>
              <Form.Item
                {...config}
                name="last_name"
              >
                <CustomInput
                  placeholder="Escribe tus apellidos"
                />
              </Form.Item>
  
              <label style={{ color: "#676767", fontSize: "12px" }}>
                BODEGA PERSONAL
              </label>
              <Form.Item
                name="ware_house_"
              >
                <CustomSelect
                  isAsync
                  allowClear
                  placeholder={'Bodega personal'}
                  service={getAllWareHouseToSelect}
                />
              </Form.Item>
            </Col>
          </Row>
          <label style={{ color: "#676767", fontSize: "12px" }}>
            ESPACIO - TIPO DE CUENTA: <span style={{ color: 'var(--primary)' }} > PROPIETARIA </span>
          </label>
          <Form.Item
            {...config}
            name="space_"
          >
            <CustomSelect
              isAsync
              allowClear
              service={getAllSpacesToSelect}
              placeholder={'Seleccionar espacio'}
            />
          </Form.Item>
  
          {places?.length > 1 && places?.slice(0, -1)?.map((placesData, index) => (
            <AdditionalSpaceForm
              key={index}
              index={index}
              config={config}
              placesData={placesData}
              userTypes={userTypesToRadio}
              getAllSpacesToSelect={getAllSpacesToSelect}
              getAllParkingToSelect={getAllParkingToSelect}
              getAllWareHouseToSelect={getAllWareHouseToSelect}
            />
          ))}
  
          {userSpaces.map((placesData, index) => (
            <AdditionalSpaceForm
              key={index}
              config={config}
              placesData={placesData}
              userTypes={userTypesToRadio}
              index={index + places.length}
              getAllSpacesToSelect={getAllSpacesToSelect}
              getAllParkingToSelect={getAllParkingToSelect}
              getAllWareHouseToSelect={getAllWareHouseToSelect}
            />
          ))}
  
          {userClicked?.profile?.profile_id && (
            <Row justify="center">
              <Button
                htmlType="button"
                type="text"
                shape="round"
                onClick={handleAddSpace}
                style={{ background: '#F0F0F0', padding: '4px 12px', height: 35 }}
              >
                <Row justify="center" align="middle" style={{ gap: 8 }}>
                  <AddPlusIcon color='#585D5C' />
                  <span style={{ color: '#494949' }}>Agregar otro espacio</span>
                </Row>
              </Button>
  
            </Row>
          )}
          {/* Validacion para que el documento solo se muestre si no estoy en un centro comercial  */}
          {((typeRol !== 'mall') && !(userClicked?.profile?.profile_id )) && (
            <Row justify="center">
              {/* Requerido solamente cuando es de tipo arrendador */}
              <Form.Item
                name="file"
                style={{ maxWidth: 260 }}
                rules={[{ required: typeUserSelected !== 'titular', message: "Por favor llenar el campo" }]}
              >
                <Upload
                  name="file"
                  listType="text"
                  maxCount={1}
                  customRequest={dummyRequest}
                  beforeUpload={verifyTypeContractFile}
                  accept=".doc,.docx,.pdf"
                >
                  <Button
                    type="text"
                    shape="round"
                    htmlType="button"
                    icon={<PlusCircleOutlined style={{ color: '#585D5C', fontSize: 16 }} />}
                    style={{ width: 260, height: 42, backgroundColor: '#F0F0F0' }}
                  >
                    <span style={{ fontFamily: 'Roboto', fontSize: 13, fontWeight: 400, color: '#494949' }}>
                      Agregar documento
                    </span>
                  </Button>
                </Upload>
              </Form.Item>
            </Row>
          )}
  
          <Divider />
  
          <Row justify="end">
            <Button
              type="text"
              shape="round"
              htmlType="submit"
              loading={loading}
              disabled={loading}
              className="btn-standard-green"
            >
              <span>{userClicked?.profile?.profile_id  ? "GUARDAR CAMBIOS" : "CREAR USUARIO"}</span>
            </Button>
          </Row>
        </Form>
      </CustomModal>
    )
  }
  
  export default NewEditUserModal