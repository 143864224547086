import { toast } from "react-toastify";
// import { customNotificationMessage } from "../UI/CustomNotification/CustomNotification";

export const verifyResponse = ({
  dataResponse,
  statusResponse,
  defaultIcon = 'saved', status = 'saved',
  message,
  description,
  duration,
  showToast = true,
  showErrorToast = true,
}) => {
  switch (statusResponse) {
    case 200:
      if (showToast && message) {
        toast.success(message);
        //customNotificationMessage({ defaultIcon, status, message: message ? message : 'Completado', description, duration: 3.5 })
      }
      return true;
    case 201:
      if (showToast && message) {
        toast.success(message);
        //customNotificationMessage({ defaultIcon, status, message: message ? message : 'Completado', description, duration: 3.5 })
      }
      return true;
    case 204:
      if (showToast && message) {
        toast.success(message);
        //customNotificationMessage({ defaultIcon, status, message: message ? message : 'Completado', description, duration: 3.5 })
      }
      return true;
    case 400:
      const missingElement = dataResponse.errors[0].path;
      const finalMessage = dataResponse.errors[0].msg.replace(
        "Elemento",
        missingElement.charAt(0).toUpperCase() + missingElement.slice(1)
      );
      //customNotificationMessage({ defaultIcon: 'paused', status: 'warning', message: 'ERROR', description: finalMessage, duration: 3.5 })
      toast.warn(finalMessage);
      return false
    case "ECONNABORTED":
      if (showErrorToast) {
        toast.warn("Tiempo de espera agotado. Intente de nuevo.");
        //customNotificationMessage({ defaultIcon: 'paused', status: 'warning', description: 'La solicitud al servidor tardó demasiado en responder, intenta de nuevo', message: 'TIEMPO AGOTADO', duration: 3.5 })
      }
      return false;
    case "ERR_NETWORK":
      if (showErrorToast) {
        toast.error("Error al conectarse al servidor.");
        //customNotificationMessage({ defaultIcon: 'error', status: 'error', description: 'No se puede conectar al sevidor', message: 'ERROR', duration: 3.5 })
      }
      return false;
    default:
      toast.error(
        dataResponse.errors[0].msg || "Hubo un error, intente de nuevo"
      );
      //customNotificationMessage({ defaultIcon: 'error', status: 'error', description: dataResponse.errors[0].msg || "Hubo un error, intente de nuevo", message: 'ERROR', duration: 3.5 })

      return false;
  }
};

export default verifyResponse;
