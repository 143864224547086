import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/store";
import useHierarchyAdministration from "./useHierarchyAdministration";
import {deletePlacesHierarchyAdminThunk, putPlacesHierarchyAdminThunk, createPlacesHierarchyAdminThunk}  from "../../../../store/hierarchyAdmin/thunk"

const useFormLogic = () => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)

  const { currentRol, idToken } = useAppSelector(state => state.auth)
  const { currentParentId} = useAppSelector((state) => state?.hierarchyAdminSpace)
  const { getDetailHierarchyAdmin } = useHierarchyAdministration()
  
    // Funcion para eliminar un lugar
    const handleDelete = async (placeId, onClose) => {
        setLoading(true);
        await dispatch( await deletePlacesHierarchyAdminThunk(idToken, placeId));
        getDetailHierarchyAdmin()
        onClose();
        setLoading(false);
    };

    const onSaveFormData = (data, placeId) =>{
        const formData = placeId ? {
            place_id: placeId,
            parent_id: parseInt(data?.parent_Id) ? data?.parent_Id : currentParentId,
            place_type_id: data?.type,
            name: data?.name
        }:{
            company_id: currentRol,
            parent_id: currentParentId ? currentParentId : null,
            place_type_id:data?.type,
            name: data?.name
        }

        return formData
    }

    const onSave = async (data, placeId) => {
        setLoading(true);
        const formData = await onSaveFormData(data, placeId)

        const response = placeId ? await dispatch( await putPlacesHierarchyAdminThunk( idToken, formData ) ) : await dispatch( await createPlacesHierarchyAdminThunk(idToken, formData));  
        setLoading(false)

        return response
    }

    return {
      onSave,
      loading,
      handleDelete,
    }
}

export default useFormLogic;
