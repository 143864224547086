import useChat from "./hooks/useChat"
import { createSocket } from "./socket"
import { useSelector } from "react-redux"
import { useContext, useEffect, useState, useRef } from "react"
import { ArrowLeftOutlined, FileOutlined } from "@ant-design/icons"
import AttachmentsModal from "./hooks/Modals/AttachmentsModal"
import { CountDownContext } from "../../../context/CountDownContext"
import { groupDataByDate } from "../../helpers/customDateHelpers"
import { MainContainer, Avatar, ChatContainer, ConversationHeader, Message, MessageInput, MessageList, MessageSeparator } from "@chatscope/chat-ui-kit-react"
import { Typography, Image, Row, Col, Tooltip } from "antd"
import { Fragment } from "react"
import { ReactComponent as ChatIcon } from '../../../assets/icons/chat-message-variant-1.svg'
import { ReactComponent as ImageIcon } from '../../../assets/icons/image-variant-1.svg'
import { ReactComponent as EmptyFilesIcon } from '../../../assets/emptyIcons/emptyFilesIcon.svg'
import waitIcon from '../../../assets/icons/chat-message-wait-variant-1.png'
import unSeenIcon from '../../../assets/icons/chat-message-unseen-variant-1.png'
import seenIcon from '../../../assets/icons/chat-message-seen-variant-1.png'
import moment from "moment"
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css"
import './styles.css'
import EmptyComponent from "../EmptyComponent"
import AvatarUser from '../../../assets/icons_png/avatar_user.png'
import { useAppDispatch, useAppSelector } from "../../../hooks/store"
import { setCleanCurrentDataSelected } from "../../../store/home/homeSlice"

const { Text } = Typography

const CustomChatComponent = ({
  className = 'standard-chat',
  urlSocket,
  isArrow = true, // Esta prop es para mostrar o no el go back del chat
  setCurrentBody,
  height = 600,
  maxWidth = 600,
}) => {
  const {
    loading,
    loadingMore,
    messages,
    currentConversationId,
    onSaveFile,
    setMessages,
    setLoadingMore,
    metaDataResponse,
    conversationByTicketId,
    getAllConversationMessages,
  } = useChat()
  const dispatch = useAppDispatch()
  const { profileId } = useSelector(state => state.auth)
  const { handleIsOpenCloseModal } = useContext(CountDownContext)
  const [message, setMessage] = useState('')

  //Prepare data
  const [skip, setSkip] = useState(0);
  const [socketRef, setSocketRef] = useState({});
  const [isOpenAttachmentModal, setIsOpenAttachmentModal] = useState(false);
  const [chatType, setChatType] = useState('messages');
  const loadingMoreRef = useRef(false)
  const localMessagesAdd = useRef(0)
  const { currentDataChatSelected } = useAppSelector(state => state.home)

  const handleOpenCloseAttachmentModal = () =>
    setIsOpenAttachmentModal((state) => !state);

  const chatMessages = groupDataByDate({ data: messages })

  const fullNames = conversationByTicketId
    ? `${conversationByTicketId.participants[0].name.split(' ')[0]} ${conversationByTicketId.participants[0].lastname.split(' ')[0]}`
    : `${currentDataChatSelected?.senderProfile?.PROFILE_DETAIL?.firstName} ${currentDataChatSelected?.senderProfile?.PROFILE_DETAIL?.lastName}`;

  // Socket connection
  useEffect(() => {
    const socket = createSocket(profileId, urlSocket)
    socket.connect()

    if (socket.connect) setSocketRef(socket);

    // socket.on('conversation:list', async(conversations) => {
    //   //El evento igual puede servir para saber cuando llamar al endpoint no? Ya que el conversation:seen regresa a este evento
    //   if(!currentConversationId.current) return
    //   const response = await getLastMessageConversation(0, 1, currentConversationId.current);
    //   setMessages((prevMessages) => {
    //     const previousMessages = [...prevMessages]
    //     const index = previousMessages.findIndex((message) => message.id === response[0].id)
    //     if(index === -1) return prevMessages
    //     previousMessages[index] = {
    //       ...previousMessages[index],
    //       seen: response[0].seen,
    //       seenAt: response[0].seenAt
    //     }      
    //     return previousMessages
    //   })
    // });

    socket.on('conversation:message', async ({ conversationId, message }) => {
      //Se está comprobando que si el id es igual al id de la conversación, no del mensaje...
      if ((conversationId === currentConversationId.current) && (Object.keys(message).length)) {
        if (message.profileId === profileId) {
          return setMessages((prevMessages) => {

            const previousMessages = [...prevMessages]
            const tempMessageIndex = previousMessages.findIndex((prevMessage) => prevMessage.id === 'temp-message')
            if (tempMessageIndex !== -1 && previousMessages[tempMessageIndex]?.attachments?.length && previousMessages[tempMessageIndex]?.attachments[0]?.type === 'image') {
              previousMessages[tempMessageIndex] = {
                ...message,
                attachments: [{
                  ...message.attachments[0],
                  path: previousMessages[tempMessageIndex].attachments[0].path
                }]
              }
            }
            else {
              previousMessages[tempMessageIndex] = message
            }
            return previousMessages
          })
        }
        localMessagesAdd.current++;
        setMessages(previousMessages => [...previousMessages, message])
        socket.emit("conversation:seen", conversationId.current);
      }
    })

    return () => {
      socket.off("conversation:message")
      socket.disconnect()
    };
  }, []);

  // Chat pagination
  const onYReachStart = async () => {
    if (!skip) return setSkip(25)
    if (!metaDataResponse.rest) return
    if (loading || loadingMoreRef.current) {
      return
    }
    setLoadingMore(true)
    loadingMoreRef.current = true
    const response = await getAllConversationMessages(
      skip + localMessagesAdd.current,
      25,
      conversationByTicketId ? conversationByTicketId.id : currentDataChatSelected.id
    );
    setLoadingMore(false)
    loadingMoreRef.current = false
    if (response.success) {
      setSkip((state) => state + 25);
      return;
    }
  };

  const handleOnSubmitMessage = ({ message, file }) => {
    const tempMessage = {
      id: 'temp-message',
      content: message ?? null,
      status: 'sending',
      profileId: profileId,
      conversationByTicketId: conversationByTicketId ? conversationByTicketId.id : currentDataChatSelected.id,
      attachments: file ? [{ id: 'temp-file', name: file.name, path: file.url, type: file.type.startsWith('image') ? 'image' : file.type }] : []
    }
    localMessagesAdd.current++;
    setMessages((prev) => [...prev, tempMessage])
    setMessage("")
    
    if (!message) return
    
    socketRef.emit('conversation:message', {
      conversationId: conversationByTicketId ? conversationByTicketId.id : currentDataChatSelected.id,
      message: (message.replaceAll('&nbsp; ', '')).replaceAll('&nbsp;', ''),
    })
  }

  const handleAttachmentModal = () => {
    handleOpenCloseAttachmentModal();
    handleIsOpenCloseModal();
  };

  const htmlToPlainTextWithBR = (html) => {
    // Eliminar todas las etiquetas excepto <br>
    // La lógica aquí es reemplazar cualquier etiqueta que no sea <br> por su contenido de texto.
    const cleanedHtml = html.replace(/<(?!br\s*\/?)[^>]+>/gi, '');
  
    return cleanedHtml;
  }

  const imagesByProfileId = {}

  Object.keys(chatMessages).forEach((date) => {
    chatMessages[date].forEach((message) => {
      if (message.attachments.length) {
        message.attachments.forEach(attachment => {
          if (attachment.type === 'image') {
            if (!imagesByProfileId[message.profileId]) {
              imagesByProfileId[message.profileId] = []
            }
            imagesByProfileId[message.profileId].push(attachment)
          }
        })
      }
    })
  })

  const imagesChat = ({ imagesByProfileId, currentProfileId, isOwnImages }) => {
    const profileIds = Object.keys(imagesByProfileId)

    if (!profileIds.length) return { elements: <EmptyComponent Icon={<EmptyFilesIcon />} height="120px" label={'No hay fotografías adjuntas'} />, isEmpty: true }

    const filteredProfilesIds = profileIds.filter(profId => isOwnImages ? profId === currentProfileId : profId !== currentProfileId)

    if (!filteredProfilesIds.length) return { elements: <EmptyComponent Icon={<EmptyFilesIcon />} height="120px" label={'No hay fotografías adjuntas'} />, isEmpty: true }

    const elements = <Image.PreviewGroup>
      {
        filteredProfilesIds.flatMap((profId) => imagesByProfileId[profId].map((image) => (
          <Col key={image.id} span={12} lg={8} xl={6}>
            <Image preview={{ width: '90%' }} wrapperStyle={{ width: '100%', borderRadius: 8 }} style={{ height: '100px', objectFit: 'cover', borderRadius: 8 }} src={image.path} />
          </Col>
        )))
      }
    </Image.PreviewGroup>

    return { elements, isEmpty: false }
  }

  return (
    <div
      style={{
        position: "relative",
        height,
        overflowY: "auto",
        marginTop: 30,
        maxWidth
      }}
    >
      <MainContainer className="detail-ticket-chat" style={{ borderRadius: 10 }}>
        <ChatContainer>
          <ConversationHeader className="detail-ticket-chat__header">
            <Avatar
              style={{ minWidth: 32, minHeight: 32, height: 32, width: 32 }}
              src={currentDataChatSelected?.senderProfile?.PROFILE_DETAIL?.image || AvatarUser}
              name={fullNames ?? 'Usuario'}
            />
            <ConversationHeader.Content>
              <Row align="middle" justify="space-between">
                <Row>
                  {(!(conversationByTicketId) && (isArrow)) && (<Tooltip title="Lista de mensajes">
                    <div style={{ cursor: 'pointer', paddingRight: 10 }} onClick={() => {
                      dispatch(setCleanCurrentDataSelected({}))
                      setCurrentBody('chatList')
                      }}
                    >
                      <ArrowLeftOutlined style={{ color: "var(--primary)" }} size={32} />
                    </div>
                  </Tooltip>)}
                  <Text ellipsis>
                    {fullNames}
                  </Text>
                </Row>
                <Row className="detail-ticket-chat__options">
                  {/* Se comento porque hubo un cambio en el disennio */}
                  {/* <div>
                    3-09
                    <div className="detail-ticket-chat__badge">
                      <Text ellipsis>1 DÍA</Text>
                    </div>
                  </div> */}
                  <div onClick={() => setChatType('messages')}>
                    <ChatIcon />
                  </div>
                  <div onClick={() => setChatType('chat-images')}>
                    <ImageIcon />
                  </div>
                </Row>
              </Row>
            </ConversationHeader.Content>
          </ConversationHeader>
          <MessageList
            loading={loading}
            loadingMore={loadingMore}
            onYReachStart={onYReachStart}
            autoScrollToBottomOnMount
            scrollBehavior="smooth"
          >
            <MessageList.Content>
              {chatType === 'chat-images' && (
                <div className="detail-ticket-chat__images-container">
                  Fotos Recibidas
                  <Row gutter={16} justify={imagesChat({ imagesByProfileId, currentProfileId: profileId, isOwnImages: false }).isEmpty ? "center" : "start"}>
                    {
                      imagesChat({ imagesByProfileId, currentProfileId: profileId, isOwnImages: false }).elements
                    }
                  </Row>
                  Mis Fotografías
                  <Row align="middle" justify={imagesChat({ imagesByProfileId, currentProfileId: profileId, isOwnImages: true }).isEmpty ? "center" : "start"} gutter={[16, 16]}>
                    {
                      imagesChat({ imagesByProfileId, currentProfileId: profileId, isOwnImages: true }).elements
                    }
                  </Row>
                </div>
              )}
              {chatType === 'messages' && Object.keys(chatMessages).map((date) => (
                <Fragment key={date}>
                  <MessageSeparator>
                    {moment(date, 'YYYY-MM-DD').format('dddd DD  [de] MMMM [de] YYYY')}
                  </MessageSeparator>
                  {
                    chatMessages[date].map((message) => {
                      return (
                        <Message
                          key={message?.id}
                          model={{
                            message: message?.content ? message.content : "Sin mensaje",
                            sender: message?.profileId ? message.content : "Sin mensaje",
                            direction: message?.profileId === profileId ? "outgoing" : "incoming",
                            position: "single",
                            type: 'custom',

                          }}
                          avatarPosition={message?.profileId === profileId ? "br" : "bl"}
                        >
                          <Avatar
                            src={AvatarUser}
                            name={message?.profileId}
                          />
                          <Message.CustomContent>
                            {
                              (message.attachments.length) ? message.attachments.map((file) => {

                                if (file.type === 'image') return <Image key={file.id} style={{ borderRadius: '19px', maxHeight: 250, maxWidth: 300 }} src={file.path} />
                                return <a
                                  key={file.id}
                                  href={file.path}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <FileOutlined
                                    size="18px"
                                    style={{ marginRight: "5px" }}
                                  />
                                  {file.name}
                                </a>
                              }) : undefined
                            }
                            {(!message.attachments.length) && <Message.TextContent>{message.content}</Message.TextContent>}
                          </Message.CustomContent>
                          <Message.Footer className="detail-ticket-chat__message-footer">
                            {moment(message?.created_at).format('h:mm a')}
                            {/**Aqui definir el estado del mensaje */}
                            {message?.profileId === profileId ? (message.status === 'sending' ? <img width={20} height={20} src={waitIcon} alt="waiting" /> : message.seen ? <img width={20} height={20} src={seenIcon} alt="" /> : <img width={20} height={20} src={unSeenIcon} alt="" />) : undefined}
                          </Message.Footer>
                        </Message>
                      )
                    })
                  }
                </Fragment>
              ))
              }
            </MessageList.Content>
          </MessageList>
          <MessageInput        
            value={message}
            onChange={(html, content, text) => {setMessage(htmlToPlainTextWithBR(html))}}
            autoFocus
            placeholder="Escribe un mensaje"
            onSend={(html, content, text) => handleOnSubmitMessage({ message: text })}
            onAttachClick={handleAttachmentModal}
          />
        </ChatContainer>
      </MainContainer>

      <AttachmentsModal
        profileId={profileId}
        onSaveFile={onSaveFile}
        handleSendMessage={handleOnSubmitMessage}
        isOpenModal={isOpenAttachmentModal}
        conversationByTicketId={conversationByTicketId ? conversationByTicketId : currentDataChatSelected}
        onCancel={handleAttachmentModal}
      />
    </div >
  );
};

export default CustomChatComponent;
