// Estas tres funciones son casi que solamente para el CustomChatComponent por como se manejan las fechas

import moment from "moment"

// Devuelve el formato de fecha que le pases
export const getFormatDate = (date, options = { dateStyle: "full", timeStyle: "full", }, locale = 'en-US') => {
  const currentDate = new Date()
  const formatDate = new Intl.DateTimeFormat(locale, options).format(date)

  if (date < currentDate) return formatDate

  return null
}
// Esta funcion es para validar la fecha de cada mensaje y si son de un dia anterior devuelve true para mostrar
// el componente de MessageSeparator
export const isSeparatorMessagesByDate = (date) => {
  const currentDate = new Date()

  if (date < currentDate) return true

  return false
}

//Transforma una fecha ISO en formato string al tipo obj Date para poder realizar operaciones con ese obj
export const transformISODateToDate = (date) => new Date(date)

// Aqui ya estan las funciones dinamicas para handelear cualquier tipo de formato date
// Ejemplo de uso en creacion de pdf para el detalle de un ticket
export const formatUTCDateInTimeZone = ({
 utcDate = new Date(),
 targetTimeZone = 'America/El_Salvador',
 timeFormat = '',
 customOptions = {
  timeZone: targetTimeZone,
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
 },
}) => {
  const formattedDate = new Intl.DateTimeFormat('es-ES', customOptions).format(utcDate);

  // Si hay un formato para el tiempo aqui se agrega y no viene en el objeto date
  if (timeFormat) {
    const timePart = utcDate.toLocaleTimeString('es-ES', {
      timeZone: targetTimeZone,
      format: timeFormat,
    });
    return `${formattedDate} ${timePart}`;
  }

  return formattedDate;
}

export const groupDataByDate = ({ data = [], dateProp = "created_at", isAscending = true }) => {
  // Objeto para mantener los mensajes agrupados por fecha
  const newData = {};

  //Ordenar los items, por fecha
  const sortedData = data.sort((a, b) => {
    const dateA = moment(a[dateProp]);
    const dateB = moment(b[dateProp]);
    if (isAscending) return dateA.diff(dateB);
    return dateB.diff(dateA)
  })

  sortedData.forEach(mensaje => {
    const fecha = moment(mensaje[dateProp]).format('YYYY-MM-DD');
    if (!newData[fecha]) {
      newData[fecha] = [];
    }
    newData[fecha].push(mensaje);
  });

  return newData;
};
