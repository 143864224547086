import CustomModal from "../../../../../../../UI/CustomModal"
import { Avatar, Col, List } from "antd"
import '../../../styles.css'

const UsersAnswerChartModal = ({ answer, isOpenModal, setIsOpenModal }) => {

    if (!answer) return
    return (
        <CustomModal width={650} className="more-commentary-modal" isOpenModal={isOpenModal} onCancel={() => setIsOpenModal(false)}>
            <Col className="survey-answers">
                <h2 className="survey-answer-question-header">PREGUNTA #{answer.order + 1}</h2>
                <h1>{answer.question}</h1>
                <h2>R/{answer.type === 'qualify' ? `${parseInt(answer.label).toFixed(0)} ESTRELLA${answer.label === '1.0' ? '' : 'S'}` : answer.label}</h2>
                <List
                    dataSource={answer.profiles}
                    itemLayout="horizontal"
                    renderItem={(item, index) => {
                        return (<List.Item key={index}>
                            <List.Item.Meta
                                avatar={<Avatar src={item.image} size={'large'} />}
                                title={`${item.name} ${item.lastname}`}
                                description={<div>
                                    Teléfono: {item.phone}
                                </div>} />
                        </List.Item>)
                    }}
                />
            </Col>
        </CustomModal>
    )
}

export default UsersAnswerChartModal