import spinIntegroLogo from '../../../assets/logos/integroLogo.svg'
import leftBackground from '../components/DefaultTemplate/assets/leftBackground.svg'

//Definir por defecto un objeto por si no viene data en el parámetro de data
const defaultData = {
  logo: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/assets/integro/f192e0a1-d71b-4a18-9261-f7e035963c70.png',
  firstImage: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/assets/integro/ae677c6e-f083-4158-b9a9-4bc23ea6c722.jpg',
  secondImage: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/assets/integro/cccb0e71-a82d-43bf-8e39-9a294ab65ea5.jpg',
  thirdImage: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/assets/integro/8ac6ea6d-d0b8-4790-9f83-9b59d039b092.jpg',
  infoSectionBackground: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/assets/integro/b14aac71-a6ef-4fc3-8774-9b3154dd3b23.png',
  bottomLogo: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/assets/integro/151e4bdd-dbfa-4f2d-8a04-34000e27dcb6.png',
  leftBackground
}
//Template con el cual se podría tomar para reactualizar los datos del mismo con base a un id que lo identifique...
export const defaultTemplate = (data) => {

  //Parsear la data para utilizarla
  const imagesTemplates = {
    leftBackground: data?.find(image => image?.code === '7')?.image || defaultData.leftBackground,
    logo: data?.find(image => image?.code === '1')?.image || defaultData.logo,
    firstImage: data?.find(image => image?.code === '2')?.image || defaultData.firstImage,
    secondImage: data?.find(image => image?.code === '3')?.image || defaultData.secondImage,
    thirdImage: data?.find(image => image?.code === '4')?.image || defaultData.thirdImage,
    infoSectionBackground: data?.find(image => image?.code === '5')?.image || defaultData.infoSectionBackground,
    bottomLogo: data?.find(image => image?.code === '6')?.image || defaultData.bottomLogo,
  }

  return {
    templateName: "DefaultTemplate",
    templateData: {
      spinData: {
        spinLogo: spinIntegroLogo,
        spinColor: '#252525'
      },
      imageSectionData: {
        logo: imagesTemplates.logo,
        mainBackground: {
          style: {
            background:
              `url(${imagesTemplates.leftBackground})`,
          },
        },
        firstImage: {
          style: {
            background: `url(${imagesTemplates.firstImage})`,
          },
        },
        secondImage: {
          style: {
            background: `url(${imagesTemplates.secondImage})`,
          },
        },
        thirdImage: {
          style: {
            background: `url(${imagesTemplates.thirdImage})`,
          },
        },
      },
      infosSectionData: {
        infoBackground: {
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `url(${imagesTemplates.infoSectionBackground})`,
        },
        initData: {
          h1: {
            text: "Panel Administrativo",
            style: {
              fontWeight: 700,
              fontSize: 30,
              lineHeight: "36.57px",
              textAlign: "center",
            },
          },
          p: {
            text: "Administración de Centros Comerciales",
            style: {
              fontSize: 16,
              fontFamily: "Poppins, sans-serif",
              color: "#717171",
              fontWeight: 400,
              textAlign: 'center',
            },
          },
        },
        loginForm: {
          input: {
            shared: {
              label: {
                style: {
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#5B5B5B",
                  fontFamily: "Poppins, sans-serif",
                },
              },
              input: {
                style: {
                  border: "1px solid #e2e2e2",
                  backgroundColor: "#fbfbfb",
                },
              },
            },
            email: {
              label: {
                text: "Email",
              },
              input: {
                placeholder: "Correo Institucional",
                prefix: ""
              },
            },
            password: {
              label: {
                text: "Contraseña",
              },
              input: {
                placeholder: "Contraseña asignada",
                prefix: "",
              },
            },
          },
          button: {
            text: "INGRESAR",
            style: {
              color: "#FFF",
              fontFamily: "Poppins, sans-serif",
              fontSize: 14,
              fontWeight: 500,
              textAlign: "center",
              backgroundColor: "var(--primary)",
            },
          },
        },
        bottomInfo: {
          logo: imagesTemplates.bottomLogo,
        }
      },
    },
  }
}