import { useContext } from "react"
import CostSettings from "./CostSettings"
import { Col, Form, Radio, Row, Space } from "antd"
import CustomSwitch from "../../../../../../UI/CustomSwitch"
import { CustomInput } from "../../../../../../UI/CustomInput"
import { useAppSelector } from "../../../../../../../hooks/store"
import { AmenitiesContext } from "../../../../context/AmenitiesContext"

const CostsSettingsData = () => {
  const { Item } = Form
  const {
    costs,
    detailAmenity,
    currentValueRadio,
    setCurrentValueRadio,
    minimunValueTimeReserve,
    valueCostTime,
    valueStandardCost,
    valueInviteesCost,
    setValueCostTime,
    setValueStandardCost,
    setValueInviteesCost
  } = useContext(AmenitiesContext)

  const { currencySymbol } = useAppSelector(state => state.auth)

  // Funciones que cambian los estados de los switch
  const handleOnChange = (event) => setCurrentValueRadio(event.target.value)
  const handleOnChangeStandardCost = () => setValueStandardCost(state => !state)
  const handleOnChangeCostTime = () => setValueCostTime(state => !state)

  const handleOnChangeInviteesCost = () => {
    setCurrentValueRadio(1)
    setValueInviteesCost(state => !state)
  }

  return (
    <section style={{ backgroundColor: '#FFFFFF', borderRadius: 16, padding: '50px 25px', marginTop: 25 }}>
      <Row>
        <Col span={24}>
          <h2 style={{ fontWeight: 600, fontSize: 20 }}>
            CONFIGURACIÓN DE COSTES
          </h2>

          <Row gutter={16}>
            <Col span={12}>
              <Row justify="space-between">
                <label style={{ fontWeight: 600, fontSize: 14 }}>
                  Definir costo estándar por amenidad
                </label>

                <Item name="default_cost_enabled" valuePropName="checked">
                  <CustomSwitch
                    checked={valueStandardCost}
                    onChange={handleOnChangeStandardCost}
                  />
                </Item>
              </Row>

              <label style={{ fontWeight: 400, fontSize: 12, color: '#404040', opacity: valueStandardCost ? 1 : 0.5 }}>
                COSTE POR AMENIDAD
              </label>

              <Item name="default_cost">
                <CustomInput
                  type="NUMBER"
                  prefix={currencySymbol}
                  min={1}
                  maxLength={8}
                  disabled={!valueStandardCost}
                  className="custom-input-amenity"
                  precision={2}
                  step={0.01}
                  nullInputNumberText={null}
                  placeholder="Escribe el costo de la amenidad"
                />
              </Item>
              <Row justify="space-between">
                <label style={{ fontWeight: 600, fontSize: 14 }}>
                  Definir costo por tiempo reservado
                </label>

                <Item name="duration_cost_enabled" valuePropName="checked">
                  <CustomSwitch checked={valueCostTime} onChange={handleOnChangeCostTime} />
                </Item>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <label style={{ fontWeight: 400, fontSize: 12, color: '#404040', opacity: valueCostTime ? 1 : 0.5 }}>
                    TIEMPO
                  </label>

                  <CustomInput
                    disabled
                    value={minimunValueTimeReserve.toUpperCase() || `${(detailAmenity?.AMENITY_SETTING?.DURATION?.reservation_minutes) ?? '0'} MIN`}
                    className="custom-input-amenity"
                    placeholder='Escribe el tiempo minimo de una reserva'
                  />
                </Col>

                <Col span={12}>
                  <label style={{ fontWeight: 400, fontSize: 12, color: '#404040', opacity: valueCostTime ? 1 : 0.5 }}>
                    COSTE
                  </label>
                  <Item name="duration_cost">
                    <CustomInput
                      type="NUMBER"                      
                      prefix={currencySymbol}
                      nullInputNumberText={null}
                      min={1}
                      maxLength={8}
                      precision={2}
                      step={0.01}
                      disabled={!valueCostTime}
                      className="custom-input-amenity"
                      placeholder="Define el coste"
                    />
                  </Item>
                </Col>
              </Row>

              <Row justify="space-between">
                <label style={{ fontWeight: 600, fontSize: 14 }}>
                  Definir costo por invitados
                </label>

                <Item name="cost_invitee_enabled" valuePropName="checked">
                  <CustomSwitch checked={valueInviteesCost} onChange={handleOnChangeInviteesCost} />
                </Item>
              </Row>

              <Row>
                <Col span={24}>
                  <Radio.Group disabled={!valueInviteesCost} onChange={handleOnChange} value={currentValueRadio} style={{ width: '100%' }}>
                    <Space direction="vertical">
                      <Radio value={1}>
                        <Row>
                          <Col span={24}>
                            <label style={{ fontWeight: 400, fontSize: 14, opacity: currentValueRadio === 1 ? 1 : 0.5 }}>
                              Definir costo individual por invitados
                            </label>

                            <Row>
                              <Col span={24}>
                                <label style={{ fontWeight: 400, fontSize: 12, color: '#404040', opacity: ((!valueInviteesCost) || (currentValueRadio !== 1) ? 0.5 : 1) }}>
                                  COSTE POR INVITADOS
                                </label>

                                <Item name="cost_invitee_individual">
                                  <CustomInput
                                    type="NUMBER"
                                    prefix={currencySymbol}
                                    min={1}
                                    // max={1000}
                                    maxLength={8}
                                    nullInputNumberText={null}
                                    precision={2}
                                    step={0.01}
                                    disabled={(!(valueInviteesCost) || (currentValueRadio !== 1))}
                                    className="custom-input-amenity"
                                    placeholder="Define el coste"
                                  />
                                </Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Radio>

                      <Radio value={2}>
                        <Row>
                          <Col span={24}>
                            <label style={{ fontWeight: 400, fontSize: 14, opacity: (((!(valueInviteesCost)) || (currentValueRadio !== 1)) ? 1 : 0.5) }}>
                              Definir costo por rango de invitados
                            </label>
                          </Col>
                        </Row>
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <label style={{ fontWeight: 600, fontSize: 14 }}>
                Definir tus rangos
              </label>

              <Row justify="center" align="middle" style={{ overflow: 'auto', maxHeight: 555 }}>
                <Col span={24}>
                  {costs?.map((cost) => <CostSettings key={cost.id} costData={cost} />)}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  )
}

export default CostsSettingsData