import { Button, Upload, message } from 'antd'
import React from 'react'
import { UploadOutlined } from '@ant-design/icons'

const UploadImg = ({ setImg, title = 'Agregar fotografía', maxWidth }) => {
  const getBase64 = (img, callback) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const beforeUpload = (file) => {
    const isIMG = file.type === 'image/png' || file.type === 'image/jpeg'
    if (!isIMG) {
      message.error('Solo puedes subir un archivo JPG/PNG!')
    }
    const isLt2M = file.size / 1024 / 1024 < 10
    if (!isLt2M) {
      message.error('El archivo debe pesar menos de 10MB!')
    }
    return isIMG && isLt2M
  }
  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }
  const handleChange = ({ file }) => {


    if (file.status === "done") {
      getBase64(file.originFileObj, (url) => {
        setImg({ url, file: file.originFileObj });
      });
    }
  }
  return (
    <Upload
      style={{ maxWidth }}
      beforeUpload={beforeUpload}
      customRequest={dummyRequest}
      showUploadList={false}
      onChange={handleChange}
    >
      <Button icon={<UploadOutlined />}>{title}</Button>
    </Upload>
  )
}

export default UploadImg