import { Button, Col, Row } from "antd"
import CustomModal from "../../../../../UI/CustomModal"

const UploadExcelErrorsModal = ({ response, isOpenModal, handleOpenCloseModal }) => {
  return (
    <CustomModal width={650} isOpenModal={isOpenModal} onCancel={handleOpenCloseModal}>
      <Row>
        <Col span={24}>
          <Row justify="center" align="middle">
            <img src="/assets/icons/exclamationMark.svg" alt="exclamation-mark" />
          </Row>
          
          <h3 style={{ textAlign: "center", fontWeight: 700, fontSize: "20px", color: "var(--primary)" }}>FORMATO INCOMPATIBLE</h3>

          <p style={{ textAlign: "center", fontWeight: 500, fontSize: "16px" }}>La plantilla que esta intentando subir posee un formato diferente:</p>

          <Row justify="center" align="middle">
            <ol style={{ overflow: "auto", maxHeight: "20vh", marginBottom: "20px"}}>
              {response?.errors?.map((error, index) => (
                <li style={{ fontWeight: 500, fontSize: "16px" }} key={index}><span style={{ fontWeight: 500, fontSize: "16px" }}>{error?.msg}</span></li>
              ))}
            </ol>
          </Row>

          <Row justify="center" align="middle">
            <Button
              htmlType="button"
              onClick={handleOpenCloseModal}
              style={{ borderColor: "var(--primary)", background: "var(--primary)", width: "250px", margin: "20px 0" }}
              shape="round"
            >
              <span style={{ color: "#FFFFFF" }}>ACEPTAR</span>
            </Button>
          </Row>
        </Col>
      </Row>
    </CustomModal>
  )
}

export default UploadExcelErrorsModal
