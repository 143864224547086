import axios from "axios";
import { API_URL } from "../../../../../../../config";

const tasksPath = "tasks/history"

export const getComment = async ({
    token,
    company_id = 1,
    formData,
    limit = 7,
    skip = 0,
}) => {
    try {
        const params = {
            limit,
            skip,
            ...formData
        };
        
        const response = await axios.get(`${API_URL}${tasksPath}`, {
            params, timeout: 10000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return {
            success: true,
            ...response,
        };
    } catch (error) {        
        if (error.response) {
            return {
                success: false,
                ...error.response,
            };
        }
        if (error.request) {
            return {
                success: false,
                status: error.code,
                data: {
                    errors: [{ msg: error.message }],
                },
            };
        }
    }
};