import { useState } from "react"
import { Button, Row } from "antd"
import { checkObjects } from "../../helpers/checkObjects"
import { useFeatureRole } from "../../../../hooks/useFeatureRole"
import CustomPopoverConfirm from "../../../../UI/CustomPopoverConfirm"
import { getColumnsFilter } from '../../../../helpers/customTableHelper'
import { useHistoryMaintenanceServices } from "./useHistoryMaintenanceServices"
import { historyMaintenanceTableFilters } from "../../helpers/tableFiltersHelper"
import { useActivesMaintenanceContext } from "../../context/ActiveMaintenanceContext"
import { ACTIVE_MAINTENANCE_CODES, ACTIVE_MAINTENANCE_CODES_NAMES } from "../../../../../constants/modules/activeMaintenance/activeMaintenanceCodes"

import EditIcon from "../../../../../assets/icons/EditIcon"
import { ReactComponent as SeeReportIcon } from '../../assets/seeReportIcon.svg'

export const useHistoryMaintenance = () => {
    const {
      param, dataTableActiveMaintenance, dataTableHistory, limitTableHistory, quantityTableHistory,
      currentHistoryParams, currentPageTableHistory, columnsTableHistory, setColumnsTableHistory,
      handleRedirectTo, setCurrentHistoryParams, setCurrentPageTableHistory, setLastSkipHistory,
      setMetaDataTableHistory, setDataTableHistory,
    } = useActivesMaintenanceContext()

    const { getAllMaintenanceAssetService } = useHistoryMaintenanceServices()

    const [loading, setLoading] = useState(true)

    const { loadingFeatures, activeFeatures, getModuleFeatures, emptyFeatures } = useFeatureRole({ feature_codes: ACTIVE_MAINTENANCE_CODES, validate_code: ACTIVE_MAINTENANCE_CODES_NAMES.ACTIVE_MAINTENANCE_HISTORY_MAINTENANCE, returnUrl: '/menu/settings' })
    
    const getInitialData = async ({ company_asset_id }) => {
        setCurrentPageTableHistory(1)
        
        const { data, metadata } = await getAllMaintenanceAssetService({ company_asset_id, skip: 0, limit: 10, previousData: dataTableHistory, currentPage: currentPageTableHistory, setLoading, ...currentHistoryParams })
        
        setColumnsTableHistory(data.columns || [])
        setDataTableHistory(data.dataSource);
        setLastSkipHistory(0)
        setMetaDataTableHistory(metadata)
        setLoading(false)
    }

    const cleanData = () => {
        setDataTableHistory([])
        setLastSkipHistory(0)
        setMetaDataTableHistory({})
        setCurrentHistoryParams({})
        setCurrentPageTableHistory(1)
    }

    const columnsHistory = columnsTableHistory?.map((column) => {
      const dateColumn = column?.key === 'date'
        ? {
            title: 'FECHA',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (<span>{record?.date ? record.date : '---'}</span>)
        } : {}

      const isReport = column?.key === 'reports'
        ? {
            title: 'REPORTES',
            dataIndex: 'reports',
            key: 'reports',
            width: '10%',
            align: 'center',
            render: (text, record) => (
                <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_DETAIL_REPORT)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Ver reporte'}`}>
                    <Button
                      disabled={!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_DETAIL_REPORT)?.enabled}
                      style={{ maxWidth: 190 }}
                      onClick={() => { handleRedirectTo(`report/${record.company_asset_maintenance_id}`) }}
                      shape="round" className="btn-standard-white"
                      icon={<SeeReportIcon className="btn-svg-icon-white-variant2" />}
                    >
                        VER REPORTE
                    </Button>
                </CustomPopoverConfirm>
            )
        } : {}
      
      const editColumn = column?.key === 'actions'
        ? {
            ...column,
            title: '',
            width: '5%',
            render: (text, record) => (
              <Button
                type="link"
                htmlType="button"
                onClick={() => { handleRedirectTo(`edit-report/${record.company_asset_maintenance_id}`) }}
                shape="circle"
                style={{ border: '1px solid var(--primary)' }}
              >
                <Row justify="center" align="middle">
                  <EditIcon color="var(--primary)" />
                </Row>
              </Button>
            )
        } : {}

        return ({
        ...column,
        ...dateColumn,
        ...isReport,
        ...editColumn,
        ...getColumnsFilter(column.dataIndex, historyMaintenanceTableFilters({ currentParams: currentHistoryParams }))
    })})

    const handleChangeTable = async ({ pagination, filters, sorter }) => {
        setLoading(true)
        const sort_order = sorter.order === 'descend' ? "desc" : sorter.order === 'ascend' ? "asc" : undefined
        const sort_by = sorter.column?.key

        const pageValue = pagination.current || currentPageTableHistory
        const newSkip = (pageValue * limitTableHistory) - limitTableHistory

        const actualParams = { ...currentHistoryParams }
        const newParams = {
            sort_by,
            sort_order,
            skip: newSkip
        }

        let same = null
        let dataTable = {}

        if (checkObjects({ actualParams, newParams, difKey: 'skip' })) {
            setCurrentPageTableHistory(pageValue)
            same = newSkip
        }
        if (same) {
            dataTable = await getAllMaintenanceAssetService({ company_asset_id: param.historyId, limit: limitTableHistory, previousData: dataTableHistory, currentPage: currentPageTableHistory, ...newParams, skip: same })
        }
        if (!same) {
            same = 0
            dataTable = await getAllMaintenanceAssetService({ company_asset_id: param.historyId, limit: limitTableHistory, previousData: dataTableHistory, currentPage: currentPageTableHistory, ...newParams, skip: same })
            setCurrentPageTableHistory(1)
        }
        if (dataTable?.data) {
            setDataTableHistory(dataTable.data.dataSource)
            setMetaDataTableHistory(dataTable.metadata)
            setCurrentHistoryParams(newParams)
            setLastSkipHistory(same)
        }
        setLoading(false)
    }

    return {
        getInitialData,
        handleRedirectTo,
        handleChangeTable,
        cleanData,
        getModuleFeatures,
        emptyFeatures,
        loading,
        quantityTableHistory,
        limitTableHistory,
        currentPageTableHistory,
        dataTableActiveMaintenance,
        dataTableHistory,
        columnsHistory,
        loadingFeatures,
        activeFeatures,
        param
    }
}