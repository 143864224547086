import { Form, TreeSelect } from "antd"

const CustomTreeSelect = ({
  name,
  style,
  config,
  treeData,
  isToForm = true,
  onChange,
  className,
  suffixIcon,
  allowClear,
  placeholder,
  defaultValue,
  dropdownMatchSelectWidth,
  defaultSelectedKeys = [],
  treeDefaultExpandAll,
  showSearch = false,
  filterTreeNode = () => { },
  open,
  maxWidth
}) => {
  const { Item } = Form

  let body = <TreeSelect
    showSearch={showSearch}
    filterTreeNode={filterTreeNode}
    open={open}
    style={{
      maxWidth
    }}
    allowClear={allowClear}
    treeData={treeData}
    suffixIcon={suffixIcon}
    placeholder={placeholder}
    onChange={onChange}
    className={className}
    treeDefaultExpandAll={treeDefaultExpandAll}
    defaultValue={defaultValue}
    treeDefaultExpandedKeys={defaultSelectedKeys}
    dropdownMatchSelectWidth={dropdownMatchSelectWidth}
  />

  if (isToForm) {
    return (
      <Item name={name} {...config} style={{ ...style, maxWidth }}>
        {body}
      </Item>
    )
  }

  return body
}

export default CustomTreeSelect
