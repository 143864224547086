import moment from "moment"
import { useState } from "react"
import CustomDrawer from "../../../../UI/CustomDrawer"
import { Badge, Button, Calendar, Col, Row } from "antd"
import {
  employeeActivityThunk,
  ticketOfCalenderThunk,
} from "../../../../../store/tickets/thunks"
import FilterCalender from "./FilterCalender"
import useCalendar from "../../hooks/useCalendar"
import { CustomSpin } from "../../../../UI/CustomSpin"
import esLanguage from 'antd/es/calendar/locale/es_ES'
import DetailTicketDrawer from "./drawer/DetailTicketDrawer"
import { CustomNavigate } from "../../../../UI/CustomNavigate"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store";
import { useFeatureRole } from "../../../../hooks/useFeatureRole";
import { TICKETS_CODES, TICKETS_CODES_NAME } from "../../../../../constants/modules/tickets/ticketsCodes";
// IMAGENES O ARCHIVOS
import { ReactComponent as ArrowLeft } from '../../../../../assets/icons/arrow-left-variant-1.svg'
import { ReactComponent as ArrowRight } from '../../../../../assets/icons/arrow-right-variant-1.svg'
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close-drawer-variant-1.svg'

const CalendarTickets = () => {
  moment.updateLocale('es', {
    week: {
      dow: 0,
    },
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
  })

  const customLocale = {
    ...esLanguage,
    lang: {
      ...esLanguage.lang,
      dayFormat: 'D',
      shortWeekDays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      shortMonths: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ]
    },
  }

  const [employeeId, setEmployeeId] = useState("")
  const [openDrawer, setOpenDrawer] = useState(false)
  const [typeId, setTypeId] = useState("")
  const [calenderDates, setCalenderDates] = useState({timeBegin: "", timeEnd: "" })
  
  const dispatch = useAppDispatch()
  
  const { tickets } = useCalendar()
  const { currentRol } = useAppSelector((state) => state.auth)
  const { loading, activityEmployee } = useAppSelector((state) => state.tickets)
  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: TICKETS_CODES, validate_code: TICKETS_CODES_NAME.TICKETS_CALENDAR })

  const date = activityEmployee?.data?.activity?.date.split("T")[0];
  let fecha = new Date(date);
  let diaSemana = fecha.getDay() + 1;
  let day;
  switch (diaSemana) {
    case 7:
      day = "Domingo";
      break;
    case 1:
      day = "Lunes";
      break;
    case 2:
      day = "Martes";
      break;
    case 3:
      day = "Miércoles";
      break;
    case 4:
      day = "Jueves";
      break;
    case 5:
      day = "Viernes";
      break;
    case 6:
      day = "Sábado";
      break;
    default:
      day = "Dia no válido";
  }

  const newDate = async (value) => {
    const newTimeBegin = value.startOf("month").format("YYYY-MM-DD");
    const newTimeEnd = value.endOf("month").format("YYYY-MM-DD");
    setCalenderDates({
      timeBegin: newTimeBegin,
      timeEnd: newTimeEnd,
    });
    await dispatch(
      ticketOfCalenderThunk({
        timeBegin: newTimeBegin,
        timeEnd: newTimeEnd,
        companyId: currentRol,
        employeeId: employeeId,
        typeId: typeId,
      })
    );
  };

  const getListData = (value) => {
    const date = value.format("YYYY-MM-DD");
    const listData = tickets
      ?.filter((ticket) => ticket.date === date)
      .map((ticket) => ({
        type: ticket?.type === 1 ? "success" : "warning",
        content: ticket.name,
        id: ticket?.id,
      }));

    return listData;
  };
  const ShortenTextBadge = ({ type, content }) => {
    const shortenText = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "…";
      }
      return text;
    };
    const getMaxLength = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1500) {
        return 15;
      } else {
        return 25;
      }
    };

    const shortContent = shortenText(content, getMaxLength());

    return <Badge className="ticket-calendar__badge-status" color={type === 'warning' ? "#F8C822" : "#59ADFF"} text={shortContent} />;
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <div className="events">
        {listData?.map((task, index) => (
          <div
            onClick={() => handleOnClickTaskSelected(task)}
            style={{ listStyle: "none" }}
            key={index}
          >
            <ShortenTextBadge type={task.type} content={task.content} />
          </div>
        ))}
      </div>
    );
  };

  const handleOpenCloseDrawer = () => { setOpenDrawer((state) => !state) }

  const handleOnClickTaskSelected = async (task) => {
    if (task.id) {
      await dispatch(employeeActivityThunk(task?.id))
      handleOpenCloseDrawer();
    }
  };

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (
    <div className="section-wrapper" >
      <CustomNavigate title={'CALENDARIO DE TICKETS'} />
      <div style={{ paddingBottom: 48 }}>
        <CustomSpin spinning={loading}>
          <Calendar
            locale={customLocale}
            headerRender={({ value, type, onChange, onTypeChange }) => {
              const startOfMonth = value.clone().startOf('month');

              const prevMonth = () => {
                onChange(value.clone().subtract(1, 'month'));
              };

              const nextMonth = () => {
                onChange(value.clone().add(1, 'month'));
              };

              return (
                <Row
                  className="tickets-calendar__custom-header"
                  justify="space-between"
                  style={{ padding: 8 }}
                >
                  <Row justify="space-between" align="middle" gutter={16}>
                    <Col>
                      <Button onClick={prevMonth} icon={<ArrowLeft />} />
                    </Col>
                    <Col className="ticket-calendar__header-date">
                      <span >{startOfMonth.format('MMMM YYYY')}</span>
                    </Col>
                    <Col>
                      <Button icon={<ArrowRight />} onClick={nextMonth} />
                    </Col>
                  </Row>
                  <FilterCalender
                    calenderDates={calenderDates}
                    setEmployeeId={setEmployeeId}
                    setTypeId={setTypeId}
                    employeeId={employeeId}
                    typeId={typeId}
                  />
                </Row>
              );
            }}
            className="ticket-calendar"
            dateCellRender={dateCellRender}
            onChange={newDate}
          />
        </CustomSpin>
      </div>

      <CustomDrawer
        title={
          <Row style={{ height: '43px' }}>
            <Col
              span={5}
              style={{
                background: "var(--primary)",
                height: "100%",
                color: "white",
                borderRadius: "0 0 10px 0",
              }}
            >
              <Row align="middle" justify="center" style={{ height: 43 }}>
                <p style={{ fontSize: "20px" }}>
                  {day.substring(0, 3).toUpperCase()}
                </p>
                <p
                  style={{
                    fontSize: "26px",
                    paddingLeft: "0.8rem",
                    marginTop: "5px",
                  }}
                >
                  {fecha.getDate() + 1}
                </p>
              </Row>
            </Col>
            <Col className="ticket-drawer-header__time" span={19}>
              <p
              >
                {moment(activityEmployee?.data?.activity?.time_begin, 'HH:mm:ss').format('h:mm a')}
                {" - "} {moment(activityEmployee?.data?.activity?.time_end, 'HH:mm:ss').format('h:mm a')}
              </p>
            </Col>
          </Row>
        }
        className={'ticket-drawer-details'}
        extra={<CloseIcon onClick={handleOpenCloseDrawer} />}
        isOpenDrawer={openDrawer}
        onClose={handleOpenCloseDrawer}

      >
        <DetailTicketDrawer activeFeatures={activeFeatures} />
      </CustomDrawer>
    </div >
  );
};

export default CalendarTickets;