import { Tabs } from "antd"

import "./style.css"
import CustomBadge from "../CustomBadge";

const CustomTabs = ({
  onChange,
  onClick,
  items,
  defaultActiveKey = "1",
  tabBarExtraContent,
  className = 'custom-tabs',
  maxWidth,
  disabled,
  activeTabId = 0, // Si se desea quitar el count en la tab seleccionada tiene que mandarse esta prop (debe ser un numero)
  isBadge = false, // Esta prop habilita el count de filas por cada tab
}) => {
  const { TabPane } = Tabs;

  const getCustomTabTitle = (itemData) => {
    // Se valida la tab activa con el id de cada tab para ocultar el contador (la key tiene que ser el id de esa tab)
    if (isBadge) return <CustomBadge count={activeTabId === itemData.key ? 0 : itemData?.totalCount} offset={[5, 0]}>
      <span className="tab-title">{itemData?.label?.toUpperCase()}</span>
    </CustomBadge>

    return <span className="tab-title">{itemData?.label?.toUpperCase()}</span>
  }

  return (
    <Tabs    
      tabBarExtraContent={tabBarExtraContent}
      defaultActiveKey={defaultActiveKey}
      className={className}
      onChange={onChange}
      onClick={onClick}
      style={{ maxWidth }}
    >
      {items?.map((item, index) => (
        <TabPane
          tab={getCustomTabTitle(item)}
          key={item?.key || String(index)}
          disabled={disabled}
        >
          {item?.children}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default CustomTabs;
