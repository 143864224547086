import axios from "axios";
const getTicketId = async (URL_DEV, id) => {
  try {
    const response = await axios.get(
      `${URL_DEV}/detail?ticket_id=${id}&status_road=full`
    );
    return {
      success: true,
      ...response,
    };
  } catch (errorResponse) {
    return {
      success: false,
      ...errorResponse.response,
    };
  }
};
export default getTicketId;
