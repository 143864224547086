import { Button, Col, Popconfirm, Row } from "antd"
import { useDebounce } from "../../../../hooks/useDebounce"
import { useFeatureRole } from "../../../../hooks/useFeatureRole"
import { getColumnsFilter } from '../../../../helpers/customTableHelper'
import { checkObjects, compareObjects } from "../../helpers/checkObjects"
import { useActivesMaintenanceServices } from "./useActivesMaintenanceServices"
import { useActivesMaintenanceContext } from "../../context/ActiveMaintenanceContext"
import { activeMaintenanceTableFilters, providersTableFilters } from "../../helpers/tableFiltersHelper"
import { ACTIVE_MAINTENANCE_CODES, ACTIVE_MAINTENANCE_CODES_NAMES } from "../../../../../constants/modules/activeMaintenance/activeMaintenanceCodes"
import CustomPopoverConfirm from "../../../../UI/CustomPopoverConfirm"

// imagenes o archivos
import TrashIcon from "../../../../../assets/icons/TrashIcon"
import { ReactComponent as DeleteProviderIcon } from '../../assets/deleteProviderIcon.svg'
import { ReactComponent as HistoryIcon } from '../../assets/historyIcon.svg'

//Aqui colocar las funciones que se necesiten para la tabla de activos o proveedores para simplificar el código, queda pendiente
export const useActivesMaintenanceTable = () => {
  const {
      handleRedirectTo, setLoading, setLoadingProvider, setCurrentFiltersTableProviders,
      setCurrentPageTableActiveMaintenance, setDataTableProviders, setMetaDataTableProviders,
      setCurrentActiveMaintenanceParams, setCurrentPageTableProviders,
      setCurrentProvidersParams, setDataTableActiveMaintenance, setColumnsTableActiveMaintenance,
      setLastSkipActiveMaintenance, setLastSkipProviders, setMetaDataTableActiveMaintenance,
      setCurrentActiveMaintenance, loading, loadingProvider, currentActiveMaintenanceParams,
      currentFiltersTableProviders, currentPageTableActiveMaintenance,
      currentPageTableProviders, currentProvidersParams, limitTableActiveMaintenance,
      limitTableProviders, dataTableProviders, dataTableActiveMaintenance,
      columnsTableActiveMaintenance, columnsTableProviders, setCurrentActiveTab, currentActiveTab,
      handleOpenCloseModal, setClickedActive,
  } = useActivesMaintenanceContext()

  const {
      deleteExistingProviderService, getAllCompanyAssetsService, getAllCompanyProvidersService
  } = useActivesMaintenanceServices()

  const { loadingFeatures, activeFeatures, getModuleFeatures, emptyFeatures } = useFeatureRole({ feature_codes: ACTIVE_MAINTENANCE_CODES, validate_code: ACTIVE_MAINTENANCE_CODES_NAMES.ACTIVE_MAINTENANCE_MODULE, returnUrl: '/menu/settings' })

  const commonParams = (tableName) => ({
      limit: tableName === 'providers' ? limitTableProviders : limitTableActiveMaintenance,
      prevData: tableName === 'providers' ? dataTableProviders : dataTableActiveMaintenance,
      currentPage: tableName === 'providers' ? currentPageTableProviders : currentPageTableActiveMaintenance
  })

  const debounceFilter = useDebounce((confirm) => {
      confirm(false);
  }, 300)

  const handleSearch = (confirm) => (e) => debounceFilter(confirm)

  const handleReset = (confirm, clearFilters) => {
    clearFilters();
    confirm(false);
  };

  const handleGoHistoryMaintenance = ({ record }) => {
    setCurrentActiveMaintenance(record)
    handleRedirectTo(`history/${record.company_asset_id}`)
  }

  const handleDeleteProvider = async ({ id }) => {
    setLoadingProvider(true)
    await deleteExistingProviderService({ provider_id: id, setLoading: setLoadingProvider })
    setDataTableProviders((prev) => {
        const actualData = [...prev]
        const finalData = actualData.filter((item) => item.id !== id)
        return finalData
    })
    setMetaDataTableProviders((prev) => ({
        ...prev,
        quantity: prev.quantity - 1
    }))
    setLoadingProvider(false)
  }

  const columnsActiveMaintenance = columnsTableActiveMaintenance?.map((column) => {
    const isCode = column?.key === 'code'
      ? {
          title: 'COD',
          dataIndex: 'code',
          key: 'code',
          render: (text, record) => (
              <span>{record?.code ? record.code : '---'}</span>
          )}
      : {}

    const isLastMaintance = column?.key === 'last_maintenance_date'
      ? {
          ...column,
          align: 'center',
        }
      : {}

    const isNextMaintance = column?.key === 'next_maintenance_date'
      ? {
          ...column,
          align: 'center',
          render: (text, record) => ( //Parsea la próxima fecha de mantenimiento
              <span>{record?.next_maintenance_date ? record.next_maintenance_date  : '---'}</span>
          )
      }
      : {}

    const isReports = column?.key === 'reports'
      ? {
          ...column,
          align: 'center',
          render: (text, record) => (<Row justify="center" gutter={8}>
            <Col>
              <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_HISTORY_MAINTENANCE)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Ver historial'}`}>
                <Button
                  disabled={!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_HISTORY_MAINTENANCE)?.enabled || !(record.actions.history_button)}
                  style={{ maxWidth: 143 }}
                  onClick={() => handleGoHistoryMaintenance({ record })}
                  shape="round"
                  className="btn-standard-white"
                  icon={<HistoryIcon className="btn-svg-icon-variant2" />}
                >
                  HISTORIAL
                </Button>
              </CustomPopoverConfirm>
            </Col>

            <Col>
              <CustomPopoverConfirm
                trigger={'hover'}
                placement="left"
                content={`${!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENNACE_DELETE_ACTIVE)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Archivar activo'}`}
              >
                <Button
                  disabled={!(record.actions.update_button) || !activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENNACE_DELETE_ACTIVE)?.enabled}
                  onClick={() => {
                    setClickedActive(record)
                    handleOpenCloseModal()
                  }}
                  shape="circle"
                  style={{
                    width: 42, height: 42, display: 'flex', alignItems: 'center', justifyContent: 'center',
                    border: '1px solid var(--primary)', opacity: currentActiveTab === '2' ? 0.5 : 1
                  }}
                  icon={<TrashIcon color="var(--primary" width="22" height="22" />}
                />
              </CustomPopoverConfirm>
            </Col>
          </Row>
          )
      } : {}

    return ({
      ...column,
      ...isCode,
      ...isLastMaintance,
      ...isNextMaintance,
      ...isReports,
      ...getColumnsFilter(column.dataIndex, activeMaintenanceTableFilters({ currentParams: currentActiveMaintenanceParams }))
  })})

  const columnsProviders = columnsTableProviders?.map(column => ({
      ...column,
      ...getColumnsFilter(column.dataIndex, providersTableFilters({ currentFiltersTableInfo: currentFiltersTableProviders, handleReset, handleSearch }))
  }))

const columnsModified = [
    ...columnsProviders,
    {
        dataIndex: 'deleteProvider',
        key: 'deleteProvider',
        render: (text, record) => ( //Se renderiza un botón en la tabla para eliminar un proveedor, y aparece un Popconfirm para confirmar la eliminación 
          <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_DELETE_PROVIDER)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Eliminar proveedor'}`}>
            <Popconfirm
              icon={<DeleteProviderIcon />}
              disabled={!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_DELETE_PROVIDER)?.enabled}
              okButtonProps={{ className: 'custom-popover__ok-button', shape: 'round', loading: loading }}
              cancelButtonProps={{ className: 'custom-popover__cancel-button', shape: 'round', disabled: loading }}
              overlayClassName="custom-popover"
              placement="leftTop"
              title={"¿Desea eliminar este proveedor?"}
              onConfirm={() => handleDeleteProvider({ id: record?.id })}
              okText="ELIMINAR"
              cancelText="CANCELAR"
            >  
              <Button disabled={!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_DELETE_PROVIDER)?.enabled} style={{ background: 'transparent', border: 'none', boxShadow: 'none' }} size="small" icon={<DeleteProviderIcon />} />
            </Popconfirm>
          </CustomPopoverConfirm>
        )
    }
  ]

    const handleOnChangeTabs = async (company_asset_status_id) => {
      setLoading(true)

      setCurrentActiveTab(company_asset_status_id)
      const { data: activeMaintenanceData, metadata: activeMaintenanceMetaData } = await getAllCompanyAssetsService({ company_asset_status_id, limit: limitTableActiveMaintenance, skip: 0, })
    
      setColumnsTableActiveMaintenance(activeMaintenanceData.columns);
      setDataTableActiveMaintenance(activeMaintenanceData?.dataSource)
      setMetaDataTableActiveMaintenance(activeMaintenanceMetaData)

      setLoading(false)
    }

    const handleChangeTable = async ({ pagination, filters, sorter, tableName }) => {
        let find_by, find_value
        if (tableName === 'providers') {
            const filter = compareObjects({ object1: currentFiltersTableProviders, object2: filters })
            find_by = filter && Object.keys(filter)[0]
            find_value = filter && filter[Object.keys(filter)[0]]

            setCurrentFiltersTableProviders((prev) => {
                let lastData = { ...prev } ?? {}
                //Puede ser un string vacío ""
                find_by ? lastData[find_by] = find_value : lastData = {}
                return lastData
            })
        }

        const sort_order = sorter.order === 'descend' ? "desc" : sorter.order === 'ascend' ? "asc" : undefined
        const sort_by = sorter.column?.key

        const pageValue = pagination.current || currentPageTableActiveMaintenance
        const newSkip = (pageValue * limitTableActiveMaintenance) - limitTableActiveMaintenance

        const actualParams = tableName === 'providers' ? { ...currentProvidersParams } : { ...currentActiveMaintenanceParams }
        const newParams = tableName === 'providers' ? {
            find_by,
            find_value,
            skip: newSkip,
        } : {
            find_by: currentActiveMaintenanceParams?.find_by,
            find_value: currentActiveMaintenanceParams?.find_value,
            sort_by,
            sort_order,
            skip: newSkip,
        }

        let same = null
        let dataTable = {}

        if (checkObjects({ actualParams, newParams, difKey: 'skip' })) {
            tableName !== 'providers' && setCurrentPageTableActiveMaintenance(pageValue)
            same = newSkip
        }

        if (same) {

            setLoading(true)
            dataTable = tableName === 'providers'
              ? undefined
              : await getAllCompanyAssetsService({ ...newParams, ...commonParams(), skip: same, setLoading, company_asset_status_id: currentActiveTab })
        }
        if (!same) {
            same = 0
            setLoadingProvider(true)
            setLoading(true)
            dataTable = tableName === 'providers'
              ? await getAllCompanyProvidersService({ ...newParams, ...commonParams('providers'), skip: same, setLoading: setLoadingProvider })
              : await getAllCompanyAssetsService({ ...newParams, ...commonParams(), skip: same, setLoading, company_asset_status_id: currentActiveTab })
            tableName === 'providers' ? setCurrentPageTableProviders(1) : setCurrentPageTableActiveMaintenance(1)
        }

        setLoading(false)
        if (tableName === 'providers' && !same && dataTable?.data) {
            setDataTableProviders(dataTable.data.dataSource)
            setMetaDataTableProviders(dataTable.metadata)
            setLastSkipProviders(same)
            setCurrentProvidersParams(newParams)
            setLoadingProvider(false)
            return
        }
        if (tableName !== 'providers' && dataTable?.data) {
            setDataTableActiveMaintenance(dataTable.data.dataSource)
            setMetaDataTableActiveMaintenance(dataTable.metadata)
            setLastSkipActiveMaintenance(same)
            setCurrentActiveMaintenanceParams(newParams)
            setLoadingProvider(false)
        }
    }

    const handlePagination = async ({ pagination, page, last }) => {
        if (!page) return

        const pageValue = page || pagination.current
        const newSkip = pageValue * limitTableProviders - limitTableProviders
        setLoadingProvider(true)
        const { data, metadata } = await getAllCompanyProvidersService({ currentPage: currentPageTableProviders, limit: limitTableProviders, actualLimit: limitTableProviders, prevData: dataTableProviders, ...currentProvidersParams, skip: newSkip, setLoading: setLoadingProvider })
        setDataTableProviders(data.dataSource)
        setMetaDataTableProviders(metadata)
        setLastSkipProviders(newSkip)
        setLoadingProvider(false)
    }

    return {
        handleRedirectTo, handleChangeTable, handleOnChangeTabs,
        handlePagination, getModuleFeatures,
        emptyFeatures, loading, loadingProvider,
        columnsActiveMaintenance, columnsProviders, columnsModified,
        loadingFeatures, activeFeatures,
    }
}

export default useActivesMaintenanceTable