import { Col, Divider, Row, Typography } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";

const { Text } = Typography

const UnapprovedRequirements = () => {
  const { ticketById } = useSelector((state) => state.detailTicketById);
  const inspectionData = ticketById?.TICKET_INSPECTION || {};
  const inspectionSupplies = inspectionData?.TICKET_INSPECTION_SUPPLies || [];
  const inspectionEquipment =
    inspectionData?.TICKET_INSPECTION_EQUIPMENTs || [];
  const expectedCost = inspectionData?.expectedCost || 0;

  const workSessions =
    ticketById?.TICKET_INSPECTION?.TICKET_INSPECTION_WORK_SESSIONs || [];
  const minutes = ticketById?.TICKET_INSPECTION?.expectedMinutes;
  const timeResult = (Math.round((minutes / 60) * 100) / 100)
    .toFixed(2)
    .replace(".", ":");

  const currency =
    ticketById?.COMPANY?.COMPANY_DETAIL?.COMPANY_CURRENCY?.symbol;

  return (
    <div>
      <Row
        justify="space-around"
        style={{
          padding: "1rem",
        }}
      >
        <Col>
          <p style={{ fontWeight: "bold" }}>Insumos solicitados:</p>
          {inspectionSupplies.map((item) => (
            <p
              key={item?.id}
              className="detail-ticket__requirement-item"
            >
              <Text key={item?.id} ellipsis style={{
                maxWidth: 200,
              }}>
                {item?.quantity} {item?.Supply.name} | {currency}{" "}
                {item?.totalCost.toFixed(2)}

              </Text>
            </p>
          ))}
        </Col>
        <Divider className="detail-ticket__divider" type="vertical" />
        <Col>
          <p style={{ fontWeight: "bold" }}>Equipo solicitado:</p>
          {inspectionEquipment.map((item) => (
            <p
              className="detail-ticket__requirement-item"
              key={item?.id}
            >
              <Text key={item?.id} ellipsis style={{
                maxWidth: 200,
              }}>
                {item?.quantity} {item?.Equipment.name}
              </Text>
            </p>
          ))}
        </Col>
        <Divider className="detail-ticket__divider" type="vertical" />
        <Row style={{ marginRight: "2rem" }} align="middle">
          <Col>
            <p style={{ fontWeight: "bold" }}>Costo Total</p>
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>
              {currency} {expectedCost.toFixed(2)}
            </p>
          </Col>
        </Row>
      </Row>
      <p>
        Tiempo estimado de reparación: {timeResult}{" "}
        {minutes < 60 ? "minutos" : "horas"}
      </p>
      <p>Fecha(s) de mantenimiento:</p>
      <ul>
        {workSessions?.slice()?.map((item, index) => (
          <li key={item?.id}>
            Jornada {index + 1} - (
            {moment(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.date).format('DD/MM/YYYY')}{" "}
            {moment(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeBegin, 'HH:mm:ss').format('h:mm A')} {" - "}
            {moment(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeEnd, 'HH:mm:ss').format('h:mm A')})
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UnapprovedRequirements;
