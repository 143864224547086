import axios from "axios"
import { verifyBodyResponse } from "../../../../../../helpers/verifyBodyResponse"
import { API_URL } from "../../../../../../../config"

const getOrnamentChecklistItemsByCategory = async ({
  checklistCategoryId,
  name,
  skip,
  limit,
  sortOrder,
  sortBy,
  idToken,
}) => {
  try {
    const isSkip = skip ? `&skip=${skip}` : ''
    const isLimit = limit ? `&limit=${limit}` : ''
    const isName = name ? `&name=${name}` : ''
    const isSortOrder = sortOrder ? `&sort_order=${sortOrder}` : ''
    const isSortBy = sortBy ? `&sort_by=${sortBy}` : ''

    const response = await axios.get(`${API_URL}checklist/type/ornament/item?checklist_category_id=${checklistCategoryId}${isName}${isSkip}${isLimit}${isSortBy}${isSortOrder}`,
      { headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000 }
    )

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getOrnamentChecklistItemsByCategory