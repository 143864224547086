import { Checkbox } from "antd";

export const CustomCheckbox = ({
  id,
  className,
  value,
  onChange,
  options,
  horizontal = false,
  maxWidth
}) => (
  <Checkbox.Group
    id={id}
    className={className || "custom-checkbox"}
    options={options}
    defaultValue={value}
    value={value}
    onChange={onChange}
    style={{ flexDirection: horizontal ? "row" : "column", maxWidth }}
  />
);
