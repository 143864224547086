import { axiosCreate } from "../config/index"
import { verifyBodyResponse } from "../topia/helpers/verifyBodyResponse"

const themePath = 'theme/company/active'
//Endpoint de topia que permite obtener la paleta de colores del tema para una cuenta en específico
const getActiveTheme = async ({ company_id, idToken }) => {
    try {
        const params = {
            company_id
        }
        const response = await axiosCreate.get(`${themePath}`, {
            params, timeout: 10000, headers: {
                Authorization: `Bearer ${idToken}`
            }
        })

        return verifyBodyResponse(response, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }
}

export default getActiveTheme