
const PhoneIcon = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
            d="M14.4826 19.366C13.2226 19.3196 9.65176 18.8261 5.91201 15.0873C2.17313 11.3475 1.68051 7.77754 1.63326 6.51666C1.56326 4.59517 3.03501 2.72879 4.73513 1.99992C4.93987 1.91152 5.16406 1.87786 5.38572 1.90225C5.60739 1.92664 5.8189 2.00824 5.99951 2.13905C7.39951 3.15929 8.36551 4.70279 9.19501 5.91642C9.37753 6.18305 9.45556 6.50752 9.41425 6.82799C9.37294 7.14846 9.21517 7.44253 8.97101 7.65417L7.26389 8.92204C7.18141 8.9816 7.12335 9.06906 7.10051 9.1682C7.07765 9.26733 7.09155 9.37138 7.13963 9.46104C7.52638 10.1637 8.21413 11.2101 9.00163 11.9976C9.79001 12.7851 10.8855 13.5184 11.6371 13.9489C11.7313 14.0018 11.8423 14.0166 11.9471 13.9903C12.0519 13.9638 12.1427 13.8984 12.2006 13.8071L13.3118 12.1158C13.5162 11.8444 13.8176 11.6626 14.1529 11.6084C14.4883 11.5543 14.8316 11.632 15.1109 11.8253C16.342 12.6775 17.7787 13.6269 18.8305 14.9735C18.972 15.1554 19.0619 15.3721 19.091 15.6006C19.1201 15.8292 19.0872 16.0614 18.9958 16.2729C18.2635 17.9818 16.4102 19.4369 14.4826 19.366V19.366Z"
            fill="var(--primary)" />
    </svg>
)

export default PhoneIcon
