import { createContext, useState } from "react";

export const ChecklistResultsContext = createContext()

export const ChecklistContextProvider = ({ children }) => {
  const [metadata, setMetadata] = useState({})
  const [metadataItems, setMetadataItems] = useState({})

  const [loadingItems, setLoadingItems] = useState(false)
  const [loadingDetailData, setLoadingDetailData] = useState(false)
  const [categoryClicked, setCategoryClicked] = useState({})

  const [checklistResultsTableData, setChecklistResultsTableData] = useState({})
  const [checklistResultsDetails, setChecklistResultsDetails] = useState({})
  const [checklistItemsTable, setChecklistItemsTable] = useState({})

  const onCleanChecklistResultsData = () => setChecklistResultsTableData({})
  const onCleanChecklistResultsDetails = () => setChecklistResultsDetails({})
  const onCleanchecklistItemsTable = () => setChecklistItemsTable({})
  const onCleanMetadata = () => setMetadata({})

  const value = {
    metadata, setMetadata,
    metadataItems, setMetadataItems,
    loadingItems, setLoadingItems,
    loadingDetailData, setLoadingDetailData,
    categoryClicked, setCategoryClicked,
    checklistItemsTable, setChecklistItemsTable,
    checklistResultsDetails, setChecklistResultsDetails,
    checklistResultsTableData, setChecklistResultsTableData,
    onCleanChecklistResultsData, onCleanChecklistResultsDetails,
    onCleanchecklistItemsTable, onCleanMetadata
  }

  return (
    <ChecklistResultsContext.Provider value={value}>
      {children}
    </ChecklistResultsContext.Provider>
  )
}