import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const companyProvidersPath = "provider"

export const getAllProviders = async ({
    token,
    company_id = 1,
    formData,
    find_by,
    find_value,
    sort_by,
    sort_order,
    limit = 7,
    skip = 0,
}) => {
    try {
        const params = {
            company_id,
            limit,
            skip,
            find_by,
            find_value,
            sort_by,
            sort_order,
            ...formData
        };

        const response = await axiosCreate.get(`${companyProvidersPath}`, {
            params, timeout: 10000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
};