import { Row } from "antd"
import { CaretDownOutlined } from '@ant-design/icons'
import { CustomInput } from "../../../../../UI/CustomInput"
import { CustomSelect } from "../../../../../UI/CustomSelect"
import CustomSwitch from "../../../../../UI/CustomSwitch"
import CustomTable from "../../../../../UI/CustomTable/CustomTable"
import searchIcon from '../../../../../../assets/icons/search.svg'
import { typeFeatures } from "../../../constants/typeFeatures"
import { renderCustomCheckbox } from "../../../helpers/customRowSelectionHelper"

//Muestra la tabla de las caracteristicas de un modulo seleccionado de un rol en específico, además de tener los filtros para buscar por instancia o por funcionalidad en las características
const DetailRoles = ({ featureModulesRole, loading, columns, activeModuleRole, handleChangeModuleRolStatus, handleChangeFeatureStatus, handleChangeTypePermission, justView }) => (
    <section className="detailRoles-section" >
        <Row className="detailRoles-header" style={{ padding: '0 24px', marginBottom: 16 }} justify="space-between">
            <Row style={{ gap: 8 }} align="middle">
                {justView ? <></> : <CustomSwitch checked={activeModuleRole.status} onChange={() => handleChangeModuleRolStatus({ module_id: activeModuleRole.module_id })} />}
                <h1>CONTROL DE ACCESO</h1>
            </Row>
            <Row style={{ gap: 12 }}>
                <CustomSelect onChange={(value) => handleChangeTypePermission({ value, type: 'type' })} placeholder={'Tipo de permiso'} allowClear={true} options={typeFeatures} width="261px" svgIcon={true} suffixIcon={<CaretDownOutlined />} />
                <CustomInput onChange={(e) => handleChangeTypePermission({ value: e.target.value, type: 'name' })} placeholder={'Buscar funcionalidad'} maxWidth={256} suffixIcon={searchIcon} />
            </Row>
        </Row>
        <CustomTable rowKey={'feature_id'}
            rowSelection={justView ? null : {
                columnTitle: 'Estado',
                columnWidth: '10%',
                checkStrictly: false, //Esto hace que por ejemplo si se selecciona un padre todos los hijos van a seleccionarse también
                selectedRowKeys: featureModulesRole?.dataSource?.length ? featureModulesRole.selectedRows : [],
                onChange: (selectedRowKeys) => handleChangeFeatureStatus({ selectedRowKeys }),
                //Se renderiza un checkbox personalizado para la tabla
                renderCell: (checked, record, index, originNode) => renderCustomCheckbox(checked, record, index, originNode, activeModuleRole)
            }}
            scrollSize={{ y: 540 }} loading={loading} dataSource={featureModulesRole.dataSource} columns={[...columns,]} pagination={false} className="detailRoles-table" />
    </section>
)

export default DetailRoles
