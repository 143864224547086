import { Button, Row } from "antd"
import { CustomNavigate } from "../../../UI/CustomNavigate"
import { ListProviderIcon, NewAccessProviderIcon, ReasonVisitIcon } from "../assets"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import { ACCESS_PROVIDERS_CODES } from "../../../../constants/modules/accessProviders/accessProvidersCodes"

const Header = ({ handleOpenCloseModal, activeFeatures }) => (
    <CustomNavigate title={'ACCESO A PROVEEDORES'} >
        <Row style={{ gap: 16 }}>
            <CustomPopoverConfirm maxWidthContainer={260} trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_MANAGEMENT_PROVIDERS)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Listado de proveedores'}`}>
                <Button
                    disabled={!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_MANAGEMENT_PROVIDERS)?.enabled}
                    onClick={() => handleOpenCloseModal({ name: 'providersModal', visible: true })}
                    className="btn-standard-white"
                    shape="round"
                    style={{ maxWidth: 260 }}
                    icon={<ListProviderIcon className="btn-svg-icon" />}
                >
                    LISTADO DE PROVEEDORES
                </Button>
            </CustomPopoverConfirm>
            <CustomPopoverConfirm maxWidthContainer={260} trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_MANAGEMENT_REASON_VISITS)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Motivos de visita'}`}>
                <Button
                    disabled={!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_MANAGEMENT_REASON_VISITS)?.enabled}
                    onClick={() => handleOpenCloseModal({ name: 'reasonVisitsModal', visible: true })}
                    className="btn-standard-white"
                    shape="round"
                    style={{ maxWidth: 260 }}
                    icon={<ReasonVisitIcon className="btn-svg-icon" />}
                >
                    MOTIVO DE VISITA
                </Button>
            </CustomPopoverConfirm>
            <CustomPopoverConfirm maxWidthContainer={220} trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_NEW_ACCESS)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nuevo acceso de proveedor'}`}>
                <Button
                    disabled={!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_NEW_ACCESS)?.enabled}
                    onClick={() => handleOpenCloseModal({ name: 'newAccessProviderModal', visible: true })}
                    className="btn-standard-green"
                    shape="round"
                    style={{ maxWidth: 220 }}
                    icon={<NewAccessProviderIcon />}
                >
                    NUEVO ACCESO
                </Button>
            </CustomPopoverConfirm>
        </Row>
    </CustomNavigate>
)

export default Header