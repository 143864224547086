import { themeSettingsColor } from "../../constants/theme";
import { setColorsTheme, setImagesTheme } from "./themeSlice";
import { getTemplate } from "../../auth/pages/services/auth/GET/getTemplate";
import getActiveTheme from "../../services/getActiveTheme";
import verifyResponse from "../../topia/helpers/verifyResponse";
import { changeCurrentRol } from "../auth";

export const setColorsThemeThunk = ({ company_id, idToken, loginData }) => {

    return async (dispatch, getState) => {
        //Llamar aquí al servicio para obtener los datos del tema de una cuenta, a partir del id
        let res = null;
        if (!loginData) {
            res = await getActiveTheme({ company_id, idToken })
            const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status })
            if (!status) {
                return
            }
        } else res = loginData
        //Se serializan los datos antes de guardarlos en el estado global
        // Establecer una variable CSS global
        const newColors = {
            theme: res.data.data.theme,
            colors: res.data.data.colors.reduce((acc, item) => {
                //Recorre el array para luego guardar un objeto donde la clave sera el nombre ya parseado para la variable CSS y su valor será el color
                const name = item.code.replace('+', 'plus').replace('-', 'minus')
                //Se busca en un arreglo externo que el nombre de la variable css incluya un prefijo para así agruparlo
                const group = themeSettingsColor.find(prefix => name.includes(prefix))
                //Verifica si ya existe un grupo en el cual colocar el valor
                if (group) {
                    //Si no se ha inicializado el objeto de grupo lo crea
                    if (!acc[group]) acc[group] = {}
                    //Si ya existe entonces dentro del objeto de grupo añade la clave del nombre y su valor de color
                    acc[group][name] = item.color
                }
                //Si no existe el grupo añade la clave directamente al objeto proncipal
                else {
                    acc[name] = item.color
                }
                return acc
            }, {})
        }
        //Obtiene las claves del objeto de colores, para sacar el nombre de la variable css con la clave del objeto
        //el color obteniendo el valor del objeto, y luego actualizar el valor de esa propiedad
        //css con la función directa de js
        Object.keys(newColors.colors).forEach(group => {
            //Verificar si se está accediendo a un grupo
            if (typeof newColors.colors[group] === 'object') {
                Object.keys(newColors.colors[group]).forEach(key => {
                    const name = `--${key}`
                    const color = newColors.colors[group][key]
                    document.documentElement.style.setProperty(name, color)
                })
            }
            else {
                //Si no es un grupo entonces establece la propiedad directamente
                const name = `--${group}`
                const color = newColors.colors[group]
                document.documentElement.style.setProperty(name, color)
            }
        })
        //Hace el dispatch para poder guardar en el estado globar los datos??
        dispatch(setColorsTheme({ theme: newColors.theme, colors: newColors.colors }))
    }
}

export const setImagesThemeThunk = ({ data }) => {
    return async (dispatch, getState) => {
        dispatch(setImagesTheme(data))
    }
}

export const setAllDataThemeThunk = ({ idTemplateRoute }) => {
    return async (dispatch, getState) => {
        const { currentRol } = getState()?.auth
        const newCurrentRol = idTemplateRoute ? idTemplateRoute : currentRol
        const res = await getTemplate({ idTemplateRoute: newCurrentRol });
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status })
        if (!status) return
        const data = res.data.data
        //Luego de llamar los datos se debe personalizar la data con base al nombre del tema, para que así después se llame al template que corresponda, crear algun objeto que contenga los template y utilizarlo aquí
        //con el propósito de actualizar los datos para las imágenes y logos
        await dispatch(setColorsThemeThunk({ loginData: { data: { data: { colors: data.colors, theme: data.theme } } } }))
        await dispatch(setImagesThemeThunk({ data: data.zones }))
        dispatch(changeCurrentRol(newCurrentRol))
        localStorage.setItem('images-dashboard', JSON.stringify(data.zones))
        return data
    }
}