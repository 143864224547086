import { useContext } from "react"
import { useAppSelector } from "../../../../hooks/store"
import verifyResponse from "../../../helpers/verifyResponse"
import { ChecklistsAdminContext } from "../context/ChecklistsAdminContext"
import { createUpdateChecklists, deleteChecklists, getChecklistsStatus, getDefaultChecklistsByCompanyId } from "../services"

const useChecklists = () => {
  const { idToken, currentRol } = useAppSelector(state => state.auth)

  const {
    setChecklistsAdmin, filters, setChecklistsStatus, setLoadingTable, setFilters, setMetadata,
    setCurrentTabChecklistStatus
  } = useContext(ChecklistsAdminContext)

  const onDeleteChecklists = async (formData) => {
    const response = await deleteChecklists(formData, idToken)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Checklist archivado con éxito' })
  }

  const onSaveChecklists = async (formData) => {
    const objToSend = {
      ...formData,
      company_id: currentRol
    }
    const response = await createUpdateChecklists(objToSend, idToken)

    const messageToShow = formData?.checklist_id ? 'actualizado' : 'creado'

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: `Checklist ${messageToShow} con éxito` })
  }

  const getChecklistsTable = async ({ limit, skip, find_value, sort_by, sort_order, checklist_status_id }) => {
    setLoadingTable(true)
    const response = await getDefaultChecklistsByCompanyId({ limit, skip, find_value, sort_by, sort_order, checklist_status_id, company_id: currentRol, idToken })

    if (response.success) {
      setChecklistsAdmin(response.data.data)
      setMetadata(response.data.metadata)
      setLoadingTable(false)
      return true
    }

    setLoadingTable(false)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.data })
  }

  const getInitialData = async () => {
    const response = await getChecklistsStatus(idToken)

    if (response.success) {
      setChecklistsStatus(response.data.data)
      setCurrentTabChecklistStatus(response.data.data[0]?.code)
      setFilters(state => ({ ...state, checklist_status_id: response.data.data[0]?.checklist_status_id }))

      await getChecklistsTable({ ...filters, checklist_status_id: response.data.data[0]?.checklist_status_id })
      
      return true
    }

    return verifyResponse({ dataResponse: response.data, statusResponse: response.data })
  }

  return {
    getInitialData,
    onSaveChecklists,
    onDeleteChecklists,
    getChecklistsTable,
  }
}

export default useChecklists