import { Button, Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import SuppliesTaskModal from '../modals/SuppliesTaskModal';
import React, { useState, useContext, useEffect } from 'react';
import TrashIcon from '../../../../../../../../assets/icons/TrashIcon';
import useOpenCloseModal from '../../../../../../../hooks/useOpenCloseModal';
import { setInventoryThunk } from '../../../../../../../../store/inventory/thunks';
import { CreateTicketByCheckListContext } from '../../../../context/CreateTicketByCheckListContext';


const SuppliesTask = ({ inventoryData, currency, setCountSupplies }) => {
    const { setTotalCostPreview, setSuppliesSelected } = useContext(CreateTicketByCheckListContext);
    const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal();
    const [dataItems, setDataItems] = useState([]);
    const [save, setSave] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setInventoryThunk(searchWord, "supplies"));
    }, [searchWord]);
    const closeModal = () => {
        handleOpenCloseModal();
        dispatch(setInventoryThunk("", "supplies"));
    }

    const handleDelete = (id) => {
        const newData = dataItems.filter(item => item.id !== id);
        setDataItems(newData);
    }
    const totalPrice = dataItems.map(item => Number(item.price) * Number(item.quantity)).filter(item => !isNaN(item));
    const totalCost = totalPrice.reduce((acc, currentValue) => acc + currentValue, 0);
    setTotalCostPreview(totalCost)
    setSuppliesSelected(dataItems);
    setCountSupplies(dataItems.length);
    return (
        <div style={{ marginTop: '1.5rem' }} >
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {!dataItems.length && (
                    <Row justify='center' align='middle' style={{ border: '1px solid #D6D6D6', width: '100%', borderRadius: '6px', background: '#F6F6F6', paddingInline: '1.5rem', marginBottom: '1rem' }} >
                        <Col>
                            <p style={{ paddingTop: '1.2rem', marginInline: '1rem' }}> No existe insumo solicitado </p>
                        </Col>
                    </Row>
                )
                }
                {dataItems && save && dataItems.map((item, i) => (
                    <Row key={i} justify='space-between' align='middle' style={{ border: '1px solid #D6D6D6', width: '100%', borderRadius: '6px', background: '#F6F6F6', paddingInline: '1.5rem', marginBottom: '1rem' }} >
                        <Col>
                            <p style={{ paddingTop: '1.2rem', marginInline: '1rem' }}>{item?.name} ({item?.quantity}) </p>
                        </Col>
                        <Col>
                            <Row align='middle'>
                                <p style={{ paddingTop: '1.2rem', marginInline: '1rem', fontWeight: 'bold' }}>{currency} {(item?.price * item?.quantity).toFixed(2)}</p>
                                <Button onClick={() => handleDelete(item?.id)} type='ghost' style={{ border: 'none' }} >
                                    <TrashIcon color="#E82A2A" width="20px" />
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                ))}
            </div>
            <Button
                icon={<PlusOutlined style={{ color: 'white', fontSize: '1.5rem' }} />}
                shape='circle'
                onClick={handleOpenCloseModal}
                style={{ width: '36px', height: '36px', border: '1px solid #D6D6D6', background: 'var(--primary)', position: 'absolute', top: '-0.5rem', right: 20 }}
            />
            <SuppliesTaskModal setSearchWord={setSearchWord} setSave={setSave} setDataItems={setDataItems} inventoryData={inventoryData} onCancel={closeModal} isOpenModal={isOpenModal} />
        </div>
    );
};

export default SuppliesTask;