import React, { useState } from "react";
import CustomModal from "../../../../UI/CustomModal";
import { Button, Col, Form, Row, Checkbox, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import QuantityInventory from "../../UI/QuantityInventory";
import { updateQuantityInventoryThunk } from "../../../../../store/detailTicket/thunk";
const InventoryModal = ({ onCancel, isOpenModal }) => {
  const { ticketById } = useSelector((state) => state.detailTicketById);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);
  const inspectionSupplies =
    ticketById?.TICKET_INSPECTION?.TICKET_INSPECTION_SUPPLies.map((supply) => ({
      ...supply,
      checked: supply?.TICKET_INSPECTION_SUPPLY_STATUS?.code === "active",
    })) || [];
  const inspectionEquipment =
    ticketById?.TICKET_INSPECTION?.TICKET_INSPECTION_EQUIPMENTs.map(
      (equipment) => ({
        ...equipment,
        checked:
          equipment?.TICKET_INSPECTION_EQUIPMENT_STATUS?.code === "active",
      })
    ) || [];
  const [equipment, setEquipment] = useState(inspectionEquipment);
  const [supplies, setSupplies] = useState(inspectionSupplies);
  const currency =
    ticketById?.COMPANY?.COMPANY_DETAIL?.COMPANY_CURRENCY?.symbol;
  const [form] = Form.useForm();

  const onChangeSupply = (e, id) => {
    const updatedSupplies = supplies.map((supply) => {
      if (supply.Supply.id === id) {
        const updatedSupply = { ...supply };
        updatedSupply.checked = e.target.checked;
        return updatedSupply;
      }
      return supply;
    });
    setSupplies(updatedSupplies);
  };

  const onChangeEquipment = (e, id) => {
    const updatedEquipment = equipment.map((equipment) => {
      if (equipment.Equipment.id === id) {
        const updatedEquipment = { ...equipment };
        updatedEquipment.checked = e.target.checked;
        return updatedEquipment;
      }
      return equipment;
    });
    setEquipment(updatedEquipment);
  };

  const previewPrice = supplies
    ?.filter((supply) => supply.checked)
    ?.map((supply) => {
      return supply?.quantity * supply?.unitPrice;
    });
  const previewTotalCost = previewPrice.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const handleUpdateInventory = () => {
    setLoading(true);
    const suppliesToUpdate = supplies
      .filter((supply) => supply.checked)
      .map((supply) => {
        return {
          id: supply.Supply.id,
          quantity: supply.quantity,
        };
      });

    const equipmentToUpdate = equipment
      .filter((equipment) => equipment.checked)
      .map((equipment) => {
        return {
          id: equipment.Equipment.id,
          quantity: equipment.quantity,
        };
      });

    dispatch(
      updateQuantityInventoryThunk(
        ticketById?.id,
        userData.roles[0].employeeId,
        equipmentToUpdate,
        suppliesToUpdate
      )
    );
    setLoading(false);
  };

  const quantityUpdateEquipment = (name, quantity) => {
    const updatedEquipment = equipment.map((equipmentItem) => {
      if (equipmentItem.Equipment.name === name) {
        const updatedItem = { ...equipmentItem };
        updatedItem.quantity = quantity;
        return updatedItem;
      }
      return equipmentItem;
    });

    setEquipment(updatedEquipment);
  };

  const quantityUpdateSupply = (name, quantity) => {
    const updatedSupplies = supplies.map((supplyItem) => {
      if (supplyItem.Supply.name === name) {
        const updatedItem = { ...supplyItem };
        updatedItem.quantity = quantity;
        return updatedItem;
      }
      return supplyItem;
    });

    setSupplies(updatedSupplies);
  };

  return (
    <CustomModal onCancel={onCancel} isOpenModal={isOpenModal}>
      <h3
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "22px",
          marginBottom: "3rem",
        }}
      >
        INVENTARIO SOLICITADO
      </h3>
      <Form
        className="standard-form"
        disabled={loading}
        form={form}
        layout="vertical"
        onFinish={handleUpdateInventory}
      >
        <Row justify="space-around">
          <Col span={11}>
            <p style={{ textAlign: "center", fontSize: "13px" }}>
              INSUMOS SOLICITADOS
            </p>
            {supplies.map((item) => (
              <Row
                align="middle"
                key={item?.id}
                style={{ marginBottom: "0.3rem", marginLeft: "1rem" }}
              >
                <Checkbox
                  defaultChecked={
                    item?.TICKET_INSPECTION_SUPPLY_STATUS?.code === "active"
                      ? true
                      : false
                  }
                  onChange={(e) => onChangeSupply(e, item?.Supply.id)}
                />
                <Dropdown
                  disabled={!item?.checked}
                  trigger={"click"}
                  overlay={
                    <QuantityInventory
                      quantityUpdate={quantityUpdateSupply}
                      stock={item?.Supply.stock}
                      name={item?.Supply.name}
                    />
                  }
                >
                  <Button
                    style={{
                      paddingInline: "0.5rem",
                      borderRadius: "5px",
                      marginLeft: "0.5rem",
                      fontSize: "12px",
                      height: "100%",
                    }}
                  >
                    {item?.quantity} {item?.Supply.name.slice(0, 18)} |{" "}
                    {currency} {(item?.quantity * item?.unitPrice).toFixed(2)}
                  </Button>
                </Dropdown>
              </Row>
            ))}
          </Col>
          <div style={{ border: "0.5px solid #BDBDBD" }}></div>
          <Col span={11}>
            <p style={{ textAlign: "center", fontSize: "13px" }}>
              EQUIPOS SOLICITADOS
            </p>
            {equipment.map((item) => (
              <Row
                align="middle"
                key={item?.id}
                style={{ marginBottom: "0.3rem", marginLeft: "1rem" }}
              >
                <Checkbox
                  defaultChecked={
                    item?.TICKET_INSPECTION_EQUIPMENT_STATUS?.code === "active"
                      ? true
                      : false
                  }
                  onChange={(e) => onChangeEquipment(e, item?.Equipment.id)}
                />
                <Dropdown
                  disabled={!item?.checked}
                  trigger={"click"}
                  overlay={
                    <QuantityInventory
                      quantityUpdate={quantityUpdateEquipment}
                      stock={item?.Equipment.stock}
                      name={item?.Equipment.name}
                    />
                  }
                >
                  <Button
                    style={{
                      paddingInline: "0.5rem",
                      borderRadius: "5px",
                      marginLeft: "0.5rem",
                      fontSize: "12px",
                      height: "100%",
                    }}
                  >
                    {item?.quantity} {item?.Equipment.name.slice(0, 18)}
                  </Button>
                </Dropdown>
              </Row>
            ))}
          </Col>
        </Row>
        <Row
          justify="center"
          align="middle"
          style={{
            marginTop: "3rem",
          }}
        >
          <p>Costo Total</p>
          <p
            style={{ fontSize: "20px", fontWeight: "bold", marginLeft: "1rem" }}
          >
            {currency} {previewTotalCost.toFixed(2)}
          </p>
        </Row>
        <Row justify="center">
          <Button
            htmlType="submit"
            loading={loading}
            className="btn-standard-green"
            shape="round"
            type="ghost"
          >
            <span>GUARDAR</span>
          </Button>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default InventoryModal;
