import axios from "axios"


const disabledEmployee = async(data) => {
    try {
        const res = await axios.put('https://domo.topiaapp.com/api/employee/status/sick', data)
        return {
            success: true,
            data: res.data,
            status: res.status
        }
    } catch (error) {
        return {
            success: false,
            error: error.response
        }
    }
}

export default disabledEmployee