import axios from "axios";
import { API_URL } from "../../../../../../../config";

const categoriesPath = "tasks/category";

export const getCategories = async ({
  token,
  company_id = 1,
  limit = 50,
  skip = 0,
  find_by,
  find_value,
}) => {
  try {
    const params = {
      company_id,
      limit,
      skip,
      find_by,
      find_value,
    };

    const response = await axios.get(`${API_URL}${categoriesPath}`, {
      params,
      timeout: 10000,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      success: true,
      ...response,
    };
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        ...error.response,
      };
    }
    if (error.request) {
      return {
        success: false,
        status: error.code,
        data: {
          errors: [{ msg: error.message }],
        },
      };
    }
  }
};
