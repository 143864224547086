import { useAppSelector } from '../../../../hooks/store'
import { customNotificationMessage } from '../../../UI/CustomNotification/CustomNotification'
import { getTableDataHelper } from '../../../helpers/getTableDataHelper'
import verifyResponse from '../../../helpers/verifyResponse'
import { deleteAccessProvider, deleteProvider, deleteReasonVisit, getAccessProviderDetail, getAllAccessProviders, getAllProviders, getReasonVisits, createAccessProvider, createProvider, createReasonVisit, updateAccessProvider } from '../services'

export const useAccessProvidersServices = () => {

    const auth = useAppSelector(state => state.auth)

    const deleteAccessProviderService = async ({ event_id, setLoading }) => {
        setLoading(true)
        const res = await deleteAccessProvider({ token: auth.idToken, event_id })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        setLoading(false)
        if (!status) throw new Error('Error in deleteAccessProviderService')
        customNotificationMessage({ status: 'error', defaultIcon: 'deleted', message: 'ACCESO DE PROVEEDORES', description: 'Acceso cancelado', duration: 2.5 })
        return res.data.data
    }

    const deleteProviderService = async ({ id, setLoading }) => {
        setLoading(true)
        const res = await deleteProvider({ token: auth.idToken, provider_id: id })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        setLoading(false)
        if (!status) throw new Error('Error in deleteProviderService')
        customNotificationMessage({ status: 'error', defaultIcon: 'deleted', message: 'PROVEEDORES', description: 'Proveedor Eliminado', duration: 2.5 })
        return res.data.data
    }

    const deleteReasonVisitService = async ({ id, setLoading }) => {
        setLoading(true)
        const res = await deleteReasonVisit({ token: auth.idToken, event_reason_id: id })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        setLoading(false)
        if (!status) throw new Error('Error in deleteReasonVisitService')
        customNotificationMessage({ status: 'error', defaultIcon: 'deleted', message: 'MOTIVO DE VISITA', description: 'Motivo Eliminado', duration: 2.5 })
        return res.data.data
    }

    const updateAccessProviderService = async ({ formData, setLoading }) => {
        setLoading(true)
        const res = await updateAccessProvider({ token: auth.idToken, formData: { ...formData, company_id: auth.currentRol } })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false, })
        setLoading(false)
        if (!status) throw new Error('Error in updateAccessProviderService')
        customNotificationMessage({ status: 'info', defaultIcon: 'saved', message: 'ACCESO DE PROVEEDORES', description: 'Evento actualizado', duration: 2.5 })
        return res.data.data
    }

    const createAccessProviderService = async ({ formData, setLoading }) => {
        setLoading(true)
        const res = await createAccessProvider({ token: auth.idToken, formData: { ...formData, company_id: auth.currentRol } })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false, })
        setLoading(false)
        if (!status) throw new Error('Error in createAccessProviderService')
        customNotificationMessage({ status: '', defaultIcon: 'saved', message: 'ACCESO DE PROVEEDORES', description: 'Evento creado', duration: 2.5 })
        return res.data.data
    }

    const createProviderService = async ({ formData, setLoading }) => {
        setLoading(true)
        const res = await createProvider({ token: auth.idToken, formData: { ...formData, company_id: auth.currentRol } })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false, })
        setLoading(false)
        if (!status) throw new Error('Error in createProviderService')
        customNotificationMessage({ status: '', defaultIcon: 'saved', message: 'PROVEEDORES', description: 'Proveedor creado', duration: 2.5 })
        return res.data.data
    }

    const createReasonVisitService = async ({ formData, setLoading }) => {
        setLoading(true)
        const res = await createReasonVisit({ token: auth.idToken, formData: { ...formData, company_id: auth.currentRol } })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false, })
        setLoading(false)
        if (!status) throw new Error('Error in createReasonVisitService')
        customNotificationMessage({ status: '', defaultIcon: 'saved', message: 'MOTIVO DE VISITA', description: 'Motivo creado', duration: 2.5 })
        return res.data.data
    }

    const getAllAccessProvidersService = async ({ refresh, provider_id, date_begin, date_end, setLoading }) => {
        !refresh && setLoading(true)
        const res = await getAllAccessProviders({ token: auth.idToken, company_id: auth.currentRol, provider_id, date_begin, date_end })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false, message: '' })
        !refresh && setLoading(false)
        if (!status) throw new Error('Error in getAllAccessProvidersService')
        return res.data.data
    }

    const getAccessProviderDetailService = async ({ event_appointment_id, setLoading }) => {
        setLoading(true)
        const res = await getAccessProviderDetail({ token: auth.idToken, event_appointment_id })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false, message: '' })
        setLoading(false)
        if (!status) throw new Error('Error in getAccessProviderDetailService')
        return res.data.data
    }

    const getAllProvidersService = async ({ find_by, find_value, sort_by, sort_order, limit, skip, setLoading, customSelectData }) => {
        setLoading(true)
        const res = await getAllProviders({ token: auth.idToken, company_id: auth.currentRol, find_by, find_value, sort_by, sort_order, limit, skip })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false, message: '' })
        if (!status) {
            setLoading(false)
            throw new Error('Error in getAllProvidersService')
        }
        if (customSelectData) return res
        const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata })
        return { data, metadata }
    }

    const getReasonVisitsService = async ({ find_by, find_value, sort_by, sort_order, limit, skip, setLoading, customSelectData }) => {
        setLoading(true)
        const res = await getReasonVisits({ token: auth.idToken, company_id: auth.currentRol, find_by, find_value, sort_by, sort_order, limit, skip })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false, message: '' })

        if (!status) {
            setLoading(false)
            throw new Error('Error in getReasonVisitsService')
        }
        let newRes = { ...res, data: { ...res.data, data: { dataSource: res.data.data } } }
        if (customSelectData) {
            const data = newRes.data.data.dataSource
            const newData = data.map(item => ({ ...item, id: item.event_reason_id, name: item.event_reason_name }))
            newRes.data.data.dataSource = newData
            return newRes
        }
        const { data, metadata } = getTableDataHelper({ data: newRes.data.data, metadata: newRes.data.metadata })
        return { data, metadata }
    }

    return {
        deleteAccessProviderService,
        deleteReasonVisitService,
        deleteProviderService,
        updateAccessProviderService,
        createAccessProviderService,
        createProviderService,
        createReasonVisitService,
        getAccessProviderDetailService,
        getAllAccessProvidersService,
        getAllProvidersService,
        getReasonVisitsService
    }

}
