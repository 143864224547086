import { Col, Row } from "antd"
import { useContext } from "react"
import useResultsChecklist from "../hooks/useResultsChecklist"
import CustomTable from "../../../../../UI/CustomTable/CustomTable"
import { ChecklistResultsContext } from "../context/ChecklistResultsContext"

const ItemsChecklistResultsTable = () => {
  const { currentPageItemsTable, changePageHandlerItemsTable } = useResultsChecklist()
  const { loadingItems, checklistItemsTable, metadataItems } = useContext(ChecklistResultsContext)
  
  const { columns = [], dataSource = [] } = checklistItemsTable
  const { quantity = 0, limit = 15 } = metadataItems
  
  const newColums = columns?.map((columnData) => {
    if (columnData.key === 'status') {
      return ({
        ...columnData,
        title: ""
      })
    }
    return columnData
  })

  return (
    <Row>
      <Col span={24}>
        <CustomTable
          rowKey={record => record.id}
          loading={loadingItems}
          className="custom-table-variant-3"
          dataSource={dataSource ?? []}
          columns={newColums ?? []}
          pagination={{
            total: quantity,
            pageSize: limit,
            onChange: async (current) => {
              await changePageHandlerItemsTable(current, limit)
            },
            showTotal: (total, range) => {
              return `${range[0]}-${range[1]} de ${total}`;
            },
            current: currentPageItemsTable,
          }}
        />
      </Col>
    </Row>
  )
}

export default ItemsChecklistResultsTable
