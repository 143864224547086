import React from 'react'

const ReportIcon = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="22" height="22" fill="white" fillOpacity="0.01" />
        <mask id="mask0_0_1342" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
            <rect width="22" height="22" fill="white" />
        </mask>
        <g mask="url(#mask0_0_1342)">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.66642 14.3204H12.4293C13.2656 12.9553 14.634 11.9624 16.2364 11.6459V3.04053C16.2364 1.93963 15.3407 1.04395 14.2398 1.04395H12.8101V1.79375C12.8101 2.78684 12.0021 3.59483 11.009 3.59483H5.43635C4.44325 3.59483 3.63527 2.78684 3.63527 1.79375V1.04395H2.20557C1.10467 1.04395 0.208984 1.93963 0.208984 3.04053V19.9066C0.208984 21.0075 1.10467 21.9032 2.20557 21.9032H13.569C12.3364 20.821 11.5547 19.2192 11.5547 17.4347C11.5547 16.8134 11.6497 16.2143 11.8252 15.6515H3.66642C3.29887 15.6515 3.00088 15.3535 3.00088 14.986C3.00088 14.6184 3.29887 14.3204 3.66642 14.3204V14.3204ZM3.66638 6.45622H12.7799C13.1474 6.45622 13.4454 6.75421 13.4454 7.12176C13.4454 7.48932 13.1474 7.7873 12.7799 7.7873H3.66638C3.29882 7.7873 3.00083 7.48932 3.00083 7.12176C3.00083 6.75421 3.29882 6.45622 3.66638 6.45622V6.45622ZM3.66638 9.07754H12.7799C13.1474 9.07754 13.4454 9.37552 13.4454 9.74308C13.4454 10.1106 13.1474 10.4086 12.7799 10.4086H3.66638C3.29882 10.4086 3.00083 10.1106 3.00083 9.74308C3.00083 9.37552 3.29882 9.07754 3.66638 9.07754V9.07754ZM3.66638 11.6991H12.7799C13.1474 11.6991 13.4454 11.9971 13.4454 12.3646C13.4454 12.7322 13.1474 13.0302 12.7799 13.0302H3.66638C3.29882 13.0302 3.00083 12.7322 3.00083 12.3646C3.00083 11.9971 3.29882 11.6991 3.66638 11.6991V11.6991Z" fill="var(--primary)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.0095 0H5.43683C5.17764 0 4.9668 0.210848 4.9668 0.470035V1.79356C4.9668 2.05275 5.17764 2.26359 5.43683 2.26359H11.0095C11.2687 2.26359 11.4795 2.05275 11.4795 1.79356V0.470035C11.4795 0.210848 11.2686 0 11.0095 0V0Z" fill="var(--primary)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.3377 12.8691C14.8823 12.8691 12.8848 14.9172 12.8848 17.4347C12.8848 19.9522 14.8823 22.0002 17.3377 22.0002C19.7931 22.0002 21.7906 19.9522 21.7906 17.4347C21.7906 14.9172 19.7931 12.8691 17.3377 12.8691V12.8691ZM19.2014 19.9298C19.0819 20.019 18.9421 20.0621 18.8037 20.0621C18.6008 20.0621 18.4006 19.9698 18.2699 19.7948L16.8046 17.833C16.7187 17.7179 16.6723 17.5782 16.6723 17.4347V16.0137C16.6723 15.6461 16.9703 15.3481 17.3378 15.3481C17.7054 15.3481 18.0033 15.6461 18.0033 16.0136V17.2136L19.3363 18.9983C19.5563 19.2928 19.4958 19.7098 19.2014 19.9298V19.9298Z" fill="var(--primary)" />
        </g>
    </svg>
)

export default ReportIcon