import { Fragment } from "react"
import { modulesCategories } from "../constants/modulesCategories"
import MenuItem from "./MenuItem"

const SubmenuItem = ({ submenuItems, type, handleActualSetFeatures }) => {
    return (
        <>
            <span className="submenu-title">{modulesCategories[type]}</span>
            <div className="submenu-container">
                {
                    submenuItems.items[type].map((item, index) => <Fragment key={index}>
                        <MenuItem handleActualSetFeatures={handleActualSetFeatures} item={item} />
                    </Fragment>)
                }
            </div>
        </>
    )
}

export default SubmenuItem