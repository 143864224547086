import { axiosCreate } from "../config/index"
import { verifyBodyResponse } from "../topia/helpers/verifyBodyResponse"

const checkFeatures = async ({ formData, token }) => {
    try {
        const res = await axiosCreate.post('role/company/module/feature/check', formData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return verifyBodyResponse(res, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }
}

export default checkFeatures