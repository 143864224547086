import { Button, Divider, Typography } from 'antd'
import { ReactComponent as DeleteIcon } from '../assets/deleteIcon.svg'
import { CustomProgress } from '../../../pages/checklist/activityMonitoring/components/ActivityMonitoring/components/CustomProgress'
const { Text, Paragraph } = Typography

const FilesList = ({ progressFile, fixedWidth, width, fileList, justImages, maxSizeInBytes, multipleFiles, verticalUpload, itemRender, getFileSize, handleRemoveFile, handleTextChange, handleOpenDialogFile }) => {
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16, width: fixedWidth ? width - 50 : '100%' }}>

                {
                    fileList?.map((file) => {
                        if (justImages) {
                            return (<div className='uploaded-image' key={file.uid} style={{ maxWidth: fixedWidth ? width - 50 : '100%' }}>
                                {itemRender(file)}
                                <div className='item-container' style={{ paddingRight: 32, width: '100%' }}>
                                    <Text style={{ width: fixedWidth ? width - 200 : '100%' }} editable={{ onChange: (value) => handleTextChange({ id: file.uid, type: 'title', value }), autoSize: { maxRows: 1 }, tooltip: 'Editar' }} ellipsis>{file.title || `${file.name}`}</Text>
                                    <Paragraph style={{ maxWidth: '100%' }} editable={{ onChange: (value) => handleTextChange({ id: file.uid, type: 'description', value, }), autoSize: { maxRows: 3 }, tooltip: 'Editar' }} ellipsis={{ rows: 3 }}>{file.description || 'Descripción'}</Paragraph>
                                    <Button className='delete-file' icon={<DeleteIcon />} onClick={handleRemoveFile(file)} />
                                </div>
                            </div>)
                        }
                        return (
                            <div className='uploaded-file' key={file.uid} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                <div className='uploade-file__file-info'>
                                    {itemRender(file)}
                                    <div>
                                        <span>
                                            <Paragraph style={{ maxWidth: width - 145 }} ellipsis={{ rows: 2 }}>{file.name}</Paragraph>
                                        </span>
                                        {
                                            file.percent < 100 && progressFile ?
                                                <CustomProgress
                                                    fontSize={12}
                                                    marginBottom={0}
                                                    strokeLinecap='circle'
                                                    width={'100%'}
                                                    height={'100%'}
                                                    percent={file?.percent}
                                                    className='custom-upload-progress'
                                                    strokeColor='var(--primary)'
                                                    trailColor='var(--primaryminus3)'
                                                    textColor='var(--primary)'
                                                    type='line'
                                                />
                                                : <div>
                                                    <small> Tamaño: {getFileSize(file.size)}</small>
                                                    <Button className='delete-file' icon={<DeleteIcon />} onClick={handleRemoveFile(file)} />
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Divider type={verticalUpload ? 'horizontal' : 'vertical'} />
            <div className='custom-upload-file__add-files'>
                <Button onClick={handleOpenDialogFile} shape='round'> {multipleFiles ? 'SUBIR MÁS ARCHIVOS' : 'SUBIR ARCHIVO OTRA VEZ'}</Button>
                <small>Tamaño máximo: {getFileSize(maxSizeInBytes, 0)}</small>
            </div>
        </>
    )
}

export default FilesList