import { Badge } from "antd"

const CustomBadge = ({
  dot,
  color = '',
  count,
  offset,
  overflowCount,
  showZero,
  size = '',
  status = '',
  text,
  title = '',
  children
}) => {
  return (
    <Badge
      color={color}
      count={count}
      dot={dot}
      offset={offset}
      overflowCount={overflowCount}
      showZero={showZero}
      size={size}
      status={status}
      text={text}
      title={title}
      style={{
        background: "var(--primary)",
        // transform: "translate(90%, -70%)",
      }}
    >
      {children}
    </Badge>
  )
}

export default CustomBadge