import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  treeSelectData: [],
  detailLocalsData: {},
  tagsElements: [],
  idClickedPlace: '',
  clickedPlaceData: {},
  idLevelSelected: null,
  placeAnalyticsData: {},
}

export const mallsSlice = createSlice({
  name: "malls",
  initialState,
  reducers: {
    setCurrentTreeSelectData: (state, { payload }) => {
      state.treeSelectData = payload
    },
    setDetailLocalsData: (state, { payload }) => {
      state.detailLocalsData = payload
    },
    setTagsElements: (state, { payload }) => {
      state.tagsElements = payload
    },
    setClickedPlaceData: (state, { payload }) => {
      state.clickedPlaceData = payload
    },
    setIdLevelSelected: (state, { payload }) => {
      state.idLevelSelected = payload
    },
    setIdClickedPlace: (state, { payload }) => {
      state.idClickedPlace = payload
    },
    setPlaceAnalyticsData: (state, { payload }) => {
      state.placeAnalyticsData = payload
    },
    setInitialState: (state) => {
      state.clickedPlaceData = {}
      state.detailLocalsData = {}
      state.idClickedPlace = ''
      state.tagsElements = []
      state.treeSelectData = []
      state.idLevelSelected = null
      state.placeAnalyticsData = {}
    }
  }
})

export const {
  setCurrentTreeSelectData,
  setDetailLocalsData,
  setTagsElements,
  setClickedPlaceData,
  setIdLevelSelected,
  setIdClickedPlace,
  setPlaceAnalyticsData,
  setInitialState,
} = mallsSlice.actions
