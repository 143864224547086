import { Col, Image, Row } from "antd"
import useTicketById from "../hooks/useTicketById"
import { useEffect, useState } from "react"
import { getChecklistDetail } from "../../checklist/handoverCheck/services"
import { useSelector } from "react-redux"
import { FileOutlined } from '@ant-design/icons';
import CustomPreviewImageModal from "../../../UI/CustomPreviewImageModal"
import useOpenCloseModal from "../../../hooks/useOpenCloseModal"
import { CustomSpin } from "../../../UI/CustomSpin"

const DetailRequirementByChecklist = () => {
  const [urlImage, setUrlImage] = useState('')
  const [loading, setLoading] = useState(false)
  const [detailChecklist, setDetailChecklist] = useState({})

  const { ticketById } = useTicketById()
  const companyId = useSelector((state) => state?.auth?.currentRol) ?? 0
  const { idToken } = useSelector(state => state.auth)
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  //Prepare Data
  const { dataSource } = detailChecklist ?? []

  useEffect(() => {
    getChecklistDetailById()
  }, [])

  const getChecklistDetailById = async () => {
    setLoading(true)
    const idChecklist = (ticketById.CHECKLIST_INSTANCE.id) ?? 1
    const response = await getChecklistDetail(companyId, idChecklist, '', '', 10, 0, idToken)

    if (response.success) setDetailChecklist(response.data.data)

    setLoading(false)
  }

  const handleOnClickImages = (img) => {
    setUrlImage(img)
    handleOpenCloseModal()
  }

  if (loading) return <CustomSpin section size="large" />

  return <Row style={{ marginTop: 15, paddingBottom: 48 }}>
    <Col style={{ backgroundColor: "#FFFFFF", borderRadius: 12, }} span={24}>
      <Row justify="start" style={{ margin: 25 }}>
        <label style={{ backgroundColor: "#FFFFFF", padding: 16, border: "1px solid #ADADAD", borderRadius: 8, color: "#545454", fontWeight: 500, fontSize: 16, display: 'flex', alignItems: 'center', gap: 6 }}>
          NÚMERO DE REQUERIMIENTOS
          <span style={{ fontWeight: 700, fontSize: 25, color: "#E82A2A" }}>
            {detailChecklist?.checklistInstanceDetail?.failedReviews < 10
              ? `  0${detailChecklist?.checklistInstanceDetail?.failedReviews}`
              : `  ${detailChecklist?.checklistInstanceDetail?.failedReviews}`}
          </span>
        </label>
      </Row>

      <Row >
        <Col span={24} style={{ padding: 24, display: 'flex', flexDirection: 'column', gap: 16 }}>
          {dataSource?.length ? (
            dataSource?.map((requeriment) => (
              <Row key={requeriment.item} style={{ border: "1px solid #ADADAD", borderRadius: 8, padding: 16 }}>
                <Col span={12}>
                  <p style={{ color: "#2D2D2D", fontSize: 12, fontWeight: 400 }}>TAREA: <span style={{ fontWeight: 500 }}>{requeriment.item}</span></p>

                  <p style={{ color: "#2D2D2D", fontSize: 12, fontWeight: 400 }}>CATEGORÍA: <span style={{ fontWeight: 500 }}>{requeriment.category}</span></p>

                  <p style={{ color: "#2D2D2D", fontSize: 12, fontWeight: 400 }}>OBSERVACIÓN: <span style={{ fontWeight: 500 }}>{requeriment.review.comment}</span></p>
                </Col>
                <Col span={12}>
                  <p style={{ color: "#2D2D2D", fontSize: 12, fontWeight: 500 }}>IMÁGENES</p>
                  <Row justify="center" style={{ border: `${requeriment.review.images.length ? '1px solid #ADADAD' : ''}`, borderRadius: 5 }}>
                    {requeriment.review.images ? (
                      requeriment.review.images.map((img) => (
                        <Image
                          key={`${img}`}
                          alt={`${img}`}
                          src={`${img}`}
                          style={{ maxWidth: 100, cursor: "pointer", margin: 10 }}
                        />
                      ))
                    ) : 'Sin imágenes'}
                  </Row>
                  {requeriment.review.files ? (
                    requeriment.review.files.map((file) => (
                      <Row>
                        <a
                          href={file}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <FileOutlined
                            size="18px"
                            style={{ marginRight: "5px" }}
                          />
                          Archivo
                        </a>
                      </Row>
                    ))
                  ) : 'Sin archivos'}
                </Col>
              </Row>
            ))
          ) : (
            <Row justify="center">
              <p>NO HAY REQUERIMIENTOS</p>
            </Row>
          )
          }
        </Col>
      </Row>
    </Col>
    <CustomPreviewImageModal
      width={500}
      isOpenModal={isOpenModal}
      onCancel={handleOpenCloseModal}
      previewImage={urlImage}
    />
  </Row>
}

export default DetailRequirementByChecklist