import axios from "axios"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"
import { API_URL } from "../../../../config"

const getExportOrnamentChecklistExcel = async({
  idToken,
  companyId,
  checklistId,
  employeeId,
  dateBegin,
  dateEnd,
  checklistIdStatus,
}) => {
  try {
    const isChecklistId = checklistId ? `&checklist_id=${checklistId}` : ''
    const isEmployeeId = employeeId ? `&employee_id=${employeeId}` : ''
    const isDateBegin = dateBegin ? `&date_begin=${dateBegin}` : ''
    const isDateEnd = dateEnd ? `&date_end=${dateEnd}` : ''
    const isChecklistIdStatus = checklistIdStatus ? `&checklist_instance_status_id=${checklistIdStatus}` : ''

    const response = await axios.get(`${API_URL}checklist/type/ornament/instance/export?company_id=${companyId}${isChecklistId}${isEmployeeId}${isDateBegin}${isDateEnd}${isChecklistIdStatus}`,
      { headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000, responseType: 'blob' },
    )

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Checklist-de-ornato.xlsx");
    document.body.appendChild(link);
    link.click();

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getExportOrnamentChecklistExcel