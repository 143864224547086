import { Radio } from "antd";
import React from "react";

export const CustomRadioButton = ({
  className = 'custom-radio',
  options,
  id,
  value,
  defaultValue,
  onChange,
  style,
}) => {
  return (
    <Radio.Group
      style={style}
      id={id}
      options={options ? options : undefined}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      className={className}
    />
  );
};
