//ADMINISTRACIÓN DE ESPACIOS ✅
export const managementSpacesModule = {
    feature_code: 'FEAT-433',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-434',
            enabled: false,
        },
        {
            feature_code: 'FEAT-435',
            enabled: false,
        },
        {
            feature_code: 'FEAT-365',
            enabled: false,
        },
        {
            feature_code: "FEAT-368",
            enabled: false
        },
        {
            feature_code: "FEAT-369",
            enabled: false
        },
        {
            feature_code: "FEAT-370",
            enabled: false
        },
        {
            feature_code: "FEAT-371",
            enabled: false
        },
    ]
}