import { useState, useEffect, useRef } from "react";
import verifyResponse from "../../../helpers/verifyResponse";
import {
  addFileAttachment,
  getAllMessagesByConversationId,
  getConversationByIdTicket,
} from "../services";
import useCurrentLocation from "../../../hooks/useCurrentLocation";
import { useAppSelector } from "../../../../hooks/store";

const useChat = () => {
  const currentConversationId = useRef(0)
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [metaDataResponse, setMetaDataResponse] = useState({})
  const [conversationByTicketId, setConversationByTicketId] = useState(null)

  const { param } = useCurrentLocation()
  const { currentDataChatSelected } = useAppSelector(state => state.home)

  const idChat = param.idTicket ? param.idTicket : currentDataChatSelected.id

  useEffect(() => {
    getMessagesData();

    return () => {
      setMessages([]);
    };
  }, [currentDataChatSelected]);

  const getMessagesData = async () => {
    setLoading(true)
    if (param.idTicket) {
      const { id } = await getConversationByTicketId(idChat)

      if (id) await getAllConversationMessages(0, 25, id)
      setLoading(false)
      return
    }

    await getAllConversationMessages(0, 25, idChat)
    setLoading(false)
  };

  const getConversationByTicketId = async (idTicket) => {
    const response = await getConversationByIdTicket(idTicket);
    if (response.success && response.data.data) {
      setConversationByTicketId(response.data.data);
      currentConversationId.current = response?.data?.data?.id
      return response.data.data;
    }
    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  const getAllConversationMessages = async (
    skip = 0,
    limit = 25,
    idConversation
  ) => {
    const response = await getAllMessagesByConversationId(
      skip,
      limit,
      idConversation
    );

    if (response.success) {
      const newMessages = response.data.data
      setMetaDataResponse(response.data.metadata)
      setMessages((state) => [...state, ...newMessages])
      // se valida si estamos en el chat de un ticket para evitar tomar el id del ticket en lugar de tomar el id que
      // devuelve la response inicuial, es decir, si no estamos dentro de un chat de un ticket si podemos tomar el
      // id de la conversacion desde una lista
      if (!(param.idTicket)) currentConversationId.current = idChat
      return response;
    }

    setMessages([]);
    return verifyResponse(response.data, response.status, "");
  };

  // const getLastMessageConversation = async( skip = 0, limit = 0, idConversation) => {
  //   const response = await getAllMessagesByConversationId(
  //     skip,
  //     limit,
  //     idConversation
  //   );
  //   if(response.success) return response.data.data;
  //   verifyResponse(response.data, response.status, "");
  // }

  const onSaveFile = async (conversationId, profileId, formData) => {
    const response = await addFileAttachment(
      conversationId,
      profileId,
      formData
    );

    if (response.success) return true

    return verifyResponse(response.data, response.status, "");
  };

  return {
    loading,
    loadingMore,
    setLoadingMore,
    messages,
    onSaveFile,
    setMessages,
    metaDataResponse,
    conversationByTicketId,
    currentConversationId,
    setConversationByTicketId,
    getConversationByTicketId,
    getAllConversationMessages,
    // getLastMessageConversation,
  };
};

export default useChat;
