import { Button, Col, Row } from "antd"
import { useAppSelector } from "../../../../hooks/store"
import UploadUpArrowIcon from "../../../../assets/icons/Generals/ContractsFlow/UploadUpArrowIcon"
import { CustomNavigate } from "../../../UI/CustomNavigate"
import BuildingIcon from "../../../../assets/icons/Generals/BuildingIcon"
import { MANAGEMENT_CONTRACTORS_CODES } from "../../../../constants/modules/managamentContractor/managamentContractorCodes"

const HeaderContracts = ({ onReset, handleOpenCloseModal, activeFeatures }) => {
  const { clickedPlaceData } = useAppSelector(state => state.malls)
  const { currentOptionMenu } = useAppSelector(state => state.detailContracts)

  const isPLaceDetail = clickedPlaceData?.place?.PLACE_DETAIL

  return (
    <header style={{ marginBottom: 25 }}>
      <Col span={18}>
        <Row>
          <Col span={24}>
            <Row justify={`${(currentOptionMenu === "4") ? "space-between" : "start"}`} align="middle">
              <Col>
                <Row justify="start" gutter={24} align="middle">
                  <Col>
                    <CustomNavigate />
                  </Col>
                  <Col>
                    <BuildingIcon />
                  </Col>

                  <Col>
                    <h3 style={{ fontWeight: 400, fontSize: 18, color: "#232C4A" }}>
                      {isPLaceDetail ? clickedPlaceData?.place?.PLACE_DETAIL?.name : "No existe información agregada"}
                    </h3>

                    <Row justify="start">
                      <p style={{ fontWeight: 600, fontSize: 16, color: "#232C4A", margin: 0 }}>
                        {clickedPlaceData?.place?.PLACE_HIERARCHY?.name} {clickedPlaceData?.place?.name}
                        {' '}
                        <span style={{ color: "var(--primary)", fontWeight: 500, fontSize: 12, paddingLeft: 30 }}>
                          {clickedPlaceData?.parentPlace?.name}: {clickedPlaceData?.parentName}
                        </span>
                      </p>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col>
                {(currentOptionMenu === "4" && activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_MANAGEMENT_MAPS)?.enabled) && <Button
                  shape="round"
                  htmlType="button"
                  className="btn-standard-green"
                  style={{ width: 250 }}
                  onClick={() => {
                    onReset()
                    handleOpenCloseModal()
                  }}
                >
                  <Row gutter={16} align="middle">
                    <UploadUpArrowIcon color="white" />
                    <span style={{ paddingLeft: 15 }}>NUEVO PLANO</span>
                  </Row>
                </Button>}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </header>
  )
}

export default HeaderContracts