import { CustomModal } from './../../../../../UI/CustomModal'
import { useAppSelector } from '../../../../../../hooks/store'
import { APIProvider, AdvancedMarker, Map } from '@vis.gl/react-google-maps'

const AlertCoordsModal = ({
  isOpenModal,
  handleOpenCloseModal
}) => {
  const { alertSelectedDetail } = useAppSelector(state => state.alerts)
  const { coords } = alertSelectedDetail

  const center = {
    lat: (coords?.lat) ? parseFloat(coords?.lat?.toFixed(2)) : 22.54992,
    lng: (coords?.lng) ? parseFloat(coords?.lng?.toFixed(2)) : 0
  }

  const containerStyle = {
    width: '400px',
    height: '400px',
    margin: 'auto'
  };

  return (
    <CustomModal
      destroyOnClose
      isOpenModal={isOpenModal}
      onCancel={handleOpenCloseModal}
    >
      <h2 style={{ textAlign: 'center', marginBottom: 25 }}>
        COORDENADAS ALERTA
      </h2>

      <APIProvider apiKey='AIzaSyDcHXx5bT0lelig3L2bzK7sBcQUYFaLxoc'>
        <Map
          mapId={'bf51a910020fa25a'}
          style={containerStyle}
          defaultZoom={15}
          defaultCenter={center}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
        >
          <AdvancedMarker
            position={center}
            title="Coordenadas de alerta"
          />
        </Map>
      </APIProvider>
    </CustomModal>
  )
}

export default AlertCoordsModal