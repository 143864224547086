import { Button, Col, Form, Row } from "antd"
import CustomModal from "../../../../../../UI/CustomModal"
import { CustomInput } from "../../../../../../UI/CustomInput"
import { config } from "../../../../../../constants/constants"
import { useContext, useState } from "react"
import { AdminChecklistContext } from "../../context/AdminChecklistContext"
import useAdminChecklist from "../../hooks/useAdminChecklist"

const NewEditCategoryChecklistModal = ({
  form,
  isOpenModal,
  handleOpenCloseModal,
}) => {
  const [loading, setLoading] = useState(false)

  const { onSaveOrnamentChecklistCategory, getChecklistCategories } = useAdminChecklist()
  const { filters, checklistCategoryClicked, defaultChecklistData, setChecklistCategoryClicked } = useContext(AdminChecklistContext)

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      handleOpenCloseModal()
      await getChecklistCategories({ ...filters })
    }
  }

  const onSubmit = async (formData) => {
    setLoading(true)

    const isChecklistInstanceId = checklistCategoryClicked?.checklist_category_id
      ? {
        checklist_category_id: checklistCategoryClicked?.checklist_category_id
      } : {}

    const objToSend = {
      ...formData,
      ...isChecklistInstanceId,
      checklist_id: defaultChecklistData?.checklist_id,
    }

    const response = await onSaveOrnamentChecklistCategory(objToSend)

    await onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal
      destroyOnClose
      title={<h3 style={{ margin: 0 }}>{`${checklistCategoryClicked?.checklist_category_id ? 'EDITAR CATEGORÍA' : 'NUEVA CATEGORÍA'}`}</h3>}
      isOpenModal={isOpenModal}
      onCancel={() => {
        handleOpenCloseModal()
        setChecklistCategoryClicked({})
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="newEditCategoryForm"
        onFinish={onSubmit}
        disabled={loading}
      >
        <label style={{ fontWeight: 600, color: '#626262', fontSize: 12 }}>
          NOMBRE DE CATEGORÍA
        </label>

        <Form.Item name="name" {...config}>
          <CustomInput placeholder="Escribe el nombre de una categoría" />
        </Form.Item>

        <Row justify="end" gutter={16}>
          <Col>
            <Button
              type="text"
              shape="round"
              htmlType="button"
              style={{ border: 0, width: 132, height: 42 }}
              loading={loading}
              disabled={loading}
              onClick={() => {
                handleOpenCloseModal()
                setChecklistCategoryClicked({})
              }}
            >
              <span style={{ fontWeight: 500, fontSize: 14, color: '#404040' }}>CANCELAR</span>
            </Button>
          </Col>

          <Col>
            <Button
              type="text"
              shape="round"
              htmlType="submit"
              style={{ border: 0, width: 132 }}
              loading={loading}
              disabled={loading}
              className="btn-standard-green"
            >
              <span>CREAR</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default NewEditCategoryChecklistModal
