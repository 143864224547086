import { axiosCreate } from "../../../../../config/index"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const companyAssetPath = 'company/asset'

export const createUpdateCompanyAsset = async ({
  token,
  formData
}) => {
  try {
    const response = await axiosCreate.put(`${companyAssetPath}`, formData, {
      timeout: 50000,
     headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": 'multipart/form-data'
      },
    })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }}