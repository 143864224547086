import { Button, Col, Row } from "antd"
import ActionsButtons from "./ActionsButtons"
import useBookings from "../hooks/useBookings"
import { CustomSpin } from "../../../UI/CustomSpin"
import CustomTable from "../../../UI/CustomTable/CustomTable"
import { verifyStatusColor } from "../../../helpers/verifyTypeColors"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { setAllFilters } from "../../../../store/bookings/bookingsSlice"

//imagenes o archivos
import { ReactComponent as DetailIcon } from '../../../../assets/icons/assign.svg'

const BookingsTable = ({ tableData, handleOpenCloseModal, setLoadingModal }) => {
  const dispatch = useAppDispatch()
  // Prepare data from redux
  const { columns, dataSource } = tableData
  const { metadata, currentActiveTab, filters, loadingReserves } = useAppSelector(state => state.bookings)
  const { limit, quantity } = metadata
  const { changePageHandler, currentPage, getReservesByFilters } = useBookings()

  if (!(columns) || !(dataSource)) return <CustomSpin section />

  // Colores de background en table
  let objBackgroundColors = {
    'awaitingConfirmation': '#FEEDBE',
    'approved': '#C2F2DF',
    'canceled': '#F2C2C2',
    'default': ''
  }

  // Colores de texto
  let objTextColors = {
    'awaitingConfirmation': '#695212',
    'approved': '#10725A',
    'canceled': '#DD3737',
    'default': ''
  }

  // Colores de circulo
  let objCircleColors = {
    'awaitingConfirmation': '#F6B500',
    'approved': '#10725A',
    'canceled': '#FF0A0A',
    'default': ''
  }

  // Agregando columnas extras a las columnas desde el backend
  const newStyledColumns = columns.map(((column) => {
    const statusColumn = column.dataIndex === 'status_name'
      ? {
        ...column,
        render: (text, record) => (
          <Row
            justify="center"
            align="middle"
          >
            <Col style={{
              padding: '4px 16px',
              borderRadius: 50,
              color: verifyStatusColor(record.status_code, objTextColors),
              backgroundColor: verifyStatusColor(record.status_code, objBackgroundColors),
            }}>
              <span style={{ backgroundColor: verifyStatusColor(record.status_code, objCircleColors), marginRight: 5 }} className="circle"></span>

              <span style={{ fontWeight: 500 }}>
                {text}
              </span>
            </Col>
          </Row>
        )}
      : column
    
    const profileColumn = column.dataIndex === 'profile_name'
      ? {
        ...column,
        sorter: true,
        render: (text, record) => (
          <Row justify="start" align="middle" gutter={16}>
            <Col>
              <Button
                type="link"
                htmlType="button"
                shape="circle"
                onClick={() => {}}
                icon={<DetailIcon />}
              />
            </Col>

            <Col>
              <span>
                {text}
              </span>
            </Col>
          </Row>
        )}
      : {}

    const dateColumn = column.dataIndex === 'reservation_date'
      ? {
        ...column,
        sorter: true,
      }
      : {}

    const scheduleColumn = column.dataIndex === 'reservation_time'
      ? {
        ...column,
        sorter: true,
      }
      : {}

    return ({
      ...column,
      ...dateColumn,
      ...statusColumn,
      ...profileColumn,
      ...scheduleColumn,
    })
  }))

  const newColumns = [
    ...newStyledColumns ?? [],
    {
      title: "DETALLE",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (_, record) => (
        <ActionsButtons
          setLoadingModal={setLoadingModal}
          reserveData={record}
          handleOpenCloseModal={handleOpenCloseModal}
        />
      )
    }]

    const handleOnChange = async ({ sorter }) => {      
      if (sorter.order) {
        await getReservesByFilters({
          ...filters,
          ...metadata,
          reservationCode: currentActiveTab,
          sortOrder: sorter.order ? (sorter?.order === 'ascend' ? 'asc' : 'desc') : '',
          sortBy: sorter?.order ? sorter.columnKey : ''
        })
        dispatch(setAllFilters({ 
          ...filters,
          reservationCode: currentActiveTab,
          sortOrder: sorter.order ? (sorter?.order === 'ascend' ? 'asc' : 'desc') : '',
          sortBy: sorter?.order ? sorter.columnKey : ''
        }))
        return
      }

      dispatch(setAllFilters({ ...filters, reservationCode: currentActiveTab, sortOrder: '', sortBy: '' }))
    }

  return <Row style={{ marginTop: 30 }}>
    <Col span={24}>
      <CustomTable
        className="booking-table"
        loading={loadingReserves}
        columns={newColumns}
        dataSource={dataSource}
        rowKey={record => record.reservation_id}
        onChange={handleOnChange}
        pagination={{
          total: quantity,
          pageSize: limit,
          onChange: async (current) => {
            await changePageHandler(current, limit)
          },
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} de ${total}`;
          },
          current: currentPage,
        }}
      />
    </Col>
  </Row>
}

export default BookingsTable
