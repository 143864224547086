export const summaryTaskType = [
  { name: "Total de tareas", code: "all", taskCount: 0 },
  { name: "Activos", code: "inProgress", taskCount: 0 },
  { name: "Sin asignar", code: "unassigned", taskCount: 0 },
  { name: "En Pausa", code: "paused", taskCount: 0 },
  {
    name: "Eliminadas",
    code: "deleted",
    taskCount: 0,
  },  
];
