import { SearchOutlined } from '@ant-design/icons'
import { CustomSearchInputFilter } from '../../../UI/CustomSearchInputFilter/CustomSearchInputFilter'

export const activeMaintenanceTableFilters = ({ currentParams }) => ({
    lastMaintenanceDate: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams.sort_by === 'last_maintenance_date' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null
    }),
    nextMaintenanceDate: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams.sort_by === 'next_maintenance_date' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null
    }),
    default: () => ({})
})

export const historyMaintenanceTableFilters = ({ currentParams }) => ({
    date: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams.sort_by === 'date' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null
    }),
    default: () => ({})
})

export const providersTableFilters = ({ currentFiltersTableInfo, handleReset, handleSearch }) => {
    return ({
        name: () => ({
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters
            }) => (
                <CustomSearchInputFilter dataIndex={'name'} placeholder={'Buscar'} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} confirm={confirm} clearFilters={clearFilters} handleReset={handleReset} handleSearch={handleSearch} />
            ),
            filterIcon: (filtered) => <SearchOutlined />,
            filteredValue: currentFiltersTableInfo?.name || ""
        }),
        default: () => ({})
    })
}