import { Col, Row, Button } from 'antd';
import { useEffect } from 'react';
import { CustomNavigate } from '../../UI/CustomNavigate'
import { CustomSpin } from '../../UI/CustomSpin';
import EmptyComponent from '../../UI/EmptyComponent'
import { ReactComponent as PlusIcon } from './assets/icons/plusIcon.svg'
import { ReactComponent as SelectSurveyIcon } from './assets/icons/selectSurveyIcon.svg'
import CustomTabs from '../../UI/CustomTabs/CustomTabs';
import useSurveys from './hooks/useSurveys';
import DetailSurvey from './components/DetailSurvey/DetailSurvey';
import ResumeSurvey from './components/ResumeSurvey/ResumeSurvey';
import useSurveysTabs from './hooks/useSurveysTabs';
import useSurveysCharts from './hooks/useSurveysCharts';
import useSurveysForm from './hooks/useSurveysForm';
import MainModalSurveys from './components/StepSurveys/MainModalSurveys';
import './style.css'
import { useFeatureRole } from '../../hooks/useFeatureRole';
import { SURVEYS_CODES, SURVEYS_CODES_NAMES } from '../../../constants/modules/surveys/surveysCodes';
import CustomPopoverConfirm from '../../UI/CustomPopoverConfirm';

export const SurveyPage = () => {

  const { getSurveys, getActiveSurveys, handleStatusModal, clearSurveysData, dispatch, surveys } = useSurveys()
  const { tabItems, handleGetResults } = useSurveysTabs()
  const { chartData, downloadSurvey, opcionesGrafica, modalVisible } = useSurveysCharts()
  const { setFormModal, loading } = useSurveysForm()
  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: SURVEYS_CODES, validate_code: SURVEYS_CODES_NAMES.SURVEYS_MODULE })

  useEffect(() => {
    dispatch(getSurveys());

    return () => {
      dispatch(clearSurveysData())
    }
  }, [])

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (
    <>
      <section className="section-wrapper">
        <CustomNavigate title={'ADMINISTRACIÓN DE ENCUESTA '} />
        <CustomSpin spinning={loading}>
          <Row>
            <Col span={24}>
              <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === SURVEYS_CODES.SURVEYS_NEW_SURVEY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nueva encuesta'}`}>
                <Button disabled={!activeFeatures?.find(feature => feature.feature_code === SURVEYS_CODES.SURVEYS_NEW_SURVEY)?.enabled} icon={<PlusIcon className='btn-svg-icon-variant2' />} shape='round' className='btn-standard-white' onClick={() => {
                  setFormModal(true)
                }}>
                  NUEVA ENCUESTA
                </Button>
              </CustomPopoverConfirm>
            </Col>
            <Col className='surveys-tabs-container' lg={10} sm={24}>
              <CustomTabs className='custom-tabs-variant-3' defaultActiveKey='0' items={tabItems} />
            </Col>
            <Col className='surveys-details-container' lg={{ span: 14 }} sm={24}>
              {
                surveys?.active ? <DetailSurvey activeFeatures={activeFeatures} surveysDetail={surveys?.active} handleGetResults={handleGetResults} /> : <EmptyComponent label={'SELECCIONAR ENCUESTA'} Icon={<SelectSurveyIcon />} />
              }
            </Col>
          </Row>
          <MainModalSurveys />
          {surveys?.active && <ResumeSurvey getActiveSurveys={getActiveSurveys} dispatch={dispatch} chartData={chartData} downloadSurvey={downloadSurvey} surveysDetail={surveys?.active} handleStatusModal={handleStatusModal} modalVisible={modalVisible} opcionesGrafica={opcionesGrafica} />}
        </CustomSpin>
      </section>
    </>
  )
}
