import React, { useState } from "react";
import {
  Form,
  Row,
  DatePicker,
  Space,
  TimePicker,
  Button,
} from "antd";
import CustomModal from "../../../../../../../UI/CustomModal";

const WorkDayModalTask = ({ onCancel, isOpenModal, setWorkDay }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString);
  };

  const handleTimeChange = (time, timeString) => {
    setSelectedTime(timeString);
  };

  const handleSave = () => {
    setWorkDay((prev) => [...prev, { date: selectedDate, time: selectedTime }]);
    onCancel();
  };

  const disabledDate = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return current && current.valueOf() < today.valueOf();
  }

  return (
    <CustomModal destroyOnClose onCancel={onCancel} isOpenModal={isOpenModal}>
      <h3
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "22px",
          marginBottom: "2rem",
          color: "#232C4A",
        }}
      >
        ASIGNAR JORNADA
      </h3>
      <Form className="standard-form">
        <Form.Item>
          <Row justify="center">
            <Space direction="vertical">
              <label style={{ color: "#676767", fontSize: "14px" }}>
                SELECCIONAR FECHA
              </label>
              <DatePicker
              disabledDate={disabledDate}
                style={{
                  width: "18rem",
                  borderRadius: "5px",
                  background: "#F6F6F6",
                }}
                placeholder="SELECCIONAR"
                onChange={handleDateChange}
              />
            </Space>
          </Row>
        </Form.Item>
        <Form.Item name="time">
          <Row justify="center">
            <Space direction="vertical">
              <label style={{ color: "#676767", fontSize: "12px" }}>
                SELECCIONAR HORA
              </label>
              <TimePicker.RangePicker
              format={"HH:mm"}
                style={{
                  width: "18rem",
                  background: "#F6F6F6",
                  borderRadius: 5,
                }}
                onChange={handleTimeChange}
              />
            </Space>
          </Row>
        </Form.Item>
        <Row justify="center" style={{ marginTop: "3rem" }}>
          <Button
            className="btn-standard-green"
            shape="round"
            type="ghost"
            onClick={handleSave}
          >
            <span>GUARDAR</span>
          </Button>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default WorkDayModalTask;
