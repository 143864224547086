import { Button, Image } from "antd"
import { CustomSpin } from "../../../UI/CustomSpin"
import CustomDrawer from "../../../UI/CustomDrawer"
import { getPhoneFormat } from "../../../../helpers/getPhoneFormat"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import defaultUserImage from '../../../../assets/icons_png/avatar_user.png'
import { EMPLOYEE_CODES } from "../../../../constants/modules/employee/employeeCodes"
// IMAGENES O ARCHIVOS
import { CloseOutlined } from "@ant-design/icons"
import { ReactComponent as PhoneIcon} from '../../../../assets/icons/Generals/BuildingMapFlow/phoneIcon.svg'

const ProfileDrawer = ({ isVisible, onClose, openProfileOpt, employeeSelected, activeFeatures }) => {
  const { EMPLOYEE_DEPARTMENT, EMPLOYEE_CONTRACT_TYPE, EMPLOYEE_POSITION, providerName, statusId, PROFILE } = employeeSelected

  const optionsOffBoarding = [
    {

      sectionId: 1,
      disabled: !activeFeatures?.find(feature => feature.feature_code === EMPLOYEE_CODES.EMPLOYEE_INABILITY_EMPLOYEE)?.enabled,
      popoverDesc: statusId === 2 ? "Activar empleado" : "Incapacitar empleado",
      name: statusId === 2 ? "ACTIVAR" : "INCAPACITAR",
    },
    {
      sectionId: 2,
      disabled: !activeFeatures?.find(feature => feature.feature_code === EMPLOYEE_CODES.EMPLOYEE_FIRE_EMPLOYEE)?.enabled,
      popoverDesc: 'Dar de baja',
      name: "DAR DE BAJA",
    },
  ]

  return (
    <CustomDrawer
      isOpenDrawer={isVisible}
      onClose={onClose}
      title="DATOS DE EMPLEADO"
      headerStyle={{ textAlign: "center" }}
      bodyStyle={{ padding: "1rem 3rem" }}
      className="user-wrapper"
      closeIcon={<CloseOutlined style={{ color: "var(--primary)" }} />}
    >
      {!PROFILE ? <CustomSpin size="large" /> :
      <>
        <header className="user-header">
          <Image
            className="user-profile"
            src={PROFILE?.PROFILE_DETAIL?.image ?? defaultUserImage}
            alt="foto de perfil"
          />
          <h2>{PROFILE?.PROFILE_DETAIL?.firstName} {PROFILE?.PROFILE_DETAIL?.lastName}</h2>
          <div className="user-header-phone">
            <PhoneIcon />
            <span>+{PROFILE?.phoneArea} {getPhoneFormat(PROFILE?.phoneNumber)}</span>
          </div>
        </header>

        <section className="user-info">
          <div className="info-item">
            <span>DEPARTAMENTO:</span>
            <h4>{EMPLOYEE_DEPARTMENT?.COMPANY_DEPARTMENT?.name}</h4>
          </div>

          <div className="info-item">
            <span>CARGO:</span>
            <h4>{EMPLOYEE_POSITION?.COMPANY_POSITION?.name}</h4>
          </div>

          <div className="info-item">
            <span>TIPO DE CONTRATO:</span>
            <h4>{EMPLOYEE_CONTRACT_TYPE?.COMPANY_CONTRACT_TYPE?.name}</h4>
          </div>

          <div className="info-item">
            <span>EMPRESA DE SEGURIDAD:</span>
            <h4>{providerName ?? "No hay empresa asignada"}</h4>
          </div>
        </section>

        <div className="btns-container">
          {optionsOffBoarding.map((opt) => (
            <CustomPopoverConfirm trigger={'hover'} content={`${opt.disabled ? 'No tienes permiso para realizar esta acción' : opt.popoverDesc}`}>
              <Button
                disabled={opt.disabled}
                onClick={() => openProfileOpt(opt.sectionId)}
                key={opt.sectionId}
              >
                {opt.name}
              </Button>
            </CustomPopoverConfirm >
          ))}
        </div>
      </>}

    </CustomDrawer>
  )
}

export default ProfileDrawer
