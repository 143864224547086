import axios from "axios"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"
import { API_URL } from "../../../../config"

const getAllSpaces = async ({
  companyId,
  idToken,
  profileType = 'titular',
  find_value,
}) => {
  try {
    const isFindValue = find_value ? `&find_value=${find_value}` : ''
    const url = `${API_URL}place/available?company_id=${companyId}&profile_type=${profileType}${isFindValue}`
   
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${idToken}`,
      timeout: 20000 }
    })

  // Se prepara y moldea la data para que el customComponent Select trabaje sin problema
  const { data } = response
  const newResponse = {
    ...response,
    data: {
      ...response.data,
      data: {
        dataSource: data?.data?.dataSource.map((place) => ({ id: place.place_id, name: place.name })),
      }
    }
  }

  return verifyBodyResponse(newResponse,true)
  } catch (error) {
      return verifyBodyResponse(error, false)
  }
}

export default getAllSpaces
