import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const accessProvidersPath = 'event/provider-access'

export const deleteAccessProvider = async ({
    token,
    event_id
}) => {
    try {
        const params = {
            event_id
        };

        const response = await axiosCreate.delete(`${accessProvidersPath}`, {
            params, timeout: 10000, headers: {
                Authorization: `Bearer ${token}`,
            },
            data: params
        });

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
}