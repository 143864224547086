import { useContext } from "react"
import { toast } from "react-toastify"
import { Button, Col, Row } from "antd"
import { AmenitiesContext } from "../../../../context/AmenitiesContext"
import { CustomInput } from "../../../../../../UI/CustomInput"
import { useAppSelector } from "../../../../../../../hooks/store"

const CostSettings = ({ costData }) => {
  const { costs, setCosts, currentValueRadio, currentQuantityInvitees } = useContext(AmenitiesContext)
  const { currencySymbol } = useAppSelector(state => state.auth)
  const rangeStart = costData.range_start ? costData.range_start.toString() : ''
  const rangeEnd = costData.range_end ? costData.range_end.toString() : ''
  const rangeCost = costData.range_cost ? costData.range_cost.toString() : ''

  // Contaste para validar que el boton de agregar solo aparezca junto al elemento final del arreglo
  const lastIndex = costs.length

  // Funcion encargada de solamente agregar un nuevo elemento tomando el id del anterior elemento y sumandole uno
  const handleAddRange = () => {
    setCosts(state => [...state, {
      id: costData.id + 1,
      range_start: 0,
      range_end: 0,
      range_cost: 0,
    }])
  }

  // La key que se le manda en la funcion onblur es para saber a que propiedad del objeto modificar
  // es decir, range start, end o cost
  const handleOnBlur = (event, key) => {
    let objFillTypes = {
      1: () => {
        const newArrayData = costs.map(cost => {
          if (cost.id === costData.id) return ({ ...cost, range_start: event.target.value })

          return cost
        })
        setCosts(newArrayData)
      },
      2: () => {
        const newArrayData = costs.map(cost => {
          if (cost.id === costData.id) return ({ ...cost, range_end: event.target.value })

          return cost
        })
        setCosts(newArrayData)
      },
      3: () => {
        const newArrayData = costs.map(cost => {
          if (cost.id === costData.id) return ({ ...cost, range_cost: event.target.value })

          return cost
        })
        setCosts(newArrayData)
      },
      'default': () => toast.warning("Algo salió mal, intenta recargar la página")
    }
    let objFillType = objFillTypes[key] || objFillTypes['default']

    return objFillType()
  }

  return (
    <Row key={costData.id} style={{ marginTop: 15, marginInline: 20, opacity: currentValueRadio !== 2 ? 0.5 : 1, cursor: currentValueRadio !== 2 ? 'not-allowed' : '' }}>
      <Col span={24}>
        <label style={{ fontWeight: 400, fontSize: 14 }}>
          Rango #{costData.id}
        </label>

        <Row justify="center" style={{ border: '1px solid #999999', padding: 25, borderRadius: 8, marginTop: 10 }} gutter={16} align="middle">
          <Col span={6}>
            <label style={{ fontWeight: 400, fontSize: 12, color: '#404040' }}>
              DESDE
            </label>

            {/* El maximo debe de ser la cantidad de invitados que se especificaron en el formulario */}
            <CustomInput placeholder={'Cantidad mínima'} nullInputNumberText={null} type="NUMBER" min={1} max={parseInt(currentQuantityInvitees)} precision={0} disabled={currentValueRadio === 1} defaultValue={rangeStart} className="custom-input-amenity" onBlur={(e) => handleOnBlur(e, 1)} />
          </Col>

          <Col span={6}>
            <label style={{ fontWeight: 400, fontSize: 12, color: '#404040' }}>
              HASTA
            </label>

            <CustomInput placeholder={'Cantidad máxima'} nullInputNumberText={null} type="NUMBER" min={1} max={parseInt(currentQuantityInvitees)} precision={0} disabled={currentValueRadio === 1} defaultValue={rangeEnd} className="custom-input-amenity" onBlur={(e) => handleOnBlur(e, 2)} />
          </Col>

          <Col span={4}>
            <Row justify="center">
              <label style={{ fontWeight: 400, fontSize: 12, color: '#404040', textAlign: 'center' }}>
                invitados
              </label>
            </Row>
          </Col>

          <Col span={8}>
            <label style={{ fontWeight: 400, fontSize: 12, color: '#404040' }}>
              PRECIO POR RANGO
            </label>
            <CustomInput placeholder={'Define el precio'} prefix={currencySymbol} nullInputNumberText={null} type="NUMBER" min={1} precision={2} step={0.01} maxLength={10} disabled={currentValueRadio === 1} defaultValue={rangeCost} className="custom-input-amenity" onBlur={(e) => handleOnBlur(e, 3)} />
          </Col>
        </Row>

        {lastIndex === costData.id && <Row justify="center">
          <Button
            type="text"
            shape="round"
            disabled={currentValueRadio !== 2}
            htmlType="button"
            className="btn-standard-white"
            style={{ width: 229, marginTop: 20 }}
            onClick={handleAddRange}
          >
            <span>AGREGAR RANGO</span>
          </Button>
        </Row>}
      </Col>
    </Row>
  )
}

export default CostSettings