import { Button, Col, Row } from "antd"
import { useContext, useEffect } from "react"
import useChecklists from "../../hooks/useChecklists"
import { CustomNavigate } from "../../../../UI/CustomNavigate"
import CustomPopoverConfirm from "../../../../UI/CustomPopoverConfirm"
import { ChecklistsAdminContext } from "../../context/ChecklistsAdminContext"
import { HANDOVER_CHECKLIST_CODES } from "../../../../../constants/modules/handoverChecklist/handoverChecklistCodes"

// imagenes o archivos
import { PlusCircleOutlined } from "@ant-design/icons"

const HeaderChecklistsAdmin = ({ activeFeatures }) => {
  const { getInitialData } = useChecklists()
  const { handleOpenCloseModal, setChecklistAdminClicked, onReset } = useContext(ChecklistsAdminContext)

  const isCreationAllowed = !activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_CREATE_TYPE_CHECKLIST)?.enabled

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    await getInitialData()
  }

  return (
    <CustomNavigate title="CHECKLISTS">
      <CustomPopoverConfirm
        trigger={'hover'}
        content={isCreationAllowed ? 'No tienes permiso para realizar esta acción' : 'Nuevo tipo de checklist'}
      >
        <Button
          type="text"
          shape="round"
          htmlType="button"
          onClick={() => {
            onReset()
            setChecklistAdminClicked({})
            handleOpenCloseModal()
          }}
          disabled={isCreationAllowed}
          className="btn-standard-green"
        >
          <Row gutter={8}>
            <Col>
              <span>NUEVO CHECKLIST</span>
            </Col>

            <Col>
              <PlusCircleOutlined style={{ fontSize: 14, lineHeight: 0 }} />
            </Col>
          </Row>
        </Button>
      </CustomPopoverConfirm>
    </CustomNavigate>
  )
}

export default HeaderChecklistsAdmin