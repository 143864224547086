import { Image, message } from "antd";
import { useEffect, useRef } from "react";
import { mimeTypesToText } from "../mocks/mimeTypes";
import { typeFiles } from "../mocks/typeFiles";

const useUploadFile = ({ fileList, setFileList, justImages, maxCount, maxSizeInBytes, multipleFiles, acceptFilesFormat, progressFile = false }) => {

    const uploadElementRef = useRef(null)
    const acceptFiles = justImages ? 'image/jpg, image/jpeg,image/png' : acceptFilesFormat ? acceptFilesFormat : 'image/jpg, image/jpeg,image/png, application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint'

    const getFileSize = (size, fixed = 2) => {
        if ((size / 1024) < 1000) return `${(size / 1024).toFixed(fixed)}KB`
        return `${(size / 1024 / 1024).toFixed(fixed)}MB`
    }

    const updateUploadProgress = () => {
        setFileList((prevList) => prevList.map((file) => {
            if (file.status === 'uploading') {
                const currentPercent = file.percent || 0;
                const fileSizeMB = file.size / (3072 * 3072); //El numero es para poder incrementar o disminuir la velocidad de la "subida" del archivo
                const increment = (Math.random() * (4) + 1) / fileSizeMB; // Incremento inversamente proporcional al tamaño del archivo
                const newPercent = Math.min(currentPercent + increment, 100);
                if (newPercent >= 100) {
                    return { ...file, status: 'done', percent: 100 };
                }
                return { ...file, percent: newPercent };
            }
            return file;
        }));
    };

    useEffect(() => {
        if (fileList?.some(file => file.status === 'uploading') && progressFile) {
            const interval = setInterval(updateUploadProgress, 500);
            return () => clearInterval(interval);
        }
    }, [fileList]);

    const acceptFilesText = mimeTypesToText(acceptFiles)
    const handleOnChange = (event) => {

        let newFiles = event?.target?.files ? Array.from(event?.target?.files) : event?.fileList ? event?.fileList.map((item) => ({ ...item, title: item?.title ? item.title : item.name, description: item?.description ? item.description : 'Descipción de la imagen' })) : undefined

        if (!newFiles) return
        if ((fileList.length === maxCount) || (!event?.target?.files && event.fileList.length > maxCount) || (event?.target?.files && newFiles.length + fileList.length > maxCount)) return message.error('La cantidad de archivos es igual o mayor a la permitida')
        newFiles = [...newFiles].filter((file) => {
            if (file.size > maxSizeInBytes) {
                message.error(`El archivo ${file.name} pesa más de ${getFileSize(maxSizeInBytes, 0)}`)
                return false
            }
            if (!acceptFiles.includes(file.type)) {
                message.error(`El archivo ${file.name} no tiene un formato permitido`)
                return false
            }
            return true
        })
        if (!newFiles.length) return
        if (event?.target?.files) {
            newFiles = newFiles?.map((file) => ({
                uid: crypto.randomUUID(),
                title: file.name,
                description: 'Descripcion de la imágen',
                name: file.name,
                status: 'uploading',
                percent: 0,
                size: file.size,
                type: file.type,
                originFileObj: file,
                lastModified: file.lastModified,
                lastModifiedDate: file.lastModifiedDate
            }))
        }
        if (multipleFiles) {
            event?.target?.files ? setFileList((prev) => [...prev, ...newFiles]) : setFileList(newFiles)
        }
        else {
            setFileList([newFiles.at(-1)])
            uploadElementRef.current.value = ''
        }
        //Añadir alguna funcion que actualice el estado de uploading a done de todos los archivos guardados en el estado local
    }

    const handleRemoveFile = (file) => () => {
        setFileList((prev) => {
            const previousFiles = [...prev]
            return previousFiles.filter((prevFile) => prevFile.uid !== file.uid)
        })
        uploadElementRef.current.value = ''
    }

    const handleOpenDialogFile = () => {
        uploadElementRef.current.click();
    }

    //Funcion para poder modificar el texto y descripción de una imagen
    const handleTextChange = ({ id, type, value }) => {
        setFileList((prevList) => {
            const newList = [...prevList]
            return newList.map((file) => {
                if (file.uid === id) {
                    return { ...file, [type]: value }
                }
                return file;
            })
        })
    }

    //Funcion para renderizar el icono del archivo subido, o una imagen si el tipo de archivo coincide 
    const itemRender = (file) => {
        if (file.type.startsWith('image/')) {
            return (
                <Image
                    className='uploaded-file__image'
                    src={file.originFileObj ? URL.createObjectURL(file.originFileObj) : file.url}
                    alt={file.name}
                />
            )
        }
        const fileExtension = file.name.split('.').pop().toLowerCase();
        return typeFiles[fileExtension] || typeFiles.default
    }

    return (
        {
            uploadElementRef,
            acceptFiles,
            acceptFilesText,
            getFileSize,
            handleOnChange,
            handleOpenDialogFile,
            handleRemoveFile,
            handleTextChange,
            itemRender
        }
    )
}

export default useUploadFile