import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import verifyResponse from "../../../../../helpers/verifyResponse";
import {
  createNewUserHolder,
  getAvailableHouses,
  getResidentialDataByUserLogged,
  getResidentialDataTableByUser,
} from "../services";

const useUser = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({});
  const [housesData, setHousesData] = useState([]);
  const [residentialData, setResidentialData] = useState({});

  const { committee } = useSelector((state) => state.auth);

  const onSaveNewUserHolder = async (formData) => {
    const response = await createNewUserHolder(formData);

    return verifyResponse(
      response.data,
      response.status,
      "Nuevo usuario creado con exito."
    );
  };

  useEffect(() => {
    getResidentialData(committee.residential_id);
    getResidentialTableData(committee.residential_id);
  }, []);

  const getResidentialData = async (residentialId) => {
    setLoading(true);
    const response = await getResidentialDataByUserLogged(residentialId);

    if (response.success) {
      setResidentialData(response.data.data);
      setLoading(false);
      return;
    }

    setLoading(false);
    return verifyResponse(response.data, response.status, "");
  };

  const getAvailablesHouses = async (residentialId) => {
    const response = await getAvailableHouses(residentialId);

    if (response.success) return setHousesData(response.data.data);

    return verifyResponse(response.data, response.status, "");
  };

  const getResidentialTableData = async (residentialId) => {
    const response = await getResidentialDataTableByUser(residentialId);

    if (response.success) return setTableData(response.data.data);

    return verifyResponse(response.data, response.status, "");
  };

  return {
    loading,
    tableData,
    housesData,
    residentialData,
    getResidentialData,
    getAvailablesHouses,
    onSaveNewUserHolder,
    getResidentialTableData,
  };
};

export default useUser;
