import { getAllDocuments } from '../services/GET/getAllDocuments'
import { getAllPublishedDocuments } from '../services/GET/getAllPublishedDocuments'
import { createNewDocument } from '../services/POST/createNewDocument'
import { deleteDocument } from '../services/DELETE/deleteDocument'
import { useLibraryContext } from '../context/LibraryContext'
import verifyResponse from '../../../helpers/verifyResponse'
import { getTableDataHelper } from '../../../helpers/getTableDataHelper'

const useLibraryServices = () => {

    const { auth } = useLibraryContext()

    const getAllDocumentsService = async ({ limit, skip, sort_by, sort_order, find_by, find_value, setLoading, previousData, last, currentPage, actualLimit }) => {
        const res = await getAllDocuments({ token: auth.idToken, company_id: auth.currentRol, limit, skip, sort_by, sort_order, find_by, find_value })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        if (!status) {
            setLoading && setLoading(false)
            throw new Error('Error in getAllDocumentsService')
        }
        const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata, skip, previousData, last, currentPage, actualLimit })
        return { data, metadata }
    }

    const getAllPublishedDocumentsService = async ({ limit, skip, sort_by, sort_order, find_by, find_value, setLoading, previousData, last, currentPage, actualLimit }) => {
        const res = await getAllPublishedDocuments({ token: auth.idToken, company_id: auth.currentRol, limit, skip, sort_by, sort_order, find_by, find_value })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        if (!status) {
            setLoading && setLoading(false)
            throw new Error('Error in getAllPublishedDocumentsService')
        }
        const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata, skip, previousData, last, currentPage, actualLimit })
        return { data, metadata }
    }

    const createNewDocumentService = async ({ formData, setLoading }) => {
        const res = await createNewDocument({ token: auth.idToken, formData })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, message: 'Archivo subido con éxito' })
        if (!status) {
            setLoading && setLoading(false)
            throw new Error('Error in createNewDocumentService')
        }
        return res.data.data
    }

    const deleteDocumentService = async ({ document_id, setLoading }) => {
        const res = await deleteDocument({ token: auth.idToken, document_id })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, message: 'Archivo eliminado con éxito' })
        if (!status) {
            setLoading && setLoading(false)
            throw new Error('Error in deleteDocumentService')
        }
    }

    return {
        getAllDocumentsService,
        getAllPublishedDocumentsService,
        createNewDocumentService,
        deleteDocumentService,
    }

}

export default useLibraryServices