import { Col } from "antd"
import { ActivityItem } from "./components/ActivityItem"

export const CategoryInfo = ({ dailyTasks, dateDaily }) => {

    return (
        <Col className='monitoring__wrapper' span={11}>
            <h3 style={{ alignSelf: 'start', marginBottom: 24 }}>CATEGORÍAS</h3>
            {
                !dailyTasks?.categories?.length &&
                <p style={{ textAlign: 'center' }}>No hay categorías para mostrar</p>
            }
            {
                dailyTasks?.categories?.map((category) => (
                    <ActivityItem key={category.id} category_id={category.id} name={category.name} completed={category.completed} total_tasks={category.total_tasks} filter_by={dateDaily} />
                ))
            }
        </Col>
    )
}