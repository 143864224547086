import React from "react";
import { useSelector } from "react-redux";
import InventoryModal from "./modal/InventoryModal";
import useOpenCloseModal from "../../../hooks/useOpenCloseModal";
import WorkDay from "./WorkDay";
import { Row, Col, Divider, Button } from "antd";
import UnapprovedRequirements from "./UnapprovedRequirements";
import { useContext } from "react";
import { CountDownContext } from "../../../../context/CountDownContext";
import EditIcon from "../../../../assets/icons/EditIcon";
import moment from "moment";
import { TICKETS_CODES } from "../../../../constants/modules/tickets/ticketsCodes";
import { useAppSelector } from "../../../../hooks/store";
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm";

const InspectionsDetails = () => {
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal();
  const { ticketById, flags } = useSelector((state) => state.detailTicketById);
  const { handleIsOpenCloseModal } = useContext(CountDownContext);
  const handleModalOpen = () => {
    handleOpenCloseModal();
    handleIsOpenCloseModal();
  };
  const handleModalClose = () => {
    handleIsOpenCloseModal();
    handleOpenCloseModal();
  };

  const { activeFeatures } = useAppSelector(state => state.roles)
  // Acceso a los valores de ticketById
  const inspectionData = ticketById?.TICKET_INSPECTION || [];
  const inspectionDate =
    moment(new Date(inspectionData?.created_at)).format('DD/MM/YYYY [-] h:mm a') || "";
  const inspectionDescription = inspectionData?.description || "";
  const inspectionSupplies = inspectionData?.TICKET_INSPECTION_SUPPLies || [];
  const inspectionEquipment =
    inspectionData?.TICKET_INSPECTION_EQUIPMENTs || [];
  const expectedCost = inspectionData?.expectedCost || 0;

  const currency =
    ticketById?.COMPANY?.COMPANY_DETAIL?.COMPANY_CURRENCY?.symbol;

  return (
    <div style={{ marginTop: "1rem" }}>
      <Row align="middle">
        <Divider orientation="left" orientationMargin={0} className="detail-ticket__divider">
          <h4>DETALLE DE INSPECCIÓN (REQUERIMIENTO ORIGINAL): </h4>
        </Divider>

      </Row>

      <p style={{ fontWeight: "bold" }}>
        Fecha:
        <span style={{ marginLeft: "1rem", fontWeight: 500 }}>({inspectionDate})</span>
      </p>

      <p style={{ fontWeight: 500 }}>Notas del trabajador</p>
      <p style={{ fontSize: "14px" }}>{inspectionDescription}</p>

      {ticketById?.TICKET_INSPECTION?.TICKET_INSPECTION_STATUS?.code ===
        "accepted" && <UnapprovedRequirements />}
      {ticketById?.TICKET_INSPECTION?.TICKET_INSPECTION_STATUS?.code ===
        "accepted" && (
          <Row style={{ marginTop: "2rem" }} align="middle">
            <Divider orientation="left" orientationMargin={0} className="detail-ticket__divider">
              <h4>REQUERIMIENTO APROBADO </h4>
            </Divider>
          </Row>
        )}
      <Row
        justify="space-around"
        style={{
          border: "0.55mm dashed #afafaf",
          padding: "1rem",
          background: "#F5F5F5",
          borderRadius: "10px",
          position: "relative",
        }}
      >
        <Col>
          <p style={{ fontWeight: "bold" }}>Insumos solicitados:</p>
          {ticketById?.TICKET_INSPECTION?.TICKET_INSPECTION_STATUS?.code ===
            "accepted"
            ? ticketById?.TICKET_SUPPLies.map((item) => (
              <Row key={item?.id}>
                <p
                  className="detail-ticket__requirement-item"
                >
                  {item?.quantity} {item?.Supply.name} | {currency}{" "}
                  {item?.totalCost.toFixed(2)}
                </p>
              </Row>
            ))
            : inspectionSupplies.map((item) => (
              <Row
                style={{
                  color:
                    item?.TICKET_INSPECTION_SUPPLY_STATUS?.code === "deleted"
                      ? "#BBBBBB"
                      : "#000",
                  textDecoration:
                    item?.TICKET_INSPECTION_SUPPLY_STATUS?.code === "deleted"
                      ? "line-through"
                      : "none",
                }}
                key={item?.id}
              >
                <p
                  className="detail-ticket__requirement-item"
                >
                  {item?.quantity} {item?.Supply.name} | {currency}{" "}
                  {item?.totalCost.toFixed(2)}
                </p>
              </Row>
            ))}
        </Col>
        <Divider className="detail-ticket__divider" type="vertical" />
        <Col>
          <p style={{ fontWeight: "bold" }}>Equipo solicitado:</p>
          {ticketById?.TICKET_INSPECTION?.TICKET_INSPECTION_STATUS?.code ===
            "accepted"
            ? ticketById?.TICKET_EQUIPMENTs.map((item) => (
              <Row key={item?.id}>
                <p
                  className="detail-ticket__requirement-item"
                >
                  {item?.quantity} {item?.Equipment.name}
                </p>
              </Row>
            ))
            : inspectionEquipment.map((item) => (
              <Row
                style={{
                  color:
                    item?.TICKET_INSPECTION_EQUIPMENT_STATUS?.code ===
                      "deleted"
                      ? "#BBBBBB"
                      : "#000",
                  textDecoration:
                    item?.TICKET_INSPECTION_EQUIPMENT_STATUS?.code ===
                      "deleted"
                      ? "line-through"
                      : "none",
                }}
                key={item?.id}
              >
                <p
                  key={item?.id}
                  className="detail-ticket__requirement-item"
                >
                  {item?.quantity} {item?.Equipment.name}
                </p>
              </Row>
            ))}
        </Col>
        <Divider className="detail-ticket__divider" type="vertical" />
        <Row style={{ marginRight: "2rem" }} align="middle">
          <Col>
            <p style={{ fontWeight: "bold" }}>Costo Total</p>
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>
              {currency} {expectedCost.toFixed(2)}
            </p>
          </Col>
        </Row>
        {flags?.enabledButtonApproveInventory && (
          <div >
            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_UPDATE_REQUESTED_INVENTORY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Editar'}`}>
              <Button
                disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_UPDATE_REQUESTED_INVENTORY)?.enabled}
                onClick={handleModalOpen}
                style={{
                  position: "absolute",
                  right: "1rem",
                  top: "1rem",
                  border: "none",
                }}
                type="ghost"
              >
                <EditIcon color="var(--primary)" width="20px" />
              </Button>
            </CustomPopoverConfirm>
          </div>
        )}
      </Row>

      <div
        style={{
          border: "0.6mm dashed #afafaf",
          padding: "1rem",
          background: "#F5F5F5",
          borderRadius: "10px",
          marginBlock: "2rem",
          position: "relative",
        }}
      >
        <WorkDay />
      </div>
      <InventoryModal isOpenModal={isOpenModal} onCancel={handleModalClose} />
    </div>
  );
};

export default InspectionsDetails;
