import { useState } from "react"
import { Button, Form } from "antd"
import useCheckList from "../hooks/useCheckList"
import { CustomSpin } from "../../../UI/CustomSpin"
import { CustomSelect } from "../../../UI/CustomSelect"
import useRedirectTo from "../../../hooks/useRedirectTo"
import { CustomNavigate } from "../../../UI/CustomNavigate"
import NewTaskReception from "./components/NewTaskReception"
import EditTaskModal from "./components/modal/EditTaskModal"
import CustomTable from "../../../UI/CustomTable/CustomTable"
import { useFeatureRole } from "../../../hooks/useFeatureRole"
import useCheckListCategory from "../hooks/useCheckListCategory"
import useOpenCloseModal from "../../../hooks/useOpenCloseModal"
import useCurrentLocation from "../../../hooks/useCurrentLocation"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import { HANDOVER_CHECKLIST_CODES, HANDOVER_CHECKLIST_CODES_NAMES } from "../../../../constants/modules/handoverChecklist/handoverChecklistCodes";

// IMAGENES O ARCHIVOS
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-icon.svg";

import "./style.css"

const ReceptionCheck = () => {
  const [visible, setVisible] = useState(false)
  const [editTask, setEditTask] = useState({})
  const [categoryFilter, setCategoryFilter] = useState("")
  
  const hookCheckList = useCheckList()
  const { handleRedirectTo } = useRedirectTo()

  const { param } = useCurrentLocation()
  const { getAllCategoryChecklistToSelect } = useCheckListCategory()
  
  const { form, isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  
  const { checkItems, loading, setLoading, getAllCheckListItems, metadata, onDeleteItemChecklist } = hookCheckList
  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: HANDOVER_CHECKLIST_CODES, validate_code: HANDOVER_CHECKLIST_CODES_NAMES.HANDOVER_CHECKLIST_MANAGMENT_HANDOVER, returnUrl: '/checklist-entrega' })
  
  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />
  
  const disabledNewTaskFeature = !activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_NEW_TASK)?.enabled
  
  const { columns = [] } = checkItems
  const { limit = 10, quantity = 0} = metadata

  const handleCategoryChange = async (value) => {
    if (param.checklistId) {
      await getAllCheckListItems(param.checklistId, value)
      setCategoryFilter(value)
    }
  }

  const handleEditTask = (currentEditTask) => {
    setEditTask(currentEditTask)

    form.setFieldsValue({ task: currentEditTask.task })

    handleOpenCloseModal()
  }

  const handleDeleteItem = async (idTask) => {
    setLoading(true)

    const dataToSend = {
      checklist_id: param.checklistId,
      checklist_item_id: idTask,
    };
    
    const response = await onDeleteItemChecklist(dataToSend)

    if (response) {
      await getAllCheckListItems(param.checklistId)
    }
    
    setLoading(false)
  }

  const handlePagination = async (currentPage, limit) => {
    setLoading(true)
    
    const pageValue = currentPage
    const newSkip = (pageValue * limit) - limit
    
    if (param.checklistId) {
      await getAllCheckListItems(param.checklistId, categoryFilter, newSkip)
    }

    setLoading(false)
  }

  const onVisibleChange = (newOpen) => setVisible(newOpen)

  const newColumns = [
    ...columns,
    {
      dataIndex: "actions",
      title: "",
      width: 125,
      render: (_, record) => (
        <div className="actions-category">
          <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_UPDATE_TASK)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Editar tarea'}`}>
            <Button
              disabled={!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_UPDATE_TASK)?.enabled}
              onClick={() => handleEditTask(record)}
              type="text"
              size="small"
              icon={<EditIcon />}
            />
          </CustomPopoverConfirm>
          <CustomPopoverConfirm trigger={'hover'} placement={'left'} content={`${!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_DELETE_TASK)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Eliminar tarea'}`}>
            {'' /**Si no se pone este espacio, no detecta el custompopover que esta aqui dentro */}
            <CustomPopoverConfirm
              disabled={!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_DELETE_TASK)?.enabled}
              popConfirm
              onConfirm={() => handleDeleteItem(record.id)}
              overlayClassName={"delete-category"}
              title={"¿Desea eliminar esta tarea?"}
              okText={"ELIMINAR"}
              cancelText={"CANCELAR"}
              placement={"bottomRight"}
            >
              <Button
                type="link"
                htmlType="button"
                size="small"
                icon={<TrashIcon />}
                disabled={!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_DELETE_TASK)?.enabled}
              />
            </CustomPopoverConfirm>
          </CustomPopoverConfirm>
        </div>
      ),
    },
  ]

  return (
    <section className="section-wrapper">
      <header className="check-header header-reception" style={{ flexDirection: 'column' }}>
        <CustomNavigate title={'ADMINISTRACIÓN DE CHECKLIST'}>
          <div className="check-header-btns">
            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_MANAGEMENT_CATEGORIES)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Categorías de Checklist de Entrega'}`}>
              <Button
                type="text"
                htmlType="button"
                shape="round"
                className="btn-standard-white"
                disabled={!activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_MANAGEMENT_CATEGORIES)?.enabled}
                onClick={() => handleRedirectTo(`/checklist-categorias/${param.checklistId}`)}
              >
                <span>CATEGORÍAS</span>
              </Button>
            </CustomPopoverConfirm>
            <CustomPopoverConfirm trigger={'hover'} content={`${disabledNewTaskFeature ? 'No tienes permiso para realizar esta acción' : 'Nueva tarea de checklist de Entrega'}`}>
              {'' /**Si no se pone este espacio, no detecta el custompopover que esta aqui dentro */}
              <CustomPopoverConfirm
                isPropVisible
                visible={visible}
                onVisibleChange={onVisibleChange}
                disabled={disabledNewTaskFeature}
                destroyTooltipOnHide
                overlayClassName={"task-reception-popover"}
                placement={"bottomRight"}
                trigger={disabledNewTaskFeature ? 'trigger' : 'click'}
                content={disabledNewTaskFeature ? 'No tienes permiso para realizar esta acción' : <NewTaskReception hookCheckList={hookCheckList} setVisible={setVisible} />}
              >
                <Button
                  type="text"
                  htmlType="button"
                  shape="round"
                  disabled={disabledNewTaskFeature}
                  className="btn-standard-green"
                >
                  <span>NUEVA TAREA</span>
                </Button>
              </CustomPopoverConfirm>
            </CustomPopoverConfirm>
          </div>
        </CustomNavigate>
        <div>
          <CustomSelect
            isAsync
            allowClear
            width="280px"
            onChange={handleCategoryChange}
            service={getAllCategoryChecklistToSelect}
            placeholder="Categoría"
          />
        </div>
      </header>

      <CustomTable
        rowKey={record => record.id}
        className="custom-table-variant-2"
        columns={newColumns ?? []}
        dataSource={checkItems?.dataSource ?? []}
        loading={loading}
        pagination={{
          pageSize: limit,
          total: quantity,
          onChange: async (currentPage) => await handlePagination(currentPage, limit),
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} de ${total}`;
          }
        }}
      />

      <EditTaskModal
        form={form}
        editTask={editTask}
        setEditTask={setEditTask}
        isOpenModal={isOpenModal}
        hookCheckList={hookCheckList}
        onCancel={handleOpenCloseModal}
      />
    </section>
  )
}

export default ReceptionCheck
