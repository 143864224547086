// Esta funcion se encarga de retornar la ruta completa de la opcion del customSelect seleccionado
export const getAllHerarchyPath = (tree, targetValue, path = []) => {
  for (const node of tree) {
    path.push(node.title)

    if (node.value === targetValue) {
        return path
    }

    if (node.children) {
      const result = getAllHerarchyPath(node.children, targetValue, path)
      if (result) return result
    }

    path.pop()
  }
  return null
}

// Esta funcion valida si en el nivel de jerarquias te valida que solamente tomes el primer nivel de jerarquia siempre
export const verifyFatherHierarchy = (allHierarchyData = []) => {
  const { hierarchyId } = allHierarchyData[0] ?? []
  const hierarchyChilren = allHierarchyData.filter((hierarchy) => hierarchy.hierarchyId === hierarchyId)
  return hierarchyChilren ?? []
}
