import { useSelector } from 'react-redux';
import { CustomSpin } from '../topia/UI/CustomSpin';
// import { typesAccounts } from '../topia/helpers/defaultPageHelper';
import { useAppSelector } from '../hooks/store';
import { THEME_ZONE_CODES } from '../constants/theme';

//Podría ser personalizado ?
export const CheckingAuth = ({ customMessage }) => {
  const { companyName, typeRol } = useSelector(state => state.auth);
  const { themeImages, themeColors } = useAppSelector(state => state.theme)
  const spinLogo = themeImages?.find(item => item.theme_zone_code === THEME_ZONE_CODES.COMPANY_LOGO)?.images.find?.(image => image.code === "1")?.image
  const spinColor = themeColors?.secondary?.secondary
  const message = `Cambiando a ${typeRol === 'mall' ? 'Centro Comercial' : 'Condominio'} ${companyName}`

  return <CustomSpin fullScreen icon={spinLogo ? spinLogo : undefined} indicatorColor={spinColor ? spinColor : undefined} customIcon spinning={true} size="large" tip={customMessage ? customMessage : companyName !== "" && companyName !== undefined ? message : undefined} />
}