export const ACCESS_CODES = {
    ACCESS_MODULE: 'FEAT-281',
    ACCESS_RESIDENTS: 'FEAT-79',
    ACCESS_VISITANTS: 'FEAT-80',
    ACCESS_SCHEDULE_VISITANTS: 'FEAT-83'
}

export const ACCESS_CODES_NAMES = {
    ACCESS_MODULE: 'ACCESS_MODULE',
    ACCESS_RESIDENTS: 'ACCESS_RESIDENTS',
    ACCESS_VISITANTS: 'ACCESS_VISITANTS',
    ACCESS_SCHEDULE_VISITANTS: 'ACCESS_SCHEDULE_VISITANTS'
}