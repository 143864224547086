
const CheckIcon = () => (
    <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
            d="M18.4615 10.226C18.4086 10.1993 18.3557 10.1861 18.3024 10.1861C18.1876 10.1861 18.0864 10.2301 17.9983 10.3184L17.1516 11.165C17.0724 11.2445 17.0327 11.3414 17.0327 11.4561V14.816C17.0327 15.3983 16.8259 15.8963 16.4111 16.311C15.9966 16.7255 15.4982 16.9327 14.9161 16.9327H3.90957C3.32741 16.9327 2.82908 16.7255 2.41461 16.311C2.00005 15.8964 1.79288 15.3983 1.79288 14.816V3.80973C1.79288 3.22766 2.00005 2.72937 2.41461 2.31477C2.82908 1.90025 3.32741 1.69304 3.90957 1.69304H14.9162C15.1103 1.69304 15.3087 1.7195 15.5115 1.77246C15.5644 1.78997 15.6041 1.79892 15.6302 1.79892C15.7451 1.79892 15.8465 1.75495 15.9347 1.66677L16.5829 1.01854C16.6886 0.91285 16.7285 0.784872 16.7018 0.63507C16.6753 0.493933 16.5959 0.392459 16.4636 0.330787C15.9876 0.110602 15.4715 0 14.9158 0H3.90957C2.86008 0 1.96265 0.372628 1.2174 1.11788C0.472237 1.86323 0.0996094 2.76051 0.0996094 3.81005V14.8167C0.0996094 15.866 0.472237 16.7634 1.21745 17.5086C1.9627 18.2541 2.86012 18.6267 3.90961 18.6267H14.9161C15.9655 18.6267 16.863 18.2541 17.6082 17.5086C18.3535 16.7634 18.7265 15.8661 18.7265 14.8167V10.6096C18.7264 10.4245 18.6378 10.2967 18.4615 10.226V10.226ZM21.7822 2.63253L20.3268 1.17718C20.1152 0.965571 19.8637 0.859741 19.5725 0.859741C19.2818 0.859741 19.0301 0.965571 18.8186 1.17718L10.2593 9.73645L6.78027 6.25719C6.56856 6.04544 6.31715 5.93975 6.02621 5.93975C5.73522 5.93975 5.4839 6.04544 5.27215 6.25719L3.81699 7.71235C3.60533 7.92401 3.49941 8.17542 3.49941 8.46654C3.49941 8.75753 3.60533 9.00885 3.81699 9.2206L9.50542 14.909C9.71713 15.1208 9.96854 15.2265 10.2595 15.2265C10.5504 15.2265 10.8018 15.1209 11.0135 14.909L21.782 4.1406C21.9935 3.92899 22.0996 3.67752 22.0996 3.38654C22.0996 3.09555 21.9938 2.84414 21.7822 2.63253V2.63253Z"
            fill="var(--primary)" />
    </svg>
)

export default CheckIcon
