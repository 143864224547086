import { Button, Col, Divider, Row } from "antd"
import CustomDrawer from "../../../UI/CustomDrawer"
import { CloseOutlined } from "@ant-design/icons"
import { formatUTCDateInTimeZone } from "../../../helpers/customDateHelpers"
import useRedirectTo from "../../../hooks/useRedirectTo"
import { setCurrentOption } from "../../../../store/detailContracts/detailContractSlice"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { getAllMapFiles, getAllTicketsByPlace, getContractsFiles, getDetailPlaceTaxData, getPersonHoodTypes, getTypesContract } from "../../../../store/detailContracts/thunks"
import BuildingIcon from "../../../../assets/icons/Generals/BuildingIcon"
import VisionEyeIcon from "../../../../assets/icons/Generals/BuildingMapFlow/VisionEyeIcon"
import ReportIcon from "../../../../assets/icons/Generals/BuildingMapFlow/ReportIcon"
import PhoneIcon from "../../../../assets/icons/Generals/BuildingMapFlow/PhoneIcon"
import IdCardIcon from "../../../../assets/icons/Generals/BuildingMapFlow/IdCardIcon"
import CheckIcon from "../../../../assets/icons/Generals/BuildingMapFlow/CheckIcon"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import { MANAGEMENT_CONTRACTORS_CODES } from "../../../../constants/modules/managamentContractor/managamentContractorCodes"

const DetailLocalDrawer = ({
  isOpenDrawer,
  onCloseDrawer
}) => {
  const dispatch = useAppDispatch()
  const { activeFeatures } = useAppSelector(state => state.roles)

  const { clickedPlaceData, idClickedPlace } = useAppSelector(state => state.malls)
  const { handleRedirectTo } = useRedirectTo()

  //Prepare data
  const contractStartDate = (clickedPlaceData?.placeFile?.PLACE_FILE_VALIDITY?.dateBegin)
    ? new Date(clickedPlaceData?.placeFile?.PLACE_FILE_VALIDITY?.dateBegin)
    : ""
  const contractExpirationDate = (clickedPlaceData?.placeFile?.PLACE_FILE_VALIDITY?.dateEnd)
    ? new Date(clickedPlaceData?.placeFile?.PLACE_FILE_VALIDITY?.dateEnd)
    : ""
  // Cuando no existe data el valor en null por ende se puede ejecutar una ternaria
  const isPLaceDetail = clickedPlaceData?.place?.PLACE_DETAIL
  const isPlaceFile = clickedPlaceData?.placeFile

  const isDataCommercial = clickedPlaceData?.placeFile?.PLACE_FILE_COMMERCIAL
    ? clickedPlaceData.placeFile.PLACE_FILE_COMMERCIAL
    : null

  const optionsToFormatDate = {
    year: 'numeric',
    month: 'long', // Use 'long' for the full month name in the target locale
    day: 'numeric',
    weekday: 'long', // Optionally include the day of the week
  };

  return (
    <CustomDrawer
      width={650}
      title='FICHA DE CONTRATO'
      isOpenDrawer={isOpenDrawer}
      onClose={onCloseDrawer}
      className="building-drawer"
      closeIcon={<CloseOutlined style={{ color: "var(--primary)", fontSize: 20 }} />}
    >
      <Row>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={5}>
              <Row justify="center">
                <BuildingIcon />
              </Row>
            </Col>
            <Col span={12} style={{ borderRight: "1px solid #BDBDBD" }}>
              <h3 style={{ fontWeight: 400, fontSize: 22, color: "#232C4A" }}>
                {isPLaceDetail ? clickedPlaceData?.place?.PLACE_DETAIL?.name : "No existe información agregada"}
              </h3>

              <Row justify="start" style={{ paddingBottom: 30 }} >
                <p style={{ fontWeight: 600, fontSize: 22, color: "#232C4A", margin: 0 }}>
                  {clickedPlaceData?.place?.PLACE_HIERARCHY?.name} {clickedPlaceData?.place?.name}
                  {' '}
                  <span style={{ color: "var(--primary)", fontWeight: 500, fontSize: 16 }}>
                    {clickedPlaceData?.parentPlace?.name}: {clickedPlaceData?.parentName}
                  </span>
                </p>
              </Row>
            </Col>

            <Col span={7}>
              <Row>
                <Col span={4}>
                  <PhoneIcon />
                </Col>

                <Col span={20}>
                  <label style={{ color: "#404040", fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}>
                    TELÉFONO:
                  </label>
                  {isPLaceDetail
                    ? (clickedPlaceData?.place?.PLACE_DETAIL?.PLACE_DETAIL_CONTACTs?.map((phoneNumber, index) => (
                      <p key={`${phoneNumber}${index}`} style={{ color: "#404040", fontSize: 14, fontWeight: 400, marginBottom: 5 }}>
                        {phoneNumber.phone_number}
                      </p>
                    )))
                    : <p style={{ color: "#404040", fontSize: 14, fontWeight: 400, marginBottom: 5 }}>
                      No existe información agregada
                    </p>
                  }
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider style={{ borderTop: "1px solid #C9C9C9" }} />

          <Row justify="center" align="top" gutter={16} style={{ height: 100, paddingInline: 30 }}>
            <Col span={12}>
              <label style={{ color: "#404040", fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}>
                INICIO DE CONTRATO
              </label>
              <p style={{ color: "#404040", fontSize: 14, fontWeight: 400 }}>
                {contractStartDate
                  ? (formatUTCDateInTimeZone({ utcDate: contractStartDate, customOptions: optionsToFormatDate }))
                  : "No existe fecha de inicio contrato"
                }
              </p>
            </Col>

            <Col span={12}>
              <label style={{ color: "#404040", fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}>
                FINALIZACIÓN DE CONTRATO
              </label>
              <p style={{ color: "#404040", fontSize: 14, fontWeight: 400 }}>
                {contractExpirationDate
                  ? formatUTCDateInTimeZone({ utcDate: contractExpirationDate, customOptions: optionsToFormatDate })
                  : "No existe fecha de finalización contrato"
                }
              </p>
            </Col>
          </Row>

          <Row justify="center" align="middle" gutter={16} style={{ height: 100, paddingInline: 30 }}>
            <Col span={12}>
              <label style={{ color: "#404040", fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}>
                TIPO DE CONTRATO
              </label>
              <p style={{ color: "#404040", fontSize: 14, fontWeight: 400 }}>
                {isPlaceFile
                  ? clickedPlaceData?.placeFile?.PLACE_FILE_SUBTYPE?.name
                  : "No existe información"
                }
              </p>
            </Col>

            <Col span={12}>
              {isDataCommercial && (
                <Row>
                  <Col span={24}>
                    <label style={{ color: "#404040", fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}>
                      M2 DE LOCAL O QUIOSCO
                    </label>
    
                    <p style={{ color: "#404040", fontSize: 14, fontWeight: 400 }}>
                      {isDataCommercial.place_m2_size} m2
                    </p>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          
          {isDataCommercial && <Divider style={{ borderTop: "1px solid #C9C9C9" }} />}

          {isDataCommercial && (
            <Row>
              <Col span={24}>
                <Row justify="center" align="middle" gutter={16} style={{ height: 100, paddingInline: 30 }}>
                  <Col span={12}>
                    <label style={{ color: "#404040", fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}>
                      RENTA POR M2
                    </label>
                    <p style={{ color: "#404040", fontSize: 14, fontWeight: 400 }}>
                      {(isDataCommercial?.place_per_m2_rent_price)?.toString()
                        ? isDataCommercial.place_per_m2_rent_price
                        : "No se agregó un valor de renta por M2"
                      }
                    </p>
                  </Col>

                  <Col span={12}>
                    <label style={{ color: "#404040", fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}>
                      % INCREMENTO PARA RENTA
                    </label>
                    <p style={{ color: "#404040", fontSize: 14, fontWeight: 400 }}>
                      {(isDataCommercial?.place_rent_increase_percentage)?.toString()
                        ? `${isDataCommercial.place_rent_increase_percentage} %`
                        : "No se agregó un % de incremento para renta"
                      }
                    </p>
                  </Col>
                </Row>

                <Row justify="center" align="middle" gutter={16} style={{ height: 100, paddingInline: 30 }}>
                  <Col span={12}>
                    <label style={{ color: "#404040", fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}>
                      MANTENIMIENTO POR M2
                    </label>
                    <p style={{ color: "#404040", fontSize: 14, fontWeight: 400 }}>
                      {(isDataCommercial?.place_per_m2_maintenance_price)?.toString()
                        ? isDataCommercial.place_per_m2_maintenance_price
                        : "No se agregó un valor de mantenimiento por M2"
                      }
                    </p>
                  </Col>

                  <Col span={12}>
                    <label style={{ color: "#404040", fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}>
                      % INCREMENTO PARA MANTENIMIENTO
                    </label>
                    <p style={{ color: "#404040", fontSize: 14, fontWeight: 400 }}>
                      {(isDataCommercial?.place_maintenance_increase_percentage)?.toString()
                        ? `${isDataCommercial.place_maintenance_increase_percentage} %`
                        : "No se agregó un % de incremento para mantenimiento"
                      }
                    </p>
                  </Col>
                </Row>

                <Row align="middle" gutter={16} style={{ height: 100, paddingInline: 30 }}>
                  <Col span={12}>
                    <label style={{ color: "#404040", fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}>
                      RENTA VARIABLE
                    </label>
                    <p style={{ color: "#404040", fontSize: 14, fontWeight: 400 }}>
                      {(isDataCommercial?.place_variable_rent)?.toString()
                        ? `${isDataCommercial.place_variable_rent} %`
                        : "No se agregó un valor de renta variable"
                      }
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          <Divider style={{ borderTop: "1px solid #C9C9C9" }} />

          <Row gutter={32} justify="space-around" align="middle" style={{ height: 200, paddingInline: 15 }}>
            <Col span={12}>
              <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_CONTRACT_COPIES)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Copias de Contrato'}`}>
                <Button
                  block
                  shape="round"
                  htmlType="button"
                  className='btn-standard-white'
                  style={{ justifyContent: "space-evenly", marginBottom: 30 }}
                  onClick={async () => {
                    dispatch(setCurrentOption('1'))
                    dispatch(await getContractsFiles(idClickedPlace))
                    dispatch(await getTypesContract())
                    handleRedirectTo('/contracts')
                  }}
                  disabled={!activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_CONTRACT_COPIES)?.enabled}
                >
                  <VisionEyeIcon />
                  <span style={{ color: "#232C4A" }}>COPIA DE CONTRATO</span>
                </Button>
              </CustomPopoverConfirm>

              <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_TICKETS_LOCAL)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Historial de Tickets'}`}>
                <Button
                  block
                  shape="round"
                  htmlType="button"
                  className='btn-standard-white'
                  style={{ justifyContent: "space-evenly", marginTop: 30 }}
                  onClick={async () => {
                    dispatch(setCurrentOption('3'))
                    dispatch(await getAllTicketsByPlace({ placeId: idClickedPlace }))
                    handleRedirectTo('/contracts')
                  }}
                  disabled={!activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_CONTRACT_COPIES)?.enabled}
                >
                  <CheckIcon />
                  <span style={{ color: "#232C4A" }}>HISTORIAL DE TICKETS</span>
                </Button>
              </CustomPopoverConfirm>
            </Col>

            <Col span={12}>
              <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_FISCAL_DATA)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Datos fiscales'}`}>
                <Button
                  disabled={!activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_FISCAL_DATA)?.enabled}
                  block
                  shape="round"
                  htmlType="button"
                  className='btn-standard-white'
                  style={{ justifyContent: "space-evenly", marginBottom: 30 }}
                  onClick={async () => {
                    dispatch(setCurrentOption('2'))
                    // Pasar el idClickedPlace
                    dispatch(await getDetailPlaceTaxData(idClickedPlace))
                    dispatch(await getPersonHoodTypes())
                    handleRedirectTo('/contracts')
                  }}
                >
                  <IdCardIcon />
                  <span style={{ color: "#232C4A" }}>DATOS FISCALES</span>
                </Button>
              </CustomPopoverConfirm>
              
              <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_MANAGEMENT_MAPS)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Especificaciones del local'}`}>
                <Button
                  disabled={!activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_MANAGEMENT_MAPS)?.enabled}
                  block
                  shape="round"
                  htmlType="button"
                  className='btn-standard-white'
                  style={{ justifyContent: "space-evenly", marginTop: 30 }}
                  onClick={async () => {
                    dispatch(setCurrentOption('4'))
                    // Pasarle el idClickedPlace
                    dispatch(await getAllMapFiles(idClickedPlace))
                    handleRedirectTo('/contracts')
                  }}
                >
                  <ReportIcon />
                  <span style={{ color: "#232C4A" }}>ESPECIFICACIONES</span>
                </Button>
              </CustomPopoverConfirm>
            </Col>
          </Row>
        </Col>
      </Row>
    </CustomDrawer>
  )
}

export default DetailLocalDrawer