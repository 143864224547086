import { useState } from 'react';
import { getSurveys } from '../../../../store/surveys';
import { useSurveyContext } from '../contexts/SurveyContext';
import { useSurveyFormContext } from '../contexts/SurveyFormContext';

export const useSurveys = () => {

    const { surveys, modalVisible, setModalVisible, dispatch, clearSurveysData, getActiveSurveys} = useSurveyContext()
    const { listPreguntas, setListPreguntas, setEndDate, setStartDate } = useSurveyFormContext()
    const [loadMoreData, setLoadMoreData] = useState(false);

    const dateFormat = 'DD/MM/YYYY';
    const customFormat = (value) => `${value.format(dateFormat)}`;

    const handleStatusModal = (statusModal, index = null) => {
        setTimeout(() => {
            setModalVisible(statusModal);
        }, 50);
    }

    const handleChange = (value, key) => {
        let newArr = [...listPreguntas]; // copying the old datas array
        newArr[key].question = value

        setListPreguntas(newArr);
    };

    const onChangeFecha = (date, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
        //const [startDate, setstartDate] = useState("");
        //const [endDate, setendDate] = useState("");
    };

    return (
        {
            setModalVisible, setLoadMoreData, setEndDate, setStartDate, setListPreguntas, customFormat, getSurveys, onChangeFecha, handleChange, handleStatusModal, dispatch, clearSurveysData, getActiveSurveys,
            surveys, modalVisible, loadMoreData, listPreguntas
        })
}

export default useSurveys