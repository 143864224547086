import AdminChecklistTasksTable from "./components/AdminChecklistTasksTable"
import FiltersAdminChecklistTasksTable from "./components/FiltersAdminChecklistTasksTable"
import { AdminChecklistTasksProvider } from "./context/AdminChecklistTasksContext"
import HeaderFiltersAdminChecklistTask from "./layout/HeaderFiltersAdminChecklistTask"

const AdminChecklistItemsTasksPage = () => {
  return (
    <section className="section-wrapper">
      <AdminChecklistTasksProvider>
        <HeaderFiltersAdminChecklistTask />

        <FiltersAdminChecklistTasksTable />
        
        <AdminChecklistTasksTable />
      </AdminChecklistTasksProvider>
    </section>
  )
}

export default AdminChecklistItemsTasksPage
