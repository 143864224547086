import { Button, Col, Row } from "antd"
import { useAppDispatch } from "../../../../../hooks/store"
import useImageChecklist from "../../hooks/useImageChecklist"
import { CustomNavigate } from "../../../../UI/CustomNavigate"
import SettingsMenu from "../../../../components/SettingsMenu/SettingsMenu"
import { setChecklistClicked } from "../../../../../store/adminChecklist/adminChecklistSlice"

// imagenes o archivos
import { PlusCircleOutlined } from "@ant-design/icons"
import { ReactComponent as ExcelIcon } from '../../../../../assets/icons/excel.svg'

const HeaderActionsImageCheck = ({
  form,
  handleOpenCloseModal
}) => {
  const dispatch = useAppDispatch()
  const { getExcelChecklist } = useImageChecklist()

  const onClickNewEditModal = () => {
    form.resetFields()
    
    dispatch(setChecklistClicked({}))
    handleOpenCloseModal()
  }

  return (
    <header>
      <CustomNavigate title="CHECKLIST DE ORNATO E IMAGEN">
        <Row gutter={16} align="middle">
          <Col>
            <Button
              type="link"
              shape="round"
              htmlType="button"
              onClick={async () => await getExcelChecklist()}
              style={{ padding: 0 }}
              icon={<ExcelIcon />}
            />
          </Col>

          <Col>
            <SettingsMenu
              items={[{ label: 'Administración de Checklist', route: '/admin-check/adminTasks', active: true }]}
            />
          </Col>

          <Col>
            <Button
              type="text"
              shape="round"
              htmlType="button"
              className='btn-standard-green'
              style={{ width: 229 }}
              onClick={onClickNewEditModal}
            >
              <Row gutter={8} align="middle">
                <Col>
                  <span>NUEVA EVALUACIÓN</span>
                </Col>
                <Col>
                  <PlusCircleOutlined style={{ fontSize: "14px", lineHeight: 0 }} />
                </Col>
              </Row>
            </Button>
          </Col>
        </Row>
      </CustomNavigate>
    </header>
  )
}

export default HeaderActionsImageCheck
