import { Input, InputNumber, Row } from "antd"
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons"

const { TextArea } = Input;

export const CustomInput = ({
  id,
  value,
  onBlur,
  onChange,
  type = 'TEXT',
  allowClear = true,
  placeholder,
  isComponentIcon = false,
  prefixIcon,
  suffixIcon,
  disabled = false,
  defaultValue,
  maxLength = 256,
  inputNumberText,
  nullInputNumberText = 'Sin ocurrencias',
  className = "custom-input",
  stringMode = false,
  rows,
  autoSize = { minRows: 1, maxRows: 10 },
  min,
  max,
  step,
  hidden,
  maxWidth,
  prefix,
  precision = 0,
  customFormatter = () => { },
  customParser = () => { }
}) => {

  const props = {
    id,
    defaultValue,
    value,
    min,
    max,
    step,
    onBlur,
    onChange,
    disabled,
    allowClear,
    isComponentIcon,
    prefixIcon,
    suffixIcon,
    placeholder,
    maxLength,
    className,
    rows,
    autoSize,
    hidden,
    maxWidth,
    stringMode,
  }

  //Funciones para el input Number si se quiere colocar un string
  const handleFormatter = (value) => {
    if (!value) return nullInputNumberText ? `${nullInputNumberText}` : undefined
    if (inputNumberText) {
      const regex = new RegExp(inputNumberText, 'g');
      // Reemplazar todas las ocurrencias del substring con una cadena vacía
      const result = value.replace(regex, '');
      return `${result} ${inputNumberText}`;
    }
    return value
  };

  const handleParser = (value) => {
    if (!value) return "";
    const stringValue = value.toString().replace(/[^\d.]/g, ''); // Eliminar caracteres no numéricos
    return stringValue ? parseFloat(stringValue) : 0;
  };

  const verifyTypeInputs = (type, {
    id,
    defaultValue,
    value,
    min,
    max,
    step,
    onBlur,
    onChange,
    disabled,
    allowClear,
    isComponentIcon,
    prefixIcon,
    suffixIcon,
    placeholder,
    maxLength,
    className,
    rows,
    autoSize,
    hidden,
    maxWidth,
    stringMode
  }) => {
    const commonProps = {
      id,
      value,
      onBlur,
      onChange,
      disabled,
      className,
      hidden,
      maxLength,
      style: { maxWidth }
    }

    const isNumberProps = type === 'NUMBER' ? {
      ...commonProps,
      prefix,
      precision,
      controls: {
        upIcon: <CaretUpFilled />,
        downIcon: <CaretDownFilled />,
      },
      formatter: handleFormatter,
      parser: handleParser,
      defaultValue: defaultValue || null,
      placeholder,
      min,
      max,
      step,
      stringMode,
    } : {
      ...commonProps,
      allowClear,
      prefix: prefixIcon ? (isComponentIcon ? prefixIcon : <img alt="prefix-icon" src={prefixIcon} />) : undefined,
      suffix: suffixIcon ? (isComponentIcon ? suffixIcon : <img alt="suffix-icon" src={suffixIcon} />) : undefined,
      placeholder,
      rows,
      hidden,
    };

    if (type === 'AREA') {
      isNumberProps.autoSize = autoSize
    }

    let objInputsTypes = {
      "TEXT": Input,
      "AREA": TextArea,
      "NUMBER": InputNumber,
      "DEFAULT": null
    }

    let Component = objInputsTypes[type] || objInputsTypes["DEFAULT"]

    return <Component {...isNumberProps} />
  }

  return (
    <Row>
      {verifyTypeInputs(type, props)}
    </Row>
  )
}