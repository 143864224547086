import { useEffect } from "react";
import { getComment } from "../services/comments/GET/getComment";
import { useAdminCheckContext } from "../contexts/AdminCheckContext";
import { verifyResponse } from "../../../../helpers/verifyResponse";
import { commentType } from "../types/commentType";
import { useState } from "react";
import { useSelector } from "react-redux";

export const useHistoryComments = () => {
    const { param, auth, setLoading, loading } = useAdminCheckContext();
    const currentCompanyId = useSelector((state) => state?.auth?.currentRol)
    const [comments, setComments] = useState(commentType);

    const getCommentService = async ({ task_id }) => {
        setLoading(true);
        const res = await getComment({
            token: auth.idToken,
            formData: { task_id },
            company_id: currentCompanyId
        });
        const status = verifyResponse({
            dataResponse: res.data,
            statusResponse: res.status,
            message: "Tipos de tareas obtenidos",
            showToast: false,
        });
        setLoading(false);
        if (status) {
            setComments(res.data.data);
            return res.data.data;
        }
        throw new Error("Error");
    };

    useEffect(() => {
        getCommentService({ task_id: param.idTaskChecklist });
    }, []);

    return { comments, loading };
};