import { Form } from "antd";
import { createContext, useState } from "react";

export const AmenitiesContext = createContext()

export const AmenitiesProvider = ({ children }) => {
  const [form] = Form.useForm()

  // Switch states
  const [valueCostTime, setValueCostTime] = useState(false)
  const [valueStandardCost, setValueStandardCost] = useState(false)
  const [valueInviteesCost, setValueInviteesCost] = useState(false)
  const [valueInviteesAllowed, setValueInviteesAllowed] = useState(false)

  // States para manejo de logica del formulario
  const [currentQuantityInvitees, setCurrentQuantityInvitees] = useState('')
  const [fileList, setFileList] = useState([])
  const [currentValueRadio, setCurrentValueRadio] = useState(1)
  const [amenities, setAmenities] = useState([])
  const [detailAmenity, setDetailAmenity] = useState({})
  const [currentAmenitySelected, setCurrentAmenitySelected] = useState({})
  const [placesAndZonesTreeSelect, setPlacesAndZonesTreeSelect] = useState([])
  const [schedulesAmenity, setSchedulesAmenity] = useState(schedulesAmenityDefaultData)
  const [restrictions, setRestrictions] = useState([])
  const [costs, setCosts] = useState(costsDataAmenity)
  const [currentRestrictionClicked, setCurrentRestrictionClicked] = useState({})
  const [minimunValueTimeReserve, setMinimunValueTimeReserve] = useState('')

  // Resetear el formulario
  const onReset = () => form.resetFields();

  // Limpiar estados para nueva amenidad
  const cleanFileList = () => setFileList([])
  const cleanAmenities = () => setAmenities([])
  const cleanCurrentQuantityInvitees = () => setCurrentQuantityInvitees('')
  const cleanDetailAmenity = () => setDetailAmenity({})
  const cleanCurrentAmenitySelected = () => setCurrentAmenitySelected({})
  const cleanSchedulesAmenity = () => setSchedulesAmenity(schedulesAmenityDefaultData)
  const cleanRestrictions = () => setRestrictions([])
  const cleanCosts = () => setCosts(costsDataAmenity)
  const cleanMinimunValueTimeReserve = () => setMinimunValueTimeReserve('')
  const cleanCurrentRestrictionClicked = () => setCurrentRestrictionClicked({})
  const cleanStandardCostValue = () => setValueStandardCost(false)
  const cleanValueCostTime = () => setValueCostTime(false)

  // Value el que tiene acceso todo el flujo de crear y editar una nueva amenidad
  const value = {
    form,
    onReset,
    fileList,
    setFileList,
    cleanFileList,
    detailAmenity,
    setDetailAmenity,
    cleanDetailAmenity,
    amenities,
    setAmenities,
    cleanAmenities,
    currentAmenitySelected,
    setCurrentAmenitySelected,
    cleanCurrentAmenitySelected,
    placesAndZonesTreeSelect,
    setPlacesAndZonesTreeSelect,
    schedulesAmenity,
    setSchedulesAmenity,
    cleanSchedulesAmenity,
    restrictions,
    setRestrictions,
    cleanRestrictions,
    currentRestrictionClicked,
    setCurrentRestrictionClicked,
    cleanCurrentRestrictionClicked,
    costs,
    setCosts,
    cleanCosts,
    currentValueRadio,
    setCurrentValueRadio,
    minimunValueTimeReserve,
    setMinimunValueTimeReserve,
    cleanMinimunValueTimeReserve,
    currentQuantityInvitees,
    setCurrentQuantityInvitees,
    cleanCurrentQuantityInvitees,
    valueCostTime,
    valueStandardCost,
    valueInviteesCost,
    valueInviteesAllowed,
    setValueCostTime,
    setValueStandardCost,
    setValueInviteesCost,
    setValueInviteesAllowed,
    cleanStandardCostValue,
    cleanValueCostTime
  }

  return (
    <AmenitiesContext.Provider value={value}>
      {children}
    </AmenitiesContext.Provider>
  )
}

// Array default data para costes
const costsDataAmenity = [
  {
    id: 1,
    range_start: 0,
    range_end: 0,
    range_cost: 0,
  },
]

// Este array se manejara en el contexto, y si viene un id en la url se usara el array que retorna el backend
const schedulesAmenityDefaultData = [
  {
    day: 0,
    label: 'Domingo',
    enabled: false,
    timeBegin: '06:00:00',
    timeEnd: '08:00:00',
  },
  {
    day: 1,
    label: 'Lunes',
    enabled: false,
    timeBegin: '06:00:00',
    timeEnd: '08:00:00',
  },
  {
    day: 2,
    label: 'Martes',
    enabled: false,
    timeBegin: '06:00:00',
    timeEnd: '08:00:00',
  },
  {
    day: 3,
    label: 'Miércoles',
    enabled: false,
    timeBegin: '06:00:00',
    timeEnd: '08:00:00',
  },
  {
    day: 4,
    label: 'Jueves',
    enabled: false,
    timeBegin: '06:00:00',
    timeEnd: '08:00:00',
  },
  {
    day: 5,
    label: 'Viernes',
    enabled: false,
    timeBegin: '06:00:00',
    timeEnd: '08:00:00',
  },
  {
    day: 6,
    label: 'Sábado',
    enabled: false,
    timeBegin: '06:00:00',
    timeEnd: '08:00:00',
  }
]