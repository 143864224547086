import React from "react"
import CustomDrawer from "../../../UI/CustomDrawer"
import CategoryInventory from "../components/Modal/CategoryInventory"

const CategoryDrawer = ({
  openDrawer,
  closeDrawer,
  errorMessage,
  setErrorMessage,
  activeFeatures
}) => {
  return (
    <aside>
      <CustomDrawer
        width={450}
        placement="right"
        isOpenDrawer={openDrawer}
        onClose={closeDrawer}
      >
        <CategoryInventory
          activeFeatures={activeFeatures}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      </CustomDrawer>
    </aside>
  )
}

export default CategoryDrawer
