//RESERVAS ✅
import { BOOKINGS_CODES } from "./bookingsCodes";

export const bookingModule = {
    feature_code: BOOKINGS_CODES.BOOKING_MODULE,
    enabled: false,
    children: [
        {
            feature_code: BOOKINGS_CODES.DETAIL_BOOKING,
            enabled: false,
        },
        {
            feature_code: BOOKINGS_CODES.APPROVE_BOOKING,
            enabled: false,
        },
        {
            feature_code: BOOKINGS_CODES.REJECT_BOOKING,
            enabled: false,
        },
    ]
}