//ADMINISTRACIÓN DE CONTRATOS ✅
export const managementContractorModule = {
    feature_code: 'FEAT-365',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-368',
            enabled: false,
        },
        {
            feature_code: 'FEAT-369',
            enabled: false,
        },
        {
            feature_code: 'FEAT-370',
            enabled: false,
        },
        {
            feature_code: 'FEAT-371',
            enabled: false,
        },
        {
            feature_code: 'FEAT-373',
            enabled: false,
        },
    ]
}