import { Col } from 'antd'
import { CustomNavigate } from "../../../../../UI/CustomNavigate";
import { CustomProgress } from '../ActivityMonitoring/components/CustomProgress';
import { useDetailActivity } from '../../hooks/useDetailActivity';
import { CustomSpin } from '../../../../../UI/CustomSpin';
import CustomTable from '../../../../../UI/CustomTable/CustomTable';
import { useFeatureRole } from '../../../../../hooks/useFeatureRole';
import { ADMIN_CHECK_CODES, ADMIN_CHECK_CODES_NAMES } from '../../../../../../constants/modules/adminCheck/adminCheckCodes';

export const DetailsActivity = () => {
    const { handleChangeTable, limitTable, quantityTable, taskColumns, tasks, summaryTask, currentPageTable, loading, categoryInfo } = useDetailActivity();
    const { loadingFeatures } = useFeatureRole({ feature_codes: ADMIN_CHECK_CODES, validate_code: ADMIN_CHECK_CODES_NAMES.ADMIN_CHECK_ACTIVITY_MONITORING, returnUrl: '/checklist/admin' })

    if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />
    
    return (
        <section className="section-wrapper">
            <CustomSpin section spinning={loading}>
                <CustomNavigate title={categoryInfo.name} >
                    <Col style={{ width: '35%' }}>
                        <p style={{ margin: 0 }}>Cumplimiento</p>
                        <CustomProgress percent={(summaryTask.completed / summaryTask.total) * 100} type="line" height={35} fontSize={14} width={'100%'} strokeLinecap="round" strokeColor="var(--primary)" trailColor="var(--primaryminus3)" textColor="var(--primary)" />
                    </Col>
                </CustomNavigate>
                <CustomTable
                    loading={loading}
                    columns={taskColumns}
                    dataSource={tasks}
                    className='detailActivity__table'
                    onChange={handleChangeTable}
                    pagination={
                        {
                            defaultCurrent: 1,
                            pageSize: limitTable || undefined,
                            total: quantityTable || undefined,
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} de ${total}`;
                            },
                            defaultPageSize: limitTable || 10,
                            current: currentPageTable || undefined,
                            position: ["bottomRight"],
                        }
                    }
                />

            </CustomSpin>
        </section>
    )
}