import useCurrentLocation from "../../hooks/useCurrentLocation"
import { ActiveMaintenance } from "./components/ActivesMaintenance/ActiveMaintenance"
import { ActivesMaintenanceContextProvider } from "./context/ActiveMaintenanceContext"
import { HistoryMaintenancePage } from './components/HistoryMaintenance/HistoryMaintenancePage'
import NewMaintenanceReport from "./components/HistoryMaintenance/components/NewMaintenanceReport/NewMaintenanceReport"

import './styles.css'

const ActivesMaintenancePage = () => {
  const { param } = useCurrentLocation()
  const pages = {
    history: <HistoryMaintenancePage />,
    'new-report': <NewMaintenanceReport />
  }

  return (
    <ActivesMaintenanceContextProvider>
      {param?.activeId ? pages[param.activeId] : <ActiveMaintenance />}
    </ActivesMaintenanceContextProvider>
  )
}

export default ActivesMaintenancePage