import { Form } from "antd";
import { useUserRolesContext } from "../context/UserRolesContext"
import { userRolesTableColumnsPrev } from "../helpers/userRolesTableHelper";
import useUserRolesServices from "./useUserRolesServices";
import { defaulStateTable } from "../../../../constants/defaultStates";
import { useDebounce } from "../../../hooks/useDebounce";
import { changeTableParams } from "../../../helpers/customTableHelper";

//Custom hook para exportar las funciones para la página inicial de roles
const useUserRoles = () => {

    const { auth, loading, showModal, rolesItem, userRolesTable, setUserRolesTable, setLoading, setShowModal, handleRedirectTo } = useUserRolesContext()
    const { getRolesService, createRoleService, duplicateRoleService, updateRoleService, updateRoleStatusService, deleteRoleService } = useUserRolesServices()
    const [form] = Form.useForm();

    const serviceParams = { limit: 10, currentPage: userRolesTable.currentPage, skip: 0 }

    //Permite abrir o cerra el modal para un nuevo rol, en caso de existir datos es porque se edita el rol y se añaden al formulario del modal, de lo contrario se reinician los campos
    const handleOpenCloseModal = ({ data }) => {
        setShowModal(prev => !prev)
        if (!data) form.resetFields();
        else {
            form.setFieldsValue({
                role_id: data.role_id,
                name: data.name,
            })
        }
    }

    //Permite redirigir a la página de detalles de un rol donde se añade el id de la cuenta que estaba activa en el el filtro y se guarda ese estado
    const handleDetailRole = ({ data, justView }) => {
        const company_id = userRolesTable.currentParams.company_id ? userRolesTable.currentParams.company_id : auth.currentRol;
        setUserRolesTable(prev => ({ ...prev, active: { ...data, company_id, justView } }));
        handleRedirectTo(`detail/${data.role_id}`)
    }

    //Añade los parametros para buscar por nombre de rol al servicio de roles y luego guarda los nuevos datos y los nuevos parametros del input
    const searchInput = useDebounce(async (e) => {
        const newCurrentParams = {
            ...userRolesTable.currentParams,
            find_by: 'name',
            find_value: e.target.value
        }
        const { data, metadata } = await getRolesService({ ...serviceParams, ...newCurrentParams, setLoading })
        setUserRolesTable(prev => ({
            ...prev,
            currentPage: 1,
            dataTable: data.dataSource,
            metadata,
            currentParams: newCurrentParams,
            lastSkip: 0
        }))
        setLoading(false)
    }, 300)

    //Se utiliza tanto en el input de buscar por rol como en el select y dependiendo del tipo se define que funcion se ejecuta
    const handleSearchRol = async ({ type, value }) => {
        if (type === 'input') {
            searchInput(value)
        }
        if (type === 'select') {
            //Si es un select se añaden el company id como parametro adicional, se consulta al servicio y luego se guardan los datos en el estado
            const newCurrentParams = {
                ...userRolesTable.currentParams,
                company_id: value
            }
            const { data, metadata } = await getRolesService({ ...serviceParams, ...newCurrentParams, setLoading })
            setUserRolesTable(prev => ({
                ...prev,
                currentPage: 1,
                dataTable: data.dataSource,
                metadata,
                currentParams: newCurrentParams,
                lastSkip: 0
            }))
            setLoading(false)
        }
    }

    //Se utiliza en los switch de cada rol en la tabla para habilitar o deshabilitar dicho rol y luego recuperar nuevamente los datos
    const handleChangeRolStatus = async ({ data }) => {
        const formData = { role_id: data.role_id, status: data.status ? false : true }
        await updateRoleStatusService({ formData, setLoading })
        const { data: tableData, metadata } = await getRolesService({ ...serviceParams, ...userRolesTable.currentParams, setLoading, skip: userRolesTable.lastSkip })
        setUserRolesTable(prev => ({
            ...prev,
            dataTable: tableData.dataSource,
            metadata
        }))
        setLoading(false)
    }

    //Se utiliza en el boton de eliminar un rol en cada fila de la tabla  llamando al servicio que lo ejecuta y luego obtiene de nuevo los datos
    const handleDeleteRole = async ({ data }) => {
        const formData = { role_id: data.role_id }
        await deleteRoleService({ formData, setLoading })
        const { data: tableData, metadata } = await getRolesService({ ...serviceParams, ...userRolesTable.currentParams, setLoading })
        setUserRolesTable(prev => ({
            ...prev,
            dataTable: tableData.dataSource,
            metadata
        }))
        setLoading(false)
    }

    //Cuando se completa el formulario para crear, duplicar o editar un rol se evalua si viene un role id o la opcion selecionada para duplicar el rol para definir un servicio a utilizar
    //enviando siempre el nombre y dependiendo de las condiciones anteriores se añade el role id o el company id al formData y se llama al servicio respectivo
    const onSubmit = async (values) => {
        const service = values.role_id ? updateRoleService : values.optionRol === '2' ? duplicateRoleService : createRoleService
        const formData = {
            name: values.name
        }
        if (!values.role_id) {
            formData.company_id = values.company_id
        }
        if (values.optionRol === '2') {
            formData.role_id = values.duplicate_role_id
        }
        if (values.role_id) {
            formData.role_id = values.role_id
        }
        await service({ formData, setLoading })
        const { data: tableData, metadata } = await getRolesService({ ...serviceParams, ...userRolesTable.currentParams, setLoading })
        setUserRolesTable(prev => ({
            ...prev,
            dataTable: tableData.dataSource,
            metadata
        }))
        setLoading(false)
        handleOpenCloseModal({})
    }

    //Establece el valor del select de roles en undefined cuando se modifica el valor del select de cuentas, que es en donde se utiliza este handle
    const handleChangeCompanyId = () => {
        const duplicate_role_id = form.getFieldValue('duplicate_role_id');
        if (duplicate_role_id) {
            form.setFieldsValue({
                duplicate_role_id: undefined
            })
        }
    }

    //Obtiene los datos iniciales para la pagina de roles en lo que respecta a la tabla de roles
    const getInitialData = async () => {
        const { data, metadata } = await getRolesService({ ...serviceParams, setLoading })
        setUserRolesTable({
            ...structuredClone(defaulStateTable),
            dataTable: data.dataSource,
            metadata
        })
        setLoading(false)
    }

    const columns = userRolesTableColumnsPrev({ handleChangeRolStatus, handleOpenCloseModal, handleRedirectTo: handleDetailRole, handleDeleteRole, loading, columns: userRolesTable.columns })

    const paginationTable = {
        pageSize: userRolesTable.metadata.limit,
        total: userRolesTable.metadata.quantity,
        defaultPageSize: userRolesTable.metadata.limit,
        current: userRolesTable.currentPage,
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
        position: ["bottomRight"]
    }

    //Permite cambiar la paginacion de la tabla de roles, añadiendo los parametros adicionales en cada consulta que se haga que pueden ser el company id o los parametros para buscar por nombre de rol
    const handleChangeTable = async ({ pagination, sorter, filters }) => {
        const otherParams = { company_id: userRolesTable?.currentParams?.company_id, find_by: userRolesTable.currentParams.find_by, find_value: userRolesTable?.currentParams?.find_value }
        const tableParams = changeTableParams({ serviceParams, pagination, filters, sorter, state: userRolesTable, setState: setUserRolesTable, otherParams })
        if (!tableParams) return
        const { data, metadata } = await getRolesService({ setLoading, ...tableParams.finalParams })
        setUserRolesTable(prev => ({
            ...prev,
            dataTable: data.dataSource,
            columns: data.columns,
            metadata,
            currentParams: tableParams.newParams,
            lastSkip: tableParams.finalParams.skip
        }))
        setLoading(false)
    }

    return {
        handleOpenCloseModal, handleChangeTable, handleChangeCompanyId, onSubmit, getInitialData, handleSearchRol, getRolesService,
        columns,
        loading, showModal, form, Form, rolesItem, userRolesTable, paginationTable,
    }
}

export default useUserRoles