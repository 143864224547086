import { useAppDispatch } from "../../../../hooks/store"
import { getAllAdminTickets, getAllAlertsThunk, getAllBookings, getAllUsersTickets, getTodayAccessProviders } from "../../../../store/home/thunks"

const useWidgets = () => {
  const dispatch = useAppDispatch()

  const getAccessProviders = async () => {
    const response = await dispatch(getTodayAccessProviders())

    if (response.success) {
      return response.success
    }

    return response
  }

  // Funcion para obtener las alertas en home
  const getAllAlerts = async (isLimit = false) => {
    const response = await dispatch(getAllAlertsThunk(isLimit))

    if (response) {
      return true
    }

    return false
  }

  // Funcion encargada de obtener el status de las reservas y el numero de cada una
  const getBookingStatus = async () => {
    const response = await dispatch(getAllBookings())

    if (response.success) {
      return response.success
    }

    return response
  }

  // Funciones encargadas de obtener los tickets creados y revisados
  const getUsersTicketsStatus = async () => {
    const response = await dispatch(getAllUsersTickets())

    if (response.success) {
      return response.success
    }

    return response
  }

  const getAdminTicketsStatus = async () => {
    const response = await dispatch(getAllAdminTickets())

    if (response.success) {
        return response.success
      }
  
      return response
  }

  return {
    getAllAlerts,
    getBookingStatus,
    getAccessProviders,
    getUsersTicketsStatus,
    getAdminTicketsStatus,
  }
}

export default useWidgets