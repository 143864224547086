import { Button, Col, Divider, Row } from "antd"
import useRedirectTo from "../../../../../hooks/useRedirectTo"
import { useAppSelector } from "../../../../../../hooks/store"
import CustomPopoverConfirm from "../../../../../UI/CustomPopoverConfirm"
import { TICKETS_CODES } from "../../../../../../constants/modules/tickets/ticketsCodes"

import moment from "moment"

const DetailTicketDrawer = ({ activeFeatures }) => {
  const { handleRedirectTo } = useRedirectTo()

  const activityEmployee = useAppSelector((state) => state?.tickets?.activityEmployee)

  const lastWorkday =
    activityEmployee?.data?.ticket?.TICKET_WORK_SESSIONs.length - 1;
  const date = activityEmployee?.data?.activity?.date.split("T")[0];
  let fecha = new Date(date);
  let diaSemana = fecha.getDay() + 1;
  let day;
  switch (diaSemana) {
    case 7:
      day = "Domingo";
      break;
    case 1:
      day = "Lunes";
      break;
    case 2:
      day = "Martes";
      break;
    case 3:
      day = "Miércoles";
      break;
    case 4:
      day = "Jueves";
      break;
    case 5:
      day = "Viernes";
      break;
    case 6:
      day = "Sábado";
      break;
    default:
      day = "Dia no válido";
  }

  return (
    <div className="ticket-drawer-details__container">
      <div style={{ marginBlock: "2rem" }}>
        <Row>
          <Col span={1} style={{ display: 'flex', paddingTop: 16, }}>
            <div
              style={{
                width: "14px",
                height: "14px",
                borderRadius: "100%",
                background: activityEmployee?.data?.ticket?.TICKET_TYPE.name === "Administrativos" ? "#F8C822" : "#59ADFF",
                position: "relative",
              }}
            />
          </Col>
          <Col span={23}>
            <p style={{ fontSize: "26px", marginLeft: "0.5rem" }}>{activityEmployee?.data?.ticket?.TICKET_DETAIL?.title}</p>
          </Col>
        </Row>
      </div>
      <div
        style={{ marginBottom: "3.5rem", fontSize: "12px" }}
      >
        <p style={{ color: "#676767" }}>DESCRIPCION</p>
        <p style={{ fontSize: "16px", color: "#676767" }}>
          {" "}
          {activityEmployee?.data?.ticket?.TICKET_DETAIL.description}{" "}
        </p>
      </div>
      <Row style={{ color: "#676767" }} align="middle">
        <Col span={12}>
          <div style={{ marginBottom: "3rem", fontSize: "16px" }}>
            <p style={{ fontSize: "12px" }}>RESPONSABLE</p>
            <p>
              {
                activityEmployee?.data?.activity?.EMPLOYEE?.PROFILE
                  ?.PROFILE_DETAIL?.firstName
              }{" "}
              {
                activityEmployee?.data?.activity?.EMPLOYEE?.PROFILE
                  ?.PROFILE_DETAIL?.lastName
              }
            </p>
          </div>
          <div style={{ fontSize: "16px" }}>
            <p style={{ fontSize: "12px" }}>INICIA</p>
            <p>
              {
                moment(activityEmployee?.data?.ticket?.TICKET_WORK_SESSIONs[0]?.EMPLOYEE_ACTIVITY_SCHEDULE?.date).format('D/MM/YYYY')
              }
            </p>
            <p>
              {
                moment(activityEmployee?.data?.ticket?.TICKET_WORK_SESSIONs[0]?.EMPLOYEE_ACTIVITY_SCHEDULE?.time_begin, 'HH:mm:ss').format('h:mm a')
              }
              {" - "}
              {
                moment(activityEmployee?.data?.ticket?.TICKET_WORK_SESSIONs[0]?.EMPLOYEE_ACTIVITY_SCHEDULE?.time_end, 'HH:mm:ss').format('h:mm a')
              }
            </p>
          </div>
        </Col>
        <Col span={12}>
          <div style={{ marginBottom: "3rem", fontSize: "16px" }}>
            <p style={{ fontSize: "12px" }}>CATEGORIA</p>
            <p>{activityEmployee?.data?.ticket?.TICKET_TYPE?.name}</p>
          </div>
          <div style={{ fontSize: "16px" }}>
            <p style={{ fontSize: "12px" }}>FINALIZA</p>
            <p>
              {
                moment(activityEmployee?.data?.ticket?.TICKET_WORK_SESSIONs[
                  lastWorkday
                ]?.EMPLOYEE_ACTIVITY_SCHEDULE?.date).format('D/MM/YYYY')
              }
            </p>
            <p>
              {moment(activityEmployee?.data?.ticket?.TICKET_WORK_SESSIONs[
                lastWorkday
              ]?.EMPLOYEE_ACTIVITY_SCHEDULE?.time_begin, 'HH:mm:ss').format('h:mm a')}
              {" - "}
              {moment(activityEmployee?.data?.ticket?.TICKET_WORK_SESSIONs[
                lastWorkday
              ]?.EMPLOYEE_ACTIVITY_SCHEDULE?.time_end, 'HH:mm:ss').format('h:mm a')}
            </p>
          </div>
        </Col>
      </Row>
      <Divider orientation="horizontal" orientationMargin={0} style={{ borderTop: '1.5px solid var(--primary)' }} />
      <Row style={{ marginTop: "5rem" }} justify="center">
        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_DETAIL_TICKET)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Ver detalles del ticket'}`}>
          <Button
            disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_DETAIL_TICKET)?.enabled}
            onClick={() =>
              handleRedirectTo(
                `/tickets/detail/${activityEmployee?.data.ticket.id}`
              )
            }
            shape="round"
            style={{
              backgroundColor: "var(--primary)",
              padding: "0 4rem",
              color: "#fff",
              height: "3rem",
              fontSize: "16px",
              width: 250
            }}
          >
            VER TICKET
          </Button>
        </CustomPopoverConfirm>
      </Row>
    </div>
  );
};

export default DetailTicketDrawer;