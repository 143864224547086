import { useState } from "react"
import { useActivesMaintenanceContext } from "../../context/ActiveMaintenanceContext"
import { useDetailMaintenanceReportServices } from "./useDetailMaintenanceReportServices"
import { getFileSizeOnline } from "../../helpers/filesHelper"

export const useDetailMaintenanceReport = () => {
    const { param, dataTableHistory, reportDetail, setReportDetail } = useActivesMaintenanceContext()
    const { getMaintenanceReportDetailService } = useDetailMaintenanceReportServices()

    const [loading, setLoading] = useState(false)

    const getInitialData = async ({ company_asset_maintenance_id }) => {
        setLoading(true)
        const data = await getMaintenanceReportDetailService({ company_asset_maintenance_id, setLoading })

        if (!data.maintenanceReport?.COMPANY_ASSET_MAINTENANCE_ATTACHMENTs) return
        
        const sizesPromises = data.maintenanceReport.COMPANY_ASSET_MAINTENANCE_ATTACHMENTs.map((item) => getFileSizeOnline({ item, url: item.url }))
        const sizes = await Promise.all(sizesPromises)
        
        data.maintenanceReport.COMPANY_ASSET_MAINTENANCE_ATTACHMENTs = sizes
        setReportDetail(data.maintenanceReport)
        setLoading(false)

        return data
    }

    const cleanData = () => {
        setReportDetail({})
    }

    //Ahora si es necesario, algo para poder exportar el PDF

    return {
        getInitialData,
        cleanData,
        loading,
        reportDetail,
        param,
        dataTableHistory
    }
}