import { useEffect } from "react"
import BookingsTable from "./BookingsTable"
import useBookings from "../hooks/useBookings"
import { CustomSpin } from "../../../UI/CustomSpin"
import CustomTabs from "../../../UI/CustomTabs/CustomTabs"
import { getAllReservations } from "../../../../store/bookings/thunks"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { setCurrentActiveTab } from "../../../../store/bookings/bookingsSlice"

const StatusBookingsTabs = ({
  handleOpenCloseModal, setLoadingModal, currentBody
}) => {
  const dispatch = useAppDispatch()

  const { loading, getAllDataBookings } = useBookings()
  const { statusBookings, bookingsTableData, filters, loadingReserves, currentActiveTab } = useAppSelector(state => state.bookings)

  // Prepare Data to tabs items
  const tabItems = statusBookings?.map((statusBooking) => ({
    key: statusBooking?.reservation_code,
    label: `${statusBooking?.name?.toUpperCase()} (${statusBooking?.quantity})`,
    children: <BookingsTable setLoadingModal={setLoadingModal} handleOpenCloseModal={handleOpenCloseModal} tableData={bookingsTableData} />
  }))

  useEffect(() => {
    getAllDataBookings()
  }, [])

  const handleOnChange = async (value) => {
    await dispatch(getAllReservations({ ...filters, reservationCode: value, sortBy: '', sortOrder: '' }))
    dispatch(setCurrentActiveTab(value))
  }

  if (loading) return <CustomSpin section />

  return (
    <div style={{ marginTop: currentBody === 'tableBody' ? 50 : 0 }}>
      <CustomTabs
        items={tabItems}
        defaultActiveKey={currentActiveTab}
        onChange={handleOnChange}
        disabled={loadingReserves}
        className='custom-tabs-variant-1'
      />
    </div>
  )
}

export default StatusBookingsTabs
