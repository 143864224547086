import {
  Checkbox,
  Form,
  Row,
  DatePicker,
  Space,
  TimePicker,
  Button,
} from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../../UI/CustomModal";
import { updateInspectionSessionThunk } from "../../../../../store/detailTicket/thunk";

const WorkDayModal = ({ onCancel, isOpenModal }) => {
  const { ticketById } = useSelector((state) => state.detailTicketById);
  const [loading, setLoading] = useState(false);
  const workSessions =
    ticketById?.TICKET_INSPECTION?.TICKET_INSPECTION_WORK_SESSIONs.map(
      (workDay) => ({
        ...workDay,
        checked:
          workDay?.EMPLOYEE_ACTIVITY_SCHEDULE?.EMPLOYEE_ACTIVITY_STATUS
            ?.code === "pending",
      })
    ) || [];
  const [updateWorkSessions, setUpdateWorkSessions] = useState(workSessions);
  const [date, setDate] = useState("");
  const [time, setTime] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const minutes = ticketById?.TICKET_INSPECTION?.expectedMinutes;
  const timeResult = (Math.round((minutes / 60) * 100) / 100)
    .toFixed(2)
    .replace(".", ":");

  // const validateDate = (_, dateString) => {
  //   if (!dateString || new Date(dateString) <= new Date()) {
  //     return Promise.reject("La fecha debe ser superior al día actual.");
  //   }
  //   return Promise.resolve();
  // };

  const handleActiveWorkSession = (e, id) => {
    const updatedWorkSessions = updateWorkSessions.map((session) => {
      if (session.id === id) {
        const updatedSession = { ...session };
        updatedSession.checked = e.target.checked;
        return updatedSession;
      }
      return session;
    });
    setUpdateWorkSessions(updatedWorkSessions);
  };

  const sessions = updateWorkSessions
    ?.filter((workDay) => workDay.checked)
    .map((session) => {
      return {
        id: session?.id,
        date: session?.EMPLOYEE_ACTIVITY_SCHEDULE.date.split("T")[0],
        time_begin: session?.EMPLOYEE_ACTIVITY_SCHEDULE.timeBegin
          .split(":")
          .slice(0, 2)
          .join(":"),
        time_end: session?.EMPLOYEE_ACTIVITY_SCHEDULE.timeEnd
          .split(":")
          .slice(0, 2)
          .join(":"),
      };
    });

  const onSubmit = () => {
    try {
      setLoading(true);
      const newSession = {
        date: date,
        time_begin: time[0],
        time_end: time[1],
      };
      const newSessions = date === "" ? sessions : [...sessions, newSession];

      dispatch(
        updateInspectionSessionThunk(
          ticketById?.id,
          ticketById?.TICKET_EMPLOYEE?.employeeId,
          newSessions
        )
      );

      form.resetFields();
    } catch (error) {
    } finally {
      setLoading(false);
      onCancel();
    }
  };

  const dateNewInspection = (date, dateString) => {
    setDate(dateString);
  };
  const workHours = (momentArr) => {
    const startTime = momentArr[0].format("HH:mm");
    const endTime = momentArr[1].format("HH:mm");
    setTime([startTime, endTime]);
  };

  return (
    <CustomModal onCancel={onCancel} isOpenModal={isOpenModal}>
      <h3
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "22px",
          marginBottom: "3rem",
        }}
      >
        EDITAR JORNADAS
      </h3>
      <p
        style={{ textAlign: "center", marginBottom: "2rem", fontSize: "14px" }}
      >
        Tiempo Estimado de Reparacion
        <span style={{ background: "#F6F6F6", paddingInline: "0.5rem" }}>
          {timeResult} {minutes < 60 ? "minutos" : "horas"}
        </span>
      </p>
      <Form
        className="standard-form"
        onFinish={onSubmit}
        disabled={loading}
        form={form}
      >
        <Row justify="center">
          <div>
            <label style={{ color: "#676767", fontSize: "14px" }}>
              Fecha(s) de mantenimiento:
            </label>
            <div style={{ height: "90%", overflow: "auto" }}>
              {updateWorkSessions.map((item, index) => (
                <Row key={item?.id}>
                  <Checkbox
                    defaultChecked={item?.checked}
                    onChange={(e) => handleActiveWorkSession(e, item?.id)}
                  />
                  <p style={{ marginLeft: "1rem" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Jornada {index + 1}
                    </span>
                    - ({item.EMPLOYEE_ACTIVITY_SCHEDULE.date.split("T")[0]} -
                    {item.created_at.split("T")[1].split(".")[0]})
                  </p>
                </Row>
              ))}
            </div>
          </div>
        </Row>
        <Form.Item
        // rules={[
        //   { required: true, message: "Este campo es requerido." },
        //   { validator: validateDate },
        // ]}
        >
          <Row
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
            justify="center"
          >
            <Space direction="vertical">
              <label style={{ color: "#676767", fontSize: "14px" }}>
                Agregar nueva jornada
              </label>
              <DatePicker
                style={{
                  width: "18rem",
                  borderRadius: "5px",
                  background: "#F6F6F6",
                }}
                placeholder="SELECCIONAR"
                onChange={dateNewInspection}
              />
            </Space>
          </Row>
        </Form.Item>
        <Form.Item
          name="time"
          // rules={[{ required: true, message: "Este campo es requerido." }]}
        >
          <Row justify="center">
            <Space direction="vertical">
              <label style={{ color: "#676767", fontSize: "12px" }}>
                Hora de inicio y Hora de fin
              </label>
              <TimePicker.RangePicker
                onChange={workHours}
                style={{
                  width: "18rem",
                  background: "#F6F6F6",
                  borderRadius: 5,
                }}
              />
            </Space>
          </Row>
        </Form.Item>
        <Row justify="center" style={{ marginTop: "3rem" }}>
          <Button
            className="btn-standard-green"
            shape="round"
            type="ghost"
            htmlType="submit"
          >
            <span>GUARDAR</span>
          </Button>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default WorkDayModal;
