import { useEffect, useState } from "react"
import { useAppSelector } from "../../../../hooks/store"
import verifyResponse from "../../../helpers/verifyResponse"
import useCurrentLocation from "../../../hooks/useCurrentLocation"
import getAllCategoryCheckList from "../services/getAllCategoryCheckList"
import createNewCategoryChecklist from "../services/createNewCategoryCheckLis"
import editCategoryCheckList from "../services/editCategoryCheckList"
import deleteCategory from "../services/deleteCategory"

const useCheckListCategory = () => {
  const [loading, setLoading] = useState(false)
  const [categoryData, setCategoryData] = useState({})
  const [metadata, setMetadata] = useState({})

  const { param } = useCurrentLocation()
  const { idToken, currentRol } = useAppSelector((state) => state.auth)

  useEffect(() => {
    if (param.checklistId) allCategoryCheckList(param.checklistId);
  }, [])

  const allCategoryCheckList = async (checklistId, skip) => {
    setLoading(true)
    
    const response = await getAllCategoryCheckList({ idToken, checklist_id: checklistId, company_id: currentRol, skip, isToSelect: false })
    
    if (response.success) {
      setCategoryData(response.data.data)
      setMetadata(response.data.metadata)
      setLoading(false)

      return
    }

    setLoading(false)
    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  const getAllCategoryChecklistToSelect = async ({ find_value }) => {
    const response = await getAllCategoryCheckList({ idToken, checklist_id: param.checklistId, company_id: currentRol, skip: 0, isToSelect: true, find_value })

    return response
  }

  const onSaveChecklistCategory = async (formData) => {
    const functionToExecute = formData?.checklist_category_id ? editCategoryCheckList : createNewCategoryChecklist
    const messageToShow = formData?.checklist_category_id ? 'editada' : 'creada'

    const response = await functionToExecute(idToken, formData)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: `Categoría ${messageToShow} con éxito` })
  }

  const onDeleteChecklistCategory = async (formData) => {
    const response = await deleteCategory(idToken, formData)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Categoría eliminada con éxito'  })
  }

  return {
    loading,
    setLoading,
    metadata,
    categoryData,
    allCategoryCheckList,
    onSaveChecklistCategory,
    onDeleteChecklistCategory,
    getAllCategoryChecklistToSelect,
  }
}

export default useCheckListCategory
