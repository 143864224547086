import moment from "moment"
import { useState } from "react"
import { Button, Col, Form, Row } from "antd"
import CustomModal from "../../../../../UI/CustomModal"
import { config } from "../../../../../constants/constants"
import { CustomSelect } from "../../../../../UI/CustomSelect"
import { CustomCheckbox } from "../../../../../UI/CustomCheckbox"
import { CustomDatePicker } from "../../../../../UI/CustomDatePicker"
import { useActivesMaintenance } from "../../../hooks/useActivesMaintenance/useActivesMaintenance"

const ExportExcelReportModal = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [valueRadio, setValueRadio] = useState([])

  const isLength = Boolean(valueRadio.length) ? {} : {...config}

  const {
    isOpenExcelReportModal, handleOpenCloseExcelReportModal, getActiveReport, getAllCompanyAssetsService
  } = useActivesMaintenance()

  const getActivesSelectData = async () => {
    const response = await getAllCompanyAssetsService({ isToSelect: true })

    return response
  }

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      form.resetFields()
      setValueRadio([])
    }
  }

  const onSubmit = async (formData) => {
    setLoading(true)

    const isAllActives = Boolean(valueRadio.length) ? {} : { company_asset_ids: JSON.stringify(formData?.company_asset_ids) }

    const objToSend = {
      ...isAllActives,
      date_begin: moment(formData.dates[0]).format('YYYY-MM-DD'),
      date_end: moment(formData.dates[1]).format('YYYY-MM-DD')
    }
    
    const response = await getActiveReport(objToSend)

    await onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal
      width={534}
      destroyOnClose
      isOpenModal={isOpenExcelReportModal}
      onCancel={() => {
        form.resetFields()
        setValueRadio([])
        handleOpenCloseExcelReportModal()
      }}
      title={<>
          <h3 style={{ fontWeight: 600, fontSize: 22, color: '#232C4A' }}>
            EXPORTAR REPORTES
          </h3>

          <label style={{ color: '#232C4A', fontWeight: 400, fontSize: 12 }}>
            Seleccione los activos y el rango de fechas para exportar los reportes
          </label>
        </>}
    >
      <Form
        form={form}
        disabled={loading}
        onFinish={onSubmit}
        layout="vertical"
        name="exportReportForm"
      >
        <Row>
          <Col span={24}>
            <Row style={{ marginBottom: 15 }}>
              <CustomCheckbox
                value={valueRadio}
                onChange={(e) => setValueRadio(e)}
                className="custom-checkbox"
                options={[{ label: 'Exportar todos los activos', value: '1' }]}
              />
            </Row>

            <Row style={{ margin: '0 0 15px' }}>
              <label style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: 12, color: '#404040' }}>
                ACTIVOS
              </label>
            </Row>

            <Form.Item name="company_asset_ids" {...isLength}>
              <CustomSelect
                isAsync
                disabled={Boolean(valueRadio.length)}
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                service={getActivesSelectData}
                placeholder="Seleccionar los activos a exportar"
              />
            </Form.Item>

            <Row style={{ margin: '15px 0' }}>
              <label style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: 12, color: '#404040' }}>
                RANGO DE FECHAS
              </label>
            </Row>

            <Form.Item name="dates" {...config}>
              <CustomDatePicker type="RANGER" />
            </Form.Item>

            <Row gutter={16} justify="end" style={{ margin: '12px 0' }}>
              <Col>
                <Button
                  type="text"
                  htmlType="submit"
                  shape="round"
                  disabled={loading}
                  style={{ width: 186, border: 'none', height: 42 }}
                  onClick={() => {
                    form.resetFields()
                    setValueRadio([])
                    handleOpenCloseExcelReportModal()
                  }}
                >
                  <span style={{ color: '#707070' }}>CANCELAR</span>
                </Button>
              </Col>

              <Col>
                <Button
                  type="text"
                  htmlType="submit"
                  shape="round"
                  className="btn-standard-green"
                  style={{ width: 186 }}
                  loading={loading}
                  disabled={loading}
                >
                  <span>EXPORTAR</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default ExportExcelReportModal
