import axios from "axios"

const getPlaceAnalyticsByPlaceId = async (placeId, idToken) => {
  try {
    const response = await axios.get(`https://domo.topiaapp.com/api/place/analytics/mall-level-occupation?place_id=${placeId}`, {
      headers: { Authorization: `Bearer ${idToken}` }
    })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      succes: false,
      ...error.response
    }
  }
}

export default getPlaceAnalyticsByPlaceId
