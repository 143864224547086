import { Button, Form } from "antd"
import CustomModal from "../../../UI/CustomModal"

export const DeleteDocumentModal = ({ isOpenModal, handleOpenCloseModal, currentDocument, onSubmit, loading }) => {
  return (
    <CustomModal destroyOnClose={true} width={550} className="custom-modal library-delete-modal" isOpenModal={isOpenModal} onCancel={() => handleOpenCloseModal('delete')}>
      <Form
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 24 }}
        layout="vertical"
        onFinish={() => onSubmit({ formName: 'delete' })}>
        <h2>ELIMINAR DOCUMENTO</h2>
        <span>¿Realmente deseas eliminar el documento:</span>
        <strong>{currentDocument.name}?</strong>
        <Button loading={loading} style={{ marginTop: 32 }} htmlType="submit" className="btn-standard-red" shape="round">ELIMINAR DOCUMENTO</Button>
      </Form>
    </CustomModal>
  )
}

export default DeleteDocumentModal