//CHECKLIST DE ENTREGA
export const handOverChecklistModule = {
    feature_code: 'FEAT-289',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-478',
            enabled: false,
        },
        {
            feature_code: 'FEAT-479',
            enabled: false,
        },
        {
            feature_code: 'FEAT-480',
            enabled: false,
        },
        {
            feature_code: 'FEAT-477',
            enabled: false,
            children: [
                {
                    feature_code: 'FEAT-130',
                    enabled: false,
                },
                {
                    feature_code: 'FEAT-127',
                    enabled: false,
                },
                {
                    feature_code: 'FEAT-296',
                    enabled: false,
                    children: [
                        {
                            feature_code: 'FEAT-310',
                            enabled: false,
                        },
                    ]
                },
                {
                    feature_code: 'FEAT-287',
                    enabled: false,
                    children: [
                        {
                            feature_code: 'FEAT-291',
                            enabled: false,
                        },
                        {
                            feature_code: 'FEAT-294',
                            enabled: false,
                        },
                        {
                            feature_code: 'FEAT-126',
                            enabled: false,
                        },
                        {
                            feature_code: 'FEAT-264',
                            enabled: false,
                            children: [
                                {
                                    feature_code: 'FEAT-266',
                                    enabled: false,
                                },
                                {
                                    feature_code: 'FEAT-268',
                                    enabled: false,
                                },
                                {
                                    feature_code: 'FEAT-267',
                                    enabled: false,
                                },
                            ]
                        },
                    ]
                },
            ],
        },
    ]
}