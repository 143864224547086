import { API_URL } from "../../../../../config/index";
import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const rolPath = "role/company/module/feature"

//Endpoint de topia que permite recuperar las caracteristicas disponibles de un modulo en específico para asignarse a un rol de la cuenta
export const getAvailableFeaturesRoles = async ({
    token,
    role_id,
    module_id
}) => {
    try {
        const params = {
            role_id,
            module_id
        };

        const response = await axiosCreate.get(`${API_URL}${rolPath}`, {
            params, timeout: 15000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }
};