import verifyResponse from '../../../helpers/verifyResponse'
import { useUserRolesContext } from '../context/UserRolesContext'
import { getAvailableFeaturesRoles } from '../services/GET/getAvailableFeaturesRoles'
import { getAvailableModulesRoles } from '../services/GET/getAvailableModulesRoles'
import { getRoleModuleStatus } from '../services/GET/getRoleModuleStatus'
import { updateFeaturesRole } from '../services/PUT/updateFeaturesRole'
import { updateRoleModuleStatus } from '../services/PUT/updateRoleModuleStatus'

//Se retornan las funciones que permiten parametros adiconales para asi llamar a su respectivo servicio definido y validar si la información del endpoint es correcta o no
const useDetailRolesServices = () => {

    const { auth } = useUserRolesContext();

    //
    const getAvailableFeaturesRolesService = async ({ role_id, module_id, setLoading }) => {
        setLoading && setLoading(true);
        const res = await getAvailableFeaturesRoles({ token: auth.idToken, role_id, module_id })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status })
        if (!status) {
            setLoading && setLoading(false);
            throw new Error('Error in getAvailableFeaturesRolesServices')
        }
        return res.data.data
    }

    //
    const getAvailableModulesRolesService = async ({ company_id = auth.currentRol, setLoading }) => {
        setLoading && setLoading(true);
        const res = await getAvailableModulesRoles({ token: auth.idToken, company_id })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status })
        if (!status) {
            setLoading && setLoading(false);
            throw new Error('Error in getAvailableModulesRolesServices')
        }
        return res.data.data
    }

    //
    const getRoleModuleStatusService = async ({ role_id, module_id, setLoading }) => {
        setLoading && setLoading(true);
        const res = await getRoleModuleStatus({ token: auth.idToken, role_id, module_id })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status })
        if (!status) {
            setLoading && setLoading(false);
            throw new Error('Error in getRoleModuleStatusServices')
        }
        return res.data.data
    }

    //
    const updateFeaturesRoleService = async ({ formData, setLoading }) => {
        setLoading && setLoading(true);
        const res = await updateFeaturesRole({ token: auth.idToken, formData })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, message: 'Funcionalidades del modulo actualizadas' })
        if (!status) {
            setLoading && setLoading(false);
            throw new Error('Error in updateFeaturesRoleServices')
        }
        return res.data.data
    }

    //
    const updateRoleStatusService = async ({ formData, setLoading }) => {
        setLoading && setLoading(true);
        const res = await updateRoleModuleStatus({ token: auth.idToken, formData })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, message: 'Estado del módulo actualizado' })
        if (!status) {
            setLoading && setLoading(false);
            throw new Error('Error in updateRoleStatusServices')
        }
        return res.data.data
    }

    return {
        getAvailableFeaturesRolesService,
        getAvailableModulesRolesService,
        getRoleModuleStatusService,
        updateFeaturesRoleService,
        updateRoleStatusService
    }
}

export default useDetailRolesServices