//Esta función hace tomando un número de referencia crea un array con un objeto cuyas propiedades id y name son el número en sí, útil en los selects
//Se especifíca un stop que indicará hasta que número debe de detenerse el while, pero puede ser null
//En caso de no especificar un stop se toma el maxLength que indica la cantidad de interacciones que se hará en el array
//Existe un booleano back que indicará si a partir del número de inicio este irá hacia adelante o hacia atrás, por defecto es hacia atrás
//Se usa por ejemplo para crear un array de números que hacen referencia a los años, por ejemplo el año inicial es 2024 y se quieren los años hasta el 2020, por ende, el stop seria 2020
export const generateNumbers = ({ start = 200, stop = null, maxLength = 200, back = true }) => {

    const numbersArray = []
    let currentNumber = start

    while (numbersArray.length < maxLength && (!stop || currentNumber >= stop)) {
        numbersArray.push({ id: currentNumber, name: currentNumber.toString() })
        back ? currentNumber-- : currentNumber++
    }

    return numbersArray
}