import { axiosCreate } from "../../../../../config/index"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const getCompanyAssetDetail = async ({
  company_asset_id,
  idToken,
}) => {
  try {
    const response = await axiosCreate.get(`company/asset/detail?company_asset_id=${company_asset_id}`, {
      timeout: 20000,
      headers: { Authorization: `Bearer ${idToken}`}
    })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getCompanyAssetDetail