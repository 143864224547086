import { Form } from "antd";
import { createContext, useContext, useRef, useState } from "react";
import { formData } from "../mocks/formData";

const SurveyFormContext = createContext({
    listPreguntas: null,
    listPreguntasEliminadas: null,
    loadSendData: null,
    currentStep: 1,
    formRef: null,
    saveTempRef: null,
    formModal: false,
    form: null,
    Form: Form,
    initialFormData: {},
    setInitialFormData: () => { },
    setCurrentStep: () => { },
    setLoadSendData: () => { },
    setListPreguntas: () => { },
    setListPreguntasEliminadas: () => { },
    setFormModal: () => { },
})

export const SurveyFormContextProvidrer = ({ children }) => {

    const [listPreguntas, setListPreguntas] = useState({});
    const [initialFormData, setInitialFormData] = useState(structuredClone(formData))
    const [listPreguntasEliminadas, setListPreguntasEliminadas] = useState([])
    const [loadSendData, setLoadSendData] = useState()
    const [currentStep, setCurrentStep] = useState(1)
    const [formModal, setFormModal] = useState(false)
    const [form] = Form.useForm()
    const formRef = useRef(null)
    const saveTempRef = useRef(null)    

    const values = {
        listPreguntas,
        listPreguntasEliminadas,
        loadSendData,
        currentStep,
        formRef,
        saveTempRef,
        formModal,
        form,
        Form,
        initialFormData,
        setInitialFormData,
        setFormModal,
        setCurrentStep,
        setLoadSendData,
        setListPreguntas,
        setListPreguntasEliminadas,
    }

    return (
        <SurveyFormContext.Provider value={values}>
            {children}
        </SurveyFormContext.Provider>
    )
}

export const useSurveyFormContext = () => useContext(SurveyFormContext)