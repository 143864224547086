import { Button, Col, Form, Row } from 'antd'
import CustomModal from '../../../UI/CustomModal'
import CustomTable from '../../../UI/CustomTable/CustomTable'
import './styles.css'
import { CustomInput } from '../../../UI/CustomInput'
import CustomPopoverConfirm from '../../../UI/CustomPopoverConfirm'
import { ACCESS_PROVIDERS_CODES } from '../../../../constants/modules/accessProviders/accessProvidersCodes'

export const ListReasonVisitModal = ({ isOpenModal, onCancel, table, handleChangeTable, handlePagination, columns, loading, onSubmit, setTable, activeFeatures }) => {

  const [form] = Form.useForm()
  return (
    <CustomModal destroyOnClose={true} isOpenModal={isOpenModal} onCancel={() => onCancel({ visible: false, name: 'reasonVisitsModal' })} title={'MOTIVOS DE VISITA'} customTitle className='custom-modal'>
      <Form
        style={{ border: '1px solid #c4c4c4', borderRadius: 8, padding: 20, marginBottom: 8 }}
        form={form}
        onFinish={(values) => onSubmit({ values, formName: 'reasonVisitsTable', form })}
        layout='vertical'>
        <Row align='middle' gutter={16}>
          <Col span={16}>
            <Form.Item rules={[{ required: true, message: 'El nombre es necesario' }]} label={<span style={{ fontWeight: 500 }}>NUEVO MOTIVO</span>} name={'event_reason_name'}>
              <CustomInput placeholder={'Nombre de Motivo de Visita'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_NEW_REASON_VISIT)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nuevo motivo de visita'}`}>
              <Button disabled={!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_NEW_REASON_VISIT)?.enabled} htmlType='submit' style={{ maxWidth: '100%' }} loading={loading} className='btn-standard-green' shape='round'>GUARDAR</Button>
            </CustomPopoverConfirm>
          </Col>
        </Row>
      </Form>
      <CustomTable
        tableName={'reasonVisitsTable'}
        scrollSize={{ y: 290 }}
        loading={loading}
        columns={columns}
        dataSource={table?.dataTable}
        onChange={handleChangeTable}
        handlePagination={handlePagination}
        setTable={setTable}
        totalItems={table?.metadata?.quantity}
        totalPages={Math.ceil(table?.metadata.quantity / table?.metadata?.limit)}
        currentPage={table?.currentPage}
        pagination={{
          current: table?.currentPage,
          total: table?.metadata?.quantity,
          pageSize: table?.metadata?.limit,
          onChange: handlePagination
        }}
      />
    </CustomModal>
  )

}

export default ListReasonVisitModal