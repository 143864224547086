//INVENTARIO ✅
export const inventoryModule = {
    feature_code: 'FEAT-277',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-56',
            enabled: false,
        },
        {
            feature_code: 'FEAT-58',
            enabled: false,
        },
        {
            feature_code: 'FEAT-54',
            enabled: false,
        },
        {
            feature_code: 'FEAT-57',
            enabled: false,
        },
        {
            feature_code: 'FEAT-59',
            enabled: false,
        },
        {
            feature_code: 'FEAT-55',
            enabled: false,
        },
        {
            feature_code: 'FEAT-61',
            enabled: false,
        },
        {
            feature_code: 'FEAT-62',
            enabled: false,
        },
        {
            feature_code: 'FEAT-63',
            enabled: false,
        },
        {
            feature_code: 'FEAT-278',
            enabled: false,
        },
    ]
}