import { Collapse } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
const { Panel } = Collapse;

export const CustomCollapse = ({
  className,
  headerChildren = <span>Prueba</span>,
  onChange,
  children,
  collapseKey,
  activeKey,
  defaultActive = "1",
  maxWidth
}) => {

  return (
    <Collapse

      key={collapseKey}
      activeKey={activeKey}
      className={`custom-collapse ${className ? className : ""}`}
      expandIcon={({ isActive }) => (
        <CaretDownOutlined rotate={isActive ? 360 : 270} />
      )}
      bordered={false}
      defaultActiveKey={defaultActive}
      onChange={onChange}
      style={{ 
        maxWidth
       }}
    >
      <Panel key={"1"} header={headerChildren}>
        {children}
      </Panel>
    </Collapse>
  );
};