import { Button, Col, Image, Row, Upload, message, Typography } from "antd";
import React, { useContext, useState } from "react";
import Pdf from "../../../../assets/icons/pdfD.svg";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addFileDocumentThunk,
  addImageDocumentThunk,
} from "../../../../store/detailTicket/thunk";
import useOpenCloseModal from "../../../hooks/useOpenCloseModal";
import { CountDownContext } from "../../../../context/CountDownContext";
import CustomPreviewImageModal from "../../../UI/CustomPreviewImageModal";
import { CustomSpin } from "../../../UI/CustomSpin";

const { Text } = Typography;
const DocumentsTicket = () => {
  const { ticketById } = useSelector((state) => state.detailTicketById);
  const [previewImage, setPreviewImage] = useState("");
  const [loadingDocument, setLoadingDocument] = useState(false)
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal();
  const { handleIsOpenCloseModal } = useContext(CountDownContext);
  const handleModalOpen = (image) => {
    handleOpenCloseModal();
    setPreviewImage(image);
    handleIsOpenCloseModal();
  };
  const handleModalClose = () => {
    handleIsOpenCloseModal();
    handleOpenCloseModal();
  };

  const dispatch = useDispatch();

  const handleAddImage = (file) => {
    const isIMG =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!isIMG) {
      message.error("Solo puedes subir un archivo JPG/PNG!");
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("El archivo debe pesar menos de 10MB!");
    }

    return (isLt2M && isIMG) || Upload.LIST_IGNORE;
  };

  const handleAddFile = (file) => {
    const isPDF = file.type === "application/pdf";
    if (!isPDF) {
      message.error("Solo puedes subir un archivo de tipo PDF!");
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("El archivo debe pesar menos de 10MB!");
    }
    return (isLt2M && isPDF) || Upload.LIST_IGNORE;
  };

  const handleChange = ({ file }, type) => {
    setLoadingDocument(true);
    if (file.status === "done") {
      type === "image"
        ? dispatch(addImageDocumentThunk(file.originFileObj, ticketById?.id))
        : dispatch(addFileDocumentThunk(file.originFileObj, ticketById?.id));
    }
  };
  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
      //setLoadingDocument(false)
    }, 0);
  };

  return (
    <CustomSpin spinning={loadingDocument}>
      <div
        style={{
          margin: "2rem",
          background: "white",
          padding: "32px",
          height: "70vh",
          borderRadius: 8,
        }}
      >
        <Col style={{ height: "50%", overflowY: 'scroll', overflowX: 'hidden' }}>
          <h3>Documentos</h3>
          <Row align="middle" gutter={[16, 32]}>
            {ticketById?.TICKET_DETAIL?.TICKET_DETAIL_FILEs.map((file) => (
              <Col key={file.file} span={12} lg={6} xl={4}>
                <Button
                  type="link"
                  href={file?.file}
                  target="_blank"
                  style={{
                    height: '170px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "space-around",
                  }}
                >
                  <Image
                    wrapperStyle={{
                      width: '100%'
                    }}
                    preview={false}
                    style={{
                      height: '115px',
                      width: '110px',
                      objectFit: 'cover',
                      borderRadius: 8,
                    }}
                    src={Pdf}
                    alt=""
                  />
                  <Text ellipsis style={{ fontSize: 16, color: '#464646' }}>Documento</Text>
                </Button>
              </Col>
            ))}
            <Col span={12} lg={6} xl={4}>
              <Upload
                className="detail-ticke__upload-item"
                showUploadList={false}
                onChange={(value) => handleChange(value, "file")}
                customRequest={dummyRequest}
                beforeUpload={handleAddFile}
                accept=".doc,.docx,.pdf"
                listType="picture-card"
              >
                <div>
                  <PlusOutlined style={{ fontSize: "1.5rem" }} />
                  <p style={{ fontSize: "12px", paddingTop: "0.2rem" }}>
                    Subir archivo
                  </p>
                </div>
              </Upload>
            </Col>
          </Row>
        </Col>
        <Col style={{ height: "50%", overflowY: 'scroll', overflowX: 'hidden' }}>
          <h3> Imagenes</h3>
          <Row align="middle" gutter={[8, 32]}>
            {ticketById?.TICKET_DETAIL?.TICKET_DETAIL_IMAGEs.map((image) => (
              <Col key={image.id} span={12} lg={6} xl={4}>
                <Image
                  wrapperStyle={{
                    width: '100%'
                  }}
                  style={{
                    height: '170px',
                    objectFit: 'cover',
                    borderRadius: 8,
                  }}
                  src={image?.image}
                  alt=""
                />
              </Col>
            ))}
            <Col span={12} lg={6} xl={4}>
              <Upload
                className="detail-ticke__upload-item"
                showUploadList={false}
                onChange={(value) => handleChange(value, "image")}
                customRequest={dummyRequest}
                beforeUpload={handleAddImage}
                accept=".png,.jpeg,.jpg"
                listType="picture-card"
              >
                <div>
                  <PlusOutlined style={{ fontSize: "1.5rem" }} />
                  <p style={{ fontSize: "12px", paddingTop: "0.2rem" }}>
                    Subir imagen
                  </p>
                </div>
              </Upload>
            </Col>
          </Row>
        </Col>
        <CustomPreviewImageModal
          width={"100%"}
          minHeight={"500px"}
          maxHeight={"700px"}
          isOpenModal={isOpenModal}
          previewImage={previewImage}
          onCancel={handleModalClose}
        />
      </div>
    </CustomSpin>
  );
};

export default DocumentsTicket;
