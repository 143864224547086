import { Button, Row } from "antd"
import EditIcon from "../../../../assets/icons/EditIcon"
import TrashIcon from "../../../../assets/icons/TrashIcon"
import useAmenities from "../hooks/useAmenities"
import useRedirectTo from "../../../hooks/useRedirectTo"
import { useContext } from "react"
import { AmenitiesContext } from "../context/AmenitiesContext"
import { AMENITIES_CODES } from "../../../../constants/modules/amenities/amenitiesCodes"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"

const ActionsButtons = ({ amenityData, handleOpenCloseModal, activeFeatures }) => {
  const { handleRedirectTo } = useRedirectTo()
  const { getAllDetailAmenityData } = useAmenities()
  const { setCurrentAmenitySelected } = useContext(AmenitiesContext)

  // Funciones encargadas de handelear los eventos delete y edit amenity
  const handleOnDeleteAmenity = () => {
    handleOpenCloseModal()
    setCurrentAmenitySelected(amenityData)
  }

  const handleOnEditAmenity = async () => {
    const response = await getAllDetailAmenityData(amenityData.place_id)

    if (response) handleRedirectTo(`/settings/managment-amenities/${amenityData.place_id}`)
  }

  return (
    <Row>
      <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === AMENITIES_CODES.AMENITIES_UPDATE_AMENITY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Editar'}`}>
        <Button
          disabled={!activeFeatures?.find(feature => feature.feature_code === AMENITIES_CODES.AMENITIES_UPDATE_AMENITY)?.enabled}
          type="text"
          shape="round"
          htmlType="button"
          style={{ border: '1px solid var(--primary)', width: 100 }}
          onClick={handleOnEditAmenity}
        >
          <Row justify="space-evenly" align="middle">
            <EditIcon color="var(--primary)" width="16" height="16" />

            <span style={{ fontWeight: 400, fontSize: 12, color: '#616161' }}>
              Editar
            </span>
          </Row>
        </Button>
      </CustomPopoverConfirm>
      <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === AMENITIES_CODES.AMENITIES_DELETE_AMENITY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Eliminar'}`}>
        <Button
          disabled={!activeFeatures?.find(feature => feature.feature_code === AMENITIES_CODES.AMENITIES_DELETE_AMENITY)?.enabled}
          type="link"
          shape="round"
          htmlType="button"
          onClick={handleOnDeleteAmenity}
        >
          <TrashIcon color="#606060" width="15" />
        </Button>
      </CustomPopoverConfirm>
    </Row>
  )
}

export default ActionsButtons