import { Table } from "antd"
import { columnsOrderedTable } from "../../../helpers/columnsTableHelper"

//Se retorna las columnas a utilizar para la tabla de las caracteristicas de un modulo, donde se añade una columna inicial que no aparece como tal visualmente en la tabla
//pero permite que la opcion o boton para abrir la jerarquia de children en la tabla aparezca correctamente
const prevColumns = [
    {
        title: 'Funcionalidad',
        dataIndex: 'name',
        key: 'name',
        width: '35%',
    },
    {
        title: 'Código',
        dataIndex: 'code',
        key: 'code',
        width: '15%',
    },
    {
        title: 'Instancia',
        dataIndex: 'chanel',
        key: 'chanel',
        width: '20%',
        ellipsis: true,
    },
    {
        title: 'Tipo de permiso',
        dataIndex: 'type',
        key: 'type',
        width: '20%',
        ellipsis: true,
    },
]

export const userDetailRolesTableColumnsPrev = ({ justView, columns = [] }) => {
    const defaultColumns = !justView ? [{
        dataIndex: 'testing-only',
        className: 'testing-only',
        key: 'testing-only',
        width: '1px',
    }, ...prevColumns] : [...prevColumns]

    const finalColumns = columns.length ? [
        ...columnsOrderedTable({ defaultColumns, backendColumns: columns }),
        //Se coloca aqui este codigo para que la columna de los checkbox aparezca al final de la tabla en lugar del inicio
        Table.SELECTION_COLUMN,
    ] : [...defaultColumns, Table.SELECTION_COLUMN]

    return finalColumns
}