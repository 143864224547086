import {
  loginWithEmailPassword,
  registerUserWithEmailPassword,
  singInWithGoogle,
  logoutFirebase,
} from "../../firebase/authProvider";
import { checkingCredentials, logout, login, changeRol, authComplete, changeAuthenticationStatus, changeCurrentRol } from "./";
import { setSidebarTheme } from "../sidebar/thunks";
import verifyResponse from "../../topia/helpers/verifyResponse";
import { firebaseAuthErrors } from "../../topia/constants/constants";

export const checkingAuthentication = () => {
  return async (dispatch) => {
    dispatch(checkingCredentials({}));
  };
};

export const changeAuthenticationStatusThunk = (status) => {
  return async (dispatch) => {
    dispatch(changeAuthenticationStatus(status));
  };
};

export const changeCurrentRolThunk = (status) => {
  return async (dispatch) => {
    dispatch(changeCurrentRol(status));
  };
};

export const authenticationComplete = () => {
  return async (dispatch) => {
    dispatch(authComplete());
  };
}

export const startGoogleSignIn = () => {
  return async (dispatch) => {
    dispatch(checkingCredentials({}));

    const result = await singInWithGoogle();
    if (!result.ok) return dispatch(logout(result.errorMessage));

    dispatch(login(result));
  };
};

export const startCreatingUserWithEmailPassword = ({
  email,
  password,
  displayName,
}) => {
  return async (dispatch) => {
    dispatch(checkingCredentials({}));

    const result = await registerUserWithEmailPassword({
      email,
      password,
      displayName,
    });
    if (!result.ok) return dispatch(logout('not-authenticated'));

    dispatch(login(result));
  };
};

export const startLoginWithEmailPassword = ({ email, password, idTemplate, handleRedirectTo }) => {
  return async (dispatch) => {
    // Se espera la respuesta de firebase
    const response = await loginWithEmailPassword({ email, password });

    if (!response.ok) {
      // Se filtra el error devuelto por firebase, en el caso que no se tenga en el diccionario de errores devuelve
      // el error por defecto de contactar a IT
      const errorFiltered = (firebaseAuthErrors.find((errorAuthData) => errorAuthData.path === response.code)) ?? firebaseAuthErrors[8]

      verifyResponse({ dataResponse: { errors: [errorFiltered] }, statusResponse: 400 })
      return dispatch(logout('not-authenticated'))
    }    
    dispatch(checkingCredentials({}));

    //Al autenticarse se ejecutará el useCheckAuth debido al onAuthStateChanged de Firebase
    // por lo que no tiene sentido llamar lo mismo aqui

    //Se debería llamar al servicio de getTemplate.js en este lugar? Pero aqui si esperar el endpoint
    await dispatch(setSidebarTheme({ idTheme: idTemplate, login: true }))
    dispatch(login(response));
    if (handleRedirectTo) handleRedirectTo('/');
    return "default"
  };
};

export const startLogout = (status) => {
  return async (dispatch) => {
    await logoutFirebase();
    localStorage.removeItem('currencySymbol')
    localStorage.removeItem('companyName')
    localStorage.removeItem('typeRol')
    localStorage.removeItem('savedTemplate')
    localStorage.removeItem('savedTheme')
    localStorage.removeItem('currentResidential')
    localStorage.removeItem('currentEmployee')
    localStorage.removeItem('employees')
    dispatch(logout(status));
  };
};

export const changeUserRol = ({
  currentRol, typeRol, companyName, handleRedirectTo, residential_id, currentEmployee, profileId, currencySymbol
}) => async (dispatch) => {
  localStorage.removeItem('checking-logo')
  dispatch(checkingCredentials({ companyName }))
  localStorage.setItem('currentRol', currentRol)
  localStorage.setItem('typeRol', typeRol);
  localStorage.setItem('currentResidential', residential_id);
  localStorage.setItem('companyName', companyName)
  localStorage.setItem('currentEmployee', currentEmployee)
  localStorage.setItem('currencySymbol', currencySymbol)
  dispatch(changeRol({ companyName, currentRol, typeRol, residential_id, currentEmployee, profileId, currencySymbol }));  
}
