import { useEffect } from "react"
import TypesNews from "./TypesNews"
import useTypesNews from "../hooks/useTypesNews"
import CustomTabs from './../../../UI/CustomTabs/CustomTabs'
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { setCurrentActiveTab, setFilters } from "../../../../store/typesNews/typesNewsSlice"
import { NEWS_CODES } from "../../../../constants/modules/news/newsCodes"

const StatusTypesNewsTabs = ({ activeFeatures }) => {
  const dispatch = useAppDispatch()

  const { getAllDataTypesNews, getAllTypesNewsByAllFilters } = useTypesNews()
  const { filters, loadingTypesNews } = useAppSelector(state => state.news)

  useEffect(() => {
    if (!activeFeatures?.find(feature => feature.feature_code === NEWS_CODES.NEWS_TABLE)?.enabled) return
    getAllDataTypesNews()
  }, [])

  const handleOnChange = async (value) => {
    if (!activeFeatures?.find(feature => feature.feature_code === NEWS_CODES.NEWS_TABLE)?.enabled) return
    const isPublished = value === "1"
    dispatch(setCurrentActiveTab(value))
    dispatch(setFilters({ ...filters, published: isPublished }))
    await getAllTypesNewsByAllFilters({ ...filters, published: isPublished })
  }

  const items = [
    { key: '1', label: 'PUBLICADAS', children: <TypesNews activeFeatures={activeFeatures} /> },
    { key: '0', label: 'BORRADOR', children: <TypesNews activeFeatures={activeFeatures} /> },
  ]

  return <CustomTabs items={items} className="custom-tabs-variant-1" onChange={handleOnChange} disabled={loadingTypesNews} />
}

export default StatusTypesNewsTabs