import { useContext } from "react"
import { Button, Col, Row } from "antd"
import ReportingRestriction from "./ReportingRestriction"
import { AmenitiesContext } from "../../../../context/AmenitiesContext"
import useOpenCloseModal from './../../../../../../hooks/useOpenCloseModal'
import NewEditRestrictionsModal from "../../modals/NewEditRestrictionsModal"

const ReportingRestrictions = () => {
  const { restrictions, cleanCurrentRestrictionClicked } = useContext(AmenitiesContext)
  const { form, onReset, isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  return <section style={{ backgroundColor: '#FFFFFF', borderRadius: 16, padding: '50px 25px', marginTop: 25 }}>
    <Row>
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <h2 style={{ fontWeight: 600, fontSize: 16 }}>
              RESTRICCIONES INFORMATIVAS
            </h2>

            <p style={{ margin: 0, fontWeight: 400, fontSize: 12 }}>
              Aquí podrás gestionar restricciones adicionales que desees agregar como que “no se permiten mascotas” o “menores de edad”
            </p>
          </Col>

          <Col>
            <Button
              type="text"
              shape="round"
              htmlType="button"
              className="btn-standard-white"
              style={{ width: 175 }}
              onClick={() => {
                onReset()
                cleanCurrentRestrictionClicked()
                handleOpenCloseModal()
              }}
            >
              <span>AGREGAR</span>
            </Button>
          </Col>
        </Row>

        <Row style={{ marginTop: 25, maxHeight: 500, overflow: 'auto' }} gutter={[16,16]}>
          {restrictions?.map((restrictionData) => <ReportingRestriction key={restrictionData?.title} form={form} handleOpenCloseModal={handleOpenCloseModal} restrictionData={restrictionData} />)}
        </Row>
      </Col>
    </Row>

    <NewEditRestrictionsModal form={form} onReset={onReset} isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
  </section>
}

export default ReportingRestrictions
