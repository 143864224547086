import 'moment-timezone'
import moment from 'moment'
import { FirebaseDB } from '../firebase/config'
import { getDocByCollectionAnId } from "./globalHelper"
import { collection, query, where, getDocs, doc, orderBy, limit, updateDoc } from "firebase/firestore"

// Funcion que obtiene todas las alertas activas de firebase
// La propiedad isLimit solamente es para evitar que en la pagina principal se muestren mas de 25 alertas
export const getAlertsHelper = async ({ residential_id, isLimit = false, startDate, endDate }) => {
  const collectionResidentialRef = doc( FirebaseDB, '/residentials/'+residential_id )
  const collectionRef = collection( FirebaseDB, `/messages` )

  let queryMessages = isLimit
    ? query(collectionRef,
      where("active", "==", true),
      where('residential', "==", collectionResidentialRef),
      orderBy("created_at", "desc"), limit(25))
    : query(collectionRef,
      where("active", "==", true),
      where('residential', "==", collectionResidentialRef),
      orderBy("created_at", "desc"))

  // Cuando no existe una fecha no se deberia de realizar las peticiones con el filtro de fechas
  if (startDate && endDate) {
    queryMessages = isLimit
      ? query(collectionRef,
        where("active", "==", true),
        where('residential', "==", collectionResidentialRef),
        where('created_at', ">=",  new Date(startDate)),
        where('created_at', "<=", new Date(endDate)),
        orderBy("created_at", "desc"), limit(25))
      : query(collectionRef,
        where("active", "==", true),
        where('residential', "==", collectionResidentialRef),
        where('created_at', ">=",  new Date(startDate)),
        where('created_at', "<=", new Date(endDate)),
        orderBy("created_at", "desc"))
  }

  const querySnapshot = await getDocs(queryMessages)
  
  const alerts = [];

  querySnapshot.forEach( async (documment) => {
    let data = documment.data();

    // Definimos los datos a obtener
    let alertData = {
      id: documment.id,
      active: data.active,
      archivar: data.archivar,
      created_at: moment(data.created_at.toDate()).format("Y-MM-D"),
      created_time: moment(data.created_at.toDate()).format("HH:mm"),
      address: data.address,
      contact_phone: data.contact_phone,
      id_profile: data.id_profile,
      message: data.message,
      nameUser: data.nameUser,
      notice_type: data.notice_type ? data.notice_type.id : null,
      residential: data.residential ? data.residential.id  : null,
      user_from: data.user_from,
      vehicle_plate: data.vehicle_plate,
      visible: data.visible,
      asunto: data.asunto,
      coords: data.coords ? data.coords : null,
      imageOne: data.imageOne ? data.imageOne : null,
      imageTwo: data.imageTwo ? data.imageTwo : null,
      answer: data.answer ? data.answer : null,
    }

    alerts.push(alertData);
  });

  return alerts;
}

// Funcion que actualiza una alerta
export const updateAlertById = async (alertId, data) => {
  const collectionRef = doc(FirebaseDB, 'messages', alertId)

  await updateDoc(collectionRef, { ...data })

  return {
    success: true,
  }
}

export const getNoticeTypeDataOfMessage = async (id) => {
  if(!id) return null;

  const data = await getDocByCollectionAnId("notice_type", id);
  return data;
}

export const getResidentialsOfMessage = async (id) => {
  if (!id) return null;

  const data = await getDocByCollectionAnId("residentials", id);
  if (data){
    let residentialData = { ...data };
    residentialData.created_at = moment(data.created_at).format("Y-MM-DD");
    residentialData.created_time = moment(data.created_at).format("HH:mm");
    residentialData.updated_at = null;
    residentialData.geolocation = null;
    residentialData.entity_type = null;

    return residentialData;
  }

  return null;
}

export const columnsAlertTable = [
  {
    title: 'Fecha',
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    title: 'Hora',
    dataIndex: 'created_time',
    key: 'created_time'
  },
  {
    title: 'Nombre',
    dataIndex: 'nameUser',
    key: 'nameUser'
  },
  {
    title: 'Tipo de alerta',
    dataIndex: 'asunto',
    key: 'asunto'
  }
  // {
  //   title: 'Detalle',
  //   dataIndex: 'detail',
  //   key: 'detail',
  // },
  // {
  //   title: 'Descartar',
  //   dataIndex: 'descart',
  //   key: 'descart',
  // },
]