import { useState } from "react";
import { getAllContractTypesService, getAllPositionsService, getAllRolesCompany } from "../services";

const useCompany = () => {
    const [roles, setRoles] = useState([])
    const [positions, setPositions] = useState([])
    const [contractTypes, setContractTypes] = useState([])

    const getAllPositions = async(companyId) => {
        const res = await getAllPositionsService(companyId)

        if(res.success) setPositions(res.data.data)
    }

    const getAllContractTypes = async(companyId) => {
        const res = await getAllContractTypesService(companyId)

        if(res.success) setContractTypes(res.data.data)
    }

    const getAllRolesByCompanyId = async (companyId) => {
      const response = await getAllRolesCompany(companyId)
      if (response.success) setRoles(response.data.data)
    }

    return{
        getAllPositions,
        positions,
        getAllContractTypes,
        contractTypes,
        getAllRolesByCompanyId,
        roles,
    }
};

export default useCompany;