import { Col, Row } from "antd"
import { useContext } from "react"
import { CustomSpin } from "../../../../../UI/CustomSpin"
import EmptyComponent from "../../../../../UI/EmptyComponent"
import ItemsChecklistResultsTable from "./ItemsChecklistResultsTable"
import { ChecklistResultsContext } from "../context/ChecklistResultsContext"

// imagenes o svg
import emptyIconDetail from '../../../../../../assets/icons/Generals/ornament-empty-detail.svg'
import ChecklistCategoryDetail from "./ChecklistCategoryDetail"

const ResultsChecklistDetail = () => {
  const {
    loadingDetailData,
    checklistItemsTable,
  } = useContext(ChecklistResultsContext)

  const { dataSource = [] } = checklistItemsTable

  let body = (
    <>
      <Col span={12}>
        <ChecklistCategoryDetail />
      </Col>

      <Col span={12}>
        <ItemsChecklistResultsTable />
      </Col>
    </>
  )

  if (dataSource.length === 0) {
    body = (
      <Col>
        <EmptyComponent maxWidth={400} Icon={<img src={emptyIconDetail} alt="empty-icon-categories" />} label="Para ver los detalles de una categoría, haz clic en el botón 'Detalle' en la categoría que deseas revisar." />
      </Col>
    )
  }

  return (
    <Row
      justify="center"
      align={dataSource.length === 0 ? "middle" : 'top'}
      style={{
        backgroundColor: '#FFFFFF',
        borderRadius: 16,
        minHeight: 642,
        padding: 32,
        marginBottom: 25,
        boxShadow: '0px 6px 7px 0px #9AB9D440',
    }}
      gutter={16}
    >
      {loadingDetailData ? <CustomSpin section /> : body}
    </Row>
  )
}

export default ResultsChecklistDetail
