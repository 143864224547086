import { useState } from 'react'
import ActionsButtons from './ActionsButtons'
import { bodySearch } from '../../../constants/constants'
import CustomTable from '../../../UI/CustomTable/CustomTable'
import DeleteSubCategoryModal from './Modals/DeleteSubCategoryModal'
import { getColumnsFilter } from '../../../helpers/customTableHelper'
import NewEditSubCategoryModal from './Modals/NewEditSubCategoryModal'

const SubCategoriesTable = ({
  form,
  activeFeatures,
  isOpenModal,
  subCategories,
  loadingActionsTable,
  handleOpenCloseModal,
  getAllTicketsCategories,
}) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  const handleOpenCloseDeleteModal = () => setIsOpenDeleteModal((state) => !state)

  const columnsFiltersObject = {
    'name': () => ({
      ...bodySearch,
      onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
    }),
    'default': () => ({}),
  }

  const newColumns = [
    ...columns,
    {
      title: "",
      dataIndex: "actions",
      align: "right",
      render: (_, subCategory) => (
        <ActionsButtons
          activeFeatures={activeFeatures}
          form={form}
          subCategory={subCategory}
          handleOpenCloseModal={handleOpenCloseModal}
          handleOpenCloseDeleteModal={handleOpenCloseDeleteModal}
        />
      ),
    },
  ]

  const newColumnsFiltered = newColumns.map((column) => ({
    ...column,
    ...getColumnsFilter(column.dataIndex, columnsFiltersObject),
  }))

  return (
    <>
      <CustomTable
        className="custom-ant-table"
        dataSource={subCategories}
        columns={newColumnsFiltered}
        loading={loadingActionsTable}
        rowKey={(record) => record.id}
        onChange={() => { }}
        pagination={{
          pageSize: 10,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} de ${total}`;
          }
        }}
      />

      <DeleteSubCategoryModal
        isOpenModal={isOpenDeleteModal}
        handleOpenCloseModal={handleOpenCloseDeleteModal}
        getAllTicketsCategories={getAllTicketsCategories}
      />

      <NewEditSubCategoryModal
        form={form}
        isOpenModal={isOpenModal}
        handleOpenCloseModal={handleOpenCloseModal}
        getAllTicketsCategories={getAllTicketsCategories}
      />
    </>
  )
}

const columns = [
  {
    title: "SUBCATEGORIAS",
    dataIndex: "name",
    key: "name",
    align: "left",
  },
]

export default SubCategoriesTable
