import useCurrentLocation from "../../../hooks/useCurrentLocation";
import { ActivityMonitoring } from "./components/ActivityMonitoring/ActivityMonitoring";
import { DetailsActivity } from "./components/DetailActivity/DetailActivity";

export const ActivityMonitoringPage = () => {
    const { param } = useCurrentLocation();

    const verifyPage = (action) => {
        switch (action) {
            case "details":
                return <DetailsActivity />;
            default:
                return <ActivityMonitoring />;
        }
    };

    return (
        <>
            {verifyPage(param.action)}
        </>
    );
};

