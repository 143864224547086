import useChatsList from "../../UI/CustomChat/hooks/useChatsList"
import Conversations from "./components/Conversations"
import HeaderConversation from "./layout/HeaderConversation"

const ConversationsPage = () => {
  const { loading, metadata, conversationsList, getAllConversations } = useChatsList()

  return (
    <section className="section-wrapper">
      <HeaderConversation getConversations={getAllConversations} />

      <Conversations
        isToUpdateList
        getConversations={getAllConversations}
        isLoading={loading}
        isMetadata={metadata}
        conversations={conversationsList}
      />
    </section>
  )
}

export default ConversationsPage