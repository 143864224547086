import { configureStore } from "@reduxjs/toolkit";
import { accessSlice } from "./access";
import { alertSlice } from "./alerts";
// import { announcementSlice } from "./announcement";
import { authSlice } from "./auth";
// import { paymentsSlice } from "./payments/paymentsSlice";
import { requestSlice } from "./requests";
import { surveysSlice } from "./surveys";
// import { zonasSlice } from "./zonas";
// import { nomenclaturasSlice } from "./nomenclaturas";
// import { adminCobrosSlice } from "./admincobros/adminCobrosSlice";
import { ticketsSlice } from "./tickets/ticketsSlice";
import { detailTicketByIdSlice } from "./detailTicket/detailTicketSlice";
import { inventorySlice } from "./inventory/inventorySlice";
import { isLoadingSlice } from "./isLoading";
import { inventoryCategoriesSlice } from "./inventory/inventoryCategorySlice";
import { sidebarSlice } from './sidebar/sidebarSlice'
import { detailContractSlice } from './detailContracts/detailContractSlice';
import { mallsSlice } from "./malls/mallsSlice";
import { hierarchyAdminSpaceSlice } from "./hierarchyAdmin/hierarchyAdminSlice";
import { homeSlice } from "./home/homeSlice";
import { bookingsSlice } from "./bookings/bookingsSlice";
import { themeSlice } from "./theme/themeSlice";
import { typesNewsSlice } from "./typesNews/typesNewsSlice";
import { rolesSlice } from "./roles/rolesSlice";
import { adminChecklistSlice } from "./adminChecklist/adminChecklistSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    alerts: alertSlice.reducer,
    requests: requestSlice.reducer,
    access: accessSlice.reducer,
    // payments: paymentsSlice.reducer,
    // announcement: announcementSlice.reducer,
    surveys: surveysSlice.reducer,
    // zonas: zonasSlice.reducer,
    // adminCobros: adminCobrosSlice.reducer,
    // nomenclaturas: nomenclaturasSlice.reducer,
    tickets: ticketsSlice.reducer,
    detailTicketById: detailTicketByIdSlice.reducer,
    detailContracts: detailContractSlice.reducer,
    malls: mallsSlice.reducer,
    home: homeSlice.reducer,
    bookings: bookingsSlice.reducer,
    inventory: inventorySlice.reducer,
    inventoryCategories: inventoryCategoriesSlice.reducer,
    isLoading: isLoadingSlice.reducer,
    sidebarTheme: sidebarSlice.reducer,
    hierarchyAdminSpace: hierarchyAdminSpaceSlice.reducer,
    theme: themeSlice.reducer,
    news: typesNewsSlice.reducer,
    roles: rolesSlice.reducer,
    adminCheck: adminChecklistSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
