import { useEffect } from 'react'
import useCurrentLocation from '../../hooks/useCurrentLocation'
import { useFeatureRole } from '../../hooks/useFeatureRole'
import AmenitiesTable from './components/AmenitiesTable'
import HeaderAmenitiesActions from './components/HeaderAmenitiesActions'
import NewEditAmenityPage from './components/amenity/NewEditAmenityPage'
import { AmenitiesProvider } from './context/AmenitiesContext'
import './styles.css'
import { CustomSpin } from '../../UI/CustomSpin'
import { AMENITIES_CODES, AMENITIES_CODES_NAMES } from '../../../constants/modules/amenities/amenitiesCodes'

const AmenitiesPage = () => {
  const { param } = useCurrentLocation()

  const verifyBodyPage = (idAmenity) => {
    let body = <MainAmenitiesPage />

    if (idAmenity) body = <NewEditAmenityPage />

    return body
  }

  return (
    <AmenitiesProvider>
      <section className="section-wrapper">
        {verifyBodyPage(param.idAmenity)}
      </section>
    </AmenitiesProvider>
  )
}

const MainAmenitiesPage = () => {
  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: AMENITIES_CODES, validate_code: AMENITIES_CODES_NAMES.AMENITIES_MODULE, returnUrl: '/menu/settings' })


  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />
  return (
    <>
      <HeaderAmenitiesActions activeFeatures={activeFeatures} />
      <AmenitiesTable activeFeatures={activeFeatures} />
    </>
  )
}

export default AmenitiesPage