import axios from "axios";
const updateInspectionSession = async (
  URL_DEV,
  ticketId,
  employeeId,
  session
) => {
  try {
    const inspectionSession = {
      ticket_id: ticketId,
      employee_id: employeeId,
      session: session,
    };
    const response = await axios.put(
      `${URL_DEV}/inspection/session`,
      inspectionSession
    );

    return {
      success: true,
      ...response,
    };
  } catch (errorResponse) {
    return {
      success: false,
      ...errorResponse.response,
    };
  }
};
export default updateInspectionSession;
