import { Button, Col, Form, Row } from 'antd'
import '../../styles.css'
import { CustomSelect } from '../../../../../UI/CustomSelect'
import { statusColor } from '../../../mocks/statusColor'
import { PDFDownloadLink } from '@react-pdf/renderer'
import moment from 'moment'
import MonthlyIncomePDFReport from './MonthlyAccessReportPDF'

const FilterCalendar = ({ data, handleProviderChange, providerService, providersTable, handleChangeAccessStatus, currentParams, calendarData }) => (
    <Row justify="end" align="middle">
      <Form layout="vertical">
        <Row gutter={20} align="middle" justify="space-around">
          <Col>
            <Form.Item name="status_id" style={{ marginBottom: 0 }}>
              <CustomSelect
                filters={false}
                options={statusColor}
                onChange={(value) => handleChangeAccessStatus({ value })}
                width="186px"
                placeholder={"Estado del Acceso"}
                allowClear={true}
                itemsCount={25}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="provider_id" style={{ marginBottom: 0 }}>
              <CustomSelect
                filterSorter={false}
                otherParams={{ sort_by: 'name', sort_order: 'asc' }}
                externalData={providersTable}
                isAsync
                service={providerService}
                onChange={(value) => handleProviderChange({ name: 'provider_id', value })}
                width="186px"
                placeholder={"Proveedor"}
                allowClear={true}
                itemsCount={25}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <PDFDownloadLink fileName={`REPORTE DE ACCESO DE PROVEEDORES ${moment(currentParams.date_begin).format('MMMM YYYY').toUpperCase()}`} document={<MonthlyIncomePDFReport data={{ table: calendarData }} />}>
        <Button className='btn-standard-green' shape='round' style={{ marginLeft: 16, maxWidth: 250 }}>EXPORTAR ACCESOS DEL MES</Button>
      </PDFDownloadLink>
    </Row>
  )

export default FilterCalendar