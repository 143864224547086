import { useState } from "react"
import { useDebounce } from "./useDebounce"

// Para utilizar este customHook solo tienen que importarlo una vez en el archivo que se utilizara debido a que
// por cada vez que se instancia se crea data diferente
export const useCustomTable = () => {
    const [dataSource, setDataSource] = useState([])
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
  
    const handleExpandAll = () => {
      // Get the keys of all rows to expand
      const allRowKeys = dataSource.map((item) => item.key)
      setExpandedRowKeys(allRowKeys)
    }
  
    const handleCollapseAll = () => {
      // Collapse all rows by setting expandedRowKeys to an empty array
      setExpandedRowKeys([])
    }
  
    const handleRowExpand = (expanded, record) => {
      // Handle individual row expand/collapse
      if (expanded) {
        setExpandedRowKeys((prevKeys) => [...prevKeys, record.key])
      } else {
        setExpandedRowKeys((prevKeys) => prevKeys.filter((key) => key !== record.key))
      }
    }

  return {
    expandedRowKeys,
    setDataSource,
    handleExpandAll,
    handleCollapseAll,
    handleRowExpand
  }
}

//Este custom hook se utiliza en los CustomTable para las columnos que tienen filtros, de tal forma que ejecuten el evento para disparar el onChange de la tabla
export const useCustomSearch = () => {

  //Se usa otro customHook useDebounce para aplicar un delay cuando se escribe en el filtro antes de ejecutar la búsqueda
  const debounceFilter = useDebounce((confirm) => {
      confirm(false)
  }, 300)
  const handleSearch = (confirm) => e => debounceFilter(confirm)
  const handleReset = (confirm, clearFilters) => {    
      clearFilters()
      confirm(false)
  }

  return {
      handleSearch,
      handleReset
  }
}

export default useCustomTable