import axios from "axios";
const updateStatusTicketToChecked = async (URL_DEV, id) => {
  try {
    const ticketChecked = {
      ticket_id: id,
      employee_id: 1,
      message: "",
    };
    const response = await axios.put(
      `${URL_DEV}/status/reviewed`,
      ticketChecked
    );

    return {
      success: true,
      ...response,
    };
  } catch (errorResponse) {
    return {
      success: false,
      ...errorResponse.response,
    };
  }
};
export default updateStatusTicketToChecked;
