import axios from "axios";

const getEmployee = async (companyId) => {
  try {
    const response = await axios.get(
      `https://domo.topiaapp.com/api/employee?company_id=${companyId}&limit=50&skip=0`
    );
    return {
      success: true,
      ...response,
    };
  } catch (errorResponse) {
    return {
      success: false,
      ...errorResponse.response,
    };
  }
};

export default getEmployee;
