import './styles.css'
import errorIcon from '../../assets/icons/404Error.svg'
import ground from './assets/images/ground.svg'
import heaven from './assets/images/heaven.svg'
import mountains from './assets/images/mountains.svg'
import { Button } from 'antd'
import useRedirectTo from '../../topia/hooks/useRedirectTo'

const ErrorPage = () => {
    const {handleRedirectTo} = useRedirectTo();
    return (
        <>
            <div className='error-page' >
                <img className='error-page__heaven-img' src={heaven} alt="heaven-img" />
                <img className='error-page__error-icon' src={errorIcon} alt="error-icon" />
                <img className='error-page__mountains-img' src={mountains} alt="mountains-img" />
                <img className='error-page__ground-img' src={ground} alt="ground-img" />
                <div className='error-page-container'>
                    <h1>Ooops!</h1>
                    <p>No pudimos encontrar esta página</p>
                    <Button onClick={() => {handleRedirectTo('/')}}>Regresar</Button>
                </div>
            </div>
        </>
    )
}

export default ErrorPage