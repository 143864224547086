import { Button, Col, Form, Row } from "antd"
import CustomModal from "../../../UI/CustomModal"
import { CustomInput } from "../../../UI/CustomInput"
import { CustomDatePicker } from "../../../UI/CustomDatePicker"
import { CustomRadioButton } from "../../../UI/CustomRadio/CustomRadioButton"
import CustomUploadFile from "../../../UI/CustomUploadFile/CustomUploadFile"

export const UploadDocumentModal = ({ isOpenModal, handelOpenCloseModal, handleChangeDatePicker, onSubmit, fileList, setFileList, loading }) => {

  const config = {
    required: true,
    message: 'El campo es necesario'
  }

  const [form] = Form.useForm()
  return (
    <CustomModal destroyOnClose={true} width={900} className="custom-modal library-upload-modal" title={<span style={{ display: 'block', width: '100%', textAlign: 'center', fontWeight: 600, color: '#2532C4A', fontSize: 22 }}>NUEVO DOCUMENTO</span>} isOpenModal={isOpenModal} onCancel={handelOpenCloseModal} >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => onSubmit({ values, form })}>
        <Row gutter={40}>
          <Col span={12}>
            <Form.Item name={'name'} label={'NOMBRE DEL DOCUMENTO'} rules={[{ ...config }]}>
              <CustomInput placeholder={'Digitar el nombre del documento'} />
            </Form.Item>
            <Form.Item name={'publish_start_date'} label={'PUBLICAR DESDE'} rules={[{ ...config }]}>
              <CustomDatePicker onChange={(value) => handleChangeDatePicker({ value, form })} className={'custom-date-picker'} disabledDateBeforeToday={true} />
            </Form.Item>
            <h4>FINALIZAR PUBLICACIÓN</h4>
            <Row>
              <Col span={12}>
                <Form.Item name={'endPublication'} rules={[{ ...config }]} >
                  <CustomRadioButton options={[{ label: 'Nunca', value: 'never' }, { label: 'El día', value: 'day' }]} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item noStyle shouldUpdate={(prev, curr) => (prev["endPublication"] !== curr["endPublication"] || prev["publish_start_date"] !== curr["publish_start_date"])}>
                  {
                    ({ getFieldValue }) => {
                      const option = getFieldValue('endPublication')
                      const startDate = getFieldValue('publish_start_date')
                      return (
                        <Form.Item name={'publish_end_date'} style={{ marginTop: 44 }} rules={[{ required: option === 'day', message: 'El campo es necesario' }]}>
                          <CustomDatePicker disabled={option !== 'day'} disabledDateDependsOnStartDate={startDate && startDate} className={'custom-date-picker option-date'} />
                        </Form.Item>
                      )
                    }
                  }
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item name={'file'} rules={[{ validator: async (_, value) => { return fileList?.length ? Promise.resolve() : Promise.reject(new Error('Debe haber un archivo o imagen a subir')) } }]}>
              <CustomUploadFile fileList={fileList} setFileList={setFileList} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" align="middle" style={{ marginTop: 32, gap: 16 }}>
          <Button onClick={() => { handelOpenCloseModal(); form.resetFields() }} style={{ maxWidth: 186 }} className="btn-standard-white" shape="round">CANCELAR</Button>
          <Button loading={loading} style={{ maxWidth: 186 }} className="btn-standard-green" shape="round" htmlType="submit">GUARDAR</Button>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default UploadDocumentModal