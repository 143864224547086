import { Button } from "antd"
import { CustomNavigate } from '../../../../../../UI/CustomNavigate'
import { ReactComponent as NewReportIcon } from '../../../../assets/newReportIcon.svg'
import { useHistoryMaintenance } from "../../../../hooks/useHistoryMaintenance/useHistoryMaintenance"
import { useEffect } from "react"
import CustomTable from "../../../../../../UI/CustomTable/CustomTable"
import { ACTIVE_MAINTENANCE_CODES } from "../../../../../../../constants/modules/activeMaintenance/activeMaintenanceCodes"
import CustomPopoverConfirm from "../../../../../../UI/CustomPopoverConfirm"
import { CustomSpin } from "../../../../../../UI/CustomSpin"

export const HistoryMaintenance = () => {

  const { handleRedirectTo, getInitialData, handleChangeTable, cleanData, param, loading,
    dataTableActiveMaintenance, dataTableHistory, columnsHistory, currentPageTableHistory, limitTableHistory,
    quantityTableHistory, loadingFeatures, activeFeatures,
  } = useHistoryMaintenance()

  useEffect(() => {
    if (param.historyId) {
      getInitialData({ company_asset_id: param.historyId })
    }

    return () => {
      cleanData()
    }
  }, []);

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (
    <div className="section-wrapper">
      <CustomNavigate title={dataTableActiveMaintenance.find(item => item.company_asset_id.toString() === param.historyId)?.name?.toUpperCase()} >
        <div style={{ maxWidth: 217 }}>
          <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_NEW_REPORT)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nuevo reporte de mantenimiento'}`}>
            <Button
              disabled={!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_NEW_REPORT)?.enabled}
              onClick={() => handleRedirectTo('new')}
              style={{ maxWidth: 217 }}
              icon={<NewReportIcon />}
              className="btn-standard-green"
              shape="round"
            >
              NUEVO REPORTE
            </Button>
          </CustomPopoverConfirm>
        </div>
      </CustomNavigate>
      <CustomTable className="custom-table"
        loading={loading}
        dataSource={dataTableHistory}
        columns={columnsHistory}
        onChange={handleChangeTable}
        pagination={{
          pageSize: limitTableHistory || undefined,
          total: quantityTableHistory || undefined,
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
          defaultPagesize: limitTableHistory || undefined,
          current: currentPageTableHistory || undefined,
          position: ["bottonRight"]
        }} />
    </div>
  )
}

export default HistoryMaintenance