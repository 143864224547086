//USUARIOS ⚠️
export const userModules = {
    feature_code: 'FEAT-284',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-274',
            enabled: false,
        },
        {
            feature_code: 'FEAT-39',
            enabled: false,
        },
        {
            feature_code: 'FEAT-243',
            enabled: false,
        },
        {
            feature_code: 'FEAT-241',
            enabled: false,
        },
        {
            feature_code: 'FEAT-242',
            enabled: false,
        },
        {
            feature_code: 'FEAT-334',
            enabled: false,
        },
        {
            feature_code: 'FEAT-335',
            enabled: false,
        },
    ]
}