import { Button } from "antd"
import { useContext, useEffect, useState } from "react"
import { CustomSpin } from "../../../../../UI/CustomSpin"
import useResultsChecklist from "../hooks/useResultsChecklist"
import { useAppSelector } from "../../../../../../hooks/store"
import CustomTable from "../../../../../UI/CustomTable/CustomTable"
import { ChecklistResultsContext } from "../context/ChecklistResultsContext"

const ResultsChecklistTable = () => {
  const [loading, setLoading] = useState(false)

  const { checklistClicked } = useAppSelector(state => state.adminCheck)
  const { metadata, checklistResultsTableData, setLoadingDetailData, setCategoryClicked } = useContext(ChecklistResultsContext)
  
  const {
    currentPage,
    changePageHandler,
    getChecklistCategoryItems,
    getOrnamentCheckCategories,
    getOrnamentCheckCategoryDetail,
  } = useResultsChecklist()

  const { columns = [], dataSource = [] } = checklistResultsTableData
  const { quantity = 0, limit = 10 } = metadata

  useEffect(() => {
    getAllData()
  }, [])

  const getAllData = async () => {
    setLoading(true)

    await getOrnamentCheckCategories({ checklistId: checklistClicked.checklist_instance_id })

    setLoading(false)
  }

  const onClickDetail = async (categoryData) => {
    setLoadingDetailData(true)

    setCategoryClicked(categoryData)

    await getOrnamentCheckCategoryDetail(categoryData.checklist_instance_category_id)
    await getChecklistCategoryItems({ checklistCategoryId: categoryData.checklist_instance_category_id })

    setLoadingDetailData(false)
  }

  const newColumns = [
    ...columns,
    {
      dataIndex: 'actions',
      key: 'actions',
      title: 'Acción',
      render: (_, record) => (
        <Button
          type="text"
          htmlType="button"
          shape="round"
          style={{ border: '1px solid #252525', padding: '0px 32px 0px 32px' }}
          onClick={() => onClickDetail(record)}
        >
          <span style={{ fontSize: 14, fontWeight: 500, color: '#252525' }}>DETALLE</span>
        </Button>
      )
    }
  ]

  if (loading) return <CustomSpin section />

  return (
    <CustomTable
      rowKey={(record) => record.checklist_instance_category_id}
      className="custom-table-variant-3"
      dataSource={dataSource ?? []}
      columns={newColumns ?? []}
      pagination={{
        total: quantity,
        pageSize: limit,
        onChange: async (current) => {
          await changePageHandler(current, limit)
        },
        showTotal: (total, range) => {
          return `${range[0]}-${range[1]} de ${total}`;
        },
        current: currentPage,
      }}
    />
  )
}

export default ResultsChecklistTable