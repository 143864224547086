import AmenityForm from './components/AmenityForm'
import HeaderAmenityActions from './components/HeaderAmenityActions'
import '../../styles.css'

const NewEditAmenityPage = () => {
  return (
    <>
      <HeaderAmenityActions />

      <AmenityForm />
    </>
  )
}

export default NewEditAmenityPage