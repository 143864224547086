import { Button, Col, Form, Row } from "antd"
import CustomModal from "../../../../UI/CustomModal"
import { useState } from "react"
import { CustomInput } from "../../../../UI/CustomInput"
import { CustomSelect } from "../../../../UI/CustomSelect"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store"
import { getDetailPlaceTaxData, onSavePlaceDetailTax } from "../../../../../store/detailContracts/thunks"

const EditTaxDataModal = ({ form, isOpenModal, handleOpenCloseModal }) => {
  const dispatch = useAppDispatch()
  const { idClickedPlace } = useAppSelector(state => state.malls)

  const [loading, setLoading] = useState(false)
  const { Item } = Form
  const config = { rules: [{ required: true, message: "Por favor llenar el campo" }] }

  const { personhoodTypes } = useAppSelector(state => state.detailContracts)

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      // Agregar place idClickedPlace en lugar de 96
      dispatch(await getDetailPlaceTaxData(idClickedPlace))
    }
  }

  const handleOnSubmit = async (formData) => {
    setLoading(true)
    // Agregar place idClickedPlace en lugar de 96
    const response = await dispatch(await onSavePlaceDetailTax({ ...formData, place_id: idClickedPlace }))

    onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal
      width={877}
      destroyOnClose
      isOpenModal={isOpenModal}
      onCancel={handleOpenCloseModal}
    >
      <Form
        form={form}
        disabled={loading}
        layout="vertical"
        name="editTaxDataForm"
        onFinish={handleOnSubmit}
        className="standard-form-contracts"
      >
        <Row justify="center">
          <Col span={24}>
            <h2 className="title-modal">DATOS FISCALES</h2>

            <Row gutter={24}>
              <Col span={12}>
                <label>TIPO DE PERSONERÍA:</label>
                <Item name="place_detail_tax_personhood_id" {...config}>
                  <CustomSelect
                    showSearch={false}
                    options={personhoodTypes ?? []}
                    className="custom-select-contract"
                    placeholder="Selecciona una opción"
                  />
                </Item>
              </Col>

              <Col span={12}>
                <label>NIT:</label>
                <Item name="dni" {...config}>
                  <CustomInput className="custom-input-contract" />
                </Item>
              </Col>
            </Row>
            
            <label>NOMBRE O RAZÓN SOCIAL(SEGÚN NIT):</label>
            <Item name="tax_name" {...config}>
              <CustomInput className="custom-input-contract" />
            </Item>

            <label>NOMBRE O RAZÓN SOCIAL:</label>
            <Item name="name" {...config}>
              <CustomInput className="custom-input-contract" />
            </Item>
            
            <label>NOMBRE DEL REPRESENTANTE LEGAL:</label>
            <Item name="contact_name" {...config}>
              <CustomInput className="custom-input-contract" />
            </Item>
            
            <label>DIRECCIÓN:</label>
            <Item name="address" {...config}>
              <CustomInput maxLength={1000} className="custom-textarea" type="AREA" />
            </Item>

            <Row justify="space-between" gutter={24}>
              <Col span={4}>
                <label>COD. ÁREA:</label>
                <Item name="phone_area" {...config}>
                  <CustomInput min={1} maxLength={3} className="custom-input-contract" />
                </Item>
              </Col>

              <Col span={6}>
                <label>TELÉFONO:</label>
                <Item name="phone_number" {...config}>
                  <CustomInput min={1} maxLength={8} className="custom-input-contract" />
                </Item>
              </Col>

              <Col span={14}>
                <label>CORREO ELECTRÓNICO:</label>
                <Item name="mail" {...config}>
                  <CustomInput className="custom-input-contract" />
                </Item>
              </Col>
            </Row>

            <Row justify="end">
              <Button
                type="link"
                htmlType="submit"
                shape="round"
                loading={loading}
                className="btn-standard-green"
                style={{ width: 250 }}
              >
                <span>GUARDAR</span>
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default EditTaxDataModal