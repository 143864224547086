import { Text, View, Image, Link } from "@react-pdf/renderer"
import { styles } from './styles'
import { verifyTypeFilePng, verifyTypesFiles } from "../../../../../../../helpers/customFilesHelper"
import CustomFilePdf from "../../../../../../../UI/CustomFiles/CustomFilePdf"
import { truncateText } from "../../../../../helpers/stringHelper"

const PDFMaintenanceReport = ({ reportDetail, titleColor = '#16A07F' }) => {
    if (!reportDetail) return null
    return (
        <CustomFilePdf size="Letter" footer multiplePages>
            <View>
                <View style={styles.mainInfo}>
                    <View style={styles.mainInfoTitle}>
                        <Text>REPORTE DE MANTENIMIENTO</Text>
                        <Text style={{ ...styles.mainInfoSpan, color: titleColor }}>{reportDetail?.COMPANY_ASSET?.name?.toUpperCase()}</Text>
                    </View>
                    <View style={styles.nextMaintenanceDateContainer}>
                        <Text>PRÓXIMO MANTENIMIENTO</Text>
                        <View style={styles.nextMaintenanceDate}>
                            <Text>
                                {reportDetail?.formated_next_maintenance_date && reportDetail?.formated_next_maintenance_date}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.providerInfo}>
                    <Text style={styles.small}>EMPRESA PROVEEDORA DE MANTENIMIENTO</Text>
                    <Text style={styles.strong}>{reportDetail?.PROVIDER?.name}</Text>
                </View>
                <View style={styles.reportDetails}>
                    <View>
                        <Text style={styles.small}>EMPLEADO RESPONSABLE</Text>
                        <Text style={styles.strong}>{reportDetail?.employee_name}</Text>
                    </View>
                    <View>
                        <Text style={styles.small}>FECHA DE MANTENIMIENTO</Text>
                        <Text style={styles.strong}>{reportDetail?.date}</Text>
                    </View>
                    <View>
                        <Text style={styles.small}>HORA DE INICIO</Text>
                        <Text style={styles.strong}>{reportDetail?.formated_time_begin}</Text>
                    </View>
                    <View>
                        <Text style={styles.small}>HORA DE FINALIZACIÓN</Text>
                        <Text style={styles.strong}>{reportDetail?.formated_time_end}</Text>
                    </View>
                </View>
                <View style={{ marginBottom: 24 }}>
                    <Text style={styles.small}>NOTAS GENERALES</Text>
                    <Text style={{ marginTop: 4, ...styles.p, }}>
                        {reportDetail?.observations}
                    </Text>
                </View>
                <View >
                    <Text style={{ ...styles.small, marginBottom: 12 }}>DOCUMENTOS ADJUNTOS</Text>
                    {
                        reportDetail?.COMPANY_ASSET_MAINTENANCE_ATTACHMENTs && reportDetail?.COMPANY_ASSET_MAINTENANCE_ATTACHMENTs.map((attachment) => {
                            const png = verifyTypeFilePng[(verifyTypesFiles(attachment.url))]
                            return (
                                <View style={styles.attachmentFile} key={attachment.url}>
                                    <Image style={{ width: 27, height: 27 }} src={png} />
                                    <Link style={styles.attachmentFileDetails} src={attachment.url} rel="noreferrer">
                                        <Text style={{ ...styles.p, maxWidth: 150 }}>{attachment?.name && truncateText({ text: attachment?.name, maxLength: 30 })}</Text>
                                        <Text style={{ ...styles.small, marginBottom: 0 }}>{attachment.size}</Text>
                                    </Link>
                                </View>
                            )
                        })
                    }
                </View>
                <Text break style={{ ...styles.small, marginBottom: 24 }}>FOTOGRAFÍAS ANEXAS </Text>
                <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {
                        reportDetail?.COMPANY_ASSET_MAINTENANCE_IMAGEs && reportDetail?.COMPANY_ASSET_MAINTENANCE_IMAGEs?.map((image) => {
                            return (
                                <View style={styles.attachmentImage} key={image.url}>
                                    <Image style={styles.attachmentImageImg} src={image.url} />
                                    {
                                        image.name && image.description && (
                                            <View style={styles.attachmentImageDescription}>
                                                <Text style={styles.strong}> {image.name}</Text>
                                                <Text style={styles.p}>{image.description}</Text>
                                            </View>)
                                    }
                                </View>
                            )
                        })
                    }
                </View>
            </View>
        </CustomFilePdf>)
}

export default PDFMaintenanceReport