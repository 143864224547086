//NOTICIAS Y EVENTOS ✅
export const newsModule = {
    feature_code: 'FEAT-305',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-306',
            enabled: false,
        },
        {
            feature_code: 'FEAT-71',
            enabled: false,
        },
        {
            feature_code: 'FEAT-72',
            enabled: false,
        },
        {
            feature_code: 'FEAT-307',
            enabled: false,
        },
        {
            feature_code: 'FEAT-344',
            enabled: false,
        },
    ]
}