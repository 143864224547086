import { useRef, useState } from "react"
import { useAccessProvidersServices } from "./useAccessProvidersServices"
import moment from "moment"

export const useAccessProviderCalendar = ({ setLoading, handleOpenCloseModal }) => {

    const { getAllAccessProvidersService, deleteAccessProviderService } = useAccessProvidersServices()
    const [calendarData, setCalendarData] = useState([])
    const [accessStatus, setAccessStatus] = useState(null)
    const [currentParams, setCurrentParams] = useState({ date_begin: moment().startOf('month').format('YYYY-MM-DD'), date_end: moment().endOf('month').format('YYYY-MM-DD'), provider_id: undefined })
    const isRefresh = useRef(false)
    const initialCalendarData = useRef([])


    const accessStatusData = ({ res }) => {
        //Guarda la data del servicio en un ref para utilizarlo cuando no haya un filtro por estado
        initialCalendarData.current = res
        //Si no esta activo el filtro de estado de acceso retorna la data del servicio
        if (!accessStatus) {
            return setCalendarData(res)
        }
        //En caso de que si este activo filtrará la data del servicio con base al color del estado del acceso y luego actualizará la nueva data para el calendario
        const newData = res.filter(item => item.status_color === accessStatus)
        setCalendarData(newData)
    }

    //Obtiene la data inicial del calendario, pero si refresh viene en las propiedades, actualizara el ref de isRefresh para evitar volver a hacer la peticion (esto en el useEffect del componente)
    const getCalendarData = async ({ refresh }) => {
        if (refresh) isRefresh.current = true
        const res = await getAllAccessProvidersService({ setLoading, refresh, ...currentParams })
        accessStatusData({ res })
    }

    //Se utiliza en los botones del calendario, donde clona la fecha actual (objeto de moment) y establece dicha fecha al inicio y al final del mes actual
    //Luego llama al servicio y le incluye los parámetros y luego actualiza los parametros actuales
    const handleChangeMonthCalendar = async ({ date }) => {
        const date_begin = date.clone().startOf('month').format('YYYY-MM-DD')
        const date_end = date.clone().endOf('month').format('YYYY-MM-DD')
        const res = await getAllAccessProvidersService({ ...currentParams, date_begin, date_end, setLoading })
        setCurrentParams(prev => ({ ...prev, date_begin, date_end }))
        accessStatusData({res})
    }

    //Se utiliza en el select del proveedor para tomar el valor del select cuando cambia y llamar al servicio, actualiza los nuevos parámetros y se llama al accessStatusData
    const handleChangeProvider = async ({ name, value }) => {
        const res = await getAllAccessProvidersService({ ...currentParams, [name]: value, setLoading })
        setCurrentParams(prev => ({ ...prev, [name]: value }))
        accessStatusData({res})
    }

    //Es utilizada en el botón de CANCELAR VISITA en el drawer que se abre al elegir un evento del calendario
    //Si no hay algun event_id retorna pero si lo hay se llama al servicio para eliminar el evento del calendario y luego actualiza la data del calendario, y cierra el drawer
    const handleCancelAccess = async ({ event_id }) => {
        if (!event_id) return
        await deleteAccessProviderService({ event_id, setLoading })
        await getCalendarData({})
        handleOpenCloseModal({ name: 'accessDetailDrawer', visible: false })
    }

    //Se utiliza para el select de estado del acceso, donde se hace una copia soft de la data en el ref y si existe un value filtrará la data por el color del estado del acceso
    //Si no hay un valor seleccionado en el select, se actualiza la data del calendario con el que se guardó en el ref 
    const handleChangeAccessStatus = ({ value }) => {
        const data = [...initialCalendarData.current]
        
        setAccessStatus(value)
        if (value) {
            const newData = data.filter(item => item.status_color === value)
            return setCalendarData(newData)
        }
        setCalendarData(initialCalendarData.current)
    }

    return {
        handleChangeMonthCalendar,
        handleChangeProvider,
        handleCancelAccess,
        handleChangeAccessStatus,
        getCalendarData,
        isRefresh,
        currentParams,
        calendarData,
        accessStatus
    }
}

export default useAccessProviderCalendar