// Se agregaron mas espacios porque es necesario validar esos permisos tambien
export const MANAGEMENT_SPACES_CODES = {
    MANAGEMENT_SPACES_MODULE: 'FEAT-433',
    MANAGEMENT_SPACES_NEW_SPACE: 'FEAT-434',
    MANAGEMENT_SPACES_UPDATE_SPACE: 'FEAT-435',
    MANAGEMENT_CONTRACTORS_MAP: 'FEAT-365',
    MANAGEMENT_CONTRACTORS_CONTRACT_COPIES: 'FEAT-368',
    MANAGEMENT_CONTRACTORS_FISCAL_DATA: 'FEAT-369',
    MANAGEMENT_CONTRACTORS_TICKETS_LOCAL: 'FEAT-370',
    MANAGEMENT_CONTRACTORS_MANAGEMENT_MAPS: 'FEAT-371',
}

export const MANAGEMENT_SPACES_CODES_NAMES = {
    MANAGEMENT_SPACES_MODULE: 'MANAGEMENT_SPACES_MODULE',
    MANAGEMENT_SPACES_NEW_SPACE: 'MANAGEMENT_SPACES_NEW_SPACE',
    MANAGEMENT_SPACES_UPDATE_SPACE: 'MANAGEMENT_SPACES_UPDATE_SPACE',
    MANAGEMENT_CONTRACTORS_MAP: 'MANAGEMENT_CONTRACTORS_MAP',
    MANAGEMENT_CONTRACTORS_CONTRACT_COPIES: 'MANAGEMENT_CONTRACTORS_CONTRACT_COPIES',
    MANAGEMENT_CONTRACTORS_FISCAL_DATA: 'MANAGEMENT_CONTRACTORS_FISCAL_DATA',
    MANAGEMENT_CONTRACTORS_TICKETS_LOCAL: 'MANAGEMENT_CONTRACTORS_TICKETS_LOCAL',
    MANAGEMENT_CONTRACTORS_MANAGEMENT_MAPS: 'MANAGEMENT_CONTRACTORS_MANAGEMENT_MAPS',
}