import { Form } from "antd"
import moment from "moment"
import { useState } from "react"
import useCurrentLocation from "../../../../hooks/useCurrentLocation"
import { dataToFormData } from "../../../../../helpers/dataToFormData"
import { useMaintenanceReportServices } from "./useNewMaintenanceReportServices"
import { useActivesMaintenanceContext } from "../../context/ActiveMaintenanceContext"
import { customNotificationMessage } from "../../../../UI/CustomNotification/CustomNotification"
import { useActivesMaintenanceServices } from "../useActivesMaintenance/useActivesMaintenanceServices"

export const useNewMaintenanceReport = () => {
    const [form] = Form.useForm()
    const { param } = useCurrentLocation()

    const { dataTableProviders, auth, handleRedirectTo } = useActivesMaintenanceContext()
    const { createMaintenanceReportService, updateCompanyActiveReport } = useMaintenanceReportServices()
    const { getAllCompanyProvidersService } = useActivesMaintenanceServices()
    
    const [loading, setLoading] = useState(false)
    const [filesList, setFilesList] = useState([])
    const [imagesList, setImagesList] = useState([])

    const getInitialDataProviders = async ({ find_by, find_value }) => {
      const response = await getAllCompanyProvidersService({ isToSelect: true, find_by, find_value })

      return response
    }

    const onSubmit = async ({ values }) => {
        //Crear el reporte inicialmente, incluyendo los archivos del reporte, y luego mandar todas las imagenes
        setLoading(true)

        const isEditOrCreate = (param?.historyAction === 'edit-report')
          ? { company_asset_maintenance_id: param.reportId }
          : {
            company_asset_ids: values?.company_asset_ids ? JSON.stringify(values?.company_asset_ids) : JSON.stringify([`${param.historyId}`]),
          }
          
        const objToTransform = {
            ...isEditOrCreate,
            provider_id: values.provider_id,
            profile_id: auth.profileId,
            employee_name: values.employee_name,
            observations: values.observations,
            date: moment(values.date).format('YYYY-MM-DD'),
            next_maintenance_date: moment(values.next_maintenance_date).format('YYYY-MM-DD'),
            time_begin: moment(values.time_begin).format('kk:mm'),
            time_end: moment(values.time_end).format('kk:mm'),
            cost: values.cost
        }

        const dataToSend = dataToFormData(objToTransform)

        const oldFiles = filesList?.filter((oldFile) => oldFile.url)?.map((oldFileData) => oldFileData.url)
        const oldFilesImages = imagesList?.filter((filesImages) => filesImages.url)?.map((imageData) => imageData?.url)

        const allArray = oldFiles?.concat(oldFilesImages)

        if (allArray?.length > 0) {
          dataToSend.append('current_files', JSON.stringify(allArray))
        }

        filesList.forEach((file) => {
            if (!(file.originFileObj instanceof File)) return
            dataToSend.append('files', file.originFileObj, file.name)
        })

        imagesList.forEach((imageFile) => {
            if (!(imageFile.originFileObj instanceof File)) return

            dataToSend.append('images', imageFile.originFileObj, imageFile.name)
        })

        const functionToExecute = (param?.historyAction === 'edit-report')
            ? updateCompanyActiveReport : createMaintenanceReportService
        
        const dataToSendOrUpdate = (param?.historyAction === 'edit-report')
            ? dataToSend : { formData: dataToSend, setLoading }

        const response = await functionToExecute(dataToSendOrUpdate)

        if (response) {
            setLoading(false)
            customNotificationMessage({
                message: 'REPORTE DE MANTENIMIENTO CREADO SATISFACTORIAMENTE',
                description: `FECHA: ${moment(values.date).format('DD/MM/YYYY')}`,
                notificationType: 'info',
                defaultIcon: 'saved',
                status: 'info',
                duration: 2.5,
            })
            handleRedirectTo(-1)
        }
    }

    const onFailedSubmit = (error) => {
        const firstError = error.errorFields[0]

        if (!firstError) return
        const { name } = firstError
        const element = document.getElementById("reportMaintenance_" + name.join("_"))
        if (!element || !element.scrollIntoView) return
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
        })
    }

    const handleChangeDate = (value) => {
        const date = moment(value)
        form.setFieldsValue({
            next_maintenance_date: date.add(1, 'day')
        })
    }

    const handleChangeTimePicker = (value) => {
        const date = moment(value)
        form.setFieldsValue({
            time_end: date.add(5, 'minutes')
        })
    }

    const config = {
        required: true,
        message: 'El campo es necesario'
    }

    return {
        onSubmit, onFailedSubmit, getAllCompanyProvidersService, setFilesList, setImagesList, handleChangeDate,
        handleChangeTimePicker, handleRedirectTo, getInitialDataProviders, config, loading, Form, form,
        filesList, imagesList, dataTableProviders
    }
}