import { API_URL, axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const rolPath = "role/company/delete"

//Endpoint de topia con el que se elimina un rol a partir de un id
export const deleteRole = async ({ token, formData }) => {
    try {
        const response = await axiosCreate.put(`${API_URL}${rolPath}`, formData, {
            timeout: 10000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }
};