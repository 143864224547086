import Alert from "./Alert"
import { Button, Col, Row } from "antd"
import { useEffect, useState } from "react"
import useWidgets from "../../hooks/useWidgets"
import { ArrowRightOutlined } from '@ant-design/icons'
import { CustomSpin } from "../../../../UI/CustomSpin"
import { useAppSelector } from "../../../../../hooks/store"
import useRedirectTo from '../../../../hooks/useRedirectTo'
import useOpenCloseModal from "../../../../hooks/useOpenCloseModal"
import CustomPopoverConfirm from "../../../../UI/CustomPopoverConfirm"
import { ALERTS_CODES } from "../../../../../constants/modules/alerts/alertsCodes"
import DetailAlertDrawer from "../../../../pages/alertsReceived/components/drawer/DetailAlertDrawer"

const LastAlerts = ({ activeFeatures }) => {
  const [loadingAlerts, setLoadingAlerts] = useState(false)

  const { getAllAlerts } = useWidgets()
  const { handleRedirectTo } = useRedirectTo()
  const { alertsActive, isLoading } = useAppSelector(state => state.alerts)
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  useEffect(() => {
    getAllertData()
  }, [])

  const getAllertData = async () => {
    setLoadingAlerts(true)

    await getAllAlerts()

    setLoadingAlerts(false)
  }

  if (loadingAlerts) return <CustomSpin section />

  return (
    <Row style={{ marginBottom: 25, padding: 20, maxHeight: 500, overflow: 'auto', backgroundColor: "#FFF", borderRadius: 16 }}>
      <Col span={24}>
        <Row justify="space-between" style={{ margin: '15px 0' }}>
          <h2 style={{ fontWeight: 600, color: '#252525' }}>
            ALERTAS
          </h2>
          <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ALERTS_CODES.ALERTS_TABLE)?.enabled ? 'No tienes permiso para realizar esta acción' : ''}`}>
            <Button
              disabled={!activeFeatures?.find(feature => feature.feature_code === ALERTS_CODES.ALERTS_TABLE)?.enabled}
              type="text"
              shape="round"
              htmlType="button"
              style={{ width: 165, border: "1px solid var(--primary)" }}
              onClick={() => handleRedirectTo('/alertas')}
            >
              <Row justify="space-evenly" align="middle">
                <span style={{ fontSize: 12, color: '#252525', fontWeight: 500 }}>
                  VER HISTORIAL
                </span>

                <ArrowRightOutlined style={{ fontSize: 10, color: '#252525' }} />
              </Row>
            </Button>
          </CustomPopoverConfirm>
        </Row>

        {alertsActive?.length
          ? (
            <Row gutter={[16, 16]} style={{ opacity: isLoading ? 0.5 : 1 }}>
              {alertsActive?.map((alertData, index) => <Alert activeFeatures={activeFeatures} key={`${alertData.created_time}+${index}`} alertData={alertData} handleOpenCloseDrawer={handleOpenCloseModal} />)}
            </Row>
          )
          : null
        }
      </Col>

      <DetailAlertDrawer isOpenDrawer={isOpenModal} handleOpenCloseDrawer={handleOpenCloseModal} />
    </Row>
  )
}

export default LastAlerts
