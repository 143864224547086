import { useState } from "react"
import { Button, Col, Form, Row } from "antd"
import { config } from "../../../../constants/constants"
import { CustomInput } from "../../../../UI/CustomInput"
import { CustomSelect } from "../../../../UI/CustomSelect"
import useCheckListCategory from "../../hooks/useCheckListCategory"
import useCurrentLocation from "../../../../hooks/useCurrentLocation"

const NewTaskReception = ({ hookCheckList, setVisible }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const { param } = useCurrentLocation()
  const { getAllCategoryChecklistToSelect } = useCheckListCategory()
  const { getAllCheckListItems, onSaveItemChecklist } = hookCheckList

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      await getAllCheckListItems(param.checklistId)
    
      form.resetFields()
      setVisible(false)
    }
  }
  
  const onSubmit = async (values) => {
    setLoading(true)
    const trimValue = values.categoryName.trim()

    const dataToSend = {
      checklist_id: param.checklistId,
      checklist_category_id: values.categoryId,
      name: trimValue,
    }

    const response = await onSaveItemChecklist(dataToSend)

    await onFinish(response)
    
    setLoading(false)
  }

  return (
    <div className="new-task-reception">
      <Form
        form={form}
        disabled={loading}
        onFinish={onSubmit}
        layout="vertical"
        name="createTasksForm"
      >
        <Row>
          <Col span={24}>
            <label style={{ color: '#676767' }}>
              Categorías
            </label>
            <Form.Item name="categoryId" {...config}>
              <CustomSelect
                isAsync
                allowClear
                service={getAllCategoryChecklistToSelect}
                placeholder="Seleccionar categoría"
              />
            </Form.Item>

            <label style={{ color: '#676767' }}>
              Nombre de la tarea
            </label>
            <Form.Item name="categoryName" {...config}>
              <CustomInput placeholder="Ej: Limpieza de pisos" />
            </Form.Item>

            <Row justify="center">
              <Button
                type="text"
                shape="round"
                htmlType="submit"
                loading={loading}
                disabled={loading}
                className="btn-standard-green"
              >
                <span>CREAR</span>
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};



export default NewTaskReception;
