import { Col, Form, Row } from "antd"
import MoreActions from "./MoreActions"
import { useContext, useEffect } from "react"
import useAdminChecklist from "../hooks/useAdminChecklist"
import CustomTable from "../../../../../UI/CustomTable/CustomTable"
import { AdminChecklistContext } from "../context/AdminChecklistContext"
import DeleteChecklistCategoryModal from "./modals/DeleteChecklistCategoryModal"
import NewEditCategoryChecklistModal from "./modals/NewEditCategoryChecklistModal"
import useOpenCloseModal from "../../../../../hooks/useOpenCloseModal"

const AdminChecklistTable = ({
  isOpenCategoryModal,
  handleOpenCloseCategoryModal
}) => {
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { getAllData, getChecklistCategories,changePageHandler } = useAdminChecklist()
  const {
    form,
    loading,
    filters,
    setFilters,
    currentPage,
    checklistCategoriesTableData,
    metadataCategories,
  } = useContext(AdminChecklistContext)
  
  const { columns = [], dataSource = [] } = checklistCategoriesTableData
  const { quantity = 0, limit = 10 } = metadataCategories

  useEffect(() => {
    getAllData()
  }, [])

  // Agregando columnas extras a las columnas desde el backend
  const newStyledColumns = columns.map(((column) => {
    const nameColumn = column.dataIndex === 'name'
      ? {
          ...column,
          sorter: true,
        }
      : column

    return {
      ...nameColumn,
    }
  }))

  const newColumns = [
    ...newStyledColumns,
    {
      dataIndex: 'actions',
      key: 'actions',
      title: 'Acciones',
      align: 'end',
      render: (_, record) => (
        <MoreActions
          form={form}
          handleOpenCloseModalNewEdit={handleOpenCloseCategoryModal}
          handleOpenCloseModalDelete={handleOpenCloseModal}
          dataChecklistAdmin={record}
        />
      )
    }
  ]

  const handleOnChangeTable = async ({ sorter }) => {
    if (sorter.order) {
      await getChecklistCategories({
        ...filters,
        sortBy: sorter?.order ? sorter.columnKey : '',
        sortOrder: sorter?.order ? (sorter?.order === 'ascend' ? 'asc' : 'desc') : '',
      })

      setFilters(state => ({
        ...state,
        sortBy: sorter?.order ? sorter.columnKey : '',
        sortOrder: sorter?.order ? (sorter?.order === 'ascend' ? 'asc' : 'desc') : ''
      }))

      return
    }

    setFilters(state => ({
      ...state,
      sortBy: '',
      sortOrder: ''
    }))
  }

  return (
    <Row>
      <Col span={24}>
        <CustomTable
          columns={newColumns ?? []}
          dataSource={dataSource ?? []}
          rowKey={record => record.checklist_category_id}
          className="custom-table-variant-admin-check"
          loading={loading}
          onChange={handleOnChangeTable}
          pagination={{
            total: quantity,
            pageSize: limit,
            onChange: async (current) => {
              await changePageHandler(current, limit)
            },
            showTotal: (total, range) => {
              return `${range[0]}-${range[1]} de ${total}`;
            },
            current: currentPage,
          }}
        />
        <NewEditCategoryChecklistModal form={form} isOpenModal={isOpenCategoryModal} handleOpenCloseModal={handleOpenCloseCategoryModal} />

        <DeleteChecklistCategoryModal isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
      </Col>
    </Row>
  )
}

export default AdminChecklistTable