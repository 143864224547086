import verifyResponse from '../../../../helpers/verifyResponse'
import { useActivesMaintenanceContext } from '../../context/ActiveMaintenanceContext'
import getCompanyAssetReportDetail from '../../services/GET/getCompanyAssetReportDetail'

export const useDetailMaintenanceReportServices = () => {
    const { auth } = useActivesMaintenanceContext()

    const getMaintenanceReportDetailService = async ({ company_asset_maintenance_id, setLoading }) => {
        const res = await getCompanyAssetReportDetail({ idToken: auth.idToken, company_asset_maintenance_id })
        
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        
        if (!status) {
            setLoading && setLoading(false)
            throw new Error('Error')
        }
        return res.data.data
    }
    return {
        getMaintenanceReportDetailService
    }
}