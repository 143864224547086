import { Col, Row } from "antd"
import { ReactComponent as SelectSurveyIcon } from '../../../../assets/icons/selectSurveyIcon.svg'
import useSurveyAnswers from "./hooks/useSurveyAnswers"
import MoreCommentaryAnswers from "./components/MoreCommentaryAnswers"
import UsersAnswerChartModal from "./components/UsersAnswerChartModal"
import CustomBarChart from "./components/CustomBarChart"
import EmptyComponent from "../../../../../../UI/EmptyComponent"
import { useEffect } from "react"


const SurveyAnswers = ({ chartData, }) => {
    const { questions, chartOptions, checkUpdatesModal, isAnswerProfileModal, isOpenModal, moreAnswersData, profileAnswersData, setIsAnswerProfileModal, setProfileAnswersData, setIsOpenModal, setMoreAnswersData, handleBarClick } = useSurveyAnswers({ chartData })

    useEffect(() => {        
        checkUpdatesModal()
    }, [questions]);

    return (
        <>
            {questions.map((answer) => {
                return <Col key={answer.order}>
                    <h2 className="survey-answer-question-header">PREGUNTA #{answer.order + 1}</h2>
                    <span className="survey-answer-question">{answer.question}</span>
                    {
                        answer.type !== 'commentary' && <CustomBarChart data={answer.data} labels={answer.labels} question={answer.question} questionIndex={answer.order} onBarClick={handleBarClick} type={answer.type} generalOptions={chartOptions} />
                    }

                    {answer.type === 'commentary' && <>
                        {
                            !answer.answers.length && <EmptyComponent label={'NO HAY RESPUESTAS'} height="100px" Icon={<SelectSurveyIcon />} />
                        }
                        {
                            answer.answers.slice(0, 4).map(answer => (
                                <Row key={answer.idR} className="item-answer-commentary">
                                    {answer.response}
                                </Row>
                            ))
                        }
                        {
                            answer.answers.length > 1 && (
                                <Row className="more-answers-commentary" justify="center" onClick={() => { setMoreAnswersData(answer); setIsOpenModal(true) }}>
                                    Ver más respuestas...
                                </Row>
                            )
                        }
                    </>}
                </Col>
            })}
            <MoreCommentaryAnswers isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} setMoreAnswersData={setMoreAnswersData} modalData={moreAnswersData} />
            <UsersAnswerChartModal isOpenModal={isAnswerProfileModal} setIsOpenModal={setIsAnswerProfileModal} answer={profileAnswersData} />
        </>
    )
}

export default SurveyAnswers