import axios from "axios";

const assignEmployee = async (URL_DEV, idTicket, employeeId) => {
  try {
    const emplyeeAssign = {
      ticket_id: idTicket,
      employee_id: employeeId,
    };
    const response = await axios.put(`${URL_DEV}/assign`, emplyeeAssign);

    return {
      success: true,
      ...response,
    };
  } catch (errorResponse) {
    return {
      success: false,
      ...errorResponse.response,
    };
  }
};

export default assignEmployee;
