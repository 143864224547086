import TypeNewBodyForm from "./TypeNewBodyForm"
import { CustomNavigate } from "../../../../UI/CustomNavigate"
import useCurrentLocation from "../../../../hooks/useCurrentLocation"

const NewEditTypeNewPage = () => {
  const { param } = useCurrentLocation()

  const isEditTypeNew = param.idNew !== 'new' ? 'EDITAR' : 'NUEVA'

  return (
    <div>
      <CustomNavigate title={`${isEditTypeNew} NOTICIA`} />

      <TypeNewBodyForm />
    </div>
  )
}

export default NewEditTypeNewPage