import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd'
import CustomModal from './../../../../../UI/CustomModal'
import { useState } from 'react'
import useUser from '../../hooks/useUser'
import { useSelector } from 'react-redux'

const NewUserHolderModal = ({
  modalData,
  isOpenModal,
  isDataHousesLoading,
  handleOpenCloseModal,
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [optionsHouses, setOptionsHouses] = useState([])
  const { onSaveNewUserHolder } = useUser()
  const { committee } = useSelector(state => state.auth)
  // Prepare Data
  const config = { rules: [{ required: true, message: "Por favor llenar el campo" }] }
  const optionsNomenclatureSelect = modalData.map((nomenclature) => ({ value: nomenclature.id, label: nomenclature.name }))
  
  const handleOnChangeFilterSelect = (nomenclatureValueSelected) => {
    const optionsHousesFiltered = modalData.find((nomenclature) => nomenclature.id === nomenclatureValueSelected)
  
    if (optionsHousesFiltered) {
      const optionsToHousesSelect = optionsHousesFiltered.houses.map((houses) => ({ value: houses.id, label: houses.name}))
      setOptionsHouses(optionsToHousesSelect)
    }
  }

  const onReset = () => form.resetFields()

  const onCancel = () => {
    onReset()
    handleOpenCloseModal()
  }

  const onFinish = (isSucessfull) => {
    if (isSucessfull){
      onCancel()
    }
    return null
  }

  const onSubmit = async (data) => {
    setLoading(true)

    const response = await onSaveNewUserHolder({ ...data, residential_id: committee.residential_id })
    onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal width={800} isOpenModal={isOpenModal} onCancel={onCancel}>
      <Form
        form={form}
        name="newUserHolderForm"
        layout="vertical"
        className="standard-form"
        disabled={loading || isDataHousesLoading}
        onFinish={onSubmit}
      >
        <Row align="center">
          <h2 style={{ color:"#232C4A", marginBottom: "35px" }}>NUEVO USUARIO TITULAR</h2>  
        </Row>

        <Row justify="center" gutter={16}>
          <Col className="gutter-row" sm={24} lg={12}>
            <p style={{ marginBottom: "5px", paddingLeft: "5px" }}>NOMENCLATURAS:</p>
            <Form.Item name="nomenclature_id" {...config}>
              <Select
                showSearch
                allowClear
                size="large"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                loading={isDataHousesLoading}
                placeholder="Seleccione nomenclatura"
                onChange={handleOnChangeFilterSelect}
                options={optionsNomenclatureSelect ?? []}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" sm={24} lg={12}>
            <p style={{ marginBottom: "5px", paddingLeft: "5px" }}>ESPACIOS:</p>
            <Form.Item name="house_id" {...config}>
              <Select
                showSearch
                allowClear
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                size="large"
                loading={isDataHousesLoading}
                placeholder="Seleccione la casa"
                options={optionsHouses}
              />
            </Form.Item>
          </Col>
        </Row>
        
        <Row justify="center" gutter={16}>
          <Col className="gutter-row" sm={24} lg={12}>
            <p style={{ marginBottom: "5px", paddingLeft: "5px" }}>NOMBRES DE CUENTA TITULAR:</p>
            <Form.Item name="first_name" {...config} >
              <Input
                size="large"
                placeholder="Nombres"
                maxLength={25}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" sm={24} lg={12}>
            <p style={{ marginBottom: "5px", paddingLeft: "5px" }}>APELLIDOS DE CUENTA TITULAR:</p>
            <Form.Item name="last_name" {...config} >
              <Input
                size="large"
                placeholder="Apellidos"
                maxLength={25}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center" gutter={16}>
          <Col className="gutter-row" sm={24} lg={12}>
            <p style={{ marginBottom: "5px", paddingLeft: "5px" }}>CÓDIGO DE ÁREA DE TELÉFONO:</p>
            <Form.Item name="phone_area"{...config}>
              <InputNumber
                size="large"
                min={1}
                maxLength={3}
                placeholder="Codigo"
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" sm={24} lg={12}>
            <p style={{ marginBottom: "5px", paddingLeft: "5px" }}>TELÉFONO TITULAR:</p>
            <Form.Item name="phone_number"{...config}>
              <InputNumber
                size="large"
                min={1}
                maxLength={8}
                placeholder="Telefono"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center">
          <Button
            htmlType="submit"
            loading={loading}
            className="btn-standard-green"
            shape="round"
          >
            <span>CREAR</span>
          </Button>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default NewUserHolderModal