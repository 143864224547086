export const getNewColumns =  (columns) => {
    const columnsTable = columns?.map(column => (
        {
            ...column,
            sorter: (a, b) => a[column.dataIndex].localeCompare(b[column.dataIndex]),
            render: (text) => {
                if(column.dataIndex === 'name'){
                    return (
                        <div className="name-column">
                            <img src="/assets/icons/user-icon.svg" alt="icono usuario"/>
                            <span>{text}</span>
                        </div>
                    )
                }

                return text
                
            }
        }
    ))

    

    return columnsTable
}