import { useAllCheckList } from "../../../hooks/useAllCheckList";
import CustomTable from "../../../../../../UI/CustomTable/CustomTable";

const CheckTable = () => {
  const { tasks, loading, taskColumns, currentPageTable, limitTable, quantityTable, setCurrentPageTable, handleChangeTable } = useAllCheckList();

  return (
    <CustomTable
      className="admincheck-table"
      isDispatch={false}
      rowKey={tasks.id}      
      columns={taskColumns}
      dataSource={tasks}
      loading={loading}     
      onChange={handleChangeTable} 
      pagination={
        {
          defaultCurrent: 10,
          pageSize: limitTable || undefined,
          total: quantityTable || undefined,
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} de ${total}`;
          },
          defaultPageSize: limitTable || undefined,
          current: currentPageTable || undefined,
          position: ["bottomRight"],
        }
      }
    />
  );
};

export default CheckTable;