import { Col, Divider, Row, Spin } from "antd";
import { CustomNavigate } from "../../../../../UI/CustomNavigate";
import { Comment } from "./components/Comment";
import { useHistoryComments } from "../../hooks/useHistoryComments";
import moment from "moment";
import "./historyCommentStyle.css";
import "moment/locale/es";
import { CustomSpin } from "../../../../../UI/CustomSpin";


export const HistoryComments = () => {
    const { comments, loading } = useHistoryComments();
    
    moment.updateLocale("es", {
        weekdays: "Domingo,Lunes,Martes,Miércoles,Jueves,Viernes,Sábado".split(","),
    });
    const newComments = { ...comments }.comments.sort((a,b) => moment(a.date).toDate() -moment(b.date).toDate()).reduce((acc, comment) => {
        const date = moment(comment.date).format("YYYY-MM-DD");
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(comment);
        return acc;
    }, {});

    return (
        <section className="section-wrapper" style={{ height: "auto" }}>
            <CustomNavigate title={"COMENTARIOS"} />
            <CustomSpin section tip="Cargando" size="large" spinning={loading}>
                <Row className="comments__principal-info">
                    <Row>
                        <p> Categoría: <span>{comments.category_name}</span> </p>
                    </Row>
                    <Row>
                        <p> Tarea: <span>{comments.task_name}</span> </p>
                    </Row>
                    <Row>
                        <p> Periodo: <span>{comments.period}</span> </p>
                    </Row>
                </Row>
                <Col>
                    {Object.entries(newComments).map(([date, comment]) => {
                        return (
                            <div key={date}>
                                <Divider>
                                    {moment(date).format("dddd D [de] MMMM [del] YYYY")}
                                </Divider>
                                {comment.map((comment, index) => {
                                    return (
                                        <Comment
                                            key={index}
                                            pictures={comment.images}
                                            files={comment.files}
                                            employee_name={comment.employee_name}
                                            message={comment.comment}
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
                </Col>
            </CustomSpin>
        </section>
    );
};
