// funcion para crear formData de un formulario con claves simples
export const dataToFormData = (data) => {
  const formData = new FormData()

  for (const key in data) {
    if(data[key]){
      formData.append(key, data[key])
    }
  }

  return formData
}

// Funcion para manejar cuando es un objeto lo que devuelve el formData de ant d 
export const dataToFormDataWithStringify = (data) => {
  const formData = new FormData()

  for (const key in data) {
    if(data[key]){
      formData.append(key, JSON.stringify(data[key]))
    }
  }

  return formData
}
