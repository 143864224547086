import {
	Col,
	Row,
	Card,
	Image,
	Typography,
} from "antd"

import handIcon from '../../../../../assets/icons/handIcon.svg'

const { Text } = Typography

export const AppBanner = ({ bannerColor, title, icon }) => (
<>
	<div style={{ width: "320px" }}>
		<Card
			bordered={false}
			bodyStyle={{
				borderRadius: "16px",
				backgroundColor: bannerColor,
				width: "320px",
				margin: "0px",
				padding: "0px",
			}}
		>
			<Row>
				<Col span={6}>
					<Image alt="banner-icon" style={{height: "80px" }} src={icon || handIcon} />
				</Col>
				<Col
					span={18}
					style={{ maxWidth: "230px", display: "inline-block" }}
				>
					<div
						style={{
							marginTop: "5px",
							marginLeft: "5px",
							marginRight: "5px",
						}}
					>
						<span
							style={{
								fontSize: 15,
								fontWeight: 600,
								textAlign: "justify",
								width: 190,
								color: '#FFFFFF'
							}}
						>
						  {title.length > 75
							? <Text
								style={{ width: 190, color: '#FFFFFF' }}
								ellipsis={{ tooltip: title ?? '' }}
							  >
								{title}
							  </Text>
							: title
						  }

						</span>
					</div>
				</Col>
			</Row>
		</Card>
	</div>
</>
)