import verifyResponse from '../../../../helpers/verifyResponse'
import { getTableDataHelper } from '../../../../helpers/getTableDataHelper'
import { getAllMaintenanceAsset } from '../../services/GET/getAllMaintenanceAsset'
import { useActivesMaintenanceContext } from '../../context/ActiveMaintenanceContext'
import getCompanyAssetReportDetail from '../../services/GET/getCompanyAssetReportDetail'

export const useHistoryMaintenanceServices = () => {
    const { auth } = useActivesMaintenanceContext()

    const getAllMaintenanceAssetService = async ({ company_asset_id, skip, limit, previousData, currentPage, setLoading, sort_by,sort_order }) => {
        const res = await getAllMaintenanceAsset({ token: auth.idToken, company_asset_id, skip, limit, sort_by, sort_order })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })

        if (!status) {
            setLoading && setLoading(false)
            throw new Error('Error')
        }
        const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata, skip, previousData, currentPage, actualLimit: limit })
        return { data, metadata }
    }

    const getCompanyActiveReportDetail = async (company_asset_maintenance_id) => {
      const response = await getCompanyAssetReportDetail({ idToken: auth.idToken, company_asset_maintenance_id })

      if (response.success) {
        // guardar en contexto
        return
      }

      return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
    }

    return {
        getCompanyActiveReportDetail,
        getAllMaintenanceAssetService,
    }
}