import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { GeneralInfo } from "./components/GeneralInfo";
import { EmployeeSchedule } from "./components/EmployeeSchedule/EmployeeSchedule";
import { useNewCheckList } from "../../../../hooks/useNewCheckList";
import { summaryCodeType } from "../../../../types/summaryCodeType";

export const FormNewCheckList = ({
  Form,
  form,
  formRef,
  config,
  onSubmit,
  onFailedSubmit,
}) => {
  const { taskStatus, handleOpenCloseCollapse } =
    useNewCheckList();

  return (
    <Form
      validateMessages={{ required: "Por favor, llenar el campo" }}
      disabled={taskStatus === summaryCodeType.DELETED ? true : false}
      ref={formRef}
      form={form}
      onFinish={onSubmit}
      onFinishFailed={onFailedSubmit}
      layout="vertical"
      name="newChecklist"
      autoComplete="off"
      style={{ gap: 24, display: "flex", flexDirection: "column" }}
    >
      <GeneralInfo
        config={config}
        Form={Form}
        handleOpenCloseCollapse={handleOpenCloseCollapse}
      />
      <Form.List
        initialValue={[]}
        name={"schedule"}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item key={field.key}>
                <EmployeeSchedule
                  Form={Form}
                  config={config}
                  fields={fields}
                  field={field}
                  remove={remove}
                  index={index}
                />
              </Form.Item>
            ))}
            <Form.ErrorList errors={errors} />
            <Form.Item className="newChecklist__add-schedule">
              <Button
                icon={<PlusCircleOutlined color="var(--primary)" />}
                shape="round"
                className="btn-standard-white"
                onClick={() => {
                  add();
                }}
              >
                AGREGAR TURNO
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};
