import { Row, Col } from "antd";
import { CustomDatePicker } from "../../../../../../../../../../UI/CustomDatePicker";
import { CustomInput } from "../../../../../../../../../../UI/CustomInput";
import { CustomRadioButton } from "../../../../../../../../../../UI/CustomRadio/CustomRadioButton";
import { useNewCheckList } from "../../../../../../../hooks/useNewCheckList";

export const EndCheckListOptions = ({ Form, config, field }) => {
  const { handleChangeEndDateOption } = useNewCheckList();
  return (
    <Col style={{ width: "100%", marginTop: 24 }}>
      <h4>FECHA QUE FINALIZA</h4>
      <Row>
        <Col span={12}>
          <Form.Item
            name={[field.name, "endDateOption"]}
            style={{ marginBottom: 0, height: "100%" }}
            {...config}
          >
            {/*Custom Radio en lugar de CustomCheck */}
            <CustomRadioButton
              onChange={() => handleChangeEndDateOption({ field })}
              options={[
                { label: "Nunca", value: "1" },
                { label: "El día", value: "2" },
                { label: "Después de", value: "3" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Row style={{ gap: 8 }}>
            <div style={{ visibility: "hidden", height: 35 }}>{"1"}</div>
            <Form.Item
              shouldUpdate={(prev, curr) => {
                return (
                  prev["schedule"][field.name]?.endDateOption !==
                  curr["schedule"][field.name]?.endDateOption
                );
              }}
              noStyle
            >
              {({ getFieldValue }) => {
                return (
                  <>
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      name={[field.name, "date_end"]}
                      key={[field.key, "date_end"]}
                      rules={[
                        {
                          required:
                            getFieldValue("schedule")[field.name]
                              ?.endDateOption === "2"
                              ? true
                              : false,
                          message: "Especifica el día que finaliza la tarea",
                        },
                      ]}
                    >
                      <CustomDatePicker
                        placeholder={"Fecha de finalización"}
                        disabled={
                          getFieldValue("schedule")[field.name]
                            ?.endDateOption !== "2"
                        }
                        disabledDatePreviousWeek={true}
                        format={"dddd DD, MMM, YYYY"}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      name={[field.name, "occurrences"]}
                      key={[field.key, "occurrences"]}
                      rules={[
                        {
                          required:
                            getFieldValue("schedule")[field.name]
                              ?.endDateOption === "3"
                              ? true
                              : false,
                          message: "Especifica el número de ocurrencias",
                        },
                      ]}
                    >
                      <CustomInput
                        disabled={
                          getFieldValue("schedule")[field.name]
                            ?.endDateOption !== "3"
                        }
                        type={"NUMBER"}
                        min={0}
                        inputNumberText={"ocurrencias"}
                      />
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
