import moment from "moment"
import { useDebounce } from "../../../../hooks/useDebounce"
import { dataToFormData } from './../../../../../helpers/dataToFormData';
import { useActivesMaintenanceServices } from "./useActivesMaintenanceServices"
import { useActivesMaintenanceContext } from "../../context/ActiveMaintenanceContext"

export const useActivesMaintenance = () => {
  const {
    dataTableActiveMaintenance, limitTableActiveMaintenance, quantityTableActiveMaintenance,
    currentActiveMaintenanceParams, currentPageTableActiveMaintenance, dataTableProviders,
    limitTableProviders, quantityTableProviders, currentPageTableProviders, auth, searchValue,
    loading, loadingProvider,setCurrentActiveMaintenanceParams, setCurrentPageTableActiveMaintenance,
    setDataTableActiveMaintenance, setMetaDataTableActiveMaintenance, setLastSkipActiveMaintenance,
    setCurrentPageTableProviders, setMetaDataTableProviders, setDataTableProviders, setSearchValue,
    setLoading, setLoadingProvider, setColumnsTableActiveMaintenance, setColumnsTableProviders,
    statusActivesMaintenance, setStatusActivesMaintenance, currentActiveTab, setClickedActive, clickedActive,
    setDetailActiveDrawer, handleOpenCloseDrawer, handleOpenCloseExcelReportModal, isOpenExcelReportModal,
    isOpenDrawer, detailActiveDrawer, showProvidersModal, setShowProvidersModal, showCreateActiveModal,
    setShowCreateActiveModal, isOpenModal, handleOpenCloseModal, setFilesList, filesList
  } = useActivesMaintenanceContext()

  const {
    getAllCompanyAssetsService, getAllCompanyProvidersService, createUpdateCompanyAssetService,
    createCompanyProviderService, getAllStatusMaintenance, getActiveReport, getDetailActive,
    onDeleteCompanyAsset
  } = useActivesMaintenanceServices()
  
  const commonParams = (tableName) => ({
    limit: tableName === 'providers' ? limitTableProviders : limitTableActiveMaintenance,
    prevData: tableName === 'providers' ? dataTableProviders : dataTableActiveMaintenance,
    currentPage: tableName === 'providers' ? currentPageTableProviders : currentPageTableActiveMaintenance
  })

  const getInitialData = async ({ force }) => {
    if ((dataTableActiveMaintenance.length || dataTableProviders.length) && !force) return setLoading(false)

    const response = await getAllStatusMaintenance()

    const { data: activeMaintenanceData, metadata: activeMaintenanceMetaData } = await getAllCompanyAssetsService({ limit: limitTableActiveMaintenance, skip: 0, prevData: dataTableActiveMaintenance, company_asset_status_id: response?.data?.data[0]?.company_asset_status_id || currentActiveTab  })
    const { data: providersData, metadata: providersMetaData } = await getAllCompanyProvidersService({ limit: limitTableProviders, skip: 0, prevData: dataTableProviders })
    
    setColumnsTableActiveMaintenance(activeMaintenanceData.columns);
    setDataTableActiveMaintenance(activeMaintenanceData?.dataSource)
    setMetaDataTableActiveMaintenance(activeMaintenanceMetaData)
    setColumnsTableProviders(providersData.columns);
    setDataTableProviders(providersData?.dataSource)
    setMetaDataTableProviders(providersMetaData)
    setLoading(false)
  }

  const handleCloseProviderModal = () => {
    setShowProvidersModal(false)
    setShowCreateActiveModal(false)
  }

  const debounceSearch = useDebounce(async ({ value }) => {
    const actualParams = { ...currentActiveMaintenanceParams }
    const newParams = { ...actualParams, find_by: 'name', find_value: value }
    setLoading(true)
    if (!value) {
        delete newParams.find_by
        delete newParams.find_value
    }
    const { data, metadata } = await getAllCompanyAssetsService({ ...newParams, ...commonParams(), skip: 0, setLoading, company_asset_status_id: currentActiveTab })
    setDataTableActiveMaintenance(data.dataSource)
    setMetaDataTableActiveMaintenance(metadata)
    setCurrentActiveMaintenanceParams(newParams)
    setCurrentPageTableActiveMaintenance(1)
    setLastSkipActiveMaintenance(0)
    setLoading(false)
  }, 300)

  const handleSearchActiveMaintenance = async (e) => {
    const value = e.target.value
    setSearchValue(value)
    debounceSearch({ value })
  }

  const onFinishCreateAsset = async (isSuccessfull, form, isEdit) => {
    if (isSuccessfull) {
      await getInitialData({ force: true })
      form.resetFields()
      setFilesList([])
      setShowCreateActiveModal(false)
      isEdit && handleOpenCloseDrawer()
    }
  }

  const onFinishCreateProvider = async (isSuccessfull, form) => {
    if (isSuccessfull) {
      form.resetFields()
      setLoadingProvider(false)
    }
  }

  /**Funcion que controla los formularios para buscar en la tabla principal, en los proveedores y para un nuevo activo */
  const onSubmit = async ({ values, formName, form }) => {
    if (formName === 'providers-form') {
      setLoadingProvider(true)
      const data = await createCompanyProviderService({ formData: values, setLoading: setLoadingProvider })
      setDataTableProviders(prev => [...prev, data])
      setMetaDataTableProviders(prev => ({
          ...prev,
          quantity: prev.quantity + 1,
      }))

      await onFinishCreateProvider(data, form)

      return
    }
    setLoading(true)

    const isEdit = Boolean(clickedActive?.company_asset_id)

    const isEditActive = isEdit
        ? { company_asset_id: clickedActive?.company_asset_id }
        : {}

    const objTransform = {
      ...isEditActive,
      name: values.name,
      description: values.description,
      next_maintenance_date: moment(values.next_maintenance_date).format('YYYY-MM-DD'),
      company_id: auth.currentRol,
      provider_id: isEdit ? values.provider_id.value : values.provider_id,
      code: values.code,
      cost: values.cost
    }

    const dataToSend = dataToFormData(objTransform)

    // Se filtran archivos nuevos
    const newFiles = filesList?.filter((filterData) => !(filterData.url))?.map((filesData) => filesData?.originFileObj)

    // Se filtran los archivos viejos
    const currentFiles = filesList?.filter((filterData) => filterData.url)?.map((oldFile) => oldFile?.url)

    if (newFiles?.length > 0) {
      newFiles?.map((newFileData) => dataToSend.append('files', newFileData))
    }

    if (currentFiles?.length > 0) {
      currentFiles?.map((currentFilesData) => dataToSend.append('current_files', currentFilesData))
    }

    const response = await createUpdateCompanyAssetService({ formData: dataToSend })
    
    await onFinishCreateAsset(response, form, isEdit)

    setLoading(false)
  }

  return {
    getAllStatusMaintenance, dataTableActiveMaintenance, dataTableProviders, showProvidersModal,
    currentPageTableProviders, currentPageTableActiveMaintenance, quantityTableActiveMaintenance,
    quantityTableProviders, limitTableActiveMaintenance, limitTableProviders, showCreateActiveModal,
    filesList, searchValue, loading, loadingProvider, getAllCompanyProvidersService, setFilesList,
    setShowCreateActiveModal, handleSearchActiveMaintenance, setCurrentPageTableProviders,
    setShowProvidersModal, handleCloseProviderModal, getInitialData, onSubmit, statusActivesMaintenance,
    setStatusActivesMaintenance, setClickedActive, setDetailActiveDrawer, handleOpenCloseDrawer,
    handleOpenCloseExcelReportModal, isOpenExcelReportModal, getActiveReport, auth, currentActiveTab,
    getDetailActive, isOpenDrawer, detailActiveDrawer, isOpenModal, handleOpenCloseModal, clickedActive,
    onDeleteCompanyAsset, getAllCompanyAssetsService, setColumnsTableActiveMaintenance, setDataTableActiveMaintenance,
    setMetaDataTableActiveMaintenance
  }
}