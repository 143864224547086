import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const accessProvidersPath = "event/provider-access"

export const getAllAccessProviders = async ({
    token,
    company_id = 1,
    provider_id,
    date_begin,
    date_end
}) => {
    try {
        const params = {
            company_id,
            provider_id,
            date_begin,
            date_end
        };

        const response = await axiosCreate.get(`${accessProvidersPath}`, {
            params, timeout: 10000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
};