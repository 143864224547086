import { collection, query, where, orderBy, getDocs, doc, getDoc } from "firebase/firestore";
import { FirebaseDB } from '../firebase/config';
import moment from 'moment';
import 'moment-timezone';


export const getSurveysHelper = async (residential_id) => {
  const collectionRef = collection(FirebaseDB, `/surveys`);
  const queryCollection = query(collectionRef, where("active", "==", true), where('residential_id', "==", residential_id), orderBy("created_at"));
  const querySnapshot = await getDocs(queryCollection);

  const dataList = [];
  querySnapshot.forEach(async (documment) => {
    const data = documment.data();
    data.id = documment.id;
    data.created_at = !data.created_at ? "" : moment(data?.created_at?.toDate()).format("Y-MM-DD")
    if (data.startDate) {
      data.startDate = moment(data?.startDate?.toDate()).format("DD/MM/YYYY")
    }
    if (data.endDate) {
      data.endDate = moment(data?.endDate?.toDate()).format("DD/MM/YYYY")
    }
    const respuesta = []
    data.respuesta = respuesta
    dataList.push(data);
  });
  return dataList;
}

export const getActiveSurveyHelper = async (surveys_id) => {
  const collectionRefS = doc(FirebaseDB, `/surveys/${surveys_id}`)
  const querySnapshotS = await getDoc(collectionRefS)
  if (querySnapshotS.exists()) {
    const allData = querySnapshotS.data()
    allData.id = surveys_id
    allData.created_at = !allData.created_at ? "" : moment(allData?.created_at?.toDate()).format("Y-MM-DD")
    if (allData.startDate) {
      allData.startDate = moment(allData?.startDate?.toDate()).format("DD/MM/YYYY")
    }
    if (allData.endDate) {
      allData.endDate = moment(allData?.endDate?.toDate()).format("DD/MM/YYYY")
    }
    return allData
  }
}

export const getProfilesSurveysHelper = async (profile_id = 'T6wryJLy6zJEsSfb1JeS') => {
  const collectionRefS = doc(FirebaseDB, `profile/${profile_id}`)
  const querySnapshotS = await getDoc(collectionRefS)
  if (querySnapshotS.exists()) {
    const allData = querySnapshotS.data()

    return { id: profile_id, name: allData.name, lastname: allData.lastname, phone: allData.phone, code: allData.code, image: allData.image, active: allData.active, area: allData.area, }
  }
  return null
}

export const getSurveysResponseHelper = async (residential_id, surveys_id) => {
  const respuesta = []
  const collectionRefS = collection(FirebaseDB, `/surveys_response`);
  const queryCollectionS = query(collectionRefS,
    where("surveys_id", "==", surveys_id),
    where('residential_id', "==", residential_id),
    orderBy("profile_id")
  );

  const querySnapshotS = await getDocs(queryCollectionS);
  querySnapshotS.forEach(async (docummentR) => {

    const dataR = docummentR.data();
    dataR.idR = docummentR.id;
    respuesta.push(dataR)
  })
  return respuesta;
}

export const getSurveysQuestionHelper = async (surveys_id) => {

  const respuesta = []
  const collectionRefS = collection(FirebaseDB, `/surveys/` + surveys_id + `/questions`);
  const queryCollectionS = query(collectionRefS);

  const querySnapshotS = await getDocs(queryCollectionS);
  querySnapshotS.forEach(async (docummentR) => {

    const dataR = docummentR.data();
    dataR.idR = docummentR.id;
    respuesta.push(dataR)
  })

  return respuesta;
}

export const getAllUserResidential = async (residential_id) => {

  let totalUser = 0
  const collectionRef = collection(FirebaseDB, `/profile`);
  const queryCollection = query(collectionRef,
    where("active", "==", true),
    where('residentialsIds', "array-contains", residential_id),
  );

  const querySnapshot = await getDocs(queryCollection);
  totalUser = querySnapshot.size
  return totalUser;
}

export const columnsAlertTable = () => {
  return [
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Fecha Inicio',
      dataIndex: 'start_date',
      key: 'start_date'
    },
    {
      title: 'Fecha Fin',
      dataIndex: 'end_date',
      key: 'end_date',
    },
    {
      title: 'Tipo Encuesta',
      dataIndex: 'survey_type',
      key: 'survey_type',
    },
  ];
}