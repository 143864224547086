import axios from "axios"

const getPlacesTypesHierarchyAdmin = async (URL_DEV, idToken) => {
  try {
    const response = await axios.get(`${URL_DEV}/place/type`,
      { headers: { Authorization: `Bearer ${idToken}` } })
    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getPlacesTypesHierarchyAdmin