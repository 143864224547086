import { topiaTemplate } from '../../../mocks/topiaTemplate';

export const InfoPanel = () => {

  const initData = topiaTemplate().templateData.infoSectionData.initData;
  const infoImages = topiaTemplate().templateData.infoSectionData.infoImages;
  return (
    <>
      <img className="logo" src={infoImages.logo} alt="Topia - Logo" width={256} height={128} />
      <h2 style={initData.h2.style} id="login-slogan">
        {initData.h2.firstText}
        <br />
        {initData.h2.secondText}
        <span style={initData.span.style}>{initData.span.text}</span>
      </h2>
    </>
  );
};
