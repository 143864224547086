//Se muestran los modulos de los roles que están activos en una lista en la parte izquierda de la página
const RolesModules = ({ modulesRole, handleChangeModuleRole }) => (
    <section className="detailRoles-section">
        <h2>MÓDULOS DISPONIBLES</h2>
        <ul className="moduleRole-container">
            {modulesRole?.data?.map(module => (
                //Dependiendo de si un modulo esta activo se añade una clase adicional
                <li className={`moduleRole-item ${module.module_id === modulesRole.active ? 'active' : ''}`} key={module.module_id} onClick={() => handleChangeModuleRole({ module_id: module.module_id })}>
                    {module.name}
                </li>
            ))}
        </ul>
    </section>
)

export default RolesModules
