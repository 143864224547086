//EMPLEADOS ✅
export const employeeModule = {
    feature_code: 'FEAT-279',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-41',
            enabled: false,
        },
        {
            feature_code: 'FEAT-43',
            enabled: false,
        },
        {
            feature_code: 'FEAT-44',
            enabled: false,
        },
        {
            feature_code: 'FEAT-45',
            enabled: false,
        },
        {
            feature_code: 'FEAT-42',
            enabled: false,
        },
    ]
}