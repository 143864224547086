import { Col, Row } from "antd"
import { CustomSelect } from "../../../UI/CustomSelect"
import useImageChecklist from "../hooks/useImageChecklist"
import { CustomDatePicker } from "../../../UI/CustomDatePicker"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { setFilters } from "../../../../store/adminChecklist/adminChecklistSlice"

const FiltersImageChecklistTable = () => {
  const dispatch = useAppDispatch()

  const { getChecklistsInstances, getAllEmployees } = useImageChecklist()
  const { filters, ornamentStatusChecklist, activeOrnamentChecklist } = useAppSelector(state => state.adminCheck)

  const checklistOptionsSelect = activeOrnamentChecklist?.map((checklistData) => ({ id: checklistData.checklist_id, name: checklistData.name }))
  const statusOptionsSelect = ornamentStatusChecklist?.map((statusData) => ({ id: statusData.checklist_instance_status_id, name: statusData.name }))

  const handleOnChangeDate = async (dates, dateStrings) => {    
    if (dateStrings[0] || dateStrings[1]) {
      await getChecklistsInstances({ ...filters, dateBegin: dateStrings[0], dateEnd: dateStrings[1] })

      dispatch(setFilters({ ...filters, dateBegin: dateStrings[0], dateEnd: dateStrings[1] }))

      return
    }

    await getChecklistsInstances({ ...filters, dateBegin: '', dateEnd: '' })

    dispatch(setFilters({ ...filters, dateBegin: '', dateEnd: '' }))
  }

  const handleOnChangeStatus = async (value) => {
    if (value) {
      await getChecklistsInstances({ ...filters, checklistIdStatus: value })
  
      dispatch(setFilters({ ...filters, checklistIdStatus: value }))

      return
    }

    await getChecklistsInstances({ ...filters, checklistIdStatus: '' })

    dispatch(setFilters({ ...filters, checklistIdStatus: '' }))
  }
  
  const handleOnChangeEmployees = async (value) => {
    if (value) {
      await getChecklistsInstances({ ...filters, employeeId: value })
  
      dispatch(setFilters({ ...filters, employeeId: value }))

      return
    }

    await getChecklistsInstances({ ...filters, employeeId: '' })

    dispatch(setFilters({ ...filters, employeeId: '' }))
  }

  const handleOnChangeCheck = async (value) => {
    if (value) {
      await getChecklistsInstances({ ...filters, checklistId: value })

      dispatch(setFilters({ ...filters, checklistId: value }))

      return
    }

    await getChecklistsInstances({ ...filters, checklistId: 0 })

    dispatch(setFilters({ ...filters, checklistId: 0 }))
  }

  return (
    <Row
      gutter={16}
      style={{
        marginInline: 1,
        marginTop: 40,
        padding: '24px 8px',
        backgroundColor: '#FFFFFF',
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
      }}
    >
      <Col span={4}>
        <CustomSelect
          allowClear
          onChange={handleOnChangeCheck}
          options={checklistOptionsSelect ?? []}
          placeholder="Todos los checklist"
        />
      </Col>

      <Col span={4}>
        <CustomSelect
          isAsync
          allowClear
          onChange={handleOnChangeEmployees}
          placeholder="Empleado"
          service={getAllEmployees}
        />
      </Col>

      <Col span={4}>
        <CustomDatePicker
          allowClear
          type="RANGER"
          format="YYYY-MM-DD"
          onChange={handleOnChangeDate}
        />
      </Col>

      <Col span={4}>
        <CustomSelect
          allowClear
          placeholder="Status"
          options={statusOptionsSelect ?? []}
          onChange={handleOnChangeStatus}
        />
      </Col>
    </Row>
  )
}

export default FiltersImageChecklistTable