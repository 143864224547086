import { Col, Row } from "antd"
import HeaderResultsChecklist from "./layout/HeaderResultsChecklist"
import ResultsChecklistTable from "./components/ResultsChecklistTable"
import ResultsChecklistDetail from "./components/ResultsChecklistDetail"
import { ChecklistContextProvider } from "./context/ChecklistResultsContext"

const ResultsChecklistTests = () => (
  <ChecklistContextProvider>
    <Row>
      <Col span={24}>
        <HeaderResultsChecklist />

        <Row gutter={16} style={{ marginTop: 40 }}>
          <Col span={12}>
            <ResultsChecklistTable />
          </Col>

          <Col span={12}>
            <ResultsChecklistDetail />
          </Col>
        </Row>
      </Col>
    </Row>
  </ChecklistContextProvider>
)

export default ResultsChecklistTests
