import { useState } from "react";
import ProfileDrawer from "./ProfileDrawer";
import TableEmployee from "./TableEmployee";
import useEmployee from "../hooks/useEmployee";
import NewEditEmployee from "./modals/NewEditEmployee";
import EmployeeOffBoarding from "./EmployeeOffBoarding";

const Employee = ({ companyId, setIsModalOpen, setIsVisible, isModalOpen, isVisible, departmentSelected, activeFeatures }) => {
  const employeeHook = useEmployee();
  const { employeeSelected, getEmployeeInfo, clearEmployeeSelected, setEmployeeSelected } = employeeHook
  const [isOffBoardingOpen, setIsOffBoardingOpen] = useState(false);

  const employeeProfileHandler = (id) => {
    setIsVisible(true);
    getEmployeeInfo(id);
  };

  const onCloseHandler = (closeAction) => {
    closeAction(false);
    clearEmployeeSelected();
  };

  const openProfileOpt = (sectionId) => {
    setIsVisible(false);
    setIsOffBoardingOpen(true);
    setEmployeeSelected((prev) => ({
      ...prev,
      sectionId, // El section id define como se comportara el modal  1 = INCAPACIDAD / 2 = BAJA
    }));
  };


  return (
    <>
      <TableEmployee
        activeFeatures={activeFeatures}
        companyId={companyId}
        setIsModalOpen={setIsModalOpen}
        setIsOffBoardingOpen={setIsOffBoardingOpen}
        employeeProfileHandler={employeeProfileHandler}
        departmentSelected={departmentSelected}
        employeeHook={employeeHook}
      />

      <ProfileDrawer
        activeFeatures={activeFeatures}
        isVisible={isVisible}
        onClose={() => onCloseHandler(setIsVisible)}
        openProfileOpt={openProfileOpt}
        employeeSelected={employeeSelected}
      />

      <NewEditEmployee
        companyId={companyId}
        visible={isModalOpen}
        onClose={() => onCloseHandler(setIsModalOpen)}
        employeeSelected={employeeSelected}
        employeeHook={employeeHook}
      />

      <EmployeeOffBoarding
        visible={isOffBoardingOpen}
        onClose={() => onCloseHandler(setIsOffBoardingOpen)}
        employeeSelected={employeeSelected}
        employeeHook={employeeHook}
        companyId={companyId}
      />
    </>
  );
};

export default Employee;
