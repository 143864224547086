export const phoneFormatHandler = (e) => {
    const maxLength = 8
    let value = e.target.value.replace(/\D/g, '') 
  
    value = value.slice(0, maxLength) 
  
    if (value.length > 4) {
      value = value.replace(/^(\d{4})/, '$1-') 
    }
  
    e.target.value = value
};

