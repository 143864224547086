import spinIntegroLogo from '../../../assets/logos/integroLogo.svg'
import logo from '../components/BetoTemplate/assets/betoLogo.svg'
import firstImage from '../components/BetoTemplate/assets/betoImage1.svg'
import leftBackground from '../components/BetoTemplate/assets/leftSide.svg'

//Definir por defecto un objeto por si no viene data en el parámetro de data
const defaultData = {
  logo,
  firstImage,
  bottomLogo: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/assets/integro/151e4bdd-dbfa-4f2d-8a04-34000e27dcb6.png',
  leftBackground
}
//Template con el cual se podría tomar para reactualizar los datos del mismo con base a un id que lo identifique...
export const betoTemplate = (data) => {

  //Parsear la data para utilizarla
  const imagesTemplates = {
    logo: data?.find(image => image?.code === '1')?.image || defaultData.logo,
    leftBackground: data?.find(image => image?.code === '2')?.image || defaultData.leftBackground,
    bottomLogo: data?.find(image => image?.code === '3')?.image || defaultData.bottomLogo,
  }

  return {
    templateName: "BetoTemplate",
    templateData: {
      spinData: {
        spinLogo: spinIntegroLogo,
        spinColor: '#252525'
      },
      imageSectionData: {
        background: imagesTemplates.leftBackground,
        logo: imagesTemplates.logo,
        firstImage: imagesTemplates.firstImage
      },
      infoSectionData: {
        initData: {
          h1: {
            text: "Panel Administrativo",
            style: {
              fontWeight: 700,
              fontSize: 30,
              lineHeight: "36.57px",
              textAlign: "center",
            },
          },
          p: {
            text: "Administración de Centros Comerciales",
            style: {
              fontSize: 16,
              fontFamily: "Poppins, sans-serif",
              color: "#717171",
              fontWeight: 400,
              textAlign:'center',
            },
          },
        },
        loginForm: {
          input: {
            shared: {
              label: {
                style: {
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#5B5B5B",
                  fontFamily: "Poppins, sans-serif",
                },
              },
              input: {
                style: {
                  border: "1px solid #e2e2e2",
                  backgroundColor: "#fbfbfb",
                },
              },
            },
            email: {
              label: {
                text: "Email",
              },
              input: {
                placeholder: "Correo Institucional",
                prefix: ""
              },
            },
            password: {
              label: {
                text: "Contraseña",
              },
              input: {
                placeholder: "Contraseña asignada",
                prefix: "",
              },
            },
          },
          button: {
            text: "INGRESAR",
            style: {
              color: "#FFF",
              fontFamily: "Poppins, sans-serif",
              fontSize: 14,
              fontWeight: 500,
              textAlign: "center",
              backgroundColor: "var(--primary)",
            },
          },
        },
        bottomInfo: {
          logo: imagesTemplates.bottomLogo,
        }
      },
    },
  }
}