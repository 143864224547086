import useUsers from "../hooks/useUsers"
import { useContext, useEffect } from "react"
import { Button, Col, Image, Row } from "antd"
import { UsersContext } from "../context/UsersContext"
import NewEditUserModal from "./modals/NewEditUserModal"
import CustomTable from "../../../UI/CustomTable/CustomTable"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import { USERS_CODES } from "../../../../constants/modules/user/userCodes"

//imagenes o archivos
import EmptyComponent from "../../../UI/EmptyComponent"
import EditIcon from "../../../../assets/icons/EditIcon"
import propertiesIcon from '../../../../assets/icons/Generals/properties-icon.svg'

const UsersTable = ({ activeFeatures, handleOpenCloseDrawer }) => {
  const {
    getTypesOwner, getUsersTableData,
    getDetailUserData, getDetailUserToUpdate,
    getAvailabilityOptions,
  } = useUsers()
  
  const {
    form, filters, setFilters,
    loading, metadata,
    usersTableData, currentPage,
    setCurrentPage, handleOpenCloseModal,
  } = useContext(UsersContext)

  const { columns = [], dataSource = [] } = usersTableData
  const { limit = 10, quantity = 0 } = metadata

  useEffect(() => {
    getInitialData()
  }, [])

  const getInitialData = async() => {
    await getUsersTableData({ findValue: '' })
    await getTypesOwner()
    await getAvailabilityOptions()
  }

  const handleOnClickEditModal = async (ownerData) => {
    const response = await getDetailUserToUpdate(ownerData.owner_profile_id)

    if (response.success) {
      const { profile = {}, places = [] } = response.data.data

      let assignedPlaces = {}

      assignedPlaces['space_'] =  ({ value: places[0]?.place_id || 0, label: places[0]?.name || "No posee espacio asignado" })
      // Debe de seguir el orden de parking primero y luego warehouse
      assignedPlaces['parking_'] =  (places[0]?.dependencies[0]?.type === 'parking')
        ? ({ value: places[0]?.dependencies?.[0]?.place_id || 0, label: places[0]?.dependencies[0]?.name || "No posee parqueo asignado" })
        : ({ value: 0, label: "No posee parqueo asignado" })
      assignedPlaces['ware_house_'] = (places[0]?.dependencies[1]?.type === 'warehouse')
        ? ({ value: places[0]?.dependencies?.[1]?.place_id || 0, label: places[0]?.dependencies[1]?.name || "No posee bodega asignada" })
        : ({ value: 0, label: "No posee parqueo asignado" })
      if (places.length > 1) {
        places.slice(1).forEach((place, index) => {
          assignedPlaces[`type_user_new_space_${index}`] = place?.place_owner_type_id || 1
          assignedPlaces[`space_${index}`] = ({ value: place?.place_id || 0, label: place?.name || "No hay espacio asignado" })
          assignedPlaces[`ware_house_${index}`] = ({ value: place.dependencies?.[0]?.place_id || 0, label: place.dependencies[0]?.name || "No posee bodega asignada" })
          assignedPlaces[`parking_${index}`] = ({ value: place.dependencies?.[1]?.place_id || 0, label: place?.dependencies?.[1]?.name || "No posee parqueo asignado" })
        })
      }
  
      form.setFieldsValue({
        email: profile?.mail || "",
        name: profile?.first_name || "",
        last_name: profile?.last_name || "",
        phone_area: profile?.phone_area || "",
        phone_number: profile?.phone_number || "",
        ...assignedPlaces
      })

      handleOpenCloseModal()
    }
  }

  const newColumns = columns?.map((column) => {
    const isActions = column?.key === 'actions'
      ? {
        ...column,
        render: (_, record) => <Button
            type="link"
            htmlType="button"
            shape="circle"
            style={{ border: '1px solid #404040', opacity: record?.owner_profile_id ? 1 : 0.5 }}
            disabled={!(record?.owner_profile_id)}
            onClick={() => handleOnClickEditModal(record)}
          >
            <EditIcon color="var(--primary)" />
          </Button>
        }
      : {}

    const isAddress = column?.key === 'place_address' 
      ? {
        ...column,
        align: 'start'
      }
      : {}

    const isProperties = column?.key === 'place_owned_quantity'
      ? {
        ...column,
        render: (_, record) => (<CustomPopoverConfirm trigger={'hover'} content={record?.owned_places?.length
            ? (<Row>
                <Col span={24}>
                  <h3 style={{ fontWeight:500, fontSize: 11, color: '#232323' }}>PROPIEDADES</h3>
                  
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {record?.owned_places?.map((ownedPlaceData, index) => (
                      <li key={`${ownedPlaceData}-${index}`}>
                        <p style={{ fontWeight: 400, fontSize: 10, color: '#252525' }}>
                          {ownedPlaceData}
                        </p>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>)
            : <h3 style={{ fontWeight:500, fontSize: 11, color: '#232323', margin: 0 }}>NO POSEE PROPIEDADES</h3>}
          >
          <Row>
            <Col>
              <Row
                gutter={6}
                align="middle"
                justify="center"
                style={{ padding: '4px 8px', border: '1px solid #404040', borderRadius: 50 }}
              >
                <Col>
                  <Image src={propertiesIcon} preview={false} />
                </Col>

                <Col>
                  <label style={{ color: '#404040', fontSize: 11, fontWeight: 400 }}>
                    {parseInt(record?.places_owned_quantity) > 10
                      ? record?.places_owned_quantity
                      : `0${record?.places_owned_quantity}`
                    }
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
        </CustomPopoverConfirm>)
    }
    : {}
    
    return {
      ...column,
      ...isAddress,
      ...isProperties,
      ...isActions,
    }
  })

  const handleOnRowClick = async (ownerData) => {
    const response = await getDetailUserData(ownerData?.place_id)

    if (response) {
      handleOpenCloseDrawer()
    }
  }

  const handleOnChangeTable = async ({ sorter, pagination }) => {
    const newSkip = (pagination?.current * limit) - limit

    let isSort = ''
    let isColumnKeyAdded = ''

    if (sorter?.order) {
      isSort = sorter?.order === 'ascend' ? 'asc' : 'desc'
      isColumnKeyAdded = sorter?.columnKey
    }

    await getUsersTableData({
      ...filters,
      sortOrder: isSort,
      sortBy: isColumnKeyAdded,
      limit: limit,
      skip: newSkip,
    })

    setFilters(state => ({
      ...state,
      sortOrder: isSort,
      sortBy: isColumnKeyAdded,
      limit: limit,
      skip: newSkip,
    }))

    setCurrentPage(pagination?.current)
  }

  return (<Row style={{ backgroundColor: '#FFFFFF', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
    <Col span={24} style={{ paddingInline: 16 }}>
      {activeFeatures?.find(feature => feature.feature_code === USERS_CODES.USERS_TABLE)?.enabled ? (
        <CustomTable
          loading={loading}
          rowClassName="row-className"
          className="custom-table-users"
          columns={newColumns ?? []}
          dataSource={dataSource ?? []}
          rowKey={(record) => record.place_id}
          onChange={handleOnChangeTable}
          pagination={{
            total: quantity,
            pageSize: limit,
            showTotal: (total, range) => {
              return `${range[0]}-${range[1]} de ${total}`;
            },
            current: currentPage,
          }}
          onRow={(record) => {
            return {
              onDoubleClick: () => handleOnRowClick(record)
            }
          }}
        />
      ) : <EmptyComponent label={'NO TIENES PERMISO PARA VER ESTOS DATOS'} />}

      <NewEditUserModal />
    </Col>
  </Row>
  )
}

export default UsersTable