import { Col, Empty, Row } from "antd"
import { useState } from "react"
import EmptyComponent from "../../../UI/EmptyComponent"
import CustomChatComponent from '../../../UI/CustomChat/CustomChatComponent'
import CustomConversationsList from '../../../UI/CustomChat/CustomConversationsList'

const Conversations = ({
  getConversations,
  isLoading,
  isMetadata,
  conversations,
}) => {
  const [currentChat, setCurrentChat] = useState('emptyChat')

  const verifyBody = (currentBody) => {
    let objBody = {
      'selectedChat': <CustomChatComponent height={800} maxWidth="100%" urlSocket="https://domo.topiaapp.com" isArrow={false} setCurrentBody={setCurrentChat} />,
      'emptyChat': <EmptyComponent Icon={<Empty description="" />} label="Selecciona un chat" />,
    }

    let body = objBody[currentBody] || objBody['emptyChat']
    return body
  }
  
  return (
    <Row>
      <Col span={24} style={{ backgroundColor: "#FFFFFF", borderRadius: 16, padding: 25 }}>
        <Row gutter={24} wrap={false}>
          <Col span={8} style={{ borderRight: '1px solid #C4C4C4' }}>
            <CustomConversationsList
              isToUpdateList
              getConversations={getConversations}
              isLoading={isLoading}
              isMetadata={isMetadata}
              conversations={conversations}
              urlSocket="https://domo.topiaapp.com"
              labelCurrentBody="selectedChat" 
              setCurrentBody={setCurrentChat}
            />
          </Col>

          <Col span={16}>
            {verifyBody(currentChat)}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Conversations