//BIBLIOTECA ✅

export const libraryModule = {
    feature_code: 'FEAT-400',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-401',
            enabled: false,
        },
        {
            feature_code: 'FEAT-402',
            enabled: false,
        },
    ]
}