import verifyResponse from "../../topia/helpers/verifyResponse"
import { setContractTypes, setCurrentResidentialData, setIsLoading, setPersonhoodTypes, setSpecifications, setTaxData, setTicketsHistory, setVersionHistory } from "./detailContractSlice"
import getResidentialDataByUserLogged from '../../topia/services/general/getResidentialDataByUserLogged'
import {
  updateContract,
  getContractTypes,
  getPlaceDetailTax,
  getAllHistoryTickets,
  updatePlaceDetailTax,
  getAllTypesOfPersonhood,
  getAllContractsFilesByPlaceId,
  getAllMapFilesByPlaceId,
} from "../../topia/pages/adminContracts/services"
import postNewContractFile from "../../topia/pages/adminContracts/services/contracts/postNewContractFile"
import postNewMapFile from "../../topia/pages/adminContracts/services/specifications/postNewMapFile"
import updateMapFile from "../../topia/pages/adminContracts/services/specifications/updateMapFile"
import { dataToFormData } from "../../helpers/dataToFormData"


// get Functions
export const getResidentialInfo = async (residentialId) => async (dispatch) => {
  const response = await getResidentialDataByUserLogged(residentialId)

  if (response.success) {
    dispatch(setCurrentResidentialData(response.data.data))
    return true
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getAllTicketsByPlace = async ({ placeId, limit, skip, sortOrder, sortBy, findBy, findValue, dateBegin, dateEnd }) => async (dispatch, getState) => {
  const { idToken } = getState().auth

  const response = await getAllHistoryTickets({ placeId, idToken, limit, skip, sortOrder, sortBy, findBy, findValue, dateBegin, dateEnd })

  if (response.success) {
    dispatch(setTicketsHistory(response.data))
    return
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getAllMapFiles = async (placeId, limit, skip) => async (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const { idToken } = getState().auth

  const response = await getAllMapFilesByPlaceId(placeId, limit, skip, idToken)

  if (response.success) {
    dispatch(setSpecifications(response.data))
    dispatch(setIsLoading(false))
    return true
  }
  dispatch(setIsLoading(false))

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getDetailPlaceTaxData = async (placeId) => async (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const { idToken } = getState().auth
  
  const response = await getPlaceDetailTax(placeId, idToken)

  if (response.success) {
    dispatch(setTaxData(response.data.data))
    dispatch(setIsLoading(false))
    return 
  }

  dispatch(setIsLoading(false))

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getPersonHoodTypes = async () => async (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const { idToken } = getState().auth

  const response = await getAllTypesOfPersonhood(idToken)

  if (response.success) {
    dispatch(setPersonhoodTypes(response.data.data))
    dispatch(setIsLoading(false))
    return
  }
  dispatch(setIsLoading(false))

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getContractsFiles = async (placeId) => async (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const { idToken } = getState().auth

  const response = await getAllContractsFilesByPlaceId(placeId, idToken)

  if (response.success) {
    dispatch(setVersionHistory(response.data.data))
    dispatch(setIsLoading(false))
    return true
  }

  dispatch(setIsLoading(false))

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getTypesContract = async () => async (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const { idToken, currentRol } = getState().auth

  const response = await getContractTypes(idToken, currentRol)

  if (response.success) {
    dispatch(setContractTypes(response.data.data))
    dispatch(setIsLoading(false))
    return true
  }
  dispatch(setIsLoading(false))

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

// onSave functions
export const onSavePlaceDetailTax = async (formData) => async (dispatch, getState) => {
  const { idToken } = getState().auth

  const response = await updatePlaceDetailTax(formData, idToken)

  if (response.success) {
    verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Detalle actualizado correctamente'})

    return true
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const createContract = (formData) => async (dispatch, getState) => {
  const { idToken } = getState().auth

  const response = await postNewContractFile(formData, idToken)

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Contrato creado correctamente' })
}

export const updateContracts = (formData) => async (dispatch, getState) => {
  const { idToken } = getState().auth

  const response = await updateContract(formData, idToken)

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Contrato editado correctamente' })
}

export const onSaveMapFile = async (formData) => async (dispatch, getState) => {
  const { idToken } = getState().auth

  const dataToSend = dataToFormData(formData)

  const functionToExecute = formData.id ? updateMapFile : postNewMapFile
  const messateToShow = formData.id ? 'Mapa actualizado correctamente' : 'Mapa creado correctamente'

  const response = await functionToExecute(dataToSend, idToken)

  if (response.success) {
    verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: messateToShow})
    return true
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}