import { Badge } from "antd";
import moment from "moment";
import CustomPopoverConfirm from "../../../../../UI/CustomPopoverConfirm";
import { ACCESS_PROVIDERS_CODES } from "../../../../../../constants/modules/accessProviders/accessProvidersCodes";

const ShortenTextBadge = ({ status, content }) => {
  return <Badge className="accessProviderCalendar__badge-status" color={status} text={content} />;
};

const DateCellRender = ({ value, data, handleOpenCloseItem, currentParams, activeFeatures }) => {

  const date = value.clone().startOf('day').format('YYYY-MM-DD')
  const startMonth = moment(currentParams.date_begin)
  const endMonth = moment(currentParams.date_end)
  const getData = ({ date, data = [] }) => {
    const dateInfo = data.filter((item) => moment(item?.date).utcOffset(0).startOf('day').format('YYYY-MM-DD') === date)
    return dateInfo
  }
  const listData = getData({ date, data })

  return (
    <div onClick={(e) => moment(date).isBefore(startMonth) || moment(date).isAfter(endMonth) ? e.stopPropagation() : null} className="events">
      <div className="accessProviderCalendar__dateCell-header">
        {moment(date).format('DD')}
        {listData.length ? <div className="badge-events-count">
          {listData.length} {listData.length > 1 ? 'eventos' : 'evento'}
        </div> : <></>}
      </div>
      <div className="events-list">
        {listData?.map((item, index) => (
          <CustomPopoverConfirm key={index} trigger={'click'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_DETAIL_ACCESS)?.enabled ? 'No tienes permiso para ver los detalles del acceso' : ''}`}>
            <div
              onClick={!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_DETAIL_ACCESS)?.enabled ? () => { } : () => handleOpenCloseItem({ name: 'accessDetailDrawer', visible: true, data: item })}
              style={{ listStyle: "none" }}              
            >
              <ShortenTextBadge status={item.status_color} content={item.provider_name} />
            </div>
          </CustomPopoverConfirm>
        ))}
      </div>
    </div>
  );
}

export default DateCellRender