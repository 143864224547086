export const mimeTypesToText = (mimeTypes) => {
    const mimeMap = {
        'image/jpg': 'JPG',
        'image/jpeg': 'JPEG',
        'image/png': 'PNG',
        'application/pdf': 'PDF',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PowerPoint',
        'application/msword':'Word',
        'application/vnd.ms-excel':'Excel',
        'application/vnd.ms-powerpoint': 'PowerPoint',
    }

    const fileTypes = [...new Set(mimeTypes.split(',').map((mime) => mimeMap[mime.trim()] || mime))].join(', ')
    return fileTypes
}