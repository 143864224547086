import { Button, Input, Row, Space } from "antd"
import { SearchOutlined } from "@ant-design/icons"

// Body search just with input search bar
export const bodySearch = ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <Row>
      <Input
        autoFocus
        placeholder="Escribe algo"
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        onBlur={() => confirm()}
        style={{ marginBottom: "5px" }}
      />
      <Space direction="horizontal">
        <Button
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginBottom: "5px", marginLeft: "5px" }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => {
            clearFilters()
            confirm()
          }}
          size="small"
          style={{ width: 90, marginBottom: "5px" }}>
            Limpiar
        </Button>
      </Space>
    </Row>
  ),
  filterIcon: () => <SearchOutlined />,
})

// Funcion para validar que no sean cero ni menor a 8 digitos los inputs number
export const validateNumbersEightDigits = (rule, value) => {
  return new Promise ((resolve, reject) => {
    const stringValue = value ? value.toString() : ''
    const lengthValue = stringValue.length

  if (lengthValue < 8 || lengthValue === 0) {
    reject("El número tiene que ser de 8 dígitos")
  } else {
    resolve()
  }
  })
}

// Funcion para validar que no sean cero ni menor a 3 digitos los inputs number
export const validateNumbersThreeDigits = (rule, value) => {
  return new Promise ((resolve, reject) => {
    const stringValue = value ? value.toString() : ''
    const lengthValue = stringValue.length

  if (lengthValue < 3 || lengthValue === 0) {
    reject("El número tiene que ser de 3 dígitos")
  } else {
    resolve()
  }
  })
}

// Constante utilizada para campos requeridos en formularios
export const config = { rules: [{ required: true, message: "Campo requerido" }] }

// Constante utilizada para campos de tipo 8 numero de telefonos
export const configToEigthPhoneNumber = {
  rules: [
    { required: true, message: "Campo requerido" },
    { validator: validateNumbersEightDigits }
  ]
}

// Constante utilizada para campos de tipo prefijo 3 digitos
export const configToThreePhoneNumber = {
  rules: [
    { required: true, message: "Campo requerido" },
    { validator: validateNumbersThreeDigits }
  ]
}

// Array de codigos de error de firebase para login
export const firebaseAuthErrors = [
  { 
    path: "auth/internal-error",
    msg: "El servidor de Authentication encontró un error inesperado cuando se intentaba procesar la solicitud"
  },
  {
    path: "auth/invalid-email",
    msg: "Correo incorrecto"
  },
  {
    path: "auth/email-already-exists",
    msg: "Otro usuario ya está utilizando el correo electrónico proporcionado"
  },
  {
    path: "auth/wrong-password",
    msg: "Contraseña incorrecta"
  },
  {
    path: "auth/invalid-uid",
    msg: "El uid proporcionado debe ser una string no vacía con un máximo de 128 caracteres"
  },
  {
    path: "auth/uid-already-exists",
    msg: "Otro usuario ya utiliza el uid proporcionado"
  },
  {
    path: "auth/too-many-requests",
    msg: "La cantidad de solicitudes supera el máximo permitido"
  },
  {
    path: "auth/user-not-found",
    msg: "Usuario no encontrado"
  },
  {
    path: "default",
    msg: "Error desconocido, por favor contactar a IT"
  }
]