import axios from "axios"
import { API_URL } from "../../../../../../config"
import { verifyBodyResponse } from "../../../../../helpers/verifyBodyResponse"

const getOrnamentChecklistCategories = async ({
  checklistId,
  name,
  skip,
  limit,
  sortOrder,
  sortBy,
  idToken,
}) => {
  try {
    const isSkip = skip ? `&skip=${skip}` : ''
    const isLimit = limit ? `&limit=${limit}` : ''
    const isName = name ? `&name=${name}` : ''
    const isSortOrder = sortOrder ? `&sort_order=${sortOrder}` : ''
    const isSortBy = sortBy ? `&sort_by=${sortBy}` : ''

    const response = await axios.get(`${API_URL}/checklist/type/ornament/category?checklist_id=${checklistId}${isName}${isSkip}${isLimit}${isSortOrder}${isSortBy}`,
      { headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000 }
    )

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getOrnamentChecklistCategories