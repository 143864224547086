import moment from "moment";
import { Button, Col, Divider, Row } from "antd"
import { useAppSelector } from "../../../../hooks/store";
import ReassingEmployeeModal from "./modal/ReassingEmployeeModal";
import useOpenCloseModal from "../../../hooks/useOpenCloseModal";

// imagenes o archivos
import { ReactComponent as Assign } from "../../../../assets/icons/assign.svg"
import { ReactComponent as ReAssigIcon } from '../../../../assets/icons/Generals/user-reassing-icon.svg'

const AssignedEmployees = () => {
  const { isOpenModal, handleOpenCloseModal, form } = useOpenCloseModal()
  const { ticketById, flags } = useAppSelector((state) => state.detailTicketById)
  
  const employeesLength = ticketById?.TICKET_EMPLOYEE_LOG?.length
  const status = ticketById?.TICKET_EMPLOYEE?.TICKET_EMPLOYEE_STATUS.name;
  const utcDate = ticketById?.TICKET_EMPLOYEE?.created_at;
  
  const date = new Date(utcDate);
  const color =
    status === "Aceptado"
      ? "#16A07F"
      : status === "Rechazado"
        ? "#FC4850"
        : "#0780F5";

  return (
    <div>
      {/* <PreviouslyAssigned /> */}
      <Row align="middle">
        <Divider className="detail-ticket__divider" orientation="left" orientationMargin={0}>
          <h4>ASIGNACIÓN: </h4>
        </Divider>
      </Row>
      
      <Row justify="space-between">
        <Col>
          <p style={{ fontWeight: "bold" }}>
            Estatus:
            <span style={{ color: color, marginLeft: "1rem", fontWeight:500, textTransform:'uppercase' }}>
              {status} ({moment(date).format('DD/MM/YYYY [-] h:mm A')})
            </span>
          </p>
        </Col>
        
        {flags?.enabledButtonInProgressReasign && (
          <Col>
            <Button
              type="text"
              shape="round"
              htmlType="button"
              onClick={() => {
                handleOpenCloseModal()
                form.setFieldsValue({ employee_id : ticketById?.TICKET_EMPLOYEE?.employeeId })
              }}
              style={{ border: '1px solid #404040' }}
            >
              <Row gutter={8} align="">
                <Col>
                  <ReAssigIcon />
                </Col>
                <Col>
                  <span>Reasignar</span>
                </Col>
              </Row>
            </Button>
          </Col>
        )}
      </Row>

      <p style={{ fontWeight: "bold" }}>
        Departamento:
        <span style={{ marginLeft: 8, fontWeight:500 }}>
          {ticketById?.TICKET_EMPLOYEE?.EMPLOYEE.EMPLOYEE_DEPARTMENT?.COMPANY_DEPARTMENT?.name}
        </span>
      </p>

      <Row align="middle" style={{ marginLeft: 8 }}>
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {ticketById?.TICKET_EMPLOYEE_LOG?.map((employeeData, index) => (
            <li key={`${employeeData?.employee_name}-${index}`}>
              <Row>
                <p style={{ fontWeight: "bold", marginRight: "1rem", marginBottom: 0 }}>
                  {(employeesLength === 1)
                    ? 'Asignado'
                    : ((index === (employeesLength - 1)) ? 'Reasignado' : 'Asignado')} a:
                </p>

                <Row>
                  <Col span={24}>
                    <Row gutter={16}>
                      <Col>
                        <Assign />
                      </Col>

                      <Col>
                        <p style={{ marginBottom: 0, fontWeight:500 }}>
                          {employeeData?.employee_name}
                        </p>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col>
                        <label style={{ color: '#444444', fontSize: 14, fontWeight: 400 }}>
                          Fecha: <span>{employeeData?.assignment_date}</span>
                        </label>
                      </Col>

                      <Col>
                        <label style={{ color: '#444444', fontSize: 14, fontWeight: 400 }}>
                          Fase: <span>{employeeData?.ticket_status}</span>
                        </label>
                      </Col>
                    </Row>
                  </Col>
                </Row>

              </Row>
            </li>
          ))}
        </ul>
      </Row>

      <ReassingEmployeeModal form={form} isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
    </div>
  );
};

export default AssignedEmployees;
