import axios from "axios";
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse";
import { API_URL } from "../../../../config";

const getTicketsTable = async ({
  statusId,
  limit,
  skip,
  role,
  findValue = "",
  companyId,
  idToken,
  assignedTickets
}) => {
  try {
    const isFindValue = findValue ? `&find_value=${findValue}` : ''
    const isLimit = limit ? `?limit=${limit}` : ''
    const isSkip = skip ? `&skip=${skip}` : ''
    const isStatusId =  statusId ? `&status_id=${statusId}` : ''
    const isAssignedTickets = assignedTickets ? `&assigned_tickets=${assignedTickets}` : ''

    const response = await axios.get(`${API_URL}ticket${isLimit}${isSkip}${isStatusId}&company_id=${companyId}&type=${role}&find_by=ticket${isFindValue}${isAssignedTickets}`,
      { headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000 }
    )

    return verifyBodyResponse(response, true)
  } catch (errorResponse) {
    return verifyBodyResponse(errorResponse, false)
  }
}

export default getTicketsTable
