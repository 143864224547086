import { Button, Col, Form, Row } from "antd"
import { CustomModal } from "../../../../../UI/CustomModal"
import { CustomInput } from "../../../../../UI/CustomInput"
import { config } from "../../../../../constants/constants"
import { CustomSelect } from "../../../../../UI/CustomSelect"
import { useAppSelector } from "../../../../../../hooks/store"
import { CustomDatePicker } from "../../../../../UI/CustomDatePicker"
import { CustomUploadFile } from '../../../../../UI/CustomUploadFile/CustomUploadFile'
import { useActivesMaintenanceContext } from "../../../context/ActiveMaintenanceContext"
import { useActivesMaintenance } from "../../../hooks/useActivesMaintenance/useActivesMaintenance"

// imagenes y archivos
import { ReactComponent as MaintenanceDateIcon } from '../../../assets/maintenanceDateIcon.svg'

const NewEditActiveModal = ({
  isOpenModal,
  handleCloseModal,
  loading,
  onSubmit,
  providers,
  setFilesList,
  filesList,
  service
  }) => {
  const { form } = useActivesMaintenanceContext()
  const { clickedActive } = useActivesMaintenance()
  const { currencySymbol } = useAppSelector(state => state.auth)

  return (
    <CustomModal
      destroyOnClose
      className="custom-modal"
      title={<span 
          style={{ fontWeight: 600, fontSize: 22, display: 'block', width: '100%', textAlign: 'center', color: '#232C4A' }}
        >
          {clickedActive?.company_asset_id ? 'EDITAR ACTIVO' : 'NUEVO ACTIVO'}
        </span>
      }
      width={1020}
      isOpenModal={isOpenModal}
      onCancel={() => {
        setFilesList([])
        form.resetFields()
        handleCloseModal()
      }}
    >
      <Form
        form={form}
        layout="vertical"
        disabled={loading}
        onFinish={(values) => onSubmit({ values, formName: 'newActive-form', form })}
      >
        <Row gutter={56}>
          <Col span={13}>
            <label style={{ fontWeight: 500, fontSize: 12, color: '#404040' }}>NOMBRE DEL ACTIVO</label>
            <Form.Item name={'name'} { ...config }>
              <CustomInput className="custom-input-active-maintance" placeholder={'Escribe un nombre'} />
            </Form.Item>
            
            <label style={{ fontWeight: 500, fontSize: 12, color: '#404040' }}>DESCRIPCIÓN</label>
            <Form.Item { ...config } name={'description'}>
              <CustomInput className="custom-textarea" type="AREA" rows={4} autoSize={{ minRows: 4 }} placeholder={'Descripción'} />
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <label style={{ fontWeight: 500, fontSize: 12, color: '#404040' }}>CÓDIGO</label>
                <Form.Item { ...config } name={'code'}>
                  <CustomInput maxLength={12} className="custom-input-active-maintance" placeholder={'Código del activo'} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <label style={{ fontWeight: 500, fontSize: 12, color: '#404040' }}>COSTO</label>
                <Form.Item { ...config } name={'cost'}>
                  <CustomInput type="NUMBER" nullInputNumberText={null} className="custom-input-active-maintance" prefix={currencySymbol} precision={2} min={0} step={0.01} maxLength={10} placeholder={'Escribe tu costo'} />
                </Form.Item>
              </Col>
            </Row>

            <label style={{ fontWeight: 500, fontSize: 12, color: '#404040' }}>EMPRESA PROVEEDORA DE MANTENIMIENTO</label>
            <Form.Item { ...config } name={'provider_id'}>
              <CustomSelect className='custom-select-active-maintance' allowClear itemsCount={10} isAsync service={service} />
            </Form.Item>

            <label style={{ fontWeight: 500, fontSize: 12, color: '#404040' }}>PRÓXIMO MANTENIMIENTO</label>
            <Form.Item { ...config } name={'next_maintenance_date'}>
              <CustomDatePicker className="custom-date-picke-active-maintance" disabledDateBeforeToday={true} suffixIcon={<MaintenanceDateIcon />} />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item name='file' rules={[{ validator: async (_, value) => { return providers?.length ? Promise.resolve() : Promise.reject(new Error('Debe haber un archivo o imagen a subir')) } }]}>
              <CustomUploadFile fixedWidth multipleFiles maxCount={5} setFileList={setFilesList} fileList={filesList} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" style={{ gap: 16 }}>
          <Button
            onClick={handleCloseModal}
            style={{ maxWidth: 186 }}
            className="btn-standard-white"
            shape="round"
            loading={loading}
          >
            CANCELAR
          </Button>
          <Button
            style={{ maxWidth: 186 }}
            className="btn-standard-green"
            shape="round"
            htmlType="submit"
            loading={loading}
          >
            GUARDAR
          </Button>
        </Row>
      </Form>
    </CustomModal >
  )
}

export default NewEditActiveModal