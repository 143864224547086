import React from "react";
import { Steps } from "antd";
import { useSelector } from "react-redux";
import { ReactComponent as CheckIcon } from '../../../../assets/icons/checkStepIcon.svg'

const StepBar = () => {
  const { Step } = Steps;
  const { statusRoad, ticketById } = useSelector(
    (state) => state.detailTicketById
  );

  const currentStep = () => {
    const stateCurrent = statusRoad?.find((item) => item?.current === true);
    const currentNumber = statusRoad?.findIndex(
      (item) => item?.id === stateCurrent?.id
    );
    return currentNumber;
  };

  const customIcon = (index) => {
    if (index < currentStep()) {
      return <CheckIcon className="btn-svg-icon-white-variant2" />
    }
    return <span>{`0${index + 1}`}</span>

  }

  const backGorund =
    ticketById?.TICKET_STATUS.name !== "Cancelado" ? "var(--primary)" : "#B2B2B2";
  return (
    <Steps    
      className={`detail-ticket__steps ${ticketById?.TICKET_STATUS.name !== "Cancelado" ? "active" : "canceled"}`}
      style={{ background: backGorund }}
      labelPlacement="vertical"
      current={
        ticketById?.TICKET_STATUS.name === "Cancelado" ? -1 : currentStep()
      }
    >
      {statusRoad?.map((step, index) => (
        <Step icon={customIcon(index)} key={step.id} title={step?.name} />
      ))}
    </Steps>
  );
};

export default StepBar;
