import { useContext } from "react"
import { Button, Col, Row } from "antd"
import useChecklists from "../hooks/useChecklists"
import useRedirectTo from "../../../hooks/useRedirectTo"
import CustomTabs from "../../../UI/CustomTabs/CustomTabs"
import CustomTable from "../../../UI/CustomTable/CustomTable"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import { ChecklistsAdminContext } from "../context/ChecklistsAdminContext"
import { HANDOVER_CHECKLIST_CODES } from "../../../../constants/modules/handoverChecklist/handoverChecklistCodes"

// imagenes o archivos
import EditIcon from "../../../../assets/icons/EditIcon"
import TrashIcon from "../../../../assets/icons/TrashIcon"

const ChecklistsAdminTable = ({ handleOpenCloseDeleteModal, activeFeatures }) => {
  const { handleRedirectTo } = useRedirectTo()
  const { getChecklistsTable } = useChecklists()
  const {
    checklistsStatus, checklistsAdmin, loadingTable, setFilters, filters, setChecklistAdminClicked,
    handleOpenCloseModal, form, metadata, currentPage, setCurrentPage, setCurrentTabChecklistStatus,
    currentTabChecklistStatus,
  } = useContext(ChecklistsAdminContext)
  
  const { columns = [], dataSource = [] } = checklistsAdmin
  const { limit = 10, quantity = 0 } = metadata

  const isEditAllowed = !activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_UPDATE_TYPE_CHECKLIST)?.enabled
  const isDeleteAllowed = !activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_DELETE_TYPE_CHECKLIST)?.enabled
  const isAdminAllowed = !activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_ADMIN_TYPE_CHECKLIST)?.enabled

  const handleOnChangeTabs = async (value) => {
    await getChecklistsTable({ ...filters, checklist_status_id: value })
    setFilters(state => ({ ...state, checklist_status_id: value }))

    const findCurrentTabCode = checklistsStatus?.find((statusCheckData) => statusCheckData?.checklist_status_id === parseInt(value))

    setCurrentTabChecklistStatus(findCurrentTabCode?.code)
  }

  const handleOnChangeTable = async ({ sorter, pagination }) => {
    const newSkip = (pagination?.current * limit) - limit

    let isSort = ''
    let isColumnKeyAdded = ''

    if (sorter?.order) {
      isSort = sorter?.order === 'ascend' ? 'asc' : 'desc'
      isColumnKeyAdded = sorter?.columnKey
    }
    
    await getChecklistsTable({
      ...filters,
      sort_order: isSort,
      sort_by: isColumnKeyAdded,
      limit: limit,
      skip: newSkip
    })

    setFilters(state => ({
      ...state,
      sort_order: isSort,
      sort_by: isColumnKeyAdded,
      limit: limit,
      skip: newSkip,
    }))

    setCurrentPage(pagination?.current)
  }

  const newColumns = columns?.map((columnData) => {
    if (columnData?.dataIndex === 'actions') {
      return ({
        ...columnData,
        align: 'end',
        width: '20%',
        render: (text, record) => (<Row gutter={8} align="middle">
          <Col>
            <CustomPopoverConfirm
              trigger={'hover'}
              content={isAdminAllowed ? 'No tienes permiso para realizar esta acción' : 'Administrar checklist'}
            >
              <Button
                type="text"
                htmlType="button"
                shape="round"
                disabled={isAdminAllowed}
                onClick={() => {
                  handleRedirectTo(`/checklist-entrega/${record.checklist_id}`)
                }}
                style={{ width: 132 }}
                className="btn-standard-white"
              >
                <span>Administrar</span>
              </Button>
            </CustomPopoverConfirm>
          </Col>

          <Col>
            <CustomPopoverConfirm
              trigger={'hover'}
              content={isEditAllowed ? 'No tienes permiso para realizar esta acción' : 'Editar checklist'}
            >
              <Button
                type="link"
                htmlType="button"
                shape="circle"
                disabled={isEditAllowed}
                style={{ width: 37, height: 37, border: '1px solid var(--primary)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                onClick={() => {
                  form.setFieldsValue({
                    name: record?.name
                  })
                  handleOpenCloseModal()
                  setChecklistAdminClicked(record)
                }}
                icon={<EditIcon color="var(--primary)" />}
              />
            </CustomPopoverConfirm>
          </Col>

          <Col>
            <CustomPopoverConfirm
              trigger={'hover'}
              placement="left"
              content={isDeleteAllowed ? 'No tienes permiso para realizar esta acción' : 'Archivar checklist'}
            >
              <Button
                type="link"
                htmlType="button"
                shape="circle"
                disabled={isDeleteAllowed || currentTabChecklistStatus === 'deleted'}
                style={{
                  width: 37,
                  height: 37,
                  border: '1px solid var(--primary)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  opacity: (isDeleteAllowed || currentTabChecklistStatus) === 'deleted' ? 0.5 : 1
                }}
                onClick={() => {
                  handleOpenCloseDeleteModal()
                  setChecklistAdminClicked(record)
                }}
                icon={<TrashIcon color="var(--primary)" />}
              />
            </CustomPopoverConfirm>
          </Col>
        </Row>)
      })
    }

    return columnData
  })

  const items = checklistsStatus?.map((itemData) => ({
    key: itemData?.checklist_status_id,
    label: itemData?.name,
    children: (<Row style={{ marginTop: 25 }}>
      <Col span={24}>
        <CustomTable
          rowKey={record => record.checklist_id}
          loading={loadingTable}
          dataSource={dataSource ?? []}
          columns={newColumns ?? []}
          className="custom-table-variant-3"
          onChange={handleOnChangeTable}
          pagination={{
            total: quantity,
            pageSize: limit,
            showTotal: (total, range) => {
              return `${range[0]}-${range[1]} de ${total}`;
            },
            current: currentPage,
          }}
        />
      </Col>
    </Row>
    )}))

  return <CustomTabs items={items ?? []} className="custom-tabs-variant-1" onChange={handleOnChangeTabs} />
}

export default ChecklistsAdminTable