import React, { useState, useEffect } from "react";
import CustomModal from "../../../../UI/CustomModal";
import { Button, Form, Row, Select } from "antd";
import {
  putCategoriesTicketThunk,
  putEmployeeThunk,
} from "../../../../../store/detailTicket/thunk";
import { config } from "../../../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store";

// imagenes o archivos
import assign from "../../../../../assets/icons/assign.svg"

const AssignTicketModal = ({ onCancel, isOpenModal }) => {
  const dispatch = useAppDispatch()

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [subcategories, setSubcategories] = useState([])
  
  const { ticketById } = useAppSelector((state) => state.detailTicketById)
  const ticketCategories = useAppSelector((state) => state.detailTicketById.ticketCategory)
  const employees = useAppSelector((state) => state.detailTicketById.employeeTicket)

  const categories = ticketCategories.map((category) => {
    return {
      value: category?.id,
      label: category?.name,
    };
  });

  const handleCategoryChange = (value) => {
    const selectedCategory = ticketCategories?.find(
      (category) => category?.id === value
    );

    if (selectedCategory) {
      const subcategoriesForSelectedCategory =
        selectedCategory.TICKET_SUBCATEGORies?.map((subcategory) => {
          return {
            value: subcategory?.id,
            label: subcategory?.name,
          };
        });

      setSubcategories(subcategoriesForSelectedCategory || []);
    } else {
      setSubcategories([]);
    }

    form.resetFields(["subcategoria"]);
  };

  const Label = ({ name }) => (
    <Row justify="space-between">
      <Row align="middle">
        <img style={{ marginBottom: "0.85rem" }} src={assign} alt="" />
        <p style={{ marginLeft: "1rem" }}>{name}</p>
      </Row>
    </Row>
  )

  const employeeList = employees?.map((employee) => {
    return {
      value: employee.id,
      label: <Label name={employee?.name} />,
    };
  });

  const onFinish = (isSuccessful) => {
    if (isSuccessful) {
      form.resetFields();
      onCancel();
    }
  }

  const onSubmit = (values) => {
    setLoading(true);
    const categoryId = values?.category || ticketById?.TICKET_CATEGORY?.id;
    const subcategoriaId =
      values?.subcategoria || ticketById?.TICKET_SUBCATEGORY?.id;
    const employeeId =
      values?.employee || ticketById?.TICKET_EMPLOYEE?.EMPLOYEE?.id;
    dispatch(
      putCategoriesTicketThunk(ticketById?.id, categoryId, subcategoriaId)
    );
    if (employeeId) {
      dispatch(putEmployeeThunk(ticketById?.id, employeeId));
    }

    onFinish()

    setLoading(false);
  };


  useEffect(() => {
    form.setFieldsValue({
      category: ticketById?.categoryId,
      subcategoria: ticketById?.TICKET_SUBCATEGORY?.id,
      employee: ticketById?.TICKET_EMPLOYEE?.EMPLOYEE?.id,
    });

    const selectedCategory = ticketCategories?.find(
      (category) => category?.id === ticketById?.categoryId
    );

    if (selectedCategory) {
      const subcategoriesForSelectedCategory =
        selectedCategory.TICKET_SUBCATEGORies?.map((subcategory) => {
          return {
            value: subcategory?.id,
            label: subcategory?.name,
          };
        });

      setSubcategories(subcategoriesForSelectedCategory || []);
    }
  }, [ticketById, form, ticketCategories]);

  return (
    <CustomModal onCancel={onCancel} isOpenModal={isOpenModal}>
      <div>
        <h3 style={{ textAlign: "center" }}>ASIGNAR TICKET</h3>
        <Form
          className="standard-form"
          onFinish={onSubmit}
          disabled={loading}
          form={form}
          layout="vertical"
        >
          <Row align="middle" justify="space-around">
            <Form.Item
              name="category"
              style={{ width: "45%" }}
              label="Categoria"
              {...config}
            >
              <Select
                placeholder="Seleccionar Categoria"
                onChange={handleCategoryChange}
                options={categories}
              />
            </Form.Item>

            <Form.Item
              name="subcategoria"
              style={{ width: "45%" }}
              label="Subcategoria"
              {...config}
            >
              <Select
                className="standard-form"
                allowClear={true}
                placeholder="Seleccionar Subcategoria"
                options={subcategories}
              />
            </Form.Item>
          </Row>

          <Row justify="center">
            <Form.Item
              name="employee"
              style={{ width: "95%" }}
              label="Empleado"
              {...config}
            >
              <Select
                className="standard-form"
                optionFilterProp="value"
                options={employeeList}
                allowClear={true}
              />
            </Form.Item>
          </Row>

          <Row style={{ marginTop: "1rem" }} justify="center">
            <Form.Item>
              <Button
                className="btn-standard-green"
                shape="round"
                type="ghost"
                htmlType="submit"
              >
                <span>Asignar Ticket</span>
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
    </CustomModal>
  );
};

export default AssignTicketModal;
