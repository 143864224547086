import { useEffect } from "react"
import StatusTicket from "./StatusTicket"
import useTickets from "../hooks/useTickets"
import { CustomSpin } from "../../../UI/CustomSpin"
import CustomTabs from "../../../UI/CustomTabs/CustomTabs"
import { setActiveKey, setIsAssignedTickets } from "../../../../store/tickets/ticketsSlice"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"

import "../styles.css"

const StatusTickets = () => {
  const { getTableTickets, getAllDataTickets } = useTickets()

  const dispatch = useAppDispatch()

  const { currentRol } = useAppSelector((state) => state.auth)
  const { status, activeKey, loading, loadingChangeTabs } = useAppSelector(state => state.tickets)

  useEffect(() => {
    if (currentRol) {
      getAllData()
    }
  }, [])

  const getAllData = async () => {
    await getAllDataTickets()
  } 

  const handleOnChangeTabs = async (activeTabIdCode) => {
    const currentIdFiltered = status?.find((statusData) => statusData.code === activeTabIdCode)

    dispatch(setActiveKey(activeTabIdCode))
    dispatch(setIsAssignedTickets(false))
    
    await getTableTickets({ statusId: currentIdFiltered?.id ?? status[0]?.id })

  }

  if (loading) return <CustomSpin section />

  const items = status?.map((statusData) => ({
    key: statusData.code,
    label: statusData.name,
    totalCount: statusData.code === 'resolved' ? 0 : statusData.totalTicket,
    children: <StatusTicket />
  }))

  return (
    <CustomTabs
      isBadge
      activeTabId={activeKey}
      items={items}
      disabled={loading || loadingChangeTabs}
      onChange={handleOnChangeTabs}
      className="custom-tabs-variant-2"
    />
  )
}

export default StatusTickets;