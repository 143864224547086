import { axiosCreate } from "../../../../config/index"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const getReservationsExcel = async ({
  company_id,
  date_begin,
  date_end,
  idToken
}) => {
  try {
    const params = {
      date_begin,
      date_end,
      company_id,
    }

    const response = await axiosCreate.get('reservation/export', {
      params,
      headers: { Authorization: `Bearer ${idToken}` },
      timeout: 20000,
      responseType: 'blob'
    })

    const data = new Blob([response.data], { type: 'application/vnd.MS Excel' })
    const csvUrl = window.URL.createObjectURL(data)
    const link = document.createElement("a")
    link.href = csvUrl;
    link.setAttribute("download", `Reporte-de-reservas-${date_begin}-${date_end}.xlsx`);
    document.body.appendChild(link);
    link.click();

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getReservationsExcel