import axios from "axios"

const updateTicketSubCategory = async (formData) => {
  try {
    const response = await axios.put('https://domo.topiaapp.com/api/ticket/category/subcategory', ({
      ...formData,
      category_id: formData.category_id.value,
      subcategory_id : formData.id,
    }))

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }    
  }
}

export default updateTicketSubCategory