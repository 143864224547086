import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const requestSlice = createSlice({
    name: 'requests',
    initialState: {
        requests: {
            data: [],
            unApproved: [],
            pendingPayment: [],
            receivedPayment: [],
            denied: [],
            editing: null,
            isSaving: false,
            active: null
        },
        errorMessage: null
    },
    reducers: {
        setRequestData: ( state, { payload } ) => {
            state.requests.data = payload;
        },
        setRequestUnApproved: ( state, { payload } ) => {
            state.requests.unApproved = payload;
        },
        setRequestPendingPayment: ( state, { payload } ) => {
            state.requests.pendingPayment = payload;
        },
        setRequestWithReceivedPayment: ( state, { payload } ) => {
            state.requests.receivedPayment = payload;
        },
        setRequestDenied: ( state, { payload } ) => {
            state.requests.denied = payload;
        },
        setActiveRequest: (state, {payload}) => {
            state.requests.active = payload
        },
        setEditingRequest: (state, {payload}) => {
            state.requests.editing = payload
        },
        setIsSavingRequest: (state, { payload }) => {
            state.requests.isSaving = true;
        },

        onUpdateRequest: (state, {payload}) => {
            state.requests.isSaving = false;
            state.requests.data = state.requests.data.map( requestData => {

                if ( requestData.id === payload.id ) {
                    return payload;
                }

                return requestData;
            });
            state.requests.active = null;
            state.requests.editing = null;
            toast.success("Solicitud guardada");
        }
    }
});


export const { 
    setRequestData, 
    setActiveRequest,
    setEditingRequest,
    setIsSavingRequest,
    onUpdateRequest,
    setRequestUnApproved,
    setRequestPendingPayment,
    setRequestWithReceivedPayment,
    setRequestDenied,

} = requestSlice.actions;