import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    small: {
        fontFamily: 'Poppins',
        fontSize: 8,
        marginBottom: 4,
        color: '#000'
    },
    strong: {
        fontFamily: 'Poppins',
        fontSize: 9,
        fontWeight: 'semibold',
        color: '#000'
    },
    p: {
        fontFamily: 'Poppins',
        color: '#000',
        fontWeight: 'normal',
        fontSize: 9
    },
    mainInfo: {
        fontFamily: 'Poppins',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    mainInfoTitle: {
        fontWeight: 400,
        fontSize: 11,
    },
    mainInfoSpan: {
        fontWeight: 600,
        fontSize: 12,
        marginTop: 2,
        maxWidth: 500
    },
    nextMaintenanceDateContainer: {
        fontFamily: 'Poppins',
        fontSize: 8,

    },
    nextMaintenanceDate: {
        fontFamily: 'Poppins',
        marginTop: 4,
        padding: 8,
        paddingHorizontal: 12,
        fontSize: 10,
        borderRadius: 8,
        backgroundColor: '#232C4A',
        color: '#fff',
        fontWeight: 'semibold'
    },
    providerInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 24,
    },
    reportDetails: {
        display: 'flex',
        flexDirection: 'row',
        gap: 16,
        marginBottom: 24,        
    },
    attachmentFile: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        padding: 8,
        backgroundColor: '#f5f5f5',
        maxWidth: 250,
        marginBottom: 8,
        alignItems: 'center',
    },
    attachmentFileDetails: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        alignItems: 'center'
    },
    attachmentImage: {
        marginBottom: 16,
        width: 400,
        minWidth: 400,
        maxWidth: 400
    },
    attachmentImageImg: {
        objectFit: 'contain',
        maxWidth: 400,
        maxHeight:500
    },
    attachmentImageDescription: {
        backgroundColor: '#f4f4f4',
        width: '100%',
        padding: 8,
        paddingBottom: 7
    }
})