import { Button, Col, Row } from "antd"
import useTickets from "./hooks/useTickets"
import StatusTickets from "./components/StatusTickets"
import { PlusCircleOutlined } from "@ant-design/icons"
import { CustomNavigate } from "../../UI/CustomNavigate"
import useOpenCloseModal from "../../hooks/useOpenCloseModal"
import CreateTaskModal from "./components/Modal/CreateTaskModal"
import SettingsMenu from "../../components/SettingsMenu/SettingsMenu"
import { useFeatureRole } from "../../hooks/useFeatureRole"
import { useEffect } from "react"
import { TICKETS_CODES } from "../../../constants/modules/tickets/ticketsCodes"
import { CustomSpin } from "../../UI/CustomSpin"
import CustomPopoverConfirm from "../../UI/CustomPopoverConfirm"
import { INVENTORY_CODES } from "../../../constants/modules/inventory/inventoryCodes"
import useRedirectTo from "../../hooks/useRedirectTo"
import { toast } from "react-toastify"

const TicketPage = () => {
  const { roleType } = useTickets()
  const { handleRedirectTo } = useRedirectTo()
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { loadingFeatures, activeFeatures, gettingFeatures, getModuleFeatures, emptyFeatures, setLoadingFeatures } = useFeatureRole({ defaultCheck: false })
  useEffect(() => {
    //Aqui se debe de hacer algo...    
    if (!activeFeatures.length && gettingFeatures) {
      getModuleFeatures( roleType === "admin" ? TICKETS_CODES.TICKETS_ADMIN_TICKETS : TICKETS_CODES.TICKETS_USERS_TICKETS)
    }

    if (activeFeatures.length && !gettingFeatures) {
      setLoadingFeatures(false)
      if ((roleType === 'tenant' && !activeFeatures.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_USERS_TICKETS)?.enabled) || (roleType === 'admin' && !activeFeatures.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_ADMIN_TICKETS)?.enabled)) {
        toast.info('No tienes permiso para acceder a este módulo')
        return handleRedirectTo('/menu/specialModules/tickets')
      }
    }

    return () => {
      if (activeFeatures.length && !gettingFeatures) {
        emptyFeatures()
      }
    }
  }, [!activeFeatures.length, gettingFeatures])

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (
    <section className="section-wrapper">
      <CustomNavigate title={'TICKETS'}>
        <Row style={{ gap: 12 }}>
          {roleType === "admin" && (

            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_NEW_ADMIN_TICKET)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Crear Ticket Administrativo'}`}>
              <Button
                disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_NEW_ADMIN_TICKET)?.enabled}
                className="btn-standard-green"
                shape="round"
                type="ghost"
                style={{ width: "248px" }}
                onClick={handleOpenCloseModal}
              >
                <Row justify="center" align="middle" gutter={12}>
                  <Col style={{ paddingTop: '0.5rem' }}>
                    <PlusCircleOutlined style={{ fontSize: "20px" }} />
                  </Col>

                  <Col>
                    <span>CREAR TAREA</span>
                  </Col>
                </Row>
              </Button>
            </CustomPopoverConfirm>
          )}
          <SettingsMenu items={[{ label: 'Administración de Categorías', route: '/tickets/managment-categories', active: true, disabled: !activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_CATEGORY_TICKET)?.enabled }, { label: 'Inventario', route: '/tickets/managment-inventory', active: true, disabled: !activeFeatures?.find(feature => feature.feature_code === INVENTORY_CODES.INVENTORY_MODULE)?.enabled }]} />
        </Row>
      </CustomNavigate>

      <StatusTickets />

      <CreateTaskModal
        isOpenModal={isOpenModal}
        onClose={handleOpenCloseModal}
      />
      {/* <AverageTimeModal isOpenModal={isOpenModal} onClose={onCancel} /> */}
    </section>
  );
};

export default TicketPage;