import { useContext, useState } from "react"
import { Button, Col, Divider, Row } from "antd"
import NewEditContractModal from "../modals/NewEditContractModal"
import EmptyComponent from "../../../../UI/EmptyComponent"
import { useAppSelector } from "../../../../../hooks/store"
import useOpenCloseModal from "../../../../hooks/useOpenCloseModal"
import { AdminContractsContext } from "../../context/AdminContractsContext"
import { formatUTCDateInTimeZone } from "../../../../helpers/customDateHelpers"
import CustomFileToShowPdf from "../../../../UI/CustomFiles/CustomFileToShowPdf"
import moment from "moment"

// imagenes o archivos
import EditIcon from "../../../../../assets/icons/EditIcon"
import UploadUpArrowIcon from "../../../../../assets/icons/Generals/ContractsFlow/UploadUpArrowIcon"
import EmptyContractsIcon from "../../../../../assets/icons/Generals/ContractsFlow/emptyContracts.svg"
import { ReactComponent as PdfIcon } from "../../../../../assets/icons/Generals/ContractsFlow/pdfIcon.svg"

const ContractsBody = () => {
  const [currentData, setCurrentData] = useState({})

  const { clickedPlaceData } = useAppSelector(state => state.malls)
  const { form, isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { setFileListContracts, setIsVariableRent, setContractSelected } = useContext(AdminContractsContext)
  const { contractTypes, currentResidentialData, versionHistory } = useAppSelector(state =>  state.detailContracts)

  const isWordFile = ((currentData?.file)?.includes(".docx"))

  const customOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }
  // Funcion para abrir modal y rellenar la data en el formulario
  const handleOnClickContract = (contractSelected) => {
    setContractSelected(contractSelected)
    contractSelected?.PLACE_FILE_COMMERCIAL?.place_variable_rent ? setIsVariableRent(['1']) : setIsVariableRent([])

    const contractMatch = contractTypes?.find((contractData) => contractData?.id === contractSelected?.place_file_subtype_id)

    const newDataFileList = [{
      uid: crypto.randomUUID(),
      name: `Contrato_adjuntado_anteriormente.${contractSelected?.file?.split('.').pop().toLowerCase()}`,
      status: 'done',
      url: contractSelected?.file,
      type: '',
    }]

    setFileListContracts(newDataFileList)

    // Validaciones para rellenar el formulario
    const objToFill = {
      name: contractSelected?.name,
      file_sub_type_id: [{ value: contractMatch.id , label: contractMatch.name }],
      place_m2_size: (contractSelected?.PLACE_FILE_COMMERCIAL?.place_m2_size).toString(),
      place_per_m2_rent_price: (contractSelected?.PLACE_FILE_COMMERCIAL?.place_per_m2_rent_price) !== null
        ? (contractSelected.PLACE_FILE_COMMERCIAL.place_per_m2_rent_price).toString()
        : null,
      place_per_m2_maintenance_price: (contractSelected?.PLACE_FILE_COMMERCIAL?.place_per_m2_maintenance_price) !== null
        ? (contractSelected.PLACE_FILE_COMMERCIAL.place_per_m2_maintenance_price).toString()
        : null,
      place_variable_rent: (contractSelected?.PLACE_FILE_COMMERCIAL?.place_variable_rent) !== null
        ? (contractSelected?.PLACE_FILE_COMMERCIAL?.place_variable_rent).toString()
        : null,
      place_rent_increase_percentage: (contractSelected?.PLACE_FILE_COMMERCIAL?.place_rent_increase_percentage) !== null
        ? (contractSelected?.PLACE_FILE_COMMERCIAL?.place_rent_increase_percentage).toString()
        : null,
      place_maintenance_increase_percentage: (contractSelected?.PLACE_FILE_COMMERCIAL?.place_maintenance_increase_percentage) !== null
        ? (contractSelected?.PLACE_FILE_COMMERCIAL?.place_maintenance_increase_percentage).toString()
        : null,
      date_begin: moment(contractSelected?.PLACE_FILE_VALIDITY?.date_begin),
      date_end: moment(contractSelected?.PLACE_FILE_VALIDITY?.date_end)
    }

    form.setFieldsValue(objToFill)

    handleOpenCloseModal()
  }

  let body = (
    <Row gutter={24}>
      <Col span={14}>
        <h3 style={{ fontWeight: 500, fontSize: 17, color: "#232C4A" }}>
          Contrato arrendamiento CC {currentResidentialData?.name} -
          {' '}
          <span style={{ fontWeight: 400 }}>
            {clickedPlaceData?.place?.PLACE_DETAIL?.name}
          </span>
        </h3>

        <Divider style={{ borderTop: "1px solid #BDBDBD" }} />

        <p style={{ color: "#232C4A", fontSize: 14, fontWeight: 400 }}>
          Última actualización: {' '}
          <span style={{ color: "var(--primary)" }}>
            {(currentData?.created_at)
              ? formatUTCDateInTimeZone({ utcDate: new Date(currentData?.created_at), customOptions: customOptions })
              : "Seleccione un archivo"
            }
          </span>
        </p>

        {isWordFile && <label>El archivo es de tipo word, por ende descarguelo haciendo click.</label>}

        {Object.keys(currentData)?.length
          ? <CustomFileToShowPdf fileUrl={currentData?.file} />
          : ''
        }

      </Col>
      <Col span={10}>
        <Row justify="end">
          <Button
            type="text"
            shape="round"
            htmlType="button"
            className="btn-standard-white"
            onClick={() => {
              form.resetFields()
              setContractSelected({})
              setIsVariableRent([])
              handleOpenCloseModal()
            }}
            style={{ width:264, justifyContent: "space-evenly" }}
          >
            <Row justify="space-around" align="middle">
              <UploadUpArrowIcon color="var(--primary)" />
              <span style={{ color: "#232C4A", paddingLeft: 15 }}>CARGAR CONTRATO</span>
            </Row>
          </Button>
        </Row>

        <Row justify="end" style={{ marginTop: 30 }}>
          <h4 style={{ color: "var(--primary)", fontSize: 14, fontWeight: 400 }}>Historial de versiones</h4>
        </Row>

        <Row justify="end" style={{ overflow: "auto", maxHeight: 475 }}>
          <Row className="hover-row-contracts" style={{ border: "1px solid #CDC2C2", borderRadius: 8, width: 374 }}>
            {versionHistory?.map((fileData, index) => (
              <a key={fileData.id} target="_self" rel="noopener noreferrer" href={isWordFile ? fileData?.file : null}>
                <Row
                  onClick={() => setCurrentData(fileData)}
                  style={{
                    padding: 15,
                    borderBottom: `${(index + 1) !== (versionHistory?.length) ? "1px solid #CDC2C2" : ""}`,
                    cursor: "pointer",
                  }}
                >
                  <Col span={2}>
                    <PdfIcon className="btn-svg-icon-white-variant2" />
                  </Col>
                  <Col span={20}>
                    <label style={{ fontWeight: 400, fontSize: 14, color: "#232C4A", cursor: "pointer" }}>
                      Contrato de arrendamiento CC {currentResidentialData?.name}
                      {' '} - {clickedPlaceData?.place?.PLACE_DETAIL?.name}
                    </label>
                    <p style={{ fontWeight: 400, color: "var(--primary)", fontSize: 12, margin: 0 }}>
                      {formatUTCDateInTimeZone({ 
                        utcDate: (fileData?.created_at) ? (new Date(fileData?.created_at)) : (new Date()),
                        customOptions: customOptions
                        })}
                    </p>
                  </Col>
                  <Col span={2}>
                    <Button
                      type="link"
                      htmlType="button"
                      shape="circle"
                      style={{ border: '1px solid var(--primary)' }}
                      onClick={() => handleOnClickContract(fileData)}
                    >
                      <Row justify="center">
                        <EditIcon color="var(--primary)" width="18px" height="18px" />
                      </Row>
                    </Button>
                  </Col>
                </Row>
              </a>
          ))}
          </Row>
        </Row>
      </Col>
    </Row>
  )

  if (!(versionHistory.length)) (
    body = (
      <>
        <EmptyComponent height="" Icon={<img src={EmptyContractsIcon} alt='empty-contracts-icon' />} label="NO EXISTEN CONTRATOS AÚN" />
        <Row justify="center">
          <Button
            type="text"
            shape="round"
            htmlType="button"
            className="btn-standard-white"
            onClick={handleOpenCloseModal}
            style={{ width:264 }}
          >
            <Row align="middle">
              <UploadUpArrowIcon color="var(--primary)" />
              <span style={{ color: "#232C4A", paddingLeft: 15 }}>CARGAR CONTRATO</span>
            </Row>
          </Button>
        </Row>
      </>
    )
  )

  return (
    <section style={{
      height: `${!(versionHistory.length) ? '500px' : '714px'}`,
      width: "100%",
      padding: 40,
      backgroundColor: "#FFFFFF",
      borderRadius: 8,
      boxShadow: "0px 6px 7px 0px #9AB9D440",
      display: `${!(versionHistory.length) ? 'flex' : ''}`,
      flexDirection: `${!(versionHistory.length) ? 'column' : ''}`,
      alignItems: `${!(versionHistory.length) ? 'center' : ''}`,
      justifyContent: `${!(versionHistory.length) ? 'center' : ''}`
    }}>
      {body}

      <NewEditContractModal form={form} isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
    </section>
  )
}

export default ContractsBody