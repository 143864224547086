import { Button, Divider, Row } from "antd"
import CustomModal from "../../../../../UI/CustomModal"
import { CustomRadioButton } from "../../../../../UI/CustomRadio/CustomRadioButton";
import { CustomInput } from "../../../../../UI/CustomInput";
import { CustomSelect } from "../../../../../UI/CustomSelect";
import { CaretDownOutlined } from "@ant-design/icons";
import { formItemConfig } from "../../../../../../constants/formConfig";

//Se muestra el modal con el que puede crear, editar o duplicar un rol a través de un formulario
const NewRoleModal = ({ handleOpenCloseModal, isOpenModal, onSubmit, form, Form, rolesItem, service, handleChangeCompanyId, loading }) => (
    <CustomModal className="custom-modal" destroyOnClose={true} onCancel={handleOpenCloseModal} isOpenModal={isOpenModal} title={<span style={{ fontSize: 22, fontWeight: 600, display: 'flex', justifyContent: 'center' }}>{form.getFieldValue('role_id') ? 'EDITAR' : 'NUEVO'} ROL</span>}>
        <Form form={form} id="new-role-modal" layout="vertical" onFinish={onSubmit} >
            <Form.Item name={'role_id'} noStyle /*Funciona para almacenar el id del rol cuando se está editando*/ >
                <CustomInput hidden={true} />
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
                {//Dependiendo de si no existe un id de rol en el input se mostrará los radio buttons para poder elegir entre crear o duplicar un rol o no
                    ({ getFieldValue }) => {
                        const role_id = getFieldValue('role_id');
                        if (role_id) return <></>
                        return <>
                            <Form.Item rules={[...formItemConfig.rules]} initialValue={'1'} name={'optionRol'}>
                                <CustomRadioButton style={{ flexDirection: 'row', gap: '24px' }} options={[{ label: 'NUEVO ROL', value: '1', }, { label: 'DUPLICAR ROL', value: '2' }]} />
                            </Form.Item>
                            <Form.Item rules={[...formItemConfig.rules]} name={'company_id'} label={'COMPAÑÍA'}>
                                <CustomSelect onChange={handleChangeCompanyId} options={rolesItem} svgIcon={true} suffixIcon={<CaretDownOutlined />} />
                            </Form.Item>
                        </>
                    }

                }
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
                {/**
                 * Dependiendo de la opción seleccionada, si es para duplicar un rol se mostrará el select de roles,
                 *  además dependiendo del cambio de la opcion en el select de la compañía se actualizará los datos dentro de este select para los roles correspondientes
                 */
                    ({ getFieldValue }) => {
                        const optionRol = getFieldValue('optionRol')
                        const company_id = getFieldValue('company_id');
                        if (optionRol !== '2') return <></>
                        return (<Form.Item rules={[...formItemConfig.rules]} name={'duplicate_role_id'} label={'ROL A DUPLICAR'}>
                            <CustomSelect externalData={company_id} otherParams={{ company_id }} filters={false} isAsync service={service} svgIcon={true} suffixIcon={<CaretDownOutlined />} />
                        </Form.Item>)
                    }
                }
            </Form.Item>
            <Form.Item rules={[...formItemConfig.rules]} name={'name'} label={'NOMBRE DE ROL'}>
                <CustomInput />
            </Form.Item>
            <Divider />
            <Row justify="center">
                <Button loading={loading} htmlType="submit" className="btn-standard-green" shape="round">GUARDAR</Button>
            </Row>
        </Form>
    </CustomModal>
)

export default NewRoleModal
