import { Form } from "antd";
import { createContext, useState } from "react";

export const AdminChecklistContext = createContext()

export const AdminChecklistProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)

  const [metadataCategories, setMetadataCategories] = useState({})
  const [defaultChecklistData, setDefaultChecklistData] = useState({})
  const [checklistCategoriesTableData, setChecklistCategoriesTableData] = useState({})
  
  const [checklistCategoryClicked, setChecklistCategoryClicked] = useState({})

  const [filters, setFilters] = useState({})
  const [currentPage, setCurrentPage] = useState(1)

  const [form] = Form.useForm()

  const value = {
    form,
    loading, setLoading,
    filters, setFilters,
    currentPage, setCurrentPage,
    checklistCategoryClicked, setChecklistCategoryClicked,
    metadataCategories, setMetadataCategories,
    defaultChecklistData, setDefaultChecklistData,
    checklistCategoriesTableData, setChecklistCategoriesTableData,
  }

  return <AdminChecklistContext.Provider value={value}>
    {children}
  </AdminChecklistContext.Provider>
}