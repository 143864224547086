import { API_URL } from "../../../../../config/index";
import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const rolPath = "role/company/create"

//Endpoint de topia con el que se crea un rol de usuario en una cuenta, a partir del id y el nombre
export const createRole = async ({
    token,
    formData
}) => {
    try {
        const response = await axiosCreate.post(`${API_URL}${rolPath}`, formData, {
            timeout: 10000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }
};