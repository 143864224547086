import { Navigate } from "react-router-dom"
import useCurrentLocation from "../../hooks/useCurrentLocation"
import DetailRolesPage from "./components/DetailRoles/DetailRolesPage"
import { UserRolesContextProvider } from "./context/UserRolesContext"
import UserRolesPage from "./components/UserRoles/UserRolesPage"

//Componente que se coloca en el DashboardRouter para poder acceder al flujo de roles mediante el menu de ajustes
const UserRolesMainPage = () => {

    const { param } = useCurrentLocation()

    const pages = { //Se define las páginas a utilizar según el valor del amenityId, dicho valor debe coincidir con la clave del objeto
        detail: <DetailRolesPage />,
    }

    const renderComponent = () => {
        if (param?.roleId) {
            //Si viene un parámetro verifica si existe esa página en el objeto y si es así la retorna, si no redirigirá a la ruta de la página inicial del flujo
            if (pages[param?.roleId]) return pages[param.roleId]
            return <Navigate to={'/settings/managment-roles'} />
        }
        //Si no viene un parámetro entonce se renderiza la página inicial del flujo
        return <UserRolesPage />
    }
    return (
        <UserRolesContextProvider>
            {renderComponent()}
        </UserRolesContextProvider>
    )
}

export default UserRolesMainPage