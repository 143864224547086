import axios from "axios"
import { API_URL } from "../../../../config"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const getAllWareHouses = async ({ companyId, idToken, find_value }) => {
  try {
    const isFindValue = find_value ? `&find_value=${find_value}` : ''
    const url = `${API_URL}place/by/type?place_type=warehouse&company_id=${companyId}${isFindValue}`
   
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${idToken}` }
    })
    
  // Se prepara y moldea la data para que el customComponent Select trabaje sin problema
  const { data } = response
  const newResponse = {
    ...response,
    data: {
      ...response.data,
      data: {
        dataSource: data?.data?.dataSource.map((wareHouse) => ({ id: wareHouse.place_id, name: wareHouse.name })),
      }
    }
  }

    return verifyBodyResponse(newResponse,true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getAllWareHouses
