import { io } from 'socket.io-client'

export const createSocket = (profileId, url) => (
  io(url, {
    transports: ['websocket'],
    path: "/chat",
    query: {
      profile_id: profileId,
    }
}))
