import axios from "axios"
import { verifyTypesFiles } from "../../../helpers/customFilesHelper"

export const downloadFileFromUrl = async ({ url, filename, setLoading }) => {
    try {
        const res = await axios.get(url, {
            method: 'GET',
            responseType: 'blob'
        })

        const localUrl = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = localUrl
        link.setAttribute('download', `${filename}.${verifyTypesFiles(url)}`)
        document.body.appendChild(link)
        link.click()

        link.parentNode.removeChild(link)
        window.URL.revokeObjectURL(localUrl)
    } catch (error) {
        console.error('Error al descargar un archivo', filename)
        console.error(error)
    }
    finally {
        setLoading && setLoading(false)
    }
}