import EmptyComponent from "../../UI/EmptyComponent";
import AdminCheckPage from "./adminCheck/AdminCheckPage";
import useCurrentLocation from "../../hooks/useCurrentLocation";
import { ActivityMonitoringPage } from "./activityMonitoring/ActivityMonitoringPage";

import './antdStyle.css'

const ChecklistPage = () => {
  const { param } = useCurrentLocation();
  // Aqui agregar los otros componentes posteriores --> literamente es un switch

  const verifyPage = (checkId) => {
    let objComponentsPages = {
      "admin": <AdminCheckPage />,
      "monitoring": <ActivityMonitoringPage />,
      "default": <EmptyComponent label="Página no encontrada" />,
    };

    let pages = objComponentsPages[checkId] || objComponentsPages["default"];
    return pages;
  };

  return <>{verifyPage(param.checkId)}</>;
};

export default ChecklistPage;
