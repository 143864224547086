import { Button } from "antd"
import { ComprobantIcon } from '../assets'
import { columnsOrderedTable } from "../../../helpers/columnsTableHelper"

export const salesStatementTableColumnsPrev = ({ columns = [] }) => {
    const defaultColumns = [
        {
            title: 'ID',
            dataIndex: 'code',
            key: 'code',
            width: '15%',
            render: (text, record) => {
                return record.code
            }
        },
        {
            title: 'CENTRO COMERCIAL',
            dataIndex: 'mall',
            key: 'mall',
            width: '17%',
            align: 'center',

        },
        {
            title: 'LOCALES',
            dataIndex: 'place_name',
            key: 'place_name',
            width: '13%',
            align: 'center',
        },
        {
            title: 'FECHA DE VENTAS',
            dataIndex: 'transaction_date',
            key: 'transaction_date',
            width: '15%',
            align: 'center',
        },
        {
            title: 'ENCARGADO DEL LOCAL',
            dataIndex: 'profile_name',
            key: 'profile_name',
            width: '15%',
            align: 'center',
        },
        {
            title: 'FECHA DE REGISTRO',
            dataIndex: 'created_at',
            key: 'created_at',
            width: '10%',
            align: 'center',
        },
        {
            title: 'MONTO',
            dataIndex: 'quantity',
            key: 'quantity',
            width: '10%',
            align: 'center',
        },
        {
            title: 'COMPROBANTE',
            dataIndex: 'file',
            key: 'file',
            width: '10%',
            align: 'center',
            render: (text, record) => {
                return <Button href={record?.file} target="_blank" icon={<ComprobantIcon />} style={{ boxShadow: 'none', border: 'none', backgroundColor: 'transparent' }} />
            }
        },
    ]

    return columns.length ? columnsOrderedTable({ defaultColumns, backendColumns: columns, titleUpperCase: true }) : defaultColumns
}

export const salesStatementTableFilters = ({ currentParams }) => ({
    code: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams?.sort_by === 'code' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null,
    }),
    transaction_date: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams?.sort_by === 'transaction_date' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null,
    }),
    profile_name: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams?.sort_by === 'profile_name' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null,
    }),
    created_at: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams?.sort_by === 'created_at' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null,
    }),
    quantity: () => ({
        sorter: (a, b) => {

        },
        sortDirections: ["descend", "ascend"],
        sortOrder: currentParams?.sort_by === 'quantity' ? (currentParams.sort_order === 'asc' ? 'ascend' : 'descend') : null,
    }),
    default: () => ({}),
})