const TreePointsMoreIcon = ({ color = 'black', width = '19', height = '19' }) => {
  return (
    <svg style={{ cursor: 'pointer' }} width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_93_5077" style={{ maskType:"luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="19">
        <rect x="0.400391" y="0.798828" width="18" height="18" fill="white"/>
      </mask>
      <g mask="url(#mask0_93_5077)">
        <ellipse cx="9.40041" cy="2.79884" rx="2.00002" ry="2.00002" fill={color} />
        <circle cx="9.40041" cy="9.79884" r="2.00002" fill={color} />
        <ellipse cx="9.40041" cy="16.7988" rx="2.00002" ry="2.00002" fill={color} />
      </g>
    </svg>
  )
}

export default TreePointsMoreIcon