import verifyResponse from "../../topia/helpers/verifyResponse"
import { createUpdateOrnamentChecklistInstance, deleteOrnamentChecklistInstance, getActiveOrnamentChecklistByCompanyId, getAllOrnamentStatus, getOrnamentChecklistInstances } from "../../topia/pages/adminChecklists/services"
import { setActiveOrnamentChecklist, setAdminChecklists, setLoadingOrnamentChecklists, setMetadata, setOrnamentStatusChecklist } from "./adminChecklistSlice"

export const getActiveOrnamentChecklistInstanceId = () => async (dispatch, getState) => {
  const { idToken, currentRol } = getState().auth

  const response = await getActiveOrnamentChecklistByCompanyId(currentRol, idToken)

  if (response.success) {
    dispatch(setActiveOrnamentChecklist(response.data.data))

    return
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getOrnamentChecklists = ({
  checklistId,
  employeeId,
  dateBegin,
  dateEnd,
  checklistIdStatus,
  skip,
  limit,
  sortOrder,
  sortBy
}) => async (dispatch, getState) => {
  const { idToken, currentRol } = getState().auth

  dispatch(setLoadingOrnamentChecklists(true))

  const response = await getOrnamentChecklistInstances({
    idToken,
    companyId: currentRol,
    checklistId,
    employeeId,
    dateBegin,
    dateEnd,
    checklistIdStatus,
    skip,
    limit,
    sortOrder,
    sortBy
  })

  if (response.success) {
    dispatch(setAdminChecklists(response.data.data))
    dispatch(setMetadata(response.data.metadata))
    dispatch(setLoadingOrnamentChecklists(false))
    return true
  }

  dispatch(setLoadingOrnamentChecklists(false))

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getOrnamentStatusChecklist = () => async (dispatch, getState) => {
  const { idToken } = getState().auth

  const response = await getAllOrnamentStatus(idToken)

  if (response.success) {
    dispatch(setOrnamentStatusChecklist(response.data.data))

    return
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const newEditOrnamentChecklist = (formData) => async (dispatch, getState) => {
  const { idToken, currentRol } = getState().auth

  const messageToShow = formData.checklist_instance_id ? 'editado' : 'creado'

  const response = await createUpdateOrnamentChecklistInstance({ ...formData, company_id: currentRol }, idToken)

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: `Checklist ${messageToShow} con éxito` })
}

export const deleteOrnamentChecklist = (formData) => async (dispatch, getState) => {
  const { idToken } = getState().auth

  const response = await deleteOrnamentChecklistInstance(formData, idToken)

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Checklist eliminado con éxito' })
}