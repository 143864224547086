import { Viewer  } from "@react-pdf-viewer/core"

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const CustomFileToShowPdf = ({ fileUrl = '', maxWidth }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div style={{ height: 500, maxWidth }}>
      <Viewer
        fileUrl={fileUrl}
        plugins={[ defaultLayoutPluginInstance ]}
      />
    </div>
  )
}

export default CustomFileToShowPdf