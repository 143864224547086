import { UserOutlined, UnlockOutlined } from '@ant-design/icons'
import spinTopiaLogo from '../../../assets/logos/topiaLogo.svg'

export const topiaTemplate = (data) => {

  return {
    templateName: "TopiaTemplate",
    templateData: {
      spinData: {
        spinLogo: spinTopiaLogo,
        spinColor: '#1264e0'
      },
      infoSectionData: {
        infoImages: {
          background: {
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url(https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/assets/integro/70a7a15b-260e-4ba9-b47f-3b3f872a9a3f.png)`,
          },
          logo: 'https://topia-assets.nyc3.digitaloceanspaces.com/topia/dashboard/assets/integro/1361d2c8-ad47-44c4-b5cf-0a83cf98b5d9.png',
        },
        initData: {
          h2: {
            firstText: "La nueva forma",
            secondText: "de ",
            style: {
              color: "#fff",
              fontFamily: "Poppins, sans-serif",
              fontSize: 32,
              fontWeight: 700,
              lineHeight: "37px",
              marginTop: 50,
            },
          },
          span: {
            text: "convivir",
            style: {
              color: "#0ac598",
            },
          },
        },
        loginForm: {
          input: {
            shared: {
              input: {
                style: {
                  border: "1px solid #e2e2e2",
                  backgroundColor: "#fbfbfb",
                },
              },
            },
            email: {
              input: {
                placeholder: "Usuario",
                prefix: <UserOutlined />,
              },
            },
            password: {
              input: {
                placeholder: "Contraseña",
                prefix: <UnlockOutlined />,
              },
            },
          },
          button: {
            text: "Ingresar",
            style: {
              width: "100%",
              color: "#fff",
              borderRadius: 26,
              height: 46,
              backgroundColor: "#16A07F",
              border: "none",
            },
          },
        },
      },
    },
  }
};
