import { useState } from "react";
import getExcelTickets from "../Services/getExcelTickets";
import { useSelector } from "react-redux";
import verifyResponse from "../../../../helpers/verifyResponse";

const useDownLoadExcel = () => {
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const currentCompanyId = useSelector((state) => state?.auth?.currentRol)

  const getTicketExcel = async (skip, role) => {
    setIsDownloadLoading(true);

    const response = await getExcelTickets(skip, role, currentCompanyId);

    setIsDownloadLoading(false);

    return verifyResponse(
      response.data,
      response.status,
      "Tickets descargado con exito"
    );
  };
  return {
    isDownloadLoading,
    getTicketExcel,
  };
};

export default useDownLoadExcel;
