import { Button, Col, Divider, Image, Row } from "antd"
import CustomPopoverConfirm from "../../../../UI/CustomPopoverConfirm"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store"
import { setCurrentOptionModalBody } from "../../../../../store/bookings/bookingsSlice"
import { BOOKINGS_CODES } from "../../../../../constants/modules/bookings/bookingsCodes"

// Imagenes o iconos
import detailIcon from '../../../../../assets/icons/assign.svg'
import pinIcon from '../../../../../assets/icons/Generals/pin-icon.svg'
import userImageDefault from '../../../../../assets/icons_png/avatar_user.png'

const DetailReserveBody = () => {
  const dispatch = useAppDispatch()
  const { activeFeatures } = useAppSelector(state => state.roles)

  const { currentActiveTab, detailBooking } = useAppSelector(state => state.bookings)

  return (
    <Row>
      <Col span={24}>
        <Row gutter={16} align="middle">
          <Col span={3}>
            <Image style={{ borderRadius: '50%', objectFit: 'cover' }} alt="user-img" src={(detailBooking?.PROFILE?.picture) || userImageDefault} width={51} height={51} />
          </Col>

          <Col span={21}>
            <Row>
              <label style={{ fontSize: 16, fontWeight: 500, color: '#232C4A' }}>
                {detailBooking?.PROFILE?.name}
              </label>

              <img src={detailIcon} alt="detail-user-icon" style={{ paddingLeft: 10 }} />
            </Row>

            <Row style={{ minHeight: 0, maxHeight: 100, overflow: 'auto' }}>
              <Col span={24}>
                {detailBooking?.PROFILE?.addresses?.map((address, index) => (
                  <Row key={`${address}-${index}`}>
                    <img src={pinIcon} alt="pin-icon" style={{ paddingInline: 7 }} />
                    <p style={{ fontWeight: 400, fontSize: 13, color: '#404040', margin: 0 }}>
                      {address}
                    </p>
                  </Row>
                ))}
              </Col>
            </Row>
          </Col>

        </Row>

        <Divider style={{ borderTop: "1px solid #C4C4C4" }} />

        <label style={{ fontWeight: 600, fontSize: 16, color: '#252525' }}>
          Datos del evento
        </label>

        <Row style={{ fontWeight: 400, fontSize: 14, marginTop: 30 }}>
          <Col>
            <label style={{ color: '#626262' }}>Amenidad:</label>
            <p style={{ color: "#252525", margin: 0 }}>
              {detailBooking?.RESERVATION?.address}
            </p>
          </Col>
        </Row>

        <Row gutter={24} style={{ fontWeight: 400, fontSize: 14, marginBottom: 30, marginTop: 15 }}>
          <Col span={12}>
            <label style={{ color: '#626262' }}>Fecha:</label>
            <p style={{ color: "#252525" }}>
              {(detailBooking?.RESERVATION?.date) ?? 'No existe fecha asignada'}
            </p>

            <label style={{ color: '#626262' }}>Hora:</label>
            <p style={{ color: "#252525" }}>
              {(detailBooking?.RESERVATION?.time) ?? 'No existe una hora asignada'}
            </p>
          </Col>

          <Col span={12}>
            <label style={{ color: '#626262' }}>Tipo de evento:</label>
            <p style={{ color: "#252525" }}>
              {(detailBooking?.RESERVATION?.type_name) ?? 'No existe un tipo de evento'}
            </p>

            <label style={{ color: '#626262' }}>Número de invitados:</label>
            <p style={{ color: "#252525" }}>
              {(detailBooking?.RESERVATION?.invitees) ?? 'No existe un numero de invitados'}
            </p>
          </Col>
        </Row>


        <label style={{ fontWeight: 600, fontSize: 16, color: '#252525' }}>
          Costes
        </label>
        <ul>
          {detailBooking?.RESERVATION?.COST?.DETAIL?.map((cost, index) => (
            <li key={`${cost?.name}-${index}`}>
              <Row justify="space-between" style={{ margin: '10px 0' }}>
                <Col>
                  <label style={{ color: '#373737', fontSize: 12 }}>
                    {cost?.name}
                  </label>
                </Col>
                <Col>
                  <span style={{ fontSize: 12, fontWeight: 600 }}>
                    {cost?.totalPrice}
                  </span>
                </Col>
              </Row>
            </li>
          ))}
        </ul>

        <Divider style={{ borderTop: "1px solid #C4C4C4" }} />

        <Row justify="space-between">
          <label style={{ fontWeight: 400, color: '#373737', fontSize: 12, paddingLeft: 25 }}>
            Total
          </label>
          <span style={{ color: "#373737", fontSize: 16, fontWeight: 600 }}>
            {detailBooking?.RESERVATION?.COST?.totalCost}
          </span>
        </Row>

        <Divider style={{ borderTop: "1px solid #C4C4C4" }} />

        <Row justify="space-between" gutter={16}>
          <Col span={12}>
            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === BOOKINGS_CODES.REJECT_BOOKING)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Cancelar reserva'}`}>
              <Button
                type="link"
                htmlType="button"
                shape="round"
                style={{ opacity: currentActiveTab === 'canceled' ? 0.5 : 1, height: 42, width: 250, color: "#ff2e2e", backgroundColor: "#ffffff", fontSize: 14, fontWeight: 500 }}
                disabled={!activeFeatures?.find(feature => feature.feature_code === BOOKINGS_CODES.REJECT_BOOKING)?.enabled || currentActiveTab === 'canceled'}
                onClick={() => {
                  dispatch(setCurrentOptionModalBody("3"))
                }}
              >
                <span>CANCELAR RESERVA</span>
              </Button>
            </CustomPopoverConfirm>

          </Col>
          <Col span={12}>
            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === BOOKINGS_CODES.APPROVE_BOOKING)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Aprobar reserva'}`}>
              <Button
                type="link"
                htmlType="button"
                shape="round"
                className="btn-standard-green"
                style={{ width: 250, opacity: currentActiveTab !== 'awaitingConfirmation' ? 0.5 : 1 }}
                disabled={!activeFeatures?.find(feature => feature.feature_code === BOOKINGS_CODES.APPROVE_BOOKING)?.enabled || currentActiveTab !== 'awaitingConfirmation'}
                onClick={() => {
                  dispatch(setCurrentOptionModalBody("2"))
                }}
              >
                <span>ENVIAR APROBACIÓN</span>
              </Button>
            </CustomPopoverConfirm>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default DetailReserveBody