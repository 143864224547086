import axios from "axios"

const deleteTicketSubCategory = async (subCategoryId) => {
  try {
    const requestData = { subcategory_id: subCategoryId }
    const response = await axios.delete('https://domo.topiaapp.com/api/ticket/category/subcategory',
    { data: requestData })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      
      ...error.response
    }
  }
}

export default deleteTicketSubCategory