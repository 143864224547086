import { useState } from "react"
import { Button, Col, Form, Row } from "antd"
import useBookings from "../../hooks/useBookings"
import { CustomSpin } from "../../../../UI/CustomSpin"
import { CustomInput } from '../../../../UI/CustomInput'
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store"
import { setCurrentOptionModalBody } from "../../../../../store/bookings/bookingsSlice"

const ApproveCancelReserveBody = ({ loadingModal, handleOpenCloseModal }) => {
  const dispatch = useAppDispatch()  
  const [loading, setLoading] = useState(false)

  const { onSaveBooking, getAllDataBookings } = useBookings()
  const { currentOptionModalBody } = useAppSelector(state => state.bookings)

  const { Item } = Form

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      handleOpenCloseModal()
      await getAllDataBookings()
    }
  }

  const onSubmit = async (formData) => {
    setLoading(true)

    const response = await onSaveBooking(formData)

    await onFinish(response)
    setLoading(false)
  }
  // Se agrega dinamicamente el evento onClick o no dependiendo que accion quiere ejecutar el usuario, es decir,
  // Si quiere cancelar o aprobar una reserva
  const propsCancelButton = currentOptionModalBody !== "3" && {
    onClick: () => {
      dispatch(setCurrentOptionModalBody("1"))
    }
  }

  const propsApproveButton = currentOptionModalBody === "3" && {
    onClick: () => {
      dispatch(setCurrentOptionModalBody("1"))
    }
  }

  if (loadingModal) return <CustomSpin />

  return (
    <Form
      layout="vertical"
      name="approveCancelReserveForm"
      onFinish={onSubmit}
      disabled={loading}
    >
      <Row>
        <Col span={24}>
          <h2 style={{ textAlign: "center", fontWeight: 600, fontSize: 22, color: "#232C4A" }}>
            {currentOptionModalBody === "3" ? 'CANCELAR RESERVACIÓN' : 'ENVIAR APROBACIÓN'}
          </h2>

          <label style={{ fontWeight: 400, fontSize: 12, color: "#404040" }}>
            COMENTARIOS
          </label>

          <Item name="comment">
            <CustomInput type="AREA" className="custom-textarea" />
          </Item>

          <Row justify="center">
            <p style={{ fontSize: 12, fontWeight: 400, color: '#252525' }}>
              ¿Desea {currentOptionModalBody === "3" ? 'cancelar esta reservación' : 'aprobar la reserva'}?
            </p>
          </Row>

          <Row justify="space-between" gutter={16}>
            <Col span={12}>
              <Button
                type="link"                
                htmlType={currentOptionModalBody === "3" ? "submit" : "button"}
                shape="round"
                loading={loading}
                style={{ height: 42, width: 250, color: "#ff2e2e", backgroundColor: "#ffffff", fontSize: 14, fontWeight: 500 }}
                {...propsCancelButton}
              >
                <span>CANCELAR RESERVA</span>
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="link"
                htmlType={currentOptionModalBody === "3" ? "button" : "submit"}
                shape="round"
                loading={loading}
                className="btn-standard-green"
                style={{ width: 250 }}
                {...propsApproveButton}
              >
                <span>{currentOptionModalBody === "3" ? 'REGRESAR' : 'APROBAR'}</span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

export default ApproveCancelReserveBody
