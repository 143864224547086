import { Button, Row } from "antd"
import useCountDown from "../hooks/useCountDown"

const CustomCountDown = ({
  functionToExecute,
  timeLeft,
  setTimeLeft,
  initialTime,
  isBtn = false,
  onClickFunction,
  classNameProp,
  labelBtn,
  maxWidth
}) => {
  const Component = isBtn ? Button : Row;

  useCountDown(functionToExecute, initialTime, timeLeft, setTimeLeft);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <Component
      onClick={isBtn ? onClickFunction : null}
      className={classNameProp ? classNameProp : null}
      style={{ maxWidth }}
    >
      <Row>
        <p style={{ marginRight: "1rem", marginBottom: 0 }}>
          {labelBtn}
        </p>
        <span>
          {minutes < 10 ? `0${minutes}` : minutes} :{" "}
          {seconds < 10 ? `0${seconds}` : seconds}
        </span>
      </Row>
    </Component>
  );
};

export default CustomCountDown;
