import { Form } from "antd"
import { createContext, useState } from "react"

export const TypesNewsContext = createContext()

export const TypesNewsProvider = ({ children }) => {
  const [form] = Form.useForm()

  const [isUrl, setIsUrl] = useState(false)
  const [color, setColor] = useState("#16A07F")

  const [fileListImg, setFileListImg] = useState([])
  const [fileListFiles, setFileListFiles] = useState([])
  const [isPublishedButton, setIsPublishedButton] = useState(false)

  const [valueRadio, setValueRadio] = useState(1)
  const [valueRadioDateEnd, setValueRadioDateEnd] = useState(0)

  const [title, setTitle] = useState("")
  const [icon, setIcon] = useState(null)
  const [iconFile, setIconFile] = useState(null)

  // Resetear el formulario
  const onReset = () => form.resetFields()

  const onCleanAllStates = () => {
    setColor("#16A07F")
    setIsUrl(false)
    setFileListImg([])
    setFileListFiles([])
    setIsPublishedButton(false)
    setValueRadio(1)
    setValueRadioDateEnd(0)
    setTitle("")
    setIcon(null)
    setIconFile(null)
  }

  const value = {
    form,
    onReset,
    onCleanAllStates,
    color, setColor,
    fileListImg, setFileListImg,
    fileListFiles, setFileListFiles,
    isUrl, setIsUrl,
    isPublishedButton, setIsPublishedButton,
    title, setTitle,
    icon, setIcon,
    iconFile, setIconFile,
    valueRadio, setValueRadio,
    valueRadioDateEnd, setValueRadioDateEnd
  }

  return (
    <TypesNewsContext.Provider value={value}>
      {children}
    </TypesNewsContext.Provider>
  )

}