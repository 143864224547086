//Se exportan los nombres de las propiedades de los tipos de colores que pueden haber dentro de un tema para formar grupos de colores
export const themeSettingsColor = ['primary', 'secondary', 'neutral', 'bw', 'warning', 'success', 'negative', 'info']

//El estado inicial que se usa en "themeSlice" que incluye los objetos del tema, los colores del tema y las imagenes, que pueden recuperarse desde el localstorage al recargar la página
export const themeInitialState = {
    theme: {
        code: '',
        name: '',
    },
    themeColors: {
        "primary": {
            "primary": "#16A07F",
            "primaryplus1": "#13886C",
            "primaryplus2": "#10725A",
            "primaryminus1": "#3EB095",
            "primaryminus2": "#78C8B5",
            "primaryminus3": "#9FD8CB",
            "primaryminus4": "#E8F6F2"
        },
        "secondary": {
            "secondary": "#232C4A",
            "secondaryplus1": "#191F34",
            "secondaryplus2": "#151B2D",
            "secondaryminus1": "#485069",
            "secondaryminus2": "#7F8596",
            "secondaryminus3": "#A5A8B5",
            "secondaryminus4": "#E9EAED"
        },
        "warning": {
            "warning": "#F8C822",
            "warningplus1": "#AE8C18",
            "warningplus2": "#977A15",
            "warningminus1": "#F9D148",
            "warningminus2": "#FBDF7F",
            "warningminus3": "#FCE8A4",
            "warningminus4": "#FEFAE9"
        },
        "success": {
            "success": "#05BE4F",
            "successplus1": "#048537",
            "successplus2": "#037430",
            "successminus1": "#30C96D",
            "successminus2": "#6ED999",
            "successminus3": "#99E4B7",
            "successminus4": "#E6F9ED"
        },
        "negative": {
            "negative": "#FD535B",
            "negativeplus1": "#B13A40",
            "negativeplus2": "#9A3338",
            "negativeminus1": "#FD7077",
            "negativeminus2": "#FE9BA0",
            "negativeminus3": "#FEB8BC",
            "negativeminus4": "#FFEEEF"
        },
        "info": {
            "info": "#00ABF5",
            "infoplus1": "#0078AC",
            "infoplus2": "#006895",
            "infominus1": "#2BB9F7",
            "infominus2": "#6BCEF9",
            "infominus3": "#96DDFB",
            "infominus4": "#E6F7FE"
        },
    },
    themeImages: localStorage.getItem('images-dashboard') ? JSON.parse(localStorage.getItem('images-dashboard')) : []
}

//Se colocan aquí los codigos de zona que hacen referencia a las ubicaciones en donde estarán las imágenes que provienen del endpoint y tener centralizados los strings
export const THEME_ZONE_CODES = {
    SIDEBAR_ICON: 'sidebar-icon',
    CC_BANNER_HOME: 'cc-banner-home',
    COMPANY_LOGO: 'company-logo',
    CC_WELCOME_SPLASH: 'cc-welcome-splash',
}