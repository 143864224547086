import { useState } from "react"
import { Button, Col, Row } from "antd"
import useBookings from "../hooks/useBookings"
import { CustomSelect } from "../../../UI/CustomSelect"
import { CustomNavigate } from "../../../UI/CustomNavigate"
import { CustomDatePicker } from "../../../UI/CustomDatePicker"
import CustomSwitchButton from "../../../UI/CustomSwitchButton"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { setAllFilters } from "../../../../store/bookings/bookingsSlice"

// imagenes o archivos
import { ReactComponent as ExcelIcon } from '../../../../assets/icons/excel.svg'

const HeaderBookingActions = ({
  currentBody,
  setCurrentBody
}) => {
  const dispatch = useAppDispatch()
  const [loadingExcel, setLoadingExcel] = useState(false)

  const { currentActiveTab, filters, metadata, filtersCalendar } = useAppSelector(state => state.bookings)
  const { getReservesByFilters, getAmenitiesBySearch, getProfilesBySearch, getBookingsExcelData } = useBookings()

  // Funciones para manejar los filtros desde el redux y realizar las peticiones
  const handleOnChange = async (dates) => {
    if (!dates) {
      await getReservesByFilters({
        ...filters,
        ...metadata,
        reservationCode: currentActiveTab,
        dateBegin: '',
        dateEnd: '',
      })
      dispatch(setAllFilters({ ...filters, dateBegin: '', dateEnd: '' }))
      return
    }

    await getReservesByFilters({
      ...filters,
      ...metadata,
      dateBegin: dates[0]?.format('YYYY-MM-DD'),
      dateEnd: dates[1]?.format('YYYY-MM-DD'),
      reservationCode: currentActiveTab,
    })
    dispatch(setAllFilters({ ...filters, dateBegin: dates[0]?.format('YYYY-MM-DD'), dateEnd: dates[1]?.format('YYYY-MM-DD') }))
  }

  const handleOnChangeAmenities = async (value) => {
    // Si limpia el selector realiza la peticion de nuevo pero sin ese valor seleccionado
    if (!value) {
      await getReservesByFilters({
        ...filters,
        ...metadata,
        placeId: '',
        reservationCode: currentActiveTab,
      })
      dispatch(setAllFilters({ ...filters, placeId: '' }))
      return
    }

    await getReservesByFilters({
      ...filters,
      ...metadata,
      placeId: value,
      reservationCode: currentActiveTab,
    })
    dispatch(setAllFilters({ ...filters, placeId: value }))
  }

  const handleOnChangeUsers = async (value) => {
    // Si limpia el selector realiza la peticion de nuevo pero sin ese valor seleccionado
    if (!value) {
      await getReservesByFilters({
        ...filters,
        ...metadata,
        profileId: '',
        reservationCode: currentActiveTab,
      })
      dispatch(setAllFilters({ ...filters, profileId: '' }))

      return
    }

    await getReservesByFilters({
      ...filters,
      ...metadata,
      profileId: value,
      reservationCode: currentActiveTab,
    })
    dispatch(setAllFilters({ ...filters, profileId: value }))
  }

  const handleOnClickExcel = async () => {
    setLoadingExcel(true)
    await getBookingsExcelData({ date_begin: filtersCalendar?.date_begin, date_end: filtersCalendar?.date_end })
    setLoadingExcel(false)
  }

  return (
    <header>
      <CustomNavigate title={'RESERVAS DE AMENIDADES'}>
        <Row align="middle">
          <Col span={24}>
            <Row justify="end" align="middle" gutter={8} style={{ marginBottom: currentBody === 'tableBody' ? 20 : 0 }}>
              <Col>
                <Button
                  type="link"
                  htmlType="button"
                  shape="circle"
                  loading={loadingExcel}
                  disabled={loadingExcel}
                  icon={<ExcelIcon />}
                  onClick={handleOnClickExcel}
                />
              </Col>

              <Col>
                <CustomSwitchButton
                  setCurrentBody={setCurrentBody}
                  labelButtonOne="Vista Calendario"
                  labelButtonTwo="Vista Tabla"
                  labelStateOne="calendarBody"
                  labelStateTwo="tableBody"
                />
              </Col>
            </Row>
            
            {currentBody === 'tableBody' && (<Row justify="end" align="middle" gutter={8}>
                <Col span={7}>
                  <CustomSelect onChange={handleOnChangeAmenities} allowClear isAsync placeholder="Seleccione una amenidad" service={getAmenitiesBySearch} />        
                </Col>

                <Col span={7}>
                  <CustomSelect onChange={handleOnChangeUsers} allowClear isAsync placeholder="Seleccione un usuario" service={getProfilesBySearch} />
                </Col>

                <Col span={7}>
                  <CustomDatePicker allowClear type="RANGER" onChange={handleOnChange} />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </CustomNavigate>
    </header >
  )
}

export default HeaderBookingActions