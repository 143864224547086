import { useContext } from "react"
import { Col, Divider, Row } from "antd"
import SpinLoader from "../../../../../../UI/SpinLoader"
import DetailsChecklistTable from "./DetailsChecklistTable"
import CreateTicketByChecklist from "./CreateTicketByChecklist"
import FooterActionsDetails from "./layout/FooterActionsDetails"
import { useFeatureRole } from "../../../../../../hooks/useFeatureRole"
import { HandoverChecklistContext } from "../../../context/HandoverChecklistContext"
import { CreateTicketByCheckListContextProvider } from "../../../context/CreateTicketByCheckListContext"
import { HANDOVER_CHECKLIST_CODES, HANDOVER_CHECKLIST_CODES_NAMES } from "../../../../../../../constants/modules/handoverChecklist/handoverChecklistCodes"

const MiddleBody = ({
  isCurrentBody,
  setDataSource,
  expandedRowKeys,
  handleRowExpand,
  handleCurrentBody,
}) => {
  const { loading, detailChecklistById } = useContext(HandoverChecklistContext)

  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: HANDOVER_CHECKLIST_CODES, validate_code: HANDOVER_CHECKLIST_CODES_NAMES.HANDOVER_CHECKLIST_DETAILS_HANDOVER, returnUrl: '/checklists-admin' })

  let body = (
    <div>
      <DetailsChecklistTable
        expandedRowKeys={expandedRowKeys}
        setDataSource={setDataSource}
        handleRowExpand={handleRowExpand}
      />

      {detailChecklistById?.checklistInstanceDetail?.failedReviews > 0 && <Divider style={{ margin: '75px 0' }} />}

      {detailChecklistById?.checklistInstanceDetail?.failedReviews > 0 && 
        <footer style={{ position: "fixed", bottom: 0, width: `${(window.innerWidth) - 90}px` }}>
          <FooterActionsDetails activeFeatures={activeFeatures} handleCurrentBody={handleCurrentBody} />
        </footer>
      }
    </div>
  )

  if (isCurrentBody) body = (
    <CreateTicketByCheckListContextProvider>
      <CreateTicketByChecklist />
    </CreateTicketByCheckListContextProvider>
  )

  return (
    <Row>
      <Col span={24}>
        {(loading || loadingFeatures) ? <SpinLoader size="large" /> : body}
      </Col>
    </Row>
  )
}

export default MiddleBody