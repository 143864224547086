
//Se exporta un arreglo para el select de filtro por instancia en las caracteristicas de los modulos de un rol
export const typeFeatures = [
    {
        id: 'App de agentes',
        name: 'App de agentes'
    },
    {
        id: 'App de residentes',
        name: 'App de residentes'
    },
    {
        id: 'Dashboard',
        name: 'Dashboard'
    },
    {
        id: 'App de administradores y empleados',
        name: 'App de administradores y empleados'
    },
    {
        id: 'App de centro comerciales',
        name: 'App de centro comerciales'
    },
]