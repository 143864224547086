import { useContext, useState } from "react"
import { Button, Col, Image, Row } from "antd"
import CustomModal from "../../../../UI/CustomModal"

// archivos o imagenes
import warningIcon from '../../../../../assets/icons/waring.svg'
import { ChecklistsAdminContext } from "../../context/ChecklistsAdminContext"
import useChecklists from "../../hooks/useChecklists"

const DeleteChecklistAdminModal = ({
  isOpenModal,
  handleOpenCloseModal
}) => {
  const [loading, setLoading] = useState(false)

  const { onDeleteChecklists, getChecklistsTable } = useChecklists()
  const { checklistAdminClicked, setChecklistAdminClicked, filters } = useContext(ChecklistsAdminContext)

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      handleOpenCloseModal()
      await getChecklistsTable({ ...filters })
    }
  }

  const onDeleteChecklist = async () => {
    setLoading(true)

    const response = await onDeleteChecklists({ checklist_id: checklistAdminClicked?.checklist_id })

    await onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal
      destroyOnClose
      width={491}
      isOpenModal={isOpenModal}
      onCancel={() => {
        handleOpenCloseModal()
        setChecklistAdminClicked({})
    }}
    >
      <Row>
        <Col span={24}>
          <Row justify="center">
            <Image src={warningIcon} alt="x-icon" width={59} height={59} />
          </Row>

        <Row justify="center">
          <label style={{ textAlign: 'center', width: 416, margin: '25px 0', color: '#373737', fontWeight: 500, fontSize: 20 }}>
            ¿Deseas archivar esta checklist?
          </label>
        </Row>

        <Row justify="end" align="middle" gutter={16}>
          <Col span={8}>
            <Row justify="center">
              <Button
                type="text"
                shape="round"
                htmlType="button"
                onClick={() => {
                  handleOpenCloseModal()
                  setChecklistAdminClicked({})
                }}
                disabled={loading}
                loading={loading}
                style={{ padding: '0px 32px 0px 32px', height: 40, width: 132  }}
              >
                <span style={{ color: '#404040', fontSize: 14, fontWeight: 500 }}>CANCELAR</span>
              </Button>
            </Row>
          </Col>

          <Col span={8}>
            <Row justify="center">
              <Button
                type="text"
                shape="round"
                htmlType="button"
                disabled={loading}
                loading={loading}
                onClick={onDeleteChecklist}
                style={{ padding: '0px 32px 0px 32px', backgroundColor: '#FF5858', height: 40, width: 132 }}
              >
                <span style={{ color: '#FFFFFF', fontWeight: 500, fontSize: 14 }}>ARCHIVAR</span>
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
    </CustomModal>
  )
}

export default DeleteChecklistAdminModal