import { Button, Col, Row } from "antd"
import { ReactComponent as EyeIcon } from '../../assets/icons/eyeIcon.svg'
import { SURVEYS_CODES } from "../../../../../constants/modules/surveys/surveysCodes"
import CustomPopoverConfirm from "../../../../UI/CustomPopoverConfirm"

export const DetailSurvey = ({ surveysDetail, loadMoreData, handleGetResults, activeFeatures }) => {
    return (
        <>
            <div className='primary-card-detail survey-detail'>

                <div className="primary-card-section" style={{ lineHeight: "16px" }}>
                    <h4>Encuesta sobre</h4>
                    <h2>
                        {surveysDetail.name}
                    </h2>
                    <span style={{ color: 'var(--primary)' }}>ID: {surveysDetail.id}</span>
                </div>
                <div className="primary-card-section">

                    <Row gutter={48} style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                        <Col lg={24} xl={16} style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>

                            <div className="answered-surveys">
                                {surveysDetail.encuestasContestadas}
                            </div>
                            <h4 style={{ marginLeft: "10px" }}>Encuestas <br /> contestadas</h4>

                        </Col>

                        <Col lg={12} xl={8} className="contCreacion">
                            <h4>Creación:</h4>
                            <p>
                                {surveysDetail.created_at}
                            </p>
                        </Col>
                    </Row>

                    <Row style={{ borderTop: "1px solid #ccc", borderBottom: "1px solid #ccc", }}>
                        <Col lg={12}>
                            <div style={{ paddingLeft: "50px" }}>
                                <h4>Inicio</h4>
                                <p>{surveysDetail.startDate}</p>
                            </div>
                        </Col>
                        <Col lg={12}>

                            <div style={{ paddingLeft: "50px" }}>
                                <h4>Expiro</h4>
                                <p>{surveysDetail.endDate}</p>
                            </div>

                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center", marginTop: "24px", display: "flex", justifyContent: 'center' }}>
                        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === SURVEYS_CODES.SURVEYS_DETAILS_SURVEY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Detalles de la encuesta'}`}>
                            <Button disabled={!activeFeatures?.find(feature => feature.feature_code === SURVEYS_CODES.SURVEYS_DETAILS_SURVEY)?.enabled} icon={<EyeIcon className="btn-svg-icon-variant3" />} loading={loadMoreData} onClick={handleGetResults} shape="round" className="btn-standard-white">
                                Ver resultados
                            </Button>
                        </CustomPopoverConfirm>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default DetailSurvey