import { useState } from "react"
import HeaderAdminChecklist from "./layout/HeaderAdminChecklist"
import AdminChecklistTable from "./components/AdminChecklistTable"
import useOpenCloseModal from "../../../../hooks/useOpenCloseModal"
import { AdminChecklistProvider } from "./context/AdminChecklistContext"
import FiltersAdminChecklistTable from "./components/FiltersAdminChecklistTable"
import EditChecklistModal from "./components/modals/EditChecklistModal"

const AdminChecklistPage = () => {
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false)

  const handleOpenCloseCategoryModal = () => setIsOpenCategoryModal((state) => !state)

  const { form, isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  return (
    <AdminChecklistProvider>
      <HeaderAdminChecklist formChecklist={form} handleOpenCloseModal={handleOpenCloseModal} handleOpenCloseCategoryModal={handleOpenCloseCategoryModal} />
      
      <FiltersAdminChecklistTable />

      <AdminChecklistTable isOpenCategoryModal={isOpenCategoryModal} handleOpenCloseCategoryModal={handleOpenCloseCategoryModal} />
    
      <EditChecklistModal form={form} isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
    </AdminChecklistProvider>
  )
}

export default AdminChecklistPage
