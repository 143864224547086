import { Calendar } from "antd"

const CustomCalendar = ({
  onChange,
  validRange,
  headerRender,
  onPanelChange,
  dateCellRender,
  mode = 'month',
  fullscreen = true,
}) => {
  return (
    <Calendar
      mode={mode}
      fullscreen={fullscreen}
      headerRender={headerRender}
      dateCellRender={dateCellRender}
      validRange={validRange}
      onChange={onChange}
      onPanelChange={onPanelChange}
    />
  )
}

export default CustomCalendar
