import axios from "axios";


const fireEmployeeService = async(data) => {
    try {
        const res = await axios.put('https://domo.topiaapp.com/api/employee/status/fired', data)
        return {
            success: true,
            data: res.data,
            status: res.status
        }
    } catch (error) {
        return {
            success: false,
            data: error.response,
        }
    }
    
};

export default fireEmployeeService;