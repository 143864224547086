//MANTENIMIENTO DE ACTIVOS ✅

export const activeMaintenanceModule = {
    feature_code: 'FEAT-405',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-406',
            enabled: false,
        },
        {
            feature_code: 'FEAT-407',
            enabled: false,
            children: [
                {
                    feature_code: 'FEAT-408',
                    enabled: false,
                },
                {
                    feature_code: 'FEAT-409',
                    enabled: false,
                },
            ]
        },
        {
            feature_code: 'FEAT-475',
            enabled: false,
        },
        {
            feature_code: 'FEAT-472',
            enabled: false,
        },
        {
            feature_code: 'FEAT-410',
            enabled: false
        },
        {
            feature_code: 'FEAT-411',
            enabled: false,
        },
        {
            feature_code: 'FEAT-412',
            enabled: false,
        },
    ]
}