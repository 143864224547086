import { useEffect } from "react"
import { Button, Col, Row } from "antd"
import { CustomSpin } from "../../../../UI/CustomSpin"
import { CustomInput } from "../../../../UI/CustomInput"
import useRedirectTo from "../../../../hooks/useRedirectTo"
import CustomTabs from "../../../../UI/CustomTabs/CustomTabs"
import { CustomNavigate } from "../../../../UI/CustomNavigate"
import DeleteActiveModal from "./components/DeleteActiveModal"
import CustomTable from "../../../../UI/CustomTable/CustomTable"
import NewEditActiveModal from "./components/NewEditActiveModal"
import { ProvidersListModal } from './components/ProvidersListModal'
import CustomPopoverConfirm from "../../../../UI/CustomPopoverConfirm"
import CompanyAssetDetailDrawer from "./drawer/CompanyAssetDetailDrawer"
import ExportExcelReportModal from "./components/ExportExcelReportModal"
import { useActivesMaintenance } from "../../hooks/useActivesMaintenance/useActivesMaintenance"
import useActivesMaintenanceTable from "../../hooks/useActivesMaintenance/useActivesMaintenanceTable"
import { ACTIVE_MAINTENANCE_CODES } from "../../../../../constants/modules/activeMaintenance/activeMaintenanceCodes"

// imagenes o archivos
import { PlusOutlined } from "@ant-design/icons"
import searchIcon from '../../assets/searchIcon.svg'
import { ReactComponent as ProvidersIcon } from '../../assets/providersIcon.svg'
import { ReactComponent as ExcelIcon } from '../../../../../assets/icons/excel.svg'

import '../styles.css'

export const ActiveMaintenance = () => {
  const {
    getInitialData, handleCloseProviderModal, handleSearchActiveMaintenance, setShowProvidersModal, onSubmit,
    setCurrentPageTableProviders, setShowCreateActiveModal, setFilesList, getAllCompanyProvidersService,
    currentPageTableProviders, quantityTableProviders, limitTableProviders, showProvidersModal,
    dataTableActiveMaintenance, dataTableProviders, currentPageTableActiveMaintenance, limitTableActiveMaintenance,
    quantityTableActiveMaintenance, showCreateActiveModal, filesList, searchValue, statusActivesMaintenance,
    handleOpenCloseDrawer, handleOpenCloseExcelReportModal, getDetailActive, setClickedActive
  } = useActivesMaintenance()
  
  const {
    handleChangeTable, handlePagination, loading, loadingProvider, handleOnChangeTabs, columnsActiveMaintenance,
    columnsModified, loadingFeatures, activeFeatures,
  } = useActivesMaintenanceTable()

  const { handleRedirectTo } = useRedirectTo()

  const handleOnRowClick = async (activeRowData) => {
    const response = await getDetailActive(activeRowData?.company_asset_id)

    if (response.success) {
      handleOpenCloseDrawer()
    }
  }

  const items = statusActivesMaintenance?.map((statusData) => ({
    key: statusData.company_asset_status_id,
    label: statusData.name,
    children: <CustomTable
      loading={loading}
      rowClassName="row-className"
      tableName={'activeMaintenances'}
      columns={columnsActiveMaintenance ?? []}
      dataSource={dataTableActiveMaintenance ?? []}
      onChange={handleChangeTable}
      pagination={{
        pageSize: limitTableActiveMaintenance || undefined,
        total: quantityTableActiveMaintenance || undefined,
        showTotal: (total, range) => {
          return `${range[0]}-${range[1]} de ${total}`;
        },
        defaultPageSize: limitTableActiveMaintenance || undefined,
        current: currentPageTableActiveMaintenance || undefined,
        position: ["bottomRight"]
      }}
      className="custom-table active-maintenanceTable"
      onRow={(record) => {
        return {
          onDoubleClick: () => {
            setClickedActive(record)
            handleOnRowClick(record)
          }
        }
      }}
    />
  }))

  useEffect(() => {
    getInitialData({})
  }, [])

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (
    <div className="section-wrapper">
      <CustomNavigate title={'MANTENIMIENTO DE ACTIVOS'}>
        <Row gutter={6} align="middle">
          <Col>
            <Button
              type="link"
              htmlType="button"
              icon={<ExcelIcon />}
              onClick={handleOpenCloseExcelReportModal}
              style={{ border: 'none', boxShadow: 'none', background: 'transparent' }}
            />
          </Col>
          <Col>
            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_MANAGEMENT_PROVIDER)?.enabled ? 'No tienes permiso para realizar esta acción' : 'PROVEEDORES'}`}>
              <Button
                disabled={!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_MANAGEMENT_PROVIDER)?.enabled}
                onClick={() => setShowProvidersModal(true)}
                shape="round"
                htmlType="button"
                type="text"
                style={{ width: 164, border: 0, backgroundColor: '#FFFFFF', height: 42 }}
              >
                <Row justify="center" align="middle" gutter={8}>
                  <Col>
                    <ProvidersIcon className="btn-svg-icon-variant2" />
                  </Col>

                  <Col>
                    <span style={{ color: '#707070', fontSize: 13, fontWeight: 500 }}>PROVEEDORES</span>
                  </Col>
                </Row>
              </Button>
            </CustomPopoverConfirm>
          </Col>

          <Col>
            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_NEW_MULTISELECT_REPORT)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nuevo reporte'}`}>
              <Button
                disabled={!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_NEW_MULTISELECT_REPORT)?.enabled}
                onClick={() => handleRedirectTo('new-report')}
                icon={<PlusOutlined style={{ color: '#707070' }} />}
                style={{ width: 176, border: 0, backgroundColor: '#FFFFFF', height: 42 }}
                shape="round"
                htmlType="button"
                type="text"
              >
                <span style={{ color: '#707070', fontSize: 13, fontWeight: 500 }}>NUEVO REPORTE</span>
              </Button>
            </CustomPopoverConfirm>
          </Col>

          <Col>
            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_NEW_ACTIVE)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nuevo activo'}`}>
              <Button
                disabled={!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_NEW_ACTIVE)?.enabled}
                onClick={() => {
                  setFilesList([])
                  setShowCreateActiveModal(true)
                }}
                icon={<PlusOutlined />}
                className="btn-standard-green"
                style={{ width: 217 }}
                shape="round"
                htmlType="button"
                type="text"
              >
                NUEVO ACTIVO
              </Button>
            </CustomPopoverConfirm>
          </Col>
        </Row>
      </CustomNavigate>

      <CustomSpin spinning={false}>
        <CustomTabs items={items} className="custom-tabs-variant-4" onChange={handleOnChangeTabs} />

        <Row style={{ position: 'absolute', top: 10, right: 0, width: '25%' }}>
          <Col span={24}>
            <CustomInput
              value={searchValue}
              onChange={handleSearchActiveMaintenance}
              suffixIcon={searchIcon}
              placeholder={'Buscar por nombre o código'}
            />
          </Col>
        </Row>
      </CustomSpin>

      <ExportExcelReportModal />

      <CompanyAssetDetailDrawer />

      <DeleteActiveModal />

      <NewEditActiveModal
        loading={loading}
        isOpenModal={showCreateActiveModal}
        handleCloseModal={handleCloseProviderModal}
        onSubmit={onSubmit}
        providers={dataTableProviders}
        filesList={filesList}
        setFilesList={setFilesList}
        service={getAllCompanyProvidersService}
      />
      
      <ProvidersListModal
        activeFeatures={activeFeatures}
        loading={loadingProvider}
        handlePagination={handlePagination}
        handleChangeTable={handleChangeTable}
        onSubmit={onSubmit}
        providers={dataTableProviders}
        columnsModified={columnsModified}
        isOpenModal={showProvidersModal}
        handleCloseModal={handleCloseProviderModal}
        setCurrentPageTableProviders={setCurrentPageTableProviders}
        currentPageTableProviders={currentPageTableProviders}
        limitTableProviders={limitTableProviders}
        quantityTableProviders={quantityTableProviders}
      />
    </div>
  )
}

export default ActiveMaintenance