import { API_URL, axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const rolPath = "role/company/module/feature"

//Endpoint de topia con el que a partir del id del rol y del modulo se habilitan o se deshabilitan las caracteristicas del modulo, pasando en un arreglo los id de cada característica
export const updateFeaturesRole = async ({
    token,
    formData
}) => {
    try {
        const response = await axiosCreate.put(`${API_URL}${rolPath}`, formData, {
            timeout: 10000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }
};