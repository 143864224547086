import { createContext, useState } from "react"
import useOpenCloseModal from "../../../hooks/useOpenCloseModal"

export const UsersContext = createContext()

export const UsersProvider = ({ children }) => {
  const [typeUserSelected ,setTypeUserSelected] = useState('titular')
  const [userSpaces, setUserSpaces] = useState([])
  const [usersTableData, setUsersTableData] = useState({})
  const [userClicked, setUserClicked] = useState({})
  const [userDetailData, setUserDetailData] = useState({})
  const [typesOwner, setTypesOwner] = useState([])
  const [ownerPlaces, setOwnerPlaces] = useState([])

  const [filters, setFilters] = useState({
    findValue: '',
  })
  const [metadata, setMetadata] = useState({})
  
  const [loadingExcel, setLoadingExcel] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const { form, onCancel, isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  const value = {
    typeUserSelected, setTypeUserSelected,
    userSpaces, setUserSpaces,
    ownerPlaces, setOwnerPlaces,
    typesOwner, setTypesOwner,
    filters, setFilters,
    loading, setLoading,
    loadingExcel, setLoadingExcel,
    metadata, setMetadata,
    userClicked, setUserClicked,
    currentPage, setCurrentPage,
    userDetailData, setUserDetailData,
    usersTableData, setUsersTableData,
    form, handleOpenCloseModal, onCancel, isOpenModal,
  }

  return <UsersContext.Provider value={value}>
    {children}
  </UsersContext.Provider>
}