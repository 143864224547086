
export const INVENTORY_CODES = {
    INVENTORY_MODULE: 'FEAT-277',
    INVENTORY_DELETE_SUPPLY: 'FEAT-54',
    INVENTORY_DELETE_EQUIPMENT: 'FEAT-55',
    INVENTORY_NEW_SUPPLY: 'FEAT-56',
    INVENTORY_NEW_EQUIPMENT: 'FEAT-57',
    INVENTORY_UPDATE_SUPPLY: 'FEAT-58',
    INVENTORY_UPDATE_EQUIPMENT: 'FEAT-59',
    INVENTORY_NEW_CATEGORY: 'FEAT-61',
    INVENTORY_UPDATE_CATEGORY: 'FEAT-62',
    INVENTORY_DELETE_CATEGORY: 'FEAT-63',
    INVENTORY_MANAGEMENT_SUBCATEGORIES: 'FEAT-278',
}

export const INVENTORY_CODES_NAMES = {
    INVENTORY_MODULE: 'INVENTORY_MODULE',
    INVENTORY_DELETE_SUPPLY: 'INVENTORY_DELETE_SUPPLY',
    INVENTORY_DELETE_EQUIPMENT: 'INVENTORY_DELETE_EQUIPMENT',
    INVENTORY_NEW_SUPPLY: 'INVENTORY_NEW_SUPPLY',
    INVENTORY_NEW_EQUIPMENT: 'INVENTORY_NEW_EQUIPMENT',
    INVENTORY_UPDATE_SUPPLY: 'INVENTORY_UPDATE_SUPPLY',
    INVENTORY_UPDATE_EQUIPMENT: 'INVENTORY_UPDATE_EQUIPMENT',
    INVENTORY_NEW_CATEGORY: 'INVENTORY_NEW_CATEGORY',
    INVENTORY_UPDATE_CATEGORY: 'INVENTORY_UPDATE_CATEGORY',
    INVENTORY_DELETE_CATEGORY: 'INVENTORY_DELETE_CATEGORY',
    INVENTORY_MANAGEMENT_SUBCATEGORIES: 'INVENTORY_MANAGEMENT_SUBCATEGORIES',
}