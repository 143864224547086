import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { getAllReservationStatus, getAllReservations, getAmenitiesByCompanyId, getBookingsCalendar, getBookingsCalendarStatus, getDetailReserve, getExcelData, getUsersProfileByCompanyId, updateToApproveStatusReserve, updateToCancelStatusReserve } from "../../../../store/bookings/thunks"
import { setCurrentActiveTab } from "../../../../store/bookings/bookingsSlice"

const useBookings = () => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const { currentBookingId, currentOptionModalBody, currentActiveTab, filters } = useAppSelector(state => state.bookings)

  // Funcion onSave de reservas
  const onSaveBooking = async (formData) => {
    const functionToExecute = currentOptionModalBody === "3" ? updateToCancelStatusReserve : updateToApproveStatusReserve

    const response = await dispatch(await functionToExecute({ ...formData, reservation_id: currentBookingId }))

    return response
  }

  // Funciones get para obtener la data inicial de reservas y refirige a la primera tab siempre
  const getAllDataBookings = async () => {
    setLoading(true)
    // Se pone el doble await para esperar y obtener lo que retorna el dispatch
    const response = await dispatch(getAllReservationStatus())

    if (response.success) {
      const { reservation_code } = response.data.data[0]
      dispatch(await getAllReservations({ reservationCode: currentActiveTab || reservation_code }))
      dispatch(setCurrentActiveTab(currentActiveTab || reservation_code))
    }
    setLoading(false)
  }

  // Funcion get utilizada para filtros de busqueda
  const getAmenitiesBySearch = async ({ find_by, find_value }) => {
    return dispatch(getAmenitiesByCompanyId({ find_by: find_by, find_value: find_value }))
  }

  // Funcion get utilizada para filtros de busqueda
  const getProfilesBySearch = async ({ find_by, find_value, limit, skip, setLoading }) => {
    return dispatch(getUsersProfileByCompanyId({ find_by: find_by, find_value: find_value, limit: limit, skip: skip, setLoading }))
  }

  const getReservesByFilters = async ({
    reservationCode,
    limit,
    skip,
    sortBy,
    sortOrder,
    placeId,
    profileId,
    dateBegin,
    dateEnd,
  }) => {
    dispatch(await getAllReservations({
      reservationCode,
      limit,
      skip,
      sortBy,
      sortOrder,
      placeId,
      profileId,
      dateBegin,
      dateEnd,
    }))
  }

  const getDetailBooking = async (reservationId) => {
    const response = await dispatch(getDetailReserve(reservationId))

    return response
  }

  const getBookingsExcelData = async ({ date_begin, date_end }) => {
    await dispatch(getExcelData({ date_begin, date_end }))
  }

  const getCalendarBookings = async ({ date_begin, date_end, reservation_status_id, place_id, find_value }) => {
    await dispatch(getBookingsCalendar({ date_begin, date_end, reservation_status_id, place_id, find_value }))
  }

  const getStatusByCalendar = async () => {
    await dispatch(getBookingsCalendarStatus())
  }

  const changePageHandler = async (currentPag, limit) => {
    const pageValue = currentPag || currentPage
    const newSkip = (pageValue * limit) - limit
    setCurrentPage(pageValue)
    dispatch(await getAllReservations({ ...filters, reservationCode: currentActiveTab, limit: limit, skip: newSkip }))
  }

  return {
    loading,
    currentPage,
    onSaveBooking,
    changePageHandler,
    getDetailBooking,
    getAllDataBookings,
    getProfilesBySearch,
    getBookingsExcelData,
    getReservesByFilters,
    getAmenitiesBySearch,
    getStatusByCalendar,
    getCalendarBookings
  }
}

export default useBookings