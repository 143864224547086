import { useEffect } from "react"
import { CustomSpin } from "../../UI/CustomSpin"
import { useSalesStatement } from "./hooks/useSalesStatement"
import Header from "./components/Header"
import CustomTable from "../../UI/CustomTable/CustomTable"
import moment from "moment"
import 'moment/locale/es'

import './styles.css'
moment.locale('es')

export const SalesStatementPage = () => {
  const {
    loading,
    placesTreeData,
    salesStatementTable,
    columns,
    salesStatementFilters,
    pdfData,
    isTopData,
    paginationTable,
    handleTopTenData,
    getInitialData,
    handleChangeTable,
    handleChangeFilter,
  } = useSalesStatement()

  useEffect(() => {
    getInitialData()
  }, [])

  return (
    <section className="section-wrapper">
      <CustomSpin spinning={loading}>
        <Header placesTreeData={placesTreeData} salesStatementFilters={salesStatementFilters} pdfData={pdfData} isTopData={isTopData} handleChangeFilter={handleChangeFilter} handleTopTenData={handleTopTenData} />
        
        <main>
          <CustomTable
            loading={loading}
            className="custom-table-variant-1"
            columns={columns}
            dataSource={salesStatementTable.dataTable}
            onChange={handleChangeTable}
            pagination={paginationTable}
          />
        </main>
      </CustomSpin>
    </section>
   )}

export default SalesStatementPage