import axios from "axios";
const approveInventory = async (URL_DEV, idTicket, employeeId) => {
  try {
    const approved = {
      ticket_id: idTicket,
      employee_id: employeeId,
    };
    const response = await axios.put(`${URL_DEV}/inspection/approve`, approved);

    return {
      success: true,
      ...response,
    };
  } catch (errorResponse) {
    return {
      success: false,
      ...errorResponse.response,
    };
  }
};
export default approveInventory;
