export const BOOKINGS_CODES = {
    BOOKING_MODULE: 'FEAT-383',
    DETAIL_BOOKING: 'FEAT-384',
    APPROVE_BOOKING: 'FEAT-385',
    REJECT_BOOKING: 'FEAT-386'
}

export const BOOKINGS_CODES_NAMES = {
    BOOKING_MODULE: 'BOOKING_MODULE',
    DETAIL_BOOKING: 'DETAIL_BOOKING',
    APPROVE_BOOKING: 'APPROVE_BOOKING',
    REJECT_BOOKING: 'REJECT_BOOKING'
}