import { useContext, useState } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { CustomInput } from '../../../../../UI/CustomInput'
import { CustomModal } from './../../../../../UI/CustomModal'
import { AmenitiesContext } from '../../../context/AmenitiesContext'

const NewEditRestrictionsModal = ({ form, onReset, isOpenModal, handleOpenCloseModal }) => {
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  
  const { Item } = Form
  const config = { rules: [{ required: true, message: "Por favor llenar el campo" }] }

  const { currentRestrictionClicked, cleanCurrentRestrictionClicked, setRestrictions } = useContext(AmenitiesContext)

  const onSubmit = async (formData) => {
    setLoading(true)

    // Validacion para verificar si es una edicion o una restriccion nueva
    !(Object.keys(currentRestrictionClicked).length)
      ? await setRestrictions(state => {
          setCount(state => state + 1)
          return [...state, { title: formData?.title?.trim(), description: formData?.description?.trim(), id: count }]
        })
      : await setRestrictions(state => {
          const newArray = state.map((restriction) => {
            if (restriction.id === currentRestrictionClicked.id) return ({ ...formData, id: currentRestrictionClicked.id })
            
            return restriction
          })
          return newArray
        })

    // Se limpian todos los estados y el formulario 
    cleanCurrentRestrictionClicked()
    setLoading(false)

    handleOpenCloseModal()
    onReset()
  }

  return (
    <CustomModal
      destroyOnClose
      isOpenModal={isOpenModal}
      onCancel={handleOpenCloseModal}
    >
      <Form
        form={form}
        disabled={loading}
        layout="vertical"
        name="newEditRestrictionForm"
        onFinish={onSubmit}
      >
        <Row justify='center'>
          <Col span={24} style={{ paddingInline: 25 }}>
            <h2 style={{ fontWeight: 600, fontSize: 22, color: '#232C4A', textAlign: 'center', padding: '0 0 15px 0' }}>
              AGREGAR RESTRICCIONES
            </h2>

            <label style={{ fontWeight: 400, fontSize: 12, color: '#404040' }}>
              TITULO
            </label>

            <Item name="title" {...config}>
              <CustomInput className='custom-input-amenity' />
            </Item>

            <label style={{ fontWeight: 400, fontSize: 12, color: '#404040' }}>
              DESCRIPCIÓN
            </label>

            <Item name="description" {...config}>
              <CustomInput allowClear autoSize={{ minRows: 4, maxRows: 4 }} type='AREA' className='custom-textarea-amenity' />
            </Item>

            <Row justify='center' style={{ padding: '15px 0' }}>
              <Button
                type="text"
                shape="round"
                htmlType="submit"
                loading={loading}
                className="btn-standard-green"
              >
                <span>AGREGAR</span>
              </Button>
            </Row>

          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default NewEditRestrictionsModal