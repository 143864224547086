import moment from "moment"
import { useContext } from "react"
import { Button, Col, Radio, Row } from "antd"
import { AmenitiesContext } from "../../../../context/AmenitiesContext"
import CustomTimePicker from "../../../../../../UI/CustomTimePicker/CustomTimePicker"
import arrowIcon from '../../../../../../../assets/icons/Generals/arrow-large-icon.svg'

const ScheduleAmenityData = () => {
  const { schedulesAmenity, setSchedulesAmenity } = useContext(AmenitiesContext)

  const changeDataScheduleArray = (changedData) => {
    const newArray = schedulesAmenity.map((scheduleAmenity) => {
      if (scheduleAmenity.day === changedData.day) return ({ ...changedData })

      return scheduleAmenity
    })

    setSchedulesAmenity(newArray)
  }
  
  const optionsRadio = [{ label: "Seleccionar todos", value: "1" },
  { label: "Horario universal", value: "2" }]

  // Funcion encargada de handelear los radio button
  const handleOnChange = (e) => {
    let allSchedules = []
    if (e.target.value === '1') {
      allSchedules = schedulesAmenity.map((schedule) => ({ ...schedule, enabled: true }))

      setSchedulesAmenity(allSchedules)

      return
    }

    const { timeBegin, timeEnd } = schedulesAmenity[0]

    allSchedules = schedulesAmenity.map((schedule) => ({ ...schedule, timeBegin: timeBegin, timeEnd: timeEnd }))

    setSchedulesAmenity(allSchedules)
  }

  return <section style={{ backgroundColor: '#FFFFFF', borderRadius: 16, padding: '50px 25px', marginTop: 25 }}>
    <Row>
      <Col span={24}>
        <h2 style={{ fontWeight: 600, fontSize: 20 }}>
          HORARIOS
        </h2>

        <label style={{ fontWeight: 600, fontSize: 16, color: '#404040' }}>
          Días en los que estará disponible la amenidad
        </label>

        <Radio.Group onChange={handleOnChange} className="custom-radio-amenity" options={optionsRadio ?? []} style={{ flexDirection: 'row' }} />

        {schedulesAmenity.map((schedule) => (
          <div key={schedule.day}>
            <Row>
              <Col offset={18} span={6}>
                <Row>
                  <Col span={12}>
                    <label style={{ color: '#626262', fontSize: 10, fontWeight: 400, paddingLeft: 8 }}>
                      Desde
                    </label>
                  </Col>

                  <Col span={12}>
                    <label style={{ color: '#626262', fontSize: 10, fontWeight: 400 }}>
                      Hasta
                    </label>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row align="middle" key={schedule.day} gutter={16}>
              <Col span={4}>
                <Row justify="center">
                  <Button
                    type="text"
                    htmlType="button"
                    style={{
                      width: '100%',
                      borderRadius: 8,
                      height: 35,
                      backgroundColor: schedule.enabled ? 'var(--primary)' : '#FFFFFF',
                      border: schedule.enabled ? '' : '1px solid var(--primary)',
                      color: schedule.enabled ? '#FFFFFF' : 'var(--primary)'
                    }}
                    onClick={() => changeDataScheduleArray({ ...schedule, enabled: !(schedule.enabled) })}
                  >
                    <span>{schedule.label}</span>
                  </Button>
                </Row>
              </Col>

              <Col span={14}>
                <img src={arrowIcon} alt="arrow-icon" style={{ width: '100%' }} />
              </Col>

              <Col span={6}>
                <Row>
                  <Col span={12}>
                    {/* On blur cambio de data */}
                    <CustomTimePicker
                      defaultValue={schedule.timeBegin ? moment(schedule?.timeBegin, "HH:mm:ss") : moment("06:00:00", "HH:mm:ss")}
                      value={moment(schedule.timeBegin, "HH:mm:ss")}
                      format="HH:mm:ss"
                      minuteStep={5}
                      onChange={(e, timeString) => changeDataScheduleArray({ ...schedule, timeBegin: timeString })}
                    />
                  </Col>

                  <Col span={12}>
                    <CustomTimePicker
                      defaultValue={schedule.timeEnd ? moment(schedule?.timeEnd, "HH:mm:ss") : moment("08:00:00", "HH:mm:ss")}
                      value={moment(schedule.timeEnd, "HH:mm:ss")}
                      format="HH:mm:ss"
                      minuteStep={5}
                      onChange={(e, timeString) => changeDataScheduleArray({ ...schedule, timeEnd: timeString })}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ))}
      </Col>
    </Row>
  </section>
}

export default ScheduleAmenityData