import "../../../style/login.css";
import { topiaTemplate } from '../../mocks/topiaTemplate';
import { CustomForm } from "../../UI/CustomForm";
import { InfoPanel } from "./components/InfoPanel";

export const TopiaTemplate = ({
  isAuthenticating,
  loading,
  config,
  onSubmit,
}) => {

  const infoImages = topiaTemplate().templateData.infoSectionData.infoImages;
  const loginForm = topiaTemplate().templateData.infoSectionData.loginForm;

  return (
    <div style={infoImages.background} className="wrapper-login">
      <div className="login-box">
        <InfoPanel />
        <CustomForm
          input={loginForm.input}
          button={loginForm.button}
          config={config}
          isAuthenticating={isAuthenticating}
          loading={loading}
          onSubmit={onSubmit}
        />
        <p className="copyright">
          Derechos Reservados Topia {new Date().getFullYear()}. IT Latinoamérica
          <br />
          <span>soporte@topiapp.com</span>
        </p>
      </div>
    </div>
  );
};

export default TopiaTemplate;
