import axios from "axios"

const getAllUsersProfileByCompanyId = async ({
  companyId,
  limit,
  skip,
  find_by,
  find_value,
  idToken,
}) => {
  try {
    const isLimit = limit ? `&limit=${limit}` : ''
    const isSkip = skip ? `&skip=${skip}` : ''
    const isFindBy = find_by ? `&find_by=${find_by}` : ''
    const isFindValue = find_value ? `&find_value=${find_value}` : ''

    const response = await axios.get(`https://domo.topiaapp.com/api/company/profile/user?company_id=${companyId}${isLimit}${isSkip}${isFindBy}${isFindValue}`,
    {
      headers: { Authorization: `Bearer ${idToken}` },
      timeout: 10000
    })

     // Se prepara y moldea la data para que el customComponent Select trabaje sin problema
     const { data } = response

     const newResponse = {
       ...response,
       data: {
         ...response.data,
         data: {
           dataSource: data.data.map((profileData) => ({ id: profileData.profileId, name: `${profileData.firstName} ${profileData.lastName}` })),
         }
       }
     }
 
     return {
       success: true,
       ...newResponse
     }
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        ...error.response,
      };
    }
    if (error.request) {
      return {
        success: false,
        status: error.code,
        data: {
          errors: [{ msg: error.message }],
        },
      };
    }
  }
}

export default getAllUsersProfileByCompanyId