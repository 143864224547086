//Esta función retornara falso si al menos uno de las propiedades en el objeto no coincide con el nuevo, de lo contrario siempre será verdadero
export const checkObjects = ({ actualParams, newParams, difKey }) => {
  for (const key in actualParams) {
    if (key !== difKey && actualParams[key] !== newParams[key]) {
      return false;
    }
  }
  return true;
};

//Esta funcion compara dos objetos (para los filtros de una tabla en useMainInfoAccount) y va a devolver aquellas propiedades que no hayan coincidido con el primer objeto
export const compareObjects = ({
  object1,
  object2,
  actualParams,
  newParams,
}) => {


  //Verificar si todas las propiedades del objeto 2 son nulas
  if (Object.values(object2).every((item) => !item)) return null;

  //Verifica si el objeto 1 no está definido o es nulo
  if (!object1) {
    for (const key in object2) {
      if (object2[key] !== null && key !== "state") {
        return { [key]: object2[key] };
      }
    }
  }
  if (!object1) return null;

  const property = Object.keys(object1);
  if (property.length) {
    for (const key in object2) {
      //Verifica si tiene las mismas propiedades
      if (key === "state") continue;
      if (object1.hasOwnProperty(key) && object2.hasOwnProperty(key)) {
        //Verifica si el valor de las propiedades en ambos objetos son iguales, si difieren es porque se ha modificado la misma propiedad
        if (object1[key] !== object2[key] && object2[key] !== null) {
          return { [key]: object2[key] };
        }
        //Si la propiedad es igual en ambos objetos se deberia verificar si otros parametros son los que han cambiado de tal manera que retorne el mismo objeto en esos casos
        else if (
          actualParams?.sort_by !== newParams?.sort_by ||
          actualParams?.sort_order !== newParams?.sort_order ||
          actualParams?.pageValue !== newParams?.pageValue
        ) {
          return { [key]: object1[key] };
        }
      } else {
        //Si la propiedad del objeto 1 no coincide con las del objeto 2 se va a retorna la propiedad del objeto 2 que no sea nulo
        if (object2[key] !== null) {
          return { [key]: object2[key] };
        }
      }
    }
  }
  //Si el objeto está vacio {}
  else {
    for (const key in object2) {
      if (object2[key] !== null && key !== "state") {
        return { [key]: object2[key] };
      }
    }
  }
  //Si el state cambió nada más
  return object1;
};
