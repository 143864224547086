import moment from "moment"
import { verifyResponse } from "../detailTicket"
import { getStatusTicket } from "../../topia/pages/Tickets/Services"
import { getAllAccessProviders } from "../../topia/pages/accessProviders/services"
import { getAllBookingStatusByCompanyId } from "../../topia/pages/bookings/services"
import { getAlerts, setAlertsActive, setAlertsArchive, setIsLoading } from "../alerts"
import { setAccessProviders, setAdminStatusTickets, setBookingStatus, setUsersStatusTickets } from "./homeSlice"

export const getAllAlertsThunk = (isLimit = false) => async (dispatch, getState) => {
  const { typeRol } = getState().auth

  dispatch(setIsLoading(true))
  
  // Si solo es un condominio o residencial se realiza la peticion de alertas
  if (typeRol !== 'mall') {
    dispatch(getAlerts({ isLimit: isLimit }))
    dispatch(setIsLoading(false))
    return
  }

  // Si no es un centro comercial se limpia la store de la redux
  dispatch(setAlertsActive([]))
  dispatch(setAlertsArchive([]))
  dispatch(setIsLoading(false))
}

export const getAllBookings = () => async (dispatch, getState) => {
  const { idToken, currentRol } = getState().auth

  const response = await getAllBookingStatusByCompanyId(currentRol, idToken)

  if (response.success) {
    dispatch(setBookingStatus(response.data.data))

    return response
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getAllUsersTickets = () => async (dispatch, getState) => {
  const { currentRol } = getState().auth

  const response = await getStatusTicket('tenant', currentRol)

  if (response.success) {
    dispatch(setUsersStatusTickets(response.data.data))

    return response
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getAllAdminTickets = () => async (dispatch, getState) => {
  const { currentRol } = getState().auth

  const response = await getStatusTicket('admin', currentRol)

  if (response.success) {
    dispatch(setAdminStatusTickets(response.data.data))

    return response
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getTodayAccessProviders = () => async (dispatch, getState) => {
  const { idToken, currentRol } = getState().auth
  
  const yesterdayDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
  const tomorrowDate = moment().add(1, 'day').format('YYYY-MM-DD')

  const response = await getAllAccessProviders({ token: idToken, company_id: currentRol, date_begin: yesterdayDate, date_end: tomorrowDate  })

  if (response.success) {
    dispatch(setAccessProviders(response.data.data))
    return response
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}