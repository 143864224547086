import { Button, Image, Row } from 'antd'
import { ReactComponent as DeleteDocumentIcon } from '../assets/deleteDocumentIcon.svg'
import { ReactComponent as DownloadIcon } from '../assets/downloadDocumentIcon.svg'
import { verifyTypeFile, verifyTypesFiles } from '../../../helpers/customFilesHelper'
import { LIBRARY_CODES } from '../../../../constants/modules/library/libraryCodes'
import CustomPopoverConfirm from '../../../UI/CustomPopoverConfirm'
import { columnsOrderedTable } from '../../../helpers/columnsTableHelper'

export const librayTableColumnsPrev = ({ handleDeleteDocument, handleDownloadDocument, loading, activeFeatures, columns = [] }) => {
    const defaultColumns = [
        {
            title: 'FECHA',
            dataIndex: 'startPublishAt',
            key: 'publish_date',
            width: '20%'
        },
        {
            title: 'NOMBRE',
            dataIndex: 'name',
            key: 'name',
            width: '50%',
            align: 'left',
            ellipsis: true,
            render: (text, record) => {
                //Identificar el tipo de documento para añadirle el icono respectivo
                const extension = verifyTypesFiles(record?.fileUrl)
                return <div className='name-files'>
                    {(extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'webp') ? <Image src={record?.fileUrl} /> : verifyTypeFile(extension)} {record?.name}
                </div>
            }
        },
        {
            title: 'VENCIMIENTO',
            dataIndex: 'endPublishAt',
            key: 'expire_date',
            align: 'center',
            width: '10%',
            render: (text, record) => {
                return (
                    <span>{record.endPublishAt ? record.endPublishAt : '---'}</span>
                )
            }
        },
        {
            title: 'OPCIONES',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            width: '20%',
            render: (text, record) => {
                return (
                    <Row style={{ gap: 16 }} align='middle' justify='center' >
                        <Button onClick={() => handleDownloadDocument({ record })} loading={loading} style={{ border: 'none', background: 'transparent' }} icon={<DownloadIcon className='btn-svg-icon' />} />
                        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === LIBRARY_CODES.LIBRARY_DELETE_DOCUMENT)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Eliminar el archivo'}`}>
                            <Button disabled={!activeFeatures?.find(feature => feature.feature_code === LIBRARY_CODES.LIBRARY_DELETE_DOCUMENT)?.enabled} loading={loading} onClick={() => handleDeleteDocument({ record })} style={{ border: 'none', background: 'transparent' }} icon={<DeleteDocumentIcon />} />
                        </CustomPopoverConfirm>
                    </Row>
                )
            }
        }
    ]
    return columns.length ? columnsOrderedTable({ defaultColumns, backendColumns: columns, titleUpperCase: true }) : defaultColumns
}