import { createContext } from "react";
import useOpenCloseModal from './../topia/hooks/useOpenCloseModal';

export const CountDownContext = createContext();

export const CountDownProvider = ({ children }) => {
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  const handleIsOpenCloseModal = handleOpenCloseModal
  const isModalOpen = isOpenModal

  const value = {
    isModalOpen,
    handleIsOpenCloseModal,
  };

  return (
    <CountDownContext.Provider value={value}>
      {children}
    </CountDownContext.Provider>
  );
};
