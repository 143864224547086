import { useState } from "react"
import { Button, Col, Row } from "antd"
import useTypesNews from "../../hooks/useTypesNews"
import CustomModal from "../../../../UI/CustomModal"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store"

//Imagenes o archivos
import Warning from '../../../../../assets/icons/waring.svg'
import { setCurrentTypeNewClicked } from "../../../../../store/typesNews/typesNewsSlice"

const DeleteTypeNewModal = ({ isOpenModal, onCancel }) => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)

  const { onDeleteTypeNewById } = useTypesNews()
  const { currentTypeNewClicked } = useAppSelector(state => state.news)

  const onFinish = (isSuccessfull) => {
    if (isSuccessfull) {
      dispatch(setCurrentTypeNewClicked({}))
      onCancel()
    }
  }

  const onDeleteTypeNew = async () => {
    setLoading(true)

    const objToSend = {
      news_id: currentTypeNewClicked?.news_id
    }
    
    const response = await onDeleteTypeNewById(objToSend)

    onFinish(response)

    setLoading(false)
  }

  return <CustomModal
    destroyOnClose
    isOpenModal={isOpenModal}
    onCancel={() => {
      dispatch(setCurrentTypeNewClicked({}))
      onCancel()
    }}
  >
    <Row>
      <Col span={24}>
        <Row justify="center">
          <img src={Warning} alt="x-icon" />
        </Row>

        <Row justify="center">
          <label style={{ textAlign: 'center', width: 416, margin: '25px 0', color: '#373737', fontWeight: 500, fontSize: 25 }}>
            ¿Seguro que deseas eliminar esta noticia?
          </label>
        </Row>


        <Row justify="center" align="middle" gutter={16}>
          <Col span={8}>
            <Row justify="center">
              <Button
                type="text"
                shape="round"
                htmlType="button"
                onClick={() => {
                    dispatch(setCurrentTypeNewClicked({}))
                    onCancel()
                }}
                disabled={loading}
                loading={loading}
                style={{ border: '1px solid #8D8D8D', padding: '4px 40px 4px 40px' }}
              >
                <span style={{ color: '#8D8D8D' }}>CANCELAR</span>
              </Button>
            </Row>
          </Col>

          <Col span={8}>
            <Row justify="center">
              <Button
                type="text"
                shape="round"
                htmlType="button"
                disabled={loading}
                loading={loading}
                onClick={onDeleteTypeNew}
                style={{ padding: '4px 40px 4px 40px', backgroundColor: 'var(--primary)' }}
              >
                <span style={{ color: '#FFFFFF' }}>ELIMINAR</span>
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  </CustomModal>
}

export default DeleteTypeNewModal