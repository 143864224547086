import { useState } from "react"
import { Col, Row, Input } from "antd"
import CustomTable from './../../../../UI/CustomTable/CustomTable'
import { CustomDatePicker } from "../../../../UI/CustomDatePicker"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store"
import { getAllTicketsByPlace } from "../../../../../store/detailContracts/thunks"
const { Search } = Input

const TicketsHistoryBody = () => {
  const dispatch = useAppDispatch()
  const [loadingTable, setLoadingTable] = useState(false)

  const { idClickedPlace } = useAppSelector(state => state.malls)
  const { ticketsHistory } = useAppSelector(state => state.detailContracts)

  const { dataSource, columns } = (ticketsHistory?.data) ?? []
  const { skip, limit, quantity, rest } = (ticketsHistory?.metadata) ?? []

  const handleOnSearch = async (value) => {
    setLoadingTable(true)
    //Pasar idClickedPlace
    await dispatch(await getAllTicketsByPlace({ placeId: idClickedPlace, findValue: value }))

    setLoadingTable(false)
  }

  const handleOnChange = async (dates) => {
    setLoadingTable(true)

    let startDate = ''
    let endDate = ''

    if (dates) {
      startDate = dates[0].format("YYYY-MM-DD")
      endDate = dates[1].format("YYYY-MM-DD")
    }
    
    //Pasar idClickedPlace

    await dispatch( await getAllTicketsByPlace({ placeId: idClickedPlace, dateBegin: startDate, dateEnd: endDate }))

    setLoadingTable(false)
  }

  return (
    <section style={{
      height: 714,
      width: "100%",
      padding: 40,
      backgroundColor: "#FFFFFF",
      borderRadius: 8,
      boxShadow: "0px 6px 7px 0px #9AB9D440",
    }}>
      <Row>
        <Col span={24}>
          <Row justify="space-between">
            <h2 style={{ fontSize: 22, fontWeight: 400 }}>Historial de Tickets</h2>

            <p style={{ color: "#8F9997", fontWeight: 600, fontSize: 18 }}>
              NÚMERO DE TICKETS:
              {' '}
              <span style={{ fontSize: 24, fontWeight: 700, color: "var(--primary)" }}>
                {(dataSource?.length < 10 ? `0${dataSource?.length}` : dataSource?.length) ?? '00'}
              </span>
            </p>
          </Row>

          <Row justify="end" gutter={24}>
            <Col span={8}>
              <Search placeholder="Buscar Ticket" onSearch={handleOnSearch} />
            </Col>

            <Col span={8}>
              <CustomDatePicker allowClear type="RANGER" onChange={handleOnChange} />
            </Col>
          </Row>
          
          <CustomTable
            columns={columns ?? []}
            dataSource={dataSource ?? []}
            loading={loadingTable}
            rowKey={record => record.id}
            pagination={{ 
              total: quantity,
              pageSize: limit,
              onChange: async (current) => {
                setLoadingTable(true)
                // await changePageHandler(current, limit)
                setLoadingTable(false)
              },
              // current: currentPage,
             }}
          />
        
        </Col>
      </Row>
    </section>
  )
}

export default TicketsHistoryBody