import { axiosCreate } from "../../../../config/index";
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse";

const getAllCategoryCheckList = async ({ idToken, checklist_id, company_id, skip = 0, limit = 10, isToSelect, find_value }) => {
  try {
    const params = {
      checklist_id,
      company_id,
      skip,
      limit,
      find_value
    }

    const response = await axiosCreate.get('checklist/category/active', {
      headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000, params
    })

    if (isToSelect) {
      const { data } = response

     const newResponse = {
       ...response,
       data: {
         ...response.data,
         data: {
           dataSource: data.data.dataSource.map((categoryData) => ({ id: categoryData.id, name: categoryData.category })),
         }
       }
     }
 
     return {
       success: true,
       ...newResponse
     }
    }

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
};

export default getAllCategoryCheckList
