import { Bar } from "react-chartjs-2"
import { Chart, Tooltip, CategoryScale, LinearScale, BarElement, Title, ArcElement } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { barColors } from "../../../../../mocks/barColors"
import { useAppSelector } from "../../../../../../../../hooks/store"

Chart.register(ChartDataLabels, Tooltip, CategoryScale, LinearScale, BarElement, Title, ArcElement)

export const CustomBarChart = ({ data, labels, question, onBarClick, questionIndex, type, generalOptions }) => {

    const theme = useAppSelector(state => state.theme)
    const qualifyBarColor = theme?.themeColors?.primary?.primary ?? '#10B48C'
    const starLabels = labels.map((label) => `${parseInt(label).toFixed(0)} ESTRELLA${label === '1.0' ? '' : 'S'}`)
    const chartData = {
        labels: type === 'qualify' ? starLabels : labels,
        datasets: [{
            label: question,
            data,
            backgroundColor: type === 'qualify' ? qualifyBarColor : barColors
        }]
    }

    const maxValue = Math.max(...data)
    const suggestedMax = Math.ceil(maxValue / 10) * 3

    const options = {
        indexAxis: type === 'qualify' ? 'y' : 'x',
        onClick: (event, element) => {
            if (element.length) {
                const index = element[0].index
                onBarClick(questionIndex, index)
            }
        },
        scales: {
            x: {
                grid: {
                    display: false,

                },
                suggestedMax,
                ticks: {
                    stepSize: 1,
                }
            },
            y: {
                grid: {
                    display: false,

                },
                suggestedMax,
                ticks: {
                    stepSize: 1,
                }
            }
        }
    }

    return <Bar style={{ maxWidth: 700, maxHeight: 350 }} data={chartData} options={{
        ...generalOptions({ type }), ...options
    }} />
}

export default CustomBarChart