import { CheckingAuth } from "../../components/CheckingAuth";
import { useLogin } from "./hooks/useLogin"
import { CustomTemplate } from "./UI/CustomTemplate"

export const LoginPage = () => {
  const { template, status } = useLogin()

  if (status === 'checking-login') return <CheckingAuth customMessage={'Verificando sesión'} />

  return (
    <CustomTemplate>
      {template}
    </CustomTemplate>
  );
};