import { Button, Row } from "antd"
import { CustomNavigate } from "../../../../../UI/CustomNavigate"
import { CustomInput } from "../../../../../UI/CustomInput"
import { CustomSelect } from "../../../../../UI/CustomSelect"
import { CaretDownOutlined } from "@ant-design/icons"
import { ReactComponent as PlusCheckCircleIcon } from '../../../../../../assets/icons/plusCheckCircleIcon.svg'
import searchIcon from '../../../../../../assets/icons/search.svg'

//Se muestran las opcioens para filtrar la tabla de roles, ya sea por nombre de rol o por cuenta, además para abrir el modal de un nuevo rol
const UserRolesHeader = ({ rolesItem, handleOpenCloseModal, handleChangeSearchInput, handleChangeCompany }) => (
    <header>
        <CustomNavigate title={'ROLES DE USUARIO'} >
            <Row className="user-rol-navigate" style={{ gap: 8 }} align="middle">
                <CustomInput allowClear onChange={(value) => handleChangeSearchInput({ type: 'input', value })} placeholder={'Buscar rol'} maxWidth={261} suffixIcon={searchIcon} />
                <CustomSelect onChange={(value) => handleChangeCompany({ type: 'select', value })} allowClear={true} options={rolesItem} svgIcon={true} suffixIcon={<CaretDownOutlined />} placeholder={'Filtrar por compañia'} width="261px" />
                <Button icon={<PlusCheckCircleIcon />} onClick={handleOpenCloseModal} className="btn-standard-green btn-right-icon" shape="round">NUEVO ROL</Button>
            </Row>
        </CustomNavigate>
    </header>
)

export default UserRolesHeader
