import { useState } from "react"
import { useAppSelector } from "../../../../../hooks/store"
import verifyResponse from "../../../../../helpers/verifyResponse"
import { createChecklistInstance, getChecklistsByChecklistId, updateChecklistInstance } from "../services"
import useCurrentLocation from "../../../../hooks/useCurrentLocation"

const useChecklist = () => {
  const [loading, setLoading] = useState(false)
  const [checklists, setChecklists] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [checklistsPaginationData, setChecklistPaginationData] = useState({})
  
  const { param } = useCurrentLocation()
  const { idToken, currentRol } = useAppSelector((state) => state.auth)

  const getData = async () => {
    setLoading(true)

    await getCheckListsByIdChecklist(param.checklistId, currentRol, 10, 0)

    setLoading(false)
  }

  const getCheckListsByIdChecklist = async (checklistId, companyId, limit, skip, statusId) => {
    const response = await getChecklistsByChecklistId(checklistId, companyId, limit, skip, statusId, idToken)

    if (response.success) {
      setChecklists(response.data.data)
      setChecklistPaginationData(response.data.metadata)
      return
    }

    return verifyResponse(response.data, response.status, '')
  }

  const onSaveChecklist = async (formData) => {
    const functionToExecute = formData.checklist_instance_id ? updateChecklistInstance : createChecklistInstance
    const messageToShow = formData.checklist_instance_id ? 'Checklist editado correctamente' : 'Checklist creado correctamente'

    const response = await functionToExecute(formData, idToken)

    return verifyResponse(response.data, response.status, messageToShow)
  }

  const changePageHandler = async (currentPag, limit) => {
    const pageValue = currentPag || currentPage
    const newSkip = (pageValue * limit) - limit
    setCurrentPage(pageValue)
    await getCheckListsByIdChecklist(param.checklistId, currentRol, limit, newSkip)
  }

  return {
    getData,
    loading,
    checklists,
    checklistsPaginationData,
    onSaveChecklist,
    changePageHandler,
    getCheckListsByIdChecklist,
  }
}

export default useChecklist