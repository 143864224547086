import { useState, createContext } from "react"

export const CreateTicketByCheckListContext = createContext()

export const CreateTicketByCheckListContextProvider = ({ children }) => {
  const [assignedTo, setAssignedTo] = useState({})
  const [averageTime, setAverageTime] = useState(0)
  const [sessionSelected, setSessionSelected] = useState([])
  const [totalCostPreview, setTotalCostPreview] = useState(0)
  const [suppliesSelected, setSuppliesSelected] = useState([])
  const [equipmentSelected, setEquipmentSelected] = useState([])

  const value = {
    averageTime,
    setAverageTime,
    totalCostPreview,
    setTotalCostPreview,
    equipmentSelected,
    setEquipmentSelected,
    suppliesSelected,
    setSuppliesSelected,
    sessionSelected,
    setSessionSelected,
    assignedTo,
    setAssignedTo,
  }

  return <CreateTicketByCheckListContext.Provider value={value}>
    {children}
  </CreateTicketByCheckListContext.Provider>
}