export const newCheckListType = {
  category_id: 1,
  title: "Revisar Cisterna",
  description: "Revisar Cisterna",
  allow_attach_files: true,
  schedule: [
    {
      task_employee_id:null,
      employee_id: 1,
      deleted_days: [],
      days: [-1],
      time_begin: "06:30",
      time_end: "06:50",
      date_end: null,
      occurrences: null,
    },
  ],
};

export const newCheckListTypeLocal = {
  category_id: 1,
  title: "Revisar Cisterna",
  description: "Revisar Cisterna",
  allow_attach_files: true,
  schedule: [
    {
      employee_id: 1,
      task_employee_id:32,
      days: [-1],
      time_begin: "08:30",
      time_end: "10:50",
      date_end: null,
      occurrences: null,
    },
    {
      employee_id: 2,
      task_employee_id:34,
      days: [0, 5, 6],
      time_begin: "15:30",
      time_end: "18:50",
      date_end: null,
      occurrences: 15,
    },
    {
      employee_id: 3,
      task_employee_id:38,
      days: [0, 2, 4],
      time_begin: "11:30",
      time_end: "19:30",
      date_end: "2023-12-30",
      occurrences: null,
    },
  ],
};
