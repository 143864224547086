const Eye2Icon = ({ color = 'var(--primary)', width = '20', height = '20' }) => (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.99992 4.16797C5.46326 4.16797 2.94242 7.6963 2.04659 9.26464C1.86242 9.5863 1.77076 9.74797 1.77992 9.98797C1.79076 10.228 1.89909 10.3846 2.11492 10.698C3.18159 12.2463 6.07826 15.8346 9.99992 15.8346C13.9216 15.8346 16.8183 12.2463 17.8849 10.698C18.1016 10.3846 18.2099 10.228 18.2191 9.98797C18.2291 9.74797 18.1374 9.5863 17.9533 9.26464C17.0583 7.6963 14.5366 4.16797 9.99992 4.16797Z"
            stroke={color} />
        <path
            d="M9.99935 13.3346C11.8403 13.3346 13.3327 11.8423 13.3327 10.0013C13.3327 8.16035 11.8403 6.66797 9.99935 6.66797C8.1584 6.66797 6.66602 8.16035 6.66602 10.0013C6.66602 11.8423 8.1584 13.3346 9.99935 13.3346Z"
            fill={color} />
    </svg>
)

export default Eye2Icon
