import axios from "axios"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const getDetailTypeNewById = async (newId, idToken) => {
  try {
    const response = await axios.get(`https://domo.topiaapp.com/api/news/detail/light?news_id=${newId}`,{
      headers: { Authorization: `Bearer ${idToken}` },
      timeout: 10000,
    })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getDetailTypeNewById