import { axiosCreate } from '../../../../../config/index'
import { verifyBodyResponse } from '../../../../helpers/verifyBodyResponse'

const getCompanyAssetStatus = async () => {
  try {
    const response = await axiosCreate.get('company/asset/status')

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getCompanyAssetStatus