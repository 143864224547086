import { useState } from "react"
import { Button, Col, Image, Row } from "antd"
import CustomModal from "../../../../../UI/CustomModal"
import { useActivesMaintenance } from "../../../hooks/useActivesMaintenance/useActivesMaintenance"

//imagenes o archivos
import warningIcon from '../../.../../../../../../assets/icons/waring.svg'

const DeleteActiveModal = () => {
  const [loading, setLoading] = useState(false)

  const {
    onDeleteCompanyAsset, getAllCompanyAssetsService, currentActiveTab, limitTableActiveMaintenance, isOpenModal,
    handleOpenCloseModal, clickedActive, setClickedActive, setColumnsTableActiveMaintenance, setDataTableActiveMaintenance,
    setMetaDataTableActiveMaintenance
  } = useActivesMaintenance()

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      const { data: activeMaintenanceData, metadata: activeMaintenanceMetaData } = await getAllCompanyAssetsService({ company_asset_status_id: currentActiveTab, skip: 0, limit: limitTableActiveMaintenance })
      
      setColumnsTableActiveMaintenance(activeMaintenanceData.columns);
      setDataTableActiveMaintenance(activeMaintenanceData?.dataSource)
      setMetaDataTableActiveMaintenance(activeMaintenanceMetaData)
      
      handleOpenCloseModal()
      setClickedActive({})
    }
  }
  
  const onDeleteActive = async () => {
    setLoading(true)

    const response = await onDeleteCompanyAsset({ company_asset_id: clickedActive?.company_asset_id })

    await onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal
      destroyOnClose
      width={491}
      isOpenModal={isOpenModal}
      onCancel={() => {
        setClickedActive({})
        handleOpenCloseModal()
    }}
    >
      <Row>
        <Col span={24}>
          <Row justify="center">
            <Image src={warningIcon} alt="x-icon" width={59} height={59} />
          </Row>

        <Row justify="center">
          <label style={{ textAlign: 'center', width: 416, margin: '25px 0', color: '#373737', fontWeight: 500, fontSize: 20 }}>
            ¿Deseas archivar este activo?
          </label>
        </Row>

        <Row justify="center" align="middle" gutter={16}>
          <Col span={8}>
            <Row justify="center">
              <Button
                type="text"
                shape="round"
                htmlType="button"
                onClick={() => {
                  handleOpenCloseModal()
                  setClickedActive({})
                }}
                disabled={loading}
                loading={loading}
                style={{ padding: '0px 32px 0px 32px' }}
              >
                <span style={{ color: '#404040', fontSize: 14, fontWeight: 500 }}>CANCELAR</span>
              </Button>
            </Row>
          </Col>

          <Col span={8}>
            <Row justify="center">
              <Button
                type="text"
                shape="round"
                htmlType="button"
                disabled={loading}
                loading={loading}
                onClick={onDeleteActive}
                style={{ padding: '0px 32px 0px 32px', backgroundColor: '#FF5858' }}
              >
                <span style={{ color: '#FFFFFF', fontWeight: 500, fontSize: 14 }}>ARCHIVAR</span>
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
    </CustomModal>
  )
}

export default DeleteActiveModal