import { useAppDispatch } from "../../../../hooks/store"
import { deleteCompanyNew, getAllDataTypesIdNews, getAllTypesNews, getDetailTypeByIdNew, getFullDetailTypeByIdNew, onSaveTypeNew } from "../../../../store/typesNews/thunks"

const useTypesNews = () => {
  const dispatch = useAppDispatch()

  // Funcion para peticion inicial
  const getAllDataTypesNews = async () => {
    dispatch(getAllTypesNews({ published: true, dateBegin: '', dateEnd: '', active: '' }))

    dispatch(getAllDataTypesIdNews())
  }

  // Funcion para obtener los tipos de noticias by filters
  const getAllTypesNewsByAllFilters = async ({
    dateBegin,
    dateEnd,
    published,
    active,
    newsTypeId,
    skip,
    limit,
  }) => {
    dispatch(getAllTypesNews({
      dateBegin,
      dateEnd,
      published,
      active,
      newsTypeId,
      skip,
      limit
    }))
  }

  // Funcion para obtener el detalle de un tipo de noticia de la drawer
  const getDetailByTypeIdNew = async (idNew) => {
    const response = await dispatch(getDetailTypeByIdNew(idNew))

    return response
  }

  // Funcion para obtener el detalle de un tipo de noticia y editarla
  const getFullDetailByTypeIdNew = async (idNew) => {
    const response = await dispatch(getFullDetailTypeByIdNew(idNew))

    return response
  }

  // Funcion para eliminar una noticia
  const onDeleteTypeNewById = async (formData) => {
    const response = await dispatch(deleteCompanyNew(formData))

    return response
  }

  // Funcion para crear o editar un tipo de noticia
  const onSaveTypesNews = async (formData) => {
    const response = await dispatch(onSaveTypeNew(formData))

    return response
  }

  return {
    onSaveTypesNews,
    onDeleteTypeNewById,
    getAllDataTypesNews,
    getDetailByTypeIdNew,
    getFullDetailByTypeIdNew,
    getAllTypesNewsByAllFilters,
  }
}

export default useTypesNews