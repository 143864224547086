import { Col } from "antd"
import { CustomProgress } from "../../CustomProgress"
import { useActivityMonitoring } from "../../../../../hooks/useActivityMonotoring"

export const ActivityItem = ({ category_id, name, total_tasks, completed, filter_by }) => {
    const { handleRedirectTo } = useActivityMonitoring();
    return (
        <Col onClick={() => handleRedirectTo(`/checklist/monitoring/details/${JSON.stringify({ category_id,filter_by })}`)} className="monitoring__activity-item">
            <h2>{name}</h2>
            <p>Cumplimiento</p>
            <CustomProgress percent={(completed / total_tasks) * 100} width={'100%'} type="line" fontSize={14} height={30} strokeColor="var(--primary)" textColor="var(--primary)" trailColor="var(--primaryminus3)" strokeLinecap="round" />
        </Col>
    )
}