import { useEffect, useState } from "react"
import { useAppSelector } from "../../../../../hooks/store"
import verifyResponse from "../../../../../helpers/verifyResponse"
import { getStatusChecklist, getHierarchyByCompanyId, getAllHierarchiesByCompanyId } from "../services"

const useGenerals = () => {
  const [hierarchyData, setHierarchyData] = useState([])
  const [allHierarchyData, setAllHierarchyData] = useState([])
  const [statusChecklist, setStatusChecklist] = useState([])
  const [loadingHierarchy, setLoadingHierarchy] = useState(false)

  const { idToken, currentRol } = useAppSelector((state) => state.auth)

  useEffect(() => {
    if (idToken) {
      getCategoriesChecklist()
    }
  }, [])

  const getCategoriesChecklist = async () => {
    const response = await getStatusChecklist(idToken)

    if (response.success) return setStatusChecklist(response.data.data)

    return verifyResponse(response.data, response.status, '')
  }

  const getAllHierarchyData = async (parentId) => {
    const response = await getAllHierarchiesByCompanyId(currentRol, idToken, parentId)

    if (response.success) {
      setAllHierarchyData(response.data.data)
      return
    }

    return verifyResponse(response.data, response.status, '')
  }

  const getHierarchyDataByCompanyId = async () => {
    setLoadingHierarchy(true)
    const response = await getHierarchyByCompanyId(currentRol, idToken)

    if (response.success) {
      setHierarchyData(response.data.data)
      setLoadingHierarchy(false)
      return
    }

    setLoadingHierarchy(false)
    return verifyResponse(response.data, response.status, '')
  }

  return {
    hierarchyData,
    allHierarchyData,
    statusChecklist,
    loadingHierarchy,
    getAllHierarchyData,
    getCategoriesChecklist,
    getHierarchyDataByCompanyId,
  }
}

export default useGenerals