//RESERVAS TUSCANIA
export const bookingTuscaniaModule = {
    feature_code: 'FEAT-358',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-361',
            enabled: false,
            children: [
                {
                    feature_code: 'FEAT-362',
                    enabled: false,
                },
                {
                    feature_code: 'FEAT-363',
                    enabled: false,
                },
                {
                    feature_code: 'FEAT-364',
                    enabled: false,
                },
            ]
        },
    ]
}