import axios from "axios"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse";
import { API_URL } from "../../../../config";

const getUserTypes = async (idToken) => {
  try {
    const url = `${API_URL}profile/users/types`;
   
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${idToken}` ,
      timeout: 20000, }
    });

  // Se prepara y moldea la data para que el customComponent Select trabaje sin problema
  const { data } = response
   const newResponse = {
    ...response,
    data: {
      ...response.data,
      data: {
        dataSource: data?.data?.map((type) => ({ id: type.id, name: type.name })),
      }
    }
  }

  return newResponse
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getUserTypes
