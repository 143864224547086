import axios from "axios"

const getAllAmenitiesByCompanyId = async ({
    companyId,
    idToken,
    skip,
    limit,
  }) => {
    try {
      const isLimit = limit ? `&limit=${limit}` : ''
      const isSkip = skip ? `&skip=${skip}` : ''

      const response = await axios.get(`https://domo.topiaapp.com/api//place/amenity?company_id=${companyId}${isLimit}${isSkip}`, {
        headers: { Authorization: `Bearer ${idToken}` },
        timeout: 10000,
      })

      return {
        success: true,
        ...response
      }
  } catch (error) {
    if (error.response) {
        return {
          success: false,
          ...error.response,
        };
      }
      if (error.request) {
        return {
          success: false,
          status: error.code,
          data: {
            errors: [{ msg: error.message }],
          },
        };
      }
  }
}

export default getAllAmenitiesByCompanyId