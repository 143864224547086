export const formData = {
    active: true,
    id: "",
    name: "",
    descripcion: "",
    eraser: true,
    listPreguntas: [{}],
    listaPreguntasEliminadas: [],
    tipoEncuesta: "",
    idTipo: "",
}