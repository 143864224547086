
export const AMENITIES_CODES = {
    AMENITIES_MODULE: 'FEAT-387',
    AMENITIES_NEW_AMENITY: 'FEAT-388',
    AMENITIES_CHANGE_STATUS_AMENITY: 'FEAT-389',
    AMENITIES_UPDATE_AMENITY: 'FEAT-391',
    AMENITIES_DELETE_AMENITY: 'FEAT-390',
}

export const AMENITIES_CODES_NAMES = {
    AMENITIES_MODULE: 'AMENITIES_MODULE',
    AMENITIES_NEW_AMENITY: 'AMENITIES_NEW_AMENITY',
    AMENITIES_CHANGE_STATUS_AMENITY: 'AMENITIES_CHANGE_STATUS_AMENITY',
    AMENITIES_UPDATE_AMENITY: 'AMENITIES_UPDATE_AMENITY',
    AMENITIES_DELETE_AMENITY: 'AMENITIES_DELETE_AMENITY',
}