import { ArrowLeftOutlined } from "@ant-design/icons";
import { Row, Tooltip, Button } from "antd";
import useRedirectTo from "../hooks/useRedirectTo";

export const CustomNavigate = ({
  maxWidth,
  className, 
  colorTitle = "#232C4A", 
  fontSizeTitle = 24, 
  title,
  secondTitle,
  condition = false, 
  showReturnButton = true, 
  children,
  redirectTo,
  customRedirectTo = false,
  customCondition
}) => {
  const { handleRedirectTo } = useRedirectTo()

  return (
    <Row className={`navigation-row ${className ? className : ''}`} style={{ maxWidth }}>
      <Row>
        {
          showReturnButton &&
          <Tooltip title="Atrás">
            <Button
              style={{
                marginRight: "20px",
                backgroundColor: "var(--primary)",
                borderColor:"var(--primary)",
                borderRadius: "50px",
              }}
              icon={<ArrowLeftOutlined style={{ color: "white" }} size={32} />}
              onClick={() => !customRedirectTo ? handleRedirectTo(redirectTo ? redirectTo : -1) : customCondition(false)}
            />
          </Tooltip>
        }
        <h1 style={{display:'flex', flexDirection:'column', color: colorTitle , fontSize: fontSizeTitle   }}>
          {
            condition ? secondTitle : title
          }
        </h1>
      </Row>
      {
        children
      }
    </Row>
  );
};