import { Button, Col, Row } from 'antd'
import { ReactComponent as ExcelIcon } from '../../../assets/icons/excelIcon.svg'

export const SurveyHeader = ({ surveysDetail, downloadSurvey }) => {
    return (
        <Row className='encabezado' style={{ textAlign: "left", paddingLeft: "25px" }}>
            <Col span={15}>
                <small>NOMBRE DE ENCUESTA</small>
                <h2>
                    {surveysDetail.name}
                </h2>
                <Row>
                    <Col span={8}><strong>INICIO:</strong> <small>{surveysDetail.startDate}</small></Col>
                    <Col span={8}><strong>FINALIZACIÓN:</strong> <small>{surveysDetail.endDate}</small></Col>
                    <Col span={8}><strong>NÚMERO DE PREGUNTAS:</strong> <small>{surveysDetail.preguntas.length}</small></Col>
                </Row>
            </Col>
            <Col span={5} className="survey-answered" >
                <small>
                    ENCUESTAS
                </small>
                <small>
                    CONTESTADAS:
                </small>
                <span className="survey-answered__number">
                    {surveysDetail.encuestasContestadas}
                </span>
            </Col>
            <Col span={4} style={{ display: "flex", alignItems: 'center' }}>
                <Button icon={<ExcelIcon />} className="btn-download-survey" onClick={downloadSurvey}><div><span>EXPORTAR</span><span>ENCUESTA</span></div></Button>
            </Col>
        </Row>
    )
}

export default SurveyHeader