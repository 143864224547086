import HomePage from './../pages/home/HomePage'
import EmptyComponent from "../UI/EmptyComponent"
import BuildingSketchPage from '../pages/buildingSketchs/BuildingSketchPage'
import AdminCategoriesPage from "../pages/adminCategories/AdminCategoriesPage"
import { useAppSelector } from '../../hooks/store'

const DefaultPage = () => {
  const { typeRol } = useAppSelector(state => state.auth)

  const verifyDefaultPage = (type) => {
    const objDefaultPages = {
      "residential": <HomePage />,
      "mall": <BuildingSketchPage />,
      "service": <AdminCategoriesPage />,
      'default': <EmptyComponent label="No hay pagina creada" />
    }

    const defaultPage = objDefaultPages[type] || objDefaultPages['default']
    return defaultPage
  }

  return verifyDefaultPage(typeRol)
}

export default DefaultPage
