import moment from "moment"
import { Button, Col, Image, Row } from "antd"
import CustomDrawer from "../../../../../UI/CustomDrawer/CustomDrawer"
import { useActivesMaintenanceContext } from "../../../context/ActiveMaintenanceContext"
import { useActivesMaintenance } from "../../../hooks/useActivesMaintenance/useActivesMaintenance"

// imagenes o archivos
import { CloseOutlined } from "@ant-design/icons"
import EditIcon from "../../../../../../assets/icons/EditIcon"
import FileIcon from "../../../../../../assets/icons/FileIcon"
import DownloadIcon from "../../../../../../assets/icons/Generals/ContractsFlow/downloadDownArrow.svg"

const CompanyAssetDetailDrawer = () => {
  const { form } = useActivesMaintenanceContext()

  const {
    isOpenDrawer, handleOpenCloseDrawer, detailActiveDrawer, setDetailActiveDrawer, setClickedActive,
    setShowCreateActiveModal, setFilesList,
  } = useActivesMaintenance()

  const onClickEditActive = () => {
    const files = detailActiveDrawer?.files?.map((fileUrl) => ({
      uid: crypto.randomUUID(),
      name: fileUrl,
      status: 'done',
      url: fileUrl,
      type: ''
    }))

    const filesImg = detailActiveDrawer?.images?.map((imgeUrl, index) => ({
      uid: crypto.randomUUID(),
      name: `Imagen adjuntada anteriormente ${index}`,
      status: 'done',
      url: imgeUrl,
      type: 'image/',
    }))

    const allFilesArray = files.concat(filesImg)

    form.setFieldsValue({
      name: detailActiveDrawer?.name,
      description: detailActiveDrawer?.description,
      code: detailActiveDrawer?.code,
      cost: detailActiveDrawer?.cost,
      provider_id: ({ value: detailActiveDrawer?.provider_id, label: detailActiveDrawer?.provider_name }),
      next_maintenance_date: moment(detailActiveDrawer?.next_maintenance_date),
    })
    setFilesList(allFilesArray)
    setShowCreateActiveModal(true)
  }

  return (
    <CustomDrawer
      width={578}
      isOpenDrawer={isOpenDrawer}
      onClose={() => {
        setClickedActive({})
        setDetailActiveDrawer({})
        handleOpenCloseDrawer()
      }}
      closeIcon={<CloseOutlined />}
    >
      <Row>
        <Col span={24} style={{ padding: '10px 24px' }}>
          <Row justify="space-between" wrap={false}>
            <h3 style={{ fontWeight: 600, fontSize: 25, color: '#232C4A' }}>
              {detailActiveDrawer?.name}
            </h3>

            <Button
              type="link"
              htmlType="button"
              shape="circle"
              // disabled={}
              onClick={onClickEditActive}
              style={{ border: '1px solid var(--primary)' }}
            >
              <Row justify="center" align="middle">
                <Col>
                  <EditIcon color="var(--primary)" />
                </Col>
              </Row>
            </Button>
          </Row>
          
          <Row gutter={8}>
            <Col span={8}>
              <label style={{ color: '#232C4A', fontWeight: 500, fontSize: 13 }}>
                CÓDIGO
              </label>

              <p style={{ color: '#444444', fontSize: 14, fontWeight: 400 }}>
                {detailActiveDrawer?.code ? detailActiveDrawer.code : 'No posee un código agregado'}
              </p>
            </Col>

            <Col span={8}>
              <label style={{ color: '#232C4A', fontWeight: 500, fontSize: 13 }}>
                COSTO
              </label>

              <p style={{ color: '#444444', fontSize: 14, fontWeight: 400 }}>
                {detailActiveDrawer?.cost ? detailActiveDrawer.cost : 'No posee un costo agregado'}
              </p>
            </Col>
          </Row>

          <Row style={{ margin: '15px 0' }}>
            <Col span={24}>
              <label style={{ color: '#252525', fontWeight: 500, fontSize: 13 }}>
                DESCRIPCIÓN
              </label>
                
              <p style={{ color: '#444444', fontSize: 14, fontWeight: 400 }}>
                {detailActiveDrawer?.description ? detailActiveDrawer.description : 'No posee una descripción agregada'}
              </p>
            </Col>
          </Row>

          <Row style={{ margin: '15px 0' }}>
            <Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
              <label style={{ color: '#252525', fontWeight: 500, fontSize: 13 }}>
                ARCHIVOS ADJUNTOS
              </label>

              {detailActiveDrawer?.files?.length > 0
                ? <ul style={{ listStyle: 'none', padding: 0 }}>
                    {detailActiveDrawer.files.map((fileData, index) => (
                      <li key={fileData}>
                        <Row align="middle" justify="space-between" style={{ marginTop: 12 }}>
                          <Col>
                            <Row align="middle" gutter={8}>
                              <Col>
                                <FileIcon widthIcon={28} heightIcon={28} urlFile={fileData} isJustIcon />
                              </Col>

                              <Col>
                                <span style={{ fontWeight: 400, fontSize: 14 }}>
                                  Archivo {index + 1}
                                </span>
                              </Col>
                            </Row>
                          </Col>

                          <Col>
                            <Button
                              type="link"
                              htmlType="button"
                            >
                              <a
                                href={fileData}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <Image src={DownloadIcon} alt="download-icon" />
                              </a>
                            </Button>
                          </Col>
                        </Row>
                      </li>
                    ))}
                  </ul> 
                : <label>No posee archivos adjuntos</label>
              }
            </Col>
          </Row>

          <Row gutter={8} style={{ margin: '15px 0' }}>
            <Col>
              <label style={{ color: '#252525', fontWeight: 500, fontSize: 13 }}>
                EMPRESA DE MANTENIMIENTO
              </label>

              <p style={{ color: '#444444', fontSize: 14, fontWeight: 400 }}>
                {detailActiveDrawer?.provider_name}
              </p>
            </Col>
         
            <Col>
              <label style={{ color: '#252525', fontWeight: 500, fontSize: 13 }}>
                PRÓXIMO MANTENIMIENTO
              </label>

              <p style={{ color: '#444444', fontSize: 14, fontWeight: 400 }}>
                {detailActiveDrawer?.next_maintenance_date}
              </p>
            </Col>
          </Row>

          <Row style={{ margin: '15px 0' }}>
            <Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
              <label style={{ color: '#252525', fontWeight: 500, fontSize: 13 }}>
                MULTIMEDIA
              </label>

              {detailActiveDrawer?.images?.length > 0
                ? <ul style={{ listStyle: 'none', padding: 0 }}>
                    <Row gutter={16} style={{ margin: '15px 0' }}>
                      {detailActiveDrawer?.images?.map((imageData) => (
                        <Col key={imageData}>
                          <li>
                            <Image src={imageData} alt="image-actives" width={112} height={84} style={{ borderRadius: 8, objectFit: 'cover' }} />
                          </li>
                        </Col>
                      ))}
                    </Row>
                  </ul>
                : <label>No posee imágenes adjuntas</label>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </CustomDrawer>
  )
}

export default CompanyAssetDetailDrawer