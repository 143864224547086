//ENCUENTAS ✅

export const surveysModule = {
    feature_code: 'FEAT-379',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-140',
            enabled: false,
        },
        {
            feature_code: 'FEAT-380',
            enabled: false,
        },
    ]
}