import { axiosCreate } from "../../../../config/index"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const deleteItem = async (idToken, formData) => {
  try {
    const response = await axiosCreate.delete('checklist/item', {
      headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000, data: formData
    })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default deleteItem
