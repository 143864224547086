import { Row } from 'antd'
import { useEffect, useState } from "react"
import HomePage from '../home/HomePage'
import useGeneral from './hooks/useGeneral'
import { CustomSpin } from '../../UI/CustomSpin'
import EmptyComponent from '../../UI/EmptyComponent'
import { useAppSelector } from '../../../hooks/store'
import HeaderActions from "./components/HeaderActions"
import SketchComponent from './components/SketchComponent'
import CustomSwitchButton from '../../UI/CustomSwitchButton'
import DetailLocalDrawer from "./components/DetailLocalDrawer"
import './style.css'
import { useFeatureRole } from '../../hooks/useFeatureRole'
import { MANAGEMENT_CONTRACTORS_CODES } from '../../../constants/modules/managamentContractor/managamentContractorCodes'

const BuildingSketchPage = () => {
  const [currentBody, setCurrentBody] = useState('buildings')
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)

  const { loading } = useGeneral()
  const { committee } = useAppSelector(state => state.auth)
  const fullName = `${(committee?.name)} ${(committee?.lastname)}`

  const { loadingFeatures, activeFeatures, gettingFeatures, getModuleFeatures, emptyFeatures, setLoadingFeatures, setGettingFeatures } = useFeatureRole({ defaultCheck: false })
  useEffect(() => {
    if (currentBody !== 'buildings') return
    //Aqui se debe de hacer algo...    
    if (!activeFeatures.length && gettingFeatures) {
      setLoadingFeatures(true)
      getModuleFeatures(MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_MAP)
    }

    if (activeFeatures.length && !gettingFeatures) {
      setLoadingFeatures(false)
    }

    return () => {
      if (activeFeatures.length && !gettingFeatures) {
        setGettingFeatures(true)
        emptyFeatures()
      }
    }
  }, [activeFeatures, gettingFeatures, currentBody])

  if (loadingFeatures || loading) return <CustomSpin tip="Cargando módulo" />

  const handleOpenCloseDrawer = () => setIsOpenDrawer(state => !state)

  const verifyBody = (currentActiveIdBody) => {
    let objBodies = {
      'messaging': <HomePage />,
      'buildings': (
        !activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_MAP)?.enabled ? <EmptyComponent label={'No tienes permiso para ver estos datos'} /> : (
          <>
            <HeaderActions />
            <SketchComponent handleOpenCloseDrawer={handleOpenCloseDrawer} />
          </>
        )
      ),
      'default': <EmptyComponent label="Hubo un error por favor refresque la página" />,
    }

    let currentBody = objBodies[currentActiveIdBody] || objBodies['default']
    return currentBody
  }

  return (
    <section className="wrapper-page" style={{ position: 'relative' }}>
      <Row
        justify="space-between"
        style={{ position: currentBody === 'messaging' ? 'absolute' : '', top: 0, right: 40 }}
      >
        {currentBody === 'buildings' && <h2 style={{ fontWeight: 600, fontSize: 26, color: "#232C4A" }}>
          Bienvenido {fullName}
        </h2>}

        <CustomSwitchButton setCurrentBody={setCurrentBody} />
      </Row>

      {verifyBody(currentBody)}

      <DetailLocalDrawer isOpenDrawer={isOpenDrawer} onCloseDrawer={handleOpenCloseDrawer} />
    </section>
  )
}

export default BuildingSketchPage
