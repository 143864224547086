import { Tree } from 'antd';
import React from 'react';

const CustomTree = ({defaultExpandAll, selectedKeys,selectItem ,multiple = true, onSelect, treeData, switcherIcon, showIcon}) => {
    const {DirectoryTree} = Tree
    return (
        <DirectoryTree
            defaultExpandAll={defaultExpandAll}
            selectedKeys={selectedKeys(selectItem)}
            multiple={multiple}
            onSelect={onSelect}
            treeData={treeData}
            switcherIcon={switcherIcon}
            showIcon={showIcon}
        />
    );
};

export default CustomTree;