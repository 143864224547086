import moment from "moment";
import { useAdminCheckContext } from "../contexts/AdminCheckContext";
import { customNotification } from "../../../../UI/CustomNotification";
import { deleteTaskIcon2, inProgressTaskIcon2, pausedTaskIcon2, savedChecklistIcon } from "../../assets/icons";
import { matchArrayObjectProps } from "../helpers/matchArrayObjectProps";
import { newCheckListType } from "../types/newCheckListType";
import { verifyResponse } from "../../../../helpers/verifyResponse";
import { getTaskDetail } from "../services/allCheckLists/GET/getTaskDetail";
import { createNewTask } from "../services/newCheckList/POST/createNewTask";
import { updateStatusTask } from "../services/newCheckList/PUT/updateStatusTask";
import { updateTask } from "../services/newCheckList/PUT/updateTask";
import { summaryCodeType } from '../types/summaryCodeType'
import { daysMock, statusDay, weekDays } from "../mocks/daysMock";
import { getColors } from "../helpers/randomColor";
import { useSelector } from "react-redux";

export const useNewCheckList = () => {
  const {
    Form,
    auth,
    form,
    formRef,
    eventsCalendar,
    generalSchedule,
    employees,
    categories,
    activeKey,
    loading,
    scheduleLength,
    formData,
    detailsRef,
    schedule_deleted_ids,
    taskStatus,
    param,
    loadingPage,
    employeeIndex,
    eventsColor,
    calendarColors,
    setEmployees,
    setEmployeeIndex,
    setLoadingPage,
    setTaskStatus,
    set_Schedule_Deleted_Ids,
    setFormData,
    setLoading,
    setActiveKey,
    setCategories,
    setEventsCalendar,
    setGeneralSchedule,
    getCategoriesService,
    getEmployeesService,
    handleRedirectTo
  } = useAdminCheckContext();

  const config = {
    rules: [{ required: true, message: "Por favor, no dejar vacío el campo" }],
  };
  const currentCompanyId = useSelector((state) => state?.auth?.currentRol)
  const cloneArray = items =>
    items.map(item =>
      Array.isArray(item)
        ? cloneArray(item)
        : item
    )

  /*****Funciones para obtener datos desde los servicios*******/
  const getTaskDetailService = async ({ task_id }) => {
    const res = await getTaskDetail({ token: auth.idToken, task_id });
    const status = verifyResponse({
      dataResponse: res.data,
      statusResponse: res.status,
    });
    if (status) return res.data.data;
    handleRedirectTo(-1);
    throw new Error("Error");
  };

  const createNewTaskService = async ({ formData }) => {
    setLoading(true);
    const res = await createNewTask({ token: auth.idToken, formData });
    const status = verifyResponse({
      dataResponse: res.data,
      statusResponse: res.status,
    });
    setLoading(false)
    if (status) return res.data.data;
    throw new Error("Error");
  };

  const updateTaskService = async ({ formData }) => {
    setLoading(true);
    const res = await updateTask({ token: auth.idToken, formData });
    const status = verifyResponse({
      dataResponse: res.data,
      statusResponse: res.status,
    });
    setLoading(false);
    if (status) return res.data.data;
    throw new Error("Error");
  };

  const updateStatusTaskService = async ({ formData, statusType }) => {
    setLoading(true);
    const res = await updateStatusTask({
      token: auth.idToken,
      formData,
      statusType,
    });
    const status = verifyResponse({
      dataResponse: res.data,
      statusResponse: res.status,
    });
    setLoading(false);
    if (status) {
      setTaskStatus(res.data.data.status)
      return res.data.data
    };
    throw new Error("Error");
  };

  /*****Funciones para manipular el formulario*****/

  const checkLength = ({ fields, field }) => {
    if (
      fields.length === field.name + 1 &&
      fields.length < scheduleLength.current
    ) {
      return (scheduleLength.current = fields.length);
    }

    if (
      fields.length === field.name + 1 &&
      fields.length >= scheduleLength.current
    ) {
      handleOpenCloseCollapse({
        collapseKey: field.key,
        collapseName: field.name,
      });
      handleOnChangeEmployee({ field, push: true })
      scheduleLength.current = fields.length;
    }
  };

  const handleChangeTaskStatus = async ({ value }) => {
    const res = await updateStatusTaskService({ formData: { task_id: param.idTaskChecklist }, statusType: value });
    if (res.status === summaryCodeType.DELETED) {
      handleRedirectTo(-1);
    }
    setTaskStatus(res.status);
    customNotification({
      message: res.status === summaryCodeType.PROGRESS ? "TAREA ACTIVADA" : res.status === summaryCodeType.PAUSED ? "TAREA PAUSADA" : "TAREA ELIMINADA",
      description: `Fecha de Inicio: ${moment().format("DD/MM/YYYY")}`,
      notificationType: "info",
      className: `newCheckList__notification ${res.status === summaryCodeType.PAUSED ? 'paused' : res.status === summaryCodeType.DELETED ? 'deleted' : ''}`,
      icon: res.status === summaryCodeType.DELETED ? deleteTaskIcon2 : res.status === summaryCodeType.PAUSED ? pausedTaskIcon2 : inProgressTaskIcon2,
      duration: 2.5,
    });
  }

  const handleOnChangeEmployee = ({ field, push, pop }) => {    //

    const schedule = form.getFieldValue('schedule');
    //Guardar los id de empleados una vez
    const existingEmployees = new Set();
    schedule.forEach((employee) => {
      if (pop && employee?.employee_id === schedule[field.name]?.employee_id) {
        //Omitir este elemento
      } else if (employee?.employee_id && !existingEmployees.has(employee?.employee_id)) {
        existingEmployees.add(employee?.employee_id);
      }
    })
    //Formar ahora un map para crear un indice ascendente de los empleados
    const employeeMap = new Map(Array.from(existingEmployees).map((employee, index) => [employee, index + 1]));

    if (push) {
      const newEmployee = { employee_id: null, key: field.name, index: field.name }
      return setEmployeeIndex((prev) => [...prev, newEmployee]);
    }
    if (pop) {
      return setEmployeeIndex((prev) => {
        const actualEmployeeIndex = [...prev];
        const finalEmployeeIndex = actualEmployeeIndex.filter((item) => item.key !== field.name).map((item, index) => ({ ...item, key: index, index: employeeMap.get(item.employee_id) }))
        return finalEmployeeIndex;
      });
    }

    const actualIndexEmployee = employeeMap.get(schedule[field.name]?.employee_id);

    //Actualizar el indice del empleado en el collapse seleccionado

    setEmployeeIndex((prev) => {
      const actualEmployeeIndex = [...prev];

      const finalEmployeeIndex = actualEmployeeIndex.map((item) => {
        //Si no existe un id, asignarle un id y el indice 
        const indexEmployee = employeeMap.get(item.employee_id)
        if (((item.employee_id === null) && (item.key === field.name)) || item.key === field.name) {
          return { ...item, employee_id: schedule[field.name]?.employee_id, index: actualIndexEmployee };
        }
        if (item.employee_id !== null) return { ...item, index: indexEmployee }
        return item;
      })
      return finalEmployeeIndex;
    })
  };

  const handleOpenCloseCollapse = ({
    value,
    collapseKey,
    collapseName,
    pop,
  }) => {
    if (pop) {
      const taskId =
        form.getFieldValue("schedule")[collapseName]?.task_employee_id;
      if ((taskId != null && taskId) || undefined)
        set_Schedule_Deleted_Ids((prev) => [...prev, taskId]);
    }
    setActiveKey((prev) => {
      let prevCollapses = [...prev];

      const index = prevCollapses.findIndex((item) =>
        collapseKey
          ? item.collapseKey === collapseKey
          : item.collapseName === collapseName
      );
      //Remover el item si viene pop
      if (pop) {        // 
        prevCollapses = [...prev]
          .filter((item) => item.collapseKey !== collapseKey)
          .map((item, index) => ({
            ...item,
            collapseName: index > 0 ? index - 1 : item.collapseName,
          }));
      } else if (index !== -1 && !value) {        // 
        prevCollapses[index].value =
          prevCollapses[index].value === "1" ? "" : "1";
      } else if (index !== -1 && value) {        // 
        prevCollapses[index].value = value;
      } else {
        //Agregar el objeto aqui        // 
        prevCollapses.push({ collapseKey, value: "1", collapseName });
      }      //
      return prevCollapses;
    });
  };

  const handleGeneralSchedule = ({ field }) => {
    //Comprobar esta funcion con el deleted_days
    setGeneralSchedule((prev) => !prev);
    const schedule = form.getFieldValue('schedule');
    const generalSchedule = form.getFieldValue('schedule')[field.name]?.generalSchedule;
    const deleted_days = form.getFieldValue('schedule')[field.name]?.deleted_days
    let days = schedule[field.name]?.days
    if (generalSchedule.length) {
      days = days.map((item) => ({
        ...item,
        status: !item.notMap && statusDay.SAVED,
        previousStatus: statusDay.SAVED,
        time_begin: moment().startOf('week').day(item.week_day).toDate(),
        time_end: moment().startOf('week').day(item.week_day).add(15, 'minutes').toDate()
      }));
    }
    else {
      //Eliminar ids de los días antes de resetear los días      
      days.forEach((item) => {
        if (item.id) {
          deleted_days.push(item.id)
        }
      })
      days = JSON.parse(JSON.stringify(daysMock));
    }
    form.setFieldsValue({
      schedule: schedule.map((item, index) => {
        if (index === field.name) {
          return { ...item, days }
        }
        return item;
      })
    })
    handleGetWeekDays({ field });
  };

  const handleChangeEndDateOption = ({ field }) => {
    const schedule = form.getFieldValue("schedule");
    const endOption = schedule[field.name]?.endDateOption;

    form.setFieldsValue({
      schedule: schedule.map((item, index) => {
        if (index === field.name && endOption !== 2 && endOption !== 3)
          return { ...item, occurrences: null, date_end: null };
        if (index === field.name && endOption !== 2)
          return { ...item, date_end: null };
        if (index === field.name && endOption !== 3)
          return { ...item, occurrences: null };
        return item;
      }),
    });
  };

  const handleChangeTimePicker = ({ field, timePicker }) => {

    //Validar si el time_end está antes del time_begin si es así añadir un día al time_end
    const schedule = form.getFieldValue("schedule");
    const scheduleKey = schedule[field.name];
    const currentDay = scheduleKey?.days.find((item) => item.status === statusDay.SELECTED);
    if (currentDay) {
      const newScheduleWeekDay = schedule.map((item, index) => {
        if (index === field.name) {
          return {
            ...item,
            days: item.days.map((subItem) => {
              //Si el item es el mismo que el seleccionado, cambiar el estado a unsaved cuando se hagan cambios en el timePicker
              if (subItem.day === currentDay.day && currentDay.status !== statusDay.SAVED) {
                subItem.status = statusDay.UNSAVED;
              }
              return subItem;
            })
          }
        }
        return item;
      })
      form.setFieldsValue({
        schedule: newScheduleWeekDay
      })
    }

    let time_begin = scheduleKey?.time_begin;
    let time_end = scheduleKey?.time_end;
    const time_begin_day = moment(time_begin).day();
    const time_end_day = moment(time_end).day();
    const time_begin_hour = moment(time_begin, 'hh:mm');
    const time_end_hour = moment(time_end, 'hh:mm');    //

    //Paso final, verificar si la hora es para el siguiente día y si es sábado
    if (time_begin === null) return
    if (timePicker === "time_begin") {
      if (time_begin) {
        //Obtener la fecha inicial

        //Obtener las horas de la fecha inicial
        if (moment(time_begin).isSame(moment(time_end))) {          // 
          time_end = moment(time_end).add(15, 'minutes');
        }
        //Si la fecha inicial está despues de la final y están en el mismo día
        if (moment(time_begin).isAfter(moment(time_end)) && time_begin_day === time_end_day) {
          time_end = moment(time_end).add(1, 'days');
        }
        //Si la fecha inicial está despues de la final y esta en dias diferentes (no hacer nada)
        else if ((time_end_hour.diff(time_begin_hour, 'minutes') < 1440)) {
        }
        //Si la fecha inicial es igual o está antes de la fecha final y están en días diferentes
        else if (moment(time_begin).isSameOrBefore(moment(time_end)) && time_begin_day !== time_end_day) {
          time_end = moment(time_end).subtract(1, 'days');
        }
      }
    }
    if (timePicker === "time_end") {
      if (time_end) {
        //Obtener la fecha final
        if (moment(time_begin).isSame(moment(time_end))) {
          time_end = moment(time_end).add(15, 'minutes');
        }
        if (moment(time_begin).isAfter(moment(time_end)) && time_begin_day === time_end_day) {
          time_end = moment(time_end).add(1, 'days');
        }
        else if ((time_end_hour.diff(time_begin_hour, 'minutes') < 1440)) {          //
        } else if (moment(time_begin).isSameOrBefore(moment(time_end)) && time_begin_day !== time_end_day) {
          time_end = moment(time_end).subtract(1, 'days');
        }
      }
    }

    //Si es un horario general debe de actualizarse la fecha de todos los días al cambiar el timepicker, pues no hay un boton de guardar horario
    const generalSchedule = schedule[field.name].generalSchedule;
    let days = schedule[field.name]?.days
    if (generalSchedule.length) {

      days = days.map((item) => {
        const newTime_begin = moment().startOf('week').day(item.week_day).add(moment(time_begin).hours(), 'hours').add(moment(time_begin).minutes(), 'minutes')
        const newTime_end = moment().startOf('week').day(item.week_day).add(moment(time_end).hours(), 'hours').add(moment(time_end).minutes(), 'minutes')
        if (moment(time_begin).day() !== moment(time_end).day()) {
          newTime_end.add(1, 'days');
        }
        //Verificar si es sabado y si el turno excede el día sabado, si es así dividir el horario
        if (item.week_day === 6 && moment(time_begin).day() !== moment(time_end).day()) {
          return (
            {
              ...item,
              time_begin: newTime_begin.toDate(),
              time_end: newTime_end.toDate(),
              week_day_end: 0,
            })

        }
        //Se utiliza un evento extra para simular el evento en el mismo domingo de la semana
        if (item.notMap && moment(time_begin).day() !== moment(time_end).day()) {
          const newDay = {
            ...item,
            time_begin: newTime_begin.subtract(1, 'day').toDate(),
            time_end: newTime_end.subtract(1, 'day').toDate(),
            status: statusDay.SAVED
          }
          return newDay
        }
        //Si las fechas son iguales no se crea este evento simulado
        if (item.notMap && moment(time_begin).day() === moment(time_end).day()) {
          return ({ ...item, time_begin: '', time_end: '', status: statusDay.NONE })
        }
        return ({
          ...item,
          time_begin: newTime_begin.toDate(),
          time_end: newTime_end.toDate(),
          week_day_end: moment(time_begin).day() !== moment(time_end).day() ? item.week_day + 1 : null,
        })
      });
    }

    const updateValues = {
      schedule: schedule.map((item, index) => {
        return index === field?.name
          ? {
            ...item,
            days,
            time_begin: time_begin
              ? time_begin
              : schedule[field.name]?.time_begin,
            time_end: time_end ? time_end : schedule[field.name]?.time_end,
          }
          : item;
      }),
    };    // 
    if (updateValues.schedule.length) form.setFieldsValue(updateValues);
    if (generalSchedule.length) handleGetWeekDays({});
  };

  const handleSelectWeekDay = ({ field, subField }) => {
    const all = form.getFieldsValue(true)
    const scheduleWeekDay = form.getFieldValue('schedule');    //
    const currrentDay = scheduleWeekDay[field.name]?.days[subField.name];

    //Establecer los valores a los time picker aqui     
    const newScheduleWeekDay = scheduleWeekDay.map((item, index) => {
      if (index === field.name) {
        return {
          ...item,
          time_begin: currrentDay.time_begin === '' ? moment().startOf('week').day(currrentDay.week_day) : currrentDay.time_begin,
          time_end: currrentDay.time_end === '' ? moment().startOf('week').day(currrentDay.week_day) : currrentDay.time_end,
          days: item.days.map((subItem) => {
            //Si el item es el mismo que el seleccionado, cambiar el estado a selected
            if (subItem.day === currrentDay.day) {
              subItem.previousStatus = currrentDay.status;
              subItem.status = statusDay.SELECTED;
            }
            //Cambiar el estado a none si el día es diferente al seleccionado
            if (subItem.status !== statusDay.SAVED && subItem.day !== currrentDay.day) {
              subItem.status = subItem.previousStatus;
            }
            return subItem;
          })
        }
      }
      return item;
    })
    form.setFieldsValue({
      schedule: newScheduleWeekDay
    })
  }

  const handleGetWeekDays = ({ field, subField, pop }) => {    //
    const schedule = form.getFieldValue("schedule");    //
    //Manejar el asignamiento de las tareas en el calendario
    //Mapear los eventos para el calendario
    const events = schedule
      .flatMap((item, index) => {
        if (
          item?.employee_id
        ) {
          const actualItem = item?.days?.flatMap((item2, index2) => {
            //Comprobar si el día de la fecha inicial es sábado y termina en domingo, si es así se debe fraccionar el horario
            //De tal manera que el sabado termmine a las 11:59 y continue el domingo de la misma semana, no el siguiente domingo            //
            if (item2.status === statusDay.SAVED || (item2.status === statusDay.SELECTED && item2.previousStatus === statusDay.SAVED)) {
              return {
                id: item.employee_id,
                color: getColors({ num: item.employee_id, assignColors: eventsColor, colors: calendarColors }),
                index: index,
                title: employees.find(
                  (employee) => employee.id === item.employee_id
                )?.name, //Nombre del empleado cuando exista
                start: moment(item2.time_begin).toDate(),
                end: moment(item2.time_end).toDate(),
              };
            }
            return undefined
          });
          return actualItem;
        }
        return undefined;
      })
      .filter((item) => item !== undefined);

    // 

    if (events.length || eventsCalendar.length) {
      setEventsCalendar(events);
    }
  };

  const handleSavedWeekDay = ({ field }) => {
    const schedule = form.getFieldValue("schedule");
    const newSchedule = cloneArray(schedule);
    const time_begin = newSchedule[field.name]?.time_begin;
    const time_end = newSchedule[field.name]?.time_end;
    const currrentDay = newSchedule[field.name]?.days.find((item) => item.status === statusDay.UNSAVED);

    if (!currrentDay) return;        // 

    //Guardar la fecha
    //Primero verificar si dentro del mismo turno se intersectan las horas
    const selfError = newSchedule[field.name]?.days?.map((item, index) => {
      if (item === currrentDay || item.time_begin === "") {        //
        return false;
      }

      const date1 = moment(item.time_begin);
      const date2 = moment(item.time_end)
      const date3 = moment(time_begin);
      const date4 = moment(time_end);
      const date5 = moment(newSchedule[field.name]?.days[!index ? newSchedule[field.name]?.days?.length - 1 : index - 1].time_begin);
      const date6 = moment(newSchedule[field.name]?.days[!index ? newSchedule[field.name]?.days?.length - 1 : index - 1].time_end);
      if (
        ((date1.isValid()) && (date1.isBetween(date3, date4, undefined, '[)'))) ||
        ((date1.isValid()) && (date1.isBetween(date5, date6, undefined, '[)'))) ||
        ((date2.isValid()) && (date2.isBetween(date3, date4, undefined, '(]'))) ||
        ((date2.isValid()) && (date2.isBetween(date5, date6, undefined, '(]'))) ||
        ((date3.isValid()) && (date3.isBetween(date1, date2, undefined, '[)'))) ||
        ((date3.isValid()) && (date3.isBetween(date5, date6, undefined, '[)'))) ||
        ((date4.isValid()) && (date4.isBetween(date1, date2, undefined, '(]'))) ||
        ((date4.isValid()) && (date4.isBetween(date5, date6, undefined, '(]'))) ||
        ((date5.isValid()) && (date5.isBetween(date1, date2, undefined, '[)'))) ||
        ((date5.isValid()) && (date5.isBetween(date3, date4, undefined, '[)'))) ||
        ((date6.isValid()) && (date6.isBetween(date1, date2, undefined, '(]'))) ||
        ((date6.isValid()) && (date6.isBetween(date3, date4, undefined, '(]')))
      ) {
        if (date3.isSame(date5) && date4.isBefore(date1)) return false;
        return true;
      }
    })

    //Pero también aqui se debe verificar si la hora se va a guardar ya la tiene el empleado...
    const sameEmployee = newSchedule.map((item, index) => {
      const employee_id = item.employee_id === newSchedule[field.name].employee_id;
      const excludeIndex = index !== field.name
      if (employee_id && excludeIndex) {
        return { days: item.days };
      }
    }).filter((item) => item !== undefined);

    const error = sameEmployee.flatMap((employee) => employee.days.map((item, index) => {
      const date1 = moment(item.time_begin);
      const date2 = moment(item.time_end)
      const date5 = moment(employee?.days[!index ? employee?.days?.length - 2 : index - 1].time_begin);
      const date6 = moment(employee?.days[!index ? employee?.days?.length - 2 : index - 1].time_end);
      const date3 = moment(newSchedule[field.name].time_begin);
      const date4 = moment(newSchedule[field.name].time_end);

      if (
        ((date1.isValid()) && (date1.isBetween(date3, date4, undefined, '[)'))) ||
        ((date1.isValid()) && (date1.isBetween(date5, date6, undefined, '[)'))) ||
        ((date2.isValid()) && (date2.isBetween(date3, date4, undefined, '(]'))) ||
        ((date2.isValid()) && (date2.isBetween(date5, date6, undefined, '(]'))) ||
        ((date3.isValid()) && (date3.isBetween(date1, date2, undefined, '[)'))) ||
        ((date3.isValid()) && (date3.isBetween(date5, date6, undefined, '[)'))) ||
        ((date4.isValid()) && (date4.isBetween(date1, date2, undefined, '(]'))) ||
        ((date4.isValid()) && (date4.isBetween(date5, date6, undefined, '(]'))) ||
        ((date5.isValid()) && (date5.isBetween(date1, date2, undefined, '[)'))) ||
        ((date5.isValid()) && (date5.isBetween(date3, date4, undefined, '[)'))) ||
        ((date6.isValid()) && (date6.isBetween(date1, date2, undefined, '(]'))) ||
        ((date6.isValid()) && (date6.isBetween(date3, date4, undefined, '(]')))
      ) {        //
        return true;
      }
    }))


    if (error?.includes(true) || selfError?.includes(true)) {
      form.setFields([
        {
          name: ['schedule', field.name, 'time_begin'],
          errors: ['El empleado ya tiene un horario asignado en este rango de horas']
        },
        {
          name: ['schedule', field.name, 'time_end'],
          errors: ['El empleado ya tiene un horario asignado en este rango de horas']
        },
      ])
      return; //Si hay un error no guardar
    }

    const newScheduleWeekDay = newSchedule.map((item, index) => {
      if (index === field.name) {
        return {
          ...item,
          days: item.days.map((subItem) => {
            //Si el item es el mismo que el seleccionado, cambiar el estado a selected
            if (currrentDay.week_day === 6) {
              if (subItem.week_day === 6) {
                return (
                  {
                    ...subItem,
                    time_begin,
                    time_end,
                    week_day_end: moment(time_begin).day() !== moment(time_end).day() ? 0 : null,
                    status: statusDay.SAVED
                  })
              }
              //Se utiliza un evento extra para simular el evento en el mismo domingo de la semana
              if (subItem.notMap && moment(time_begin).day() !== moment(time_end).day()) {
                const newDay = {
                  ...subItem,
                  time_begin: moment(time_begin).subtract(7, 'days'),
                  time_end: moment(time_end).subtract(7, 'days'),
                  status: statusDay.SAVED
                }
                return newDay
              }
              //Si las fechas son iguales no se crea este evento simulado
              if (subItem.notMap && moment(time_begin).day() === moment(time_end).day()) {
                return ({ ...subItem, status: statusDay.NONE })
              }
            }
            if (subItem.day === currrentDay.day && currrentDay.week_day !== 6) {
              //Verificar si es sabado y si el turno excede el día sabado, si es así dividir el horario
              return ({
                ...subItem,
                time_begin,
                time_end,
                week_day_end: moment(time_begin).day() !== moment(time_end).day() ? subItem.week_day + 1 : null,
                status: statusDay.SAVED
              })
            }
            return subItem;
          })
        }
      }
      return item
    })

    form.setFieldsValue({
      schedule: newScheduleWeekDay
    })
    //Resetear los errores
    form.setFields([
      {
        name: ['schedule', field.name, 'time_begin'],
        errors: []
      },
      {
        name: ['schedule', field.name, 'time_end'],
        errors: []
      },
    ]);

    handleGetWeekDays({});
  }

  const handleDeleteWeekDay = ({ field }) => {
    const schedule = form.getFieldValue('schedule');
    const currentDay = schedule[field.name]?.days.find((item) => item.status === statusDay.SELECTED || item.status === statusDay.UNSAVED);
    const deleted_days = form.getFieldValue('schedule')[field.name]?.deleted_days;
    if (!currentDay) return;
    if (currentDay.id) {
      deleted_days.push(currentDay.id)
    }
    const newScheduleWeekDay = schedule.map((item, index) => {
      if (index === field.name) {
        return {
          ...item,
          days: item.days.map((subItem, index) => {
            //Si el item es el mismo que el seleccionado y es sábado, eliminar tambien el dia auxiliar para el domingo
            if (currentDay.week_day === 6) {
              if (subItem === currentDay || subItem.notMap) {
                subItem.id = null;
                subItem.status = statusDay.NONE;
                subItem.previousStatus = statusDay.NONE;
                subItem.time_begin = '';
                subItem.time_end = '';
                return (
                  subItem
                )
              }
            }
            if (subItem.day === currentDay.day) {
              subItem.id = null;
              subItem.status = statusDay.NONE;
              subItem.previousStatus = statusDay.NONE;
              subItem.time_begin = '';
              subItem.time_end = '';
            }
            return subItem;
          })
        }
      }
      return item;
    })
    form.setFieldsValue({
      schedule: newScheduleWeekDay
    })

    form.setFields([
      {
        name: ['schedule', field.name, 'time_begin'],
        errors: []
      },
      {
        name: ['schedule', field.name, 'time_end'],
        errors: []
      },
    ])
    handleGetWeekDays({ field });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    formRef.current.submit();
  };

  const onSubmit = async (formData) => {
    const newFormData = matchArrayObjectProps(
      newCheckListType,
      formData,
      "schedule",
      "task_employee_id"
    );
    if (schedule_deleted_ids.length) {
      newFormData.schedule_deleted_ids = schedule_deleted_ids;
    }
    newFormData.allow_attach_files = newFormData?.allow_attach_files.length
      ? true
      : false;
    //Recorrer el formdata para modificar las fechas?
    const newSchedule = newFormData.schedule.map((item) => {
      const newItem = {};
      if (item.deleted_days?.length) {
        newItem.deleted_days = item.deleted_days;
      }
      if (item.task_employee_id) {
        newItem.task_employee_id = item.task_employee_id;
      }

      newItem.employee_id = item.employee_id;
      newItem.days = item.days.map((item) => {
        const day = item;
        if ((day.status === statusDay.SAVED || day.previousStatus === statusDay.SAVED) && !day.notMap) {
          const savedDay = {
            week_day: day.week_day,
            week_day_end: day.week_day_end,
            time_begin: moment(day.time_begin).format("HH:mm").toString(),
            time_end: moment(day.time_end).format("HH:mm").toString(),
          }
          if (item.id) {
            savedDay.id = item.id;
          }

          return (savedDay);
        }
      }).filter(item => item !== undefined);
      newItem.occurrences = item.occurrences
      newItem.date_end = item.date_end
        ? moment(item.date_end).format("YYYY-MM-DD").toString()
        : null;      // 
      return newItem;
    }).filter(item => item !== undefined);

    const finalFormData = { ...newFormData, schedule: newSchedule, company_id: currentCompanyId };

    //Listo para enviar al servicio!
    let res = {};
    if (param.idTaskChecklist) {
      res = await updateTaskService({ formData: { ...finalFormData, task_id: param.idTaskChecklist } });
      res.category_id = res.category_id;
      res.allow_attach_files = res.allow_attach_files ? "1" : [];
      //Con la nueva data establecer los ids correspondientes a los elementos si se han creado nuevos antes de actualizar la tarea   
      const newSchedule = res.schedule.map((item, index) => {
        return {
          ...item,
          deleted_days: [],
          employee_id: item.employee_id,
          days: daysOfWeekSchedule(item.days),
          date_end: item.date_end ? moment(item.date_end, "YYYY-MM-DD") : null,
          endDateOption: item.date_end ? "2" : item.occurrences ? "3" : "1",
          generalSchedule: item.days.length >= 7 ? "1" : [],
          time_begin: (item.days).every((item2) => item2.time_begin === (item.days)[0].time_begin) ? moment((item.days)[0].time_begin, 'hh:mm a') : null,
          time_end: (item.days).every((item2) => item2.time_end === (item.days)[0].time_end) ? moment((item.days)[0].time_end, 'hh:mm a') : null,
        };
      });
      form.setFieldsValue({ ...res, schedule: newSchedule });
    }
    else {
      res = await createNewTaskService({ formData: { ...finalFormData, date: moment().format("YYYY-MM-DD").toString() } });
      handleRedirectTo(-1);
    }
    setTaskStatus(res.status)
    set_Schedule_Deleted_Ids([]);
    customNotification({
      message: !param.idTaskChecklist ? "TAREA CREADA" : "TAREA ACTUALIZADA",
      description: `Fecha de Inicio: ${moment().format("DD/MM/YYYY")}`,
      notificationType: "info",
      className: "newCheckList__notification",
      icon: savedChecklistIcon,
      duration: 4.5,
    });
  };

  const onFailedSubmit = (error) => {
    //Si hay un error desplazarlo al primero si no está visible    
    //Si el error en en un campo de información general
    if (
      error.errorFields.find(
        (item) =>
          item.name.includes("name") ||
          item.name.includes("description") ||
          item.name.includes("category")
      )
    ) {
      handleOpenCloseCollapse({ value: "1", collapseName: "generalInfo" });
    } else {
      //Si el error es en algun horario del empleado
      const collapseItem = error.errorFields.find((item) => item.name[1] !== "")
        ?.name[1];
      handleOpenCloseCollapse({ value: "1", collapseName: collapseItem });
    }
    const firstError = error.errorFields[0];

    //Se espera un momento mientra se abre el collapse para redirigir el scroll hacia donde está el error (animación local)
    setTimeout(() => {
      if (firstError) {
        const { name } = firstError;
        const element = document.getElementById(
          "newChecklist_" + name.join("_")
        );
        if (element && element.scrollIntoView) {          // 
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      }
    }, 250);
  };

  const daysOfWeekSchedule = (days) => {
    const mappedDays = JSON.parse(JSON.stringify(daysMock))
    days.forEach((day) => {
      const newDay = {};
      newDay.id = day.id;
      newDay.day = weekDays[day.week_day].day;
      newDay.fullDay = weekDays[day.week_day].fullDay;
      newDay.week_day = day.week_day;
      newDay.week_day_end = day.week_day_end;
      newDay.time_begin = moment().startOf('week').day(day.week_day).add(moment(day.time_begin, "h:mm a").hours(), 'hours').add(moment(day.time_begin, "h:mm a").minutes(), 'minutes');
      newDay.time_end = moment().startOf('week').day(day.week_day_end !== null && day.week_day_end !== 0 ? day.week_day_end : day.week_day).add(moment(day.time_end, "h:mm a").hours(), 'hours').add(moment(day.time_end, "h:mm a").minutes(), 'minutes');
      newDay.status = statusDay.SAVED;
      newDay.previousStatus = statusDay.SAVED;
      newDay.notMap = false;
      //Mapear un día adicional en caso de que la tarea del sábado acabe en domingo
      if (day.week_day === 6 && day.week_day_end === 0) {
        const auxiliarDay = {};
        newDay.time_end = newDay.time_end.add(1, 'day');
        auxiliarDay.notMap = true;
        auxiliarDay.status = statusDay.SAVED;
        auxiliarDay.previousStatus = statusDay.SAVED;
        auxiliarDay.week_day = 0;
        auxiliarDay.week_day_end = null;
        auxiliarDay.time_begin = moment().startOf('week').day(day.week_day).add(moment(day.time_begin, "h:mm a").hours(), 'hours').add(moment(day.time_begin, "h:mm a").minutes(), 'minutes').subtract(7, 'days');
        auxiliarDay.time_end = moment().startOf('week').day(day.week_day).add(moment(day.time_end, "h:mm a").hours(), 'hours').add(moment(day.time_end, "h:mm a").minutes(), 'minutes').subtract(6, 'days');
        const dayIndex = mappedDays.findIndex((item) => item.week_day === newDay.week_day);
        const auxiliarDayIndex = mappedDays.findIndex((item) => item.notMap === true);

        if (dayIndex !== -1) mappedDays[dayIndex] = newDay;
        if (auxiliarDayIndex !== -1) mappedDays[auxiliarDayIndex] = auxiliarDay;
      }
      else {
        const index = mappedDays.findIndex((item) => item.week_day === newDay.week_day);
        if (index !== -1) mappedDays[index] = newDay;
      }
    });
    return mappedDays;
  }

  //Cuando vienen datos a partir de un id
  const getCheckListDetail = async ({ task_id }) => {
    setLoadingPage(true);
    const res = await getTaskDetailService({ task_id });
    const employeesIndex = [];
    res.category_id = res.category_id;
    res.allow_attach_files = res.allow_attach_files ? "1" : [];

    //Formar ahora un map para crear un indice ascendente de los empleados
    const existingEmployees = new Set();

    const newSchedule = res.schedule.map((item, index) => {

      if (item?.employee_id && !existingEmployees.has(item?.employee_id)) {
        existingEmployees.add(item?.employee_id);
      }
      return {
        ...item,
        deleted_days: [],
        employee_id: item.employee_id,
        days: daysOfWeekSchedule(item.days),
        date_end: item.date_end ? moment(item.date_end, "YYYY-MM-DD") : null,
        endDateOption: item.date_end ? "2" : item.occurrences ? "3" : "1",
        generalSchedule: item.days.length >= 7 ? "1" : [],
        time_begin: (item.days).every((item2) => item2.time_begin === (item.days)[0].time_begin) ? moment((item.days)[0].time_begin, 'hh:mm a') : null,
        time_end: (item.days).every((item2) => item2.time_end === (item.days)[0].time_end) ? moment((item.days)[0].time_end, 'hh:mm a') : null,
      };
    });

    const employeeMap = new Map(Array.from(existingEmployees).map((employee, index) => [employee, index + 1]));
    newSchedule.forEach((item, index) => {
      employeesIndex.push({
        employee_id: item.employee_id,
        key: index,
        index: employeeMap.get(item.employee_id)
      });
    });
    setEmployeeIndex(employeesIndex);

    form.setFieldsValue({ ...res, schedule: newSchedule });
    //Para establecer el calendario con los datos iniciales
    handleGetWeekDays({});

    //Para abrir cada uno de los collapses
    const collapseActives = Array(newSchedule.length + 1)
      .fill()
      .map((_, i) => {
        if (!i)
          return {
            collapseName: "generalInfo",
            collapseKey: "generalInfo",
            value: "1",
          };
        return {
          collapseKey: i - 1,
          collapseName: i - 1,
          value: i === newSchedule.length ? "" : "1",
        };
      });
    setActiveKey(collapseActives);
    setTaskStatus(res.status);
    return setLoadingPage(false);
  };

  return {
    Form,
    form,
    formRef,
    eventsCalendar,
    config,
    generalSchedule,
    employees,
    categories,
    activeKey,
    loading,
    scheduleLength,
    formData,
    detailsRef,
    taskStatus,
    loadingPage,
    employeeIndex,
    eventsColor,
    calendarColors,
    setEmployees,
    setLoadingPage,
    setFormData,
    checkLength,
    setCategories,
    setEventsCalendar,
    setActiveKey,
    getCheckListDetail,
    handleChangeEndDateOption,
    handleChangeTaskStatus,
    handleOpenCloseCollapse,
    handleOnChangeEmployee,
    handleChangeTimePicker,
    handleGeneralSchedule,
    handleGetWeekDays,
    handleSelectWeekDay,
    handleSavedWeekDay,
    handleDeleteWeekDay,
    handleSubmit,
    onSubmit,
    onFailedSubmit,
    getCategoriesService,
    getEmployeesService
  };
};