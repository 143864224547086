import { createContext, useContext, useState } from "react";
import { useAppSelector } from "../../../../hooks/store";
import { defaulStateTable } from "../../../../constants/defaultStates";
import useRedirectTo from "../../../hooks/useRedirectTo";

//Se define las propiedades iniciales para el contexto
const UserRolesContext = createContext({
    auth: null, serviceParams: null, rolesItem: [], handleRedirectTo: () => { },
    loading: {
        userRolPage: false
    }, setLoading: () => { },
    showModal: false, setShowModal: () => { },
    userRolesTable: defaulStateTable, setUserRolesTable: () => { },
})

//Se exporta un contexto que contiene valores comunes para cada pagina del flujo de roles como el de autenticacion y otros estados locales
export const UserRolesContextProvider = ({ children }) => {
    const auth = useAppSelector(state => state.auth)
    const { handleRedirectTo } = useRedirectTo()
    const [loading, setLoading] = useState({
        userRolPage: false
    })
    const [showModal, setShowModal] = useState(false)
    const [userRolesTable, setUserRolesTable] = useState(structuredClone(defaulStateTable))

    const rolesItem = auth?.roles?.map(role => ({
        ...role,
        id: role.companyId,
        name: role.companyName
    }))

    const values = {
        auth, rolesItem, handleRedirectTo,
        loading, setLoading,
        showModal, setShowModal,
        userRolesTable, setUserRolesTable,
    }

    return (
        <UserRolesContext.Provider value={values}>
            {children}
        </UserRolesContext.Provider>
    )
}

export const useUserRolesContext = () => useContext(UserRolesContext)