import { Button } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { getCategoryDailyTask } from "../services/GET/getCategoryDailyTask";
import { getColumnsFilter } from "../../../../helpers/customTableHelper";
import { ReactComponent as CheckedTableIcon } from '../assets/icons/checkedTableIcon.svg'
import { ReactComponent as CheckTableIcon } from '../assets/icons/checkTableIcon.svg'
import useCurrentLocation from "../../../../hooks/useCurrentLocation";
import verifyResponse from "../../../../helpers/verifyResponse";
import { useSelector } from "react-redux";
import { getTableDataHelper } from "../../../../helpers/getTableDataHelper";
import { columnsOrderedTable } from "../../../../helpers/columnsTableHelper";

export const useDetailActivity = () => {

    const auth = useSelector((state) => state.auth);
    const currentCompanyId = useSelector((state) => state?.auth?.currentRol)
    const { param } = useCurrentLocation();

    const [loading, setLoading] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [columnsTasks, setColumnsTasks] = useState([])
    const [currentParams, setCurrentParams] = useState()
    const [summaryTask, setSummaryTask] = useState({ total: 1, completed: 0, })
    const [currentPageTable, setCurrentPageTable] = useState(1)
    const [{ limit: limitTable, quantity: quantityTable }, setMetaDataTable] = useState({});
    const [categoryInfo, setCategoryInfo] = useState({})

    const getCategoryDailyTaskService = async ({ formData, skip = 0 }) => {
        setLoading(true);
        const res = await getCategoryDailyTask({ token: auth.idToken, formData, skip, limit: 10, company_id: currentCompanyId });
        const statusRes = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false });
        setLoading(false);
        if (statusRes) {
            const { data, metadata } = getTableDataHelper({ data: res?.data?.data, metadata: res?.data?.metadata })
            setTasks(data?.dataSource)
            setColumnsTasks(data?.columns)
            setMetaDataTable(metadata);
            setCategoryInfo(res.data.data.category)
            setSummaryTask(res.data.data.summary)
            setCurrentParams(formData);
            return res.data.data;
        }
        throw new Error("Error");
    }

    const columnsFilterObject = {
        default: () => { }
    }

    const tempColumns = [{
        dataIndex: 'checkStatus',
        key: 'checkStatus',
        render: (text, record) => {
            return <Button style={{ border: 'none', background: 'transparent', boxShadow: 'none' }} icon={record.status === 'completed' ? <CheckedTableIcon /> : <CheckTableIcon />} />
        }
    },
    {
        title: 'Nombre de la tarea',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Hora',
        dataIndex: 'time',
        key: 'time',
        render: (text, record) => {
            if (record.status === 'delayed') {
                return <span style={{ color: '#FF7373', fontWeight: 700, fontSize: 14 }}>{text}</span>
            }
            return <span>{text}</span>
        }
    },
    {
        title: 'Estado',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (text, record) => {
            if (text === 'delayed') {
                return <span style={{ color: '#FF7373', fontWeight: 700, fontSize: 12 }}>DEMORADO</span>
            }
            if (text === 'completed') {
                return <span style={{ color: 'var(--primary)', fontWeight: 700, fontSize: 12 }}>FINALIZADO</span>
            }
            if (text === 'inProgress') {
                return <span style={{ color: '#59ADFF', fontWeight: 700, fontSize: 12 }}>EN PROGRESO</span>
            }
        }
    }
    ]

    const columns = columnsTasks.length ? columnsOrderedTable({ defaultColumns: tempColumns, backendColumns: columnsTasks, titleUpperCase: false }) : tempColumns
    const taskColumns = columns.map((col) => ({
        ...col,
        ...getColumnsFilter({
            column: col, columnsFilterObject
        })
    }))

    const handleChangeTable = async ({ pagination }) => {
        const { current } = pagination;
        const pageValue = current || currentPageTable;
        const newSkip = pageValue * limitTable - limitTable;
        const newParams = { ...currentParams }
        setCurrentPageTable(pageValue);
        await getCategoryDailyTaskService({ token: auth.idToken, formData: newParams, skip: newSkip });            //
    }


    useEffect(() => {

        if (param.idDetail) {
            const data = JSON.parse(param.idDetail);
            const formData = { category_id: data.category_id, filter_by: data.filter_by }
            setCurrentParams(formData);
            getCategoryDailyTaskService({ formData })
        }

    }, [])


    return { loading, taskColumns, tasks, currentPageTable, limitTable, quantityTable, summaryTask, categoryInfo, handleChangeTable };
}