import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const companyProvidersPath = 'provider'

export const deleteProvider = async ({
    token,
    provider_id
}) => {
    try {
        const params = {
            provider_id
        };

        const response = await axiosCreate.delete(`${companyProvidersPath}`, {
            params, timeout: 10000, headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
}