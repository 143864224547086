import { useContext, useState } from "react"
import CustomModal from "../../../../../../../../UI/CustomModal"
import { AdminChecklistTasksContext } from "../../context/AdminChecklistTasksContext"
import useAdminCheckListTasks from "../../hooks/useAdminCheckListTasks"
import { config } from "../../../../../../../../constants/constants"
import { Button, Col, Form, Row } from "antd"
import { CustomInput } from "../../../../../../../../UI/CustomInput"
import useCurrentLocation from "../../../../../../../../hooks/useCurrentLocation"

const NewEditTaskModal = () => {
  const [loading, setLoading] = useState(false)
 
  const { param } = useCurrentLocation()
  const { onSaveChecklistItem, getTasksByCategory } = useAdminCheckListTasks()
  const { form, isOpenModal, handleOpenCloseModal, filters, taskClicked, setTaskClicked } = useContext(AdminChecklistTasksContext)

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      handleOpenCloseModal()
      await getTasksByCategory({ ...filters  })
    }
  }

  const onSubmit = async (formData) => {
    setLoading(true)

    const isChecklistInstanceId = taskClicked?.checklist_item_id
      ? {
        checklist_item_id: taskClicked?.checklist_item_id
      } : {}

    const objToSend = {
      ...formData,
      ...isChecklistInstanceId,
      checklist_category_id: param.checklistCategoryId,
    }

    const response = await onSaveChecklistItem(objToSend)

    await onFinish(response)

    setLoading(false)
  }
  
  return (
    <CustomModal
      destroyOnClose
      title={<h3 style={{ margin: 0 }}>{`${taskClicked?.checklist_item_id ? 'EDITAR TAREA' : 'NUEVA TAREA'}`}</h3>}
      isOpenModal={isOpenModal}
      onCancel={() => {
        handleOpenCloseModal()
        setTaskClicked({})
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="newEditTaskForm"
        onFinish={onSubmit}
        disabled={loading}
      >
        <label style={{ fontWeight: 600, color: '#626262', fontSize: 12 }}>
          NOMBRE DE TAREA
        </label>

        <Form.Item name="name" {...config}>
          <CustomInput placeholder="Escribe el nombre de la tarea" />
        </Form.Item>

        <Row justify="end" gutter={16}>
          <Col>
            <Button
              type="text"
              shape="round"
              htmlType="button"
              style={{ border: 0, width: 132, height: 42 }}
              loading={loading}
              disabled={loading}
              onClick={() => {
                handleOpenCloseModal()
                setTaskClicked({})
              }}
            >
              <span style={{ fontWeight: 500, fontSize: 14, color: '#404040' }}>CANCELAR</span>
            </Button>
          </Col>

          <Col>
            <Button
              type="text"
              shape="round"
              htmlType="submit"
              style={{ border: 0, width: 132 }}
              loading={loading}
              disabled={loading}
              className="btn-standard-green"
            >
              <span>{`${taskClicked?.checklist_item_id ? 'EDITAR' : 'CREAR'}`}</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default NewEditTaskModal