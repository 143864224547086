import { useContext } from "react"
import { Col, Form, Row } from "antd"
import CustomSwitch from './../../../../../../UI/CustomSwitch'
import { CustomInput } from "../../../../../../UI/CustomInput"
import { config } from "../../../../../../constants/constants"
import { AmenitiesContext } from "../../../../context/AmenitiesContext"

const GeneralSettingsData = () => {
  const { Item } = Form

  const {
    valueInviteesAllowed,
    setValueInviteesAllowed,
    setMinimunValueTimeReserve,
    setCurrentQuantityInvitees,
  } = useContext(AmenitiesContext)

  const handleOnBlurTime = (value) => {
    setMinimunValueTimeReserve(value.target.value)
  }

  const handleOnChangeInviteesQuantity = () => setValueInviteesAllowed(state => !state)

  const handleOnBlurQuantityInvitees = (event) => setCurrentQuantityInvitees(event.target.value)

  return <section style={{ backgroundColor: '#FFFFFF', borderRadius: 16, padding: '50px 25px', marginTop: 25 }}>
    <Row gutter={16}>
      <Col span={24}>
        <h2 style={{ fontWeight: 600, fontSize: 20 }}>
          CONFIGURACIÓN DE RESERVAS
        </h2>

        <Row gutter={16} style={{ margin: '25px 0' }}>
          <Col span={12}>
            <label style={{ fontWeight: 400, fontSize: 12, color: '#404040' }}>
              TIEMPO MINIMO PARA UNA RESERVA (En minutos)
            </label>

            <Item name="reservation_minutes" {...config}>
              <CustomInput
                type="NUMBER"
                nullInputNumberText={null}
                min={30}
                maxLength={5}
                precision={0}
                className="custom-input-amenity"
                onBlur={handleOnBlurTime}
                placeholder="Escribe un tiempo mínimo"
              />
            </Item>

            <label style={{ fontWeight: 400, fontSize: 12, color: '#404040' }}>
              CANTIDAD MAXIMA DE TURNOS SEGUIDOS
            </label>

            <Item name="allowed_iterations" {...config}>
              <CustomInput
                type="NUMBER"
                nullInputNumberText={null}
                min={0}
                maxLength={5}
                precision={0}
                className="custom-input-amenity"
                placeholder="Escribe la cantidad de turnos permitidos"
              />
            </Item>

            <label style={{ fontWeight: 400, fontSize: 12, color: '#404040' }}>
              TIEMPO DE MANTENIMIENTO
            </label>

            <Item name="previous_minutes">
              <CustomInput
                type="NUMBER"
                nullInputNumberText={null}
                min={10}
                maxLength={5}
                precision={0}
                className="custom-input-amenity"
                placeholder="Escribe el tiempo de manetenimiento"
              />
            </Item>
          </Col>

          <Col span={12}>
            <Row justify="space-between">
              <label style={{ fontWeight: 600, fontSize: 14 }}>
                Se permiten invitados externos
              </label>

              <Item name="invitee_enabled" valuePropName="checked">
                <CustomSwitch checked={valueInviteesAllowed} onChange={handleOnChangeInviteesQuantity} />
              </Item>
            </Row>
            <label style={{ fontWeight: 400, fontSize: 12, color: '#404040', opacity: valueInviteesAllowed ? 1 : 0.5 }}>
              CANTIDAD MÁXIMA DE INVITADOS
            </label>

            <Item name="maximum_invitee_allowed">
              <CustomInput
                type="NUMBER"
                nullInputNumberText={null}
                min={0}
                maxLength={5}
                precision={0}
                disabled={!valueInviteesAllowed}
                placeholder="Ingresa la cantidad de invitados"
                className="custom-input-amenity"
                onBlur={handleOnBlurQuantityInvitees}
              />
            </Item>

            <label style={{ fontWeight: 400, fontSize: 12, color: '#404040' }}>
              TIEMPO MÍNIMO DE ANTICIPACION DE RESERVA
            </label>

            <Item name="days_available_for_reservation" {...config}>
              <CustomInput
                type="NUMBER"
                nullInputNumberText={null}
                min={0}
                maxLength={5}
                precision={0}
                className="custom-input-amenity"
                placeholder="Escribe el tiempo mínimo para una reserva"
              />
            </Item>

            <Row justify="space-between">
              <label style={{ fontWeight: 600, fontSize: 14 }}>
                Se requiere confirmación del administrador
              </label>

              <Item name="admin_confirmation" valuePropName="checked">
                <CustomSwitch />
              </Item>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  </section>
}

export default GeneralSettingsData