import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import useTickets from '../../hooks/useTickets'
import CustomModal from '../../../../UI/CustomModal'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { CategoriesTicketContext } from '../../context/CategoriesTicketContext'

const NewEditSubCategoryModal = ({
  form,
  isOpenModal,
  handleOpenCloseModal,
  getAllTicketsCategories,
}) => {
  const [loading, setLoading] = useState(false)
  const currentCompanyId = useSelector((state) => state?.auth?.currentRol)
  const { categories, onSaveSubCategory } = useTickets()
  const { subCategorySelected, cleanSubCategorySelected } = useContext(CategoriesTicketContext)
  const config = { rules: [{ required: true, message: "Por favor llenar el campo" }] }
  const categoriesToSelect = (categories.map((category) => ({ value: category.id, label: category.name })))

  const onReset = () => form.resetFields()

  const onFinish = async (isSuccessful) => {
    if (isSuccessful) {
      await getAllTicketsCategories(currentCompanyId)
      onReset()
      handleOpenCloseModal()
      cleanSubCategorySelected()
      return
    }
    return null
  }

  const onSubmit = async (formData) => {
    setLoading(true)
    const response = await onSaveSubCategory({ ...formData, id: subCategorySelected.id })
    onFinish(response)
    setLoading(false)
  }

  return (
    <CustomModal
      centered
      destroyOnClose
      isOpenModal={isOpenModal}
      onCancel={() => {
        handleOpenCloseModal()
        cleanSubCategorySelected()
      }}
    >
      <Form
        form={form}
        name="newEditSubCategoryForm"
        className="standard-form"
        disabled={loading}
        layout="vertical"
        onFinish={onSubmit}
      >
        <Row justify="center" align="middle">
          <Col span={24}>
            <h2 className="title-modal">{subCategorySelected.id ? 'EDITAR' : 'NUEVA'} SUBCATEGORÍA</h2>

            <p style={{ color: "#676767", fontSize: "12px", lineHeight: "14px" }}>CATEGORÍA</p>
            <Form.Item name="category_id" {...config}>
              <Select
                allowClear
                size="large"
                options={categoriesToSelect ?? []}
                placeholder="Seleccione una categoria"
                disabled={subCategorySelected.id}
              />
            </Form.Item>

            <p style={{ color: "#676767", fontSize: "12px", lineHeight: "14px" }}>NOMBRE DE LA SUBCATEGORIA</p>
            <Form.Item name="name" {...config}>
              <Input
                placeholder="Mantenimiento preventivo"
                maxLength={50}
                size="large"
              />
            </Form.Item>

            <Row justify="center">
              <Button
                htmlType="submit"
                loading={loading}
                className="btn-standard-green"
                style={{ width: "250px" }}
                shape="round"
              >
                <span style={{ color: "#FFFFFF" }}>GUARDAR</span>
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default NewEditSubCategoryModal
