import { Button } from 'antd'
import useRedirectTo from '../../../../../../hooks/useRedirectTo'
import { CustomNavigate } from '../../../../../../UI/CustomNavigate'
import SettingsMenu from '../../../../../../components/SettingsMenu/SettingsMenu'
import { ADMIN_CHECK_CODES } from '../../../../../../../constants/modules/adminCheck/adminCheckCodes'
import CustomPopoverConfirm from '../../../../../../UI/CustomPopoverConfirm'

const CheckHeader = ({ activeFeatures }) => {
  const { handleRedirectTo } = useRedirectTo()

  return (
    <CustomNavigate title={
      <div className='check-header'>
        <h1>TAREAS PROGRAMADAS / <span>CHECKLIST</span></h1>
      </div>
    }>
      <div className='check-header-btns'>
        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_ACTIVITY_MONITORING)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Monitoreo de Actividades'}`}>
          <Button
            disabled={!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_ACTIVITY_MONITORING)?.enabled}
            onClick={() => handleRedirectTo('/checklist/monitoring')}
          >
            MONITOREO
          </Button>
        </CustomPopoverConfirm>
        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_NEW_TASK)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Crear tarea'}`}>
          <Button
            disabled={!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_NEW_TASK)?.enabled}
            onClick={() => handleRedirectTo('/checklist/admin/new/')}
          >
            NUEVA TAREA
          </Button>
        </CustomPopoverConfirm>
        <SettingsMenu items={[{ label: 'Categorías de Tareas Programadas', route: '/checklist/admin/categories', active: true, disabled: !activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_MANAGEMENT_TASK_CATEGORIES)?.enabled }]} />
      </div>
    </CustomNavigate>
  )
}

export default CheckHeader
