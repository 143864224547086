import axios from "axios";
import { API_URL } from "../../../../../../../config";

const employeePath = "employee";

export const getEmployees = async ({
  token,
  department_id,
  sort_by,
  sort_order,
  find_by,
  find_value,
  company_id = 1,
  limit = 50,
  skip = 0,
  isToSelect = false
}) => {
  try {
    const params = {
      company_id,
      limit,
      skip,
      department_id,
      sort_by,
      sort_order,
      find_by,
      find_value,
    };

    const response = await axios.get(`${API_URL}${employeePath}`, {
      params,
      timeout: 10000,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (isToSelect) {
      // Se prepara y moldea la data para que el customComponent Select trabaje sin problema
      const { data } = response

      const newResponse = {
        ...response,
        data: {
          ...response.data,
          data: {
            dataSource: data.data.dataSource.map((employeeData) => ({ id: employeeData.id, name: employeeData.name })),
          }
        }
      }

      return {
        success: true,
        ...newResponse
      }
    }

    return {
      success: true,
      ...response,
    };
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        ...error.response,
      };
    }
    if (error.request) {
      return {
        success: false,
        status: error.code,
        data: {
          errors: [{ msg: error.message }],
        },
      };
    }
  }
};
