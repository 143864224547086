import './styles.css'
import CustomTable from "../../../../UI/CustomTable/CustomTable"
import useUserRoles from "../../hooks/useUserRoles"
import NewRoleModal from "./components/NewRoleModal"
import { useEffect } from "react"
import UserRolesHeader from "./components/UserRolesHeader"

//Renderiza la página que contiene los filtros de la tabla de roles y la tabla en sí, y que contiene el modal de un nuevo rol
const UserRolesPage = () => {

    const { showModal, columns, userRolesTable, paginationTable, loading, handleChangeCompanyId, handleChangeTable, handleSearchRol, handleOpenCloseModal, getInitialData, onSubmit, getRolesService, form, Form, rolesItem } = useUserRoles();

    useEffect(() => {
        getInitialData();
    }, []);

    return (
        <section className="section-wrapper">
            <UserRolesHeader handleOpenCloseModal={handleOpenCloseModal} handleChangeSearchInput={handleSearchRol} handleChangeCompany={handleSearchRol} rolesItem={rolesItem} />
            <main style={{ marginTop: 64 }}>
                <CustomTable loading={loading} dataSource={userRolesTable.dataTable} columns={columns} className="custom-table-variant-2" onChange={handleChangeTable}
                    pagination={paginationTable} />
            </main>
            <NewRoleModal loading={loading} handleChangeCompanyId={handleChangeCompanyId} service={getRolesService} rolesItem={rolesItem} isOpenModal={showModal} handleOpenCloseModal={handleOpenCloseModal} onSubmit={onSubmit} form={form} Form={Form} />
        </section>
    )
}

export default UserRolesPage