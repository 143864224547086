import { useContext } from 'react'
import { Col, Row, Form } from 'antd'
import useGeneral from '../hooks/useGeneral'
import { config } from '../../../../../../constants/constants'
import { CustomSelect } from './../../../../../../UI/CustomSelect'
import calendar from '../../../../../../../assets/icons/calendarIcon.svg'
import { CreateTicketByCheckListContext } from '../../../context/CreateTicketByCheckListContext'

import './styles.css'
import { useAppSelector } from '../../../../../../../hooks/store'

const AssignTicketSection = ({ form }) => {
  const { Item } = Form

  const { currencySymbol } = useAppSelector(state => state.auth)
  const { categories, subCategories, setSubCategories, getEmployees } = useGeneral()
  const { totalCostPreview, averageTime } = useContext(CreateTicketByCheckListContext)
  
  // Funcion que filtra las subcategorias dependiendo de la categoria seleccionada
  const handleOnChangeSubCategories = (idCategorySelected) => {
    if (!idCategorySelected) {
      form.resetFields(["subcategory_id"]);
      setSubCategories([])
      return
    }
    form.resetFields(["subcategory_id"]);

    const subCategoryFiltered = categories?.find((category) => category.id === idCategorySelected)
  
    setSubCategories(subCategoryFiltered?.TICKET_SUBCATEGORies)
  }

  return (
    <div style={{background:'#fff', padding: '2rem', borderRadius:'8px'}} >
      <h2 style={{fontSize:'16px', fontWeight:'bold'}}>Asignación de ticket</h2>
        <Row justify='space-between' align='middle' gutter={10}>
          <Col span={5}>
            <label style={{ color:'#676767', fontSize:'12px' }}>
              CATEGORÍA
            </label>
            <Item
              name="category_id"
              style={{ marginRight:'1rem', marginTop: 8 }}
              {...config}
            > 
              <CustomSelect
                allowClear
                options={categories}
                placeholder="Categorías"
                onChange={handleOnChangeSubCategories}
              />
            </Item>
          </Col>

          <Col span={5}>
            <label style={{color:'#676767', fontSize:'12px'}} >
              SUBCATEGORÍA
            </label>

            <Item
              name="subcategory_id"
              style={{ marginRight:'1rem', marginTop: 8 }}
              {...config}
            >
              <CustomSelect
                allowClear
                options={subCategories}
                placeholder="Subcategorías"
              />
            </Item>
          </Col>

          <Col span={5}>
            <label style={{ color:'#676767', fontSize:'12px' }}>
              RESPONSABLE
            </label>

            <Item
              name="employee_id"
              style={{ marginRight:'1rem', marginTop: 8 }}
              {...config}
            >
                <CustomSelect
                  isAsync
                  allowClear
                  service={getEmployees}
                  placeholder="Empleados"
                />
            </Item>
          </Col>

          <Col span={5}>
            <Item style={{flex: 0.5, marginRight:'1rem' }}>
              <div>
                <p style={{color:'#676767', fontSize:'12px'}}>TIEMPO ESTIMADO DE REPARACIÓN</p>
                <Row  justify='start'
                  style={{
                    width: "100%",
                    background: "#F6F6F6",
                    borderRadius: '5px',
                    height:'3rem',
                    padding: "0.7rem 1rem",
                  }}
                >
                  <img style={{ marginBottom: "0.85rem" }} src={calendar} alt="" />
                  <p style={{color:averageTime ? '#000': '#A1A1A1', marginTop:'0.2rem', marginLeft:'2rem', fontWeight:averageTime ?'bold' : 'initial'}}>{averageTime ? averageTime + " Horas" : 'Seleccionar rango'}</p>
                </Row>
              </div>
            </Item>
          </Col>

          <Col span={4}>
            <Row justify='center' align='middle' style={{ flex: 0.2, border: '1px solid #000', padding: '1rem 0 0 0', borderRadius: '16px' }}>
              <Col>
                <p style={{ fontSize:'12px'}}>Costo Total</p>

                <Row justify='center'>
                  <p style={{fontSize:'20px', fontWeight:'bold'}}>
                    {currencySymbol ?? "$"} {totalCostPreview.toFixed(2)}
                  </p>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
    </div>
  )
};

export default AssignTicketSection;
