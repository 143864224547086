import { useState } from "react"
import { Button, Form, Row, Input, Col } from "antd"
import CustomModal from "../../../../../UI/CustomModal"
import { useAppSelector } from "../../../../../../hooks/store"
import useCheckListCategory from "../../../hooks/useCheckListCategory"
import useCurrentLocation from "../../../../../hooks/useCurrentLocation"

const EditCategoryModal = ({ form, onCancel, isOpenModal, editCategory, setEditCategory, allCategoryCheckList }) => {
  const { Item } = Form
  const [loading, setLoading] = useState(false)

  const { param } = useCurrentLocation()
  const { onSaveChecklistCategory } = useCheckListCategory()

  const { currentRol } = useAppSelector((state) => state.auth)

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      form.resetFields()
    
      onCancel()
      await allCategoryCheckList(param.checklistId)
      setEditCategory({})
    }
  }

  const onSubmit = async (values) => {
    setLoading(true)

    const trimCategory = values.category.trim()
    
    const dataToSend = {
      company_id: currentRol,
      checklist_id: param.checklistId,
      checklist_category_id: editCategory.id,
      name: trimCategory
    }
    
    const response = await onSaveChecklistCategory(dataToSend)
    
    await onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal
      destroyOnClose
      onCancel={() => {
        setEditCategory({})
        onCancel()
      }}
      isOpenModal={isOpenModal}
    >
      <h2 style={{ textAlign: "center" }}>EDITAR CATEGORÍA</h2>

      <Form
        form={form}
        layout="vertical"
        disabled={loading}
        onFinish={onSubmit}
        className="standard-form"
        name="editCategoryChecklistForm"
      >
        <Row>
          <Col span={24}>
            <Item
              name="category"
              rules={[
                { required: true, message: "Por favor llenar el campo" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value.trim() === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("El campo no debe contener espacios al principio o al final"));
                  },
                }),
              ]}
            >
              <Input />
            </Item>

            <Row justify="center">
              <Button
                type="text"
                htmlType="submit"
                className="btn-standard-green"
                shape="round"
                loading={loading}
                disabled={loading}
              >
                <span>EDITAR CATEGORÍA</span>
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>     
    </CustomModal>
  )
}

export default EditCategoryModal;
