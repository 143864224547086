import { pdf } from "@react-pdf/renderer"

export const getFileSizeOnline = async ({ item, url }) => {
    try {
        const res = await fetch(url, { method: 'HEAD' })
        const size = res.headers.get('Content-Length')
        if (!size) throw new Error('No se puede determinar el tamaño en bytes del archivo')
        const newSize = getFileSizeLocal(size)
        return { ...item, size: newSize }

    } catch (error) {
        console.error('Error', error)
        return null
    }
}

export const getFileSizeLocal = (size, fixed = 2) => {
    if ((size / 1024) < 1000) return `${(size / 1024).toFixed(fixed)}KB`
    if ((size / 1024 / 1024) < 1000) return `${(size / 1024 / 1024).toFixed(fixed)}MB`
    return `${(size / 1024 / 1024 / 1024).toFixed(fixed)}GB`
}

export const svgToImage = async (svg) => {
    return new Promise((resolve, reject) => {
        const svgBlob = new Blob([svg.outerHTML], { type: 'image/svg+xnl' })
        const url = URL.createObjectURL(svgBlob)
        const img = new Image()

        img.onload = () => {
            URL.revokeObjectURL(url)
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height

            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0)
            canvas.toBlob((blob) => {
                resolve(blob)
            }, 'image/png')
        }
        img.onerror = reject
        img.src = url
    })
}

export const pdfToBlob = async ({ pdfChildren, setPdfUrl }) => {
    const doc = pdfChildren
    const asPdf = pdf([])
    asPdf.updateContainer(doc)
    const blob = await asPdf.toBlob()
    const url = URL.createObjectURL(blob)
    setPdfUrl(url)
}

//Para mostrar un pdf
//<iframe src={pdfUrl} width={'100%'} height={600} title="pdf" />