import React from 'react'
import { Button, Col, Row } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { CustomNavigate } from '../../../UI/CustomNavigate'
import CustomPopoverConfirm from '../../../UI/CustomPopoverConfirm'
import { TICKETS_CODES } from '../../../../constants/modules/tickets/ticketsCodes'

const HeaderActions = ({ handleOpenCloseModal, form, handleOpenCloseCategoryModal, activeFeatures }) => {
  const onReset = () => form.resetFields()

  const handleResetFormSubCategoryModal = () => {
    onReset()
    handleOpenCloseModal()
  }

  const handleResetFormCategoryModal = () => {
    onReset()
    handleOpenCloseCategoryModal()
  }

  return (
    <header>
      <CustomNavigate title={'ADMINISTRACIÓN DE CATEGORÍAS DE TICKETS'}>
        <Row gutter={12}>
          <Col span={12}>
            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_NEW_CATEGORY_TICKET)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nueva categoría'}`}>
              <Button
                disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_NEW_CATEGORY_TICKET)?.enabled}
                type="primary"
                htmlType="button"
                shape="round"
                className='btn-standard-white'
                onClick={handleResetFormCategoryModal}
                icon={<PlusCircleOutlined style={{ fontSize: "14px" }} />}
              >
                <span>NUEVA CATEGORÍA</span>
              </Button>
            </CustomPopoverConfirm>
          </Col>

          <Col span={12}>
            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_NEW_SUBCATEGORY_TICKET)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nueva subcategoría'}`}>
              <Button
                disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_NEW_SUBCATEGORY_TICKET)?.enabled}
                type="primary"
                htmlType="button"
                shape="round"
                className='btn-standard-green'
                onClick={handleResetFormSubCategoryModal}
                icon={<PlusCircleOutlined style={{ fontSize: "14px" }} />}
              >
                <span>NUEVA SUBCATEGORÍA</span>
              </Button>
            </CustomPopoverConfirm>
          </Col>
        </Row>
      </CustomNavigate>
    </header>
  )
}

export default HeaderActions