import { useState } from "react"
import { chartDataProcessed } from "../../../../../helpers/charts"
import { labelColors } from "../../../../../mocks/barColors"
import { useAppSelector } from "../../../../../../../../hooks/store"

export const useSurveyAnswers = ({ chartData }) => {

    const theme = useAppSelector(state => state.theme)
    const qualifyLabelColor = theme?.themeColors?.primary?.primary ?? '#10B48C'
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isAnswerProfileModal, setIsAnswerProfileModal] = useState(false)
    const [moreAnswersData, setMoreAnswersData] = useState()
    const [profileAnswersData, setProfileAnswersData] = useState()
    const questions = chartDataProcessed(chartData)
    const handleBarClick = (questionIndex, barIndex) => {
        const question = questions[questionIndex];
        const profiles = question.answers.filter((answer) => answer.response === question.labels[barIndex]).map((answer) => answer.profile)
        setProfileAnswersData({ profiles, label: question.labels[barIndex], type: question.type, question: question.question, order: question.order, barIndex, questionIndex })
        setIsAnswerProfileModal(true)
    }

    const checkUpdatesModal = () => {
        const prevQuestions = structuredClone(questions)
        if (moreAnswersData && isOpenModal) {
            const prevMoreAnswersData = { ...moreAnswersData }
            const newData = prevQuestions.find((answer) => answer.idR === prevMoreAnswersData.idR)
            //Verificar si los datos han diferido respecto a la ultima actualización de firebase antes de actualizar el estado
            if (JSON.stringify(prevMoreAnswersData) !== JSON.stringify(newData)) setMoreAnswersData(newData)
        }
        if (profileAnswersData && isAnswerProfileModal) {
            const prevProfileAnswerData = { ...profileAnswersData }
            const question = prevQuestions[prevProfileAnswerData.questionIndex];
            const profiles = question.answers.filter((answer) => answer.response === question.labels[prevProfileAnswerData.barIndex]).map((answer) => answer.profile)
            const finalProfileAnswersData = { profiles, label: question.labels[prevProfileAnswerData.barIndex], type: question.type, question: question.question, order: question.order, barIndex: prevProfileAnswerData.barIndex, questionIndex: prevProfileAnswerData.questionIndex }
            //Verificar si los datos han diferido respecto a la ultima actualización de firebase antes de actualizar el estado
            if (JSON.stringify(prevProfileAnswerData) !== JSON.stringify(finalProfileAnswersData)) setProfileAnswersData(finalProfileAnswersData)
        }
    }

    const chartOptions = ({ type }) => ({
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                formatter: (value, context) => {
                    const sum = context.dataset.data.reduce((acc, v) => acc + v, 0)
                    const percentage = `${(value / sum * 100).toFixed(1)}%`
                    return value ? `${value} ${(percentage) ? `- ${percentage}` : ''} ` : '0'
                },
                font: {
                    weight: 'bold'
                },
                color: (context) => {
                    return type === 'qualify' ? qualifyLabelColor : labelColors
                },
            },
            legend: {
                display: false
            },
        },
        responsive: true,
    })
    return (
        { questions, isOpenModal, isAnswerProfileModal, moreAnswersData, profileAnswersData, setIsAnswerProfileModal, setProfileAnswersData, setIsOpenModal, setMoreAnswersData, handleBarClick, chartOptions, checkUpdatesModal }
    )
}

export default useSurveyAnswers