import moment from 'moment';
import { Button, Badge } from 'antd'
import { useAppSelector } from '../../../../../hooks/store';
import { verifyResponse } from "../../../../helpers/verifyResponse";
import { useAdminCheckContext } from "../contexts/AdminCheckContext";
import CustomPopoverConfirm from '../../../../UI/CustomPopoverConfirm';
import { getAllTasks } from "../services/allCheckLists/GET/getAllTasks";
import { getColumnsFilter } from '../../../../helpers/customTableHelper';
import { getTableDataHelper } from '../../../../helpers/getTableDataHelper';
import { columnsOrderedTable } from '../../../../helpers/columnsTableHelper';
import { getSummaryTasks } from "../services/allCheckLists/GET/getSummaryTasks";
import { ADMIN_CHECK_CODES } from '../../../../../constants/modules/adminCheck/adminCheckCodes';

// imagenes o archivos
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit-icon.svg'
import { ReactComponent as NewMessageIcon } from '../../../../../assets/icons/newMessageIcon.svg'
import { ReactComponent as NotMessageIcon } from '../../../../../assets/icons/notMessageIcon.svg'

export const useAllCheckList = () => {
  const {
    categories,
    employees,
    auth,
    form,
    Form,
    loading,
    activeCard,
    summary,
    tasks,
    currentPageTable,
    currentParams,
    limitTable,
    quantityTable,
    columnsTasks,
    setColumnsTasks,
    setMetaDataTable,
    setLastSkip,
    setCurrentPageTable,
    setCurrentParams,
    setTasks,
    handleRedirectTo,
    setLoading,
    setActiveCard,
    setSummary,
    getCategoriesService,
    getEmployeesService,
  } = useAdminCheckContext();

  const currentCompanyId = useAppSelector((state) => state?.auth?.currentRol)
  const { activeFeatures } = useAppSelector(state => state.roles)
  /****Funciones de los servicios*****/
  const getAllTaskService = async ({ formData = { status: 'inProgress' }, skip = 0, last }) => {
    setLoading(true);
    const res = await getAllTasks({ token: auth.idToken, formData, skip: last ? 0 : skip, limit: 10, company_id: currentCompanyId });
    const statusRes = verifyResponse({
      dataResponse: res.data,
      statusResponse: res.status,
      message: "Tareas obtenidas",
      showToast: false,
    });
    setLoading(false);
    if (statusRes) {
      const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata })
      setTasks(data.dataSource)
      setColumnsTasks(data.columns)
      setMetaDataTable(metadata)
      setLastSkip(skip)
      setCurrentParams(formData);
      return res.data.data;
    }
    throw new Error("Error");
  };

  const getSummaryTasksService = async () => {
    setLoading(true);
    const res = await getSummaryTasks({ token: auth.idToken, company_id: currentCompanyId });
    const status = verifyResponse({
      dataResponse: res.data,
      statusResponse: res.status,
      message: "Tipos de tareas obtenidos",
      showToast: false,
    });
    setLoading(false);
    if (status) {
      setSummary(res.data.data);
      return res.data.data;
    }
    throw new Error("Error");
  };

  /****Funciones para el formulario*****/
  const handleActiveCard = ({ code }) => {
    setActiveCard(code);
  };

  const onSubmit = async (formData) => {
    //Omitir la propiedad dates del objeto formData
    formData.status = activeCard;
    if (formData.dates !== null && formData.dates !== undefined) {
      formData.start_date = moment(formData?.dates[0]).format("YYYY-MM-DD");
      formData.end_date = moment(formData?.dates[1]).format("YYYY-MM-DD");
    }

    let newFormData = {};
    //Sanitizar valores nulos o undefined
    for (const key in formData) {
      if (formData[key] === undefined || key === "dates" || formData[key] === '') {
        continue
      }
      newFormData[key] = formData[key];
    }
    setCurrentPageTable(1);
    await getAllTaskService({ formData: newFormData, skip: 0 });
  }

  /****Funciones para la tabla****/
  const handleNextRoutePage = ({ record, location }) => {
    handleRedirectTo(`/checklist/admin/${location}/${record.id}`)
  }

  const handleChangeTable = async ({ pagination, filters, sorter, last, page }) => {
    const pageValue = page || pagination.current;
    const newSkip = pageValue * limitTable - limitTable;
    const newParams = { ...currentParams }
    pagination?.current && setCurrentPageTable(pageValue);
    await getAllTaskService({ token: auth.idToken, formData: newParams, skip: newSkip, last });
  }

  const columnsFilterObject = {
    default: () => { }
  }

  const tempColumns = [
    {
      title: "TAREA",
      dataIndex: "task",
      key: "task",
    },
    {
      title: "PERIODO",
      dataIndex: "frequency",
      key: "frequency",
    },
    {
      title: "CATEGORIA",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "ENCARGADO",
      dataIndex: "assignees",
      key: "assignees",
      ellipsis: true,
      render: (text, record) => {
        return <span style={{ color: text?.toLowerCase() === 'sin asignar' ? '#FF2E2E' : '', fontWeight: text?.toLowerCase() === 'sin asignar' ? 600 : 400 }}>{text}</span>
      }
    },
    {
      dataIndex: "edit",
      key: "edit",
      render: (text, record) => (
        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_EDIT_TASK)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Editar'}`}>
          <Button
            disabled={!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_EDIT_TASK)?.enabled} 
            onClick={() => handleNextRoutePage({ record, location: 'edit' })} className="btn-edit" icon={<EditIcon className="btn-icon" />}
          >
            Editar
          </Button>
        </CustomPopoverConfirm>),
    },

    {
      dataIndex: "comments",
      key: "comments",
      align: 'center',
      width: '7%',
      render: (text, record) => (
        <CustomPopoverConfirm trigger={'hover'} placement={'left'} content={`${!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_COMMENTS)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Ver comentarios'}`}>
          <Button
            disabled={!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_COMMENTS)?.enabled}
            className='admincheck-table__btn-comments' onClick={() => record.comments ? handleNextRoutePage({ record, location: 'comment' }) : {}}
            icon={record.comments ? <NewMessageIcon className='btn-svg-icon' /> : <NotMessageIcon />}
          >
            <Badge
              className='admincheck-table__btn-comments__badge'
              size='small'
              offset={[-20, -3]}
              showZero
              overflowCount={99}
              count={record.comments ? record.comments : 0}
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent'
              }}
            />
          </Button>
        </CustomPopoverConfirm>
      ),
    },
  ]
  const columns = columnsTasks.length ? columnsOrderedTable({ defaultColumns: tempColumns, backendColumns: columnsTasks, titleUpperCase: true }) : tempColumns

  const taskColumns = columns.map((column) => ({
    ...column,
    ...getColumnsFilter({ column, columnsFilterObject })
  }))

  return {
    categories,
    employees,
    Form,
    form,
    activeCard,
    loading,
    tasks,
    summary,
    taskColumns,
    limitTable,
    quantityTable,
    currentPageTable,
    activeFeatures,
    setCurrentPageTable,
    onSubmit,
    handleActiveCard,
    handleChangeTable,
    getAllTaskService,
    getSummaryTasksService,
    getCategoriesService,
    getEmployeesService,
  };
};
