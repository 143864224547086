import { Button, Col, Row } from 'antd'
import { ReactComponent as ArrowLeft } from '../../../../../../assets/icons/arrow-left-variant-1.svg'
import { ReactComponent as ArrowRight } from '../../../../../../assets/icons/arrow-right-variant-1.svg'
import FilterCalendar from './FilterCalendar'
import '../../styles.css'

const HeaderCalendar = ({ data, value, onChange, handleChangeMonthCalendar, handleProviderChange, providerService, providersTable, handleChangeAccessStatus, currentParams }) => {

  const startOfMonth = value.clone().startOf('month');

  const prevMonth = async () => {
    const date = value.clone().subtract(1, 'month')
    onChange(date);
    await handleChangeMonthCalendar({ date })
  };

  const nextMonth = async () => {
    const date = value.clone().add(1, 'month')
    onChange(date);
    await handleChangeMonthCalendar({ date })
  };
  return (
    <Row
      className="accessProviderCalendar__custom-header"
      justify="space-between"
      style={{ padding: 8 }}
    >
      <Row justify="space-between" align="middle" gutter={16}>
        <Col>
          <Button onClick={prevMonth} style={{ border: 'none', boxShadow: 'none' }} icon={<ArrowLeft />} />
        </Col>
        <Col className="accessProviderCalendar__header-date">
          <span >{startOfMonth.format('MMMM YYYY')}</span>
        </Col>
        <Col>
          <Button icon={<ArrowRight />} style={{ border: 'none', boxShadow: 'none' }} onClick={nextMonth} />
        </Col>
      </Row>
      <FilterCalendar currentParams={currentParams} calendarData={data} providersTable={providersTable} providerService={providerService} handleProviderChange={handleProviderChange} handleChangeAccessStatus={handleChangeAccessStatus} />
    </Row>
  )
}

export default HeaderCalendar