import { Button, Divider, Input } from "antd";
import { CustomCollapse } from "../../../../../../../../../UI/CustomCollapse";
import { CustomSelect } from "../../../../../../../../../UI/CustomSelect";
import { ScheduleInfo } from "./components/ScheduleInfo";
import { EndCheckListOptions } from "./components/EndCheckListOptions";
import { useNewCheckList } from "../../../../../../hooks/useNewCheckList";
import { dropdownSelectIcon2 } from "../../../../../../../assets/icons";
import { useEffect } from "react";

export const EmployeeSchedule = ({
  Form,
  config,
  fields,
  field,
  remove,
}) => {
  const {
    employees,
    activeKey,
    employeeIndex,
    getEmployeesService,
    checkLength,
    setEmployees,
    handleOpenCloseCollapse,
    handleOnChangeEmployee,
    handleGetWeekDays,
    handleChangeTimePicker,
  } = useNewCheckList();

  useEffect(() => {
    checkLength({ fields, field });
  }, [fields.length]);

  return (
    <CustomCollapse
      collapseKey={field.name}
      activeKey={
        activeKey.find((item) => item.collapseKey === field.key)?.value
      }
      onChange={() =>
        handleOpenCloseCollapse({
          collapseKey: field.key,
          collapseName: field.name,
        })
      }
      defaultActive={"1"}
      headerChildren={
        <>
          <p>Horario</p>
          <Divider
            orientationMargin={0}
            orientation="left"
            className="newChecklist__custom-divider"
          >
            <span style={{ color: "var(--primary)" }}>{employeeIndex.find((item) => item.key === field.name && item.employee_id != null)?.index || field.name + 1}° Empleado</span>
          </Divider>
        </>
      }
    >
      <Form.Item initialValue={null} name={[field.name, "task_employee_id"]} noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item initialValue={[]} name={[field.name, "deleted_days"]} noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item
        name={[field.name, "employee_id"]}
        label="Empleado"
        rules={[{ required: true }]}
      >
        <CustomSelect
          suffixIcon={dropdownSelectIcon2}
          onChange={() => {
            handleGetWeekDays({});
            handleOnChangeEmployee({ field });
          }}
          options={employees}
        />
      </Form.Item>
      <ScheduleInfo Form={Form} field={field} />
      <EndCheckListOptions Form={Form} field={field} config={config} />
      {fields.length && (
        <Button
          style={{ marginTop: 16 }}
          shape="round"
          className="btn-standard-red"
          onClick={() => {
            handleChangeTimePicker({ field });
            handleOpenCloseCollapse({
              collapseKey: field.key,
              collapseName: field.name,
              pop: true,
            });
            remove(field.name);
            handleGetWeekDays({});
            handleOnChangeEmployee({ field, pop: true })
          }}
        >
          ELIMINAR TURNO
        </Button>
      )}
    </CustomCollapse>
  );
};
