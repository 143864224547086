const VisionEyeIcon = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="22" height="22" fill="white" fillOpacity="0.01" />
        <mask id="mask0_0_1316" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
            <rect width="22" height="22" fill="white" />
        </mask>
        <g mask="url(#mask0_0_1316)">
            <path d="M1.375 4.8125C1.7547 4.8125 2.0625 4.5047 2.0625 4.125V2.0625H4.125C4.5047 2.0625 4.8125 1.7547 4.8125 1.375C4.8125 0.995307 4.5047 0.687502 4.125 0.6875H1.375C0.995304 0.6875 0.6875 0.995304 0.6875 1.375V4.125C0.6875 4.5047 0.995304 4.8125 1.375 4.8125V4.8125Z" fill="var(--primary)" />
            <path d="M17.875 2.0625H19.9375V4.125C19.9375 4.5047 20.2453 4.8125 20.625 4.8125C21.0047 4.8125 21.3125 4.5047 21.3125 4.125V1.375C21.3125 0.995304 21.0047 0.6875 20.625 0.6875H17.875C17.4953 0.6875 17.1875 0.995303 17.1875 1.375C17.1875 1.75469 17.4953 2.0625 17.875 2.0625H17.875Z" fill="var(--primary)" />
            <path d="M4.125 19.9375H2.0625V17.875C2.0625 17.4953 1.7547 17.1875 1.375 17.1875C0.995307 17.1875 0.687502 17.4953 0.6875 17.875V20.625C0.6875 21.0047 0.995304 21.3125 1.375 21.3125H4.125C4.5047 21.3125 4.8125 21.0047 4.8125 20.625C4.8125 20.2453 4.5047 19.9375 4.125 19.9375H4.125Z" fill="var(--primary)" />
            <path d="M20.625 17.1875C20.2453 17.1875 19.9375 17.4953 19.9375 17.875V19.9375H17.875C17.4953 19.9375 17.1875 20.2453 17.1875 20.625C17.1875 21.0047 17.4953 21.3125 17.875 21.3125H20.625C21.0047 21.3125 21.3125 21.0047 21.3125 20.625V17.875C21.3125 17.4953 21.0047 17.1875 20.625 17.1875V17.1875Z" fill="var(--primary)" />
            <circle cx="11" cy="11" r="2.75" fill="var(--primary)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21.2364 11.3162C21.3391 11.1179 21.3391 10.8821 21.2364 10.6837C19.3192 6.75509 15.3698 4.22475 10.9995 4.125C6.62912 4.22475 2.67978 6.75509 0.762601 10.6838C0.659861 10.8821 0.659862 11.1179 0.762603 11.3162C2.67978 15.2449 6.62912 17.7753 10.9995 17.875C15.3698 17.7753 19.3192 15.2449 21.2364 11.3162V11.3162ZM10.9995 15.125C8.72134 15.125 6.87452 13.2782 6.87451 11C6.87451 8.72183 8.72133 6.87501 10.9995 6.875C13.2777 6.87499 15.1245 8.72181 15.1245 11C15.1245 13.2782 13.2777 15.125 10.9995 15.125H10.9995Z" fill="var(--primary)" />
        </g>
    </svg>
)

export default VisionEyeIcon
