import "./styles.css"
import { Button, Row, Switch, Col } from "antd"
import { useState, useContext, useEffect } from "react"
import { CustomSpin } from "../../UI/CustomSpin"
import useTicketById from "./hooks/useTicketById"
import { PDFDownloadLink } from "@react-pdf/renderer"
import CustomCountDown from "../../UI/CustomCountDown"
import { CustomNavigate } from "../../UI/CustomNavigate"
import DetailTicketPdf from "./Components/DetailTicketPdf"
import { useFeatureRole } from "../../hooks/useFeatureRole"
import useOpenCloseModal from "../../hooks/useOpenCloseModal"
import CustomFilePdf from "../../UI/CustomFiles/CustomFilePdf"
import CustomPopoverConfirm from "../../UI/CustomPopoverConfirm"
import CancelTicketModal from "./Components/modal/CancelTicketModal"
import { CountDownContext } from "../../../context/CountDownContext"
import { TICKETS_CODES, TICKETS_CODES_NAME } from "../../../constants/modules/tickets/ticketsCodes"

// imagenes o archivos
import { PrinterOutlined, DeleteOutlined, } from "@ant-design/icons"
import { getCategoriesTicketThunk, getEmployeeThunk } from "../../../store/detailTicket/thunk"
import { useAppDispatch, useAppSelector } from "../../../hooks/store"

const DetailTicket = () => {
  const dispatch = useAppDispatch()
  const [timeLeft, setTimeLeft] = useState(600)
  
  const { currentRol } = useAppSelector(state => state.auth)
  const { handleIsOpenCloseModal } = useContext(CountDownContext)
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  
  const { currentBody, disabled, verifyBody, onChange, handleCurrentBody, ticketById, flags, getDetailTicket, loading, statusRoad, } = useTicketById();

  useEffect(() => {
    getDetailTicket();
    dispatch(getCategoriesTicketThunk(currentRol))
    dispatch(getEmployeeThunk(currentRol))
  }, [ticketById?.id]);

  const handleModalOpen = () => {
    handleOpenCloseModal();
    handleIsOpenCloseModal();
  };
  const handleModalClose = () => {
    handleIsOpenCloseModal();
    handleOpenCloseModal();
  };

  const { activeFeatures, loadingFeatures } = useFeatureRole({ feature_codes: TICKETS_CODES, validate_code: TICKETS_CODES_NAME.TICKETS_DETAIL_TICKET, returnUrl: '/menu/specialModules' })

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  const handleOnRefreshDetailTicketClick = () => {
    getDetailTicket();
    setTimeLeft(600);
  };

  return (
    <div className="section-wrapper">
      <CustomNavigate title={
        <Row align="middle">
          <Row align="middle">
            <Button
              onClick={() => handleCurrentBody(false)}
              style={{
                border: "1px solid #cbcbcb",
                paddingInline: "20px",
                paddingBlock: "2px",
                background: `${!currentBody ? '#fff' : '#eaf0f1'}`,
                borderRadius: "8px",
                marginRight: "0.5rem",
                minHeight: 43
              }}
            >
              Ticket: <span style={{ color: "var(--primary)" }}> {ticketById?.code}</span>
            </Button>
            <div className="hide-element" style={{ height: 32, display: 'flex', alignItems: 'center' }}>
              <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_DOCUMENTS_TICKET)?.enabled ? 'No tienes permiso para realizar esta acción' : "Ver documentos"}`}>
                <Button
                  disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_DOCUMENTS_TICKET)?.enabled}
                  onClick={() => handleCurrentBody("documents")}
                  style={{ borderRadius: "8px", marginRight: "0.5rem", border: "1px solid #9f9f9f", height: '43px', background: `${currentBody === 'documents' ? '#fff' : '#eaf0f1'}`, minWidth: 157 }}
                >
                  Documentos
                </Button>
              </CustomPopoverConfirm>
            </div>
            {ticketById?.CHECKLIST_INSTANCE && <div className="hide-element" style={{ height: 32, display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={() => handleCurrentBody("requirements")}
                style={{ borderRadius: "8px", marginRight: "0.5rem", border: "1px solid #9f9f9f", height: '43px', background: `${currentBody === 'requirements' ? '#fff' : '#eaf0f1'}`, minWidth: 157 }}
              >
                Requerimientos
              </Button>
            </div>}
            <div className="hide-element" style={{ height: 32, display: 'flex', alignItems: 'center' }}>
              <PDFDownloadLink
                document={<CustomFilePdf>
                  <DetailTicketPdf detailTicketData={ticketById} statusRoad={statusRoad} />
                </CustomFilePdf>}
                fileName={`Ticket: ${ticketById?.code}`}
              >
                {({ loading }) => (
                  <Button
                    type="link"
                    shape="round"
                    loading={loading}
                    icon={<PrinterOutlined style={{ color: "#000000", fontSize: 16 }} />}
                  >
                    <span style={{ color: "#444444" }}>Exportar</span>
                  </Button>
                )}
              </PDFDownloadLink>
            </div>
          </Row>
        </Row>
      }>
        <Row align="middle">
          <Row>
            <CustomCountDown
              isBtn
              classNameProp={"btnCounterDown"}
              labelBtn="Actualizar"
              setTimeLeft={setTimeLeft}
              functionToExecute={handleOnRefreshDetailTicketClick}
              onClickFunction={handleOnRefreshDetailTicketClick}
              timeLeft={timeLeft}
              initialTime={600}
            />
          </Row>

          {flags?.enabledButtonCancel && (
            <>
              <div >
                <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_REJECT_STATUS_TICKET)?.enabled ? 'No tienes permiso para realizar esta acción' : "Cancelar ticket"}`}>
                  <Button
                    disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_REJECT_STATUS_TICKET)?.enabled}
                    onClick={handleModalOpen}
                    danger
                    style={{
                      color: "red",
                      border: "none",
                      background: "none",
                    }}
                    type="ghost"
                  >
                    Cancelar ticket
                    <DeleteOutlined />
                  </Button>
                </CustomPopoverConfirm>
              </div>
              <div >
                <Col style={{ marginInline: "1rem" }}>Revisado</Col>
              </div>
              <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_REVIEWED_STATUS_TICKET)?.enabled ? 'No tienes permiso para realizar esta acción' : "Revisar ticket"}`}>
                <Switch
                  checked={disabled}
                  disabled={disabled || !activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_REVIEWED_STATUS_TICKET)?.enabled}
                  onChange={onChange}
                />
              </CustomPopoverConfirm>
            </>
          )}
        </Row>
      </CustomNavigate>

      {loading ? <CustomSpin section size="large" /> : verifyBody(currentBody)}

      <CancelTicketModal
        isOpenModal={isOpenModal}
        onCancel={handleModalClose}
      />
    </div>
  );
};

export default DetailTicket;
