import axios from "axios";
import { API_URL } from "../../../../../../../config";

const detailPath = 'tasks/detail'

export const getTaskDetail = async ({token,task_id}) => {
  const params = {
    task_id
  }
  try {
    const response = await axios.get(`${API_URL}${detailPath}`, {
      params,
      timeout: 10000,
      headers:{
        Authorization:`Bearer ${token}`
      }
    });

    return {
      success: true,
      ...response,
    };
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        ...error.response,
      };
    }
    if (error.request) {
      return {
        success: false,
        status: error.code,
        data: {
          errors: [{ msg: error.message }],
        },
      };
    }
  }
};
