import { useContext, useState } from "react"
import { Button, Col, Row } from "antd"
import CustomModal from "../../../../../../UI/CustomModal"
import useAdminChecklist from "../../hooks/useAdminChecklist"
import { AdminChecklistContext } from "../../context/AdminChecklistContext"

//imagenes o archivos
import Warning from '../../../../../../../assets/icons/waring.svg'

const DeleteChecklistCategoryModal = ({
  isOpenModal,
  handleOpenCloseModal,
}) => {
  const [loading, setLoading] = useState(false)

  const { filters, checklistCategoryClicked, setChecklistCategoryClicked } = useContext(AdminChecklistContext)
  const { onDeleteChecklistCategory, getChecklistCategories } = useAdminChecklist()

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      handleOpenCloseModal()
      await getChecklistCategories({ ...filters })
    }
  }

  const onDeleteCategory = async () => {
    setLoading(true)

    const response = await onDeleteChecklistCategory({ checklist_category_id: checklistCategoryClicked?.checklist_category_id })

    await onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal
      destroyOnClose
      isOpenModal={isOpenModal}
      onCancel={() => {
        handleOpenCloseModal()
        setChecklistCategoryClicked({})
      }}
    >
      <Row>
        <Col span={24}>
          <Row justify="center">
            <img src={Warning} alt="x-icon" />
          </Row>

          <Row justify="center">
            <label style={{ textAlign: 'center', width: 416, margin: '25px 0', color: '#373737', fontWeight: 500, fontSize: 25 }}>
              ¿Seguro que deseas eliminar esta categoría?
            </label>
          </Row>

          <Row justify="center" align="middle" gutter={16}>
            <Col span={8}>
              <Row justify="center">
                <Button
                  type="text"
                  shape="round"
                  htmlType="button"
                  onClick={() => {
                    handleOpenCloseModal()
                    setChecklistCategoryClicked({})
                  }}
                  disabled={loading}
                  loading={loading}
                  style={{ height: 40, width: 132, border: 0, padding: '0px 32px 0px 32px' }}
                >
                  <span style={{ fontSize: 14, fontWeight: 500, color: '#404040' }}>
                    CANCELAR
                  </span>
                </Button>
              </Row>
            </Col>

            <Col span={8}>
              <Row justify="center">
                <Button
                  type="text"
                  shape="round"
                  htmlType="button"
                  disabled={loading}
                  loading={loading}
                  onClick={onDeleteCategory}
                  style={{ height: 40, width: 132, padding: '0px 32px 0px 32px', backgroundColor: '#FF5858' }}
                >
                  <span style={{ fontSize: 14, fontWeight: 500, color: '#FFFFFF' }}>
                    ELIMINAR
                  </span>
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </CustomModal>
  )
}

export default DeleteChecklistCategoryModal