import { Button, Col, Divider, Popconfirm } from 'antd'
import { CustomDrawer } from '../../../UI/CustomDrawer/CustomDrawer'
import { DeleteTableItemIcon } from '../assets'
import './styles.css'
import { CloseOutlined } from '@ant-design/icons'
import CustomPopoverConfirm from '../../../UI/CustomPopoverConfirm'
import { ACCESS_PROVIDERS_CODES } from '../../../../constants/modules/accessProviders/accessProvidersCodes'

const ProvidersAccessDetailDrawer = ({ isOpenDrawer, onClose, accessDetail, handleCancelAccess, loading, activeFeatures }) => (
    <CustomDrawer className='drawerAccessDetail' closeIcon={<CloseOutlined style={{ fontSize: '16px', color: '#08c' }} />} title={'DETALLE DE ACCESO'} isOpenDrawer={isOpenDrawer} onClose={() => onClose({ visible: false, name: 'accessDetailDrawer' })}>
        <Col className='main-details provider_title'>
            <span>PROVEEDOR</span>
            <strong>{accessDetail?.provider?.provider_name}</strong>
        </Col>
        <Divider />
        <Col className='main-details'>
            <Col className='detail-container'>
                <small>FECHA DE VISITA</small>
                <span>{accessDetail?.date}</span>
            </Col>
            <Col className='detail-container'>
                <small>MOTIVO DE LA VISITA</small>
                <span>{accessDetail?.reason?.reason_name}</span>
            </Col>
            <Col className='detail-container'>
                <small>{accessDetail?.employee_name ? 'EMPLEADO A CARGO DE SUPERVISIÓN' : 'UNIDAD O EMPLEADO QUE LO INVITA'}</small>
                <span>{accessDetail?.employee_name ? accessDetail?.employee_name : accessDetail?.receiver_name}</span>
            </Col>
            <Col className='detail-container'>
                <small>DESCRIPCIÓN DE SU VISITA</small>
                <span>{accessDetail?.event_description}</span>
            </Col>
        </Col>
        <Divider />
        {
            accessDetail?.assistance && (
                <Col className='main-details assistance_container'>
                    <div className="assistance">
                        <span>¿Asistió a su visita?</span>
                        <strong style={{ color: accessDetail?.assistance?.color, fontSize: 28 }}>{accessDetail?.assistance?.answer}</strong>
                    </div>
                </Col>
            )
        }
        {
            (accessDetail?.actions?.update_event || accessDetail?.actions?.cancel_event) && (
                <Col className='main-details assistance_container' style={{ gap: 24, marginTop: 32 }}>
                    {accessDetail?.actions?.update_event &&
                        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_UPDATE_ACCESS)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Actualizar visita'}`}>
                            <Button
                                disabled={!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_UPDATE_ACCESS)?.enabled}
                                onClick={() => onClose({ name: 'newAccessProviderModal', visible: true, data: { edit: true } })}
                                style={{ maxWidth: 240 }}
                                className='btn-standard-white'
                                shape='round'
                            >
                                Reprogramar Visita
                            </Button>
                        </CustomPopoverConfirm>
                    }
                    {accessDetail?.actions?.cancel_event && (
                        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_CANCEL_ACCESS)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Cancelar visita'}`}>
                            <Popconfirm icon={<DeleteTableItemIcon />}
                                disabled={!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_CANCEL_ACCESS)?.enabled}
                                okButtonProps={{ className: 'custom-popover__ok-button', shape: 'round', loading }}
                                cancelButtonProps={{ className: 'custom-popover__cancel-button', shape: 'round', disabled: loading }}
                                overlayClassName='custom-popover'
                                placement='top'
                                title={<><span>¿Seguro que quieres cancelar esta visita?</span><span>(Se cancelarán todas las visitas relacionadas a este evento)</span></>}
                                onConfirm={() => handleCancelAccess({ event_id: accessDetail?.event_id })}
                                okText="ELIMINAR"
                                cancelText="CANCELAR"
                            >
                                <Button
                                    disabled={!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_CANCEL_ACCESS)?.enabled}
                                    style={{ maxWidth: 240 }}
                                    className='btn-standard-red'
                                    shape='round'
                                >
                                    Cancelar Visita
                                </Button>
                            </Popconfirm>
                        </CustomPopoverConfirm>
                    )}
                </Col>
            )
        }
    </CustomDrawer>
)

export default ProvidersAccessDetailDrawer