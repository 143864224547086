import axios from "axios"
import { API_URL } from "../../../../config"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const getAllOrnamentStatus = async (idToken) => {
  try {
    const response = await axios.get(`${API_URL}checklist/type/ornament/instance/status/full`,
      { headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000 }
    )

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getAllOrnamentStatus
