/* eslint-disable no-undef */
import { useEffect } from "react";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLoginWithEmailPassword, changeAuthenticationStatusThunk } from "../../../store/auth";
import { defaultTemplate } from '../mocks/defaultTemplate'
import { loginTemplates } from '../mocks/templates'
import { setAllDataThemeThunk } from "../../../store/theme/thunks";
import useCurrentLocation from "../../../topia/hooks/useCurrentLocation";
import TopiaTemplate from "../components/TopiaTemplate/TopiaTemplate";
import useRedirectTo from "../../../topia/hooks/useRedirectTo";


export const useLogin = () => {
  const dispatch = useDispatch();
  const { param } = useCurrentLocation();
  const [template, setTemplate] = useState(<></>);
  const { status } = useSelector((state) => state.auth);
  const { handleRedirectTo } = useRedirectTo()

  const config = {
    rules: [{ required: true, message: "Por favor llenar el campo" }],
  };

  const isAuthenticating = useMemo(() => status === "checking", [status]);

  const onSubmit = async ({ formData, setLoading }) => {
    setLoading(true);
    await dispatch(startLoginWithEmailPassword({ ...formData, handleRedirectTo }));

    if (!status) return
    setLoading(false);
  };

  //Funcion que va a importar componentes dinámicamente
  const dynamicTemplate = async ({ idTemplateRoute }) => {
    try {
      let fullTemplateData = null;
      let module = null;
      
      if (!fullTemplateData) {//Aquí debe de traerse la data tanto codigo y nombre del tema, como la de los colores e imagenes del login
        const res = await dispatch(setAllDataThemeThunk({ idTemplateRoute }))
        //Luego de llamar al dispatch para almacenar globalmente los colores y las imagenes, se debe de actualizar la data del template
        //Se recuperan las imagenes para el template
        const templateData = res.zones.find(zone => zone.theme_zone_code === `login-${res.theme.code}-dashboard`)?.images
        //Se llama a la función, pasandole la informacion de las imagenes y la clave que es el codigo del tema, retornando un objeto con los datos finales del template
        fullTemplateData = loginTemplates(templateData)[res.theme?.code]

        //Importar el modulo que contiene la template según el codigo del tema
        module = await import(
          `../components/${res?.theme?.name}Template/${res?.theme?.name}Template`
        );
      }

      const ImportDynamicTemplate = module[`${fullTemplateData?.templateName}`];
      const finalTemplate = (
        <ImportDynamicTemplate
          isAuthenticating={isAuthenticating}
          config={config}
          onSubmit={onSubmit}
          dynamicData={fullTemplateData.templateData}
        />
      );
      setTemplate(finalTemplate);
      //Se debe mandar a llamar el dispatch aqui para almacenar los colores luego de mandar el template
    } catch (error) {
      //En algun caso de que no se cargue el template por un error de importación dinámica se colocará el de default
      setTemplate(<TopiaTemplate
        isAuthenticating={isAuthenticating}
        config={config}
        onSubmit={onSubmit}
        dynamicData={defaultTemplate().templateData}
      />)
      throw new Error(error)
    }
    finally {
      if (status === 'checking-login') {
        dispatch(changeAuthenticationStatusThunk('not-authenticated'))
      }
    }

  };

  useEffect(() => {
    dynamicTemplate({ idTemplateRoute: param?.idTemplate }); //Se pasará el id de la ruta de navegacion por parametro si existe
  }, []);

  return {
    config,
    template,
    isAuthenticating,
    onSubmit,
    status,
  };
};
