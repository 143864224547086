import { createSlice } from "@reduxjs/toolkit";
import { themeInitialState } from "../../constants/theme";

const initialState = structuredClone(themeInitialState)

//Se crea el slice para definir en un objeto los colores a utilizar como variables CSS
export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setColorsTheme: (state, { payload }) => {
            state.theme = payload?.theme
            state.themeColors = payload?.colors
        },
        setImagesTheme: (state, { payload }) => {
            state.themeImages = payload
        }
    }
})

export const { setColorsTheme, setImagesTheme } = themeSlice.actions