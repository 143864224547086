import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import moment from 'moment';

const initialState = {
    surveys: {
        data: [],
        active: null
    },
    loading: true,
    errorMessage: null
}

export const surveysSlice = createSlice({
    name: 'surveys',
    initialState,
    reducers: {
        setSurvey: (state, { payload }) => {
            state.surveys.data = payload;
        },
        createSurveys: (state, { payload }) => {
            const surversList = state.surveys.data

            payload.created_at = payload.created_at ? moment(payload.created_at).format("Y-MM-DD") : ""
            payload.startDate = payload.startDate ? moment(payload.startDate).format("DD/MM/YYYY") : ""
            payload.endDate = payload.endDate ? moment(payload.endDate).format("DD/MM/YYYY") : ""
            payload.encuestasContestadas = 0

            const indexEncuesta = surversList.findIndex((item) => item.id === payload.id)
            if (indexEncuesta !== -1) {
                surversList[indexEncuesta] = payload
                state.surveys.data = surversList
            } else {
                state.surveys.data.unshift(payload);
            }

            //state.surveys.data.unshift(payload);
            toast.success("Encuesta guardada");
        },
        setActiveSurveys: (state, { payload }) => {
            state.surveys.active = payload
        },
        setLoading: (state, { payload }) => {
            state.loading = payload.status
        },
        resetSurveys: () => initialState

    }
});


export const {
    createSurveys,
    setSurvey,
    setActiveSurveys,
    setLoading,
    resetSurveys
} = surveysSlice.actions;