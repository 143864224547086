import EmptyComponent from "../../../topia/UI/EmptyComponent"
import { useAppSelector } from "../../../hooks/store"
import MallsSvg from "./malls/sanNicolas/MallsSvg"
import { Row } from "antd"

const BuildingComponent = ({ idLevelSelected, handleOnClickToGetLocalId }) => {
  const { tagsElements } = useAppSelector(state => state.malls)

  const verifyBuildingsketch = (idLevelSelected) => {
    let objBuildingsComponents = {
      "default": () => <MallsSvg tagsElements={tagsElements} handleOnClickToGetLocalId={handleOnClickToGetLocalId} />
    }

    let buildingComponent = objBuildingsComponents[idLevelSelected] || objBuildingsComponents["default"]
    return buildingComponent()
  }

  // if (!(idLevelSelected)) return <EmptyComponent label="Por el momento no hay mapa para este nivel" />

  return (
    <Row justify="center">
      {verifyBuildingsketch(idLevelSelected)}
    </Row>
  )
}

export default BuildingComponent