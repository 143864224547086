import { Button, Col, Row } from "antd";
import React, { useContext } from "react";
import useOpenCloseModal from "../../../hooks/useOpenCloseModal";
import CategoryModal from "./modal/CategoryModal";
import { CountDownContext } from "../../../../context/CountDownContext";
import EditIcon from "../../../../assets/icons/EditIcon";
import { useAppSelector } from "../../../../hooks/store";
import { TICKETS_CODES } from "../../../../constants/modules/tickets/ticketsCodes";
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm";

const TicketCategory = ({ ticketById }) => {
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal();
  const { handleIsOpenCloseModal } = useContext(CountDownContext);
  const handleModalOpen = () => {
    handleOpenCloseModal();
    handleIsOpenCloseModal();
  };
  const { activeFeatures } = useAppSelector(state => state.roles)
  const handleModalClose = () => {
    handleIsOpenCloseModal();
    handleOpenCloseModal();
  };

  return (
    <Row
      align="middle"
      gutter={16}
      style={{
        background: "#ECECEC",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        borderRadius: 8,
        position: "relative",
        marginBlock: "2rem",
      }}
    >
      <Col md={24} xl={11} style={{ paddingTop: "1rem" }}>
        <p style={{ fontWeight: "bold", fontSize: "12px" }}>
          Categoría
          <span
            style={{
              marginLeft: "0.5rem",
              fontWeight: "normal",
              fontSize: "12px",
            }}
          >
            {ticketById?.TICKET_CATEGORY?.name}
          </span>
        </p>
      </Col>
      <Col md={22} xl={11} style={{ paddingTop: "1rem" }}>
        <p style={{ fontWeight: "bold", fontSize: "12px" }}>
          Subcategoría
          <span
            style={{
              marginLeft: "0.5rem",
              fontWeight: "normal",
              fontSize: "12px",
            }}
          >
            {ticketById?.TICKET_SUBCATEGORY?.name}
          </span>
        </p>
      </Col>
      <Col md={2} xl={2} className="hide-element">
        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_CATEGORY_TICKET)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Editar'}`}>
          <Button
            disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_CATEGORY_TICKET)?.enabled}
            onClick={handleModalOpen}
            style={{
              border: "none",
              boxShadow: 'none',
              width: '100%',
              padding: 0,
            }}
            type="ghost"
          >
            <EditIcon color="var(--primary)" width="20px" />
          </Button>
        </CustomPopoverConfirm>
      </Col>
      <CategoryModal
        ticketById={ticketById}
        isOpenModal={isOpenModal}
        onCancel={handleModalClose}
      />
    </Row >
  );
};

export default TicketCategory;
