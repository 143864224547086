import { accessModule } from "./access/accessModule";
import { accessProvidersModule } from "./accessProviders/accessProvidersModule";
import { activeMaintenanceModule } from "./activeMaintenance/activeMaintenanceModule";
import { adminCheckModule } from "./adminCheck/adminCheckModule";
import { alertsModules } from "./alerts/alertsModules";
import { amenitiesModule } from "./amenities/amenitiesModule";
import { bookingModule } from "./bookings/bookingModule";
import { bookingTuscaniaModule } from "./bookingTuscania/bookingTuscaniaModule";
import { employeeModule } from "./employee/employeeModule";
import { handOverChecklistModule } from "./handoverChecklist/handoverChecklistModule";
import { inventoryModule } from "./inventory/inventoryModule";
import { libraryModule } from "./library/libraryModule";
import { managementContractorModule } from "./managamentContractor/managementContractorModule";
import { managementSpacesModule } from "./managmentSpaces/managmentSpacesModule";
import { messagingModule } from './messaging/messagingModule'
import { newsModule } from "./news/newsModule";
import { salesStatementModule } from "./salesStatement/salesStatementModule";
import { surveysModule } from "./surveys/surveysModule";
import { ticketsModule } from "./tickets/ticketsModule";
import { userModules } from "./user/userModules";

//Este arreglo servirá  cuando se consulte el endpoint , ya que se encontrará la opción del menu y se formará un arreglo nuevo, incluyendo todos los childrens del modulo, que se guardará en la store para validar si se activa o no una feature dentro
export const modulesFeatures = [
    accessModule,
    employeeModule,
    inventoryModule,
    managementContractorModule,
    newsModule,
    handOverChecklistModule,
    adminCheckModule,
    surveysModule,
    alertsModules,
    ...ticketsModule,
    userModules,
    bookingTuscaniaModule,
    messagingModule,
    bookingModule,
    amenitiesModule,
    libraryModule,
    activeMaintenanceModule,
    accessProvidersModule,
    salesStatementModule,
    managementSpacesModule,
]