import { axiosCreate } from "../../../../config/index"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const createNewCategoryChecklist = async (idToken, data) => {
  try {
    const response = await axiosCreate.post('checklist/category', data, {
      headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000
    })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default createNewCategoryChecklist;
