import './style.css'
import CustomTabs from '../../UI/CustomTabs/CustomTabs';
import Employee from './components/Employee';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useRedirectTo from '../../hooks/useRedirectTo';
import { CustomNavigate } from '../../UI/CustomNavigate';
import EmployeeActions from './components/EmployeeActions';
import { Row } from 'antd';
import { useFeatureRole } from '../../hooks/useFeatureRole';
import { CustomSpin } from '../../UI/CustomSpin';
import { EMPLOYEE_CODES, EMPLOYEE_CODES_NAMES } from '../../../constants/modules/employee/employeeCodes';

const handleItems = ({ companyId, setIsVisible, setIsModalOpen, setDepartmentSelected, isModalOpen, isVisible, departmentSelected, activeFeatures }) => {
  const tabItems = [
    {
      label: 'Empleados',
      children: <Employee companyId={companyId} setIsModalOpen={setIsModalOpen} setIsVisible={setIsVisible} setDepartmentSelected={setDepartmentSelected} isModalOpen={isModalOpen} isVisible={isVisible} departmentSelected={departmentSelected} activeFeatures={activeFeatures} />
    }
  ]
  return tabItems
}

const EmployeedManagementPage = () => {
  const { handleRedirectTo } = useRedirectTo()
  const { roles, committee, currentRol } = useSelector(state => state.auth)
  const [isVisible, setIsVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [departmentSelected, setDepartmentSelected] = useState(null);
  const roleFiltered = roles.find((role) => role.residentialId === committee.residential_id)
  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: EMPLOYEE_CODES, validate_code: EMPLOYEE_CODES_NAMES.EMPLOYEE_MODULE, returnUrl: '/menu/settings' })
  useEffect(() => {
    if (!roleFiltered) return handleRedirectTo('/settings/user-profiles')
  }, [])

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (
    <section className='section-wrapper'>
      <CustomNavigate title={'ADMÓN. EMPLEADOS'}>
        <Row>
          <EmployeeActions
            activeFeatures={activeFeatures}
            companyId={currentRol}
            setIsModalOpen={setIsModalOpen}
            setDepartmentSelected={setDepartmentSelected}
          />
        </Row>
      </CustomNavigate>

      <CustomTabs className='custom-tabs-variant-1'
        items={handleItems({ companyId: currentRol, setIsVisible, setIsModalOpen, setDepartmentSelected, isModalOpen, isVisible, departmentSelected, activeFeatures })}
      />
    </section>
  );
};

export default EmployeedManagementPage;