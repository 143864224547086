import checkFeatures from "../../services/checkFeatures";
import verifyResponse from "../../topia/helpers/verifyResponse";
import { cleanModules, setCurrentFeature, setCurrentModule } from './rolesSlice'

//A paritr de un arreglo de feature_codes, y obteniendo del estado global de auth el idToken y el id de la cuenta se crea el json a enviar al servidor, que en caso de exito
//responde un arreglo indicando si estan habilitadas o no las features, y ese arreglo lo guarda en la store y retorna igualmente dicho arreglo
export const getFeaturesThunk = ({ featureCodes }) => {
    return async (dispatch, getState) => {
        const { idToken, currentRol } = getState().auth;

        const formData = {
            company_id: currentRol,
            feature_codes: featureCodes
        }
        const res = await checkFeatures({ formData, token: idToken })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        if (!status) {
            dispatch(setCurrentFeature([])) 
            return []
        }
        const finalFeatureCodes = res.data.data
        dispatch(setCurrentFeature(finalFeatureCodes))
        return finalFeatureCodes
    }
}

//Con la feature_code proporcionada se establece el modulo activo en el estado global
export const setCurrentModuleRoleThunk = ({ feature_code }) => {
    return async (dispatch, getState) => {
        return dispatch(setCurrentModule(feature_code))
    }
}

//Se borran de la store el arreglo de feature_codes activas y se cambia por uno vacío
export const setEmptyModuleFeatures = () => {
    return async (dispatch) => {
        return dispatch(cleanModules())
    }
}