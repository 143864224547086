import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentOptionMenu: '',
  currentResidentialData: {},
  versionHistory: [],
  taxData: {},
  ticketsHistory: {},
  specifications: {},
  contractTypes: [],
  personhoodTypes: [],
  isLoading: false,
}

export const detailContractSlice = createSlice({
  name: "contractsByPlaceId",
  initialState,
  reducers: {
    setCurrentOption: (state, { payload }) => {
      state.currentOptionMenu = payload
    },
    setCurrentResidentialData: (state, { payload }) => {
      state.currentResidentialData = payload
    },
    setVersionHistory: (state, { payload }) => {
      state.versionHistory = payload
    },
    setTaxData: (state, { payload }) => {
      state.taxData = payload
    },
    setTicketsHistory: (state, { payload }) => {
      state.ticketsHistory = payload      
    },
    setSpecifications: (state, { payload }) => {
      state.specifications = payload
    },
    setContractTypes: (state, { payload }) => {
      state.contractTypes = payload
    },
    setPersonhoodTypes: (state, { payload }) => {
      state.personhoodTypes = payload
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
  },
});

export const { setCurrentOption,
  setCurrentResidentialData,
  setVersionHistory,
  setTicketsHistory,
  setSpecifications,
  setTaxData,
  setContractTypes,
  setPersonhoodTypes,
  setIsLoading,
} = detailContractSlice.actions;
