import { Row } from "antd"
import { verifyTypeFile, verifyTypesFiles } from "../../topia/helpers/customFilesHelper"

const FileIcon = ({
  width = 125,
  height = 125,
  urlFile = 'default',
  isJustIcon = false,
  widthIcon,
  heightIcon
  }) => {
  const extentionFile = verifyTypesFiles(urlFile)

  if (isJustIcon) return (
    <>
      {verifyTypeFile(extentionFile, widthIcon, heightIcon)}
    </>
  )

  return (
    <Row
      justify="center"
      style={{ width: width, height: height, backgroundColor: "#FFFFFF", border: "1px solid #D2D2D2", margin: 10 }}
    >
      <a
        href={urlFile}
        rel="noreferrer"
        target="_blank"
        style={{ display: "flex", alignItems: 'center' }}
      >
        {verifyTypeFile(extentionFile)}
      </a>
    </Row>
  )
}

export default FileIcon
