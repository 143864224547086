//TAREAS PROGRAMADAS ✅
export const adminCheckModule = {
    feature_code: 'FEAT-290',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-298',
            enabled: false,
            children: [
                {
                    feature_code: 'FEAT-117',
                    enabled: false,
                },
                {
                    feature_code: 'FEAT-119',
                    enabled: false,
                },
                {
                    feature_code: 'FEAT-120',
                    enabled: false,
                },
            ]
        },
        {
            feature_code: 'FEAT-111',
            enabled: false,
        },
        {
            feature_code: 'FEAT-299',
            enabled: false,
        },
        {
            feature_code: 'FEAT-133',
            enabled: false,
        },
        {
            feature_code: 'FEAT-113',
            enabled: false,
        },
        {
            feature_code: 'FEAT-263',
            enabled: false,
        },
    ]
}