import { axiosCreate } from "../../../../../config/index"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const updateCompanyAssetReport = async (formData, idToken) => {
  try {
    const response = await axiosCreate.put('company/asset/report',
      formData,
      {
        timeout: 20000,
        headers: { Authorization: `Bearer ${idToken}`}
      })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default updateCompanyAssetReport