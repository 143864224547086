import axios from "axios";
const updateQuantityInventory = async (
  URL_DEV,
  ticketId,
  employeeId,
  equipmentInventory,
  supplyInventory
) => {
  try {
    const inventoryUpdated = {
      ticket_id: ticketId,
      employee_id: employeeId,
      equipment: equipmentInventory,
      supply: supplyInventory,
    };
    const response = await axios.put(
      `${URL_DEV}/inspection/inventory`,
      inventoryUpdated
    );

    return {
      success: true,
      ...response,
    };
  } catch (errorResponse) {
    return {
      success: false,
      ...errorResponse.response,
    };
  }
};
export default updateQuantityInventory;
