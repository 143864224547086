import { dataToFormData } from "../../helpers/dataToFormData"
import verifyResponse from "../../topia/helpers/verifyResponse"
import { createTypeNew, deleteTypeNewById, getAllNewsType, getDetailTypeNewById, getFullDetailTypeNewById, getTypesNews, updateTypeNewById } from "../../topia/pages/typesNews/services"
import { setDetailTypeNew, setIdTypesNews, setLoadingDetailTypeNew, setLoadingTypesNews, setMetadata, setTypesNews } from "./typesNewsSlice"

export const getAllTypesNews = ({
  dateBegin,
  dateEnd,
  published,
  active,
  newsTypeId,
  skip,
  limit
}) => async (dispatch, getState) => {
  dispatch(setLoadingTypesNews(true))
  // El current rol es el company Id
  const { currentRol, idToken } = getState().auth

  const response = await getAllNewsType({
    idToken,
    companyId: currentRol,
    dateBegin: dateBegin,
    dateEnd: dateEnd,
    published: published,
    active: active,
    newsTypeId: newsTypeId,
    skip: skip,
    limit: limit
  })

  if (response.success) {
    dispatch(setMetadata(response.data.metadata))
    dispatch(setTypesNews(response.data.data))
    dispatch(setLoadingTypesNews(false))
    return true
  }

  dispatch(setLoadingTypesNews(false))

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getAllDataTypesIdNews = () => async (dispatch, getState) => {
  const { idToken } = getState().auth

  const response = await getTypesNews({ idToken })

  if (response.success) {
    dispatch(setIdTypesNews(response.data.data))
    return true
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const deleteCompanyNew = (formData) => async (dispatch, getState) => {
  const { idToken } = getState().auth
  const { filters } = getState().news

  const response = await deleteTypeNewById(formData, idToken)

  if (response.success) {
    dispatch(getAllTypesNews({ ...filters }))
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Noticia eliminada con éxito' })
}

export const getDetailTypeByIdNew = (idTypeNew) => async (dispatch, getState) => {
  dispatch(setLoadingDetailTypeNew(true))
  const { idToken } = getState().auth

  const response = await getDetailTypeNewById(idTypeNew, idToken)

  if (response.success) {
    dispatch(setDetailTypeNew(response.data.data))
    dispatch(setLoadingDetailTypeNew(false))

    return true
  }
  dispatch(setLoadingDetailTypeNew(false))

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getFullDetailTypeByIdNew = (idTypeNew) => async (dispatch, getState) => {
  dispatch(setLoadingDetailTypeNew(true))
  const { idToken } = getState().auth

  const response = await getFullDetailTypeNewById(idToken, idTypeNew)

  if (response.success) {
    dispatch(setDetailTypeNew(response.data.data))
    dispatch(setLoadingDetailTypeNew(false))

    return response
  }

  dispatch(setLoadingDetailTypeNew(false))

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

// Funcion para crear o actualizar un tipo de noticia
export const onSaveTypeNew = (formData) => async (dispatch, getState) => {
  const { detailTypeNew } = getState().news
  const { idToken, currentRol } = getState().auth

  const isDetailNewId = detailTypeNew?.news_id ? { news_id: detailTypeNew.news_id } : {}

  const copyNewObj = {...formData, ...isDetailNewId, company_id: currentRol }
  // Propiedades a borrar
  const propertiesToDelete = ['new_files', 'old_files', 'file', 'icon', 'header', 'time_begin_date', 'time_end_date']
  //Se borran los files
  propertiesToDelete.forEach(property => delete copyNewObj[property])

  const firstFormData = dataToFormData(copyNewObj)

  // verificar si archivos se han modificado o no
  // 1. Actualizar icono de noticia
  if (formData.icon) {
    firstFormData.append('icon', formData.icon)
  }
  // 2. Actualizar el header

  // Incluir solo los headers nuevos
  const headerFile = formData?.header?.filter((headerImg) => headerImg.originFileObj)

  if (headerFile.length > 0) {
    firstFormData.append("header", formData?.header[0]?.originFileObj)
  }

  // 3. Verificar archivos nuevos y viejos
  const newFiles = formData?.new_files?.filter((newFile) => newFile.originFileObj)

  const oldFiles = formData?.old_files?.filter((oldFile) => oldFile.url)

  if (newFiles?.length > 0) {
    newFiles?.map((newFile) => firstFormData.append('files', newFile.originFileObj))
  }

  if (oldFiles?.length > 0) {
    oldFiles?.map((oldFile) => firstFormData.append('current_files', oldFile.url))
  }

  const funcionToExecute = detailTypeNew?.news_id ? updateTypeNewById : createTypeNew
  const typeMessageToShow = detailTypeNew?.news_id ? 'actualizada' : 'creada'
  const messageTypeNewToShow = detailTypeNew?.NEWS_TYPE?.name ? detailTypeNew?.NEWS_TYPE?.name : 'Noticia'
  
  const response = await funcionToExecute(firstFormData, idToken)

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: `${messageTypeNewToShow} ${typeMessageToShow} con éxito` })
}