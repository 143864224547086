import axios from "axios";
import { API_URL } from "../../../../../../config";
import { verifyBodyResponse } from "../../../../../helpers/verifyBodyResponse";

const employeePath = "employee";

const getAllEmployees = async ({
  token,
  find_value,
  company_id,
}) => {
  try {
    const params = {
      company_id,
      find_value,
    };

    const response = await axios.get(`${API_URL}${employeePath}`, {
      params,
      timeout: 10000,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Se prepara y moldea la data para que el customComponent Select trabaje sin problema
   const { data } = response
   const newResponse = {
     ...response,
     data: {
       ...response.data,
       data: {
         dataSource: data?.data?.dataSource.map((employee) => ({ id: employee.id, name: employee.name })),
       }
     }
   }

    return verifyBodyResponse(newResponse,true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getAllEmployees
