import { axiosCreate } from "../../../../../config/index"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const getCompanyAssetMaintenanceReport = async (formData) => {
  try {
    const params = {
      date_end: formData.date_end,
      date_begin: formData.date_begin,
      company_id: formData.company_id,
      company_asset_ids: formData.company_asset_ids
    }

    const response = await axiosCreate.get(`company/asset/export`,
      { headers: { Authorization: `Bearer ${formData.idToken}` },
        timeout: 20000, params,
        responseType: 'blob',
      }
    )

    const data = new Blob([response.data], { type: 'application/vnd.MS Excel' })
    const csvUrl = window.URL.createObjectURL(data)
    const link = document.createElement("a")
    link.href = csvUrl;
    link.setAttribute("download", "Reporte-de-activos.xlsx");
    document.body.appendChild(link);
    link.click();

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getCompanyAssetMaintenanceReport