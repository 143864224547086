import { useState } from "react"
import MiddleBody from "./components/MiddleBody"
import useCustomTable from "../../../../../hooks/useCustomTable"
import HeaderActionsDetails from "./components/layout/HeaderActionsDetails"
import { HandoverChecklistProvider } from "../../context/HandoverChecklistContext"
import useOpenCloseModal from "../../../../../hooks/useOpenCloseModal"
import ChecklistSignModal from "./components/modals/ChecklistSignModal"

const DetailHandoverChecklist = () => {
  const [isCurrentBody, setIsCurrentBody] = useState(false)

  const handleCurrentBody = () => setIsCurrentBody(state => !state)

  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  const {
    expandedRowKeys,
    setDataSource,
    handleExpandAll,
    handleCollapseAll,
    handleRowExpand
  } = useCustomTable()

  return (
    <HandoverChecklistProvider>
      <section className="section-wrapper">
        <HeaderActionsDetails
          handleOpenCloseModal={handleOpenCloseModal}
          handleCollapseAll={handleCollapseAll}
          handleExpandAll={handleExpandAll}
          isCurrentBody={isCurrentBody}
          handleCurrentBody={handleCurrentBody}
        />
        <MiddleBody
          isCurrentBody={isCurrentBody}
          setDataSource={setDataSource}
          handleRowExpand={handleRowExpand}
          expandedRowKeys={expandedRowKeys}
          handleCurrentBody={handleCurrentBody}
        />
      </section>

      <ChecklistSignModal isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
    </HandoverChecklistProvider>
  )
}

export default DetailHandoverChecklist
