import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  currentDataChatSelected: {},
  bookingStatus: [],
  adminStatusTickets: [],
  usersStatusTickets: [],
  statusChecklist: [],
  checklistSchedule: [],
  accessProviders: [],
  currentDashBody: ''
}

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setCurrentDataChatSelected: (state, { payload }) => {
      state.currentDataChatSelected = payload
    },
    setCleanCurrentDataSelected: (state, { payload }) => {
      state.currentDataChatSelected = payload
    },
    setBookingStatus: (state, { payload }) => {
      state.bookingStatus = payload
    },
    setAdminStatusTickets: (state, { payload }) => {
      state.adminStatusTickets = payload
    },
    setUsersStatusTickets: (state, { payload }) => {
      state.usersStatusTickets = payload
    },
    setStatusChecklist: (state, { payload }) => {
      state.statusChecklist = payload
    },
    setChecklistSchedule: (state, { payload }) => {
      state.checklistSchedule = payload
    },
    setCurrentDashBody: (state, { payload }) => {
      state.currentDashBody = payload
    },
    setAccessProviders: (state, { payload }) => {
      state.accessProviders = payload
    }
  }
})

export const {
  setBookingStatus,
  setAccessProviders,
  setCurrentDashBody,
  setStatusChecklist,
  setChecklistSchedule,
  setAdminStatusTickets,
  setUsersStatusTickets,
  setCurrentDataChatSelected,
  setCleanCurrentDataSelected,
} = homeSlice.actions
