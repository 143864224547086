import moment from "moment"
import { useEffect } from "react"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { CustomSpin } from "../../../../../../UI/CustomSpin"
import FileIcon from "../../../../../../../assets/icons/FileIcon"
import { useAppSelector } from "../../../../../../../hooks/store"
import { CustomNavigate } from "../../../../../../UI/CustomNavigate"
import PDFMaintenanceReport from "./components/PDFMaintenanceReport"
import { Button, Col, Image as ImageAnt, Row, Typography } from "antd"
import { useDetailMaintenanceReport } from "../../../../hooks/useDetailMaintenanceReport/useDetailMaintenanceReport"

import 'moment/locale/es'

import '../../../styles.css'

const { Text } = Typography

export const DetailMaintenanceReport = () => {
  const { getInitialData, cleanData, reportDetail, dataTableHistory, param, loading } = useDetailMaintenanceReport()
  const { themeColors } = useAppSelector(state => state.theme)

  useEffect(() => {
    moment.locale('es')
    if (param?.reportId) {
      getInitialData({ company_asset_maintenance_id: param.reportId })
    }

    return () => {
      cleanData()
    }
  }, []);

  return (
    <div className="section-wrapper">
      <CustomNavigate title={<span>REPORTE: <span>{dataTableHistory?.find(item => item?.company_asset_maintenance_id.toString() === param?.reportId)?.name || reportDetail?.COMPANY_ASSET?.name?.toUpperCase() || ''}</span></span>}>
        <PDFDownloadLink document={<PDFMaintenanceReport reportDetail={reportDetail} titleColor={themeColors?.primary?.primary} />}
          fileName={`Reporte - ${reportDetail?.COMPANY_ASSET?.name}`}>
          <Button style={{ maxWidth: 181 }} className="btn-standard-green" shape="round">
            EXPORTAR PDF
          </Button>
        </PDFDownloadLink>
      </CustomNavigate>
      <CustomSpin spinning={loading}>
        <div style={{ paddingBottom: 32 }}>
          <Col className="report-details">
            <Row className="report-details__start-info">
              <Col span={12}>
                REPORTE DE MANTENIMIENTO
                <span>{reportDetail?.COMPANY_ASSET?.name?.toUpperCase()}
                  <span style={{ color: '#232C4A' }}>
                    {' '}/ {reportDetail?.COMPANY_ASSET?.code ? reportDetail?.COMPANY_ASSET?.code : "SIN CÓDIGO"}
                  </span>
                </span>
              </Col>
              <Col offset={4} span={8}>
                <small>PRÓXIMO MANTENIMIENTO</small>
                <Col>
                  {reportDetail?.formated_next_maintenance_date
                    ? reportDetail.formated_next_maintenance_date
                    : "No se programó una próxima fecha de manetenimiento"
                  }
                </Col>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col style={{ display: 'flex', flexDirection: 'column', marginBottom: 24 }}>
                <small>EMPRESA PROVEEDORA DE MANTENIMIENTO</small>
                <strong>{reportDetail?.PROVIDER?.name}</strong>
              </Col>

              <Col>
                <Row style={{ border: '1px solid #949494', borderRadius: 8 }}>
                  <Col style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                    <small>COSTE DEL MANTENIMIENTO</small>

                    <strong style={{ fontWeight: 600, fontSize: 25 }}>{reportDetail?.formated_cost}</strong>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="report-details__info-details" gutter={32}>
              <Col span={5}>
                <small>EMPLEADO RESPONSABLE</small>
                <strong>{reportDetail?.employee_name}</strong>
              </Col>
              <Col span={4}>
                <small>FECHA DE MANTENIMIENTO</small>
                <strong>{reportDetail?.date}</strong>
              </Col>
              <Col span={3}>
                <small>HORA DE INICIO</small>
                <strong>{reportDetail?.formated_time_begin}</strong>
              </Col>
              <Col span={4}>
                <small>HORA DE FINALIZACIÓN</small>
                <strong>{reportDetail?.formated_time_end}</strong>
              </Col>
            </Row>
            
            <Col>
              <small>NOTAS GENERALES</small>
              <p style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}>
                {reportDetail?.observations}
              </p>
            </Col>
            <Col>
              <small>DOCUMENTOS ADJUNTOS</small>
              {
                reportDetail?.COMPANY_ASSET_MAINTENANCE_ATTACHMENTs && reportDetail?.COMPANY_ASSET_MAINTENANCE_ATTACHMENTs.map((attachment) => {
                  return (
                    <Row className="report-details__attachment-file" key={attachment.url}>
                      <FileIcon urlFile={attachment.url} />
                      <a className="report-details__attachment-file__details" href={attachment.url} target="_blank" rel="noreferrer">
                        <Text ellipsis style={{ maxWidth: 250 }}><span>{attachment?.name}</span></Text>
                        <small>{attachment.size}</small>
                      </a>
                    </Row>
                  )
                })
              }
            </Col>
            <Col>
              <small>FOTOGRAFÍAS ANEXAS</small>
              {
                reportDetail?.COMPANY_ASSET_MAINTENANCE_IMAGEs && (<Row gutter={16}>
                    {reportDetail?.COMPANY_ASSET_MAINTENANCE_IMAGEs?.map((image) => {
                      return (
                        <Col className="report-details__attachment-image" key={image.url}>
                          <ImageAnt width={214} height={260} src={image.url} />
                          <div className="description">
                            <strong> {image.name}</strong>
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                )
              }
            </Col>
          </Col>
        </div>
      </CustomSpin>
    </div>
  )
}

export default DetailMaintenanceReport