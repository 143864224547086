import { Button, Col, Row } from "antd"
import { useState } from "react"
import { CustomSpin } from "../../UI/CustomSpin"
import EmptyComponent from "../../UI/EmptyComponent"
import { useAppSelector } from "../../../hooks/store"
import CustomCountDown from "../../UI/CustomCountDown"
import { useFeatureRole } from "../../hooks/useFeatureRole"
import useChatsList from "../../UI/CustomChat/hooks/useChatsList"
import MallStatisticsBody from "./components/bodies/MallStatisticsBody"
import ResidentialStatisticsBody from "./components/bodies/ResidentialStatisticsBody"
import { MESSAGING_CODES, MESSAGING_CODES_NAMES } from "../../../constants/modules/messaging/messagingCodes"

import './style.css'

const HomePage = () => {
  const [timeLeft, setTimeLeft] = useState(600)

  const { typeRol, committee } = useAppSelector(state => state.auth)
  const { loading, metadata, conversationsList, getAllConversations } = useChatsList()

  const fullName = `${(committee?.name)} ${(committee?.lastname)}`

  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: MESSAGING_CODES, validate_code: MESSAGING_CODES_NAMES.MESSAGING_MODULE })

  // Aqui tendria que agregar las otras funciones que actualizan todas las estadisticas
  const handleUpdateChatList = async () => {
    await getAllConversations()
  }

  const verifyDashBody = (currentBody) => {
    switch (currentBody) {
      case 'residential':
        return <ResidentialStatisticsBody loading={loading} metadata={metadata} conversationsList={conversationsList} getAllConversations={getAllConversations} activeFeatures={activeFeatures} />
      case 'mall':
        return <MallStatisticsBody loading={loading} metadata={metadata} conversationsList={conversationsList} getAllConversations={getAllConversations} activeFeatures={activeFeatures} />
      default:
        break;
    }
  }

  if (loadingFeatures) return <CustomSpin section tip="Cargando módulo" />

  return (
    <section className="wrapper-page">
      {
        !activeFeatures?.find(feature => feature.feature_code === MESSAGING_CODES.MESSAGING_MODULE)?.enabled ? <EmptyComponent label={'No tienes permiso para ver estos datos'} /> : (
          <>
            <Row justify="space-between" align="middle" style={{ marginBottom: 10, paddingTop: typeRol === 'mall' ? 30 : 0 }}>
              <Col>
                <h2 style={{ fontSize: 35, fontWeight: 600, color: '#000000', margin: 0 }}>
                  Bienvenido {fullName}
                </h2>
              </Col>

              <Col>
                <Button
                  type="text"
                  htmlType="button"
                  onClick={handleUpdateChatList}
                  style={{ width: 198, backgroundColor: '#404040', height: 40, borderRadius: 8 }}
                >
                  <span style={{ fontWeight: 400, fontSize: 16, color: '#FFFFFF' }}>Actualizar</span>
                </Button>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                {verifyDashBody(typeRol)}
              </Col>
            </Row>
          </>
        )
      }
    </section>
  )
}

export default HomePage