import axios from "axios"

const getAvailableHouses = async (residentialId) => {
  try {
    const response = await axios.get(`https://domo.topiaapp.com/api/residential/nomenclature/houses/available?residential_id=${residentialId}`)

    return {
      success: true,
      ...response
    }
    
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getAvailableHouses