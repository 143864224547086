import moment from "moment"
import { Button, Col, Dropdown, Menu, Row } from "antd"
import { useAppDispatch } from "../../../../hooks/store"
import { setChecklistClicked } from "../../../../store/adminChecklist/adminChecklistSlice"
import useRedirectTo from "../../../hooks/useRedirectTo"

const MoreActions = ({
  form,
  dataChecklist,
  handleOpenCloseModalDelete,
  handleOpenCloseModalNewEdit
}) => {
  const dispatch = useAppDispatch()

  const { handleRedirectTo } = useRedirectTo()

  const ItemLabel = ({ item, index }) => (
    <div className="more-options" onClick={() => item.action(index)}>
      <img src={item.icon} alt={item.name} />
      <span>{item.name}</span>
    </div>
  )

  const items = [
    {
      name: "Eliminar",
      // disabled: featuresEnabled.fireEmployee,
      icon: "/assets/icons/basura.svg",
      action: () => {
        handleOpenCloseModalDelete()
        dispatch(setChecklistClicked(dataChecklist))
      },
    },
    {
      name: "Editar",
      // disabled: featuresEnabled.updateEmployee,
      icon: "/assets/icons/editar.svg",
      action: () => {
        handleOpenCloseModalNewEdit()
        dispatch(setChecklistClicked(dataChecklist))
        form.setFieldsValue({ employee_id: dataChecklist.employee_id, date: moment(dataChecklist.date) })
      },
    }
  ]

  const menu = (
    <Menu
      style={{
        width: 175,
      }}
      items={items.map((item, index) => ({
        disabled: item.disabled,
        key: index,
        label: <ItemLabel item={item} index={index} />,
      }))}
    />
  );

  return (
    <Row wrap={false} align="middle">
      <Col>
        <Button
          type="text"
          shape="round"
          htmlType="button"
          onClick={() => {
            dispatch(setChecklistClicked(dataChecklist))
            handleRedirectTo('/admin-check/results')
          }}
          style={{ border: '1px solid #252525', width: 152, height: 36 }}
        >
          <span style={{ fontSize: 14, fontWeight: 500, color:' #252525' }}>RESULTADOS</span>
        </Button>
      </Col>

      <Col>
        <Dropdown trigger={["click"]} overlay={menu}>
          <img
            src="/assets/icons/more-icon.svg"
            alt="mas"
            style={{
              cursor: "pointer",
            }}
          />
        </Dropdown>
      </Col>
    </Row>
  )
}

export default MoreActions