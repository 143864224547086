import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const libaryPath = 'document/library'

export const createNewDocument = async ({
    token,
    formData
}) => {
    try {

        const response = await axiosCreate.post(`${libaryPath}`, formData, {
            timeout: 10000, headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": 'multipart/form-data'
            },
        });

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
}