import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import React from "react";
import useEmployee from "../hooks/useEmployee";
import { useEffect } from "react";
import { useState } from "react";
import { dataToFormData } from "../../../../helpers/dataToFormData";
import { phoneFormatHandler } from "../../../../helpers/phoneFormatHandler";
import useCompany from "../hooks/useCompany";
import { getPhoneFormat } from "../../../../helpers/getPhoneFormat";

const EmployeeForm = ({
  companyId,
  file,
  onClose,
  setImageUrl,
  employeeSelected,
  employeeHook,
}) => {
  const { positions, getAllPositions, contractTypes, getAllContractTypes, getAllRolesByCompanyId, roles } = useCompany();
  const {
    PROFILE,
    EMPLOYEE_DEPARTMENT,
    EMPLOYEE_CONTRACT_TYPE,
    id,
    EMPLOYEE_POSITION,
    description,
    providerName,
    profileId,
  } = employeeSelected;
  const { departments, getAllDepartments,  onSaveEmployees } = useEmployee();
  const { changePageHandler, currentPage } = employeeHook;
  const [loading, setLoading] = useState(false);
  const rules = [{ required: true, message: "Este campo es requerido" }];
  const rolesToSelect = roles.map((role) => ({ value: role.id, label: role.name}))

  useEffect(() => {
    getAllDepartments(companyId);
    getAllPositions(companyId);
    getAllContractTypes(companyId);
    getAllRolesByCompanyId(companyId);
  }, []);

  const onSubmit = async (values) => {
    const phone_number = values.phone_number.split("-").join("");
    const data = {
      ...values,
      phone_number,
      residential_id: " ",
      company_id: companyId,
      employee_id: id,
      profile_id: profileId,
      file,
    };
    
    const formData = dataToFormData(data);
    setLoading(true)

    const result = await onSaveEmployees(formData, id)
    onFinish(result)

    setLoading(false)
  };

  const onFinish = (resultSuccess) => {
    if (!resultSuccess) return;
    changePageHandler(currentPage, null, companyId);
    setImageUrl({});
    onClose();
  };

  return (
    <Form
      className="employee-form"
      layout="vertical"
      onFinish={onSubmit}
      disabled={loading}
      initialValues={{
        first_name: PROFILE?.PROFILE_DETAIL?.firstName,
        last_name: PROFILE?.PROFILE_DETAIL?.lastName,
        mail: PROFILE?.mail,
        role_id: PROFILE?.ROLE_COMPANY_PROFILEs[0]?.ROLE_COMPANY?.ROLE?.id,
        department_id: EMPLOYEE_DEPARTMENT?.departmentId,
        phone_number: getPhoneFormat(PROFILE?.phoneNumber),
        contract_id: EMPLOYEE_CONTRACT_TYPE?.contractId,
        position_id: EMPLOYEE_POSITION?.COMPANY_POSITION?.id,
        provider_name: providerName,
        description,
        phone_area: PROFILE?.phoneArea,
      }}
    >
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item label="NOMBRE" name="first_name" rules={rules}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="APELLIDO" name="last_name" rules={rules}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item label="CORREO" name="mail" rules={rules}>
            <Input type="email" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="ROL" name="role_id" rules={rules}>
            <Select options={rolesToSelect} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item label="DEPARTAMENTO" name="department_id" rules={rules}>
            <Select>
              {departments.map((department) => (
                <Select.Option key={department.id} value={department.id}>
                  {department.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="CARGO" name={"position_id"} rules={rules}>
            <Select>
              {positions.map((position) => (
                <Select.Option key={position.id} value={position.id}>
                  {position.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            label="CÓDIGO DE ÁREA DE TÉLEFONO"
            name="phone_area"
            rules={rules}
          >
            <InputNumber maxLength={3} min={1} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="TELÉFONO PERSONAL"
            name="phone_number"
            rules={rules}
          >
            <Input onInput={phoneFormatHandler} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={12}>
          <Form.Item label="TIPO DE CONTRATO" name="contract_id" rules={rules}>
            <Select>
              {contractTypes.map((contract) => (
                <Select.Option key={contract.id} value={contract.id}>
                  {contract.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="EMPRESA EXTERNA:" name="provider_name">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="NOTAS:" name="description">
        <Input.TextArea rows={4} />
      </Form.Item>

      <div className="btn-center save-btn">
        <Button htmlType="submit" loading={loading}>
          GUARDAR
        </Button>
      </div>
    </Form>
  );
};

export default EmployeeForm;
