import { Button, Row, Space } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import useTickets from "../hooks/useTickets"
import { useContext } from "react"
import { CategoriesTicketContext } from "../context/CategoriesTicketContext"
import EditIcon from "../../../../assets/icons/EditIcon"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import { TICKETS_CODES } from "../../../../constants/modules/tickets/ticketsCodes"

const ActionsButtons = ({ form, subCategory, handleOpenCloseModal, handleOpenCloseDeleteModal, activeFeatures }) => {
  const { categories } = useTickets()
  const { setSubCategorySelected } = useContext(CategoriesTicketContext)
  const categoryFiltered = categories.find((category) => category.id === subCategory.categoryId) ?? {}

  return (
    <Row justify="end">
      <Space direction="horizontal">
        <CustomPopoverConfirm placement={'left'} trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_UPDATE_SUBCATEGORY_TICKET)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Editar subcategoría'}`}>
          <Button
            disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_UPDATE_SUBCATEGORY_TICKET)?.enabled}
            type="link"
            htmlType="button"
            shape="circle"
            onClick={() => {
              form.setFieldsValue({ name: subCategory.name, category_id: { value: categoryFiltered.id, label: categoryFiltered.name } })
              handleOpenCloseModal()
              setSubCategorySelected(subCategory)
            }}
          >
            <EditIcon color="var(--primary)" width="20px" />
          </Button>
        </CustomPopoverConfirm>
        <CustomPopoverConfirm placement={'left'} trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_DELETE_SUBCATEGORY_TICKET)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Eliminar subcategoría'}`}>
          <Button
            disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_DELETE_SUBCATEGORY_TICKET)?.enabled}
            type="link"
            htmlType="button"
            shape="circle"
            onClick={() => {
              handleOpenCloseDeleteModal()
              setSubCategorySelected(subCategory)
            }}
            icon={<DeleteOutlined style={{ fontSize: "18px", color: "#EA5E64" }} />}
          />
        </CustomPopoverConfirm>

      </Space>
    </Row>
  )
}

export default ActionsButtons