import { CustomSpin } from '../../../UI/CustomSpin'
import { useAppSelector } from '../../../../hooks/store'
import CustomTable from '../../../UI/CustomTable/CustomTable'
import { columnsAccessScheduleVisitorTable } from '../../../../helpers/accessHelper'
import EmptyComponent from '../../../UI/EmptyComponent'
import { ACCESS_CODES } from '../../../../constants/modules/access/accessCodes'

export const AccessScheduleVisitorsTab = ({ activeFeatures }) => {
    const { scheduledVisitors } = useAppSelector(state => state.access);
    const columns = columnsAccessScheduleVisitorTable();

    // useEffect(() => {
    // setActiveResident(scheduledVisitors.active);
    // }, [scheduledVisitors.active]);


    // const handleStatusModal = (statusModal, index = null) => {

    // if(index != null){
    //   dispatch( setActiveAlert( alerts.data[index] ) );
    // }else{
    //   dispatch( setActiveAlert( null ) );
    // }


    // setTimeout(() => {
    //   setModalVisible(statusModal);
    // }, 50);

    // }


    return (
        <>
            {!activeFeatures?.find(feature => feature.feature_code === ACCESS_CODES.ACCESS_SCHEDULE_VISITANTS)?.enabled ? <EmptyComponent label={'No tienes permiso para ver estos datos'} /> : scheduledVisitors.loadResidente ? (
                <div className={"loader"}>
                    <CustomSpin height='300px' size="large" />
                </div>
            ) : (
                <div>
                    <CustomTable
                        rowKey={'id'}
                        className='custom-ant-table'
                        columns={columns}
                        dataSource={scheduledVisitors.data}
                        pagination={{
                            showTotal: (total, range) => {
                                return `${range[0]}-${range[1]} de ${total}`
                            }
                        }}
                    />
                </div>
            )}

        </>
    )
}