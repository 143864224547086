import axios from "axios";
const cancelTicket = async (URL_DEV, id, msg) => {
  try {
    const infoCancelTicket = {
      ticket_id: id,
      employee_id: 1,
      message: msg,
    };
    const response = await axios.put(
      `${URL_DEV}/status/canceled`,
      infoCancelTicket
    );
    return {
      success: true,
      ...response,
    };
  } catch (errorResponse) {
    return {
      success: false,
      ...errorResponse.response,
    };
  }
};
export default cancelTicket;
