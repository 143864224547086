import logoSidebar from "../assets/logo-sidebar.png";
import { ReactComponent as HomeIcon } from '../../../../assets/icons/Sidebar/HomeIcon.svg'
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/Sidebar/SettingsIcon.svg'
import { ReactComponent as ModulesIcon } from '../../../../assets/icons/Sidebar/ModulesIcon.svg'

const commonProps = {
  backgroundColor: "var(--primary)",
  activeBackgroundColor: "var(--secondary)",
  color: "#fff",
  activeColor: "#fff",
}

const defaulData = {
  sidebarLogo: logoSidebar
}

export const defaultTheme = (data) => {
  //Definir los estilos por defecto de adminTopia...
  const sidebarTheme = {
    sidebarLogo: data?.logo
  } || defaulData

  return {
    themeId: "DefaultSidebar",
    sidebarlogo: sidebarTheme.sidebarLogo,
    sidebarBackground: "var(--primary)",
    items: [
      {
        itemName: "homeItem",
        to: "/",
        image: HomeIcon,
        activeImage: HomeIcon,
        name: "INICIO",
        ...commonProps
      },
      {
        itemName: "modulesItem",
        to: "/menu/modules",
        image: ModulesIcon,
        activeImage: ModulesIcon,
        name: "MÓDULOS",
        ...commonProps
      },
      {
        itemName: "settingsItem",
        to: '/menu/settings',
        image: SettingsIcon,
        activeImage: SettingsIcon,
        name: "AJUSTES",
        ...commonProps
      },
    ],
    submenuStyle: {
      backgroundColor: "#232c4a",
      titleColor: "var(--primary)",
      color: "#bcccff",
    },
    navbar: {
      dropdownProfile: {
        activeBackgroundColor: 'var(--primary)',
        activeColor: "#fff",
        color: "#252525",
        backgroundColor: '#fff',
      }
    }
  }
};
