import { Col, Row } from "antd"
import { useEffect, useState } from "react"
import { CustomSpin } from "../../UI/CustomSpin"
import { useAppDispatch, useAppSelector } from "../../../hooks/store"
import useImageChecklist from "./hooks/useImageChecklist"
import useOpenCloseModal from "../../hooks/useOpenCloseModal"
import ImageChecklistTable from "./components/ImageChecklistTable"
import NewEditTestModal from "./components/modals/NewEditTestModal"
import { setFilters } from "../../../store/adminChecklist/adminChecklistSlice"
import FiltersImageChecklistTable from "./components/FiltersImageChecklistTable"
import HeaderActionsImageCheck from "./components/layout/HeaderActionsImageCheck"

import './styles.css'

const ImageChecklistPage = () => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)

  const { filters } = useAppSelector(state => state.adminCheck)
  const { getOrnamentStatus, getChecklistsInstances, getChecklistInstanceId } = useImageChecklist()
  const { form, isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  useEffect(() => {
    getAllData()
  } ,[])

  const getAllData = async () => {
    setLoading(true)

    dispatch(setFilters({
      checklistId: 0,
      employeeId: '',
      dateBegin: '',
      dateEnd: '',
      checklistIdStatus: '',
      skip: 0,
      limit: 10,
      sortOrder: '',
      sortBy: ''
    }))
    await getChecklistInstanceId()
    await getChecklistsInstances({ ...filters })
    await getOrnamentStatus()
    
    setLoading(false)  
  }

  if (loading) return <CustomSpin />

  return (
    <section className="section-wrapper">
      <Row>
        <Col span={24}>
          <HeaderActionsImageCheck form={form} handleOpenCloseModal={handleOpenCloseModal} />

          <FiltersImageChecklistTable />

          <ImageChecklistTable form={form} handleOpenCloseModalNewEdit={handleOpenCloseModal} />
        </Col>
      </Row>

      <NewEditTestModal form={form} isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
    </section>
  )
}

export default ImageChecklistPage
