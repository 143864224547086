import axios from "axios"
import { API_URL } from "../../../../config";
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse";

const getAvailablesParking = async ({ companyId, idToken, find_value }) => {
  try {
    const isFindValue = find_value ? `&find_value=${find_value}` : ''
    const url = `${API_URL}place/by/type?company_id=${companyId}${isFindValue}&place_type=parking`;
   
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${idToken}` },
      timeout: 10000,
    });
   // Se prepara y moldea la data para que el customComponent Select trabaje sin problema
   const { data } = response
   const newResponse = {
     ...response,
     data: {
       ...response.data,
       data: {
         dataSource: data?.data?.dataSource.map((parking) => ({ id: parking.place_id, name: parking.name })),
       }
     }
   }

    return verifyBodyResponse(newResponse,true)
  } catch (error) {
      return verifyBodyResponse(error, false)
    }
}

export default getAvailablesParking
