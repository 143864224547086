import { scheduleEventColors } from "../mocks/scheduleEventColors";

export const getRandomHexColor = () => {
  let symbols, color;
  symbols = "0123456789ABCDEF";
  color = "#";
  for (let i = 0; i < 6; i++) {
    color = color + symbols[Math.floor(Math.random() * 16)];
  }
  return color;
}

//Función para obtener el valor de luminosidad de un color hexadecimal para determinar colores de texto claros u oscuros
export const getLuminosity = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;

  const luminosity = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminosity;
}

//Función para obtener colores aleatorios predifinidos, pasando un array de colores y un array de colores ya asignados
export const getColors = ({num, assignColors, colors}) => {
  //Hacer que los colores se vayan quitando del array y cuando ya no hayan restaurar los colores originales

  const originalColors = [...scheduleEventColors];
  const randomColor = () => {
    const indexColor = Math.floor(Math.random() * colors.current.length);
    return colors.current.splice(indexColor, 1)[0];
  }

  if (assignColors.current.hasOwnProperty(num)) {
    return assignColors.current[num];
  } else {
    const assignColor = randomColor();
    assignColors.current[num] = assignColor;
    if(!colors.current.length) colors.current = [...originalColors];
    return assignColor;
  }
}