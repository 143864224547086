import { Dropdown, Menu } from "antd"

// Para utilizar este componente es necesario agregar la tag div como children o cualquier otra para que el componente
// de ant d reconozca que existe algo de lo contrario no muestra el menu con hover ni ninguna otra opcion
const CustomDropdown = ({
  typeShowMenu = 'hover',
  placement = 'bottomLeft',
  styleMenu,
  items = [],
  children
}) => {
  const ItemLabel = ({ item }) => {
    return (
      <div className="more-options-standard" onClick={() => item.action(item.key)}>
        {(item.icon) && item.icon}
        <span>{item.label}</span>
      </div>
    )
  }

  const menu = (
    <Menu
      style={styleMenu}
      items={items.map((item) => ({
        key: item.key,
        label: <ItemLabel item={item} />,
      }))}
    />
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={[typeShowMenu]}
      placement={placement}
    >
      {children}
    </Dropdown>
  )
}

export default CustomDropdown
