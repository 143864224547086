export const verifyBodyResponse = (res, success) => {
    let data = {};

    if (success) {
        return { ...res, success: true };
    }
    if (res.response) {
        data = { ...res.response };
    }
    else if (res.request) {
        data = {
            status: res.code,
            data: {
                errors: [{ msg: res.message }],
            },
        };
    }
    return { ...data, success: false };
}