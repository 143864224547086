import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const placePath = "place/transaction/month-income/all"

export const getAllMonthIncomes = async ({
    token,
    company_id = 1,
    date,
    place_id,
    sort_by,
    sort_order,
    limit = 25,
    skip = 0,
}) => {
    try {
        const params = {
            company_id,
            date,
            place_id,
            limit,
            skip,
            sort_by,
            sort_order,
        };

        const response = await axiosCreate.get(`${placePath}`, {
            params, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
};