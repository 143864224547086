import { Button, Row } from 'antd';
import React from 'react';

const FooterActionCreateTicketByCheckList = ({ handleCreateTicket }) => (
  <Row
    justify="end"
    style={{ borderRadius: '8px', backgroundColor: "#FFFFFF", marginTop: '2rem', padding: '2rem 8rem' }}
  >
    <Button
      htmlType="submit"
      type="text"
      shape="round"
      className="btn-standard-green"
    >
      <span>CREAR TICKET GENERAL</span>
    </Button>
  </Row>
)

export default FooterActionCreateTicketByCheckList;
