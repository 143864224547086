import { Image, Link, Text, View } from "@react-pdf/renderer"
import { styles } from "./styles"
import { comprobantIconPNG } from '../assets'
import { integroLogo1, integroLogo2 } from '../../../../assets/logos'
import CustomFilePdf from "../../../UI/CustomFiles/CustomFilePdf"
import { truncateText } from "../../../helpers/stringHelper"
import moment from "moment"

export const MonthlyIncomePDFReport = ({ data = { table: [], general: {} } }) => (
    <CustomFilePdf size='letter' multiplePages footer footerBar={true}>
        <View fixed style={styles.headerInfo}>
            <View style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <Text style={styles.headerInfoTitle}>
                    DECLARACIÓN DE VENTAS
                </Text>
                <Text style={styles.headerInfoDetails}>
                    <Text style={{ fontWeight: 'semibold' }}>Centro Comercial: </Text>{data.general.mall}
                </Text>
                <Text style={styles.headerInfoDetails}>
                    <Text style={{ fontWeight: 'semibold' }}>Administrador: </Text>{data.general.first_name} {data.general.last_name}
                </Text>
                <Text style={styles.headerInfoDetails}>
                    <Text style={{ fontWeight: 'semibold' }}>Fecha: </Text>{moment().startOf('day').format('DD/MM/YYYY')}
                </Text>
            </View>
            <Image style={{ width: 200, position: 'absolute', right: '5%', top: '30%' }} src={integroLogo1} />
        </View>
        {data && data.table.length ? <>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '12%' }}>
                        <Text style={{ ...styles.tableCell }}>ID</Text>
                    </View>
                    <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '15%' }}>
                        <Text style={styles.tableCell}>CENTRO COMERCIAL</Text>
                    </View>
                    <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '10%' }}>
                        <Text style={styles.tableCell}>LOCALES</Text>
                    </View>
                    <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '15%' }}>
                        <Text style={styles.tableCell}>FECHA DE VENTAS</Text>
                    </View>
                    <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '15%' }}>
                        <Text style={styles.tableCell}>ENCARGADO DE LOCAL</Text>
                    </View>
                    <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '15%' }}>
                        <Text style={styles.tableCell}>FECHA DE REGISTRO</Text>
                    </View>
                    <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '10%' }}>
                        <Text style={styles.tableCell}>MONTO</Text>
                    </View>
                    <View style={{ ...styles.tableCol, fontWeight: 'semibold', width: '10%' }}>
                        <Text style={styles.tableCell}>COMPROB.</Text>
                    </View>
                </View>
                {/**DATOS */}
                {
                    data.table.map((item, index) => (
                        <View break={index && index % 11 === 0} key={index} style={{ ...styles.tableRow, backgroundColor: index % 2 === 0 && '#F3F3F3' }}>
                            <View style={{ ...styles.tableCol, width: '12%' }}>
                                <Text style={{ ...styles.tableCell, textAlign: 'left', paddingLeft: 16 }}>{truncateText({ text: item.code, maxLength: 13 })}</Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '15%' }}>
                                <Text style={styles.tableCell}>{item.mall}</Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '10%' }}>
                                <Text style={styles.tableCell}>{item.place_name}</Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '15%' }}>
                                <Text style={styles.tableCell}>{item.transaction_date}</Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '15%' }}>
                                <Text style={styles.tableCell}>{item.profile_name}</Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '15%' }}>
                                <Text style={styles.tableCell}>{item.created_at}</Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '10%' }}>
                                <Text style={styles.tableCell}>{item.quantity}</Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Link src={item.file}>
                                    <Image source={comprobantIconPNG} style={{ width: 10, height: 12 }} />
                                </Link>
                            </View>
                        </View>
                    ))
                }
            </View>
        </> : <View />
        }
        <Image src={integroLogo2} fixed style={{ position: 'absolute', height: 180, top: '50%', left: '40%', width: 250, opacity: 0.10, transform: 'translate(-50%,-50%)' }} />{/*MARCA DE AGUA DE INTEGRO */}
        <View fixed style={{ backgroundColor: '#252525', height: '9px', position: 'absolute', top: 16, width: 560, marginHorizontal: 24 }} />
        <View fixed style={{ position: 'absolute', height: '9px', backgroundColor: '#252525', bottom: 16, width: 560, marginHorizontal: 24 }} />
    </CustomFilePdf>
)


export default MonthlyIncomePDFReport