import { Fragment, useEffect } from "react"
import { CustomNavigate } from "../../UI/CustomNavigate"
import MenuItem from "./components/MenuItem"
import useMenu from "./hooks/useMenu"
import './styles.css'
import SubmenuItem from "./components/SubmenuItem"
import { CustomSpin } from "../../UI/CustomSpin"

const MenuPage = () => {
  const { handleActualSetFeatures, menuItems, param, loading, setMenuItems, getMenuItems, emptyFeatures } = useMenu()

  useEffect(() => {
    const getInitialData = async () => {
      const items = await getMenuItems()
      setMenuItems(items)
    }
    getInitialData()

    return () => {
      emptyFeatures()
    }
  }, [param]);

  if (loading) return <CustomSpin tip="Cargando opciones" />

  return (
    <section className="section-wrapper">
      <CustomNavigate title={menuItems.title} />
      <main className="menu__item-container" style={{ display: param.menuId === 'modules' ? 'flex' : 'grid', flexDirection: param.menuId === 'modules' ? 'column' : '' }}>
        {
          param.menuId === 'modules' && !Array.isArray(menuItems.items)
            ? Object.keys(menuItems.items).map((type, index) => (
              <SubmenuItem
                key={index}
                type={type}
                submenuItems={menuItems}
                handleActualSetFeatures={handleActualSetFeatures}
              />
            ))
            : Array.isArray(menuItems.items) ? menuItems.items.map((item, index) => (
              <Fragment key={index}>
                <MenuItem handleActualSetFeatures={handleActualSetFeatures} item={item} />
              </Fragment>
            )) : <></>
        }
      </main>
    </section>
  )
}

export default MenuPage