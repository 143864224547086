import { useContext, useState } from "react"
import { useSelector } from "react-redux"
import { Button, Col, Form, Row } from "antd"
import useTickets from "../../hooks/useTickets"
import CustomModal from "../../../../UI/CustomModal"
import { CategoriesTicketContext } from "../../context/CategoriesTicketContext"

const DeleteSubCategoryModal = ({
  isOpenModal,
  handleOpenCloseModal,
  getAllTicketsCategories,
}) => {
  const [loading, setLoading] = useState(false)
  const { onDeleteSubCategory } = useTickets()
  const currentCompanyId = useSelector((state) => state?.auth?.currentRol)
  const { subCategorySelected, cleanSubCategorySelected } = useContext(CategoriesTicketContext)

  const onFinish = async (isSuccessful) => {
    if(isSuccessful) {
      await getAllTicketsCategories(currentCompanyId)
      handleOpenCloseModal()
      cleanSubCategorySelected()
    }
  }

  const onSubmit = async () => {
    setLoading(true)
    const response = await onDeleteSubCategory(subCategorySelected.id)
    await onFinish(response)
    setLoading(false)
  }

  return (
    <CustomModal
      centered
      destroyOnClose
      isOpenModal={isOpenModal}
      onCancel={() => {
        handleOpenCloseModal()
        cleanSubCategorySelected()
      }}
    >
      <Form
        name="deleteSubCategoryForm"
        onFinish={onSubmit}
        disabled={loading}
        layout="vertical"
      >
        <h2 className="title-modal" style={{ textAlign: "center", margin: "25px 0" }}>¿DESEA ELIMINAR ESTA SUBCATEGORÍA?</h2>

        <Row justify="center" align="middle" gutter={12}>
          <Col span={12}>
            <Button
              htmlType="submit"
              type="text"
              loading={loading}
              style={{ width: "100%", height: "42px", border: "1px solid var(--primary)", backgroundColor: "var(--primary)" }}
              shape="round"
            >
              <span style={{ color: "#FFFFFF", fontWeight: 500, fontSize: "14px" }}>ELIMINAR</span>
            </Button>
          </Col>

          <Col span={12}>
            <Button
              htmlType="button"
              type="text"
              loading={loading}
              style={{ width: "100%", height: "42px", border: "1px solid #EA5E64" }}
              shape="round"
              onClick={() => {
                cleanSubCategorySelected()
                handleOpenCloseModal()
              }}
            >
              <span style={{ color: "#EA5E64", fontWeight: 500, fontSize: "14px"  }}>CANCELAR</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default DeleteSubCategoryModal