//Función que permite verificar si dos objetos son iguales, y si tiene un array en comun verifica si los objetos que poseen tienen las mismas propiedades
export const matchArrayObjectProps = (
  obj1 = {},
  obj2 = {},
  arrayProp = "",
  excludeKey = "task_employee_id"
) => {
  const result = { ...obj2 };

  if (Array.isArray(obj1[arrayProp]) && Array.isArray(obj2[arrayProp])) {
    result[arrayProp] = obj2[arrayProp]
      .map((item2) => {
        //Por cada item del array del formData se obtienen las llaves del mismo
        const mappedItem = {};
        for (const key2 in item2) {
          //Se verifica si la key en el formData existe en el tipo, sino no la va a retornar
          if (obj1[arrayProp][0].hasOwnProperty(key2)) {
            //Si se pasa una llave para exlcuir no se incluirá en el map
            if (key2 === excludeKey && item2[excludeKey] === null) {
              continue;
            }
            mappedItem[key2] = item2[key2];
          }
        }
        return mappedItem;
      })
      .filter((item) => {
        //Se filtra el nuevo array de tal forma que aquellas opciones que estén indefinidas sean nulas
        const filteredItem = {};
        for (const key in item) {
          if (item[key] === undefined) {
            item[key] = null;
          }

          filteredItem[key] = item[key];
        }
        return filteredItem;
      });
  }

  for (const key in result) {
    if (key !== arrayProp && result[key] === undefined) {
      result[key] = null;
    }
  }
  return result;
};
