import { useState } from "react"
import { Button, Form, Row, Col } from "antd"
import CustomModal from "../../../../../UI/CustomModal"
import { CustomInput } from "../../../../../UI/CustomInput"
import useCurrentLocation from "../../../../../hooks/useCurrentLocation"

const EditTaskModal = ({ form, onCancel, isOpenModal, editTask, setEditTask, hookCheckList }) => {
  const [loading, setLoading] = useState(false)

  const { param } = useCurrentLocation()
  const { getAllCheckListItems, onSaveItemChecklist } = hookCheckList

  const { Item } = Form

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      form.resetFields()
      onCancel()
      await getAllCheckListItems(param.checklistId)
      setEditTask({})
    }
  }

  const onSubmit = async (values) => {
    setLoading(true)

    const trimValue = values.task.trim()
    
    const data = {
      checklist_id: param.checklistId,
      checklist_item_id: editTask?.id,
      name: trimValue
    }

    const response = await onSaveItemChecklist(data)
    
    await onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal
      destroyOnClose
      title={<h2>EDITAR TAREA</h2>}
      onCancel={() => {
        setEditTask({})
        onCancel()
      }}
      isOpenModal={isOpenModal}
    >
      <Row justify="center">
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            name="editFormItem"
            disabled={loading}
            onFinish={onSubmit}
          >
            <Item
              name="task"
              rules={[
                { required: true, message: "Por favor llenar el campo" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value.trim() === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("El campo no debe contener espacios al principio o al final"));
                  },
                }),
              ]}
            >
              <CustomInput placeholder="Nombre de la tarea" />
            </Item>

            <Row justify="center">
              <Button
                type="text"
                shape="round"
                htmlType="submit"
                loading={loading}
                disabled={loading}
                className="btn-standard-green"
              >
                <span>EDITAR TAREA</span>
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default EditTaskModal;
