import { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";

const LibraryContext = createContext({
    auth: null, serviceParams: null,
    loading: false, setLoading: () => { },
    dataTableLibrary: [], setDataTableLibrary: () => { },
    columnsLibrary: [], setColumnsLibrary: () => { },
    currentParamsLibrary: { find_by: undefined, find_value: undefined, skip: 0, sort_by: undefined, sort_order: undefined }, setCurrentParamsLibrary: () => { },
    currentPageLibrary: false, setCurrentPageLibrary: () => { },
    lastSkipLibrary: false, setLastSkipLibrary: () => { },
    limitLibrary: 10, quantityLibrary: null, setMetaDataLibrary: () => { },
    showDeleteDocumentModal: false, setShowDeleteDocumentModal: () => { },
    showNewDocumentModal: false, setShowNewDocumentModal: () => { },
    currentDocument: {}, setCurrentDocument: () => { },
    handleOpenCloseModal: () => { }
})

export const LibraryContextProvider = ({ children }) => {
    const auth = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false)
    const [dataTableLibrary, setDataTableLibrary] = useState([])
    const [columnsLibrary, setColumnsLibrary] = useState([])
    const [currentParamsLibrary, setCurrentParamsLibrary] = useState({ find_by: undefined, find_value: undefined, skip: 0, sort_by: undefined, sort_order: undefined })
    const [currentPageLibrary, setCurrentPageLibrary] = useState(1)
    const [lastSkipLibrary, setLastSkipLibrary] = useState(0)
    const [{ limit: limitLibrary = 10, quantity: quantityLibrary }, setMetaDataLibrary] = useState({})
    const [currentDocument, setCurrentDocument] = useState({})
    const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false)
    const [showNewDocumentModal, setShowNewDocumentModal] = useState(false)

    const handleOpenCloseModal = (name) => {
        if (name === 'delete') return setShowDeleteDocumentModal(prev => !prev)
        return setShowNewDocumentModal(prev => !prev)
    }
    const serviceParams = { previousData: dataTableLibrary, actualLimit: limitLibrary, limit: limitLibrary, currentPage: currentPageLibrary, skip: 0 }

    const values = {
        auth, serviceParams,
        handleOpenCloseModal,
        loading, setLoading,
        dataTableLibrary, setDataTableLibrary,
        columnsLibrary, setColumnsLibrary,
        currentParamsLibrary, setCurrentParamsLibrary,
        currentPageLibrary, setCurrentPageLibrary,
        lastSkipLibrary, setLastSkipLibrary,
        limitLibrary, quantityLibrary, setMetaDataLibrary,
        currentDocument, setCurrentDocument,
        showDeleteDocumentModal, setShowDeleteDocumentModal,
        showNewDocumentModal, setShowNewDocumentModal,
    }

    return (
        <LibraryContext.Provider value={values}>
            {children}
        </LibraryContext.Provider>
    )
}

export const useLibraryContext = () => useContext(LibraryContext)