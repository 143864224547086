//Funciones adicionales para manejar la data en la gráfica de Chartjs
export const chartDataProcessed = (data) => {
    return data
        .map(question => {
            if (question.type === 'commentary') {
                return question
            }
            //Filtrar las respuestas para cada pregunta
            let counters = {}
            if (question.type === 'qualify') {
                counters = { "1.0": 0, "2.0": 0, "3.0": 0, "4.0": 0, "5.0": 0, }
            }
            if (question.type === 'option') {
                question.option.forEach(option => counters[option] = 0)
            }
            question.answers.forEach((answer) => {
                if (counters.hasOwnProperty(answer.response)) {
                    counters[answer.response]++
                }
            })
            return {
                question: question.question,
                type: question.type,
                data: Object.values(counters),
                labels: Object.keys(counters),
                order: question.order,
                answers: question.answers
            }
        }).filter(question => question !== null).sort((a, b) => a.order - b.order)
}