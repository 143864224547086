import { useState } from 'react'
import { useSelector } from 'react-redux'
import useTickets from '../../hooks/useTickets'
import { Button, Col, Form, Input, Row } from 'antd'
import CustomModal from '../../../../UI/CustomModal'

const NewEditCategoryModal = ({ form, isOpenModal, handleOpenCloseModal, getAllTicketsCategories }) => {
  const [loading, setLoading] = useState(false)
  const { onSaveCategory } = useTickets()
  const currentCompanyId = useSelector((state) => state?.auth?.currentRol)
  const config = { rules: [{ required: true, message: "Por favor llenar el campo" }] }

  const onReset = () => form.resetFields()

  const onFinish = async (isSuccessful) => {
    if (isSuccessful) {
      onReset()
      handleOpenCloseModal()
      await getAllTicketsCategories(currentCompanyId)
      return
    }
    return null
  }

  const onSubmit = async (formData) => {
    setLoading(true)
    const response = await onSaveCategory({ ...formData, company_id: currentCompanyId })
    await onFinish(response)
    setLoading(false)
  }

  return (
    <CustomModal
      centered
      destroyOnClose
      isOpenModal={isOpenModal}
      onCancel={handleOpenCloseModal}
    >
      <Form
        name="newEditCategoryForm"
        className="standard-form"
        disabled={loading}
        layout="vertical"
        onFinish={onSubmit}
      >
        <Row justify="center" align="middle">
          <Col span={24}>
            <h2 className="title-modal">NUEVA CATEGORÍA</h2>

            <p style={{ color: "#676767", fontSize: "12px", lineHeight: "14px" }}>NOMBRE DE LA CATEGORIA</p>
            <Form.Item name="name" {...config}>
              <Input
                placeholder="Servicio al cliente"
                maxLength={30}
                size="large"
              />
            </Form.Item>

            <Row justify="center">
              <Button
                htmlType="submit"
                loading={loading}
                className="btn-standard-green"
                style={{ width: "250px" }}
                shape="round"
              >
                <span style={{ color: "#FFFFFF" }}>GUARDAR</span>
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default NewEditCategoryModal
