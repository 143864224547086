//Esta función permite simplificar el proceso para colocar data en una CustomTable
//Requiere la data y la metadata del endpoint, y opcionalmente un booleano que indica si la data es para un select de Ant design para añadir las propiedades correspondientes

export const getTableDataHelper = ({ data, metadata, isSelect }) => {
    let finalData = {}

    const newData = data.dataSource.map((item, index) => ({ ...item, key: index + 1 }))
    finalData = { ...data, dataSource: newData }
    
    if (isSelect) {
        const selectData = newData.map((item) => {
            return { ...item, value: item.id, label: item.name };
        });
        const tableData = { data: { ...data, dataSource: selectData }, metadata }
        return tableData
    }
    const tableData = { data: finalData, metadata }
    return tableData

}