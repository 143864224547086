import { Button } from "antd"
import { useEffect } from "react"
import useEmployee from "../hooks/useEmployee"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import { EMPLOYEE_CODES } from "../../../../constants/modules/employee/employeeCodes"
// IMAGENES O ARCHIVOS
import { PlusCircleOutlined } from "@ant-design/icons"
import { CustomSelect } from "../../../UI/CustomSelect"

const EmployeeActions = ({ companyId, setIsModalOpen, setDepartmentSelected, activeFeatures }) => {
  const { departments, getAllDepartments } = useEmployee()

  useEffect(() => {
    getAllDepartments(companyId)
  }, [])

  const deparmentsToSelect = departments?.map((department) => ({ ...department, value: department?.id, label: department?.name })) 

  return (
    <header className="actions-container" style={{ position: 'static' }}>
      <CustomSelect
        allowClear
        width="261px"
        placeholder="Seleccionar departamento"
        onChange={(value) => setDepartmentSelected(value)}
        options={deparmentsToSelect ?? []}
      />

      <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === EMPLOYEE_CODES.EMPLOYEE_NEW_EMPLOYEE)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nuevo Empleado'}`}>
        <Button
          type="text"
          htmlType="button"
          shape="round"
          onClick={() => setIsModalOpen(true)}
          className="btn-standard-green"
          disabled={!activeFeatures?.find(feature => feature.feature_code === EMPLOYEE_CODES.EMPLOYEE_NEW_EMPLOYEE)?.enabled}
        >
          <PlusCircleOutlined />
          <span>Nuevo Empleado</span>
        </Button>
      </CustomPopoverConfirm>
    </header >
  )
}

export default EmployeeActions
