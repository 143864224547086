import EmptyComponent from "../../../UI/EmptyComponent"
import useCurrentLocation from "../../../hooks/useCurrentLocation"
import AdminChecklistPage from "./checklistAdmin/AdminChecklistPage"
import ResultsChecklistTests from "./checklistResults/ResultsChecklistTests"

const TypeAdminCheckPage = () => {
  const { param } = useCurrentLocation()

  const verifyTypeCheckPage = (typeCheckIdPage) => {
    let objPages = {
      'adminTasks': <AdminChecklistPage />,
      'results': <ResultsChecklistTests />,
      'default': <EmptyComponent />
    }

    const page = objPages[typeCheckIdPage] || objPages['default']

    return page
  }

  return (
    <section className="section-wrapper">
      {verifyTypeCheckPage(param.checklistIdPage)}
    </section>
  )
}

export default TypeAdminCheckPage