import { CustomSpin } from '../../UI/CustomSpin'
import UsersTable from './components/UsersTable'
import { UsersProvider } from './context/UsersContext'
import { useFeatureRole } from '../../hooks/useFeatureRole'
import useOpenCloseModal from '../../hooks/useOpenCloseModal'
import FiltersUsersTable from './components/FiltersUsersTable'
import NewEditUserModal from './components/modals/NewEditUserModal'
import DetailUserDrawer from './components/drawer/DetailUserDrawer'
import UsersHeaderActions from './components/layout/UsersHeaderActions'
import { USERS_CODES, USERS_CODES_NAMES } from '../../../constants/modules/user/userCodes'

import './styles.css'

const UsersPage = () => {
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: USERS_CODES, validate_code: USERS_CODES_NAMES.USERS_MODULE, returnUrl: '/menu/settings',  })

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (
    <section className="section-wrapper">
      <UsersProvider>
        <UsersHeaderActions activeFeatures={activeFeatures} />

        <FiltersUsersTable />

        <UsersTable activeFeatures={activeFeatures} handleOpenCloseDrawer={handleOpenCloseModal} />

        <NewEditUserModal />

        <DetailUserDrawer isOpenDrawer={isOpenModal} handleOpenCloseDrawer={handleOpenCloseModal} />
      </UsersProvider>
    </section>
  )
}

export default UsersPage;