import CustomModal from '../../../../UI/CustomModal'
import useSurveysForm from '../../hooks/useSurveysForm'
import ModalFooter from './components/ModalFooter'
import StepOne from './components/StepOne'
import StepThree from './components/StepThree'
import StepTwo from './components/StepTwo'
import './styles.css'

export const MainModalSurveys = () => {
  //Reconfigurar este modal para que sean diferentes forms y al final unir toda la data, para evitar multiples modales y solo cargar el componente dentro de un único modal

  const { loading, currentStep, handleSubmit, setCurrentStep, formModal, saveTempRef, handleCloseForm } = useSurveysForm()

  const stepForm = {
    1: <StepOne />,
    2: <StepTwo />,
    3: <StepThree />,
  }

  return (
    <CustomModal
      title={<div className='custom-modal__form-title'>
        <small>PASO {currentStep} DE 3</small>
        <span>NUEVA ENCUESTA</span>
      </div>}
      className='custom-modal'
      width={680}
      isOpenModal={formModal}
      destroyOnClose={true}
      onCancel={handleCloseForm}
      footer={<ModalFooter currentStep={currentStep} setCurrentStep={setCurrentStep} handleSubmit={handleSubmit} saveTempRef={saveTempRef} loading={loading} />}>
      {stepForm[currentStep]}
    </CustomModal >
  )
}

export default MainModalSurveys