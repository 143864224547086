import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import topiaLogo from "../../assets/logos/topiaLogo.svg";

const antIcon = ({ color = '#1264e0' }) => (
  <LoadingOutlined
    style={{
      fontSize: 112,
      color,
    }}
  />
)

/**Este Spin está más enfocado a poderse utilizar para englobar todos los objetos de un section,
 * o si es en pantalla completa que utilizarlo en divs pequeños */
export const CustomSpin = ({
  fullScreen = false,
  normal = true,
  section = false,
  wrapperClassName = "custom-spin__wrapper",
  className = 'custom-spin',
  tip = "Cargando Datos",
  size = "large",
  spinning,
  customIcon = false,
  indicatorColor,
  icon = topiaLogo,
  children,
  height = '100%',
  maxWidth
 }) => (
    <Spin          
      style={{ height, maxWidth }}
      wrapperClassName={`${fullScreen ? 'custom-spin__wrapper__fullscreen' : wrapperClassName} ${normal ? 'normal' : ''} ${section ? 'section' : ''} `}
      className={`${className} ${normal ? 'normal' : ''} ${customIcon ? '' : 'custom-icon'} ${section ? 'section' : ''}`}
      tip={tip} 
      size={size}
      spinning={spinning}
      indicator={customIcon ? antIcon({ color: indicatorColor }) : undefined}
    >
      {
        spinning && customIcon && <img alt='spin-icon' width={80} height={80} className="custom-spin__logo" src={icon} />
      }
      <div className='custom-spin__containt-wrapper' style={{ opacity: spinning ? 0.5 : 1, height }}>
        {children}
      </div>
    </Spin>
)
