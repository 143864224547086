import moment from "moment"
import { useContext } from "react"
import { Button, Col, Row } from "antd"
import useTypesNews from "../hooks/useTypesNews"
import useRedirectTo from "../../../hooks/useRedirectTo"
import { TypesNewsContext } from "../context/TypesNewsContext"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import { verifyStatusColor } from "../../../helpers/verifyTypeColors"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { NEWS_CODES } from "../../../../constants/modules/news/newsCodes"
import { setCurrentTypeNewClicked } from "../../../../store/typesNews/typesNewsSlice"

// Imagenes o archivos
import { DeleteOutlined } from "@ant-design/icons"
import EditIcon from "../../../../assets/icons/EditIcon"
import { ReactComponent as TextIcon } from '../../../../assets/icons/textIcon.svg'
import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye.svg'

const TypeNew = ({
  activeFeatures,
  form,
  typeNewData,
  handleOpenCloseModal,
  handleOpenCloseDrawer,
}) => {
  const dispatch = useAppDispatch()

  const {
    setTitle,
    setIsUrl,
    setColor,
    setIcon,
    setValueRadio,
    setFileListImg,
    setFileListFiles,
    setValueRadioDateEnd,
  } = useContext(TypesNewsContext)

  const { handleRedirectTo } = useRedirectTo()
  const { loadingDetailTypeNew } = useAppSelector(state => state.news)
  const { getDetailByTypeIdNew, getFullDetailByTypeIdNew } = useTypesNews()

  let objBackgroundColors = {
    "Activa": '#B7F2E3',
    "Inactiva": '#E4E4E4',
    'default': ''
  }

  let objTextColorsStatus = {
    "Activa": '#098969',
    "Inactiva": '#898989',
    'default': ''
  }

  const onClickFullDetailTypeNew = async () => {
    dispatch(setCurrentTypeNewClicked(typeNewData))

    const response = await getFullDetailByTypeIdNew(typeNewData.news_id)

    if (response.success) {
      handleRedirectTo(`/news/${typeNewData.news_id}`)

      const isEvent = response.data.data?.NEWS_TYPE?.code === 'event' ? {
        date: moment(response.data.data?.NEWS_INVITE?.date),
        invite_from: response.data.data?.NEWS_INVITE?.invite_from,
        place: response.data.data?.NEWS_INVITE?.place,
        time_begin: (moment(response.data.data?.NEWS_INVITE?.time_begin, "h:mm:ss a")),
        time_end: (moment(response.data.data?.NEWS_INVITE?.time_end, "h:mm:ss a"))
      } : {}

      const files = response.data.data?.NEWS_FILEs?.length === 0
        ? []
        : response.data.data?.NEWS_FILEs?.map((file, index) => (
          {
            uid: crypto.randomUUID(),
            name: `Documento adjuntado anteriormente ${index}`,
            status: 'done',
            url: file.url,
          }))

      const filesImg = response.data.data?.NEWS_DETAIL?.image
        ? [{
          uid: crypto.randomUUID(),
          name: 'Documento adjuntado anteriormente',
          status: 'done',
          url: response.data.data?.NEWS_DETAIL?.image,
          type: 'image/',
        }]
        : []

      const isUrlLink = Boolean(response?.data?.data?.NEWS_ACTION_LINK?.action_link)
      const isDateEnd = response.data.data?.date_end ? 1 : 0

      setTitle(response.data.data?.NEWS_DETAIL?.title)
      setColor(response.data.data?.NEWS_DETAIL?.color)
      setIcon(response.data.data?.NEWS_DETAIL?.icon)

      setIsUrl(isUrlLink)
      setFileListFiles(files)
      setFileListImg(filesImg)

      setValueRadio(response.data.data?.NEWS_TYPE?.news_type_id)
      setValueRadioDateEnd(isDateEnd)

      // Falta hacer el set del formulario aqui
      form.setFieldsValue({
        ...isEvent,
        "show_banner": response.data.data?.show_banner ? ['1'] : [],
        "date_begin": response.data.data?.date_begin ? moment(response.data.data?.date_begin) : '',
        "date_end": response.data.data?.date_end ? moment(response.data.data?.date_end) : '',
        "action_link": response.data.data?.NEWS_ACTION_LINK?.action_link,
        "description": response.data.data?.NEWS_DETAIL?.description,
        'title': response.data.data?.NEWS_DETAIL?.title,
        "news_type_id": response.data.data?.NEWS_TYPE?.news_type_id,
        "time_begin_date": response.data.data?.date_begin ? moment(response.data.data?.date_begin) : '',
        "time_end_date": response.data.data?.date_end ? moment(response.data.data?.date_end) : ''
      })
    }
  }

  const onClickDetailTypeNew = async () => {
    dispatch(setCurrentTypeNewClicked(typeNewData))

    const response = await getDetailByTypeIdNew(typeNewData.news_id)

    if (response) handleOpenCloseDrawer()
  }

  const isTypeNewactive = typeNewData?.status ? "Activa" : "Inactiva"

  return <Row
    justify="space-between"
    align="middle"
    gutter={8}
    style={{
      width: '100%',
      margin: '24px 0',
      padding: '24px 40px 24px 40px',
      backgroundColor: '#FFFFFF',
      borderRadius: 7,
      boxShadow: '0px 6px 7px 0px #9AB9D440',
      opacity: loadingDetailTypeNew ? 0.5 : 1,
      cursor: loadingDetailTypeNew ? 'not-allowed' : 'default'
    }}
  >
    <Col span={8}>
      <Row align="middle" gutter={16} wrap={false}>
        <Col>
          <TextIcon className="btn-svg-icon-variant2" />
        </Col>

        <Col>
          <label className="label-text-type-new">
            {typeNewData?.date}
          </label>
          <p style={{ margin: 0, fontWeight: 500, fontSize: 16, color: '#232C4A' }}>
            {typeNewData?.title}
          </p>
        </Col>
      </Row>
    </Col>

    <Col span={3}>
      <label className="label-text-type-new">CATEGORÍA</label>
      <p style={{ margin: 0, fontFamily: 'Roboto', fontSize: 14, fontWeight: 500, color: '#10B48C', textTransform: 'uppercase' }}>
        {typeNewData?.type}
      </p>
    </Col>

    <Col span={3}>
      <Row justify="center">
        <Col>
          <label className="label-text-type-new">STATUS</label>
          <p style={{ margin: 0, borderRadius: 50, backgroundColor: verifyStatusColor(isTypeNewactive, objBackgroundColors) }}>
            <span style={{ fontWeight: 500, color: verifyStatusColor(isTypeNewactive, objTextColorsStatus), padding: '4px 12px 4px 12px' }}>
              {isTypeNewactive}
            </span>
          </p>
        </Col>
      </Row>
    </Col>

    <Col span={7}>
      <Row justify="end" align="middle" gutter={12}>
        <Col>
          <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === NEWS_CODES.NEWS_DETAILS_NEWS)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Ver detalles'}`}>
            <Button
              disabled={!activeFeatures?.find(feature => feature.feature_code === NEWS_CODES.NEWS_DETAILS_NEWS)?.enabled}
              type="text"
              shape="round"
              htmlType="button"
              style={{ width: 100, border: '1px solid var(--primary)' }}
              onClick={onClickDetailTypeNew}
            >
              <Row justify="space-evenly" align="middle">
                <Col>
                  <EyeIcon width={20} height={20} className="btn-svg-icon-variant3" />
                </Col>

                <Col>
                  <span>Ver</span>
                </Col>
              </Row>
            </Button>
          </CustomPopoverConfirm>
        </Col>
        <Col>
          <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === NEWS_CODES.NEWS_UPDATE_NEWS)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Editar'}`}>
            <Button
              disabled={!activeFeatures?.find(feature => feature.feature_code === NEWS_CODES.NEWS_UPDATE_NEWS)?.enabled}
              type="text"
              shape="round"
              htmlType="button"
              style={{ width: 103, border: '1px solid var(--primary)' }}
              onClick={onClickFullDetailTypeNew}
            >
              <Row justify="space-evenly" align="middle">
                <Col>
                  <div>
                    <EditIcon width="16" height="16" color="var(--primary)" />
                  </div>
                </Col>

                <Col>
                  <span>Editar</span>
                </Col>
              </Row>
            </Button>
          </CustomPopoverConfirm>
        </Col>

        <Col>
          <Button
            type="text"
            shape="circle"
            htmlType="button"
            style={{ border: '1px solid var(--primary)' }}
            onClick={() => {
              dispatch(setCurrentTypeNewClicked(typeNewData))
              handleOpenCloseModal()
            }}
            icon={<DeleteOutlined style={{ fontSize: 16, color: 'var(--primary)' }} />}
          />
        </Col>
      </Row>
    </Col>
  </Row>
}

export default TypeNew