import axios from "axios"

const getAllBookingsByStatusAndCompany = async ({
  idToken,
  companyId,
  reservationCode,
  limit,
  skip,
  sortBy,
  sortOrder,
  placeId,
  profileId,
  dateBegin,
  dateEnd,
}) => {
  try {
    const isLimit = limit ? `&limit=${limit}` : ''
    const isSkip = skip ? `&skip=${skip}` : ''
    const isSortBy = sortBy ? `&sort_by=${sortBy}` : ''
    const isSortOrder = sortOrder ? `&sort_order=${sortOrder}` : ''
    const isPlaceId = placeId ? `&place_id=${placeId}` : ''
    const isProfileId = profileId ? `&profile_id=${profileId}` : ''
    const isDateBegin = dateBegin ? `&date_begin=${dateBegin}` : ''
    const isDateEnd = dateEnd ? `&date_end=${dateEnd}` : ''

    const response = await axios.get(`https://domo.topiaapp.com/api/reservation/all?company_id=${companyId}&reservation_code=${reservationCode}${isLimit}${isSkip}${isSortBy}${isSortOrder}${isPlaceId}${isProfileId}${isDateBegin}${isDateEnd}`, {
      headers: { Authorization: `Bearer ${idToken}` },
      timeout: 10000
    })
  
    return {
      success: true,
      ...response
    }
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        ...error.response,
      };
    }
    if (error.request) {
      return {
        success: false,
        status: error.code,
        data: {
          errors: [{ msg: error.message }],
        },
      };
    }
  }
}

export default getAllBookingsByStatusAndCompany