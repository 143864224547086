import CustomModal from "../../../../UI/CustomModal";
import { Button, Row, DatePicker, Space, TimePicker, Form } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateInspectionScheduleThunk } from "../../../../../store/detailTicket/thunk";
import useTicketById from "../../hooks/useTicketById";

const InspectionsModal = ({ onCancel, isOpenModal }) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState([]);
  const { ticketById } = useTicketById();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Item } = Form;

  const onSubmit = () => {
    try {
      setLoading(true);
      dispatch(
        updateInspectionScheduleThunk(
          ticketById?.id,
          ticketById?.TICKET_EMPLOYEE?.employeeId,
          ticketById?.TICKET_INSPECTION_SCHEDULE?.id,
          date,
          time[0],
          time[1]
        )
      );
      form.resetFields();
    } catch (error) {
    } finally {
      onCancel();
      setLoading(false);
    }
  };

  const dateNewInspection = (date, dateString) => {
    setDate(dateString);
  };
  const workHours = (momentArr) => {
    const startTime = momentArr[0].format("HH:mm");
    const endTime = momentArr[1].format("HH:mm");
    setTime([startTime, endTime]);
  };

  // const validateDate = (_, dateString) => {
  //   if (!dateString || new Date(dateString) <= new Date()) {
  //     return Promise.reject("La fecha debe ser superior al día actual.");
  //   }
  //   return Promise.resolve();
  // };
  return (
    <CustomModal onCancel={onCancel} isOpenModal={isOpenModal}>
      <p
        style={{
          textAlign: "center",
          fontSize: "22px",
        }}
      >
        INSPECCIÓN
      </p>
      <Form
        className="standard-form"
        onFinish={onSubmit}
        disabled={loading}
        form={form}
      >
        <Item
          // rules={[
          //   { required: true, message: "Este campo es requerido." },
          //   { validator: validateDate },
          // ]}
          name="date"
        >
          <Row justify="center">
            <Space direction="vertical">
              <label style={{ color: "#676767", fontSize: "12px" }}>
                Fecha de inspección
              </label>
              <DatePicker
                placeholder="Seleccionar"
                style={{
                  width: "20rem",
                  background: "#F6F6F6",
                  borderRadius: 5,
                }}
                onChange={dateNewInspection}
              />
            </Space>
          </Row>
        </Item>
        <Item
          name="time"
          // rules={[{ required: true, message: "Este campo es requerido." }]}
        >
          <Row justify="center">
            <Space direction="vertical">
              <label style={{ color: "#676767", fontSize: "12px" }}>
                Hora de inicio y Hora de fin
              </label>
              <TimePicker.RangePicker
                onChange={workHours}
                style={{
                  width: "20rem",
                  background: "#F6F6F6",
                  borderRadius: 5,
                }}
              />
            </Space>
          </Row>
        </Item>

        <Row justify="center" style={{ marginTop: "3rem" }}>
          <Button
            className="btn-standard-green"
            shape="round"
            type="ghost"
            htmlType="submit"
          >
            <span>GUARDAR</span>
          </Button>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default InspectionsModal;
