import { useState } from "react"
import { getExportOrnamentChecklistExcel } from "../services"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { getEmployees } from "../../checklist/adminCheck/services/general/GET/getEmployees"
import { deleteOrnamentChecklist, getActiveOrnamentChecklistInstanceId, getOrnamentChecklists, getOrnamentStatusChecklist, newEditOrnamentChecklist } from "../../../../store/adminChecklist/thunks"
import verifyResponse from "../../../helpers/verifyResponse"

const useImageChecklist = () => {
  const dispatch = useAppDispatch()
  const [currentPage, setCurrentPage] = useState(1)

  const { filters } = useAppSelector(state => state.adminCheck)
  const { idToken, currentRol } = useAppSelector(state => state.auth)
  
  // Funcion para obtener checklist en el primer select
  const getChecklistInstanceId = async () => {
    await dispatch(getActiveOrnamentChecklistInstanceId())
  }

  const getExcelChecklist = async () => {
    const response = await getExportOrnamentChecklistExcel({ ...filters, companyId: currentRol, idToken })

    if (response.success) {
      return true
    }

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  // Funcion para obtener datos de la tabla
  const getChecklistsInstances = async ({
    checklistId,
    employeeId,
    dateBegin,
    dateEnd,
    checklistIdStatus,
    skip,
    limit,
    sortOrder,
    sortBy
  }) => {
    const response = await dispatch(getOrnamentChecklists({
      checklistId,
      employeeId,
      dateBegin,
      dateEnd,
      checklistIdStatus,
      skip,
      limit,
      sortOrder,
      sortBy
    }))

    return response
  }

  const getOrnamentStatus = async () => {
    await dispatch(getOrnamentStatusChecklist())
  }

  const getAllEmployees = async ({ find_by, find_value, limit, skip }) => {
    const response = await getEmployees({ find_by, find_value, limit, skip, token: idToken, company_id: currentRol })

    if (response.success) {
      const { data } = response

      const newResponse = {
       ...response,
       data: {
         ...response.data,
         data: {
           dataSource: data.data.dataSource.map((profileData) => ({ id: profileData.id, name: profileData.name })),
         }
       }
     }
      return newResponse
    }

    return [] 
  }

  const createUpdateChecklist = async (formData) => {
    const response = await dispatch(newEditOrnamentChecklist(formData))

    return response
  }

  const deleteChecklistInstance = async (formData) => {
    const response = await dispatch(deleteOrnamentChecklist(formData))

    return response
  }

  const changePageHandler = async (currentPag, limit) => {
    const pageValue = currentPag || currentPage
    const newSkip = (pageValue * limit) - limit
    
    setCurrentPage(pageValue)

    await dispatch(getOrnamentChecklists({ ...filters, skip: newSkip, limit }))
  }

  return {
    currentPage,
    getAllEmployees,
    getOrnamentStatus,
    changePageHandler,
    getExcelChecklist,
    createUpdateChecklist,
    getChecklistInstanceId,
    getChecklistsInstances,
    deleteChecklistInstance
  }
}

export default useImageChecklist