export const CustomDayHeader = ({ label }) => {
  return (
    <div
      style={{
        height: "34px",
        textAlign: "center",
        background: "transparent",
        color: "#404040",
        fontWeight: 600,
        fontSize: 12,
      }}
    >
      {label.charAt(3)}
    </div>
  );
};
