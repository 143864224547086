import { Button, Col, Form, Row } from "antd"
import useChecklist from "../../hooks/useChecklist"
import { useEffect, useState, useContext } from "react"
import CustomModal from "../../../../../UI/CustomModal"
import { config } from "../../../../../constants/constants"
import { CustomSelect } from "../../../../../UI/CustomSelect"
import { useAppSelector } from "../../../../../../hooks/store"
import { CustomDatePicker } from "../../../../../UI/CustomDatePicker"
import useEmployee from "../../../../employeedManagement/hooks/useEmployee"
import CustomTreeSelect from "../../../../../UI/CustomSelects/CustomTreeSelect"
import { HandoverChecklistContext } from "../../context/HandoverChecklistContext"
import CustomTimePicker from "../../../../../UI/CustomTimePicker/CustomTimePicker"
import { getAllHerarchyPath, verifyFatherHierarchy } from "../../../../../helpers/customSelectHelper"
import useCurrentLocation from "../../../../../hooks/useCurrentLocation"

const NewChecklistModal = ({
  loadingHierarchy,
  hierarchyData,
  getCheckListsByIdChecklist,
  checklistsPaginationData,
}) => {
  const [loading, setLoading] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)

  const { Item } = Form
  
  const { onSaveChecklist } = useChecklist()
  const { getEmployees, dataSource } = useEmployee()
  const { param } = useCurrentLocation()

  const { limit } = checklistsPaginationData
  const { currentRol } = useAppSelector((state) => state.auth)

  const { form, isOpenModal, handleOpenCloseModal, checklistSelected, cleanChecklistSelected } = useContext(HandoverChecklistContext)

  useEffect(()=> {
    getEmployees(currentRol)
  }, [])

  // Prepare data
  const employeesToSelect = dataSource?.map((employee) => ({ id: employee.id, name: employee.name }))
  const arrayFiltered = verifyFatherHierarchy(hierarchyData)

  const selectedPath = selectedValue ? getAllHerarchyPath(arrayFiltered, selectedValue) : null
  const label = selectedPath ? selectedPath.join(' > ') : ''

  const handleOnChange = (value) => setSelectedValue(value)

  const onReset = () => form.resetFields()

  const onFinish = async (isSuccessful) => {
    if (isSuccessful) {
      onReset()
      handleOpenCloseModal()
      setSelectedValue(null)
      cleanChecklistSelected()
      await getCheckListsByIdChecklist(param.checklistId, currentRol, limit, 0)
    }
  }

  const onSubmit = async (formData) => {
    setLoading(true)
    const isCreateOrEdit = checklistSelected.id ? { checklist_instance_id: checklistSelected.id } : { checklist_id: param.checklistId }

    const buildObject = {
      company_id: currentRol,
      ...isCreateOrEdit,
      session: [
        {
          time_begin: (formData.time_begin).format("HH:mm"),
          time_end: (formData.time_end).format("HH:mm"),
          date: (formData.date).format("YYYY-MM-DD"),
        }
      ],
      place_id: formData.place_id.value || formData.place_id,
      employee_id: formData.employee_id
    }

    const response = await onSaveChecklist(buildObject)
    onFinish(response)
    setLoading(false)
  }

  return (
    <CustomModal
      destroyOnClose
      isOpenModal={isOpenModal}
      onCancel={() => {
        setSelectedValue(null)
        handleOpenCloseModal()
        cleanChecklistSelected()
      }}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        name="newChecklistForm"
        disabled={loadingHierarchy || loading}
        className="standard-form-checklist"
        layout="vertical"
      >
        <Row justify="center">
          <Col span={24}>
            <h2 className="title-modal">{checklistSelected.id ? 'EDITAR' : 'NUEVA'} ENTREGA</h2>

            <label>EMPLEADO</label>
            <Item name="employee_id" {...config} style={{ marginTop: 12 }}>
              <CustomSelect
                allowClear
                placeholder="Seleccione un empleado"
                options={employeesToSelect ?? []}
              />
            </Item>

            <label>FECHA</label>
            <Item name="date" {...config} style={{ marginTop: 12 }}>
              <CustomDatePicker allowClear format="YYYY-MM-DD" disabledDateBeforeToday />
            </Item>

            <Row gutter={24}>
              <Col span={12}>
                <label>DESDE</label>
                <Item name="time_begin" {...config} style={{ marginTop: 12 }}>
                  <CustomTimePicker allowClear />
                </Item>
              </Col>

              <Col span={12}>
                <label>HASTA</label>
                <Item name="time_end" {...config} style={{ marginTop: 12 }}> 
                  <CustomTimePicker allowClear/>
                </Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Row justify="space-between">
                  <label>ESPACIOS</label>
                  <label style={{ color: "var(--primary)" }}>{selectedValue ? label : checklistSelected.place}</label>
                </Row>

                <CustomTreeSelect
                  allowClear
                  name="place_id"
                  config={config}
                  style={{ marginTop: 12 }}
                  treeDefaultExpandAll={checklistSelected.id}
                  treeData={arrayFiltered}
                  placeholder="Por favor seleccione un nivel"
                  onChange={handleOnChange}
                />
              </Col>
            </Row>

            <Row justify="center">
              <Button
                htmlType="submit"
                loading={loading}
                className="btn-standard-green"
                style={{ width: 250 }}
                shape="round"
              >
                <span style={{ color: "#FFFFFF" }}>{checklistSelected.id ? 'EDITAR' : 'ASIGNAR'}</span>
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default NewChecklistModal