import moment from "moment"

//Devolver codigos de error para las validaciones
export const validateUniqueDates = ({ items = [] }) => {
    let error = null
    if (items.some(item => !item || !item.date || !item.hours || (!item.minutes && item.minutes !== 0) || !item.typeTime)) return null
    const dates = items.map(item => `${item.date.clone().startOf('day').format('YYYY-MM-DD')}-${item.hours}:${item.minutes}-${item.typeTime}`)
    const uniqueDates = new Set(dates)
    if (uniqueDates.size !== items.length) { error = 'No pueden haber fechas de visitas exactamente iguales' }
    return error
}

export const validateItemDate = ({ item = {}, updatable }) => {
    let error = null
    const today = moment().startOf('day').utcOffset(0)
    const actualHours = moment().hours()
    const actualMinutes = moment().minutes()

    if (!item.date || updatable === false) return null

    if (today.isSame(item.date.clone().startOf('day').utcOffset(0), 'day')) {
        if ((item.typeTime === 'am' && item.hours === actualHours && item.minutes < actualMinutes) || (item.typeTime === 'pm' && item.hours + 12 === actualHours && item.minutes < actualMinutes)) return error = 'Verifica los minutos'
        if ((item.hours < actualHours && item.typeTime === 'am') || (item.hours + 12 < actualHours && item.typeTime === 'pm')) return error = 'La hora es menor a la hora actual'
    }
    return error
}