import axios from "axios"

const getAllAmenitiesByCompanyId = async ({
  companyId,
  find_by,
  find_value,
  idToken,
}) => {
  try {
    const isFindBy = find_by ? `&find_by=${find_by}` : ''
    const isFindValue = find_value ? `&find_value=${find_value}` : ''
    const response = await axios.get(`https://domo.topiaapp.com/api/place/amenity?company_id=${companyId}${isFindBy}${isFindValue}`, {
      headers: { Authorization: `Bearer ${idToken}` },
      timeout: 10000,
    })
    // Se prepara y moldea la data para que el customComponent Select trabaje sin problema
    const { data } = response

    const newResponse = {
      ...response,
      data: {
        ...response.data,
        data: {
          dataSource: data.data.map((amenityData) => ({ id: amenityData.place_id, name: amenityData.name })),
        }
      }
    }

    return {
      success: true,
      ...newResponse
    }
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        ...error.response,
      };
    }
    if (error.request) {
      return {
        success: false,
        status: error.code,
        data: {
          errors: [{ msg: error.message }],
        },
      };
    }
  }
}

export default getAllAmenitiesByCompanyId