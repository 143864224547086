import { useState, useEffect } from 'react';
import { getEmployeeThunk } from '../../../../store/detailTicket/thunk'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { clearCalendarThunk, ticketOfCalenderThunk, typeTicketsThunk } from '../../../../store/tickets/thunks'

const useCalendar = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)

  const { roles, currentRol } = useAppSelector((state) => state.auth)
  const calenderTickets = useAppSelector((state) => state?.tickets.calender)

  const ticketsFilters = !calenderTickets
    ? setLoading(true)
    : calenderTickets?.data?.map((ticket) => {
      return {
        id: ticket?.id,
        name: ticket?.title,
        date: ticket?.date?.split("T")[0],
        type: ticket?.typeId,
      }
    })
  const [tickets, setTickets] = useState(ticketsFilters)

  const today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let timeBegin = year + "-" + month + "-" + 1;
  let d = new Date(year, month, 0);
  let timeEnd = year + "-" + month + "-" + d.getDate();

  useEffect(() => {
    getInitialData()

    return () => {
      dispatch(clearCalendarThunk())
    }
  }, []);

  useEffect(() => {
    if (calenderTickets) {
      setTickets(ticketsFilters);
    }
  }, [calenderTickets]);

  const getInitialData = async() => {
    setLoading(true);
    await dispatch(getEmployeeThunk(currentRol));
    await dispatch(
      ticketOfCalenderThunk({
        timeBegin: timeBegin,
        timeEnd: timeEnd,
        companyId: currentRol,
      })
    )
    await dispatch(typeTicketsThunk())
    setLoading(false);
  }

  return { loading, setLoading, tickets, roles }
};

export default useCalendar;