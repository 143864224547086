import MoreActions from "./MoreActions"
import DeleteTestModal from "./modals/DeleteTestModal"
import useImageChecklist from "../hooks/useImageChecklist"
import CustomTable from "../../../UI/CustomTable/CustomTable"
import useOpenCloseModal from "../../../hooks/useOpenCloseModal"
import { verifyStatusColor } from "../../../helpers/verifyTypeColors"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { setFilters } from "../../../../store/adminChecklist/adminChecklistSlice"

const ImageChecklistTable = ({
  form,
  handleOpenCloseModalNewEdit
}) => {
  const dispatch = useAppDispatch()

  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { currentPage, changePageHandler, getChecklistsInstances } = useImageChecklist()
  const { filters, metadata, adminChecklists, loadingOrnamentChecklists } = useAppSelector(state => state.adminCheck)
  
  const { limit, quantity } = metadata
  const { columns, dataSource } = adminChecklists

  let objTextColors = {
    'done': '#16A07F',
    'schedule': '#59ADFF',
    'canceled': '#E82A2A',
    'inProgress': '#E4B228',
    'default': ''
  }

  // Agregando columnas extras a las columnas desde el backend
  const newStyledColumns = columns.map(((column) => {
    const statusColumn = column.dataIndex === 'status'
      ? {
        ...column,
        render: (text, record) => (
          <span style={{ color: verifyStatusColor(record.checklist_instance_status_code, objTextColors), textTransform: 'uppercase', fontWeight: 700, fontSize: 12 }}>
            {text}
          </span>
        )}
      : column


    const employeeColumn = column.dataIndex === 'employee'
      ? {
          ...column,
          sorter: true,
        }
      : {}

    const dateColumn = column.dataIndex === 'date'
      ? {
          ...column,
          sorter: true
        }
      : {}

    const instanceColumn = column.dataIndex === 'instance_name'
      ? {
          ...column,
          sorter: true
        }
      : {}

    return {
      ...statusColumn,
      ...employeeColumn,
      ...dateColumn,
      ...instanceColumn,
    }
  }))

  const newColumns = [
    ...newStyledColumns,
    {
      dataIndex: 'actions',
      key: 'actions',
      title: 'Acciones',
      render: (_, record) => <MoreActions form={form} dataChecklist={record} handleOpenCloseModalDelete={handleOpenCloseModal} handleOpenCloseModalNewEdit={handleOpenCloseModalNewEdit} />
    }
  ]

  const handleOnChangeTable = async ({ sorter }) => {
    if (sorter?.order) {
      await getChecklistsInstances({
        ...filters,
        sortBy: sorter?.order ? (sorter.columnKey === 'instance_name' ? 'checklist' : sorter.columnKey) : '',
        sortOrder: sorter?.order ? (sorter?.order === 'ascend' ? 'asc' : 'desc') : '',
      })

      dispatch(setFilters({
        ...filters,
        sortBy: sorter?.order ? (sorter.columnKey === 'instance_name' ? 'checklist' : sorter.columnKey) : '',
        sortOrder: sorter?.order ? (sorter?.order === 'ascend' ? 'asc' : 'desc') : '',
      }))

      return
    }

    dispatch(setFilters({
      ...filters,
      sortBy: '',
      sortOrder: '',
    }))
  }

  return (
    <>
      <CustomTable
        rowKey={(record) => record.checklist_instance_id}
        columns={newColumns ?? []}
        dataSource={dataSource ?? []}
        loading={loadingOrnamentChecklists}
        className="custom-table-variant-admin-check"
        onChange={handleOnChangeTable}
        pagination={{
          total: quantity,
          pageSize: limit,
          onChange: async (current) => {
            await changePageHandler(current, limit)
          },
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} de ${total}`;
          },
          current: currentPage,
        }}
      />

      <DeleteTestModal isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
    </>
  )
}

export default ImageChecklistTable