import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const libaryPath = 'document/library/active'

export const getAllPublishedDocuments = async ({
    token,
    company_id,
    skip,
    limit,
    sort_by,
    sort_order,
    find_by,
    find_value
}) => {

    try {
        const params = {
            company_id, limit, skip, find_by, find_value, sort_by, sort_order
        }

        const response = await axiosCreate.get(`${libaryPath}`, {
            params, timeout: 10000, headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return verifyBodyResponse(response, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }
}