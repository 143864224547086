import { Button, Col, Row } from 'antd'
import { CustomSelect } from '../../../../../UI/CustomSelect'
import { CustomInput } from '../../../../../UI/CustomInput'
import { ReactComponent as AddOptionIcon } from '../../../assets/icons/addOption.svg'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import selectIcon from '../../../assets/icons/selectIcon.svg'
import useSurveysForm from '../../../hooks/useSurveysForm'


const StepTwo = () => {
    const { onSubmitForm, handleRemoveQuestion, handleChangeQuestionType, formRef, form, Form, initialFormData } = useSurveysForm()
    const config = {
        rules: [{ required: true, message: 'Por favor, llenar el campo' }]
    }

    return (
        <Form
            className='survey-step-two'
            name='survey-step-two'
            form={form}
            ref={formRef}
            initialValues={initialFormData}
            onFinish={onSubmitForm}
        >
            <Form.List
                {...config}
                name={'listPreguntas'}>
                {
                    (fields, { add, remove }, { errors }) => {
                        return (
                            <>
                                {
                                    fields.map((field, index) => {
                                        return (
                                            <Col key={field.key}>
                                                <Row gutter={24}>
                                                    <Col>
                                                        <Form.Item {...config} label={<span className='question-label'>PREGUNTA #{index + 1}</span>} name={[field.name, 'type']}>
                                                            <CustomSelect onChange={(value) => handleChangeQuestionType({ field, value })} width='200px' options={[{ id: "qualify", name: "De valoración" }, { id: "option", name: "Opción Múltiple" }, { id: "commentary", name: "Respuesta Breve" }]} suffixIcon={selectIcon} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col>
                                                        {
                                                            fields.length > 1 && (
                                                                <Button className='btn-icon' onClick={() => { handleRemoveQuestion(field); remove(field.name) }} icon={<MinusCircleOutlined style={{ fontSize: 22 }} />} />
                                                            )
                                                        }
                                                    </Col>
                                                </Row>
                                                <Form.Item {...config} name={[field.name, 'question']}>
                                                    <CustomInput placeholder={'¿Como valoras el trabajo de Topia?'} />
                                                </Form.Item>
                                                <Form.Item name={[field.name, 'idR']} initialValue={0} noStyle>
                                                    <CustomInput hidden />
                                                </Form.Item>
                                                <Form.Item name={[field.name, 'posicion']} initialValue={index} noStyle>
                                                    <CustomInput hidden />
                                                </Form.Item>
                                                <Form.Item noStyle shouldUpdate={(prev, cur) => {
                                                    return (
                                                        prev["listPreguntas"][field.name]?.type !==
                                                        cur["listPreguntas"][field.name]?.type
                                                    );
                                                }}>
                                                    {
                                                        ({ getFieldValue }) => {
                                                            if (getFieldValue("listPreguntas")[field.name]?.type !== 'option') return
                                                            return (
                                                                <Form.List rules={[{ validator: (_, value) => value.length < 2 ? Promise.reject(new Error('Mínimo de 2 opciones')) : Promise.resolve() }]} name={[field.name, 'opciones']}>
                                                                    {
                                                                        (subFields, { add, remove }, { errors }) => {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        subFields.map((subField, index) => {
                                                                                            return (
                                                                                                <Row key={subField.key} gutter={16}>
                                                                                                    <Col offset={6} span={16}>
                                                                                                        <Form.Item {...config} label={`Opción #${index + 1}`} name={[subField.name, 'valor']}>
                                                                                                            <CustomInput />
                                                                                                        </Form.Item>
                                                                                                    </Col>
                                                                                                    <Col span={2}>
                                                                                                        {
                                                                                                            subFields.length > 1 && subFields.length !== index + 1 && (
                                                                                                                <Button className='btn-icon' onClick={() => remove(subField.name)} icon={<MinusCircleOutlined style={{ fontSize: 22 }} />} />
                                                                                                            )
                                                                                                        }
                                                                                                        {
                                                                                                            subFields.length === index + 1 && subFields.length < 10 && <Button className='btn-icon' onClick={() => add()} icon={<AddOptionIcon />} />
                                                                                                        }
                                                                                                    </Col>
                                                                                                    <Form.Item name={[subField.name, 'posicion']} initialValue={index} noStyle>
                                                                                                        <CustomInput hidden />
                                                                                                    </Form.Item>
                                                                                                    <Col offset={16}>
                                                                                                        <Form.ErrorList className='option-errors' errors={errors} />
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
                                                                    }
                                                                </Form.List>
                                                            )
                                                        }
                                                    }
                                                </Form.Item>
                                                <Row align='middle' justify='center'>
                                                    {fields.length === index + 1 && fields.length < 100 && <Button className='btn-add-question' onClick={() => add()} icon={<PlusCircleOutlined />}>Agregar otra pregunta</Button>}
                                                </Row>
                                            </Col>
                                        )
                                    })
                                }
                                <Form.ErrorList errors={errors} />
                            </>
                        )
                    }
                }
            </Form.List>
        </Form >
    )
}

export default StepTwo