import { Row, Col, Button, Form } from "antd"
import EditTaxDataModal from "../modals/EditTaxDataModal"
import useOpenCloseModal from "../../../../hooks/useOpenCloseModal"
import { useAppSelector } from "../../../../../hooks/store"

const TaxDataBody = () => {
  const [form] = Form.useForm()

  const { taxData } = useAppSelector(state => state.detailContracts)
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  const handleOnClick = () => {
    // Hacer seteo de formulario para editar
    handleOpenCloseModal()
    form.setFieldsValue({
      place_detail_tax_personhood_id: taxData?.placeDetailTax?.PLACE_DETAIL_TAX_PERSONHOOD?.id,
      dni : taxData?.placeDetailTax?.dni,
      tax_name: taxData?.placeDetailTax?.tax_name,
      name: taxData?.placeDetailTax?.PLACE_DETAIL?.name,
      contact_name: taxData?.placeDetailContact?.name,
      address: taxData?.placeDetailTax?.address,
      phone_area: taxData?.placeDetailContact?.phone_area,
      phone_number: taxData?.placeDetailContact?.phone_number,
      mail: taxData?.placeDetailContact?.mail,
    })
  }

  return (
    <section style={{
      width: "100%",
      padding: 40,
      backgroundColor: "#FFFFFF",
      borderRadius: 8,
      boxShadow: "0px 6px 7px 0px #9AB9D440",
    }}>
      <Row>
        <Col span={24}>
          <h3 style={{ fontWeight: 500, fontSize: 22, margin: "20px 0" }}>Datos fiscales</h3>

          <Row style={{ backgroundColor: "#F5F9F9" }} align="middle">
            <Col span={12}>
              <label style={{ color: "#8C8C8C", fontSize: 14, fontWeight: 400, padding: 10, margin: "0 25px" }}>
                Tipo de Personía:
              </label>
            </Col>

            <Col span={12}>
              <Row justify="start">
                <label style={{ color: "#3F3F3F", fontSize: 14, fontWeight: 500, padding: 10, margin: "0 25px" }}>
                  {(taxData?.placeDetailTax?.PLACE_DETAIL_TAX_PERSONHOOD?.name) ?? 'No hay datos ingresados'}
                </label>
              </Row>
            </Col>
          </Row>

          <Row align="middle">
            <Col span={12}>
              <label style={{ color: "#8C8C8C", fontSize: 14, fontWeight: 400, padding: 10, margin: "0 25px" }}>
                Nombre comercial:
              </label>
            </Col>

            <Col span={12}>
              <Row justify="start">
                <label style={{ color: "#3F3F3F", fontSize: 14, fontWeight: 500, padding: 10, margin: "0 25px" }}>
                  {(taxData?.placeDetailTax?.tax_name) ?? 'No hay datos ingresados'}
                </label>
              </Row>
            </Col>

          </Row>

          <Row align="middle" style={{ backgroundColor: "#F5F9F9" }}>
            <Col span={12}>
              <label style={{ color: "#8C8C8C", fontSize: 14, fontWeight: 400, padding: 10, margin: "0 25px" }}>
                Nombre o razón social (Según NIT):
              </label>
            </Col>

            <Col span={12}>
              <Row justify="start">
                <label style={{ color: "#3F3F3F", fontSize: 14, fontWeight: 500, padding: 10, margin: "0 25px" }}>
                  {(taxData?.placeDetailTax?.PLACE_DETAIL?.name) ?? 'No hay datos ingresados'}
                </label>
              </Row>
            </Col>
          </Row>

          <Row align="middle">
            <Col span={12}>
              <label style={{ color: "#8C8C8C", fontSize: 14, fontWeight: 400, padding: 10, margin: "0 25px" }}>
                Nombre de representante legal:
              </label>
            </Col>

            <Col span={12}>
              <Row justify="start">
                <label style={{ color: "#3F3F3F", fontSize: 14, fontWeight: 500, padding: 10, margin: "0 25px" }}>
                  {(taxData?.placeDetailContact?.name) ?? 'No hay datos ingresados'}
                </label>
              </Row>
            </Col>

          </Row>

          <Row align="middle" style={{ backgroundColor: "#F5F9F9" }}>
            <Col span={12}>
              <label style={{ color: "#8C8C8C", fontSize: 14, fontWeight: 400, padding: 10, margin: "0 25px" }}>
                NIT:
              </label>
            </Col>

            <Col span={12}>
              <Row justify="start">
                <label style={{ color: "#3F3F3F", fontSize: 14, fontWeight: 500, padding: 10, margin: "0 25px" }}>
                  {(taxData?.placeDetailTax?.dni) ?? 'No hay datos ingresados'}
                </label>
              </Row>
            </Col>
          </Row>

          <Row align="middle">
            <Col span={12}>
              <label style={{ color: "#8C8C8C", fontSize: 14, fontWeight: 400, padding: 10, margin: "0 25px" }}>
                Dirección:
              </label>
            </Col>

            <Col span={12}>
              <Row justify="start">
                <label style={{ color: "#3F3F3F", fontSize: 14, fontWeight: 500, padding: 10, margin: "0 25px" }}>
                  {(taxData?.placeDetailTax?.address) ?? 'No hay datos ingresados'}
                </label>
              </Row>
            </Col>

          </Row>

          <Row align="middle" style={{ backgroundColor: "#F5F9F9" }}>
            <Col span={12}>
              <label style={{ color: "#8C8C8C", fontSize: 14, fontWeight: 400, padding: 10, margin: "0 25px" }}>
                Correo electrónico:
              </label>
            </Col>

            <Col span={12}>
              <Row justify="start">
                <label style={{ color: "#3F3F3F", fontSize: 14, fontWeight: 500, padding: 10, margin: "0 25px" }}>
                  {(taxData?.placeDetailContact?.mail) ?? 'No hay datos ingresados'}
                </label>
              </Row>
            </Col>
          </Row>

          <Row align="middle">
            <Col span={12}>
              <label style={{ color: "#8C8C8C", fontSize: 14, fontWeight: 400, padding: 10, margin: "0 25px" }}>
                Cod. Área:
              </label>
            </Col>

            <Col span={12}>
              <Row justify="start">
                <label style={{ color: "#3F3F3F", fontSize: 14, fontWeight: 500, padding: 10, margin: "0 25px" }}>
                  {(taxData?.placeDetailContact?.phone_area) ?? 'No hay datos ingresados'}
                </label>
              </Row>
            </Col>
          </Row>

          <Row align="middle" style={{ backgroundColor: "#F5F9F9" }}>
            <Col span={12}>
              <label style={{ color: "#8C8C8C", fontSize: 14, fontWeight: 400, padding: 10, margin: "0 25px" }}>
                Teléfono:
              </label>
            </Col>

            <Col span={12}>
              <Row align="middle">
                <label style={{ color: "#3F3F3F", fontSize: 14, fontWeight: 500, padding: 10, margin: "0 25px" }}>
                  {(taxData?.placeDetailContact?.phone_number) ?? 'No hay datos ingresados'}
                </label>
              </Row>
            </Col>
          </Row>
          
          <Row justify="end">
            <Button
              type="text"
              htmlType="button"
              shape="round"
              onClick={handleOnClick}
              className="btn-standard-green"
              style={{ width: 250, marginTop: 40 }}
            >
              <span>EDITAR</span>
            </Button>
          </Row>
        </Col>
      </Row>
      <EditTaxDataModal form={form} isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
    </section>
  )
}

export default TaxDataBody