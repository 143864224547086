import { axiosCreate } from "../../../../../config/index"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const companyAssetPath = "company/asset"

export const getAllCompanyAssets = async ({
  idToken,
  company_id,
  formData,
  find_by,
  find_value,
  sort_by,
  sort_order,
  company_asset_status_id,
  limit = 10,
  skip = 0,
  isToSelect = false
}) => {
  try {
    const params = {
      company_id,
      limit,
      skip,
      find_by,
      find_value,
      sort_by,
      sort_order,
      company_asset_status_id,
      ...formData
    }

    const response = await axiosCreate.get(`${companyAssetPath}`, {
      params,
      timeout: 20000,
      headers: { Authorization: `Bearer ${idToken}`}
    })

    if (isToSelect) {
      const { data } = response

      const newResponse = {
        ...response,
        data: {
          ...response.data,
          data: {
            dataSource: data.data.dataSource.map((activesData) => ({ id: activesData?.company_asset_id, name: `${activesData?.name} | ${activesData?.code ? activesData.code : '---'}` })),
          }
        }
      }
      return ({
        success: true,
        ...newResponse
      })
    }

    return verifyBodyResponse(response, true);
} catch (error) {
    return verifyBodyResponse(error, false);
  }
}