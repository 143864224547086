import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const companyMaintenancePath = 'company/asset/report'

export const createMaintenanceReport = async ({
    token,
    formData
}) => {
    try {
        const response = await axiosCreate.post(`${companyMaintenancePath}`, formData, {
            timeout: 20000, headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": 'multipart/form-data'
            },
        });

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
}