import axios from "axios"

const updatePlaceDetailTax = async (formData, idToken) => {
  try {
    const response = await axios.put('https://domo.topiaapp.com/api/place/detail/tax', formData, {
      headers: { Authorization: `Bearer ${idToken}` }
    })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: true,
      ...error.response
    }
  }
}

export default updatePlaceDetailTax