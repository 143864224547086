import { notification } from "antd";

export const customNotification = ({
  icon,
  message,
  description,
  top,
  bottom,
  className = "custom-notification",
  notificationType = "open",
  placement = "top",
  duration = 0,
  maxWidth
}) => {
  const args = {
    message,
    description,
    duration: duration,
    icon: icon ? <img src={icon} /> : undefined,
    placement,
    className,
    top,
    bottom,
    style:{maxWidth}    
  };
  notification[notificationType](args);
};
