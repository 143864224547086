import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../../topia/helpers/verifyBodyResponse";

const themeFullPath = 'theme/full'

//Endpoint de topia que retorna la información de los colores del tema y las imagenes para el template de login solicitado a partir del id de la cuenta
export const getTemplate = async ({ idTemplateRoute }) => {
  try {
    //Si hay id desde la ruta se cargará dicho template sin importar lo que exista en el localStorage y reemplazar lo que ya estaba dentro
    const params = {
      company_id: idTemplateRoute
    }
    const res = await axiosCreate.get(`${themeFullPath}`, {
      params
    })
    return verifyBodyResponse(res, true)

  } catch (error) {
    return verifyBodyResponse(error, false)
  }
};
