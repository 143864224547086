import { CustomSpin } from "../../UI/CustomSpin"
import { useFeatureRole } from "../../hooks/useFeatureRole"
import useOpenCloseModal from "../../hooks/useOpenCloseModal"
import ChecklistsAdminTable from "./components/ChecklistsAdminTable"
import FiltersChecklistsAdmin from "./components/FiltersChecklistsAdmin"
import { ChecklistsAdminProvider } from "./context/ChecklistsAdminContext"
import HeaderChecklistsAdmin from "./components/layout/HeaderChecklistsAdmin"
import DeleteChecklistAdminModal from "./components/modals/DeleteChecklistAdminModal"
import NewEditChecklistAdminModal from "./components/modals/NewEditChecklistAdminModal"
import { HANDOVER_CHECKLIST_CODES, HANDOVER_CHECKLIST_CODES_NAMES } from "../../../constants/modules/handoverChecklist/handoverChecklistCodes"

const ChecklistsAdminPage = () => {
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: HANDOVER_CHECKLIST_CODES, validate_code: HANDOVER_CHECKLIST_CODES_NAMES.HANDOVER_CHECKLIST_MODULE, returnUrl: '/menu/modules' })

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (<section className="section-wrapper">
    <ChecklistsAdminProvider>
      <HeaderChecklistsAdmin activeFeatures={activeFeatures} />

      <FiltersChecklistsAdmin />

      <ChecklistsAdminTable activeFeatures={activeFeatures} handleOpenCloseDeleteModal={handleOpenCloseModal} />

      <NewEditChecklistAdminModal />

      <DeleteChecklistAdminModal isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
    </ChecklistsAdminProvider>
  </section>
  )
}

export default ChecklistsAdminPage