import moment from "moment"
import { useState } from "react"
import useLibraryServices from "./useLibraryServices"
import { useDebounce } from "../../../hooks/useDebounce"
import { useLibraryContext } from "../context/LibraryContext"

export const useLibrary = () => {

    const { auth, dataTableLibrary, limitLibrary, currentPageLibrary, currentParamsLibrary, loading, currentDocument, serviceParams, quantityLibrary,
        setCurrentPageLibrary, setCurrentParamsLibrary, setDataTableLibrary, setLastSkipLibrary, setLoading, setMetaDataLibrary, handleOpenCloseModal, setColumnsLibrary
    } = useLibraryContext()

    const { getAllDocumentsService, createNewDocumentService, deleteDocumentService } = useLibraryServices()

    const [fileList, setFileList] = useState([])
    const getInitialData = async () => {
        const { data, metadata } = await getAllDocumentsService({ limit: limitLibrary, skip: 0, previousData: dataTableLibrary, currentPage: currentPageLibrary, setLoading })
        setDataTableLibrary(data.dataSource)
        setColumnsLibrary(data.columns);
        setMetaDataLibrary(metadata)
        setCurrentParamsLibrary({ find_by: undefined, find_value: undefined, skip: 0, sort_by: undefined, sort_order: undefined })
        setLastSkipLibrary(0)
        setCurrentPageLibrary(1)
        setLoading(false)
    }

    const debounceSearch = useDebounce(async ({ value }) => {
        const actualParams = { ...currentParamsLibrary }
        const newParams = { ...actualParams, find_by: 'name', find_value: value }
        setLoading(true)
        if (!value) {
            delete newParams.find_by
            delete newParams.find_value
        }
        const { data, metadata } = await getAllDocumentsService({ ...newParams, ...serviceParams, skip: 0, setLoading })
        setDataTableLibrary(data.dataSource)
        setMetaDataLibrary(metadata)
        setCurrentParamsLibrary(newParams)
        setCurrentPageLibrary(1)
        setLastSkipLibrary(0)
        setLoading(false)
    }, 300)

    const handleSearch = (e) => {
        const value = e.target.value
        debounceSearch({ value })
    }

    const handleChangeDatePicker = ({ value, form }) => {
        const date = moment(value)
        const endPublication = form.getFieldValue('endPublication')

        form.setFieldsValue({
            publish_end_date: endPublication === 'day' ? date.add(1, 'day') : undefined
        })
    }

    const onSubmit = async ({ values, form, formName }) => {
        setLoading(true)
        if (formName === 'delete') {
            //Eliminar el documento
            await deleteDocumentService({ document_id: currentDocument.id, setLoading })
            await getInitialData()
            handleOpenCloseModal('delete')
            return setLoading(false)
        }

        const formData = new FormData()
        formData.append('company_id', auth.currentRol)
        formData.append('profile_id', auth.profileId)
        formData.append('name', values.name)
        formData.append('publish_start_date', moment(values.publish_start_date).format('YYYY-MM-DD'))
        if (values?.publish_end_date) {
            formData.append('publish_end_date', moment(values.publish_end_date).format('YYYY-MM-DD'))
        }
        formData.append('file', fileList[0].originFileObj)

        await createNewDocumentService({ formData, setLoading })
        await getInitialData()
        form.resetFields()
        setFileList([])
        handleOpenCloseModal()
        setLoading(false)
    }

    return {
        getInitialData,
        handleSearch,
        onSubmit,
        handleOpenCloseModal,
        handleChangeDatePicker,
        setFileList,
        loading,
        quantityLibrary,
        currentDocument,
        fileList,
        dataTableLibrary,
    }
}

export default useLibrary