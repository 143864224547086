import { Button, Popconfirm } from 'antd'
import { DeleteTableItemIcon } from '../assets'
import { CustomSearchInputFilter } from '../../../UI/CustomSearchInputFilter/CustomSearchInputFilter'
import { SearchOutlined } from '@ant-design/icons'
import CustomPopoverConfirm from '../../../UI/CustomPopoverConfirm'
import { ACCESS_PROVIDERS_CODES } from '../../../../constants/modules/accessProviders/accessProvidersCodes'
import { columnsOrderedTable } from '../../../helpers/columnsTableHelper'

//Helper que contiene el array inicial de las columna para la tabla de proveedores
export const providersTableColumnsPrev = ({ handleDelete, loading, activeFeatures, columns = [] }) => {
    const defaultColumns = [
        {
            title: 'NOMBRE DEL PROVEEDOR',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: '90%'
        },
        {
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_DELETE_PROVIDER)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Eliminar proveedor'}`}>
                    <Popconfirm icon={<DeleteTableItemIcon />}
                        disabled={!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_DELETE_PROVIDER)?.enabled}
                        okButtonProps={{ className: 'custom-popover__ok-button', shape: 'round', loading }}
                        cancelButtonProps={{ className: 'custom-popover__cancel-button', shape: 'round', disabled: loading }}
                        overlayClassName='custom-popover'
                        placement='leftTop'
                        title={'¿Seguro que quieres eliminar el proveedor?'}
                        onConfirm={() => handleDelete({ id: record?.id, name: 'providersTable' })}
                        okText="ELIMINAR"
                        cancelText="CANCELAR"
                    >
                        <Button disabled={!activeFeatures?.find(feature => feature.feature_code === ACCESS_PROVIDERS_CODES.ACCESS_PROVIDERS_DELETE_PROVIDER)?.enabled} style={{ background: 'transparent', border: 'none', boxShadow: 'none' }} size='small' icon={<DeleteTableItemIcon />} />
                    </Popconfirm>
                </CustomPopoverConfirm>
            )
        }
    ]

    return columns.length ? columnsOrderedTable({ defaultColumns, backendColumns: columns, titleUpperCase: true }) : defaultColumns
}

//Helper que contiene los filtros correspondientes a las columnas de la tabla de proveedores
export const providersTableFilters = ({ currentFilters, handleReset, handleSearch }) => ({
    name: () => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <CustomSearchInputFilter dataIndex={'name'} placeholder={'Buscar proveedor'} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} confirm={confirm} handleReset={handleReset} handleSearch={handleSearch} clearFilters={clearFilters} />
        ),
        filterIcon: () => <SearchOutlined />,
        filteredValue: currentFilters?.name || ""
    }),
    default: () => ({})
})