import { useContext } from "react"
import { Col, Row, Typography } from "antd"
import CustomDropdown from "../../../../../../UI/CustomDropdown"
import { AmenitiesContext } from "../../../../context/AmenitiesContext"
// Imagenes o archivos
import EditIcon from './../../../../../../../assets/icons/EditIcon'
import TrashIcon from './../../../../../../../assets/icons/TrashIcon'
import TreePointsMoreIcon from "../../../../../../../assets/icons/Generals/TreePointsMoreIcon"

const { Text } = Typography

const ReportingRestriction = ({ form, restrictionData, handleOpenCloseModal }) => {
  const { restrictions, setRestrictions, setCurrentRestrictionClicked } = useContext(AmenitiesContext)

  const items = [
    {
      key: 0,
      label: "Eliminar",
      icon: <TrashIcon color="#4D4D4D" />,
      action: (sectionId) => handleOnClickOptions(sectionId),
    },
    {
      key: 1,
      label: "Editar",
      icon: <EditIcon color="#4D4D4D" /> ,
      action: (sectionId) => handleOnClickOptions(sectionId),
    },
  ]

  const handleOnClickOptions = (typeAction) => {
    setCurrentRestrictionClicked(restrictionData)
    let objUpdateRestrictionArray = {
      0: () => {
        const newRestrictions = restrictions?.filter((restriction) => restriction.id !== restrictionData.id)
        setRestrictions(newRestrictions)
      },
      1: () => {
        handleOpenCloseModal()
        form.setFieldsValue({
          "title": restrictionData.title,
          "description": restrictionData.description
        })
      },
      'default': () => {},
    }

    let action = objUpdateRestrictionArray[typeAction] || objUpdateRestrictionArray['default']
    return action()
  }
    
  return (
    <Col span={8}>
      <Row style={{ padding: 15, background:'#F5F8FF', borderRadius: 16, border: '1px solid #DDDDDD' }}>
        <Col span={24}>
          <Row align="middle" justify="space-between" gutter={24}>
            <Col>
              <h3 style={{ fontWeight: 600, fontSize: 16, color: "#000000", maxWidth: 184, margin: 0 }}>
                <Text
                  ellipsis={{ tooltip: restrictionData?.title ?? '' }}
                >
                  {restrictionData?.title}
                </Text>
              </h3>
            </Col>

            <Col>
              <CustomDropdown typeShowMenu="click" items={items} styleMenu={{ width: 114 }}>
                <div>
                  <TreePointsMoreIcon color="#A4A4A4" />
                </div>
              </CustomDropdown>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <label style={{ fontWeight: 400, fontSize: 10, color: '#585858' }}>
                Descripción
              </label>

              <p style={{ fontWeight: 400, fontSize: 10, color: '#252525', maxWidth: 330 }}>
                <Text
                  ellipsis={{ tooltip: restrictionData?.description ?? '' }}
                >
                  {restrictionData?.description}
                </Text>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}

export default ReportingRestriction