import { Col, Row } from "antd"
import { useContext } from "react"
import ActionsButtons from "./ActionsButtons"
import useAmenities from "../hooks/useAmenities"
import CustomSwitch from './../../../UI/CustomSwitch'
import CustomTable from "../../../UI/CustomTable/CustomTable"
import { AmenitiesContext } from "../context/AmenitiesContext"
import DeleteAmenityModal from './../modals/DeleteAmenityModal'
import useOpenCloseModal from './../../../hooks/useOpenCloseModal'
import { AMENITIES_CODES } from "../../../../constants/modules/amenities/amenitiesCodes"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"

const AmenitiesTable = ({ activeFeatures }) => {
  const { amenities } = useContext(AmenitiesContext)
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  const { loading, metadata, changePageHandler, currentPage, onUpdateAmenityStatus, getAllAmenities } = useAmenities()
  const { quantity, limit } = metadata

  const handleOnClickSwitch = async (placeId) => {
    await onUpdateAmenityStatus(placeId)
    await getAllAmenities()
  }

  const columns = [{
    key: 'amenity_name',
    dataIndex: 'amenity_name',
    title: 'NOMBRE DE AMENIDAD',
    width: '94%',
    render: (text, record) => (
      <Row align="middle" style={{ borderRight: '1px solid #C1C1C1' }}>
        <Col span={16}>
          <label style={{ fontWeight: 600, fontSize: 14, color: '#404040' }}>
            {text}
          </label>
        </Col>

        <Col span={8}>
          <Row justify="end" align="middle">
            <ActionsButtons activeFeatures={activeFeatures} amenityData={record} handleOpenCloseModal={handleOpenCloseModal} />
          </Row>
        </Col>
      </Row>
    )
  }, {
    key: 'active',
    dataIndex: 'active',
    title: 'ESTADO',
    align: "center",
    render: (text, record) => (
      <CustomPopoverConfirm placement={'left'} trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === AMENITIES_CODES.AMENITIES_CHANGE_STATUS_AMENITY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Cambiar estado'}`}>
        {' '}
        <CustomSwitch
          disabled={!activeFeatures?.find(feature => feature.feature_code === AMENITIES_CODES.AMENITIES_CHANGE_STATUS_AMENITY)?.enabled}
          defaultChecked={record.active} loading={loading} onClick={() => handleOnClickSwitch(record.place_id)}
        />
      </CustomPopoverConfirm>
    )
  }]

  return (
    <>
      <CustomTable
        className="amenities-table"
        loading={loading}
        columns={columns ?? []}
        dataSource={amenities ?? []}
        rowKey={(record) => record.place_id}
        pagination={{
          total: quantity ?? 0,
          pageSize: limit ?? 10,
          onChange: async (current) => {
            await changePageHandler(current, limit)
          },
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} de ${total}`;
          },
          current: currentPage,
        }}
      />

      <DeleteAmenityModal isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
    </>
  )
}

export default AmenitiesTable