import { Col, Row } from "antd"
// import graphicsIcon from '../../../../assets/icons/Generals/pastel-graphics.svg'
import { SummaryInfo } from "../../../../pages/checklist/activityMonitoring/components/ActivityMonitoring/components/SummaryInfo"

const TasksStatistics = ({
  dailyTasks,
  dateDaily,
  totalDailyPercent,
  addZeroToNumber,
  handleChangeDateDailyTask,
}) => {
  return (
    <Row style={{ backgroundColor: "#FFFFFF", borderRadius: 16 }}>
      <Col span={24} style={{ padding: 15 }}>
        <h2 style={{ fontWeight: 600, fontSize: 22 }}>TAREAS PROGRAMADAS</h2>

        <SummaryInfo isToHomeData dailyTasks={dailyTasks} dateDaily={dateDaily} totalDailyPercent={totalDailyPercent} addZeroToNumber={addZeroToNumber} handleChangeDateDailyTask={handleChangeDateDailyTask} />

        {/* <Row justify="center">-
          <img src={graphicsIcon} alt="pastelGrafics" />
        </Row>

        {tasksData?.map((taskData) => <TaskData taskData={taskData} />)} */}
      </Col>
    </Row>
  )
}

// const TaskData = ({ taskData }) => (
//     <Row
//       key={taskData.id}
//       align="middle"
//       style={{ height: 56, backgroundColor: '#F7F9FF', borderRadius: 8, margin: '10px 0', paddingLeft: 25 }}
//     >
//       <Col style={{ borderRadius: 15, backgroundColor: taskData.color }}>
//         <span style={{ padding: '5px 15px', fontWeight: 600, fontSize: 12, color: '#FFFFFF' }}>
//           {taskData.dataNum}
//         </span>
//       </Col>

//       <Col>
//         <label style={{ paddingLeft: 10, fontWeight: 400, fontSize: 12, color: '#4F4F4F' }}>
//           {taskData.label}
//         </label>
//       </Col>
//     </Row>
// )

// const tasksData = [
//   {
//     id: 1,
//     label: 'Tareas asignadas',
//     dataNum: '90',
//     color: 'var(--primary)'
//   },
//   {
//     id: 2,
//     label: 'Tareas en progreso',
//     dataNum: '20',
//     color: '#FAC361'
//   },
//   {
//     id: 3,
//     label: 'Tareas en retraso',
//     dataNum: '16',
//     color: '#F06969'
//   }
// ]

export default TasksStatistics