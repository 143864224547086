
export const USERS_CODES = {
    USERS_MODULE: 'FEAT-284',
    USERS_DETAILS_USER: 'FEAT-39',
    USERS_TABLE: 'FEAT-274',
    USERS_NEW_USER: 'FEAT-334',
    USERS_UPDATE_USER: 'FEAT-335',
}

export const USERS_CODES_NAMES = {
    USERS_MODULE: 'USERS_MODULE',
    USERS_DETAILS_USER: 'USERS_DETAILS_USER',
    USERS_TABLE: 'USERS_TABLE',
    USERS_NEW_USER: 'USERS_NEW_USER',
    USERS_UPDATE_USER: 'USERS_UPDATE_USER',
}