import { useEffect } from 'react'
import useGenerals from './hooks/useGenerals'
import useChecklist from './hooks/useChecklist'
import { CustomSpin } from '../../../UI/CustomSpin'
import HeaderActions from "./components/HeaderActions"
import { useFeatureRole } from '../../../hooks/useFeatureRole'
import HandoverChecklistTable from "./components/HandoverChecklistTable"
import NewEditChecklistModal from "./components/modals/NewEditChecklistModal"
import { HandoverChecklistProvider } from './context/HandoverChecklistContext'
import { HANDOVER_CHECKLIST_CODES, HANDOVER_CHECKLIST_CODES_NAMES } from '../../../../constants/modules/handoverChecklist/handoverChecklistCodes'

import './style.css'

const HandoverChecklist = () => {
  const { loadingHierarchy, allHierarchyData, getAllHierarchyData } = useGenerals()
  const {
    getData,
    loading,
    checklists,
    currentPage,
    changePageHandler,
    checklistsPaginationData,
    getCheckListsByIdChecklist
  } = useChecklist()
  
  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: HANDOVER_CHECKLIST_CODES, validate_code: HANDOVER_CHECKLIST_CODES_NAMES.HANDOVER_CHECKLIST_ADMIN_TYPE_CHECKLIST, returnUrl: '/checklists-admin' })
  
  useEffect(() => {
    getData()
  }, [])

  if (loading || loadingFeatures) return <CustomSpin size='large' tip='Cargando módulo' />

  return <HandoverChecklistProvider>
    <section className="section-wrapper">
      <HeaderActions
        activeFeatures={activeFeatures}
        getAllHierarchyData={getAllHierarchyData}
        getCheckListsByIdChecklist={getCheckListsByIdChecklist}
      />

      <HandoverChecklistTable
        checklists={checklists}
        currentPage={currentPage}
        activeFeatures={activeFeatures}
        changePageHandler={changePageHandler}
        getAllHierarchyData={getAllHierarchyData}
        checklistsPaginationData={checklistsPaginationData}
      />

      <NewEditChecklistModal
        loading={loadingHierarchy}
        hierarchyData={allHierarchyData}
        checklistsPaginationData={checklistsPaginationData}
        getAllHierarchyData={getAllHierarchyData}
        getCheckListsByIdChecklist={getCheckListsByIdChecklist}
      />
    </section>
  </HandoverChecklistProvider>
}

export default HandoverChecklist
