import { Button, Row } from "antd"
import CustomSwitch from "../../../UI/CustomSwitch"
import EditIcon from "../../../../assets/icons/EditIcon"
import Eye2Icon from "../../../../assets/icons/Eye2Icon"
import TrashIcon from "../../../../assets/icons/TrashIcon"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import { columnsOrderedTable } from "../../../helpers/columnsTableHelper"

//Devuelve las columnas para la tabla de roles
//Dependiendo si el rol esta activo o no cambian los estilos para cada record
export const userRolesTableColumnsPrev = ({ handleChangeRolStatus, handleOpenCloseModal, handleRedirectTo, handleDeleteRole, loading, columns = [] }) => {
    const defaultColumns = [
        {
            title: 'ID',
            dataIndex: 'role_id',
            key: 'role_id',
            width: '10%',
            align: 'center'
        },
        {
            title: 'ROL',
            dataIndex: 'name',
            key: 'name',
            width: '25%',
            render: (text, record) => ( //Se añade un switch en el render para poder activar o desactivar el rol 
                <Row style={{ gap: 8 }}>
                    <CustomSwitch onChange={() => handleChangeRolStatus({ data: record })} checked={record.status} />
                    <span style={{ color: record.status ? '#404040' : '#BFBFBF' }}>{record.name}</span>
                </Row>
            )
        },
        {
            title: 'COMPAÑÍA',
            dataIndex: 'company',
            key: 'company',
            width: '15%',
            render: (text, record) => (
                <span style={{ color: record.status ? '#404040' : '#BFBFBF' }}>{record.company}</span>
            )
        },
        {
            dataIndex: 'status',
            key: 'status',
            width: '35%',
            render: (text, record) => ( //En esta columna se colocan los botones ya sea para editar, ver, eliminar o administrar un rol
                <Row align="middle" justify="end" style={{ gap: 12 }}>
                    <Button onClick={() => handleOpenCloseModal({ data: record })} className="userRole-btn-actions" disabled={!record.status} style={{ borderColor: record.status ? 'var(--primary)' : '#BFBFBF', color: record.status ? 'var(--primary)' : '#BFBFBF' }} icon={<EditIcon color={record.status ? 'var(--primary)' : '#BFBFBF'} />} />
                    <Button onClick={() => handleRedirectTo({ data: record, justView: true })} className="userRole-btn-actions" disabled={!record.status} style={{ borderColor: record.status ? 'var(--primary)' : '#BFBFBF', color: record.status ? 'var(--primary)' : '#BFBFBF', }} icon={<Eye2Icon color={record.status ? 'var(--primary)' : '#BFBFBF'} />} />
                    <CustomPopoverConfirm popConfirm={true} icon={<TrashIcon color="var(--primary)" />}
                        disabled={record.status}
                        okButtonProps={{ className: 'userRole__delete-popconfirm__ok-button', shape: 'round', loading: loading }}
                        cancelButtonProps={{ className: 'userRole__delete-popconfirm__cancel-button', shape: 'round', disabled: loading }}
                        overlayClassName="userRole__delete-popconfirm"
                        placement="leftTop"
                        title={"¿Desea eliminar este rol?"}
                        onConfirm={() => handleDeleteRole({ data: record })}
                        okText="ELIMINAR"
                        cancelText="CANCELAR">
                        <Button className="userRole-btn-actions" style={{ borderColor: !record.status ? 'var(--primary)' : '#BFBFBF', color: !record.status ? 'var(--primary)' : '#BFBFBF', borderRadius: '50%' }} icon={<TrashIcon color={!record.status ? 'var(--primary)' : '#BFBFBF'} />} />
                    </CustomPopoverConfirm>
                    <Button onClick={() => handleRedirectTo({ data: record })} disabled={!record.status} style={{ borderColor: record.status ? 'var(--primary)' : '#BFBFBF' }} className="userRole-btn-admin" shape="round" ><span style={{ color: record.status ? 'var(--primary)' : '#BFBFBF' }}>ADMINISTRAR</span></Button>
                </Row>
            )
        },
    ]

    return columns.length ? columnsOrderedTable({ defaultColumns, backendColumns: columns, titleUpperCase: true }) : defaultColumns
}