import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useCheckAuth } from "../hooks";
import { DashboardRouter } from "../topia/routes/DashboardRouter";
import { AuthRoutes } from "../auth/routes/AuthRoutes";
import { CheckingAuth } from "../components/CheckingAuth";
import { Worker } from "@react-pdf-viewer/core";
import { MobileAlert } from "../components/MobileAlert/MobileAlert";
import ErrorPage from "../components/Error/ErrorPage";
import useCheckSizeWindow from "../hooks/useCheckSizeWindow";

export const AppRouter = () => {
  const status = useCheckAuth();
  const { isMobileView } = useCheckSizeWindow()

  if (isMobileView) {
    return <MobileAlert />;
  }

  if (status === 'checking') {
    return <CheckingAuth />
  }

  return (
    <BrowserRouter>
      <Routes>
        {
          (status === 'authenticated')
            ? <>
              <Route path="/*" element={<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"><DashboardRouter /></Worker>} />
              <Route path="/404" element={<ErrorPage />} />
            </>
            : <Route path="/auth/*" element={<AuthRoutes />} />
        }
        {/*Si no está logueado cualquier ruta del cliente irá a login... */}
        <Route path='/*' element={<Navigate to='/auth/login' />} />
      </Routes>
    </BrowserRouter>
  );
};
