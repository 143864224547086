import SubCategoriesTable from "./SubCategoriesTable"
import EmptyComponent from "../../../UI/EmptyComponent"
import EmptySvg from '../../../../assets/icons/empty.svg'
import CustomTabs from "../../../UI/CustomTabs/CustomTabs"
import { CustomSpin } from "../../../UI/CustomSpin"

const CategoriesTabs = ({
  activeFeatures,
  form,
  categories,
  isOpenModal,
  loadingCategories,
  loadingActionsTable,
  handleOpenCloseModal,
  getAllTicketsCategories,
}) => {
  const EmptyIcon = () => <img src={EmptySvg} alt="empty-icon" />;

  const tabItems = categories?.map((category) => ({
    label: category.name.toUpperCase(),
    children: <SubCategoriesTable
      activeFeatures={activeFeatures}
      form={form}
      subCategories={category.TICKET_SUBCATEGORies}
      isOpenModal={isOpenModal}
      loadingActionsTable={loadingActionsTable}
      handleOpenCloseModal={handleOpenCloseModal}
      getAllTicketsCategories={getAllTicketsCategories}
    />
  }))
  if (loadingCategories) return <CustomSpin size="large" />

  return <>
    {!(categories.length)
      ? <EmptyComponent Icon={<EmptyIcon />} label="No hay categorias creadas" />
      : <CustomTabs className="custom-tabs-variant-2" defaultActiveKey="0" items={tabItems} />
    }
  </>
}

export default CategoriesTabs