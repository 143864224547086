import { Col, Row } from 'antd'
import { useState } from 'react'
import CustomTree from '../../../UI/CustomTree'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import useHierarchyAdministration from '../hooks/useHierarchyAdministration'
import { currentParentIdThunk, getPlacesHierarchyAdminSpaceThunk } from '../../../../store/hierarchyAdmin/thunk'

// Imagenes o archivos
import routeSvg from "../../../../assets/icons/route.svg"
import CloseFolderIcon from '../../../../assets/icons/CloseFolderIcon'

import '../style.css'

const HierarchyAdministrationRoute  = () => {
  const dispatch = useAppDispatch()

  const [selectedKeys, setSelectedKeys] = useState([])

  const { setLoading, companyName } = useHierarchyAdministration()
  const { idToken, currentRol } = useAppSelector((state) => state.auth)
  const { currentParentId , hierarchyAdminSpaces } = useAppSelector(state => state.hierarchyAdminSpace)

  const onSelect = async (keys, info) => {
    setLoading(true)
    
    dispatch(currentParentIdThunk({ currentParentId: info.node.value }));
    await dispatch( await getPlacesHierarchyAdminSpaceThunk({ companyId:currentRol,idToken: idToken, parentId: info.node.value }))
    setSelectedKeys(keys);

    setLoading(false)
  }

  // Funcion para saber cual folder ha sido seleccionado desde la table
  const convertToSelectedKeys = (value) => {
    const selectedKeys = [];
    const traverseTree = (node, path) => {
      if (node.value === value) {
        selectedKeys.push(['0', ...path].join('-'))
    } else if (node.children) {
        node.children.forEach((child, index) => {
          traverseTree(child, [...path, index])
        })
      }
    }
    hierarchyAdminSpaces.forEach((node, index) => {
        traverseTree(node, [index])
    })
        return selectedKeys
    }

  const DotIcon = ({ color = "var(--primary)" }) => (
    <div style={{background: color, width:6, height:6, position:'relative', borderRadius: 50, top:10}}/>
  )

  // Funcion para seleccionar el icono
  const switcherIcon = ({ isLeaf, expanded }) => {
    if (isLeaf) {
      return null; 
    }

    if (expanded) {
      return (
        <div style={{position:'absolute', top:3.5, left:3}}>
            <CloseFolderIcon color={!isLeaf ? 'var(--primary)' : (!isLeaf && selectedKeys) && '#fff'  }/> 
        </div>
      )
    } else {
        return(
        <div style={{position:'absolute', top:-1, left:10}}>
            <DotIcon  />
        </div>
        )
    }
  }

  return (
    <section style={{background:'#fff', width:'100%', height:'70vh', borderRadius:10, padding:'2rem'}} >
      <Row>
        <img src={routeSvg} alt="" />
        <p style={{color:'#232C4A', fontSize:'14px', margin:'0.2rem 0 0 1rem'}}>{companyName}</p>
      </Row>

      <Row style={{width:'100%', height:'40px', background:'#F1F0F0', marginTop:'1.5rem', borderRadius:5}}>
        <p style={{padding:'0.5rem 0 0 1rem', color:'#232C4A', fontSize:'14px'}} >Root/</p>
      </Row>

      <Row style={{marginTop:'0.5rem', height:'calc(100% - 6.3rem)', overflowY:'auto', overflowX:'hidden'}}>
        <Col span={24}>
          <CustomTree
            defaultExpandAll
            selectedKeys={convertToSelectedKeys}
            selectItem={currentParentId}
            multiple
            onSelect={onSelect}
            treeData={hierarchyAdminSpaces}
            switcherIcon={switcherIcon}
            showIcon={false}
          />
        </Col>
      </Row>
    </section>
  )
}

export default HierarchyAdministrationRoute
