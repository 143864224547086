export const commentType = {
    "category_id": 3,
    "category_name": "",
    "task_id": 45,
    "task_name": "",
    "period": "---",
    "comments": [
        {
            "employee_id": 4,
            "employee_name": "",
            "comment": "",
            "files": [],
            "images": [
                {
                    "image_id": 3,
                    "file": ""
                }
            ],
            "date": new Date().toISOString(),
        },       
    ]
}