import { CustomNavigate } from "../../../../../UI/CustomNavigate"
import useCurrentLocation from "../../../../../hooks/useCurrentLocation"

const HeaderAmenityActions = () => {
  const { param } = useCurrentLocation()

  return (
    <header style={{ marginBottom: 48 }}>
      <CustomNavigate className='custom-title' title={
        <>
          {param.idAmenity === 'nueva-amenidad' ? 'NUEVA' : 'EDITAR'} AMENIDAD
          <label style={{ fontWeight: 400, fontSize: 14, color: '#000000' }}>
            Aquí podrás configurar todos los aspectos de tu amenidad
          </label>
        </>
      } />
    </header>
  )
}

export default HeaderAmenityActions
