import { useContext } from "react"
import { Button, Col, Row } from "antd"
import useGenerals from "../hooks/useGenerals"
import { PlusCircleOutlined } from "@ant-design/icons"
import { CustomSelect } from "../../../../UI/CustomSelect"
import { useAppSelector } from "../../../../../hooks/store"
import { CustomNavigate } from "../../../../UI/CustomNavigate"
import CustomPopoverConfirm from "../../../../UI/CustomPopoverConfirm"
import SettingsMenu from "../../../../components/SettingsMenu/SettingsMenu"
import { HandoverChecklistContext } from "../context/HandoverChecklistContext"
import { HANDOVER_CHECKLIST_CODES } from "../../../../../constants/modules/handoverChecklist/handoverChecklistCodes"
import useCurrentLocation from "../../../../hooks/useCurrentLocation"

const HeaderActions = ({
  getAllHierarchyData,
  getCheckListsByIdChecklist,
  activeFeatures,
}) => {
  const { statusChecklist } = useGenerals()
  const { param } = useCurrentLocation()
  const { currentRol } = useAppSelector((state) => state.auth)

  const isEnabledNewChecklist = !activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_NEW_HANDOVER)?.enabled

  const { form, loadingTable, setLoadingTable, handleOpenCloseModal } = useContext(HandoverChecklistContext)

  const statusToSelect = statusChecklist.map((status) => ({ id: status.id, name: status.name }))

  const onReset = () => form.resetFields()

  const handleOnChangeSelect = async (idCategory) => {
    setLoadingTable(true)
    await getCheckListsByIdChecklist(param.checklistId, currentRol, 10, 0, idCategory)
    setLoadingTable(false)
  }

  return <header style={{ marginBottom: 15 }}>
    <CustomNavigate title={'CHECKLIST DE ENTREGA'}>
      <Row justify="end" style={{ gap: 16 }}>
        <CustomPopoverConfirm
          trigger={'hover'}
          content={isEnabledNewChecklist ? 'No tienes permiso para realizar esta acción' : 'Nueva checklist de entrega'}
        >
          <Button
            type="primary"
            htmlType="button"
            shape="round"
            className='btn-standard-green'
            onClick={async () => {
              onReset()
              handleOpenCloseModal()
              await getAllHierarchyData()
              }}
            style={{ width: 245 }}
            disabled={isEnabledNewChecklist || loadingTable}

            >
              <Row justify="center" align="middle">
                <span>NUEVA ENTREGA</span>
                <PlusCircleOutlined style={{ fontSize: 15, paddingLeft: 15, height: 16 }} />
              </Row>
            </Button>
          </CustomPopoverConfirm>
        <SettingsMenu items={[{ label: 'Admón. Checklist de Entrega', route: `/checklist/admin-entrega/${param.checklistId}`, active: true, disabled: !activeFeatures?.find(feature => feature.feature_code === HANDOVER_CHECKLIST_CODES.HANDOVER_CHECKLIST_MANAGMENT_HANDOVER)?.enabled }]} />
      </Row>
    </CustomNavigate>

    <Row>
      <Col span={5}>
        <CustomSelect
          allowClear
          size="middle"
          loading={loadingTable}
          disabled={loadingTable}
          options={statusToSelect ?? []}
          className='standard-select'
          placeholder="Seleccione una categoría"
          onChange={handleOnChangeSelect}
        />
      </Col>
    </Row>
  </header>
}

export default HeaderActions