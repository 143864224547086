import { Col, Pagination, Row } from "antd"
import TypeNew from "./TypeNew"
import { useContext, useState } from "react"
import { CustomSpin } from "../../../UI/CustomSpin"
import { useAppSelector } from "../../../../hooks/store"
import DeleteTypeNewModal from "./modals/DeleteTypeNewModal"
import HeaderTypesNewsFilters from "./HeaderTypesNewsFilters"
import useOpenCloseModal from './../../../hooks/useOpenCloseModal'
import DetailTypeNewDrawer from "./drawer/DetailTypeNewDrawer"
import useTypesNews from "../hooks/useTypesNews"
import { TypesNewsContext } from "../context/TypesNewsContext"
import EmptyComponent from "../../../UI/EmptyComponent"
import { NEWS_CODES } from "../../../../constants/modules/news/newsCodes"

const TypesNews = ({ activeFeatures }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)

  const handleOpenCloseDrawer = () => setIsOpenDrawer((state) => !state)

  const { form } = useContext(TypesNewsContext)
  const { getAllTypesNewsByAllFilters } = useTypesNews()
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { filters, metadata, typesNews, loadingTypesNews } = useAppSelector(state => state.news)

  const handleOnChangePagination = async (currentPage) => {
    let newSkip = (currentPage * 10) - 10
    if (currentPage === 1) {
      newSkip = 0
    }

    await getAllTypesNewsByAllFilters({ ...filters, skip: newSkip })
  }

  return <Row>
    <Col span={24}>
      {
        !activeFeatures?.find(feature => feature.feature_code === NEWS_CODES.NEWS_TABLE)?.enabled ? <EmptyComponent label={!activeFeatures?.find(feature => feature.feature_code === NEWS_CODES.NEWS_TABLE)?.enabled ? 'No tienes permisos para ver estos datos' : `No hay datos`} /> :
          <>
            <HeaderTypesNewsFilters />
            {loadingTypesNews
              ? <CustomSpin section />
              : typesNews?.map((typeNewData) => <TypeNew activeFeatures={activeFeatures} key={typeNewData.news_id} form={form} typeNewData={typeNewData} handleOpenCloseModal={handleOpenCloseModal} handleOpenCloseDrawer={handleOpenCloseDrawer} />)
            }
            {metadata?.quantity > 10 && activeFeatures?.find(feature => feature.feature_code === NEWS_CODES.NEWS_TABLE)?.enabled
              ? <Row justify="end" style={{ marginTop: 10 }}>
                <Pagination
                  size="small"
                  defaultCurrent={1}
                  total={metadata?.quantity}
                  onChange={handleOnChangePagination}
                />
              </Row>
              : null
            }

            <DeleteTypeNewModal isOpenModal={isOpenModal} onCancel={handleOpenCloseModal} />

            <DetailTypeNewDrawer isOpenDrawer={isOpenDrawer} handleOpenCloseDrawer={handleOpenCloseDrawer} />
          </>
      }
    </Col>
  </Row>
}

export default TypesNews