import axios from "axios"

const getHierarchyByCompanyId = async (companyId, idToken) => {
  try {
    const response = await axios.get(`https://domo.topiaapp.com/api/place/hierarchy/tree?company_id=${companyId}`,
      { headers: { Authorization: `Bearer ${idToken}` } })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getHierarchyByCompanyId