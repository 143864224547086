import axios from "axios";
const updateInspectionSchedule = async (
  URL_DEV,
  idTicket,
  employeeId,
  idSchedule,
  date,
  timeBegin,
  timeEnd
) => {
  try {
    const inspectionSchedule = {
      ticket_id: idTicket,
      employee_id: employeeId,
      session: [
        {
          id: idSchedule,
          date: date,
          time_begin: timeBegin,
          time_end: timeEnd,
        },
      ],
    };
    const response = await axios.put(
      `${URL_DEV}/inspection/schedule`,
      inspectionSchedule
    );

    return {
      success: true,
      ...response,
    };
  } catch (errorResponse) {
    return {
      success: false,
      ...errorResponse.response,
    };
  }
};
export default updateInspectionSchedule;
