import { Button, Row } from "antd"
import { CustomNavigate } from "../../../../../UI/CustomNavigate"

const DetailRolesHeader = ({ userRolesTable, activeModuleRole, handleOnSaveFeatures, loading }) => (
    <header>
        <CustomNavigate title={<div>PERMISOS / <span style={{ color: 'var(--primary)' }}>{userRolesTable?.active?.name?.toUpperCase()}</span></div>} >
            <Row justify="end" >
                {/**Este botón hace que se guarden los cambios que se realicen en un modulo sobre las caracterisiticas */}
                {userRolesTable.active.justView ? <></> : (
                    <Button
                        disabled={!activeModuleRole.status}
                        onClick={() => handleOnSaveFeatures()}
                        loading={loading}
                        className="btn-standard-green"
                        shape="round"
                        style={{ width: 246 }}
                    >
                        GUARDAR CAMBIOS
                    </Button>
                )}
            </Row>
        </CustomNavigate>
    </header>
)

export default DetailRolesHeader
