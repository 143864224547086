import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const companyMaintenancePath = "company/asset/report"

export const getAllMaintenanceAsset = async ({
    token,
    company_asset_id,
    limit = 10,
    skip = 0,
    sort_by,
    sort_order,
}) => {
    try {
        const params = {
            limit,
            skip,
            company_asset_id,
            sort_by,
            sort_order
        };

        const response = await axiosCreate.get(`${companyMaintenancePath}`, {
            params, timeout: 20000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
};