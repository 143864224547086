import { Form } from "antd"
import { useState } from "react"
import useTickets from "./hooks/useTickets"
import HeaderActions from "./components/HeaderActions"
import CategoriesTabs from "./components/CategoriesTabs"
import useOpenCloseModal from './../../hooks/useOpenCloseModal'
import NewEditCategoryModal from "./components/Modals/NewEditCategoryModal"
import { CategoriesTicketProvider } from "./context/CategoriesTicketContext"
import { useFeatureRole } from "../../hooks/useFeatureRole"
import { TICKETS_CODES, TICKETS_CODES_NAME } from "../../../constants/modules/tickets/ticketsCodes"
import { CustomSpin } from "../../UI/CustomSpin"

const AdminCategoriesPage = () => {
  const [form] = Form.useForm()
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false)

  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { categories, getAllTicketsCategories, loadingCategories, loadingActionsTable } = useTickets()

  const handleOpenCloseCategoryModal = () => setIsOpenCategoryModal(state => !state)
  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: TICKETS_CODES, validate_code: TICKETS_CODES_NAME.TICKETS_MANAGEMENT_CATEGORIES })

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (
    <CategoriesTicketProvider>
      <section className="section-wrapper">
        <HeaderActions
          activeFeatures={activeFeatures}
          form={form}
          handleOpenCloseModal={handleOpenCloseModal}
          handleOpenCloseCategoryModal={handleOpenCloseCategoryModal}
        />

        <CategoriesTabs
          form={form}
          activeFeatures={activeFeatures}
          categories={categories}
          isOpenModal={isOpenModal}
          loadingCategories={loadingCategories}
          loadingActionsTable={loadingActionsTable}
          isOpenCategoryModal={isOpenCategoryModal}
          handleOpenCloseModal={handleOpenCloseModal}
          getAllTicketsCategories={getAllTicketsCategories}
          handleOpenCloseCategoryModal={handleOpenCloseCategoryModal}
        />

        <NewEditCategoryModal
          form={form}
          isOpenModal={isOpenCategoryModal}
          handleOpenCloseModal={handleOpenCloseCategoryModal}
          getAllTicketsCategories={getAllTicketsCategories}
        />
      </section>
    </CategoriesTicketProvider>
  )
}

export default AdminCategoriesPage