/*A partir de un arreglo de columnas para una tabla de ant design definido en local y otro arreglo de columnas que debe provenir desde un endpoint
Se hará una combinacion de las columnas que vengan desde el endpoint y las que esten en local, además de ciertas flags para cambiar ciertas propiedades
*/
export const columnsOrderedTable = ({ defaultColumns = [{ dataIndex: '', title: '' }], backendColumns = [{ dataIndex: '', title: '' }], titleUpperCase = false, ellipsis = false }) => {
    //Si no es un arreglo lo que viene en las columnas del endpoint, retonra las columnas por defecto
    if (!Array.isArray(backendColumns)) return defaultColumns

    //Se mapean las columnas del endpoint pero verifica a partir del dataIndex si esta existe en el arreglo local, en caso de existir se sustituye lo que venia en el endpoint con la local
    //caso contrario se retorna la del endpoint
    const finalColumns = backendColumns.map(col => {
        const updatedCol = defaultColumns.find(defaultCol => defaultCol.dataIndex === col.dataIndex)
        const finalCol = updatedCol || col
        const colTitle = finalCol?.title
        return {
            ...finalCol,
            ellipsis,
            title: titleUpperCase ? colTitle?.toUpperCase() : colTitle
        }
    })

    //Posteriormente se recorre el arreglo local de columnas donde se verifica con el dataIndex si existe o no una columna en local en las columnas del endpoint
    //si no existe entonces se añadirá al final del arreglo
    defaultColumns.forEach(defaultCol => {
        const colExists = backendColumns.some(col => col.dataIndex === defaultCol.dataIndex)
        if (!colExists) {
            finalColumns.push({
                ...defaultCol,
                ellipsis,
                title: titleUpperCase ? defaultCol.title?.toUpperCase() : defaultCol.title
            })
        }
    })

    return finalColumns
}