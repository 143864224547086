import { useMemo } from "react"
import { Dropdown, Menu } from "antd"
import { EMPLOYEE_CODES } from "../../../../constants/modules/employee/employeeCodes"

const MoreOptions = ({
  setIsModalOpen,
  getEmployeeInfo,
  setIsOffBoardingOpen,
  employee,
  activeFeatures
}) => {
  const clickMoreOptHandler = async (openAction, sectionId) => {
    await getEmployeeInfo(employee.id, sectionId)
    openAction(true);
  };

  const featuresEnabled = useMemo(() => ({
    updateEmployee: !activeFeatures?.find(feature => feature.feature_code === EMPLOYEE_CODES.EMPLOYEE_UPDATE_EMPLOYEE)?.enabled,
    inhabilityEmployee: !activeFeatures?.find(feature => feature.feature_code === EMPLOYEE_CODES.EMPLOYEE_INABILITY_EMPLOYEE)?.enabled,
    fireEmployee: !activeFeatures?.find(feature => feature.feature_code === EMPLOYEE_CODES.EMPLOYEE_FIRE_EMPLOYEE)?.enabled,
  }), [activeFeatures])

  const items = [
    {
      name: "Editar",
      disabled: featuresEnabled.updateEmployee,
      icon: "/assets/icons/editar.svg",
      action: (sectionId) => !featuresEnabled.updateEmployee ? clickMoreOptHandler(setIsModalOpen, sectionId) : () => { },
    },
    {
      name: employee.status === "Enfermo" ? "Activar" : "Incapacitar",
      disabled: featuresEnabled.inhabilityEmployee,
      icon: "/assets/icons/rename.svg",
      action: (sectionId) => !featuresEnabled.inhabilityEmployee ? clickMoreOptHandler(setIsOffBoardingOpen, sectionId) : () => { },
    },
    {
      name: "Dar de baja",
      disabled: featuresEnabled.fireEmployee,
      icon: "/assets/icons/basura.svg",
      action: (sectionId) => !featuresEnabled.fireEmployee ? clickMoreOptHandler(setIsOffBoardingOpen, sectionId) : () => { },
    },
  ];

  const ItemLabel = ({ item, index }) => {
    return (
      <div className="more-options" onClick={() => item.action(index)}>
        <img src={item.icon} alt={item.name} />
        <span>{item.name}</span>
      </div>
    );
  };

  const menu = (
    <Menu
      style={{
        width: 175,
      }}
      items={items.map((item, index) => ({
        disabled: item.disabled,
        key: index,
        label: <ItemLabel item={item} index={index} />,
      }))}
    />
  );

  return (
    <Dropdown trigger={["click"]} overlay={menu}>
      <img
        src="/assets/icons/more-icon.svg"
        alt="mas"
        style={{
          cursor: "pointer",
        }}
      />
    </Dropdown>
  );
};

export default MoreOptions;
