import { Col, Row } from "antd"
import { useState } from "react"
import CustomChatComponent from "../../../../UI/CustomChat/CustomChatComponent"
import CustomConversationsList from "../../../../UI/CustomChat/CustomConversationsList"

const ConversationList = ({
  loading,
  metadata,
  conversationsList,
  getAllConversations
}) => {
  const [currentBody, setCurrentBody] = useState('chatList')

  const verifyBody = (currentBody) => {
    let objBody = {
      'chatList': <CustomConversationsList isToUpdateList isLoading={loading} getConversations={getAllConversations} conversations={conversationsList} isMetadata={metadata} urlSocket="https://domo.topiaapp.com" setCurrentBody={setCurrentBody} />,
      'chat': <CustomChatComponent urlSocket="https://domo.topiaapp.com" setCurrentBody={setCurrentBody} />,
    }

    let body = objBody[currentBody] || objBody['chatList']
    return body
  }

  return (
    <Row style={{ maxHeight: 910 }}>
      <Col span={24} style={{ backgroundColor: "#FFFFFF", borderRadius: 16, padding: 25 }}>
        {currentBody === 'chatList' && (
          <>
            <label style={{ fontWeight: 600, fontSize: 25 }}>
              Mensajes
            </label>
            
            <p style={{ fontSize: 14, fontWeight: 400, color: '#8A8A8A', marginBottom: 25 }}>
              Mira y responde los mensajes de tus usuarios
            </p>
          </>
        )}

        {verifyBody(currentBody)}
      </Col>
    </Row>
  )
}

export default ConversationList
