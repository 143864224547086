import { useEffect, } from "react"
import AccessProvidersCalendar from "./components/AccessProvidersCalendar/AccessProvidersCalendar"
import useAccessProvider from "./hooks/useAccessProvider"
import ListProvidersModal from "./components/ListProvidersModal"
import ListReasonVisitModal from "./components/ListReasonVisitModal"
import ProvidersAccessDetailDrawer from "./components/ProvidersAccessDetailDrawer"
import NewProviderAccessModal from "./components/NewProviderAccessModal/NewProviderAccessModal"
import useAccessProviderCalendar from "./hooks/useAccessProviderCalendar"
import useNewAccessProvider from "./hooks/useNewAccessProvider"
import 'moment/locale/es'
import { CustomSpin } from "../../UI/CustomSpin"
import moment from "moment"
import Header from "./components/Header"
import { useFeatureRole } from "../../hooks/useFeatureRole"
import { ACCESS_PROVIDERS_CODES, ACCESS_PROVIDERS_CODES_NAMES } from "../../../constants/modules/accessProviders/accessProvidersCodes"

moment.locale('es')
export const AccessProvidersPage = () => {

    const { providersTable, reasonVisitsTable, itemVisible, loading, providerColumns, reasonVisitsColumns, accessDetail,
        handleOpenCloseModal, handleChangeTable, handlePagination, onSubmit, setReasonVisitsTable, setProvidersTable, setLoading, getAllProvidersService, getInitialData, getReasonVisitsService
    } = useAccessProvider()
    const { handleChangeMonthCalendar, handleChangeAccessStatus, handleChangeProvider, getCalendarData, handleCancelAccess, accessStatus, calendarData, currentParams, isRefresh } = useAccessProviderCalendar({ setLoading, handleOpenCloseModal })
    const { onSubmit: onSubmitForm, form, Form, getInitialFormData, handleAddNewDateItem } = useNewAccessProvider({ handleOpenCloseModal, accessDetail, getCalendarData, setLoading })
    const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: ACCESS_PROVIDERS_CODES, validate_code: ACCESS_PROVIDERS_CODES_NAMES.ACCESS_PROVIDERS_MODULE })

    useEffect(() => {
        //Solo se carga la primera vez que se monta el componente
        if (!isRefresh.current) {
            getInitialData()
            getCalendarData({ refresh: true })
        }
        //Refrescar la data del calendario cada 5 segundos
        const intervalId = setInterval(() => {
            getCalendarData({ refresh: true })
        }, 5000);
        return () => clearInterval(intervalId)
    }, [currentParams, accessStatus]);

    if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

    return (
        <section className="section-wrapper">
            <CustomSpin spinning={loading}>
                <Header activeFeatures={activeFeatures} handleOpenCloseModal={handleOpenCloseModal} />
                <AccessProvidersCalendar activeFeatures={activeFeatures} currentParams={currentParams} providersTable={providersTable.dataTable} providerService={getAllProvidersService} handleProviderChange={handleChangeProvider} data={calendarData} handleOpenCloseItem={handleOpenCloseModal} handleChangeMonthCalendar={handleChangeMonthCalendar} handleChangeAccessStatus={handleChangeAccessStatus} />
                <ListProvidersModal activeFeatures={activeFeatures} isOpenModal={itemVisible.providersModal} onCancel={handleOpenCloseModal} onSubmit={onSubmit} table={providersTable} handleChangeTable={handleChangeTable} handlePagination={handlePagination} setTable={setProvidersTable} loading={loading} columns={providerColumns} />
                <ListReasonVisitModal activeFeatures={activeFeatures} isOpenModal={itemVisible.reasonVisitsModal} onCancel={handleOpenCloseModal} onSubmit={onSubmit} table={reasonVisitsTable} handleChangeTable={handleChangeTable} handlePagination={handlePagination} setTable={setReasonVisitsTable} loading={loading} columns={reasonVisitsColumns} />
                <ProvidersAccessDetailDrawer activeFeatures={activeFeatures} loading={loading} handleCancelAccess={handleCancelAccess} accessDetail={accessDetail} isOpenDrawer={itemVisible.accessDetailDrawer} onClose={handleOpenCloseModal} />
                <NewProviderAccessModal handleAddNewDateItem={handleAddNewDateItem} loading={loading} getInitialFormData={getInitialFormData} Form={Form} form={form} providerService={getAllProvidersService} reasonVisitService={getReasonVisitsService} accessDetail={accessDetail} onSubmit={onSubmitForm} isOpenModal={itemVisible.newAccessProviderModal} onCancel={handleOpenCloseModal} />
            </CustomSpin>
        </section>
    )
}

export default AccessProvidersPage