import { Form } from "antd";
import useRedirectTo from "../../../hooks/useRedirectTo";
import { useAppSelector } from "../../../../hooks/store";
import { createContext, useContext, useState } from "react";
import useOpenCloseModal from "../../../hooks/useOpenCloseModal";
import useCurrentLocation from "../../../hooks/useCurrentLocation";

const ActivesMaintenanceContext = createContext({
    param: null, auth: null, reportDetail: {}, currentActiveTab: '', detailActiveDrawer: {}, clickedActive: {},
    statusActivesMaintenance: [], dataTableActiveMaintenance: null, currentActiveMaintenanceParams: null,
    limitTableActiveMaintenance: null, quantityTableActiveMaintenance: null, currentPageTableActiveMaintenance: 1,
    lastSkipActiveMaintenance: 0, dataTableHistory: null, currentHistoryParams: null, limitTableHistory: 10,
    quantityTableHistory: null, currentPageTableHistory: 1, lastSkipHistory: 0, dataTableProviders: null,
    currentProvidersParams: null, currentFiltersTableProviders: null, limitTableProviders: 10,
    quantityTableProviders: null, currentPageTableProviders: 1, lastSkipProviders: 0, searchValue: null,
    loading: null, setLoading: () => { }, loadingProvider: null, setLoadingProvider: () => { },
    currentActiveMaintenance: {}, setCurrentActiveMaintenance: () => { },
    columnsTableActiveMaintenance: null, setColumnsTableActiveMaintenance: () => { },
    columnsTableProviders: null, setColumnsTableProviders: () => { },
    columnsTableHistory: null, setColumnsTableHistory: () => { }, setCurrentActiveTab: () => {},
    setSearchValue: () => { }, setReportDetail: () => { }, setDetailActiveDrawer: () => {},
    setDataTableActiveMaintenance: () => { }, setCurrentActiveMaintenanceParams: () => { },
    setMetaDataTableActiveMaintenance: () => { }, setCurrentPageTableActiveMaintenance: () => { },
    setLastSkipActiveMaintenance: () => { }, setDataTableHistory: () => { }, setCurrentHistoryParams: () => { },
    setMetaDataTableHistory: () => { }, setCurrentPageTableHistory: () => { }, setLastSkipHistory: () => { },
    setDataTableProviders: () => { }, setCurrentProvidersParams: () => { }, setCurrentFiltersTableProviders: () => { },
    setMetaDataTableProviders: () => { }, setCurrentPageTableProviders: () => { },
    setLastSkipProviders: () => { }, handleRedirectTo: () => { }, handleOpenCloseDrawer: () => {},
    handleOpenCloseExcelReportModal: () => {}, isOpenExcelReportModal: false, isOpenDrawer: false, isOpenModal: false,
    handleOpenCloseModal: () => {}
})

export const ActivesMaintenanceContextProvider = ({ children }) => {
    const [form] = Form.useForm()
    const auth = useAppSelector(state => state.auth)

    const { param } = useCurrentLocation()
    const { handleRedirectTo } = useRedirectTo()
    const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

    const [filesList, setFilesList] = useState([])
    const [isOpenDrawer, setIsOpenDrawer] = useState(false)
    const handleOpenCloseDrawer = () => setIsOpenDrawer((state) => !state)

    const [isOpenExcelReportModal, setIsOpenExcelReportModal] = useState(false)
    const handleOpenCloseExcelReportModal = () => setIsOpenExcelReportModal((state) => !state)

    // Estados para detalle de un activo en Drawer y eliminar un activo
    const [detailActiveDrawer, setDetailActiveDrawer] = useState({})
    const [clickedActive, setClickedActive] = useState({})
    const [showProvidersModal, setShowProvidersModal] = useState(false)
    const [showCreateActiveModal, setShowCreateActiveModal] = useState(false)

    /*Estados para la tabla principal de mantenimiento de activos */
    const [currentActiveTab, setCurrentActiveTab] = useState('')
    const [statusActivesMaintenance, setStatusActivesMaintenance]= useState([])
    const [dataTableActiveMaintenance, setDataTableActiveMaintenance] = useState([])
    const [currentActiveMaintenanceParams, setCurrentActiveMaintenanceParams] = useState({})
    const [{ limit: limitTableActiveMaintenance = 10, quantity: quantityTableActiveMaintenance }, setMetaDataTableActiveMaintenance] = useState({})
    const [currentPageTableActiveMaintenance, setCurrentPageTableActiveMaintenance] = useState(1)
    const [columnsTableActiveMaintenance, setColumnsTableActiveMaintenance] = useState([])
    const [lastSkipActiveMaintenance, setLastSkipActiveMaintenance] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [loading, setLoading] = useState(true)
    const [loadingProvider, setLoadingProvider] = useState(false)
    const [columnsTableProviders, setColumnsTableProviders] = useState([])
    const [currentActiveMaintenance, setCurrentActiveMaintenance] = useState({})

    /*Estados para la tabla del historial de reportes de un activo */
    const [dataTableHistory, setDataTableHistory] = useState([])
    const [currentHistoryParams, setCurrentHistoryParams] = useState({})
    const [{ limit: limitTableHistory = 10, quantity: quantityTableHistory }, setMetaDataTableHistory] = useState({})
    const [currentPageTableHistory, setCurrentPageTableHistory] = useState(1)
    const [lastSkipHistory, setLastSkipHistory] = useState(0)
    const [columnsTableHistory, setColumnsTableHistory] = useState([])

    /*Estados para la tabla los proveedores */
    const [dataTableProviders, setDataTableProviders] = useState([])
    const [currentProvidersParams, setCurrentProvidersParams] = useState({})
    const [currentFiltersTableProviders, setCurrentFiltersTableProviders] = useState()
    const [{ limit: limitTableProviders = 10, quantity: quantityTableProviders }, setMetaDataTableProviders] = useState({})
    const [currentPageTableProviders, setCurrentPageTableProviders] = useState(1)
    const [lastSkipProviders, setLastSkipProviders] = useState(0)

    const [reportDetail, setReportDetail] = useState({})

    const values = {
        currentActiveTab, setCurrentActiveTab, isOpenModal, handleOpenCloseModal, isOpenDrawer, handleOpenCloseDrawer,
        detailActiveDrawer, setDetailActiveDrawer, clickedActive, setClickedActive, statusActivesMaintenance,
        setStatusActivesMaintenance, param, auth, reportDetail, dataTableActiveMaintenance, currentActiveMaintenanceParams,
        limitTableActiveMaintenance, quantityTableActiveMaintenance, currentPageTableActiveMaintenance,
        lastSkipActiveMaintenance, dataTableHistory, currentHistoryParams, limitTableHistory, quantityTableHistory,
        currentPageTableHistory, lastSkipHistory, dataTableProviders, currentFiltersTableProviders,
        limitTableProviders, quantityTableProviders, currentPageTableProviders, lastSkipProviders,
        currentProvidersParams, searchValue, loading, loadingProvider, currentActiveMaintenance,
        columnsTableActiveMaintenance, columnsTableProviders, columnsTableHistory,
        setReportDetail, setDataTableActiveMaintenance, setCurrentActiveMaintenanceParams,
        setMetaDataTableActiveMaintenance, setCurrentPageTableActiveMaintenance, setLastSkipActiveMaintenance,
        setDataTableHistory, setCurrentHistoryParams, setMetaDataTableHistory, setCurrentPageTableHistory,
        setLastSkipHistory, setDataTableProviders, setCurrentFiltersTableProviders, setMetaDataTableProviders,
        setCurrentPageTableProviders, setLastSkipProviders, handleRedirectTo, setCurrentProvidersParams,
        setSearchValue, setLoading, setLoadingProvider, setCurrentActiveMaintenance,
        setColumnsTableActiveMaintenance, setColumnsTableProviders, setColumnsTableHistory, isOpenExcelReportModal,
        handleOpenCloseExcelReportModal, showProvidersModal, setShowProvidersModal, showCreateActiveModal,
        setShowCreateActiveModal, form, filesList, setFilesList
    }

    return (
        <ActivesMaintenanceContext.Provider value={values}>
            {children}
        </ActivesMaintenanceContext.Provider>
    )
}

export const useActivesMaintenanceContext = () => useContext(ActivesMaintenanceContext)
