import { Button } from "antd"
import { useEffect } from "react"
import MoreOptions from "./MoreOptions"
import { bodySearch } from './../../../constants/constants'
import CustomTable from "../../../UI/CustomTable/CustomTable"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import { getColumnsFilter } from './../../../helpers/customTableHelper'
import { EMPLOYEE_CODES } from "../../../../constants/modules/employee/employeeCodes"
// IMAGENES O ARCHIVOS
import { ReactComponent as TaskBtn } from "../assets/employee-task.svg"
import { ReactComponent as Assing } from '../../../../assets/icons/assign.svg'

const TableEmployee = ({
  companyId,
  setIsModalOpen,
  setIsOffBoardingOpen,
  employeeProfileHandler,
  departmentSelected,
  employeeHook,
  activeFeatures
}) => {
  const {
    getEmployeeInfo,
    columnsTable = [],
    dataSource = [],
    getEmployees,
    quantity,
    loading,
    currentPage,
    changePageHandler,
    limit,
  } = employeeHook;

  const columns = [
    ...columnsTable,
    {
      title: "TAREAS",
      dataIndex: "tasks",
      align: "center",
      render: (_, { status }) => (
        <Button disabled={status === "Enfermo"} className="task-btn">
          <TaskBtn />
          Tareas
        </Button>
      ),
    },
    {
      title: "PERFIL",
      dataIndex: "profile",
      align: "center",
      render: (_, { id }) => (
        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === EMPLOYEE_CODES.EMPLOYEE_DETAILS)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Detalles del empleado'}`}>
          <Button
            disabled={!activeFeatures?.find(feature => feature.feature_code === EMPLOYEE_CODES.EMPLOYEE_DETAILS)?.enabled}
            icon={<Assing />}
            onClick={() => employeeProfileHandler(id)}
            type="text"
            size="small"
          />
        </CustomPopoverConfirm >
      ),
    },
    {
      title: "",
      dataIndex: "more",
      align: "left",
      render: (_, employee) => (
        <MoreOptions
          activeFeatures={activeFeatures}
          setIsModalOpen={setIsModalOpen}
          getEmployeeInfo={getEmployeeInfo}
          setIsOffBoardingOpen={setIsOffBoardingOpen}
          employee={employee}
        />
      ),
      width: 60,
    },
  ];

  const columnsFiltersObject = {
    'name': () => ({
      ...bodySearch,
      onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase())
    }),
    'default': () => ({}),
  }

  const newColums = columns.map((column) => ({
    ...column,
    ...getColumnsFilter(column.dataIndex, columnsFiltersObject)
  }))

  useEffect(() => {
    getEmployees(companyId, departmentSelected);
  }, [departmentSelected]);

  return (
    <CustomTable
      className="custom-table"
      columns={newColums}
      dataSource={dataSource}
      rowKey={(record) => record.id}
      loading={loading}
      onChange={() => { }}
      pagination={{
        total: quantity,
        pageSize: limit,
        onChange: async (current) =>
          await changePageHandler(current, departmentSelected, companyId),
        current: currentPage,
        showTotal: (total, range) => {
          return `${range[0]}-${range[1]} de ${total}`;
        }
      }}
    />
  );
};

export default TableEmployee;
