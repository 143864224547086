import { THEME_ZONE_CODES } from '../../constants/theme';
import { defaultTheme } from '../../topia/components/Sidebar/mocks/defaultTheme'
import { setTheme } from "./sidebarSlice";
//Este es el "custom hook" de redux por lo que aquí se conectan las funciones de redux y los servicios

//Esta función debería llamarse cuando se obtiene los datos del login en caso de que no existan en el localStorage
export const setSidebarTheme = ({ images }) => {
  return async (dispatch, getState) => {
    const { themeImages } = getState()?.theme
    const finalImages = images ? images : themeImages
    const sidebarLogo = finalImages?.find(item => item.theme_zone_code === THEME_ZONE_CODES.SIDEBAR_ICON)?.images.find?.(image => image.code === "1")?.image
    //Si no hay un logo, es decir "undefined" no se manda nada al tema y se cargará el logo del sidebar por defecto
    if (!sidebarLogo) {
      dispatch(setTheme({ data: undefined }));
      return { success: false };
    }
    dispatch(setTheme({ data: defaultTheme({ logo: sidebarLogo }) }));
    return { success: true };
  };
};
