import axios from "axios"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const updateContract = async (formData, idToken) => {
  try {
    const response = await axios.put('https://domo.topiaapp.com/api/place/file/type/contract',
      formData,
      { headers: { Authorization: `Bearer ${idToken}` } }
    )

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
    }
}

export default updateContract