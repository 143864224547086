//Toma como parámetros un arreglo de features_code y una feature_code y dentro crea una función donde igualmente tomará estos parámetros y recorre el arreglo con un forof
//donde verifica si coincide las feature_codes, en caso de que sí va a verificar si este tiene un children, y si lo tiene devuelve el mapeo de los feature_code de los children, y si no retorna un arreglo vacío
// en caso de no coincidir pero encuentra un children en la feature, se hace llamada a la función nuevamente, como una función recursiva, verificando nuevamente lo anterior
//si en el resultado, es decir arreglo hay elementos , los devuelve, si no hay ninguna coincidencia entonces retorna un arreglo vacío
export const getChildrenFeatureCodes = ({ featuresArray = [], feature_code }) => {

    const findFeatureCode = ({ featuresArray, feature_code }) => {
        for (const feature of featuresArray) {

            if (feature.feature_code === feature_code) {
                return feature.children ? feature.children?.map(children => children.feature_code) : []
            }
            if (feature?.children) {
                const result = findFeatureCode({ featuresArray: feature.children, feature_code })
                if (result.length) {
                    return result
                }
            }

        }
        return []
    }
    //Finalmente retorna los elementos que se encuentren por la función recursiva, y se le añade la feature_code que se pasó inicialmente dentro del arreglo final
    return [...findFeatureCode({ featuresArray, feature_code }), feature_code]
}