import { useContext, useState } from "react"
import { useAppSelector } from "../../../../../../hooks/store"
import verifyResponse from "../../../../../helpers/verifyResponse"
import { ChecklistResultsContext } from "../context/ChecklistResultsContext"
import { getOrnamentChecklistCategories, getOrnamentChecklistCategoryDetail, getOrnamentChecklistCategoryItems } from "../services"

const useResultsChecklist = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageItemsTable, setCurrentPageItemsTable] = useState(1)

  const {
    categoryClicked,
    setMetadata,
    setLoadingItems,
    setMetadataItems,
    setChecklistItemsTable,
    setChecklistResultsDetails,
    setChecklistResultsTableData
  } = useContext(ChecklistResultsContext)
  
  const { idToken } = useAppSelector(state => state.auth)
  const { checklistClicked } = useAppSelector(state => state.adminCheck)

  const getChecklistCategoryItems = async ({ checklistCategoryId, skip, limit }) => {
    const response = await getOrnamentChecklistCategoryItems({ skip, limit, checklistCategoryId, idToken })

    if (response.success) {
      setChecklistItemsTable(response.data.data)
      setMetadataItems(response.data.metadata)
      return
    }

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  // change page handler de checklist items table
  const changePageHandlerItemsTable = async (currentPag, limit) => {
    setLoadingItems(true)

    const pageValue = currentPag || currentPage
    const newSkip = (pageValue * limit) - limit
    
    setCurrentPageItemsTable(pageValue)

    await getChecklistCategoryItems({ checklistCategoryId: categoryClicked.checklist_instance_category_id, skip: newSkip, limit })

    setLoadingItems(false)
  }

  const getOrnamentCheckCategoryDetail = async (instanceCategoryId) => {
    const response = await getOrnamentChecklistCategoryDetail(instanceCategoryId, idToken)

    if (response.success) {
      setChecklistResultsDetails(response.data.data)

      return
    }

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  const getOrnamentCheckCategories = async ({ checklistId, limit, skip}) => {
    const response = await getOrnamentChecklistCategories({ checklistId, idToken, limit, skip })

    if (response.success) {
      setChecklistResultsTableData(response.data.data)
      setMetadata(response.data.metadata)
      return
    }

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  const changePageHandler = async (currentPag, limit) => {
    const pageValue = currentPag || currentPage
    const newSkip = (pageValue * limit) - limit
    
    setCurrentPage(pageValue)

    await getOrnamentCheckCategories({ checklistId: checklistClicked.checklist_instance_id, limit, skip: newSkip})
  }

  return {
    currentPage,
    currentPageItemsTable,
    changePageHandler,
    changePageHandlerItemsTable,
    getChecklistCategoryItems,
    getOrnamentCheckCategories,
    getOrnamentCheckCategoryDetail,
  }
}

export default useResultsChecklist