import { useEffect } from "react"
import { Button, Col, Row } from "antd"
import TaxDataBody from './bodies/TaxDataBody'
import SpinLoader from "../../../UI/SpinLoader"
import useContracts from "../hooks/useContracts"
import ContractsBody from "./bodies/ContractsBody"
import EmptyComponent from "../../../UI/EmptyComponent"
import TicketsHistoryBody from "./bodies/TicketsHistoryBody"
import SpecificationsBody from "./bodies/SpecificationsBody"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { setCurrentOption } from "../../../../store/detailContracts/detailContractSlice"
import { MANAGEMENT_CONTRACTORS_CODES } from "../../../../constants/modules/managamentContractor/managamentContractorCodes"

//imagenes o svg
import CheckedIcon from "../../../../assets/icons/Generals/ContractsFlow/CheckedIcon"

const BodyPage = ({ form, isOpenModal, handleOpenCloseModal, activeFeatures }) => {
  const dispatch = useAppDispatch()
  
  const { getInitialData, verifyTypeRequest } = useContracts()
  const { idClickedPlace } = useAppSelector(state => state.malls)
  const { currentOptionMenu, isLoading } = useAppSelector(state => state.detailContracts)

  // Funcion para camnbiar el body de la opcion que ve el usuario
  const verifyBody = (currentOption) => {
    let objBodies = {
      "1": !activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_CONTRACT_COPIES)?.enabled ? <EmptyComponent label={'No tienes permiso para ver estos datos'} /> : <ContractsBody />,
      "2": !activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_FISCAL_DATA)?.enabled ? <EmptyComponent label={'No tienes permiso para ver estos datos'} /> : <TaxDataBody />,
      "3": !activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_TICKETS_LOCAL)?.enabled ? <EmptyComponent label={'No tienes permiso para ver estos datos'} /> : <TicketsHistoryBody />,
      "4": !activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_CONTRACTORS_CODES.MANAGEMENT_CONTRACTORS_MANAGEMENT_MAPS)?.enabled ? <EmptyComponent label={'No tienes permiso para ver estos datos'} /> : <SpecificationsBody form={form} isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />,
      "default": <EmptyComponent label="EL DISEÑO DE ESTA PANTALLA AUN ESTA EN PROCESO" />,
    }

    let body = objBodies[currentOption] || objBodies["default"]

    return body
  }

  useEffect(() => {
    // Aqui tengo que pasarle ese id
    if (idClickedPlace) getInitialData()
  }, [])
  
  return (
    <Row>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={18}>
            {isLoading ? <SpinLoader /> : verifyBody(currentOptionMenu)}
          </Col>

          <Col span={6}>
            {optionsMenu.map((option) => <OptionButton key={option.id} buttonData={option} dispatch={dispatch} currentOptionMenu={currentOptionMenu} verifyTypeRequest={verifyTypeRequest} />)}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const OptionButton = ({ buttonData, dispatch, currentOptionMenu, verifyTypeRequest }) => (
  <Row key={buttonData.id} justify="center">
    <Col>
      <Button
        type="text"
        htmlType="button"
        style={{
          width: 298,
          height: 53,
          margin: "5px 0",
          backgroundColor: `${((buttonData.id).toString()) === currentOptionMenu ? "#FFFFFF" : "#eaeff2"}`,
          border: `${((buttonData.id).toString()) === currentOptionMenu ? "1px solid var(--primary)" : "1px solid #C2C2C2"}`,
          borderRadius: 8,
          boxShadow: "0px 6px 7px 0px #9AB9D440",
        }}
        onClick={() => {
          dispatch(setCurrentOption(((buttonData.id).toString())))
          verifyTypeRequest((buttonData.id).toString())
        }}
      >
        <Row align="middle">
          <Col span={12} offset={4}>
            <Row justify="start">
              <span style={{ fontWeight: 500, fontSize: 14 }}>
                {buttonData.label}
              </span>
            </Row>
          </Col>

          <Col span={6}>
            {((buttonData.id).toString()) === currentOptionMenu && <CheckedIcon />}
          </Col>
        </Row>
      </Button>
    </Col>
  </Row>
)

const optionsMenu = [{
  id: 1,
  label: 'Copia de contrato',
}, {
  id: 2,
  label: 'Datos fiscales',
}, {
  id: 3,
  label: 'Historial de tickets',
}, {
  id: 4,
  label: 'Especificaciones',
}]

export default BodyPage
