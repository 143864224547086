import { Col, Row } from "antd"
import { useContext } from "react"
import useChecklists from "../hooks/useChecklists"
import { CustomInput } from "../../../UI/CustomInput"
import { ChecklistsAdminContext } from "../context/ChecklistsAdminContext"

// imagenes o archivos
import { SearchOutlined } from "@ant-design/icons"

const FiltersChecklistsAdmin = () => {
  const { filters, setFilters } = useContext(ChecklistsAdminContext)
  const { getChecklistsTable } = useChecklists()

  const handleOnChangeSearch = async (event) => {
    const isValue = Boolean(event.target.value)

    if (isValue) {
      await getChecklistsTable({ ...filters, find_value: event.target.value })
      setFilters(state => ({ ...state, find_value: event.target.value }))

      return
    }

    await getChecklistsTable({ ...filters, find_value: '' })
    setFilters(state => ({ ...state, find_value: '' }))
  }

  return (
    <Row>
      <Col span={6}>
        <CustomInput
          allowClear
          isComponentIcon
          onChange={handleOnChangeSearch}
          suffixIcon={<SearchOutlined />}
          placeholder='Buscar por nombre'
        />
      </Col>
    </Row>
  )
}

export default FiltersChecklistsAdmin