import { useContext } from "react"
import { Col, Divider, Image, Row } from "antd"
import CustomDrawer from "../../../../UI/CustomDrawer"
import { UsersContext } from "../../context/UsersContext"

// imagenes o archivos
import { CloseOutlined } from "@ant-design/icons"
import userDefaultImg from '../../../../../assets/icons_png/avatar_user.png'
import paper from '../../../../../assets/icons/Generals/paper-envelope-icon.svg'
import propertiesIcon from '../../../../../assets/icons/Generals/properties-icon.svg'
import phoneIcon from '../../../../../assets/icons/Generals/phone-icon-without-fill.svg'

const DetailUserDrawer = ({ isOpenDrawer, handleOpenCloseDrawer }) => {
  const { userDetailData } = useContext(UsersContext)

  return (
    <CustomDrawer
      width={328}
      isOpenDrawer={isOpenDrawer}
      onClose={handleOpenCloseDrawer}
      closeIcon={<CloseOutlined />}
    >
      <Row align="middle" justify="center">
        <Image src={userDetailData?.owner_data?.image || userDefaultImg} alt="user-img" width={56} />
      </Row>
      
      <Row justify="center">
        <label style={{ color:'#10B48C', fontSize: 12, fontWeight: 500, margin: '15px 0 0', textTransform: 'uppercase' }}>
          {userDetailData?.owner_data?.commercial_name ? userDetailData?.owner_data?.commercial_name : '------'}
        </label>
      </Row>

      <Row justify="center">
        <label style={{ color:'#252525', fontSize: 15, fontWeight: 600, margin: '0 0 10px', textTransform: 'uppercase' }}>
          {userDetailData?.owner_data?.full_name}
        </label>
      </Row>

      <Row gutter={6} justify="center">
        <Col>
          <Image src={phoneIcon} preview={false} />
        </Col>

        <Col>
          <label style={{ color:'#707070', fontSize: 10, fontWeight: 400, margin: '15px 0 0' }}>
            {userDetailData?.owner_data?.phone}
          </label>
        </Col>
      </Row>

      <Row gutter={6} justify="center">
        <Col>
          <Image src={paper} preview={false} />
        </Col>

        <Col>
          <label style={{ color:'#707070', fontSize: 10, fontWeight: 400 }}>
            {userDetailData?.owner_data?.mail}
          </label>
        </Col>
      </Row>

      <Divider style={{ borderTop: '1px solid #CFCFCF' }} />

      <Row gutter={8}>
        <Col span={12}>
          <label style={{ fontWeight: 600, fontSize: 10, color: '#252525' }}>
            Tipo de residente
          </label>

          <p style={{ fontWeight: 400, fontSize: 10, color: '#252525' }}>
            {userDetailData?.place_data?.owner_type}
          </p>

          <label style={{ fontWeight: 600, fontSize: 10, color: '#252525' }}>
            Parqueo asignado
          </label>

          <p style={{ fontWeight: 400, fontSize: 10, color: '#252525' }}>
            {userDetailData?.place_data?.parking ? userDetailData?.place_data?.parking : '------'}
          </p>

          <label style={{ fontWeight: 600, fontSize: 10, color: '#252525' }}>
            Automóvil
          </label>

          <p style={{ fontWeight: 400, fontSize: 10, color: '#252525' }}>
            ------
          </p>
        </Col>

        <Col span={12}>
          <label style={{ fontWeight: 600, fontSize: 10, color: '#252525' }}>
            Dirección
          </label>

          <p style={{ fontWeight: 400, fontSize: 10, color: '#252525' }}>
            {userDetailData?.place_data?.place_address ? userDetailData?.place_data?.place_address : '------'}
          </p>

          <label style={{ fontWeight: 600, fontSize: 10, color: '#252525' }}>
            Bodega personal
          </label>

          <p style={{ fontWeight: 400, fontSize: 10, color: '#252525' }}>
            {userDetailData?.place_data?.warehouse ? userDetailData?.place_data?.warehouse : '------'}
          </p>

          <label style={{ fontWeight: 600, fontSize: 10, color: '#252525' }}>
            Propiedades
          </label>
          
          <Row>
            <Col>
              <Row justify="center" align="middle" gutter={6} style={{ padding: '4px 8px', border: '1px solid #404040', borderRadius: 50 }}>
                <Col>
                  <Image src={propertiesIcon} preview={false} />
                </Col>

                <Col>
                  <label style={{ color: '#404040', fontSize: 11, fontWeight: 400 }}>
                    {userDetailData?.place_data?.owned_places?.length > 10
                      ? userDetailData?.place_data?.owned_places?.length
                      : `0${userDetailData?.place_data?.owned_places?.length}`
                    }
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider style={{ borderTop: '1px solid #CFCFCF' }} />

      <label style={{ fontWeight: 600, fontSize: 11, color: '#252525' }}>
        Familiares con acceso
      </label>

      {userDetailData?.place_dependents?.length > 0
        ? (<ul style={{ listStyle: 'none', padding: 0 }}>
            {userDetailData?.place_dependents?.map((dependentsData, index) => (
              <li key={dependentsData?.phone} style={{ border: '1px solid #707070', borderRadius: 8, padding: '6px 8px', margin: '8px 0' }}>
                <Row justify="space-between">
                  <label style={{ color: '#404040', fontSize: 11, fontWeight: 400 }}>
                    {dependentsData?.full_name}
                  </label>
                  
                  <label style={{ color: '#404040', fontSize: 11, fontWeight: 400 }}>
                    {dependentsData?.phone}
                  </label>
                </Row>
              </li>
            ))}
          </ul>)
        : <p style={{ fontWeight: 400, fontSize: 10, color: '#252525' }}>------</p>
      }
    </CustomDrawer>
  )
}

export default DetailUserDrawer