import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const accessProviderPath = "event/provider-access/detail"

export const getAccessProviderDetail = async ({
    token,
    event_appointment_id
}) => {
    try {
        const params = {
            event_appointment_id,
        };

        const response = await axiosCreate.get(`${accessProviderPath}`, {
            params, timeout: 10000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
};