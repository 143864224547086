import { Button, Row } from "antd"
import { useAppDispatch } from "../../../../hooks/store"
import { setAlertSelectedDetail } from "../../../../store/alerts"

// Imagenes o archivos
import detailAlertIcon from '../../../../assets/icons/Generals/alert-detail-icon.svg'
import { getNoticeTypeDataOfMessage } from "../../../../helpers/alertHelper"
import { getResidentialsById, getUserDataProfileById } from "../../../../helpers/globalHelper"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import { ALERTS_CODES } from "../../../../constants/modules/alerts/alertsCodes"

const AlertActionsButtons = ({ alertData, setLoading, handleOpenCloseDrawer, activeFeatures }) => {
  const dispatch = useAppDispatch()

  const handleOnClickAction = async () => {
    setLoading(true)
    // Se realiza la peticion solamente para ver el detalle de la alerta donde se realiza el click
    const noticeType = await getNoticeTypeDataOfMessage(alertData.notice_type);
    const residentials = await getResidentialsById(alertData.residential);
    const userFrom = await getUserDataProfileById(alertData.user_from);

    const completedAlertData = {
      ...alertData,
      notice_type_data: noticeType,
      residential_data: residentials,
      user_from_data: userFrom,
    }

    // Se realiza el dispatch con la nueva informacion devuelta de firebase ya que es requerida en el drawer
    dispatch(setAlertSelectedDetail(completedAlertData))
    handleOpenCloseDrawer()
    setLoading(false)
  }

  return <Row justify="center">
    <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ALERTS_CODES.ALERTS_DETAILS)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Detalles de la alerta'}`}>
      <Button
        disabled={!activeFeatures?.find(feature => feature.feature_code === ALERTS_CODES.ALERTS_DETAILS)?.enabled}
        type="link"
        htmlType="button"
        shape="round"
        onClick={handleOnClickAction}
      >
        <img src={detailAlertIcon} alt="detail-alert" style={{ width: 25 }} />
      </Button>
    </CustomPopoverConfirm>
  </Row>
}

export default AlertActionsButtons