import { Button, Col, Row } from 'antd'

const ModalFooter = ({ currentStep, setCurrentStep, loading, handleSubmit, saveTempRef }) => {
    return (
        <Row>
            <Col span={6} style={{ textAlign: "left" }}>
                {
                    currentStep > 1 && <Button
                        loading={loading}
                        htmlType='button'
                        className='btn-cancel'
                        type="primary"
                        shape="round"
                        style={{ width: "90%" }}
                        onClick={() => {
                            setCurrentStep((prev) => prev - 1)
                        }}
                    >
                        REGRESAR
                    </Button>
                }
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
                {
                    currentStep >= 2 && <Button
                        loading={loading}
                        className='btn-borrador'
                        htmlType='button'
                        type="primary"
                        shape="round"
                        style={{ width: "90%", backgroundColor: currentStep === 2 ? '#CEB457' : 'var(--primary)' }}
                        onClick={(e) => {
                            if (currentStep === 2) {
                                saveTempRef.current = true
                            }
                            handleSubmit(e)
                        }}
                    >
                        {
                            currentStep === 2 ? 'GUARDAR Y CONTINUAR DESPUÉS' : 'PUBLICAR ENCUESTA'
                        }
                    </Button>
                }
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
                {currentStep < 3 && <Button
                    // disabled={requests.isSaving}
                    className='btn-primary'
                    htmlType='button'
                    type="primary"
                    shape="round"
                    style={{ width: "90%" }}
                    onClick={(e) => {
                        handleSubmit(e)
                    }}
                >
                    CONTINUAR
                </Button>}
            </Col>
        </Row>
    )
}

export default ModalFooter