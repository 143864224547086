import { Col, Row } from 'antd'
import { useEffect } from 'react'
import { CustomSpin } from '../../UI/CustomSpin'
import { useAppSelector } from '../../../hooks/store'
import { CustomNavigate } from '../../UI/CustomNavigate'
import { useFeatureRole } from '../../hooks/useFeatureRole'
import useHierarchyAdministration from './hooks/useHierarchyAdministration'
import HierarchyAdministrationRoute from './components/HierarchyAdministrationRoute'
import HierarchyAdministrationContainer from './components/HierarchyAdministrationContainer'
import { MANAGEMENT_SPACES_CODES, MANAGEMENT_SPACES_CODES_NAMES } from '../../../constants/modules/managmentSpaces/managmentSpacesCodes'

import './style.css'

const HierarchyAdministrationPage = () => {
  const { loading, getDetailHierarchyAdmin } = useHierarchyAdministration()
  const { currentRol, idToken, companyName } = useAppSelector(state => state.auth)
  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: MANAGEMENT_SPACES_CODES, validate_code: MANAGEMENT_SPACES_CODES_NAMES.MANAGEMENT_SPACES_MODULE })

  useEffect(() => {
    if(currentRol && idToken){
      getDetailHierarchyAdmin()
    }
  }, [])


  if (loading || loadingFeatures) return <CustomSpin tip='Cargando módulo' />

  return (
    <section className='section-wrapper'>
      <CustomNavigate title={'ADMINISTRACIÓN DE ESPACIOS'} />

      <p style={{color:'#232C4A', fontSize:'14px', marginLeft:'3.3rem'}}>
        ROOT PRINCIPAL: {companyName}
      </p>
      
      <Row gutter={40} style={{ marginBlock:'2rem' }}>
        <Col span={6} >
          <HierarchyAdministrationRoute />
        </Col>
        <Col span={18}>
          <HierarchyAdministrationContainer activeFeatures={activeFeatures} />
        </Col>
      </Row>
    </section>
  )
}

export default HierarchyAdministrationPage;