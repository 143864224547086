import { TimePicker } from "antd";

export const CustomTimePicker = ({
  value,
  onChange,
  id,
  disabled,
  suffixIcon = "",
  format="hh:mm a",
  className = "custom-timepicker",
  startHour = 0,
  startMinute,
  endHour = 24,
  minuteStep = 15,
  placeholder,
  init = true,
  allowClear = true,
  showNow = false,
}) => {

  //Init es util si se quieren tener dos timePickers en el mismo componente de tal forma que uno sea el inicial y el otro el final

  const disabledHours = () => {
    const newStart = startMinute >= 45 ? startHour + 1 : startHour;
    const hours = [];
    if (!(newStart > 0 && newStart <= 24) && !(endHour > 0 && endHour <= 24))
      return [];
    for (let i = 0; i < 24; i++) {
      if (
        (i >= 0 && i < newStart) ||
        (i >= endHour && i < 24 && init) ||
        (i > endHour && i < 24)
      ) {
        hours.push(i);
      }
    }
    return hours;
  };
  
  const disabledMinutes = (selectedHour) => {
    const minutes = [];
    if (!(startHour > 0 && startHour <= 24) && !(endHour > 0 && endHour <= 24))
      return [];
    //Si l
    const initialMinute = startHour === selectedHour && !init ? 0 : null;
    minutes.push(initialMinute);
    if (endHour === selectedHour) {
      minutes.push(15);
      minutes.push(30);
      minutes.push(45);
    }
    if(startHour === selectedHour){
      for (let i = startMinute; i > 0; i -= startMinute) {
        minutes.push(i);
      }
    } 
   

    return minutes;
  };

  const disabledTime = (now) => {
    return {
      disabledHours,
      disabledMinutes,
    };
  };

  return (
    <>
      <TimePicker
        id={id}
        placeholder={placeholder}
        allowClear={allowClear}
        //disabledTime={disabledTime}
        disabled={disabled}
        hideDisabledOptions={true}
        className={className}
        format={format}
        minuteStep={minuteStep}
        suffixIcon={suffixIcon}
        onChange={onChange}
        value={value}
        showNow={showNow}
      />
    </>
  );
};