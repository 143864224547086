import { Row, Col, Button } from "antd";
import { CustomNavigate } from "../../../../../UI/CustomNavigate";
import { CustomCalendar } from "./components/CustomCalendar/CustomCalendar";
import { useNewCheckList } from "../../hooks/useNewCheckList";
import { FormNewCheckList } from "./components/FormNewCheckList/FormNewCheckList";
import { useEffect } from "react";
import { summaryCodeType } from "../../types/summaryCodeType";
import { deleteTaskIcon, inProgressTaskIcon, pausedTaskIcon } from "../../../assets/icons";
import { CustomSpin } from "../../../../../UI/CustomSpin";
import useCurrentLocation from "../../../../../hooks/useCurrentLocation";
import "./newCheckListStyle.css";
import { useFeatureRole } from "../../../../../hooks/useFeatureRole";
import { ADMIN_CHECK_CODES, ADMIN_CHECK_CODES_NAMES } from "../../../../../../constants/modules/adminCheck/adminCheckCodes";
import CustomPopoverConfirm from "../../../../../UI/CustomPopoverConfirm";

export const NewCheckList = () => {
  const {
    Form,
    form,
    formRef,
    eventsCalendar,
    config,
    generalSchedule,
    loading,
    loadingPage,
    detailsRef,
    taskStatus,
    setLoadingPage,
    setEventsCalendar,
    onSubmit,
    onFailedSubmit,
    handleChangeTimePicker,
    handleGetWeekDays,
    handleSubmit,
    handleGeneralSchedule,
    handleChangeTaskStatus,
    getCheckListDetail,
    getEmployeesService,
  } = useNewCheckList();

  const { param } = useCurrentLocation()
  const { loadingFeatures, activeFeatures, handleRedirectTo } = useFeatureRole({ feature_codes: ADMIN_CHECK_CODES, validate_code: ADMIN_CHECK_CODES_NAMES.ADMIN_CHECK_MODULE, returnUrl: '/modules' })

  useEffect(() => {
    if (!activeFeatures.length) return handleRedirectTo('/checklist/admin')
    if (param.idTaskChecklist && !detailsRef.current) {
      detailsRef.current = true;
      getEmployeesService({});
      getCheckListDetail({ task_id: param.idTaskChecklist })
    }
    else {
      setLoadingPage(false)
    }
  }, [])

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (
    <CustomSpin section spinning={loadingPage}>
      <section className="section-wrapper newChecklist">
        {!loadingPage && <><CustomNavigate title={'NUEVA TAREA'} secondTitle={'EDITAR TAREA'} condition={detailsRef.current} />
          <Row className="newChecklist__container" gutter={32}>
            <Col span={12}>
              <FormNewCheckList
                Form={Form}
                form={form}
                formRef={formRef}
                config={config}
                generalSchedule={generalSchedule}
                handleChangeTimePicker={handleChangeTimePicker}
                handleGetWeekDays={handleGetWeekDays}
                handleGeneralSchedule={handleGeneralSchedule}
                onSubmit={onSubmit}
                onFailedSubmit={onFailedSubmit}
              />
            </Col>
            <Col className="newChecklist__calendar" span={12}>
              <h3>Turnos de la semana</h3>
              <CustomCalendar setEventsCalendar={setEventsCalendar} events={eventsCalendar} />
            </Col>
          </Row>
          <Row className="newChecklist__footer">
            {
              detailsRef.current && taskStatus === summaryCodeType.DELETED && <>
                <h2>LA TAREA HA SIDO ELIMINADA</h2>
              </>
            }
            {detailsRef.current && taskStatus !== summaryCodeType.DELETED && taskStatus !== summaryCodeType.UNASSIGNED && <>
              <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_DELETE_TASK)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Eliminar tarea'}`}>
                <Button
                  disabled={!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_DELETE_TASK)?.enabled}
                  icon={<img src={deleteTaskIcon} alt="delete-task-icon" />}
                  loading={loading}

                  htmlType="submit"
                  onClick={(e) => {
                    handleChangeTaskStatus({ value: summaryCodeType.DELETED });
                  }}
                  className="btn-standard-white"
                  style={{ width: 200 }}
                  shape="round"
                >
                  ELIMINAR TAREA
                </Button>
              </CustomPopoverConfirm>
              <Button
                icon={taskStatus === summaryCodeType.PROGRESS ? <img src={pausedTaskIcon} alt="paused-task-icon" /> : <img src={inProgressTaskIcon} alt="in-progress-task-icon" />}
                loading={loading}
                style={{ width: 200 }}
                htmlType="submit"
                onClick={(e) => {
                  handleChangeTaskStatus({ value: taskStatus === summaryCodeType.PROGRESS ? summaryCodeType.PAUSED : summaryCodeType.ACTIVE });
                }}
                className="btn-standard-white"
                shape="round"
              >
                {taskStatus === summaryCodeType.PROGRESS ? 'PAUSAR TAREA' : 'CONTINUAR TAREA'}
              </Button></>
            }
            {
              taskStatus !== summaryCodeType.DELETED &&
              <Button
                loading={loading}
                htmlType="submit"
                onClick={(e) => {
                  handleSubmit(e);
                }}
                className="btn-standard-green"
                style={{ width: 268 }}
                shape="round"
              >
                {detailsRef.current ? 'GUARDAR' : 'CREAR TAREA'}
              </Button>
            }
          </Row>
        </>}

      </section>
    </CustomSpin>
  );
};
