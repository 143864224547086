
export const NEWS_CODES = {
    NEWS_MODULE: 'FEAT-305',
    NEWS_TABLE: 'FEAT-71',
    NEWS_DETAILS_NEWS: 'FEAT-72',
    NEWS_NEW_NEWS: 'FEAT-306',
    NEWS_UPDATE_NEWS: 'FEAT-307',
}

export const NEWS_CODES_NAMES = {
    NEWS_MODULE: 'NEWS_MODULE',
    NEWS_TABLE: 'NEWS_TABLE',
    NEWS_DETAILS_NEWS: 'NEWS_DETAILS_NEWS',
    NEWS_NEW_NEWS: 'NEWS_NEW_NEWS',
    NEWS_UPDATE_NEWS: 'NEWS_UPDATE_NEWS',
}