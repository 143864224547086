import { useContext } from "react"
import { Button, Col, Row } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons"
import { CustomNavigate } from "../../../../../../../UI/CustomNavigate"
import { AdminChecklistTasksContext } from "../context/AdminChecklistTasksContext"

const HeaderFiltersAdminChecklistTask = () => {
  const { form, handleOpenCloseModal, setTaskClicked,checklistTasksTable } = useContext(AdminChecklistTasksContext)

  return (<>
    <CustomNavigate title="ADMINISTRACIÓN DE TAREAS">
      <Button
        type="text"
        htmlType="button"
        shape="round"
        style={{ width: 221 }}
        onClick={() => {
          form.resetFields()
          handleOpenCloseModal()
          setTaskClicked({})
        }}
        className="btn-standard-green"
      >
        <Row gutter={16}>
          <Col>
            <span>NUEVA TAREA</span>
          </Col>

          <Col>
            <PlusCircleOutlined style={{ fontSize: 13, lineHeight: 0 }} />
          </Col>
        </Row>
      </Button>
    </CustomNavigate>

    <h3 style={{ color: '#0D9C7A', fontSize: 16, fontWeight: 600, marginLeft: 50, marginBottom: 0 }}>
      {checklistTasksTable?.category_name}
    </h3>
  </>
    
  )
}

export default HeaderFiltersAdminChecklistTask