import { useState } from 'react'
import { Button, Col, Radio, Row, Space } from 'antd'
import CustomTable from '../../../UI/CustomTable/CustomTable'
import NewEditHierarchyModal from './modal/NewEditHierarchyModal'
import CustomPopoverConfirm from '../../../UI/CustomPopoverConfirm'
import { getDetailPlaceById } from '../../../../store/malls/thunks'
import { setIdClickedPlace } from '../../../../store/malls/mallsSlice'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import useHierarchyAdministration from '../hooks/useHierarchyAdministration'
import DetailLocalDrawer from '../../buildingSketchs/components/DetailLocalDrawer'
import { MANAGEMENT_SPACES_CODES } from '../../../../constants/modules/managmentSpaces/managmentSpacesCodes'
import { currentParentIdThunk, getPlacesHierarchyAdminSpaceThunk } from '../../../../store/hierarchyAdmin/thunk'

// Imagenes o archivos
import EditIcon from '../../../../assets/icons/EditIcon'
import imgNull from '../../../../assets/icons/empty.svg'
import filterOrderBy from '../../../../assets/icons/orderBy.svg'
import VisionEyeIcon from "../../../../assets/icons/Generals/BuildingMapFlow/VisionEyeIcon"

import '../style.css';

const HierarchyAdministrationTable = ({
  form,
  path,
  isOpenModal,
  activeFeatures,
  handleOpenCloseModal
}) => {
  const dispatch = useAppDispatch()

  const [value, setValue] = useState("")
  const [toggle, setToggle] = useState(false)
  const [sortOrder, setSortOrder] = useState("")
  const [editPlace, setEditPlace] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)

  const handleIsOpenCloseDrawer = () => setIsOpenDrawer(state => !state)

  const { idToken, currentRol, typeRol } = useAppSelector((state) => state.auth)
  const { placeTypes, currentParentId } = useAppSelector((state) => state?.hierarchyAdminSpace)
  const { dataSource = [], columns = [], loading, setLoading, quantity, limit } = useHierarchyAdministration()

  const changePageHandler = async (currentPag, limit) => {
    const pageValue = currentPag || currentPage
    const newSkip = (pageValue * limit) - limit
    setCurrentPage(pageValue)
    await dispatch(await getPlacesHierarchyAdminSpaceThunk({ companyId: currentRol, idToken: idToken, parentId: currentParentId, last: "", skip: newSkip }))
  }
  const data = dataSource?.map(data => ({
    name: data?.name,
    type: data?.type,
    status: data?.status,
    place_id: data?.place_id,
    type_id: data?.type_id,
    // profile_id: data?.profile_id,
    // children: data?.children (Esta prop hace tronar el componente table porque interpreta que es para expandir la fila)
    // childrenRef: data?.children
    // parent_id: hierarchyAdmin.parentId
  }))
  const route = `Root / ${path?.join(' / ')}`;
  const formattedRoute = route.replace(/\/undefined/g, "").replace(/undefined/g, "");

  const handleEditPlace = (hierarchyDataSelected) => {
    setEditPlace(hierarchyDataSelected.place_id);
    form.setFieldsValue({
      type: hierarchyDataSelected?.type_id || "",
      name: hierarchyDataSelected.name || "",
      parent_Id: formattedRoute
    });
    handleOpenCloseModal()
  }
  // Funcion para saber que icono pertenece dependiento del ID
  const verifyTypeIcon = (iconId) => {
    if (!placeTypes) return
    const imageToRender = placeTypes?.find((typeHierarchy) => typeHierarchy.id === iconId)
    return <img style={{ width: 24, height: 24 }} src={imageToRender?.image || imgNull} alt="icon-place" />
  }
  // Funcion para ordenar por desc y asc
  const handleToggleSort = async () => {
    setLoading(true)
    setToggle(!toggle)
    setCurrentPage(1);
    if (toggle) {
      setSortOrder("desc")
      await dispatch(await getPlacesHierarchyAdminSpaceThunk({ companyId: currentRol, idToken: idToken, parentId: currentParentId, sortOrder: sortOrder, sortOrderBy: value }))
    } else {
      setSortOrder("asc")
      await dispatch(await getPlacesHierarchyAdminSpaceThunk({ companyId: currentRol, idToken: idToken, parentId: currentParentId, sortOrder: sortOrder, sortOrderBy: value }))
    }
    setLoading(false)
  }
  // Funcion para ordenar por tipo
  const onChange = async (e) => {
    setLoading(true)
    setCurrentPage(1);
    await dispatch(await getPlacesHierarchyAdminSpaceThunk({ companyId: currentRol, idToken: idToken, parentId: currentParentId, sortOrder: sortOrder, sortOrderBy: e.target.value }))
    setValue(e.target.value);

    setLoading(false)
  };

  const ActionsOrderBy = () => {
    return (
      <Row gutter={10} align='middle'>
        <Col span={12}>
          <p style={{ fontSize: 10 }}>Ordenar por:</p>
          <Button style={{ border: '1px solid var(--primary)', paddingBottom: 30, boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.1)' }} shape='round' onClick={handleToggleSort} >
            <span style={{ fontSize: 10 }}>{sortOrder === 'asc' ? "Ascendente" : "Descendente"}</span>
          </Button>
        </Col>
        <Col span={12}>
          <Radio.Group onChange={onChange} value={value} >
            <Space direction="vertical">
              <Radio value={"name"}>Nombre</Radio>
              <Radio value={"type"}>Tipo</Radio>
              <Radio value={"status"}>Estatus</Radio>
            </Space>
          </Radio.Group>
        </Col>
      </Row>
    )
  }
  const BtnsByOrder = () => {
    return (
      <Row justify='center'>
        <CustomPopoverConfirm
          destroyTooltipOnHide
          overlayClassName={"task-reception-popover"}
          placement={"bottomRight"}
          trigger={"click"}
          content={<ActionsOrderBy />}
        >
          <Button type='ghost' style={{ border: 'none' }}> <img src={filterOrderBy} alt="" /> </Button>
        </CustomPopoverConfirm>
      </Row>
    )
  }

  const onFinish = (isSuccessfull) => {
    if (isSuccessfull) {
      handleIsOpenCloseDrawer()
    }
  }

  // Funcion para manejar el on click de ver la slide de detalle de el espacio, igual que en mapas
  const handleOnClickToGetSpaceData = async (placeId) => {
    dispatch(setIdClickedPlace(placeId.toString()))
    const response = await dispatch(await getDetailPlaceById(placeId))

    onFinish(response)
  }

  const newColums = [
    {
      title: <BtnsByOrder />,
      dataIndex: "actions",
      align: "center",
      render: (_, record) => {
        return (
          verifyTypeIcon(record.type_id)
        )
      }
    },
    ...columns, {
      title: "",
      dataIndex: "",
      render: (_, record) => {
        return (
          <Row justify='center'>
            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_SPACES_CODES.MANAGEMENT_SPACES_UPDATE_SPACE)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Actualizar espacio'}`}>
              <Button
                type="link"
                shape="circle"
                htmlType="button"
                style={{ border: 'none' }}
                onClick={() => handleEditPlace(record)}
                icon={<EditIcon color="var(--primary)" width="20px" />}
                disabled={!activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_SPACES_CODES.MANAGEMENT_SPACES_UPDATE_SPACE)?.enabled}
              />
            </CustomPopoverConfirm>

            {typeRol === 'mall' && (
                <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_SPACES_CODES.MANAGEMENT_CONTRACTORS_MAP)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Ver Detalles de espacio'}`}>
                  <Button
                    type="link"
                    shape="circle"
                    htmlType="button"
                    onClick={() => handleOnClickToGetSpaceData(record.place_id)}
                    icon={<VisionEyeIcon />}
                    disabled={!activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_SPACES_CODES.MANAGEMENT_CONTRACTORS_MAP)?.enabled}
                    />
                </CustomPopoverConfirm>
            )}
          </Row>
        )
      }
    },]

  const handleRowClick = async (record) => {
    setLoading(true)

    if (record.type_id === 1) {
      await dispatch(await getPlacesHierarchyAdminSpaceThunk({ companyId: currentRol, idToken: idToken, parentId: record.place_id }));
      dispatch(currentParentIdThunk({ currentParentId: record.place_id }))
    }

    setLoading(false)
  }

  return (
    <Row style={{ marginTop: '1rem' }}>
      <Col span={24}>
        <CustomTable
          className='custom-table-variant-1'
          loading={loading}
          columns={newColums ?? []}
          dataSource={data ?? []}
          onRow={(record, rowIndex) => ({
            onDoubleClick: async (event) => {
              handleRowClick(record)
            },
          })}
          pagination={{
            total: quantity,
            pageSize: limit,
            onChange: async (current) => {
              setLoading(true)
              await changePageHandler(current, limit)
              setLoading(false)
            },
            showTotal: (total, range) => {
              return `${range[0]}-${range[1]} de ${total}`;
            },
            current: currentPage,
          }}
        />
      </Col>

      <NewEditHierarchyModal
        form={form}
        editPlace={editPlace}
        setEditPlace={setEditPlace}
        isOpenModal={isOpenModal}
        onClose={handleOpenCloseModal}
        placeId={editPlace}
      />
      
      {typeRol === 'mall' && (
        <DetailLocalDrawer isOpenDrawer={isOpenDrawer} onCloseDrawer={handleIsOpenCloseDrawer} />
      )}
    </Row>
  )
}


export default HierarchyAdministrationTable
