
//Tendrá alguna funcionalidad adicional?
export const CustomTemplate = ({
  isAuthenticating,
  loading,
  config,
  onSubmit,
  children,
}) => {
  return <>{children}</>;
};
