import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const libaryPath = 'document/library'

export const deleteDocument = async ({
    token,
    document_id
}) => {
    try {
        const params = {
            document_id
        };

        const response = await axiosCreate.delete(`${libaryPath}`, {
            params, timeout: 10000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
}