import axios from "axios";

const activateEmployeeService = async(data) => {
    try {
        const res = await axios.put('https://domo.topiaapp.com/api/employee/status/active', data)
        return {
            success: true,
            data: res.data,
            status: res.status
        }
    } catch (error) {
        return {
            success: true,
            error:  error.response
        }
    }
};

export default activateEmployeeService;