import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "antd";
import "./style.css";
import InventoryOptions from "./components/InventoryOptions";
import { useDispatch, useSelector } from "react-redux";
import {
  setInventoryThunk,
  getAllCategoriesThunk,
} from "../../../store/inventory/thunks";
import EditIcon from "../../../assets/icons/EditIcon";
import CategoryDrawer from "./UI/CategoryDrawer";
import { CustomNavigate } from "../../UI/CustomNavigate";
import { CustomSpin } from "../../UI/CustomSpin";
import { useFeatureRole } from "../../hooks/useFeatureRole";
import { INVENTORY_CODES, INVENTORY_CODES_NAMES } from "../../../constants/modules/inventory/inventoryCodes";
import CustomPopoverConfirm from "../../UI/CustomPopoverConfirm";

const InventoryPage = () => {
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const handleOpenCloseDrawer = () => {
    setOpenDrawer((state) => !state);
    setErrorMessage(false);
  };
  const dispatch = useDispatch();
  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: INVENTORY_CODES, validate_code: INVENTORY_CODES_NAMES.INVENTORY_MODULE, returnUrl: '/modules' })

  useEffect(() => {
    getAllDataInventory();
  }, [dispatch]);

  const getAllDataInventory = async () => {
    setLoading(true);
    await dispatch(setInventoryThunk());
    await dispatch(getAllCategoriesThunk());
    setLoading(false);
  };

  const inventories = useSelector((state) => state?.inventory);
  const filteredInventories = inventories.slice(0, inventories.length - 1);
  const out_of_stock = inventories[inventories.length - 1];

  if (loading || loadingFeatures) return <CustomSpin size="large" />;

  return (
    <div className="section-wrapper">
      <CustomNavigate title={
        <span /*style={{ paddingTop: "3px", color: "var(--primary)" }}*/>
          ADMINISTRACIÓN DE INVENTARIO
        </span>}
      >
        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === INVENTORY_CODES.INVENTORY_MANAGEMENT_SUBCATEGORIES)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Administración de subcategorías'}`}>
          <Button
            disabled={!activeFeatures?.find(feature => feature.feature_code === INVENTORY_CODES.INVENTORY_MANAGEMENT_SUBCATEGORIES)?.enabled}
            className="btn-standard-green"
            type="button"
            shape="round"
            onClick={handleOpenCloseDrawer}
          >
            <Row justify="center" align="middle" gutter={6}>
              <Col>
                <EditIcon width="20px" />
              </Col>

              <Col>
                <span>ADMINISTRAR SUBCATEGORÍAS</span>
              </Col>
            </Row>
          </Button>
        </CustomPopoverConfirm>
      </CustomNavigate>
      <Row gutter={[28, 28]}>
        {filteredInventories.map((inventory, index) => (
          <Col key={index} xs={24} sm={12}>
            <InventoryOptions activeFeatures={activeFeatures} outStock={out_of_stock} inventory={inventory} />
          </Col>
        ))}
      </Row>

      <CategoryDrawer
        activeFeatures={activeFeatures}
        openDrawer={openDrawer}
        closeDrawer={handleOpenCloseDrawer}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

export default InventoryPage;
