import { collection, query, where, getDocs, doc, getDoc, updateDoc, setDoc,deleteDoc } from "firebase/firestore";
import { FirebaseDB } from '../firebase/config';
import moment from 'moment';

export const getDocByCollectionAnId = async(collection, id) => {
    
    const docRef = doc(FirebaseDB, collection, id);
    const docSnap = await getDoc(docRef);
    
    let data = null;
    if (docSnap.exists()) {
        data = docSnap.data();
    }
    
    return data;
}

/**
 * OPERATORS
 * < menor que
<= menor o igual que
== igual que
> mayor que
>= mayor que o igual que
!= no igual a
*@collectionName
*@conditionField
*@operator
*@param
*/
export const getQueryDocByCollection = async(collectionName, conditionField, operator, param) => {
    
    const collectionRef = collection( FirebaseDB, collectionName );
    // Obtenemos solo las activas
    const queryData = query(collectionRef, where(conditionField, operator, param));
    const querySnapshot = await getDocs(queryData);
        

    const data = [];
    querySnapshot.forEach( async (documment) => {
        let doc = documment.data();
        data.push({ id: documment.id, ...doc  });
    });

    return data;
}

/**
 * 
 * @param {*} ref 
 * @returns 
 */
export const getReferenceData = async (ref) => {
    if(!ref) return null;

    const collectionRef = doc( FirebaseDB, ref );
    const docSnap = await getDoc(collectionRef);
    if (docSnap.exists()) {
        return docSnap.data();
    }

    return null;
}

/**
 * 
 * Obtiene datos del perfil del usuario
 */
export const getUserDataProfileById = async (id) => {
    if(!id) return null;
    const data = await getQueryDocByCollection("profile", "user_id" , "==", id);
    
    if(data[0]){
        let user = await serializeUserData({ ...data[0]},data[0].id);
        return user;
    }else{
        const collectionRef = doc( FirebaseDB, '/profile/'+id );
        const docSnap = await getDoc(collectionRef);
        if (docSnap.exists()) {
            let user = await serializeUserData({ ...docSnap.data()},docSnap.id);
            return user;
        }
    }

    return null;

}

export const serializeUserData = async (user,idProfile) => {
    if(user){
        let userData = {};
        userData.id = idProfile;
        userData.created_at = moment(user.created_at?.toDate()).format("Y-MM-DD");
        userData.created_time = moment(user.created_at?.toDate()).format("HH:mm");
        userData.birthdate = moment(user.birthdate?.toDate()).format("Y-MM-DD");
        userData.updated_at = moment(user.updated_at?.toDate()).format("Y-MM-DD");
        userData.residentials = [];
        userData.image = user.image;
        userData.imageDUI = user.imageDUI;
        userData.observations = user.observations === undefined ? "" : user.observations;
        userData.mora = user.mora;
        userData.name = user.name;
        userData.lastname = user.lastname;
        userData.sociedadAnonima = user.sociedadAnonima;
        userData.phone = user.phone;
        userData.phoneTwo = user.phoneTwo;
        userData.phoneThree = user.phoneThree;
        userData.phoneFour = user.phoneFour;
        userData.stateDUI = user.stateDUI;
        userData.user_id = user.user_id;
        userData.verified = user.verified;
        userData.pet = user.pet;
        userData.number_children = user.number_children;
        userData.number_senior_citizens = user.number_senior_citizens;
        userData.diseases = user.diseases;
        userData.hasChildren = user.children ? true : false;
        userData.email = user.email;
        userData.emailTwo = user.emailTwo;
        userData.home_location = user.home_location;
        userData.gender = user.gender;
        userData.list_vehicle = user.list_vehicle;
        userData.role_id = user.role_id;
        userData.token = user.token;
        userData.vehicle = user.vehicle;
        userData.address = user.address;
        userData.structure_id = user.structure_id;
        userData.contact_phone = user.contact_phone;
        userData.vehicles_list_data = [];
        userData.verified = user.verified === true ? "Verificado": "Pendiente";

        
        let ownerHome = false;
        userData.ownerHome = false;

        if(user.residentials){
            if(user.residentials.length > 0){
                user.residentials.forEach(item => {
                    if (item.homePrincipal) ownerHome = true;
                    userData.residentials.push({
                        homePrincipal: item.homePrincipal,
                        idResidential: item.idResidential,
                        house: item.house,
                        type: item.type
                    })


                });
            }
        }

        if(ownerHome){
            userData.ownerHome = true;
        }

        
        return userData;
    }


    return null;

}

/**
 * Obtiene los datos de la residencia
 * @id
 */
export const getResidentialsById = async (id) => {
    if(!id) return null;

    const data = await getDocByCollectionAnId("residentials", id);
    if(data){
        let residentialData = { ...data };
        residentialData.created_at = moment(data.created_at).format("Y-MM-DD");
        residentialData.created_time = moment(data.created_at).format("HH:mm");
        residentialData.updated_at = null;
        residentialData.geolocation = null;
        residentialData.entity_type = null;

        return residentialData;
    }
    return null;

}



/** Actualizar documento
 * @collectionName
 * @conditionField
 * @operator
 * @param
 * @dataToUpdate
 */
export const onUpdateDocWithWhere = async(collectionName, conditionField, operator, param, dataToUpdate) => {

    const collectionRef = collection( FirebaseDB, collectionName );
    const queryData = query(collectionRef, where(conditionField, operator, param));
    await updateDoc(queryData, dataToUpdate);
}

export const onUpdateDocWithRefInfo = async(ref, dataToUpdate) => {
    const collectionRef = doc( FirebaseDB, ref );
    await updateDoc(collectionRef, dataToUpdate);
}

/** Actualizar documento
 * @ref
 * @dataToUpdate
 */
 export const onUpdateDocWithRef = async(ref, dataToUpdate) => {
    const collectionRef = doc( FirebaseDB, ref );
    await setDoc(collectionRef, dataToUpdate);
}

/** Crear o actualizar documento
 * @ref
 * @dataToUpdate
 */
export const onCreateOrUpdate = async(collectionName, data) => {    
    const collectionRef = doc(collection( FirebaseDB, collectionName ));
    setDoc(collectionRef, data);
    
    return collectionRef; 
}


/** Eliminar documento
 * @ref
 * @dataToUpdate
 */
 export const onDeleteDoc = async(collection,id) => {
    const collectionRef =  deleteDoc(doc(FirebaseDB, collection, id));
    return collectionRef; 
}
