import { updateAlertById } from "../../../../helpers/alertHelper"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { getAlerts, setAlertsActive, setAlertsArchive, setIsLoading } from "../../../../store/alerts"

const useAlerts = () => {
  const dispatch = useAppDispatch()
  const { typeRol } = useAppSelector(state => state.auth)

  const updateAlertData = async (alertId, data) => {
    const response = await updateAlertById(alertId, data)

    if (response.success) {
      return true
    }

    return false
  }

  // Funcion para obtener las alertas en home
  const getAllAlerts = async ({ isLimit = false, startDate, endDate }) => {
    dispatch(setIsLoading(true))
    // Si solo es un condominio o residencial se realiza la peticion de alertas
    if (typeRol !== 'mall') {
      const activeAlerts = await dispatch(getAlerts({ isLimit: isLimit, startDate: startDate, endDate: endDate }))
      dispatch(setIsLoading(false))
      
      return activeAlerts
    }
    // Si no es un centro comercial se limpia la store de la redux
    dispatch(setAlertsActive([]))
    dispatch(setAlertsArchive([]))
    dispatch(setIsLoading(false))
  }

  return {
    getAllAlerts,
    updateAlertData
  }
}

export default useAlerts