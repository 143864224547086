import { Button, Col, Row } from 'antd';
import React from 'react';
import useOpenCloseModal from '../../../../../../../hooks/useOpenCloseModal';
import { useState, useEffect } from 'react';
import EquipmentTaskModal from '../modals/EquipmentTaskModal';
import { useDispatch } from 'react-redux';
import { setInventoryThunk } from '../../../../../../../../store/inventory/thunks';
import { CreateTicketByCheckListContext } from '../../../../context/CreateTicketByCheckListContext';
import { useContext } from 'react';
import TrashIcon from '../../../../../../../../assets/icons/TrashIcon';
import { PlusOutlined } from '@ant-design/icons';

const EquipmentTask = ({ inventoryData, setCountEquipments }) => {
    const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal();
    const { setEquipmentSelected } = useContext(CreateTicketByCheckListContext);
    const [dataItems, setDataItems] = useState([]);
    const [save, setSave] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setInventoryThunk(searchWord, "equipments"));
    }, [searchWord]);
    const closeModal = () => {
        handleOpenCloseModal();
        dispatch(setInventoryThunk("", "equipments"));
    }

    const handleDelete = (id) => {
        const newData = dataItems.filter(item => item.id !== id);
        setDataItems(newData);
    }
    setEquipmentSelected(dataItems);
    setCountEquipments(dataItems.length);
    return (
        <div style={{ marginTop: '1.5rem' }}>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {!dataItems.length && (
                    <Row justify='center' align='middle' style={{ border: '1px solid #D6D6D6', width: '100%', borderRadius: '6px', background: '#F6F6F6', paddingInline: '1.5rem', marginBottom: '1rem' }} >
                        <Col>
                            <p style={{ paddingTop: '1.2rem', marginInline: '1rem' }}> No existe equipo asignado </p>
                        </Col>
                    </Row>
                )
                }
                {dataItems && save && dataItems.map((item, i) => (
                    <Row key={i} justify='space-between' align='middle' style={{ border: '1px solid #D6D6D6', width: '100%', borderRadius: '6px', background: '#F6F6F6', paddingInline: '1.5rem', marginBottom: '1rem' }} >
                        <Col>
                            <p style={{ paddingTop: '1.2rem', marginInline: '1rem' }}>{item?.name} ({item?.quantity})</p>
                        </Col>
                        <Col>
                            <Button onClick={() => handleDelete(item?.id)} type='ghost' style={{ border: 'none' }} >
                                <TrashIcon color="#E82A2A" width="20px" />
                            </Button>
                        </Col>
                    </Row>
                ))}
            </div>
            <Button
                icon={<PlusOutlined style={{ color: 'white', fontSize: '1.5rem' }} />}
                shape='circle'
                onClick={handleOpenCloseModal}
                style={{ width: '36px', height: '36px', border: '1px solid #D6D6D6', background: 'var(--primary)', position: 'absolute', top: '-0.5rem', right: 20 }}
            />
            <EquipmentTaskModal setSave={setSave} setSearchWord={setSearchWord} setDataItems={setDataItems} inventoryData={inventoryData} onCancel={closeModal} isOpenModal={isOpenModal} />
        </div>
    );
};

export default EquipmentTask;