import { Modal } from "antd";

export const CustomModal = ({
  width = 600,
  centered = true,
  customTitle = false,
  isOpenModal,
  onCancel,
  children,
  destroyOnClose,
  title,
  className = "primary-modal",
  wrapClassName,
  zIndex = 1000,
  confirmLoading = false,
  footer = null,
  maxWidth
}) => (
  <Modal
    wrapClassName={wrapClassName}
    confirmLoading={confirmLoading}
    centered={centered}
    className={className}
    visible={isOpenModal}
    onCancel={onCancel}
    footer={footer}
    width={width}
    destroyOnClose={destroyOnClose}
    title={customTitle ? <span style={{ width: '100%', display: 'block', textAlign: 'center', fontSize: 20, color: '#232C4A' }}>{title}</span> : title}
    zIndex={zIndex}    
    wrapProps={{ maxwidth: maxWidth }}
    style={{ maxwidth: maxWidth }}
  >
    {children}
  </Modal>
);



export default CustomModal;
