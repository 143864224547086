import { CustomInput } from '../../../../../UI/CustomInput'
import useSurveysForm from '../../../hooks/useSurveysForm'

const StepOne = () => {

    const { onSubmitForm, formRef, form, Form, initialFormData } = useSurveysForm();

    const config = {
        rules: [{ required: true, message: 'Por favor, llenar el campo' }]
    }

    return (
        <Form
            form={form}
            ref={formRef}
            initialValues={initialFormData}
            layout='vertical'
            name='survey-step-one'
            autoComplete='off'
            onFinish={onSubmitForm}>
            <Form.Item {...config} label="TITULO DE LA ENCUESTA" name={'name'}>
                <CustomInput />
            </Form.Item>
            <Form.Item {...config} label="DESCRIPCIÓN CORTA" name={'descripcion'}>
                <CustomInput type='AREA' />
            </Form.Item>
            <Form.Item name={'id'} noStyle >
                <CustomInput hidden />
            </Form.Item>
            <Form.Item name={'active'} noStyle>
                <CustomInput hidden />
            </Form.Item>
        </Form>
    )
}

export default StepOne