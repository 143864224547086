import { StyleSheet, Text, View } from "@react-pdf/renderer"

const Footer = () => {
    return (
        <View fixed style={styles.footer}>
            <Text render={({ pageNumber, totalPages }) => (`Página ${pageNumber} de ${totalPages}`)} style={{ paddingRight: 32 }} />
        </View>
    )
}

const styles = StyleSheet.create({
    footer: {
        fontFamily: 'Poppins',
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'right',
        fontSize: 10
    }
})

export default Footer