import { axiosCreate } from "../../../../config/index"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const getDefaultChecklistsByCompanyId = async ({
  company_id, checklist_status_id, idToken,
  find_value, sort_order, sort_by, limit, skip,
}) => {
  try {
    const params = {
      find_value: find_value ? find_value : null,
      company_id, checklist_status_id,
      sort_order: sort_order ? sort_order : null,
      sort_by: sort_by ? sort_by : null,
      limit, skip
    }

    const response = await axiosCreate.get('checklist', {
      params,
      headers: { Authorization: `Bearer ${idToken}` },
      timeout: 20000,
    })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getDefaultChecklistsByCompanyId