import { Button, Row, Upload } from "antd"
import CustomModal from "../../../CustomModal"
import { FileOutlined } from "@ant-design/icons"
import { verifyMessageTypeFile } from "../../../../helpers/customUploadHelper"

const AttachmentsModal = ({ isOpenModal, onCancel, onSaveFile, profileId, handleSendMessage, conversationByTicketId }) => {
  const onFinish = async (isSuccessful) => {
    if (isSuccessful) {
      onCancel()
    }
  }

  const getBase64 = (img, callback) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const onSubmitFiles = async (file) => {
    //setLoading(true)    
    onFinish(true)

    getBase64(file, (url) => {      
      const finalFile = {
        name: file.name,
        url,
        type: file.type
      }
      handleSendMessage({ file: finalFile })
    })

    const formData = new FormData()
    formData.append('files', file)
    if (conversationByTicketId && profileId) await onSaveFile(conversationByTicketId.id, profileId, formData)
  }

  return (
    <CustomModal
      onCancel={onCancel}
      isOpenModal={isOpenModal}
      destroyOnClose={true}
    >
      <Row justify="center">
        <Upload
          name="files"
          listType="text"
          action={onSubmitFiles}
          beforeUpload={verifyMessageTypeFile}
          accept=".png,.jpeg,.jpg,.xlsx,.xls,.doc,.docx,.pdf"
        >
          <Button type="button" className="btn-standard-green" shape="round" icon={<FileOutlined />}>
            <span>Agregar archivos</span>
          </Button>
        </Upload>
      </Row>
    </CustomModal>
  )
}

export default AttachmentsModal