import { useContext } from "react"
import ActionsButtons from "./ActionsButtons"
import CustomTable from "../../../../UI/CustomTable/CustomTable"
import { verifyStatusColor } from "../../../../helpers/verifyTypeColors"
import { HandoverChecklistContext } from "../context/HandoverChecklistContext"

const HandoverChecklistTable = ({
  checklists,
  currentPage,
  getAllHierarchyData,
  changePageHandler,
  checklistsPaginationData,
  activeFeatures,
}) => {
  const { columns, dataSource } = checklists
  const { quantity, limit } = checklistsPaginationData

  const { form, loadingTable, setLoadingTable, handleOpenCloseModal } = useContext(HandoverChecklistContext)

  let objStatusColors = {
    'schedule': '#59ADFF',
    'done': '#16A07F',
    'inProgress': '#E4B228',
    'canceled': '#E82A2A',
    'default': '#252525',
  }

  const styledColumns = columns?.map((column) => {
    const statusColumn = column.dataIndex === 'statusName'
      ? {
        ...column,
        render: (text, record) => (
          <span style={{
            textTransform: `${(record.statusCode === 'canceled' || record.statusCode === 'done' || record.statusCode === 'inProgress' || record.statusCode === 'schedule') ? "uppercase" : ""}`,
            color: `${verifyStatusColor(record.statusCode, objStatusColors)}`,
            fontSize: `${(record.statusCode === 'schedule' || record.statusCode === 'done' || record.statusCode === 'inProgress' || record.statusCode === 'canceled') ? 12 : 14}`,
            fontWeight: `${(record.statusCode === 'schedule' || record.statusCode === 'done' || record.statusCode === 'inProgress' || record.statusCode === 'canceled') ? 600 : 400}`,
          }}>
            {text}
          </span>
        )
      }
      : column

    return ({
      ...statusColumn
    })
  })

  const newColumns = [
    ...styledColumns ?? [],
    {
      title: "",
      dataIndex: "actions",
      align: "right",
      render: (_, subCategory) => (
        <ActionsButtons
          activeFeatures={activeFeatures}
          form={form}
          subCategory={subCategory}
          getAllHierarchyData={getAllHierarchyData}
          handleOpenCloseModal={handleOpenCloseModal}
        />
      )
    }]

  return (
    <CustomTable
      className="custom-table-variant-3"
      columns={newColumns}
      dataSource={dataSource}
      loading={loadingTable}
      rowKey={record => record.id}
      pagination={{
        total: quantity,
        pageSize: limit,
        onChange: async (current) => {
          setLoadingTable(true)
          await changePageHandler(current, limit)
          setLoadingTable(false)
        },
        showTotal: (total, range) => {
          return `${range[0]}-${range[1]} de ${total}`;
        },
        current: currentPage,
      }}
    />
  )
}

export default HandoverChecklistTable