import { useState } from "react"
import { defaulStateTable } from "../mocks/defaultStates"
import { providersTableColumnsPrev, providersTableFilters } from "../helpers/providersTableHelper"
import { useCustomSearch } from "../../../hooks/useCustomTable"
import { changeTableParams, getColumnsFilter } from "../../../helpers/customTableHelper"
import { reasonVisitsTableColumnsPrev, reasonVisitsTableFilters } from "../helpers/reasonVisitsTableHelper"
import { useAccessProvidersServices } from "./useAccessProvidersServices"
import { useAppSelector } from "../../../../hooks/store"

export const useAccessProvider = () => {

  const { handleReset, handleSearch } = useCustomSearch()
  const { getAllProvidersService, getAccessProviderDetailService, getReasonVisitsService, createReasonVisitService, createProviderService, deleteReasonVisitService, deleteProviderService } = useAccessProvidersServices()
  const { activeFeatures } = useAppSelector(state => state.roles)
  const [providersTable, setProvidersTable] = useState(structuredClone(defaulStateTable))
  const [reasonVisitsTable, setReasonVisitsTable] = useState(structuredClone(defaulStateTable))

  const [accessDetail, setAccessDetail] = useState({})
  const [loading, setLoading] = useState(false)
  const [itemVisible, setItemVisible] = useState({ providersModal: false, reasonVisitsModal: false, newAccessProviderModal: false, accessDetailDrawer: false })

  const getInitialData = async () => {
    const { data: reasonData, metadata: reasonMetadata } = await getReasonVisitsService({ currentPage: reasonVisitsTable.currentPage, previousData: reasonVisitsTable.dataTable, limit: 10, skip: 0, actualLimit: 10, setLoading })
    const { data, metadata } = await getAllProvidersService({ currentPage: providersTable.currentPage, previousData: providersTable.dataTable, limit: 10, skip: 0, actualLimit: 10, setLoading })
    setProvidersTable({
      ...structuredClone(defaulStateTable),
      dataTable: data.dataSource,
      columns: data.columns,
      metadata,
    })
    setReasonVisitsTable({
      ...structuredClone(defaulStateTable),
      dataTable: reasonData.dataSource,
      columns: reasonData.columns,
      metadata: reasonMetadata,
    })
    setLoading(false)
  }
  const providersServicesParams = { previousData: providersTable.dataTable, limit: providersTable.metadata.limit, actualLimit: providersTable.metadata.limit, currentPage: providersTable.currentPage, skip: 0 }
  const reasonVisitsServicesParams = { previousData: reasonVisitsTable.dataTable, limit: reasonVisitsTable.metadata.limit, actualLimit: reasonVisitsTable.metadata.limit, currentPage: reasonVisitsTable.currentPage, skip: 0 }

  //Dependiendo de la tabla en que se esté ejecutando la función, sea la de proveedores o la de motivos de visita, se establecerá el estado, y los servicios a utilizar ya que la estructura de datos es prácticamente la misma
  const currentStateService = ({ name }) => {
    const state = name === 'providersTable' ? providersTable : reasonVisitsTable
    const setState = name === 'providersTable' ? setProvidersTable : setReasonVisitsTable
    const getService = name === 'providersTable' ? getAllProvidersService : getReasonVisitsService
    const createService = name === 'providersTable' ? createProviderService : createReasonVisitService
    const deleteService = name === 'providersTable' ? deleteProviderService : deleteReasonVisitService
    const serviceParams = name === 'providersTable' ? providersServicesParams : reasonVisitsServicesParams
    return { state, serviceParams, setState, createService, deleteService, getService }
  }

  const handleOpenCloseModal = async ({ name, visible, data }) => {
    //Si se ha seleccionado un evento del calendario
    if (data && !data.edit) {
      const event_appointment_id = data.event_appointment_id
      const res = await getAccessProviderDetailService({ event_appointment_id, setLoading })
      setAccessDetail(res)
      setLoading(false)
    }
    //Si se quiere actualizar un evento, el drawer se debe cerrar y el modal de nuevo acceso se abre
    if (data && data.edit) {
      setAccessDetail(prev => ({ ...prev, edit: true }))
      return setItemVisible(prev => ({
        ...prev,
        accessDetailDrawer: false,
        [name]: visible
      }))
    }
    //Si se abre el modal de nuevo acceso desde el botón del custom navigate
    if (name === 'newAccessProviderModal' && visible) {
      setAccessDetail({})
    }
    setItemVisible(prev => ({ ...prev, [name]: visible }))
  }


  const handleDelete = async ({ id, name }) => {
    //Eliminar el elemento de la tabla y vuelve a recuperar los nuevos datos una vez se haya eliminado
    const { deleteService, getService, setState, state } = currentStateService({ name })
    await deleteService({ id, setLoading })
    const { data, metadata } = await getService({ currentPage: state.currentPage, previousData: state.dataTable, limit: 10, skip: 0, actualLimit: 10, setLoading })
    setState({
      ...structuredClone(defaulStateTable),
      dataTable: data.dataSource,
      metadata,
    })
    setLoading(false)
  }

  const handleChangeTable = async ({ pagination, filters, sorter, tableName }) => {
    //Se van a aplicar los filtros de búsqueda en la tabla que se esté llamando esta función para buscar por proveedor o motivo de visita
    const { getService, setState, state, serviceParams } = currentStateService({ name: tableName })
    const tableParams = changeTableParams({ serviceParams, pagination, filters, sorter, state, setState })
    if (!tableParams) return
    const { data, metadata } = await getService({ ...tableParams.finalParams, setLoading })
    setState(prev => ({
      ...prev,
      dataTable: data.dataSource,
      metadata,
      currentParams: tableParams.newParams,
      currentFilters: tableParams.actualFilters,
      lastSkip: tableParams.finalParams.skip
    }))
    setLoading(false)
  }

  const handlePagination = async ({ page, last, tableName }) => {
    if (!page) return
    //El propósito será cambiar de página en la tabla correspondiente, ya sea para avanzar o retroceder tomando en cuenta el lastSkip, si es menor al nuevo skip no se llamará al servicio
    const { getService, setState, state, serviceParams } = currentStateService({ name: tableName })

    const pageValue = page
    const newSkip = pageValue * state.metadata.limit - state.metadata.limit
    const { data, metadata } = await getService({ ...serviceParams, ...state.currentParams, skip: newSkip, setLoading })
    setState(prev => ({
      ...prev,
      dataTable: data.dataSource,
      metadata,
      lastSkip: newSkip
    }))
    setLoading(false)
  }

  const onSubmit = async ({ values, form, formName }) => {
    //Añadirá un nuevo elemento ya sea en proveedores o motivos de visita y luego se va a llamar al servicio para obtener los nuevos datos
    const { createService, getService, setState, state } = currentStateService({ name: formName })
    await createService({ formData: values, setLoading })
    const { data, metadata } = await getService({ currentPage: state.currentPage, previousData: state.dataTable, limit: 10, skip: 0, actualLimit: 10, setLoading })
    setState({
      ...structuredClone(defaulStateTable),
      dataTable: data.dataSource,
      metadata,
    })
    setLoading(false)
    form.resetFields()
  }

  const providerColumns = providersTableColumnsPrev({ handleDelete, activeFeatures, columns: providersTable.columns }).map(column => ({
    ...column,
    ...getColumnsFilter(column.dataIndex, providersTableFilters({ currentFilters: providersTable.currentFilters, handleSearch, handleReset }))
  }))

  const reasonVisitsColumns = reasonVisitsTableColumnsPrev({ handleDelete, activeFeatures, columns: reasonVisitsTable.columns }).map(column => ({
    ...column,
    ...getColumnsFilter(column.dataIndex, reasonVisitsTableFilters({ currentFilters: reasonVisitsTable.currentFilters, handleSearch, handleReset }))
  }))

  return {
    providersTable, providerColumns, reasonVisitsColumns, reasonVisitsTable, itemVisible, loading, accessDetail,
    handleOpenCloseModal, handleChangeTable, handlePagination, onSubmit, setProvidersTable, setReasonVisitsTable, setLoading, getAllProvidersService, getInitialData, setAccessDetail, getReasonVisitsService
  }
}

export default useAccessProvider