import useCurrentLocation from "../../../../hooks/useCurrentLocation"
import { NewMaintenanceReport } from './components/NewMaintenanceReport/NewMaintenanceReport'
import { DetailMaintenanceReport } from './components/DetailMaintenanceReport/DetailMaintenanceReport'
import { HistoryMaintenance } from "./components/HistoryMaintenance/HistoryMaintenance"

export const HistoryMaintenancePage = () => {
  const { param } = useCurrentLocation()

  const pages = {
    new: <NewMaintenanceReport />,
    report: <DetailMaintenanceReport />,
    'edit-report': <NewMaintenanceReport />
  }

  return (
    param.historyAction ? pages[param.historyAction] : <HistoryMaintenance />
  )
}

export default HistoryMaintenancePage