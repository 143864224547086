import axios from "axios"

const getAllZonesAndClusterByCompanyId = async (companyId, idToken) => {
  try {
    const response = await axios.get(`https://domo.topiaapp.com/api//place/tree/zones-and-clusters?company_id=${companyId}`,
      { headers: { Authorization: `Bearer ${idToken}` },
        timeout: 10000
      },
    )

    return {
      success: true,
      ...response
    }
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        ...error.response,
      }
    }
    if (error.request) {
      return {
        success: false,
        status: error.code,
        data: {
          errors: [{ msg: error.message }],
        },
      }
    }
  }
}

export default getAllZonesAndClusterByCompanyId