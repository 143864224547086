import {
  setType,
  setTickets,
  setLoading,
  setCalender,
  setActiveKey,
  clearCalendar,
  setLoadingTable,
  setTicketsStatus,
  setActivityEmployee,
} from "./ticketsSlice"

import {
  getType,
  getStatusTicket,
  getTicketByDate,
  getTicketsTable,
  createTicketAdmin,
  getEmployeeActivity,
} from "../../topia/pages/Tickets/Services"

import { verifyResponse } from './../../topia/helpers/verifyResponse'

export const StatusTicketsThunk = (role = "tenant") => async (dispatch, getState) => {
  dispatch(setLoading(true))

  const { currentRol } = getState().auth

  const response = await getStatusTicket(role, currentRol)

  if (response.success) {
    dispatch(setTicketsStatus(response.data.data))
    dispatch(setActiveKey(response.data.data?.[0]?.code))

    dispatch(setLoading(false))

    return response
  }
  
  dispatch(setLoading(false))
  
  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const getTicketsThunk = ({
  statusId,
  role = "tenant",
  skip = 0,
  limit = 10,
  findValue,
  assignedTickets
}) => async (dispatch, getState) => {
  dispatch(setLoadingTable(true))

  const { currentRol, idToken } = getState().auth

  const response = await getTicketsTable({
    statusId: statusId,
    limit,
    skip,
    role,
    findValue: findValue,
    companyId: currentRol,
    idToken,
    assignedTickets
  })
  
  if (response.success) {
    dispatch(setTickets(response.data))

    dispatch(setLoadingTable(false))

    return response
  }

  dispatch(setLoadingTable(false))

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

// endpoint para crear un ticket administrador
export const createATicketsAdminThunk = (formData) => async (dispatch) => {
  const response = await createTicketAdmin(formData)

  if (response.success) {
    dispatch(setTickets(response.data))
  }
  
  return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Ticket creado con éxito' })
}

export const ticketOfCalenderThunk = ({
  timeBegin,
  timeEnd,
  employeeId = "",
  typeId,
}) => async (dispatch, getState) => {
  const { currentRol } = getState().auth

  dispatch(setLoading(true))
  const { data, status, success } = await getTicketByDate(
    timeBegin,
    timeEnd,
    employeeId,
    typeId,
    currentRol
  )
  verifyResponse({ dataResponse: data, statusResponse: status})

  if (success) dispatch(setCalender(data))

  dispatch(setLoading(false))

  return { success, status }
}

export const typeTicketsThunk = () => async (dispatch) => {
  const { data, status, success } = await getType()

  verifyResponse({ dataResponse: data, statusResponse: status})

  if (success) dispatch(setType(data));
  
  return { success, status };
};

export const employeeActivityThunk = (id) => async (dispatch) => {
  const { data, status, success } = await getEmployeeActivity(id)

  verifyResponse({ dataResponse: data, statusResponse: status})
  
  if (success) dispatch(setActivityEmployee(data));
  
  return { success, status };
};

export const clearCalendarThunk = () => async (dispatch) => {
  return dispatch(clearCalendar())
}