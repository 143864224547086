import { Col, Row } from 'antd'
import UsersTable from './UsersTable'
import CustomTabs from '../../../../../UI/CustomTabs/CustomTabs'

const UsersDataTabs = ({ isUploadLoading, tableData }) => {
  const tabItems = [{ label: 'USUARIOS', children: <UsersTable isUploadLoading={isUploadLoading} tableData={tableData} /> }]

  return (
    <Row>
      <Col span={24}>
        <CustomTabs className='custom-tabs-variant-1' items={tabItems} />
      </Col>
    </Row>
  )
}

export default UsersDataTabs