import { createContext, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSurveys, setActiveSurveys, setSurveys, setLoadingSurveys, clearSurveysData, getActiveSurveys } from '../../../../store/surveys'

const SurveyContext = createContext({
    surveys: null,
    modalVisible: false,
    loading: true,
    dispatch: () => { },
    getSurveys: () => { },
    setActiveSurveys: () => { },
    setSurveys: () => { },
    setOpcionesGrafica: () => { },
    setLoadingSurveys: ({status}) => {},
    setModalVisible: () => { },
    clearSurveysData: () => {},
    getActiveSurveys: () => {},
})

export const SurveyContextProvider = ({ children }) => {
    const { surveys, loading } = useSelector((state) => state?.surveys)
    const dispatch = useDispatch()
    const [modalVisible, setModalVisible] = useState(false)

    const values = {
        surveys,
        modalVisible,
        loading,
        setActiveSurveys,
        dispatch,
        getSurveys,
        clearSurveysData,
        setLoadingSurveys,
        setModalVisible,
        setSurveys,
        getActiveSurveys
    }

    return (
        <SurveyContext.Provider value={values}>
            {children}
        </SurveyContext.Provider>
    )
}

export const useSurveyContext = () => useContext(SurveyContext)