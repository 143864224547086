import axios from "axios"
import { API_URL } from "../../../../config"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const getPlaceAvailabilityOption = async (idToken) => {
  try {
    const response = await axios.get(`${API_URL}place/owner/availability/options`,
      { headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000 }
    )

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getPlaceAvailabilityOption
