import { getAccessHelper, getScheduleVisitorsHelper,getScheduleVisitorsHelperDay} from "../../helpers/accessHelper"
import { getReferenceData, getResidentialsById, getUserDataProfileById, serializeUserData } from "../../helpers/globalHelper"
import { setResidentsData, setScheduledVisitors, setVisitorsData,setLoadResidente } from "./accessSlice"
import moment from 'moment'

export const getAccess = (fechaInicio, fechaFinal) => async( dispatch, getState ) => {
    dispatch(setLoadResidente())

    const { committee } = getState().auth
    const requestRaw = await getAccessHelper(committee.residential_id,fechaInicio,fechaFinal)

    let requestData = requestRaw.map( async(item, index) => {
        const residentials = await getResidentialsById(item.residential_id);
        var profile = {}
        if(item.profile_id !== undefined){
            const profileData = await getReferenceData(`/profile/${item.profile_id}`);
            profile = await serializeUserData(profileData);
        }
        
        let records =[]
        let house = {};

        if (item.house_id) {
            var nomencaturaSplit = item.house_id.path.split('/')
            var nomenclatura = nomencaturaSplit[0]+"/"+nomencaturaSplit[1]+"/"+nomencaturaSplit[2]+"/"+nomencaturaSplit[3]
            //var numeroCasa = await getReferenceData(item.house_id.path);
            var numeroZona =  await getReferenceData(nomenclatura);
            house = await getReferenceData(item.house_id.path);
            if(house)
            {
                house = numeroZona
            }
        }

        if(house){
            if(house.created_at){
                house.created_at = moment(house.created_at.toDate()).format("Y-MM-DD");
            }
        }
        
        const dataKeys = Object.keys(item);
        let dataFiltered = {}
        
        dataKeys.map( key => {
            if(key === "house_id"){
                if(item[key]){
                    dataFiltered[key] = item[key].id;
                }else{
                    dataFiltered[key] = null
                }
            }else{
                dataFiltered[key] = item[key];
            }                
        });
        

        return {
            key: index,
            ...dataFiltered,
            profile_data: profile,
            house_data: house,
            records: records,
            residential_data: residentials
        }

    } );

    
    Promise.all(requestData).then( data => {
        let residentList = [];
        let visitorList = [];

        data.map((item, index) => {
            
            let itemData = { ...item }
            itemData.key = index;
            //itemData.time_action = itemData.time_arrive ? itemData.time_arrive : itemData.time_leave;
            itemData.time_action = itemData.created_at
            
            itemData.phone = item.profile_data ? item.profile_data?.phone : "";
            itemData.visit_type = "";
            itemData.home_residence = item?.house_data?.zona?.name+" No."+item?.house_data?.name;
            switch(itemData.type){
                case 1:
                    itemData.visit_type = "Residente";
                    break;
                case 2:
                    itemData.visit_type = "Delivery";
                    break;
                case 3:
                    itemData.visit_type = "Visita programada";
                    break;
                case 4:
                    itemData.visit_type = "Proveedor o Empleado";
                    break;
                case 5:
                    itemData.visit_type = "Oficina";
                    break;
                case 6:
                    itemData.visit_type = "Familiar y amigos";
                    break;
                case 7:
                    itemData.visit_type = "Documento";
                    break;
                default:
                    itemData.visit_type = "N/A";
                    break;
            }

            if(item.type === 1){
                residentList.push(itemData);
            }else{
                visitorList.push(itemData);
            }
        });


        dispatch(setResidentsData(residentList));
        dispatch(setVisitorsData(visitorList));
    })
}

export const getScheduleVisitors = (fechaInicio,fechaFinal) => {
    return async( dispatch, getState ) => {
        
        dispatch(setLoadResidente());

        const { committee } = getState().auth
        const requestRaw = await getScheduleVisitorsHelper(committee.residential_id,fechaInicio,fechaFinal);
        const requestRawWeek = await getScheduleVisitorsHelperDay(committee.residential_id,fechaInicio,fechaFinal);

        let requestData = requestRaw.map( async(item, index) => {
    
            // const residentials = await getResidentialsById(item.residential_id);
            const profile = await getUserDataProfileById(item.user_id);
            let records = []
            //let records = await getAccessHistorialByProfileId(item.profile_id);
            // const profile = await serializeUserData(user_id);
    

            if(item.house !== undefined){
                var nomencaturaSplit = item.house.split('/')
                var nomenclatura = nomencaturaSplit[0]+"/"+nomencaturaSplit[1]+"/"+nomencaturaSplit[2]+"/"+nomencaturaSplit[3]
               
                var numeroCasa = await getReferenceData(item.house);
                var numeroZona =  await getReferenceData(nomenclatura);

                item.houseShow = numeroZona?.name + " No." + numeroCasa?.name
            }
    
            return {
                ...item,
                key: index,
                records: records,
                profile_data: profile,
            }
    
        } );

        let requestDataWeek = requestRawWeek.map( async(item, index) => {
    
            // const residentials = await getResidentialsById(item.residential_id);
            const profile = await getUserDataProfileById(item.user_id);
            let records = []
            //let records = await getAccessHistorialByProfileId(item.profile_id);
            // const profile = await serializeUserData(user_id);
    

            if(item.house !== undefined){
                var nomencaturaSplit = item.house.split('/')
                var nomenclatura = nomencaturaSplit[0]+"/"+nomencaturaSplit[1]+"/"+nomencaturaSplit[2]+"/"+nomencaturaSplit[3]
               
                var numeroCasa = await getReferenceData(item.house);
                var numeroZona =  await getReferenceData(nomenclatura);

                item.houseShow = numeroZona?.name + " No." + numeroCasa?.name
            }
    
            return {
                ...item,
                key: index,
                records: records,
                profile_data: profile,
            }
    
        } );
    
        const requestDataMain = requestData.concat(requestDataWeek);

        Promise.all(requestDataMain).then( data => {
            // data.map((item, index)=>{
                
            //     let itemData = { ...item }
            //     itemData.key = index;
            //     itemData.time_action = itemData.time_arrive ? itemData.time_arrive : itemData.time_leave;
            //     itemData.phone = item.profile_data ? item.profile_data?.phone : "";
            //     itemData.visit_type = "";
            //     itemData.home_residence = item.house_data?.name;
            // });
    
    
            dispatch(setScheduledVisitors(data));
        })
    };
}