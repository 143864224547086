import axios from "axios"
import { API_URL } from "../../../../config"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const getPlaceOwnersByCompanyId = async({
  companyId,
  findValue,
  ownerProfileId,
  sortBy,
  sortOrder,
  skip,
  limit,
  idToken,
  availableCode,
  ownerTypeId
}) => {
  const isFindValue = findValue ? `&find_value=${findValue}` : ''
  const isOwner = ownerProfileId ? `&owner_profile_id=${ownerProfileId}` : ''
  const isSortBy = sortBy ? `&sort_by=${sortBy}` : ''
  const isSortOrder = sortOrder ? `&sort_order=${sortOrder}` : ''
  const isSkip = skip ? `&skip=${skip}` : ''
  const isLimit = limit ? `&limit=${limit}` : ''
  const isAvailable = availableCode ? `&place_availability_code=${availableCode}` : ''
  const isOwnerType = ownerTypeId ? `&owner_type_id=${ownerTypeId}` : ''

  try {
    const response = await axios.get(`${API_URL}place/owner?company_id=${companyId}${isFindValue}${isOwner}${isSortBy}${isSortOrder}${isSkip}${isLimit}${isAvailable}${isOwnerType}`,
      { headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000 }
    )
    
    return verifyBodyResponse(response, true)
} catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getPlaceOwnersByCompanyId