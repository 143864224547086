import { useState, useEffect } from "react"
import { capitalizeFirstLetterString } from "../helpers/capitalizeFirstLetterString"
import { useAppSelector } from "../../../../hooks/store";

export const useSidebar = () => {
  const theme = useAppSelector((state) => state.sidebarTheme)
  const [template, setTemplate] = useState(null)
  const [hoverStyle, setHoverStyle] = useState({
    itemName: "",
    backgroundColor: "",
    image: "",
    color: "",
  })

  // Funcion encargada de importar dinamicamente el current module
  const dynamicSidebarTheme = async () => {
    // Se espera de forma asincrona la importacion antes de que se renderice
    const module = await import('../components/DefaultSidebar/DefaultSidebar')

    // Se guarda la referencia del current component
    const SidebarTheme = module['DefaultSidebar']

    // Se almacena en una variable para actualizar el estado con esta misma asi de renderice en el SideBar
    const defaultTemplate = <SidebarTheme dynamicData={theme} />
    // Se actualiza el estado con el current template 
    setTemplate(defaultTemplate)
  }

  const getStyle = ({ item, isActive, style }) => {
    const activeStyle = isActive ? item[`active${capitalizeFirstLetterString({ string: style })}`] : item[style];

    if (activeStyle !== hoverStyle[style] && hoverStyle[style] !== "" && !isActive) {
      return hoverStyle[style];
    }
    return activeStyle;
  };

  const handleSetStyle = ({ reset = false, item }) => {
    if (reset) {
      setHoverStyle({
        itemName: item.name,
        backgroundColor: item.backgroundColor,
        image: item.image,
        color: item.color,
      });
      return;
    }

    setHoverStyle({
      itemName: item.name,
      backgroundColor: item.activeBackgroundColor,
      image: item.activeImage,
      color: item.activeColor,
    });
  };

  useEffect(() => {
    if (template === null) {
      dynamicSidebarTheme()
    }
  }, [])

  return {
    template,
    setHoverStyle,
    getStyle,
    handleSetStyle,
  };
};
