import { Button } from "antd"
import { CustomNavigate } from "../../../UI/CustomNavigate"

const HeaderConversation = ({
  getConversations,
}) => {
  const handleOnClick = async () => {
    await getConversations()
  }

  return (
    <header>
      <CustomNavigate title="MENSAJERÍA">
        <Button
          type="text"
          htmlType="button"
          onClick={handleOnClick}
          style={{ width: 198, backgroundColor: '#404040', height: 40, borderRadius: 8 }}
        >
          <span style={{ fontWeight: 400, fontSize: 16, color: '#FFFFFF' }}>Actualizar</span>
        </Button>
      </CustomNavigate>
    </header>
  )
}

export default HeaderConversation