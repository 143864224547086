import { axiosCreate } from "../../../../config/index"
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse"

const getCheckListItems = async ({ company_id, idToken, checklist_id, checklist_category_id, skip = 0, limit = 10 }) => {
  try {
    const params = {
      checklist_category_id: checklist_category_id ? checklist_category_id : null,
      checklist_id,
      company_id,
      skip,
      limit
    }

    const response = await axiosCreate.get('checklist/item/active', {
      headers: { Authorization: `Bearer ${idToken}` }, params, timeout: 20000
    })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getCheckListItems
