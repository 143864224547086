import { bodySearch } from "../../../../../constants/constants"
import CustomTable from "../../../../../UI/CustomTable/CustomTable"
import '../../style.css'
import { getColumnsFilter, getColumnsWith } from './../../../../../helpers/customTableHelper'
import { CustomSpin } from "../../../../../UI/CustomSpin"

const UsersTable = ({ isUploadLoading, tableData }) => {
  const { columns, dataSource } = tableData

  if ((!columns || !dataSource) || isUploadLoading) return <CustomSpin height="300px" size="large" />

  const columnsFiltersObject = {
    'nomenclature': () => ({
      ...bodySearch,
      onFilter: ( value, record ) => record.nomenclature.toLowerCase().includes(value.toLowerCase())
    }),
    'name': () => ({
      ...bodySearch,
      onFilter: ( value, record ) => record.name.toLowerCase().includes(value.toLowerCase())
    }),
    'lastname': () => ({
      ...bodySearch,
      onFilter: ( value, record ) => record.lastname.toLowerCase().includes(value.toLowerCase())
    }),
    'space': () => ({
      ...bodySearch,
      onFilter: ( value, record ) => record.space.toString().includes(value.toString())
    }),
    'default': () => ({}),
  }

  const columnsWithsObj = {
    'name': () => ({ width: '170px' }),
    'lastname': () => ({ width: '170px' }),
    'nomenclature': () => ({ width: '160px' }),
    'project': () => ({ width: '160px' }),
    'phonetitular': () => ({ width: '150px' }),
    'phonedependent': () => ({ width: '150px' }),
    'type': () => ({ width: '95px' }),
    'space': () => ({ width: '90px' }),
    'default': () => ({})
  }

  const newColumns = columns.map((column) => ({ 
    ...column,
    ...getColumnsWith(column.dataIndex, columnsWithsObj),
    ...getColumnsFilter(column.dataIndex, columnsFiltersObject)
   }))

  return (
    <CustomTable className="custom-new-table import-user-table" loading={isUploadLoading} rowKey={(record) => record.space}  pagination={{ pageSize: 10 }} columns={newColumns} dataSource={dataSource} />
  )
}

export default UsersTable
