import axios from "axios"

const getHierarchyAdminSpace = async (URL_DEV,companyId, idToken) => {
  try {
    const response = await axios.get(`${URL_DEV}/place/tree/zones-and-clusters?company_id=${companyId}`,
      { headers: { Authorization: `Bearer ${idToken}` } })
    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getHierarchyAdminSpace