import React from "react"
import CustomModal from "./CustomModal"
import { Row } from "antd"

const CustomPreviewImageModal = ({
  onCancel,
  isOpenModal,
  previewImage,
  width,
  minHeight,
  maxHeight,
  widthModal,
  maxWidth
}) => (
  <CustomModal width={widthModal} onCancel={onCancel} isOpenModal={isOpenModal} maxWidth={maxWidth}>
    <Row justify="center">
      <img
        alt={previewImage}
        style={{
          width: width,
          minHeight: minHeight,
          maxHeight: maxHeight,
        }}
        src={previewImage}
      />
    </Row>
  </CustomModal>
)

export default CustomPreviewImageModal
