import { Form, Input, Button } from "antd";
import { useState } from "react";

export const CustomForm = ({
  nameForm = 'loginForm',
  className,
  input,
  button,
  loading,
  onSubmit,
  config,
}) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  return (
    <Form
      autoComplete={"off"}
      className={className}
      name={nameForm}
      disabled={loading}
      layout="vertical"
      onFinish={(formData) => onSubmit({ formData, setLoading: setLoadingSubmit })}
    >
      <Form.Item
        name="email"
        {...config}
        label={
          input?.email?.label && (
            <span style={input?.shared?.label?.style}>
              {input?.email?.label?.text}
            </span>
          )
        }
      >
        <Input
          style={input?.shared?.input?.style}
          size="large"
          placeholder={input?.email?.input?.placeholder}
          prefix={input?.email?.input?.prefix && input?.email?.input?.prefix}
        />
      </Form.Item>

      <Form.Item
        name="password"
        {...config}
        label={
          input?.password?.label && (
            <span style={input?.shared?.label?.style}>
              {input?.password?.label?.text}
            </span>
          )
        }
      >
        <Input.Password
          style={input?.shared?.input?.style}
          type="password"
          size="large"
          placeholder={input?.password?.input?.placeholder}
          prefix={input?.password?.input?.prefix && input?.password?.input?.prefix}
        />
      </Form.Item>
      <Button
        style={button?.style}
        htmlType="submit"
        loading={loadingSubmit}
        disabled={loadingSubmit}
      >
        {button?.text}
      </Button>
    </Form>
  );
};
