export const filterUserPlaces = (data, userData) => {
  const places = []
  // Iteramos sobre las propiedades del objeto data
  for (const [key, value] of Object.entries(data)) {
    // Verificamos si la clave es una propiedad de espacio (empieza con 'space_')
    if (key.startsWith('space_')) {
        // Extraemos el número de índice del espacio
        const spaceIndex = key.split('_')[1];
        
        const place = {
            place_id: value?.value || value,
            dependencies: []
        };

        // Verificamos si existen índices de parking y warehouse asociadas a este espacio
        const parkingKey = `parking_${spaceIndex}`
        const wareHouseKey = `ware_house_${spaceIndex}`
        const typeUserKey = `type_user_new_space_${spaceIndex}`
        
        // Extraemos siempre el value que nos devuelve el backend y los nuevos espacios agregados
        const idParking = data[parkingKey]?.value === 0
            ? 0
            : (data[parkingKey]?.value || data[parkingKey])


        if (idParking) {
            place.dependencies.push({ place_id: idParking, type: 'parking' });
        }

        const idWarehouse = data[wareHouseKey]?.value === 0
            ? 0
            : (data[wareHouseKey]?.value || data[wareHouseKey])

        if (idWarehouse) {
            place.dependencies.push({ place_id: idWarehouse, type: 'warehouse' })
        }

        // Agregamos place_owner_type_id, y validamos si se esta creando o editando un usuario
        place.place_owner_type_id = userData?.profile?.profile_id
          ? (data[typeUserKey] || userData?.places[0]?.place_owner_type_id)
          : data['type_user'] || userData?.places[0]?.place_owner_type_id

        places.push(place);
    }
  }

  return places
}

export const replacePlacesHelper = async (filteredPlaces, userData) => {
  const placesWithProps = [];
  const userPlaces = userData?.places || [];

  for (let i = 0; i < filteredPlaces.length; i++) {
    const filteredPlace = filteredPlaces[i];
    const userDataPlace = userPlaces[i];

    // No entra aqui si y solo si se agrega un espacio nuevo a un usuario porque userDataPlace es undefined
    if (userDataPlace) {
        const placeWithProps = {
            ...filteredPlace,
            place_owner_id: userDataPlace.place_owner_id,
            place_owner_type_id: userDataPlace.place_owner_type_id
        }

        const verifyTypeDependencyCode = (typeCode) => {
            switch (typeCode) {
            case 'parking':
                const parkingPlaceId = filteredPlace?.dependencies?.find(place => place?.type === typeCode)

                return parkingPlaceId?.place_id
            case 'warehouse':
                const wareHousePlaceId = filteredPlace?.dependencies?.find(place => place?.type === typeCode)

                return wareHousePlaceId?.place_id
            default:
                return ({})
            }
        }

        if (userDataPlace.dependencies && userDataPlace.dependencies.length > 0) {
            const modifiedDependencies = userDataPlace?.dependencies?.map((dependency) => ({
                place_dependency_id: dependency.place_dependency_id,
                place_id: verifyTypeDependencyCode(dependency.type)
            }))

            placeWithProps.dependencies = modifiedDependencies
        }

        placesWithProps.push(placeWithProps)
    } else {
        const newFilteredPlace = { ...filteredPlace, dependencies: filteredPlace?.dependencies?.map((dependency) => ({ place_id:  dependency.place_id })) }
        placesWithProps.push(newFilteredPlace)
    }
  }

  return placesWithProps
}
