
export const HANDOVER_CHECKLIST_CODES = {
    HANDOVER_CHECKLIST_MODULE: 'FEAT-289',
    HANDOVER_CHECKLIST_UPDATE_TASK: 'FEAT-126',
    HANDOVER_CHECKLIST_UPDATE_HANDOVER: 'FEAT-127',
    HANDOVER_CHECKLIST_NEW_HANDOVER: 'FEAT-130',
    HANDOVER_CHECKLIST_MANAGMENT_HANDOVER: 'FEAT-287',
    HANDOVER_CHECKLIST_MANAGEMENT_CATEGORIES: 'FEAT-264',
    HANDOVER_CHECKLIST_NEW_CATEGORY: 'FEAT-266',
    HANDOVER_CHECKLIST_DELETE_CATEGORY: 'FEAT-267',
    HANDOVER_CHECKLIST_UPDATE_CATEGORY: 'FEAT-268',
    HANDOVER_CHECKLIST_NEW_TASK: 'FEAT-291',
    HANDOVER_CHECKLIST_DELETE_TASK: 'FEAT-294',
    HANDOVER_CHECKLIST_DETAILS_HANDOVER: 'FEAT-296',
    HANDOVER_CHECKLIST_CREATE_TICKET: 'FEAT-310',
    HANDOVER_CHECKLIST_CREATE_TYPE_CHECKLIST: 'FEAT-478',
    HANDOVER_CHECKLIST_UPDATE_TYPE_CHECKLIST: 'FEAT-479',
    HANDOVER_CHECKLIST_ADMIN_TYPE_CHECKLIST: 'FEAT-477',
    HANDOVER_CHECKLIST_DELETE_TYPE_CHECKLIST: 'FEAT-480'
}

export const HANDOVER_CHECKLIST_CODES_NAMES = {
    HANDOVER_CHECKLIST_MODULE: 'HANDOVER_CHECKLIST_MODULE',
    HANDOVER_CHECKLIST_MANAGMENT_HANDOVER: 'HANDOVER_CHECKLIST_MANAGMENT_HANDOVER',
    HANDOVER_CHECKLIST_UPDATE_TASK: 'HANDOVER_CHECKLIST_UPDATE_TASK',
    HANDOVER_CHECKLIST_UPDATE_HANDOVER: 'HANDOVER_CHECKLIST_UPDATE_HANDOVER',
    HANDOVER_CHECKLIST_NEW_HANDOVER: 'HANDOVER_CHECKLIST_NEW_HANDOVER',
    HANDOVER_CHECKLIST_MANAGEMENT_CATEGORIES: 'HANDOVER_CHECKLIST_MANAGEMENT_CATEGORIES',
    HANDOVER_CHECKLIST_NEW_CATEGORY: 'HANDOVER_CHECKLIST_NEW_CATEGORY',
    HANDOVER_CHECKLIST_DELETE_CATEGORY: 'HANDOVER_CHECKLIST_DELETE_CATEGORY',
    HANDOVER_CHECKLIST_UPDATE_CATEGORY: 'HANDOVER_CHECKLIST_UPDATE_CATEGORY',
    HANDOVER_CHECKLIST_NEW_TASK: 'HANDOVER_CHECKLIST_NEW_TASK',
    HANDOVER_CHECKLIST_DELETE_TASK: 'HANDOVER_CHECKLIST_DELETE_TASK',
    HANDOVER_CHECKLIST_DETAILS_HANDOVER: 'HANDOVER_CHECKLIST_DETAILS_HANDOVER',
    HANDOVER_CHECKLIST_CREATE_TICKET: 'HANDOVER_CHECKLIST_CREATE_TICKET',
    HANDOVER_CHECKLIST_CREATE_TYPE_CHECKLIST: 'HANDOVER_CHECKLIST_CREATE_TYPE_CHECKLIST',
    HANDOVER_CHECKLIST_UPDATE_TYPE_CHECKLIST: 'HANDOVER_CHECKLIST_UPDATE_TYPE_CHECKLIST',
    HANDOVER_CHECKLIST_ADMIN_TYPE_CHECKLIST: 'HANDOVER_CHECKLIST_ADMIN_TYPE_CHECKLIST',
    HANDOVER_CHECKLIST_DELETE_TYPE_CHECKLIST: 'HANDOVER_CHECKLIST_DELETE_TYPE_CHECKLIST'
}