//Permite hacer un filtro de datos de las caracteristicas de un modules, recorriendo cada item, donde si tiene un children se hace una funcion recursiva de tal forma que 
//Se compara si hay algun nombre o instancia que coincidan en los datos o si la longitud del children es mayor a cero se regresa un nuevo item y su respectivo children y se añade a un arreglo el cual se retorna luego de verificar todos los items
export const filterData = ({ data = [], searchItems = { type: '', name: '' } }) => {
    const result = []
    data.forEach(item => {
        let filteredChildren = [];
        if (item?.children) {
            filteredChildren = filterData({ data: item.children, searchItems });
        }

        const isNameMatch = searchItems.name ? item.name.toLowerCase().includes(searchItems.name.toLowerCase()) : true
        const isChannelMatch = searchItems.type ? item.chanel.toLowerCase().includes(searchItems.type.toLowerCase()) : true

        if ((isNameMatch && isChannelMatch) || filteredChildren.length) {
            const newItem = { ...item }
            if (filteredChildren.length) {
                newItem.children = filteredChildren
            }
            result.push(newItem)
        }
    })

    return result
}

//Permite recuperar en un arreglo todos los feature_id que hacen referencia a los selectedRowkeys para la tabla
export const collectFeatureIds = (data = []) => {
    let ids = [];

    data.forEach(item => {
        ids.push(item.feature_id);
        if (item.children) {
            ids = ids.concat(collectFeatureIds(item.children));
        }
    });

    return ids;
};

//Obtener las jerarquias de las keys de las features  donde dentro hay una función que retorna un map de un arreglo donde se extrae el id de la caracteristica y su childre
//en caso de tener elementos el children se guarda en una propiedad el resultado de la función recursiva de tal forma que se retorne un arreglo que contenga los id padres y cada uno de ellos tenga un children con los id hijos hasta n childrens
export const featuresIdHierarchy = (data = []) => {
    const hierachy = {}
    const transverse = (nodes = []) => {
        return nodes.map(node => {
            const { feature_id, children = [] } = node;
            const newNode = { feature_id }
            if (children.length) {
                newNode.children = transverse(children)
            }
            return newNode
        })
    }
    hierachy.data = transverse(data)
    return hierachy
}

//Validar los ids de los childrens, guardando inicialmente los feature id sin repeticion y luego con una funcion autxiliar recorren cada uno de los item de la jerarquia de ids
// donde si hay un children y tiene elementos se hace una función recursiva, donde luego se valida si en ese item, su feature id existe en el arreglo de id features que se pasa a la función
//si no existe se elimina del set creado, y luego, como es un reduce se concatena el valor anterior con el valor nuevo, dando un booleano, aunque esto no es necesario, ya que solo importa recorrer todos los elementos
//para finalmente retorna un arreglo del Set final que resulto luego de recorrer todos los items y sus childrens
export const validateHierarchyIds = (hierachy = [], featuresIds = []) => {
    const result = new Set(featuresIds);

    const transverse = (nodes = []) => {
        return nodes.reduce((allValid, node) => {
            let childrenValid = true;
            if (node.children && node.children.length) {
                childrenValid = transverse(node.children)
            }
            const isValid = featuresIds.includes(node.feature_id) && childrenValid
            if (!isValid) {
                result.delete(node.feature_id)
            }
            return allValid && isValid
        }, true)
    }
    transverse(hierachy)
    return Array.from(result)
}

//A paritr de dos arreglos se filtra que en el primer arreglo no exista el id de cada uno de los elementos del segundo arreglo, y se retorna un arreglo donde no se repita ningun id mediante un Set
export const excludeIds = (startArray = [], excludeArray = []) => {
    const array = startArray.filter(item => !excludeArray.includes(item));
    return Array.from(new Set([...array]))
}

//A paritr de dos arreglos se concatenan dos arreglos y se devuelven un arreglo de elementos únicos despues de concatenarlos
export const concatUniqueIds = (arr1, arr2) => {
    const combinedSet = new Set([...arr1, ...arr2]);
    return Array.from(combinedSet);
};
