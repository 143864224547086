import { Col, Row } from 'antd'
import { NavLink } from 'react-router-dom'
import './style.css'

const ConfiguracionInicialPage = () => (
  <div className="wrapper-page">
    <h2 className='title-page'>
    CONFIGURACIÓN INICIAL

    </h2>
    <Row justify="start" align="middle">
      <ul className='primary-card-list'>
        <NavLink to="/configuracion-inicial/usuarios-">
          <li className={"primary-card-card"}>
            <img className='iconOpcion' src="/assets/images/senal.png" alt="" />
            <div>NOMENCLATURA DE LA RESIDENCIAL</div>
              
          </li>  
        </NavLink>

        {/* <NavLink to="/configuracion-inicial/aprobacion-usuarios">
          <li className={"primary-card-card"}>
              <img className='iconOpcion' src="/assets/images/pulgar-arriba.png" alt="" />
              <div>APROBACIÓN DE USUARIOS</div>
          </li>  
        </NavLink> */}
      </ul>
    </Row>
  </div>   
)

export default ConfiguracionInicialPage
