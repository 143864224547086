import { Col, Row } from "antd"
// import keyIcon from '../../../../assets/icons/Generals/key-icon.svg'
import { useEffect, useState } from "react"
import useWidgets from "../../hooks/useWidgets"
import { CustomSpin } from "../../../../UI/CustomSpin"
import useRedirectTo from "../../../../hooks/useRedirectTo"
import { verifyStatusColor } from "../../../../helpers/verifyTypeColors"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store"
import { setCurrentActiveTab } from "../../../../../store/bookings/bookingsSlice"

const BookingRequests = () => {
  const dispatch = useAppDispatch()

  const [loadingBooking, setLoadingBooking] = useState(false)

  const { handleRedirectTo } = useRedirectTo()
  const { getBookingStatus } = useWidgets()
  const { bookingStatus } = useAppSelector(state => state.home)

  useEffect(() => {
    getBookings()
  } ,[])

  const getBookings = async () => {
    setLoadingBooking(true)
    await getBookingStatus()
    setLoadingBooking(false)
  }

  // Se le agrega la clase especifica para cada elemento Row
  const newBookingStatus = bookingStatus?.map((bookingData) => ({ ...bookingData, className: verifyStatusColor(bookingData.reservation_code, objBackgroundHoverColors) }))

  if (loadingBooking) return <CustomSpin section />

  return (
    <Row style={{ marginBottom: 25 }}>
      <Col span={24} style={{ backgroundColor: "#FFFFFF", padding: 25, borderRadius: 16 }}>
        <h3 style={{ fontWeight: 600, fontSize: 20 }}>
          SOLICITUDES DE RESERVA
        </h3>
        
        {/* Listado de amenidades con los tres status */}
        <Row gutter={[0, 12]}>
          {newBookingStatus?.map((maintenance) => (
            <BookingType key={maintenance.reservation_code} dispatch={dispatch} maintenanceData={maintenance} handleRedirectTo={handleRedirectTo} />
          ))}
        </Row>
      </Col>
    </Row>
  )
}

const BookingType = ({ dispatch, maintenanceData, handleRedirectTo }) => {
  const handleOnClickBookings = () => {
    dispatch(setCurrentActiveTab(maintenanceData.reservation_code))
    handleRedirectTo('/bookings')
  }

  return (
    <Col
      span={24}
      style={{
        cursor: 'pointer',
        borderRadius: 16, 
        color: verifyStatusColor(maintenanceData.reservation_code, objTextColors),
        backgroundColor: verifyStatusColor(maintenanceData.reservation_code, objBackgroundColors),
      }} 
      className={maintenanceData.className}
      onClick={handleOnClickBookings}
    >
      <Row style={{ padding: '4px 16px' }}>
        <Col span={22}>
          <span style={{ backgroundColor: verifyStatusColor(maintenanceData.reservation_code, objCircleColors), marginRight: 5 }} className="circle"></span>

          <span style={{ fontWeight: 500 }}>
            {maintenanceData.name}
          </span>
        </Col>

        <Col span={2}>
          <Row justify="end">
            <span style={{ fontWeight:500 }}>{maintenanceData.quantity}</span>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}

const objBackgroundHoverColors = {
  'awaitingConfirmation': 'booking-hover-variant-1',
  'approved': 'booking-hover-variant-2',
  'canceled': 'booking-hover-variant-3',
  'default': ''
}

// Colores de background en table
const objBackgroundColors = {
  'awaitingConfirmation': '#FEEDBE',
  'approved': '#C2F2DF',
  'canceled': '#F2C2C2',
  'default': ''
}

// Colores de texto
const objTextColors = {
  'awaitingConfirmation': '#695212',
  'approved': '#10725A',
  'canceled': '#DD3737',
  'default': ''
}

// Colores de circulo
const objCircleColors = {
  'awaitingConfirmation': '#F6B500',
  'approved': '#10725A',
  'canceled': '#FF0A0A',
  'default': ''
}

export default BookingRequests
