import { Button, Col, Row } from 'antd';
import pinSvg from '../../../../assets/icons/pin.svg'
import { PlusOutlined } from "@ant-design/icons"
import HierarchyAdministrationTable from './HierarchyAdministrationTable';
import useOpenCloseModal from '../../../hooks/useOpenCloseModal';
import { useAppSelector } from '../../../../hooks/store';
import { getAllHerarchyPath } from '../../../helpers/customSelectHelper';
import CustomPopoverConfirm from '../../../UI/CustomPopoverConfirm';
import { MANAGEMENT_SPACES_CODES } from '../../../../constants/modules/managmentSpaces/managmentSpacesCodes';

const HierarchyAdministrationContainer = ({ activeFeatures }) => {
  const { form, isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { hierarchyAdminSpaces, currentParentId } = useAppSelector((state) => state?.hierarchyAdminSpace)

  // helper para especificar la ruta en la que se encuentra
  const path = getAllHerarchyPath(hierarchyAdminSpaces, currentParentId)

  return (
    <Row style={{ background: '#fff', borderRadius: 10, padding: '1rem 2rem', position: 'relative' }}>
      <Col span={24}>
        <Row style={{ background: '#F1F0F0', marginTop: '1.5rem', borderRadius: 5 }}>
          <img style={{ paddingLeft: '1rem', paddingBottom: '0.5rem' }} src={pinSvg} alt="" />
          <p style={{ padding: '0.5rem 0 0 1rem', color: '#232C4A', fontSize: '14px' }} >{`Root / ${path === null ? '' : path?.join(' / ')}`}</p>
        </Row>

        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_SPACES_CODES.MANAGEMENT_SPACES_NEW_SPACE)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nuevo Espacio'}`}>
          <Button
            disabled={!activeFeatures?.find(feature => feature.feature_code === MANAGEMENT_SPACES_CODES.MANAGEMENT_SPACES_NEW_SPACE)?.enabled}
            icon={<PlusOutlined style={{ color: 'white', fontSize: '2.5rem' }} />}
            shape='circle'
            onClick={() => {
              form.resetFields()
              handleOpenCloseModal()
            }}
            style={{ width: '56px', height: '56px', border: '1px solid #D6D6D6', background: 'var(--primary)', position: 'absolute', top: '6rem', right: '3rem', zIndex: 100 }}
          />
        </CustomPopoverConfirm>

        <HierarchyAdministrationTable
          activeFeatures={activeFeatures}
          form={form}
          path={path}
          isOpenModal={isOpenModal}
          handleOpenCloseModal={handleOpenCloseModal}
        />
      </Col>
    </Row>
  )
}

export default HierarchyAdministrationContainer;