import useUsers from "../hooks/useUsers"
import { useContext, useState } from "react"
import { Button, Col, Image, Row } from "antd"
import { CustomInput } from "../../../UI/CustomInput"
import { UsersContext } from "../context/UsersContext"
import { CustomSelect } from "../../../UI/CustomSelect"

// imagenes o archivos
import { SearchOutlined } from "@ant-design/icons"
import reloadIcon from '../../../../assets/icons/Generals/reload-icon.svg'
import { ReactComponent as ExcelIcon } from '../../../../assets/icons/excel.svg'

const FiltersUsersTable = () => {
  const [availableCode, setAvailableCode] = useState(null)
  const [ownerTypeId, setOwnerTypeId] = useState(null)

  const { getExcelReport, getUsersTableData } = useUsers()

  const { filters, setFilters, typesOwner, ownerPlaces, loading, loadingExcel } = useContext(UsersContext)

  const typesOwnerToSelect = typesOwner?.map((typeOwnerData) => ({ id: typeOwnerData?.owner_type_id, name: typeOwnerData?.name }))
  const ownerPlacesToSelect = ownerPlaces?.map((ownerData) => ({ id: ownerData?.place_availability_code, name: ownerData?.name }))

  const handleOnChangeFilter = async (value) => {
    if (value) {
      await getUsersTableData({ ...filters, findValue: value.target.value })

      setFilters(state => ({ ...state, findValue: value.target.value }))

      return
    }

    await getUsersTableData({ ...filters, findValue: '' })

    setFilters(state => ({ ...state, findValue: '' }))
  }

  const handleOnClickResetFilters = async () => {
    await getUsersTableData({ findValue: '' })

    setFilters({ findValue: '' })

    setAvailableCode(null)
    setOwnerTypeId(null)
  }

  const handleOnChangeTypes = async (value) => {
    if (value) {
      await getUsersTableData({ ...filters, ownerTypeId: value })

      setFilters(state => ({ ...state, ownerTypeId: value  }))

      setOwnerTypeId(value)

      return
    }

    setOwnerTypeId(null)

    await getUsersTableData({ ...filters, ownerTypeId: '' })

    setFilters(state => ({ ...state, ownerTypeId: ''  }))
  }

  const handleOnChangeAvailable = async (value) => {
    if (value) {
      await getUsersTableData({ ...filters, availableCode: value })

      setFilters(state => ({ ...state, availableCode: value  }))

      setAvailableCode(value)

      return
    }

    setAvailableCode(null)

    await getUsersTableData({ ...filters, availableCode: '' })

    setFilters(state => ({ ...state, availableCode: ''  }))
  }

  return <Row
    gutter={8}
    style={{ backgroundColor: '#FFFFFF', padding: '32px 40px 0', marginInline: 0.5, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
  >
    <Col span={5}>
      <CustomInput
        isComponentIcon
        suffixIcon={<SearchOutlined />}
        onChange={handleOnChangeFilter}
        placeholder="Buscar espacio, marca, nombre"
      />
    </Col>

    <Col span={5}>
      <CustomSelect
        allowClear
        loading={loading}
        disabled={loading}
        value={availableCode}
        onChange={handleOnChangeAvailable}
        options={ownerPlacesToSelect ?? []}
        placeholder="Disponibilidad"
        className="custom-select-1"
      />
    </Col>

    <Col span={5}>
      <CustomSelect
        allowClear
        value={ownerTypeId}
        loading={loading}
        disabled={loading}
        onChange={handleOnChangeTypes}
        options={typesOwnerToSelect ?? []}
        placeholder="Tipo de usuario"
        className="custom-select-1"
      />
    </Col>

    <Col span={5}>
      <Button
        type="text"
        shape="round"
        htmlType="button"
        disabled={loading}
        onClick={handleOnClickResetFilters}
      >
        <Row gutter={8} align="middle">
          <Col>
            <Image src={reloadIcon} alt="reload-icon" />
          </Col>

          <Col>
            <span>Reiniciar filtros</span>
          </Col>
        </Row>
      </Button>
    </Col>

    <Col span={4}>
      <Row justify="end">
        <Button
          type="link"
          htmlType="button"
          icon={<ExcelIcon />}
          loading={loadingExcel}
          disabled={loadingExcel}
          onClick={getExcelReport}
          style={{ border: 'none', boxShadow: 'none', background: 'transparent' }}
        />
      </Row>
    </Col>
  </Row>
}

export default FiltersUsersTable
