
const BuildingIcon = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="32" cy="32" r="31.5" fill="white" stroke="var(--primary)" />
        <rect x="12" y="11.2002" width="40" height="40" fill="white" fillOpacity="0.01" />
        <mask id="mask0_7801_12218" style={{ maskType:'luminance' }} maskUnits="userSpaceOnUse" x="12" y="11" width="40" height="41">
            <rect x="12" y="11.2002" width="40" height="40" fill="white" />
        </mask>
        <g mask="url(#mask0_7801_12218)">
            <path fillRule="evenodd" clipRule="evenodd" d="M21.5992 14.0908V33.6002H19.1992V29.6002H17.5992V33.6002H15.1992V34.4002V48.0002H28.7992H39.9992V20.2236L37.5992 19.4236V14.4002H35.9992V18.8893L34.3992 18.3564V14.4002H32.7992V17.8236L21.5992 14.0908V14.0908ZM23.1992 16.3111L38.3992 21.3768V46.4002H34.3992V43.8846C35.7718 43.5247 36.7992 42.2804 36.7992 40.8002C36.7992 39.0424 35.3571 37.6002 33.5992 37.6002C31.8414 37.6002 30.3992 39.0424 30.3992 40.8002C30.3992 42.2804 31.4266 43.5247 32.7992 43.8846V46.4002H28.7992V33.6002H23.1992V16.3111V16.3111ZM24.7992 20.8002V22.4002H29.5992V20.8002H24.7992V20.8002ZM41.5992 23.2002V24.8002H47.1992V46.4002H41.5992V48.0002H48.7992V23.2002H41.5992V23.2002ZM24.7992 24.0002V25.6002H36.7992V24.0002H24.7992V24.0002ZM41.5992 26.4002V28.8002H45.5992V26.4002H41.5992V26.4002ZM24.7992 27.2002V28.8002H31.1992V27.2002H24.7992V27.2002ZM32.7992 27.2002V28.8002H36.7992V27.2002H32.7992V27.2002ZM24.7992 30.4002V32.0002H36.7992V30.4002H24.7992V30.4002ZM41.5992 30.4002V32.8002H45.5992V30.4002H41.5992V30.4002ZM30.3992 33.6002V35.2002H36.7992V33.6002H30.3992V33.6002ZM41.5992 34.4002V36.8002H45.5992V34.4002H41.5992V34.4002ZM16.7992 35.2002H27.1992V46.4002H16.7992V35.2002V35.2002ZM18.3992 36.8002V38.4002H20.7992V36.8002H18.3992V36.8002ZM23.1992 36.8002V38.4002H25.5992V36.8002H23.1992V36.8002ZM41.5992 38.4002V40.8002H45.5992V38.4002H41.5992V38.4002ZM33.5992 39.2002C34.4924 39.2002 35.1992 39.9071 35.1992 40.8002C35.1992 41.6933 34.4924 42.4002 33.5992 42.4002C32.7061 42.4002 31.9992 41.6933 31.9992 40.8002C31.9992 39.9071 32.7061 39.2002 33.5992 39.2002V39.2002ZM18.3992 40.0002V41.6002H20.7992V40.0002H18.3992V40.0002ZM23.1992 40.0002V41.6002H25.5992V40.0002H23.1992V40.0002ZM41.5992 42.4002V44.8002H45.5992V42.4002H41.5992V42.4002ZM18.3992 43.2002V44.8002H20.7992V43.2002H18.3992V43.2002ZM23.1992 43.2002V44.8002H25.5992V43.2002H23.1992V43.2002Z" fill="var(--primary)" />
        </g>
    </svg>


)

export default BuildingIcon
