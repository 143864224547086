export const daysMock = [
    { day: 'L', fullDay: 'Lunes', week_day: 1, week_day_end: null, time_begin: '', time_end: '', status: 'none', previousStatus: 'none', notMap: false },
    { day: 'M', fullDay: 'Martes', week_day: 2, week_day_end: null, time_begin: '', time_end: '', status: 'none', previousStatus: 'none', notMap: false },
    { day: 'X', fullDay: 'Miércoles', week_day: 3, week_day_end: null, time_begin: '', time_end: '', status: 'none', previousStatus: 'none', notMap: false },
    { day: 'J', fullDay: 'Jueves', week_day: 4, week_day_end: null, time_begin: '', time_end: '', status: 'none', previousStatus: 'none', notMap: false },
    { day: 'V', fullDay: 'Viernes', week_day: 5, week_day_end: null, time_begin: '', time_end: '', status: 'none', previousStatus: 'none', notMap: false },
    { day: 'S', fullDay: 'Sábado', week_day: 6, week_day_end: null, time_begin: '', time_end: '', status: 'none', previousStatus: 'none', notMap: false },
    { day: 'D', fullDay: 'Domingo', week_day: 0, week_day_end: null, time_begin: '', time_end: '', status: 'none', previousStatus: 'none', notMap: false },
    { day: 'N', fullDay: 'TEST', week_day: 0, week_day_end: null, time_begin: '', time_end: '', status: 'none', previousStatus: 'none', notMap: true },
];

export const statusDay = {
    SAVED: 'saved',
    NONE: 'none',
    UNSAVED: 'unsaved',
    SELECTED: 'selected',
}

export const weekDays = [
    { day: 'D', fullDay: 'Domingo' },
    { day: 'L', fullDay: 'Lunes' },
    { day: 'M', fullDay: 'Martes' },
    { day: 'X', fullDay: 'Miércoles' },
    { day: 'J', fullDay: 'Jueves' },
    { day: 'V', fullDay: 'Viernes' },
    { day: 'S', fullDay: 'Sábado' },
]