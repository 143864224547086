import Library from "./components/Library"
import { LibraryContextProvider } from "./context/LibraryContext"

const LibraryPage = () => {


    return (
        <LibraryContextProvider>
            <Library />
        </LibraryContextProvider>
    )
}

export default LibraryPage