import { Col, Row } from "antd"
import { useEffect } from "react"
import { useAppSelector } from "../../../../../hooks/store"
import UsersTickets from "../../../../components/widgets/components/tickets/UsersTickets"
import ConversationList from "../../../../components/widgets/components/chats/ConversationList"
import TasksStatistics from "../../../../components/widgets/components/employeeTasks/TasksStatistics"
import { useActivityMonitoring } from "../../../checklist/activityMonitoring/hooks/useActivityMonotoring"
import TodayAccessProviders from "../../../../components/widgets/components/accessProviders/TodayAccessProviders"

const MallStatisticsBody = ({
  activeFeatures,
  loading,
  metadata,
  conversationsList,
  getAllConversations,
}) => {
  const { currentRol } = useAppSelector(state => state.auth)
  const { dailyTasks, dateDaily, totalDailyPercent, getDailyTaskService, addZeroToNumber, handleChangeDateDailyTask } = useActivityMonitoring()

  useEffect(() => {
    if (currentRol) {
      getAllData()
    }
  }, [])

  const getAllData = async () => {
    await getDailyTaskService({ formData: { filter_by: dateDaily } })
  }

  return (
    <Row gutter={16}>
      <Col md={24} lg={14}>
        <Row gutter={[16, 24]}>
          <Col span={14}>
            <TasksStatistics
              dailyTasks={dailyTasks}
              dateDaily={dateDaily}
              totalDailyPercent={totalDailyPercent}
              addZeroToNumber={addZeroToNumber}
              handleChangeDateDailyTask={handleChangeDateDailyTask}
            />
          </Col>

          <Col span={10}>
            <UsersTickets />

            <TodayAccessProviders />
          </Col>
        </Row>
      </Col>

      <Col md={24} lg={10}>
        <ConversationList
          loading={loading}
          metadata={metadata}
          getAllConversations={getAllConversations}
          conversationsList={conversationsList}
        />
      </Col>
    </Row>
  )
}

export default MallStatisticsBody