import axios from "axios"
import { verifyBodyResponse } from "../../../../../helpers/verifyBodyResponse"
import { API_URL } from "../../../../../../config"

const getOrnamentChecklistCategories = async ({
  checklistId,
  idToken,
  limit,
  skip
}) => {
  try {
    const isLimit = limit ? `&limit=${limit}` : ''
    const isSkip = skip ? `&skip=${skip}` : ''

    const response = await axios.get(`${API_URL}checklist/type/ornament/instance/category?checklist_instance_id=${checklistId}${isLimit}${isSkip}`,
      { headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000 }
    )

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getOrnamentChecklistCategories