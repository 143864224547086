import { useState, useEffect } from "react"
import { useAppSelector } from "../../../../hooks/store"
import verifyResponse from "../../../helpers/verifyResponse"
import getCheckListItems from "../services/getCheckListItems"
import useCurrentLocation from "../../../hooks/useCurrentLocation"
import createItemCheckList from "../services/createItemCheckList"
import updateTaskCheckList from "../services/updateTaskCheckList"
import deleteItem from "../services/deleteItem"

const useCheckList = () => {
  const [loading, setLoading] = useState(false)
  const [checkItems, setCheckItems] = useState({})
  const [metadata, setMetadata] = useState({})

  const { param } = useCurrentLocation()
  const { roles, idToken, currentRol } = useAppSelector((state) => state.auth)

  useEffect(() => {
    if (param.checklistId) {
      getAllCheckListItems(param.checklistId)
    }
  }, [])

  const getAllCheckListItems = async (checklist_id, checklist_category_id, skip) => {
    setLoading(true)
    const response = await getCheckListItems({ checklist_category_id, checklist_id, skip, company_id: currentRol, idToken })
    
    if (response.success) {
      setCheckItems(response.data.data)
      setMetadata(response.data.metadata)

      setLoading(false)

      return response.success
    }
    
    setLoading(false)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  const onSaveItemChecklist = async (formData) => {
    const functionToExecute = formData?.checklist_item_id ? updateTaskCheckList : createItemCheckList
    const messageToShow = formData?.checklist_item_id ? 'editada' : 'creada'

    const response = await functionToExecute(idToken, { ...formData, company_id: currentRol })

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: `Tarea ${messageToShow} con éxito` })
  }

  const onDeleteItemChecklist = async (formData) => {
    const response = await deleteItem(idToken, { ...formData, company_id: currentRol })

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Tarea eliminada con éxito' })
  }

  return {
    metadata,
    loading,
    setLoading,
    checkItems,
    roles,
    onSaveItemChecklist,
    getAllCheckListItems,
    onDeleteItemChecklist,
  }
}

export default useCheckList
