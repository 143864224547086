import { Button, Col, Form, Row, Upload } from 'antd'
import CustomModal from '../../../../UI/CustomModal'
import { useContext, useState } from 'react'
import { CustomInput } from '../../../../UI/CustomInput'
import { FileOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { getAllMapFiles, onSaveMapFile } from '../../../../../store/detailContracts/thunks'
import { verifyTypeMapFile } from '../../../../helpers/customUploadHelper'
import { AdminContractsContext } from '../../context/AdminContractsContext'

const NewEditSiteMapModal = ({ form, isOpenModal, handleOpenCloseModal }) => {
  const { Item } = Form
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [isOnChangeEvent, setIsOnChangeEvent] = useState(false)
  const { fileList, setFileList, cleanFileList, mapDataSelected, cleanMapDataSelected } = useContext(AdminContractsContext)

  const config = { rules: [{ required: true, message: "Por favor llenar el campo" }] }
  const { idClickedPlace } = useAppSelector(state => state.malls)
  const onReset = () => form.resetFields()

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      onReset()
      handleOpenCloseModal()
      cleanFileList()
      // Pasar el idClickedPlace
      dispatch(await getAllMapFiles(idClickedPlace))
    }
  }

  const handleOnSubmit = async (formData) => {
    setLoading(true)

    // Cambiar a idClickedPlace
    const response = await dispatch(await onSaveMapFile({
      ...formData,
      file: isOnChangeEvent ? formData.file.file.originFileObj : mapDataSelected?.PLACE_FILE?.file,
      place_id: idClickedPlace,
      id: mapDataSelected.id
    }))

    onFinish(response)

    setLoading(false)
  }

  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <CustomModal
      destroyOnClose
      width={954}
      isOpenModal={isOpenModal}
      onCancel={() => {
        cleanMapDataSelected()
        cleanFileList()
        handleOpenCloseModal()
      }}
    >
      <Form
        form={form}
        onFinish={handleOnSubmit}
        name="newEditSiteMapForm"
        disabled={loading}
        className="standard-form-contracts"
        layout="vertical"
      >
        <Row justify="center">
          <Col span={24}>
            <h2 className='title-modal' style={{ margin: "25px 0" }}>PLANOS</h2>

            <label>TÍTULO</label>
            <Item name="name" {...config}>
              <CustomInput className='custom-input-contract' />
            </Item>

            <label>DESCRIPCIÓN DE CAMBIOS</label>
            <Item name="description" {...config}>
              <CustomInput maxLength={255} type='AREA' className='custom-textarea' />
            </Item>

            <label>ENCARGADO DE APROBACIÓN</label>
            <Item name="updated_by" {...config}>
              <CustomInput maxLength={50} className='custom-input-contract' />
            </Item>

            <label>ESPECIFICACIONES</label>
            <Item name="general_specs" {...config}>
              <CustomInput className='custom-input-contract' />
            </Item>

            <label>SUBIR PLANO</label>
            <Row justify="center" align="middle" style={{ backgroundColor: "#F2F2F2", border: "1px solid #D9D9D9", borderRadius: 5, height: 98 }}>
              <Item name="file" {...config} style={{ margin: 0, maxWidth: 184 }}>
                <Upload
                  name="file"
                  listType="text"
                  maxCount={1}
                  fileList={fileList}
                  onChange={(file) => {
                    setFileList(file.fileList)
                    setIsOnChangeEvent(true)
                  }}
                  customRequest={dummyRequest}
                  beforeUpload={verifyTypeMapFile}
                  accept=".pdf,.rar,.zip"
                >
                  <Button
                    type="text"
                    htmlType="button"
                    icon={<FileOutlined />}
                    style={{ width: 184, backgroundColor: "#FFFFFF", borderRadius: 8, border: "1px solid #ADADAD", height: 34 }}
                  >
                    <span style={{ fontWeight: 500, fontSize: 12, color: "#676767" }}>Seleccionar archivo</span>
                  </Button>
                </Upload>
              </Item>
            </Row>

            <Row justify="end">
              <Button
                shape="round"
                htmlType="submit"
                loading={loading}
                style={{ width: 250, marginTop: 25 }}
                className='btn-standard-green'
              >
                <span>GUARDAR</span>
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default NewEditSiteMapModal
