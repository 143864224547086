import { useAppSelector } from "../../../../hooks/store"
import verifyResponse from "../../../helpers/verifyResponse"
import updateReAssignTicket from "../services/updateReAssignTicket"
import { getEmployees } from "../../checklist/adminCheck/services/general/GET/getEmployees"

const useTickets = () => {
  const { idToken, currentRol } = useAppSelector(state => state.auth)

  const updateEmployeeTicket = async (formData) => {
    const response = await updateReAssignTicket(formData, idToken)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Reasignacion completada con exito' })
  }
    
  // Funcion get utilizada para filtros de busqueda
  const getEmployeesBySearch = async ({ find_by, find_value, limit, skip }) => {
    const response = await getEmployees({ find_by: find_by, find_value: find_value, limit: limit, skip: skip, company_id: currentRol, token: idToken, isToSelect: true })
    return response
  }
    
  return {
    updateEmployeeTicket,
    getEmployeesBySearch
  }
}

export default useTickets