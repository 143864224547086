import { useAppSelector } from "../../../../../hooks/store"
import verifyResponse from "../../../../../helpers/verifyResponse"
import useCurrentLocation from "../../../../hooks/useCurrentLocation"
import { getCategoriesByChecklistId, getChecklistDetail, getStatusItemsReviews } from "../services"
import { useContext } from "react"
import { HandoverChecklistContext } from "../context/HandoverChecklistContext"

const useDetailChecklist = () => {
  const { param } = useCurrentLocation()
  const { idToken, currentRol } = useAppSelector(state => state.auth)

  const {
    setLoading, setCurrentPage,
    setDetailChecklistById, setChecklistCategories, setDetailChecklistByIdToPdf,
    setChecklistStatusReviews, setDetailCheckPaginationData
  } = useContext(HandoverChecklistContext)

  const getAllData = async () => {
    setLoading(true)
    const response = await getDetailChecklistById(param.checklistTaskId)
    await getCategoriesByIdChecklist()
    await getChecklistStatusReviews()
    await getDetailChecklistByIdToPdf(param.checklistTaskId, response?.quantity)
    setLoading(false)
  }

  const getDetailChecklistById = async (checklistId, checklistCategoryId, checklistItemReview, limit, skip) => {
    const response = await getChecklistDetail(currentRol, checklistId, checklistCategoryId, checklistItemReview, limit, skip, idToken)

    if (response.success) {
      setDetailChecklistById(response.data.data)
      setDetailCheckPaginationData(response.data.metadata)
      return response.data.metadata
    }

    return verifyResponse(response.data, response.status, '')
  }

  const getDetailChecklistByIdToPdf = async (checklistId, limit, skip) => {
    const response = await getChecklistDetail(currentRol, checklistId, '', '', limit, skip, idToken)

    if (response.success) {
      setDetailChecklistByIdToPdf(response.data.data)
      return true
    }

    return verifyResponse(response.data, response.status, '')
  }  

  const getCategoriesByIdChecklist = async () => {
    const response = await getCategoriesByChecklistId(param.checklistTaskId, idToken)

    if (response.success) {
      setChecklistCategories(response.data.data)
      return
    }

    return verifyResponse(response.data, response.status, '')
  }

  const getChecklistStatusReviews = async () => {
    const response = await getStatusItemsReviews(idToken)

    if (response.success) {
      setChecklistStatusReviews(response.data.data)
      return
    }

    return verifyResponse(response.data, response.status, '')
  }

  const changePageHandler = async (currentPage, checklistCategoryId, checklistItemReview, limit) => {
    const pageValue = currentPage
    const newSkip = (pageValue * limit) - limit
    setCurrentPage(pageValue)
    await getDetailChecklistById(param.checklistTaskId, checklistCategoryId, checklistItemReview, limit, newSkip)
  }

  return {
    getAllData,
    changePageHandler,
    getDetailChecklistById,
    getChecklistStatusReviews,
    getCategoriesByIdChecklist,
    getDetailChecklistByIdToPdf,
  }
}

export default useDetailChecklist