import { Document, Font, Page, StyleSheet } from "@react-pdf/renderer"
import { RobotoRegular, RobotoBold, RobotoLight, RobotoMedium, PoppinsBold, PoppinsLight, PoppinsMedium, PoppinsRegular, PoppinsSemiBold } from "./fonts"
import Footer from "./Footer"

const CustomFilePdf = ({ maxWidth,children, size = 'A4', footer = false, multiplePages = false }) => (
  <Document style={{ maxWidth }}>
    <Page wrap={multiplePages} size={size} style={defaultBodyStyles.body}>
      {children}
      {footer && <Footer />}
    </Page>
  </Document>
)

Font.register({ family: 'Roboto', fontStyle: 'normal', fontWeight: 'normal', fonts: [{ src: RobotoRegular }, { src: RobotoBold }, { src: RobotoLight }, { src: RobotoMedium }] })
Font.register({ family: 'Poppins', fontStyle: 'normal', fontWeight: 'normal', fonts: [{ src: PoppinsLight, fontWeight: 'light' }, { src: PoppinsBold, fontWeight: 'bold' }, { src: PoppinsMedium, fontWeight: 'medium' }, { src: PoppinsRegular, fontWeight: 'normal' }, { src: PoppinsSemiBold, fontWeight: 'semibold' }] })

const defaultBodyStyles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  }
})

export default CustomFilePdf
