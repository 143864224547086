import axios from "axios"

const getStatusItemsReviews = async (idToken) => {
  try {
    const response = await axios.get('https://domo.topiaapp.com/api/checklist/instance/item/review/status',
      { headers: { Authorization: `Bearer ${idToken}` } })
    
    return {
      success: true,
      ...response
    }
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default getStatusItemsReviews