import axios from "axios"

const getDetailReserveByReserveId = async (reservationId, idToken) => {
  try {
    const response = await axios.get(`https://domo.topiaapp.com/api/reservation/detail/full?reservation_id=${reservationId}`, {
      headers: { Authorization: `Bearer ${idToken}` },
      timeout: 10000
    })

    return {
      success: true,
      ...response
    }
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        ...error.response,
      };
    }
    if (error.request) {
      return {
        success: false,
        status: error.code,
        data: {
          errors: [{ msg: error.message }],
        },
      };
    }
  }
}

export default getDetailReserveByReserveId