import { useState } from "react"
import { useAppSelector } from "../../../../../hooks/store";
import { verifyResponse } from "../../../../helpers/verifyResponse";
import { useAdminCheckContext } from "../contexts/AdminCheckContext";
import { getCategories } from "../services/general/GET/getCategories";
import CustomPopoverConfirm from "../../../../UI/CustomPopoverConfirm";
import { getColumnsFilter } from "../../../../helpers/customTableHelper";
import { updateCategory } from "../services/categories/PUT/updateCategory";
import { createCategory } from "../services/categories/POST/createCategory";
import { getTableDataHelper } from "../../../../helpers/getTableDataHelper";
import { columnsOrderedTable } from "../../../../helpers/columnsTableHelper";
import { deleteCategory } from "../services/categories/DELETE/deleteCategory";
import { Button, Popconfirm, Form as FormCreate, Form as FormEdit } from "antd"
import { ADMIN_CHECK_CODES } from "../../../../../constants/modules/adminCheck/adminCheckCodes";

// imagenes o archivos
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/trash-icon.svg';

export const useCategories = () => {
    const {
        auth, categories, limitTable, quantityTable, currentPageTable, loading, columnsCategories,
        setColumnsCategories, setLoading, setCurrentPageTable, setLastSkip, setMetaDataTable, setCategories
    } = useAdminCheckContext();

    const currentCompanyId = useAppSelector((state) => state?.auth?.currentRol)
    const { activeFeatures } = useAppSelector(state => state.roles)
    
    const [formCreate] = FormCreate.useForm();
    const [formEdit] = FormEdit.useForm();
    const [openModal, setOpenModal] = useState(false);

    const getCategoriesService = async ({ skip = 0, message }) => {
        setLoading(true);
        const res = await getCategories({ token: auth.idToken, skip, company_id: currentCompanyId, limit: 10 });
        const status = verifyResponse({
            dataResponse: res.data,
            statusResponse: res.status,
            message,
            showToast: message ? true : false,
        });
        setLoading(false);
        if (status) {
            const { data, metadata } = getTableDataHelper({ data: res?.data?.data, metadata: res?.data?.metadata })
            if (!skip) {
                setCurrentPageTable(1);
            }
            setCategories(data?.dataSource)
            setColumnsCategories(data?.columns)
            setMetaDataTable(metadata);
            setLastSkip(skip)
            return res.data.data;
        }
        throw new Error("Error");
    };

    const createCategoryService = async ({ formData }) => {
        setLoading(true);
        const res = await createCategory({ token: auth.idToken, formData });
        const status = verifyResponse({
            dataResponse: res.data,
            statusResponse: res.status,
            showToast: true,
        });
        setLoading(false);
        if (status) {
            await getCategoriesService({ skip: 0, message: 'Categoría creada' });
            return res.data.data;
        }
        throw new Error("Error");
    }

    const updateCategoryService = async ({ formData }) => {
        setLoading(true);
        const res = await updateCategory({ token: auth.idToken, formData });
        const status = verifyResponse({
            dataResponse: res.data,
            statusResponse: res.status,
            showToast: true,
        });
        setLoading(false);
        if (status) {
            await getCategoriesService({ skip: 0, message: 'Categoría actualizada' });
            return res.data.data;
        }
        throw new Error("Error");
    }

    const deleteCategoryService = async ({ formData }) => {
        setLoading(true);
        const res = await deleteCategory({ token: auth.idToken, formData });
        const status = verifyResponse({
            dataResponse: res.data,
            statusResponse: res.status,
            showToast: true,
        });
        setLoading(false);
        if (status) {
            await getCategoriesService({ skip: 0, message: 'Categoría eliminada' });
            return res.data.data;
        }
        throw new Error("Error");
    }

    /*****Para la tabla de categorías***/

    const handleChangeTable = async ({ pagination, filters, sorter }) => {
        const { current } = pagination;
        const pageValue = current || currentPageTable;
        const newSkip = pageValue * limitTable - limitTable;
        setCurrentPageTable(pageValue);
        await getCategoriesService({ skip: newSkip });
    }

    const onCreateCategory = async (formData) => {
        const finalFormData = { company_id: currentCompanyId, ...formData }
        await createCategoryService({ formData: finalFormData });
        formCreate.resetFields();
    }

    const onUpdateCategory = async (formData) => {
        const finalFormData = { company_id: currentCompanyId, ...formData }
        await updateCategoryService({ formData: finalFormData });
        setOpenModal(false);
    }

    const handleOpenEditCategoryModal = ({ record }) => {
        setOpenModal(true);
        formEdit.setFieldsValue({ category_id: record.id, name: record.name })
    }

    const handleDeleteCategory = async ({ record }) => {
        const formData = { company_id: currentCompanyId, category_id: record.id, }
        await deleteCategoryService({ formData });
    }


    /***Datos de la tabla */
    const tempColumns = [
        {
            title: 'CATEGORÍAS',
            dataIndex: 'category',
            key: 'category',
            width: '90%',
        },
        {
            dataIndex: 'actions',
            key: 'actions',
            width: '10%',
            render: (text, record) => (
                <div className="category__table__actions">
                    <CustomPopoverConfirm trigger={'hover'} placement={'left'} content={`${!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_UPDATE_CATEGORY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Editar categoría'}`}>
                        <Button
                            disabled={!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_UPDATE_CATEGORY)?.enabled}
                            className="category__table__actions-edit"
                            size="small"
                            icon={<EditIcon className="btn-svg-icon-variant2" />}
                            onClick={() => { handleOpenEditCategoryModal({ record }) }}
                        />
                    </CustomPopoverConfirm>
                    <CustomPopoverConfirm trigger={'hover'} placement={'left'} content={`${!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_DELETE_CATEGORY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Eliminar categoría'}`}>
                        <Popconfirm icon={<DeleteIcon />}
                            disabled={!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_DELETE_CATEGORY)?.enabled}
                            okButtonProps={{ className: 'category__delete-popconfirm__ok-button', shape: 'round', loading: loading }}
                            cancelButtonProps={{ className: 'category__delete-popconfirm__cancel-button', shape: 'round', disabled: loading }}
                            overlayClassName="category__delete-popconfirm"
                            placement="leftTop"
                            title={"¿Desea eliminar esta categoría?"}
                            onConfirm={() => handleDeleteCategory({ record })}
                            okText="ELIMINAR"
                            cancelText="CANCELAR">
                            <Button disabled={!activeFeatures?.find(feature => feature.feature_code === ADMIN_CHECK_CODES.ADMIN_CHECK_DELETE_CATEGORY)?.enabled} className="category__table__actions-delete" size="small" icon={<DeleteIcon />} />
                        </Popconfirm>
                    </CustomPopoverConfirm>
                </div>)
        },
    ]

    const categoryColumns = columnsCategories.length ? columnsOrderedTable({ defaultColumns: tempColumns, backendColumns: columnsCategories, titleUpperCase: true }) : tempColumns

    const columns = categoryColumns.map((column) => ({
        ...column,
        ...getColumnsFilter({ column })
    }))


    return {
        columns,
        categories,
        limitTable,
        quantityTable,
        currentPageTable,
        openModal,
        FormCreate,
        FormEdit,
        formCreate,
        formEdit,
        loading,
        activeFeatures,
        onCreateCategory,
        onUpdateCategory,
        setOpenModal,
        getCategoriesService,
        handleChangeTable
    };
}