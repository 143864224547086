import { Button } from "antd"
import { useContext } from "react"
import useRedirectTo from "../../../hooks/useRedirectTo"
import { CustomNavigate } from './../../../UI/CustomNavigate'
import { AmenitiesContext } from "../context/AmenitiesContext"
import { AMENITIES_CODES } from "../../../../constants/modules/amenities/amenitiesCodes"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"

const HeaderAmenitiesActions = ({ activeFeatures }) => {
  const { handleRedirectTo } = useRedirectTo()
  const {
    onReset,
    cleanCosts,
    cleanFileList,
    cleanRestrictions,
    cleanDetailAmenity,
    cleanValueCostTime,
    cleanSchedulesAmenity,
    cleanStandardCostValue,
    cleanMinimunValueTimeReserve,
    cleanCurrentQuantityInvitees,
    cleanCurrentRestrictionClicked,
  } = useContext(AmenitiesContext)

  return (
    <header>
      <CustomNavigate className="custom-title" title="ADMON. AMENIDADES">
        <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === AMENITIES_CODES.AMENITIES_NEW_AMENITY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Nueva amenidad'}`}>
          <Button
            disabled={!activeFeatures?.find(feature => feature.feature_code === AMENITIES_CODES.AMENITIES_NEW_AMENITY)?.enabled}
            type="text"
            shape="round"
            htmlType="button"
            className="btn-standard-green"
            style={{ width: 250 }}
            onClick={() => {
              onReset()
              cleanCosts()
              cleanFileList()
              cleanRestrictions()
              cleanDetailAmenity()
              cleanValueCostTime()
              cleanSchedulesAmenity()
              cleanStandardCostValue()
              cleanCurrentQuantityInvitees()
              cleanMinimunValueTimeReserve()
              cleanCurrentRestrictionClicked()
              handleRedirectTo('/settings/managment-amenities/nueva-amenidad')
            }}
          >
            <span>NUEVA AMENIDAD</span>
          </Button>
        </CustomPopoverConfirm>
      </CustomNavigate>
    </header>
  )
}

export default HeaderAmenitiesActions
