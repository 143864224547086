import { Button, Col, Dropdown, Menu, Row } from "antd"
import useRedirectTo from "../../../../../hooks/useRedirectTo"
import { useContext } from "react"
import { AdminChecklistContext } from "../context/AdminChecklistContext"

const MoreActions = ({
  form,
  dataChecklistAdmin,
  handleOpenCloseModalDelete,
  handleOpenCloseModalNewEdit
}) => {
  const { handleRedirectTo } = useRedirectTo()
  const { setChecklistCategoryClicked } = useContext(AdminChecklistContext)

  const ItemLabel = ({ item, index }) => (
    <div className="more-options" onClick={() => item.action(index)}>
      <img src={item.icon} alt={item.name} />
      <span>{item.name}</span>
    </div>
  )

  const items = [
    {
      name: "Eliminar",
      // disabled: featuresEnabled.fireEmployee,
      icon: "/assets/icons/basura.svg",
      action: () => {
        handleOpenCloseModalDelete()
        setChecklistCategoryClicked(dataChecklistAdmin)
      },
    },
    {
      name: "Editar",
      // disabled: featuresEnabled.updateEmployee,
      icon: "/assets/icons/editar.svg",
      action: () => {
        handleOpenCloseModalNewEdit()
        setChecklistCategoryClicked(dataChecklistAdmin)
        form.setFieldsValue({ name: dataChecklistAdmin.name })
      },
    }
  ]

  const menu = (
    <Menu
      style={{
        width: 175,
      }}
      items={items.map((item, index) => ({
        disabled: item.disabled,
        key: index,
        label: <ItemLabel item={item} index={index} />,
      }))}
    />
  );

  return (
    <Row wrap={false} justify="end" align="middle">
      <Col>
        <Button
          type="text"
          shape="round"
          htmlType="button"
          onClick={() => {
            handleRedirectTo(`/admin-check/adminTasks/${dataChecklistAdmin?.checklist_category_id}`)
          }}
          style={{ border: '1px solid #252525', width: 117, height: 36 }}
        >
          <span style={{ fontSize: 14, fontWeight: 500, color:' #252525' }}>TAREAS</span>
        </Button>
      </Col>

      <Col>
        <Dropdown trigger={["click"]} overlay={menu}>
          <img
            src="/assets/icons/more-icon.svg"
            alt="mas"
            style={{
              cursor: "pointer",
            }}
          />
        </Dropdown>
      </Col>
    </Row>
  )
}

export default MoreActions