import { useState } from "react"
import { Button, Form, Row } from "antd"
import useTickets from "../../hooks/useTickets"
import CustomModal from "../../../../UI/CustomModal"
import useTicketById from "../../hooks/useTicketById"
import { config } from "../../../../constants/constants"
import { CustomSelect } from "../../../../UI/CustomSelect"

const ReassingEmployeeModal = ({
  form,
  isOpenModal,
  handleOpenCloseModal
}) => {
  const [loading, setLoading] = useState(false)

  const { ticketById, getDetailTicket } = useTicketById()
  const { getEmployeesBySearch, updateEmployeeTicket } = useTickets()

  const onFinish = async (isSuccessful) => {
    if (isSuccessful) {
      form.resetFields()
      handleOpenCloseModal()
      await getDetailTicket()
    }
  }

  const onSubmit = async (formData) => {
    setLoading(true)

    const objToSend = {
       ...formData,
       ticket_id: ticketById?.id
    }

    const response = await updateEmployeeTicket(objToSend)

    await onFinish(response)

    setLoading(false)
  }

  return <CustomModal
    title={<h2>REASIGNAR TICKET</h2>}
    isOpenModal={isOpenModal}
    onCancel={handleOpenCloseModal}
  >
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      disabled={loading}
      name="reassignEmployeeForm"
    >
      <label style={{ fontWeight: 600, fontSize: 12, color: '#626262', marginLeft: 5 }}>
        USUARIO
      </label>
      <Form.Item
        name="employee_id"
        {...config}
      >
        <CustomSelect
          isAsync
          allowClear
          service={getEmployeesBySearch}
          placeholder="Seleccione a un empleado"
        />
      </Form.Item>

      <Row justify="center" style={{ margin: '25px 0' }}>
        <Button
          type="text"
          htmlType="submit"
          shape="round"
          style={{ width: 250 }}
          loading={loading}
          disabled={loading}
          className="btn-standard-green"
        >
          <span>GUARDAR</span>
        </Button>
      </Row>
    </Form>
  </CustomModal>
}

export default ReassingEmployeeModal