import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  statusRoad: null,
  ticketById: null,
  flags: null,
  ticketCategory: [],
  employeeTicket: [],
}

export const detailTicketByIdSlice = createSlice({
  name: "detailTicketById",
  initialState,
  reducers: {
    setTicketById: (state, { payload }) => {
      const { data } = payload;
      state.statusRoad = data?.statusRoad;
      state.ticketById = data?.ticket;
      state.flags = data?.flags;
    },
    setTicketCategory: (state, { payload }) => {
      const { data } = payload;
      state.ticketCategory = data;
    },
    setEmployee: (state, { payload }) => {
      const { data } = payload;
      state.employeeTicket = data?.dataSource;
    }
    
  },
});

export const { setTicketById, setTicketCategory, setEmployee } =
  detailTicketByIdSlice.actions;
