import { Row, Col } from 'antd'
import { CustomProgress } from './CustomProgress'
import { CustomSelect } from '../../../../../../UI/CustomSelect'
import calendarIcon from '../../../../../../../assets/icons/calendarIcon.svg'

const nameDate = {
    today: 'Hoy',
    last_week: 'La semana Pasada',
    last_month: 'El mes pasado'
}

export const SummaryInfo = ({ isToHomeData = false, handleChangeDateDailyTask, addZeroToNumber, totalDailyPercent, dailyTasks, dateDaily, marginBottom = -225 }) => {
    return (<Col className='monitoring__wrapper' span={isToHomeData ? 24 : 11}>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center', textAlign: 'center', width: '100%', marginBottom: 64 }}>
            <h3 style={{ margin: 0 }}>PORCENTAJE DE CUMPLIMIENTO</h3>
            {/* <CustomDatePicker value={moment(dateDaily)} onChange={(date) => handleChangeDateDailyTask({ date })} width={175} placeholder={'Hoy'} /> */}
            <CustomSelect showSearch={false} defaultValue={dateDaily} onChange={(value) => handleChangeDateDailyTask({ date: value })} width={175} options={[{ id: 'today', name: 'Hoy' }, { id: 'last_week', name: 'La semana Pasada' }, { id: 'last_month', name: 'El mes pasado' }]} suffixIcon={calendarIcon} />
        </Row>
        <CustomProgress marginBottom={marginBottom} strokeColor={!totalDailyPercent || isNaN(totalDailyPercent) ? '' : totalDailyPercent >= 75 ? '#42C994' : '#FBC42C'} trailColor={!totalDailyPercent || isNaN(totalDailyPercent) ? '' : totalDailyPercent >= 75 ? '#9FD8CB' : '#FEEDBE'} textColor={!totalDailyPercent || isNaN(totalDailyPercent) ? '#e1e1e1' : totalDailyPercent >= 75 ? '#16A07F' : '#FBC42C'} percent={totalDailyPercent} />
        <h2>Porcentaje de cumplimiento</h2>
        <h4>{nameDate[dateDaily]}</h4>
        <Col style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column', marginTop: 48 }}>
            <Row className='monitoring__task-progress'>
                <CustomProgress customText={addZeroToNumber(dailyTasks?.summary?.in_progress)} strokeColor={'#42C994'} textColor='#42C994' percent={100} width={64} height={64} fontSize={20} marginBottom={0} type='circle' />
                <p>Tareas en progreso</p>
            </Row>
            <Row className='monitoring__task-progress'>
                <CustomProgress width={64} height={64} fontSize={20} percent={100} customText={addZeroToNumber(dailyTasks.summary?.delayed)} marginBottom={0} strokeColor='#FF5858' textColor='#FF5858' type='circle' />
                <p>Tareas en demora</p>
            </Row>
        </Col>
    </Col>)
}