import { Button, Col, Form, Row } from "antd"
import { useContext, useEffect, useState } from "react"
import useRedirectTo from "../../../../../hooks/useRedirectTo"
import GeneralAmenityData from "./bodiesForm/GeneralAmenityData"
import ScheduleAmenityData from './bodiesForm/ScheduleAmenityData';
import GeneralSettingsData from './bodiesForm/GeneralSettingsData';
import CostsSettingsData from "./bodiesForm/CostsSettingsData"
import ReportingRestrictions from './bodiesForm/ReportingRestrictions';
import { AmenitiesContext } from "../../../context/AmenitiesContext";
import useCurrentLocation from "../../../../../hooks/useCurrentLocation";
import useAmenity from './../hooks/useAmenity';
import { useAppSelector } from "../../../../../../hooks/store";
import { dataToFormDataWithStringify } from './../../../../../../helpers/dataToFormData';

const AmenityForm = () => {
  const [loading, setLoading] = useState(false)

  const { param } = useCurrentLocation()
  const { handleRedirectTo } = useRedirectTo()
  const { onSaveAmenity } = useAmenity()
  const { currentRol } = useAppSelector(state => state.auth)

  const {
    form,
    costs,
    fileList,
    restrictions,
    detailAmenity,
    schedulesAmenity,
    currentValueRadio,
  } = useContext(AmenitiesContext)

  useEffect(() => {
    if (param.idAmenity !== 'nueva-amenidad') {
      form.setFieldsValue({
        "amenity_name": detailAmenity?.AMENITY_DATA?.amenity_name,
        "parent_id": detailAmenity?.AMENITY_DATA?.parent_id,
        "description": detailAmenity?.AMENITY_DATA?.description,
        "admin_confirmation": detailAmenity?.AMENITY_SETTING?.admin_confirmation,
        "days_available_for_reservation": detailAmenity?.AMENITY_SETTING?.days_available_for_reservation,
        "reservation_minutes": detailAmenity?.AMENITY_SETTING?.DURATION?.reservation_minutes,
        "previous_minutes": detailAmenity?.AMENITY_SETTING?.DURATION?.previous_minutes,
        "allowed_iterations": detailAmenity?.AMENITY_SETTING?.DURATION?.allowed_iterations,
        "invitee_enabled": detailAmenity?.AMENITY_SETTING?.INVITEE?.invitee_enabled,
        "maximum_invitee_allowed": detailAmenity?.AMENITY_SETTING?.INVITEE?.maximum_invitee_allowed,
        "default_cost_enabled": detailAmenity?.AMENITY_COST?.DEFAULT?.default_cost_enabled,
        "default_cost": detailAmenity?.AMENITY_COST?.DEFAULT?.default_cost,
        "duration_cost_enabled": detailAmenity?.AMENITY_COST?.DURATION?.duration_cost_enabled,
        "duration_cost": detailAmenity?.AMENITY_COST?.DURATION?.duration_cost,
        "cost_invitee_enabled": detailAmenity?.AMENITY_COST?.INVITEE?.cost_invitee_enabled,
        "cost_invitee_type_code": detailAmenity?.AMENITY_COST?.INVITEE?.cost_invitee_type_code,
        "cost_invitee_individual": detailAmenity?.AMENITY_COST?.INDIVIDUAL?.cost_invitee_individual,
      })
    }
  } ,[])

  const transformData = (formData) => {
    // Constante que determina que valor mandar o no al backend dependiendo de el radio button que esta seleccionado
    // Asi se manda la data
    let isIndividualOrRange = currentValueRadio === 1
      ? {
          INDIVIDUAL: {
            cost_invitee_individual: parseFloat(formData.cost_invitee_individual)
          },
        }
      : {
          RANGE: costs.map((cost) => ({
            range_start: parseInt(cost.range_start),
            range_end: parseInt(cost.range_end),
            range_cost: parseFloat(cost.range_cost),
          }))
        }

    const isInviteeEnabled = formData.invitee_enabled && {
        maximum_invitee_allowed: parseInt(formData.maximum_invitee_allowed),
      }
    
    const isCostEnabled = formData.default_cost_enabled && {
        default_cost: parseFloat(formData.default_cost)
      }

    const isDurationCost = formData.duration_cost_enabled && {
        duration_cost: parseFloat(formData.duration_cost),
      }

    if (!isIndividualOrRange) isIndividualOrRange = {}
    
    // Se filtran solo los archivos que ya estaban adjuntados
    const newFileList = fileList?.filter((file) => file.url)
    // Se filtran solamente los archivos nuevo
    const newsFileList = fileList?.filter((file) => !(file.url)).map((file) => file.originFileObj)

    const objToSend = {
      company_id: currentRol,
      AMENITY_DATA: {
        FILE: {
          cover: newFileList?.map((file) => file.url)
        },
        amenity_name: formData.amenity_name,
        description: formData.description,
        parent_id: formData.parent_id,
      },
      AMENITY_SCHEDULE: schedulesAmenity,
      AMENITY_SETTING: {
        DURATION: {
          allowed_iterations: parseInt(formData.allowed_iterations),
          previous_minutes: parseInt(formData.previous_minutes),
          reservation_minutes: parseInt(formData.reservation_minutes)
        },
        INVITEE: {
          invitee_enabled: formData.invitee_enabled ? formData.invitee_enabled : false,
          ...isInviteeEnabled
        },
        admin_confirmation: formData.admin_confirmation ? formData.admin_confirmation : false,
        days_available_for_reservation: parseInt(formData.days_available_for_reservation)
      },
      AMENITY_COST: {
        DEFAULT: {
          default_cost_enabled: formData.default_cost_enabled ? formData.default_cost_enabled : false,
          ...isCostEnabled
        },
        DURATION: {
          duration_cost_enabled: formData.duration_cost_enabled ? formData.duration_cost_enabled : false,
          ...isDurationCost
        },
        INVITEE: {
          cost_invitee_enabled: formData.cost_invitee_enabled ? formData.cost_invitee_enabled : false,
          cost_invitee_type_code: currentValueRadio === 1 ? 'individual' : 'range',
        },
        ...isIndividualOrRange
      },
      AMENITY_CONSTRAINT: restrictions.map((restriction) => ({ title: restriction.title, description: restriction.description }))
    }

    const dataFormData = dataToFormDataWithStringify(objToSend)

    if (param.idAmenity !== 'nueva-amenidad') {
      dataFormData.append("place_id", param.idAmenity)
    }

    // Agrenando el fileList de nuevos archivos agregados al formData
    newsFileList.map((file) => dataFormData.append('cover', file))

    return dataFormData
  }

  const onFinish = (isSuccessFull) => {
    if (isSuccessFull) {
      handleRedirectTo('/settings/managment-amenities')
    }
  }
  
  const onSubmit = async (formData) => {
    setLoading(true)

    const finalData = transformData(formData)
    // Falta tranformar a formData
    const response = await onSaveAmenity(finalData)

    onFinish(response)

    setLoading(false)
  }

  return (
    <Form
      form={form}
      layout="vertical"
      name="newEditAmenityForm"
      onFinish={onSubmit}
      disabled={loading}
    >
      <GeneralAmenityData />

      <ScheduleAmenityData />

      <GeneralSettingsData />

      <CostsSettingsData />

      <ReportingRestrictions />

      <Row justify="end" style={{ marginTop: 25, paddingBottom: 25 }} gutter={16}>
        <Col>
          <Button
            type="text"
            shape="round"
            htmlType="button"
            className="btn-standard-white"
            style={{ width: 179 }}
            onClick={() => handleRedirectTo(-1)}
          >
            <span>REGRESAR</span>
          </Button>
        </Col>

        <Col>
          <Button
            type="text"
            shape="round"
            loading={loading}
            htmlType="submit"
            className="btn-standard-green"
          >
            <span>SIGUIENTE</span>
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default AmenityForm