export const ACTIVE_MAINTENANCE_CODES = {
    ACTIVE_MAINTENANCE_MODULE: 'FEAT-405',
    ACTIVE_MAINTENANCE_NEW_ACTIVE: 'FEAT-406',
    ACTIVE_MAINTENANCE_HISTORY_MAINTENANCE: 'FEAT-407',
    ACTIVE_MAINTENANCE_DETAIL_REPORT: 'FEAT-408',
    ACTIVE_MAINTENANCE_NEW_REPORT: 'FEAT-409',
    ACTIVE_MAINTENANCE_MANAGEMENT_PROVIDER: 'FEAT-410',
    ACTIVE_MAINTENANCE_NEW_PROVIDER: 'FEAT-411',
    ACTIVE_MAINTENANCE_DELETE_PROVIDER: 'FEAT-412',
    ACTIVE_MAINTENNACE_DELETE_ACTIVE: 'FEAT-472',
    ACTIVE_MAINTENANCE_NEW_MULTISELECT_REPORT: 'FEAT-475',
}

export const ACTIVE_MAINTENANCE_CODES_NAMES = {
    ACTIVE_MAINTENANCE_MODULE: 'ACTIVE_MAINTENANCE_MODULE',
    ACTIVE_MAINTENANCE_NEW_ACTIVE: 'ACTIVE_MAINTENANCE_NEW_ACTIVE',
    ACTIVE_MAINTENANCE_HISTORY_MAINTENANCE: 'ACTIVE_MAINTENANCE_HISTORY_MAINTENANCE',
    ACTIVE_MAINTENANCE_DETAIL_REPORT: 'ACTIVE_MAINTENANCE_DETAIL_REPORT',
    ACTIVE_MAINTENANCE_NEW_REPORT: 'ACTIVE_MAINTENANCE_NEW_REPORT',
    ACTIVE_MAINTENANCE_MANAGEMENT_PROVIDER: 'ACTIVE_MAINTENANCE_MANAGEMENT_PROVIDER',
    ACTIVE_MAINTENANCE_NEW_PROVIDER: 'ACTIVE_MAINTENANCE_NEW_PROVIDER',
    ACTIVE_MAINTENANCE_DELETE_PROVIDER: 'ACTIVE_MAINTENANCE_DELETE_PROVIDER',
    ACTIVE_MAINTENNACE_DELETE_ACTIVE: 'ACTIVE_MAINTENNACE_DELETE_ACTIVE',
    ACTIVE_MAINTENANCE_NEW_MULTISELECT_REPORT: 'ACTIVE_MAINTENANCE_NEW_MULTISELECT_REPORT',
}