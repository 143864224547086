import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./store";
import { changeAuthenticationStatusThunk } from "../store/auth";

// Definimos la media query para que al momento que el body coincida, salte y notifique
const desktopMediaQuery = window.matchMedia('(min-width: 768px')

const useCheckSizeWindow = () => {
  const dispatch = useAppDispatch()
  const { status } = useAppSelector(state => state.auth)
  const [isMobileView, setIsMobileView] = useState(false)

  useEffect(() => {
    const checkResize = (event) => {
      // El evento match media salta cuando coincide con la media query definida anterioremente
      setIsMobileView(!(event.matches))
      if (!(event.matches) && (status === 'not-authenticated')) {
        dispatch(changeAuthenticationStatusThunk('checking-login'))
      }
    }

    // El evento matchMedia y change tiene mejor rendimiento que resize,
    // ya que resize se ejecuta continuamente al redimensionar
    desktopMediaQuery.addEventListener('change', checkResize)

    return () => {
      desktopMediaQuery.removeEventListener('change', checkResize)
    }
  }, [])

  return { isMobileView }
}

export default useCheckSizeWindow
