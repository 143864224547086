import { topiaTemplate } from "./topiaTemplate";
import { defaultTemplate } from "./defaultTemplate";
import { betoTemplate } from "./betoTemplate";

//Se exporta una función que retorna un objeto al cual pasarle una data donde la clave de estos objetos son el codigo del tema que se espera obtener en el servicio
//y la data hace referencia a los cambios esperados a realizar dentro del template, sobre todo con las imágenes
export const loginTemplates = (data) => ({
    topia: topiaTemplate(data),
    default: defaultTemplate(data),
    beto: betoTemplate(data)
})