import { Button, Row, Input, Form } from "antd";
import CustomModal from "../../../../UI/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { cancelTicketThunk } from "../../../../../store/detailTicket/thunk";
import { useState } from "react";

const CancelTicketModal = ({ onCancel, isOpenModal }) => {
  const { ticketById } = useSelector((state) => state.detailTicketById);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { Item } = Form;

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(cancelTicketThunk(ticketById.id, data.message));
    form.resetFields();
    onCancel();
    setLoading(false);
    onCancel();
  };

  return (
    <CustomModal onCancel={onCancel} isOpenModal={isOpenModal}>
      <div style={{ borderRadius: "10px", padding: "2rem" }}>
        <Form
          initialValues={initialData}
          onFinish={onSubmit}
          disabled={loading}
          form={form}
        >
          <div>
            <p style={{ fontSize: "13px" }}>
              Motivo de la cancelacion del ticket
            </p>
            <Item name="message">
              <Input.TextArea placeholder="Limpieza de pisos" />
            </Item>
          </div>
          <Row justify="center" style={{ marginTop: "1rem" }}>
            <Button
              loading={loading}
              htmlType="submit"
              shape="round"
              danger
              style={{ background: "#FC4850", color: "white" }}
            >
              CANCELAR TICKET
            </Button>
          </Row>
        </Form>
      </div>
    </CustomModal>
  );
};

const initialData = {
  ticket_id: "",
  employee_id: "",
  message: "",
};

export default CancelTicketModal;
