import { axiosCreate } from "../../../../../config/index";
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse";

const reasonVisitPath = 'event/company/reason'

export const deleteReasonVisit = async ({
    token,
    event_reason_id
}) => {
    try {
        const params = {
            event_reason_id
        };

        const response = await axiosCreate.delete(`${reasonVisitPath}`, {
            timeout: 10000, headers: {
                Authorization: `Bearer ${token}`,
            },
            data: params
        });

        return verifyBodyResponse(response, true);
    } catch (error) {
        return verifyBodyResponse(error, false);
    }
}