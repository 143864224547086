import { createSocket } from "./socket"
import { CustomSpin } from "../CustomSpin"
import { useEffect, useState } from "react"
import useChatsList from "./hooks/useChatsList"
import { Col, Image, Pagination, Row, Typography } from "antd"
import { useAppDispatch, useAppSelector } from "../../../hooks/store"
import { formatUTCDateInTimeZone } from "../../helpers/customDateHelpers"
import { setCurrentDataChatSelected } from "../../../store/home/homeSlice"

// Imagenes o archivos
import userImage from '../../../assets/icons_png/avatar_user.png'

const { Text } = Typography;

// Aqui la prop setCurrentBody es necesaria para mostrar 
const CustomConversationsList = ({
  urlSocket,
  labelCurrentBody = 'chat',
  setCurrentBody,
  isToUpdateList = false, // si la prop es true por ley se deben de pasar las demas props siguientes
  getConversations, // Prop necesario con isToUpdateList
  isLoading, // Prop necesario con isToUpdateList
  isMetadata, // Prop necesario con isToUpdateList
  conversations // Prop necesario con isToUpdateList
}) => {
  const dispatch = useAppDispatch()
  const [socketRef, setSocketRef] = useState({})
  const { profileId } = useAppSelector(state => state.auth)
  const { loading, metadata, conversationsList, setConversationsList, getAllConversations } = useChatsList()

  // Prepare data en istoUpdateList True
  const functionToExecute = isToUpdateList ? getConversations : getAllConversations
  const currentLoading = isToUpdateList ? isLoading : loading
  const currentMetadata = isToUpdateList ? isMetadata : metadata
  const currentConversations = isToUpdateList ? conversations : conversationsList

  // Realizo el cambio de objeto cuando encuentro el mismo id que se actualizo
  const newConversationList = currentConversations

  const handleOnClickChat = (conversationData) => {
    dispatch(setCurrentDataChatSelected(conversationData))
    // Cambiando el body a un chat en especifico
    setCurrentBody(labelCurrentBody)
    // Notificando al socket que los mensajes de la conversacion fueron leidos al entrar al chat
    socketRef.emit("conversation:seen", conversationData.id)
  }

  // Funcion que maneja la paginacion del chat
  const handleOnChangePagination = async (currentPage) => {
    let skip = (currentPage * 10) - 10
    if (currentPage === 1) {
      skip = 0
    }

    await functionToExecute(skip)
  }

  // Socket connection
  useEffect(() => {
    if (!(profileId)) return

    const socket = createSocket(profileId, urlSocket)
    socket.connect()

    if (socket.connect) setSocketRef(socket);

    socket.on('conversation:message:notify', (conversation) => {
      if (conversation) {
        setConversationsList(prev => {
          const newData = [...prev]?.map((savedConversation) => {
            if (savedConversation.id === conversation.id) return ({ ...conversation, senderProfile: { ...conversation.senderProfile, profile_places: savedConversation?.senderProfile?.profile_places }})
            return {
              ...savedConversation
            }
          })
          return newData
        })
      }
    })

    functionToExecute()

    return () => {
      socket.off('conversation:message:notify')
      socket.disconnect()
    }
  }, [])

  // Definiremos el body inicial para evitar que la paginacion se esconda 
  let body = newConversationList?.map((conversationData) => <CoversationRow key={conversationData.id} conversationData={conversationData} handleOnClickChat={handleOnClickChat} />)

  if (currentLoading) {
    body = <CustomSpin />
  }

  return (
    <Row>
      <Col style={{ minHeight: 672, margin: '10px 0' }} span={24}>
        {body}

        {currentMetadata?.quantity > 10 ? <Row justify="end" style={{ marginTop: 10 }}>
          <Pagination size="small" defaultCurrent={1} total={currentMetadata?.quantity} onChange={handleOnChangePagination} />
        </Row> : null}
      </Col>
    </Row>
  )
}

const CoversationRow = ({ conversationData, handleOnClickChat }) => {
  let updatedAt = (conversationData?.updated_at) ?? ''
  const newsMessages = (parseInt(conversationData?.newMessages)) ?? ''

  // Estas opciones son el formato 24/01/2024
  const customOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }

  if (updatedAt) {
    updatedAt = formatUTCDateInTimeZone({ utcDate: new Date(updatedAt), customOptions: customOptions })
  }

  return (
    <Row
      key={conversationData?.id}
      style={{ cursor: 'pointer', marginBottom: '15px' }}
      align="middle"
    >
      <Col span={3}>
        <Row justify="center">
          <Image
            alt="userImage"
            src={conversationData?.senderProfile?.PROFILE_DETAIL?.image || userImage}
            style={{ width: 56, height: 56, border: "1px solid #C9D7D8", borderRadius: '50%', objectFit: 'cover' }}
          />
        </Row>
      </Col>
      <Col
        span={17}
        className="hover-row-chats"
        onClick={() => handleOnClickChat(conversationData)}
      >
        <Row>
          <Col style={{ paddingLeft: 10 }}>
            <label style={{ fontWeight: 600, fontSize: 16 }}>
              <Text
                style={{ width: 440, color: 'var(--secondary)'  }}
                ellipsis={{
                  tooltip: (conversationData?.senderProfile?.profile_places) ?? ''
                }}
              >
                {conversationData?.senderProfile?.profile_places}
              </Text>
            </label>

            <label style={{ fontWeight: 500, fontSize: 18, color: '#000000' }}>
              <Text
                style={{ width: 440 }}
                ellipsis={{
                  tooltip: (conversationData?.senderProfile?.PROFILE_DETAIL?.firstName) ?? ''
                }}
              >
                {(conversationData?.senderProfile?.PROFILE_DETAIL?.firstName) ?? ''}
                {' '}
                {(conversationData?.senderProfile?.PROFILE_DETAIL?.lastName) ?? ''}
              </Text>
            </label>

            <p style={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: 14 }}>
              <Text
                style={{ width: 440 }}
                ellipsis={{
                  tooltip: ((conversationData?.lastMessage?.content) ? (conversationData?.lastMessage?.content) : '')
                }}
              >
                {(conversationData?.lastMessage?.content) ? (conversationData?.lastMessage?.content) : 'Escribe algo por aqui'}
              </Text>
            </p>
          </Col>
        </Row>
      </Col>
      {/* Me tengo que inscribir al evento del socket */}
      <Col span={4}>
        <Row justify="center">
          <p style={{ color: 'var(--primary)', fontFamily: 'Roboto', fontSize: 14, fontWeight: 400, margin: 0 }}>
            {updatedAt}
          </p>
        </Row>

        <Row justify="center">
          <span
            style={{
              color: '#FFFFFF',
              fontFamily: 'Roboto',
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            {newsMessages !== 0
              && (<Row justify="center" align="middle" style={{ width: 24, height: 24, backgroundColor: 'var(--primary)', borderRadius: 50, }}>
                {conversationData?.newMessages}
              </Row>)}
          </span>
        </Row>
      </Col>
    </Row>
  )
}

export default CustomConversationsList