import { useContext, useState } from "react"
import useContracts from "../../hooks/useContracts"
import CustomModal from "../../../../UI/CustomModal"
import { config } from '../../../../constants/constants'
import { CustomInput } from "../../../../UI/CustomInput"
import { CustomSelect } from "../../../../UI/CustomSelect"
import { useAppSelector } from "../../../../../hooks/store"
import { Button, Checkbox, Col, Divider, Form, Row } from "antd"
import { CustomDatePicker } from "../../../../UI/CustomDatePicker"
import { AdminContractsContext } from "../../context/AdminContractsContext"
import CustomUploadFile from "../../../../UI/CustomUploadFile/CustomUploadFile"

const NewEditContractModal = ({ form, isOpenModal, handleOpenCloseModal }) => {
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)

  const { Item } = Form

  const { onSaveContract, getAllContractsFiles } = useContracts()
  const { contractTypes } = useAppSelector(state => state.detailContracts)
  const {
    contractSelected,
    fileListContracts,
    setFileListContracts,
    isVariableRent,
    setIsVariableRent,
    setContractSelected,
  } = useContext(AdminContractsContext)

  const handleOnChangeDates = (dateBegin) => setStartDate(dateBegin)

  const handleOnChangeCheckbox = (e) => setIsVariableRent(e)

  const onFinish = async (isSuccessfull) => {
    if (isSuccessfull) {
      form.resetFields()
      setFileListContracts([])
      handleOpenCloseModal()
      setIsVariableRent([])
      setContractSelected({})
      await getAllContractsFiles()
    }
  }

  const handleOnSubmit = async (formData) => {
    setLoading(true)

    // Agregamos el id de el documento para validar si es un put o post
    const objectModified = { ...formData, place_file_id: contractSelected?.place_file_id }

    const response = await onSaveContract(objectModified)

    await onFinish(response)

    setLoading(false)
  }

  return (
    <CustomModal
      className="custom-modal"
      width={1100}
      destroyOnClose
      isOpenModal={isOpenModal}
      onCancel={() => {
        form.resetFields()
        setIsVariableRent([])
        handleOpenCloseModal()
        setContractSelected({})
        setFileListContracts([])
      }}
      title={<h2 className="title-modal" style={{ margin: 0 }}>
          {contractSelected?.place_file_id ? "EDITAR" : "CARGAR"} CONTRATO
        </h2>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="newContractModal"
        className="standard-form-contracts"
        onFinish={handleOnSubmit}
        disabled={loading}
      >
        <Row gutter={36}>
          <Col span={12}>
            <label>NOMBRE DEL DOCUMENTO</label>
            <Item name="name" {...config}>
              <CustomInput placeholder="Escribe el nombre del documento" className='custom-input-contract' />
            </Item>

            <label>TIPO DE CONTRATO</label>
            <Item name="file_sub_type_id" {...config}>
              <CustomSelect
                allowClear
                options={contractTypes}
                className="custom-select-contract"
                placeholder="Selecciona una opción"
              />
            </Item>

            <Row gutter={12}>
              <Col span={8}>
                <label>M2 DEL LOCAL O QUIOSCO</label>

                <Item name="place_m2_size" {...config}>
                  <CustomInput
                    type="NUMBER"
                    nullInputNumberText={null}
                    min={0}
                    stringMode
                    maxLength={10}
                    className="custom-input-contract"
                    precision={2}
                    step={0.01}
                    placeholder="00"
                  />
                </Item>
              </Col>

              <Col span={8}>
                <label>RENTA POR M2</label>

                <Item name="place_per_m2_rent_price">
                  <CustomInput
                    type="NUMBER"
                    nullInputNumberText={null}                    
                    min={0}
                    stringMode
                    maxLength={10}
                    className="custom-input-contract"
                    precision={2}
                    step={0.01}
                    placeholder="00"
                  />
                </Item>
              </Col>

              <Col span={8}>
                <label>MANTENIMIENTO POR M2</label>

                <Item name="place_per_m2_maintenance_price">
                  <CustomInput
                    type="NUMBER"                    
                    nullInputNumberText={null}
                    min={0}
                    stringMode
                    maxLength={10}
                    className="custom-input-contract"
                    precision={2}
                    step={0.01}
                    placeholder="00"
                  />
                </Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col span={12}>
                <label>FECHA DE INICIO</label>

                <Item name="date_begin" {...config}>
                  <CustomDatePicker
                    allowClear
                    onChange={handleOnChangeDates}
                    className="custom-date-picker-contracts"
                  />
                </Item>
              </Col>

              <Col span={12}>
                <label>FECHA DE FINALIZACIÓN</label>

                <Item name="date_end" {...config}>
                  <CustomDatePicker
                    allowClear
                    disabledDateBeforeToday
                    disabledDateDependsOnStartDate={startDate}
                    className="custom-date-picker-contracts"
                  />
                </Item>

              </Col>
            </Row>

            <Divider style={{ borderTop: "1px solid #D0D0D0" }} />

            <Row gutter={24}>
              <Col span={24}>
                <Checkbox.Group
                  value={isVariableRent}
                  onChange={handleOnChangeCheckbox}
                  options={[{ label: "RENTA VARIABLE", value: "1" }]}
                />

                {isVariableRent.length > 0 && <Item name="place_variable_rent" {...config}>
                  <CustomInput
                    type="NUMBER"
                    nullInputNumberText={null}
                    min={0}
                    prefix={'%'}
                    stringMode
                    maxLength={10}
                    className="custom-input-contract"
                    precision={2}
                    placeholder='00'
                    step={0.01}
                  />
                </Item>}
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <label>% INCREMENTO PARA RENTA</label>

                <Item name="place_rent_increase_percentage">
                  <CustomInput
                    type="NUMBER"
                    nullInputNumberText={null}
                    prefix={'%'}
                    min={0}
                    stringMode
                    maxLength={10}
                    className="custom-input-contract"
                    precision={2}
                    step={0.01}
                    placeholder="00"
                  />
                </Item>
              </Col>

              <Col span={12}>
                <label>% INCREMENTO PARA MANTENIMIENTO</label>

                <Item name="place_maintenance_increase_percentage">
                  <CustomInput
                    type="NUMBER"
                    nullInputNumberText={null}
                    prefix={'%'}
                    min={0}
                    stringMode
                    maxLength={10}
                    className="custom-input-contract"
                    precision={2}
                    step={0.01}
                    placeholder="00"
                  />
                </Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row justify="center">
              <Col span={24}>
                <Item name='file' rules={[{ validator: async (_, value) => { return fileListContracts?.length ? Promise.resolve() : Promise.reject(new Error('Debe haber un archivo o imagen a subir')) } }]}>
                  <CustomUploadFile
                    fixedWidth
                    maxCount={1}
                    width={"100%"}
                    maxSizeInBytes={30 * 1024 * 1024}
                    fileList={fileListContracts}
                    setFileList={setFileListContracts}
                    acceptFilesFormat="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document,"
                  />
                </Item>
              </Col>
            </Row>

            <Row justify="center" gutter={16}>
              <Col span={12}>
                <Button
                  shape="round"
                  htmlType="button"
                  loading={loading}
                  onClick={() => {
                    setFileListContracts([])
                    handleOpenCloseModal()
                  }}
                  style={{ width: 250, marginTop: 25 }}
                  className='btn-standard-white'
                >
                  <span>CANCELAR</span>
                </Button>
              </Col>

              <Col span={12}>
                <Button
                  shape="round"
                  htmlType="submit"
                  loading={loading}
                  style={{ width: 250, marginTop: 25 }}
                  className='btn-standard-green'
                >
                  <span>GUARDAR</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default NewEditContractModal
