import { createSlice } from '@reduxjs/toolkit'

export const rolesSlice = createSlice({
    name: 'roles',
    initialState: {
        activeModuleRole: '',
        activeFeatures: [],
    },
    reducers: {
        setCurrentFeature: (state, { payload }) => {
            state.activeFeatures = payload
        },
        setCurrentModule: (state, { payload }) => {
            state.activeModuleRole = payload
        },
        cleanModules: (state, { payload }) => {
            state.activeFeatures = []
        }
    }
})

export const { setCurrentFeature, setCurrentModule, cleanModules } = rolesSlice.actions