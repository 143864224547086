import { Button, Form, Input } from "antd";
import useEmployee from "../hooks/useEmployee";
import { useState } from "react";
import { getPhoneFormat } from "../../../../helpers/getPhoneFormat";
import CustomModal from "../../../UI/CustomModal";

const EmployeeOffBoarding = ({ visible,  onClose, employeeSelected, employeeHook, companyId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { changePageHandler, currentPage } = employeeHook
  const { incapacitateEmployee, fireEmployee, activeEmployee } = useEmployee()
  const { sectionId, id, statusId, PROFILE} = employeeSelected
  const { TextArea } = Input;
  const offBoardingTitle = sectionId === 1 ? "Incapacitar" : "Dar de baja";
  const motifTitle = sectionId === 1  ? 'INCAPACIDAD' : 'BAJA'
  const isEmployeeDisabled = statusId === 2

  const onSubmit = async(values) => {
    values.employee_id = id

    setIsLoading(true)
    onDisabledEmployees(values) 
  }

  const onDisabledEmployees = async (data) => {
    const functionToExecute = getFunctionToExecute()
    const result = await functionToExecute(data);

    onFinish(result);
  };

  const getFunctionToExecute = () => {
    if(sectionId === 1 && !isEmployeeDisabled){
      return incapacitateEmployee
    }else if( sectionId === 2 && !isEmployeeDisabled){
      return fireEmployee
    }else{
      return activeEmployee
    }
  }

  const onFinish = (resultSuccess) => {
    if(!resultSuccess) return
    onClose()
    setIsLoading(false)
    changePageHandler(currentPage, null, companyId)
  }

  return (
    <CustomModal
      title={!isEmployeeDisabled ? offBoardingTitle : 'Activar'}
      isOpenModal={visible}
      className={"offboarding-modal"}
      width={648}
      onCancel={onClose}
      destroyOnClose={true}
    >
      <Form 
       className="employee-form" 
       layout="vertical"
       onFinish={onSubmit}
       disabled={isLoading}
      >
        <Form.Item 
        name='reason' 
        label={`MOTIVO DE LA ${!isEmployeeDisabled ? motifTitle : 'ACTIVACIÓN'}:`}
        rules={[{required: true, message: 'Este campo es requerido'}]}
        >
          <TextArea rows={4} placeholder="Motivo" />
        </Form.Item>

        <div className="user-info-boarding">
          <div className="user-info-item">
            <span>NOMBRE:</span>
            <p>{PROFILE?.PROFILE_DETAIL?.firstName} {PROFILE?.PROFILE_DETAIL?.lastName}</p>
          </div>

          <div className="user-info-item">
            <span>TELÉFONO:</span>
            <p>+{PROFILE?.phoneArea} {getPhoneFormat(PROFILE?.phoneNumber)}</p>
          </div>
        </div>

        <div className="btn-center btn-incapacite">
          <Button 
          shape="round"
          style={{
            backgroundColor: sectionId === 2 && '#FF424B'
          }}
          htmlType="submit"
          loading={isLoading}
          >
            {!isEmployeeDisabled ? offBoardingTitle : 'Activar'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

export default EmployeeOffBoarding;
