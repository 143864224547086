import useLibraryServices from "./useLibraryServices"
import { downloadFileFromUrl } from "../helpers/filesHelper"
import CustomTable from "../../../UI/CustomTable/CustomTable"
import { useLibraryContext } from "../context/LibraryContext"
import { useFeatureRole } from "../../../hooks/useFeatureRole"
import { libraryTableFilters } from "../helpers/filtersTableHelper"
import { librayTableColumnsPrev } from "../helpers/columnsTableHelper"
import { checkParams, getColumnsFilter } from '../../../helpers/customTableHelper'
import { LIBRARY_CODES, LIBRARY_CODES_NAMES } from "../../../../constants/modules/library/libraryCodes"

export const useLibraryTable = () => {
    const { dataTableLibrary, columnsLibrary: columnsTableLibrary, limitLibrary, quantityLibrary, currentPageLibrary, currentParamsLibrary, loading, currentDocument, showDeleteDocumentModal, showNewDocumentModal, serviceParams,
        setCurrentPageLibrary, setCurrentParamsLibrary, setDataTableLibrary, setLastSkipLibrary, setLoading, setMetaDataLibrary, setCurrentDocument, handleOpenCloseModal
    } = useLibraryContext()
    const { loadingFeatures, activeFeatures, getModuleFeatures } = useFeatureRole({ feature_codes: LIBRARY_CODES, validate_code: LIBRARY_CODES_NAMES.LIBRARY_MODULE })

    const { getAllDocumentsService } = useLibraryServices()

    const handleDeleteDocument = async ({ record }) => {
        setCurrentDocument(record)
        handleOpenCloseModal('delete')
    }

    const handleDownloadDocument = async ({ record }) => {
        setLoading(true)
        await downloadFileFromUrl({ url: record?.fileUrl, filename: record?.name, setLoading })
    }

    const columnsLibrary = librayTableColumnsPrev({ activeFeatures, loading, handleDeleteDocument, handleDownloadDocument, columns: columnsTableLibrary }).map(column => ({
        ...column,
        ...getColumnsFilter(column.dataIndex, libraryTableFilters({ currentParams: currentParamsLibrary }))
    }))

    const handleChangeTable = async ({ pagination, filter, sorter }) => {
        const sort_order = sorter.order === 'descend' ? 'desc' : sorter.order === 'ascend' ? 'asc' : undefined
        const sort_by = sorter.column?.key
        const pageValue = pagination.current || currentPageLibrary
        const newSkip = (pageValue * limitLibrary) - limitLibrary
        const actualParams = { ...currentParamsLibrary }
        const newParams = {
            find_by: actualParams.find_by ?? undefined,
            find_value: actualParams.find_value ?? undefined,
            sort_by,
            sort_order,
            skip: 0
        }

        const finalParams = { ...serviceParams, ...newParams }

        if (checkParams({ actualParams, newParams, difKey: 'skip' })) {
            setCurrentPageLibrary(pageValue)
            finalParams.skip = newSkip
        } else setCurrentPageLibrary(1)

        setLoading(true)
        const { data, metadata } = await getAllDocumentsService({ ...finalParams, setLoading })
        setCurrentParamsLibrary(newParams)
        setDataTableLibrary(data.dataSource)
        setMetaDataLibrary(metadata)
        setLastSkipLibrary(finalParams.skip)
        setLoading(false)
    }

    const tabItems = [{
        label: 'TODOS LOS USUARIOS',
        children: <CustomTable className="custom-table-variant-1 library-table" loading={loading} rowKey={'id'} columns={columnsLibrary} dataSource={dataTableLibrary} onChange={handleChangeTable}
            pagination={{
                pageSize: limitLibrary || undefined,
                total: quantityLibrary || undefined,
                defaulPageSize: limitLibrary || undefined,
                current: currentPageLibrary || undefined,
                showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
                position: ["bottomRight"]
            }} />
    }]

    return {
        handleOpenCloseModal,
        loading,
        currentDocument,
        tabItems,
        showDeleteDocumentModal,
        showNewDocumentModal,
        columnsLibrary,
        dataTableLibrary,
        loadingFeatures,
        activeFeatures,
        getModuleFeatures
    }
}

export default useLibraryTable