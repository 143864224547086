import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { getHierarchyAdminSpaceThunk, getPlacesHierarchyAdminSpaceThunk, getTypePlacesHierarchyAdminThunk } from "../../../../store/hierarchyAdmin/thunk";

const useHierarchyAdministration = () => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)

  const { idToken, currentRol } = useAppSelector((state) => state.auth)
  const { currentParentId } = useAppSelector((state) => state?.hierarchyAdminSpace)
  const { dataSource, columns, quantity, limit, skip, rest } = useAppSelector((state) => state?.hierarchyAdminSpace?.data);
    
  const getDetailHierarchyAdmin = async () => {
    setLoading(true)
    
    const response  = await dispatch( await getHierarchyAdminSpaceThunk(currentRol, idToken))
    await dispatch( await getTypePlacesHierarchyAdminThunk(idToken))
    if(response.success) await dispatch( await getPlacesHierarchyAdminSpaceThunk({companyId: currentRol, idToken:idToken, parentId:currentParentId}))        
    
    setLoading(false)
  }

  return {
    setLoading,
    loading,
    columns,
    dataSource,
    getDetailHierarchyAdmin,
    quantity, 
    limit,
    skip,
    rest
  }
}

export default useHierarchyAdministration
