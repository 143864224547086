import { Popconfirm, Popover } from "antd"

const CustomPopoverConfirm = ({
  isPropVisible = false,
  visible,
  onVisibleChange,
  popConfirm,
  className,
  children,
  content,
  trigger,
  placement,
  overlayClassName,
  destroyTooltipOnHide,
  onConfirm,
  title,
  okText,
  cancelText,
  maxWidth,
  maxWidthContainer,
  style,
  disabled,
  icon,
  okButtonProps,
  cancelButtonProps,
}) => {
  const isControlledPropVisible = isPropVisible ? {
    visible,
    onVisibleChange
  } : {}

  if (popConfirm) return (
    <Popconfirm
      icon={icon}
      disabled={disabled}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      onConfirm={onConfirm}
      overlayClassName={overlayClassName}
      title={title}
      okText={okText}
      cancelText={cancelText}
      placement={placement}
      className={className}
      overlayStyle={{ maxWidth }}
      style={{ maxWidth }}
    >
      {children}
    </Popconfirm>
  )

  return (
    <div style={{ maxWidth:maxWidthContainer }}>
      <Popover
        {...isControlledPropVisible}
        destroyTooltipOnHide={destroyTooltipOnHide}
        overlayClassName={overlayClassName}
        placement={placement}
        trigger={trigger}
        content={content}
        className={className}
        overlayStyle={{ maxWidth, ...style }}
        style={{ maxWidth, ...style }}
      >
        {children}
      </Popover>
    </div>
  )
}

export default CustomPopoverConfirm;