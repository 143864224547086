import axios from "axios";

const getTicketCategory = async (URL_DEV, companyId) => {
  try {
    const response = await axios.get(`${URL_DEV}/category?company_id=${companyId}`);
    return {
      success: true,
      ...response,
    };
  } catch (errorResponse) {
    return {
      success: false,
      ...errorResponse.response,
    };
  }
};

export default getTicketCategory;
