import { message } from 'antd'
import { useState } from 'react'
import useUser from './hooks/useUser'
import { useSelector } from 'react-redux'
import AboutUs from './components/AboutUs'
import HeaderButtons from './components/HeaderButtons'
import UsersDataTabs from './components/Users/UsersDataTabs'
import useOpenCloseModal from '../../../hooks/useOpenCloseModal'
import NewUserHolderModal from './components/Modals/NewUserHolderModal'
import UploadExcelErrorsModal from './components/Modals/UploadExcelErrorsModal'
import { CustomSpin } from '../../../UI/CustomSpin'

const NomenclaturaPage = () => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openErrorsModal, setOpenErrorsModal] = useState(false)
  const [isUploadLoading, setIsUploadLoading] = useState(false)
  const [isDataHousesLoading, setIsDataHousesLoading] = useState(false)
  const [response, setResponse] = useState({})

  const {
    loading,
    tableData,
    housesData,
    residentialData,
    getAvailablesHouses,
    getResidentialTableData,
  } = useUser()
  const { committee } = useSelector(state => state.auth)
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()

  const handleOpenCloseDrawer = () => setOpenDrawer(state => !state)
  const handleOpenCloseErrorsModal = () => setOpenErrorsModal(state => !state)

  const onFinish = async () => {
    setIsUploadLoading(true)
    message.success('El archivo se subio con exito')
    await getResidentialTableData(committee?.residential_id)
    setIsUploadLoading(false)
  }

  const handleModalData = async () => {
    setIsDataHousesLoading(true)

    handleOpenCloseModal()
    await getAvailablesHouses(committee?.residential_id)

    setIsDataHousesLoading(false)
  }

  if (loading) return <CustomSpin size="large" />

  return (
    <section className="section-wrapper">
      <HeaderButtons
        onFinish={onFinish}
        setResponse={setResponse}
        isUploadLoading={isUploadLoading}
        residentialData={residentialData}
        handleModalData={handleModalData}
        handleOpenCloseDrawer={handleOpenCloseDrawer}
        handleOpenCloseErrorsModal={handleOpenCloseErrorsModal}
      />

      <UsersDataTabs isUploadLoading={isUploadLoading} tableData={tableData} />

      <NewUserHolderModal
        modalData={housesData}
        isOpenModal={isOpenModal}
        isDataHousesLoading={isDataHousesLoading}
        handleOpenCloseModal={handleOpenCloseModal}
      />

      <UploadExcelErrorsModal response={response} isOpenModal={openErrorsModal} handleOpenCloseModal={handleOpenCloseErrorsModal} />

      <AboutUs isOpenDrawer={openDrawer} handleOpenCloseDrawer={handleOpenCloseDrawer} />
    </section>
  )
}

export default NomenclaturaPage
