
export const MANAGEMENT_CONTRACTORS_CODES = {
    MANAGEMENT_CONTRACTORS_MAP: 'FEAT-365',
    MANAGEMENT_CONTRACTORS_CONTRACT_COPIES: 'FEAT-368',
    MANAGEMENT_CONTRACTORS_FISCAL_DATA: 'FEAT-369',
    MANAGEMENT_CONTRACTORS_TICKETS_LOCAL: 'FEAT-370',
    MANAGEMENT_CONTRACTORS_MANAGEMENT_MAPS: 'FEAT-371',
    MANAGEMENT_CONTRACTORS_CONTRACTOR_CONSULTATION: 'FEAT-373',
}

export const MANAGEMENT_CONTRACTORS_CODES_NAMES = {
    MANAGEMENT_CONTRACTORS_MAP: 'MANAGEMENT_CONTRACTORS_MAP',
    MANAGEMENT_CONTRACTORS_CONTRACT_COPIES: 'MANAGEMENT_CONTRACTORS_CONTRACT_COPIES',
    MANAGEMENT_CONTRACTORS_FISCAL_DATA: 'MANAGEMENT_CONTRACTORS_FISCAL_DATA',
    MANAGEMENT_CONTRACTORS_TICKETS_LOCAL: 'MANAGEMENT_CONTRACTORS_TICKETS_LOCAL',
    MANAGEMENT_CONTRACTORS_MANAGEMENT_MAPS: 'MANAGEMENT_CONTRACTORS_MANAGEMENT_MAPS',
    MANAGEMENT_CONTRACTORS_CONTRACTOR_CONSULTATION: 'MANAGEMENT_CONTRACTORS_CONTRACTOR_CONSULTATION',
}