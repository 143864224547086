import MoreActions from "./MoreActions"
import { useContext, useEffect } from "react"
import useAdminCheckListTasks from "../hooks/useAdminCheckListTasks"
import CustomTable from "../../../../../../../UI/CustomTable/CustomTable"
import { AdminChecklistTasksContext } from "../context/AdminChecklistTasksContext"
import NewEditTaskModal from "./modals/NewEditTaskModal"
import DeleteTaskModal from "./modals/DeleteTaskModal"
import useOpenCloseModal from "../../../../../../../hooks/useOpenCloseModal"

const AdminChecklistTasksTable = () => {
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { changePageHandler, getInitialData, getTasksByCategory } = useAdminCheckListTasks()
  
  const {
    loading,
    filters,
    setFilters,
    currentPage,
    checklistTasksTable,
    metadata,
  } = useContext(AdminChecklistTasksContext)

  const { columns = [], dataSource = [] } = checklistTasksTable
  const { limit = 10, quantity = 0 } = metadata

  useEffect(() => {
    getInitialData()
  }, [])

  // Agregando columnas extras a las columnas desde el backend
  const newStyledColumns = columns.map(((column) => {
    const nameColumn = column.dataIndex === 'name'
      ? {
          ...column,
          sorter: true,
        }
      : column

    return {
      ...nameColumn,
    }
  }))

  const newColumns = [
    ...newStyledColumns,
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      align: 'end',
      render: (_, record) => <MoreActions dataChecklistAdminItem={record} handleOpenCloseModalDelete={handleOpenCloseModal} />
    }
  ]

  const handleOnChangeTable = async ({ sorter }) => {
    if (sorter.order) {
      await getTasksByCategory({
        ...filters,
        sortBy: sorter?.order ? sorter.columnKey : '',
        sortOrder: sorter?.order ? (sorter?.order === 'ascend' ? 'asc' : 'desc') : '',
      })

      setFilters(state => ({
        ...state,
        sortBy: sorter?.order ? sorter.columnKey : '',
        sortOrder: sorter?.order ? (sorter?.order === 'ascend' ? 'asc' : 'desc') : ''
      }))

      return
    }

    setFilters(state => ({
      ...state,
      sortBy: '',
      sortOrder: ''
    }))
  }

  return (
    <>
      <CustomTable
        dataSource={dataSource ?? []} 
        columns={newColumns ?? []}
        rowKey={record => record.checklist_item_id}
        className="custom-table-variant-admin-check"
        onChange={handleOnChangeTable}
        loading={loading}
        pagination={{
          total: quantity,
          pageSize: limit,
          onChange: async (current) => {
            await changePageHandler(current, limit)
          },
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} de ${total}`;
          },
          current: currentPage,
        }}
      />

      <NewEditTaskModal />

      <DeleteTaskModal isOpenModal={isOpenModal} handleOpenCloseModal={handleOpenCloseModal} />
    </>
  )
}

export default AdminChecklistTasksTable