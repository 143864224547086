import { Button, Col, Row, Upload } from 'antd'
import { InfoCircleOutlined, UserAddOutlined, CloudUploadOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import useGeneral from '../hooks/useGeneral'
import { API_URL } from '../../../../../config'
import { CustomNavigate } from '../../../../UI/CustomNavigate'
import React from 'react'

const HeaderButtons = ({
  onFinish,
  setResponse,
  isUploadLoading,
  handleModalData,
  residentialData,
  handleOpenCloseDrawer,
  handleOpenCloseErrorsModal,
}) => {
  const { isDownloadLoading, getTemplateExcel } = useGeneral()

  const props = {
    name: 'file',
    accept: '.xlsx',
    action: 'https://domo.topiaapp.com/api/residential/upload',
    headers: {
        authorization: 'authorization-text',
    },
    onChange(info) {
        if (info.file.status !== 'uploading') {}
        if (info.file.status === 'done') {
          setResponse(info.file.response)
          info.file.response.errors.length === 0
            ? onFinish()
            : handleOpenCloseErrorsModal()
        } else if (info.file.status === 'error') {
            setResponse(info.file.response)
            handleOpenCloseErrorsModal()
        }
    },
    }

  return (
    <header>
      <CustomNavigate title={
        <div style={{ display:'flex', flexDirection:'row' }}>
          <React.Fragment>
            {`USUARIOS ${(residentialData?.name) ?? 'Error'}`}
          </React.Fragment>
          <Button
            type="ghost"
            shape="circle"
            htmlType="button"
            onClick={handleOpenCloseDrawer}
            icon={<InfoCircleOutlined />}
            style={{ marginLeft: "5px" }}
          />
        </div>
      } />      

      <Row>
        <Col span={12}>
          <Button
            type="primary"
            htmlType="button"
            shape="round"
            loading={isDownloadLoading}
            disabled={isDownloadLoading}
            onClick={getTemplateExcel}
            style={{ width: '250px', border: 0, backgroundColor: '#FFFFFF', height: '42px' }}
            icon={<CloudDownloadOutlined style={{ color: "var(--primary)", fontSize: "20px" }} />}
          >
            <span style={{ fontWeight: 500, color: 'var(--primary)' }}>DESCARGAR LISTADO</span>
          </Button>

          <Upload {...props}>
            <Button
              type="primary"
              htmlType="button"
              shape="round"
              loading={isUploadLoading}
              disabled={isUploadLoading}
              className='btn-standard-green'
              icon={<CloudUploadOutlined style={{ fontSize: "20px" }} />}
              style={{ width: '250px', marginLeft: "25px" }}
            >
              <span>SUBIR ACTUALIZACIÓN</span>
            </Button>
          </Upload>
        </Col>

        <Col span={6} offset={6}>
          <Row justify="end">
            <Button
              type="primary"
              htmlType="button"
              shape="round"
              icon={<UserAddOutlined style={{ fontSize: "20px" }} />}
              onClick={handleModalData}
              className='btn-standard-green'
              style={{ width: '250px'}}
            >
              <span>NUEVO USUARIO</span>
            </Button>
          </Row>
        </Col>
      </Row>
    </header>
  )
}

export default HeaderButtons