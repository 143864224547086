import { Button, Col, Form, Row } from 'antd'
import CustomTable from '../../../../../UI/CustomTable/CustomTable'
import { CustomModal } from '../../../../../UI/CustomModal'
import { CustomInput } from '../../../../../UI/CustomInput'
import CustomPopoverConfirm from '../../../../../UI/CustomPopoverConfirm'
import { ACTIVE_MAINTENANCE_CODES } from '../../../../../../constants/modules/activeMaintenance/activeMaintenanceCodes'

export const ProvidersListModal = ({ handlePagination, handleChangeTable, isOpenModal, handleCloseModal, providers, columnsModified, loading, onSubmit, setCurrentPageTableProviders, currentPageTableProviders, limitTableProviders, quantityTableProviders, activeFeatures }) => {

  const [form] = Form.useForm()

  return (
    <CustomModal destroyOnClose={true} className='custom-modal' title={<span style={{ width: '100%', display: 'block', textAlign: 'center', fontSize: 20, color: '#232C4A' }}>LISTADO DE PROVEEDORES</span>} isOpenModal={isOpenModal} onCancel={() => { handleCloseModal(); form.resetFields() }}>
      <Form
        style={{ border: '1px solid #c4c4c4', borderRadius: 8, padding: 20, marginBottom: 8 }}
        form={form}
        onFinish={(values) => onSubmit({ values, formName: 'providers-form', form })}
        layout='vertical'>
        <Row align='middle' gutter={16}>
          <Col span={16}>
            <Form.Item rules={[{ required: true, message: 'El nombre es necesario' }]} label={<span style={{ fontWeight: 500 }}>NUEVO PROVEEDOR</span>} name={'name'}>
              <CustomInput placeholder={'Nombre de nuevo proveedor'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_NEW_PROVIDER)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Crear proveedor'}`}>
              <Button disabled={!activeFeatures?.find(feature => feature.feature_code === ACTIVE_MAINTENANCE_CODES.ACTIVE_MAINTENANCE_NEW_PROVIDER)?.enabled} htmlType='submit' style={{ maxWidth: '100%' }} loading={loading} className='btn-standard-green' shape='round'>GUARDAR</Button>
            </CustomPopoverConfirm>
          </Col>
        </Row>
      </Form>
      <CustomTable
        loading={loading}
        tableName={'providers'}
        handlePagination={handlePagination}
        onChange={handleChangeTable}
        setCurrentPage={setCurrentPageTableProviders}
        currentPage={currentPageTableProviders}
        totalItems={quantityTableProviders}
        totalPages={Math.ceil(quantityTableProviders / parseInt(limitTableProviders))}
        dataSource={providers ?? []}
        columns={columnsModified ?? []}
        pagination={{
          current: currentPageTableProviders,
          total: quantityTableProviders,
          pageSize: limitTableProviders,
          onChange: handlePagination
        }} />
    </CustomModal >
  )
}

export default ProvidersListModal