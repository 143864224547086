import { getTableDataHelper } from '../../../helpers/getTableDataHelper'
import { useUserRolesContext } from '../context/UserRolesContext'
import { getRoles } from '../services/GET/getRoles'
import { createRole } from '../services/POST/createRole'
import { duplicateRole } from '../services/POST/duplicateRole'
import { deleteRole } from '../services/PUT/deleteRole'
import { updateRoleStatus } from '../services/PUT/updateRoleStatus'
import { updateRole } from '../services/PUT/updateRole'
import verifyResponse from '../../../helpers/verifyResponse'

//Se retornan las funciones que permiten parametros adiconales para asi llamar a su respectivo servicio definido y validar si la información del endpoint es correcta o no
//ademas en aquellos servicios donde corresponda se valida si los datos son para un custom select para mapear diferente los datos o para presentarlos para una CustomTable
const useUserRolesServices = () => {

    const { auth } = useUserRolesContext();

    //
    const getRolesService = async ({ company_id = auth.currentRol, find_by, find_value, sort_by, sort_order, limit, skip, setLoading, customSelectData }) => {
        setLoading && setLoading(true);
        let res = await getRoles({ token: auth.idToken, company_id, find_by, find_value, sort_by, sort_order, limit, skip })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status })
        if (!status) {
            setLoading && setLoading(false);
            throw new Error('Error in getRolesServices')
        }
        if (customSelectData) {
            const newData = res.data.data.dataSource.map(item => ({ ...item, id: item.role_id, name: item.name }))
            res.data.data.dataSource = newData
            return res
        }
        const { data, metadata } = getTableDataHelper({ data: res.data.data, metadata: res.data.metadata })
        return { data, metadata }
    }

    //
    const createRoleService = async ({ formData, setLoading }) => {
        setLoading && setLoading(true);
        const res = await createRole({ token: auth.idToken, formData })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, message: 'El rol ha sido creado' })
        if (!status) {
            setLoading && setLoading(false);
            throw new Error('Error in createRoleService')
        }
        return res.data.data
    }

    //
    const duplicateRoleService = async ({ formData, setLoading }) => {
        setLoading && setLoading(true);
        const res = await duplicateRole({ token: auth.idToken, formData })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, message: 'El rol ha sido duplicado' })
        if (!status) {
            setLoading && setLoading(false);
            throw new Error('Error in duplicateRoleService')
        }
        return res.data.data
    }

    //
    const deleteRoleService = async ({ formData, setLoading }) => {
        setLoading && setLoading(true);
        const res = await deleteRole({ token: auth.idToken, formData })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, message:'El rol ha sido eliminado' })
        if (!status) {
            setLoading && setLoading(false);
            throw new Error('Error in deleteRoleService')
        }
        return res.data.data
    }

    //
    const updateRoleStatusService = async ({ formData, setLoading }) => {
        setLoading && setLoading(true);
        const res = await updateRoleStatus({ token: auth.idToken, formData })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, message: 'El rol ha cambiado de estado' })
        if (!status) {
            setLoading && setLoading(false);
            throw new Error('Error in updateRoleStatusService')
        }
        return res.data.data
    }

    //
    const updateRoleService = async ({ formData, setLoading }) => {
        setLoading && setLoading(true);
        const res = await updateRole({ token: auth.idToken, formData })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, message:'El rol ha sido actualizado' })
        if (!status) {
            setLoading && setLoading(false);
            throw new Error('Error in updateRoleService')
        }
        return res.data.data
    }

    return {
        getRolesService,
        createRoleService,
        duplicateRoleService,
        updateRoleStatusService,
        updateRoleService,
        deleteRoleService
    }
}

export default useUserRolesServices