import { useContext } from "react"
import { useAppSelector } from "../../../../../../hooks/store"
import { createUpdateOrnamentChecklist, createUpdateOrnamentChecklistCategory, deleteOrnamentChecklistCategory, getDefaultChecklistAsOrnament, getOrnamentChecklistCategories } from "../services"
import { AdminChecklistContext } from "../context/AdminChecklistContext"
import verifyResponse from "../../../../../helpers/verifyResponse"

const useAdminChecklist = () => {
  const { idToken, currentRol } = useAppSelector(state => state.auth)

  const {
    filters,
    setFilters,
    currentPage,
    setLoading,
    setCurrentPage,
    setMetadataCategories,
    defaultChecklistData, setDefaultChecklistData,
    setChecklistCategoriesTableData,
  } = useContext(AdminChecklistContext)

  const getAllData = async () => {
    setLoading(true)

    const checklistId = await getDefaultAdminCheck()
    await getChecklistCategories({ checklistId: checklistId })

    setLoading(false)
  }

  const getDefaultAdminCheck = async () => {
    const response = await getDefaultChecklistAsOrnament(currentRol, idToken)

    if (response.success) {
      setDefaultChecklistData(response.data.data)

      return response.data.data.checklist_id
    }
    return verifyResponse({ dataResponse: response.data , statusResponse: response.status })
  }

  const getChecklistCategories = async ({
    checklistId,
    name,
    skip,
    limit,
    sortOrder,
    sortBy,
  }) => {
    setLoading(true)
    const response = await getOrnamentChecklistCategories({
      checklistId: checklistId || defaultChecklistData.checklist_id,
      name,
      skip,
      limit,
      sortOrder,
      sortBy,
      idToken,
    })

    if (response.success) {
      setChecklistCategoriesTableData(response.data.data)
      setMetadataCategories(response.data.metadata)
      setLoading(false)

      return
    }

    setLoading(false)
    return verifyResponse({ dataResponse: response.data , statusResponse: response.status })
  }

  const onSaveOrnamentChecklistCategory = async (formData) => {
    const response = await createUpdateOrnamentChecklistCategory(formData, idToken)

    const messageToShow = formData?.checklist_category_id ? 'actualizada' : 'creada'

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: `Categoría de checklist ${messageToShow} con éxito` })
  }

  const onSaveOrnamentChecklist = async (formData) => {
    const response = await createUpdateOrnamentChecklist({ ...formData, company_id: currentRol }, idToken)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Checklist actualizado con éxito' })
  }

  const onDeleteChecklistCategory = async (formData) => {
    const response = await deleteOrnamentChecklistCategory(formData, idToken)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Categoría eliminada con éxito' })
  }

  const changePageHandler = async (currentPag, limit) => {
    const pageValue = currentPag || currentPage
    const newSkip = (pageValue * limit) - limit
    
    setCurrentPage(pageValue)

    await getChecklistCategories({ ...filters, skip: newSkip, limit })
    
    setFilters(state => ({ ...state, skip: newSkip, limit }))
  }

  return {
    getAllData,
    changePageHandler,
    getDefaultAdminCheck,
    getChecklistCategories,
    onSaveOrnamentChecklist,
    onDeleteChecklistCategory,
    onSaveOrnamentChecklistCategory,
  }
}

export default useAdminChecklist