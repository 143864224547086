import { Col, Row, Form } from "antd"
import { useEffect, useState, useContext } from "react"
import AssignTicketSection from "./AssignTicketSection"
import DetailTasksEmployee from "./DetailTasksEmployee"
import { CustomSpin } from "../../../../../../UI/CustomSpin"
import useTickets from "../../../../../Tickets/hooks/useTickets"
import useRedirectTo from "../../../../../../hooks/useRedirectTo"
import useCurrentLocation from "../../../../../../hooks/useCurrentLocation"
import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/store"
import FooterActionCreateTicketByCheckList from "./FooterActionCreateTicketByCheckList"
import { CreateTicketByCheckListContext } from "../../../context/CreateTicketByCheckListContext"
import { getAllCategoriesThunk, setInventoryThunk } from "../../../../../../../store/inventory/thunks"
import useGeneral from "../hooks/useGeneral"

const CreateTicketByChecklist = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  
  const { param } = useCurrentLocation()
  const { handleRedirectTo } = useRedirectTo()
  const { currentRol } = useAppSelector((state) => state.auth)
  
  const { onCreateTicketByChecklist } = useGeneral()
  const { suppliesSelected, equipmentSelected, sessionSelected } = useContext(CreateTicketByCheckListContext);
  
  useTickets()
  
  useEffect(() => {
    getAllDataInventory();
  }, [])

  // Prepare data to send
  const dataSupplies = suppliesSelected?.map(item => ({
    id: item?.id,
    quantity: item?.quantity
  }))
  const dataEquipments = equipmentSelected?.map(item => ({
    id: item?.id,
    quantity: item?.quantity
  }))
  const dataSessionSelected = sessionSelected?.map(item => ({
    date: item?.date,
    time_begin: item?.time[0].slice(0, 5),
    time_end: item?.time[1].slice(0, 5)

  }))

  const onFinish = (response) => {
    if (response.success) {
      handleRedirectTo(`/tickets/detail/${response.data.data.id}`)
    }
  }

  const onSubmit = async (formData) => {
    const buildFormData = {
      ...formData,
      company_id: parseInt(currentRol),
      checklist_instance_id: parseInt(param.checklistTaskId),
      equipment: dataEquipments,
      supply: dataSupplies,
      session: dataSessionSelected
    }

    const response = await onCreateTicketByChecklist(buildFormData)

    onFinish(response)
  }

  const getAllDataInventory = async () => {
    setLoading(true);
    await dispatch(setInventoryThunk());
    await dispatch(getAllCategoriesThunk());
    setLoading(false);
  };
  
  if (loading) return <CustomSpin size="large" />

  return (
    <Row>
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          className='standard-task'
          name='createTicketByChecklist'
        >
          <AssignTicketSection form={form} />

          <DetailTasksEmployee />
          
          <footer style={{ position: "fixed", bottom: 0, width: `${(window.innerWidth) - 90}px` }}>
            <FooterActionCreateTicketByCheckList />
          </footer>
        </Form>
      </Col>
    </Row>
  )
}

export default CreateTicketByChecklist
