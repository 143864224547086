import { useState } from "react"
import {
  reviewTicketThunk,
  getDetailTicketById,
} from "../../../../store/detailTicket/thunk"
import DocumentsTicket from "../UI/DocumentsTicket"
import { CustomSpin } from "../../../UI/CustomSpin"
import useCurrentLocation from "../../../hooks/useCurrentLocation"
import ContainerDetailTicket from "../Components/ContainerDetailTicket"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import DetailRequirementByChecklist from "../UI/DetailRequirementByChecklist"

const useTicketById = () => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [currentBody, setCurrentBody] = useState(false)

  const { param } = useCurrentLocation()
  const { ticketById, statusRoad, flags } = useAppSelector((state) => state.detailTicketById)

  const getDetailTicket = async () => {
    setLoading(true)
    await dispatch(getDetailTicketById(param?.idTicket))
    setLoading(false)
  }

  const onChange = async () => {
    const reviewId = ticketById.statusId === 1 && ticketById.id
    await dispatch(reviewTicketThunk(reviewId))
  }

  const handleCurrentBody = (key) => {
    setCurrentBody(key)
  }

  const verifyBody = (currentBody) => {
    if (loading) return <CustomSpin section size="large" />
    
    switch (currentBody) {
      case "documents":
        return <DocumentsTicket />;
      case "requirements":
        return <DetailRequirementByChecklist />
      default:
        return <ContainerDetailTicket steps={statusRoad} />
    }
  }

  return {
    currentBody,
    ticketById,
    verifyBody,
    onChange,
    handleCurrentBody,
    loading,
    statusRoad,
    flags,
    getDetailTicket,
  };
};

export default useTicketById;
