import moment from "moment";
import { useContext } from "react";
import TicketCategory from "./TicketCategory";
import AssignedEmployees from "./AssignedEmployees";
import InspectionsDetails from "./InspectionsDetails";
import AssignTicketModal from "./modal/AssignTicketModal";
import { Typography, Button, Col, Divider, Row } from "antd";
import useOpenCloseModal from "../../../hooks/useOpenCloseModal";
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm";
import { CountDownContext } from "../../../../context/CountDownContext";
import { useAppDispatch, useAppSelector } from "../../../../hooks/store";
import { approveInventoryThunk } from "../../../../store/detailTicket/thunk";
import { TICKETS_CODES } from "../../../../constants/modules/tickets/ticketsCodes";

const { Text } = Typography

const GeneralTicketInformation = () => {
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { handleIsOpenCloseModal } = useContext(CountDownContext)

  const handleModalOpen = () => {
    handleOpenCloseModal();
    handleIsOpenCloseModal();
  };
  const handleModalClose = () => {
    handleIsOpenCloseModal();
    handleOpenCloseModal();
  };

  const { ticketById, flags, statusRoad } = useAppSelector((state) => state.detailTicketById)

  const { activeFeatures } = useAppSelector(state => state.roles)
  const dispatch = useAppDispatch();
  
  const utcDate = ticketById?.created_at;
  const date = new Date(utcDate);

  return (
    <Col style={{ padding: "2rem" }}>
      {ticketById?.TICKET_STATUS.name === "Cancelado" && (
        <Row style={{ marginBottom: "1rem" }}>
          <Col
            style={{
              border: "1px solid #FC4850",
              width: "70%",
              borderRadius: 20,
              padding: "0.5rem 1rem",
            }}
          >
            <p style={{ color: "#FC4850" }}>
              Motivo de la cancelación del ticket:
              <span style={{ color: "#FC4850", marginLeft: "1rem" }}>
                {ticketById?.TICKET_STATUS_LOGs[0]?.created_at?.split("T")[0]} -{" "}
                {
                  ticketById?.TICKET_STATUS_LOGs[0]?.created_at
                    ?.split("T")[1]
                    ?.split(".")[0]
                }
              </span>
            </p>
            <p>{ticketById?.TICKET_STATUS_LOGs[0]?.reason}</p>
          </Col>
        </Row>
      )}
      <h3 style={{ fontSize: "14px", marginBottom: 19 }}>INFORMACIÓN GENERAL</h3>
      <Row align="middle" justify="space-around">
        {statusRoad?.[0]?.id !== 9 ? (
          <Col>
            <p style={{ fontSize: "12px", fontWeight: "bold", margin: 0 }}>Local:</p>
            <p style={{ fontSize: "26px", fontWeight: "bolder" }}>
              {ticketById?.TICKET_RESIDENTIAL?.houseName}
            </p>
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>Nomenclatura</p>
            <p style={{ fontSize: "12px" }}>
              {ticketById?.TICKET_RESIDENTIAL?.nomenclatureName}
            </p>
          </Col>
        ) : (
          <Col span={12}>
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>Título:</p>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>
              <CustomPopoverConfirm trigger={"hover"} title={"Titulo"} content={ticketById?.TICKET_DETAIL?.title}>
                <Text ellipsis>
                  {ticketById?.TICKET_DETAIL?.title}
                </Text>
              </CustomPopoverConfirm>
            </p>
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>Encargado:</p>
            <p style={{ fontSize: "12px" }}>
              {ticketById?.TICKET_EMPLOYEE?.EMPLOYEE?.name}
            </p>
          </Col>
        )}
        <Divider type="vertical" style={{ minHeight: '100px' }} />
        <Col>
          <p style={{ fontSize: "12px", fontWeight: "bold" }}>
            Fecha de creación
          </p>
          <p style={{ fontSize: "12px" }}>{moment(date).format('DD/MM/YYYY [-] hh:mm A')}</p>
          <p style={{ fontSize: "12px", fontWeight: "bold" }}>
            Última actualización
          </p>
          <p style={{ fontSize: "12px" }}>
            {moment(new Date(ticketById?.updated_at)).format('DD/MM/YYYY [-] hh:mm A') ||
              "Sin actualizacion"}
          </p>
        </Col>
      </Row>

      <TicketCategory ticketById={ticketById} />
      
      <Col style={{ marginTop: "1rem" }}>
        <Row align="middle">
          <Divider className="detail-ticket__divider" orientationMargin={0} orientation="left">
            <h4 style={{ width: 'auto' }}>DETALLE DEL TICKET: </h4>
          </Divider>
        </Row>
        <h3 style={{ color: "var(--primary)" }}>{ticketById?.TICKET_DETAIL?.title.toUpperCase()}</h3>
        <div
          style={{
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              width: "100%",
              padding: "5px 10px",
              margin: 0,
            }}
          >
            {ticketById?.TICKET_DETAIL?.description}
          </p>
        </div>

        {ticketById?.TICKET_EMPLOYEE && (
          <div>
            <AssignedEmployees />
            
            {ticketById?.TICKET_INSPECTION && (
              <InspectionsDetails />
            )}
          </div>
        )}
        {flags?.enabledButtonApproveInventory && (
          <Row justify="center">
            <div >
              <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_ASSIGN_TICKET)?.enabled ? 'No tienes permiso para realizar esta acción' : 'APROBAR INVENTARIO'}`}>
                <Button
                  onClick={() =>
                    dispatch(
                      approveInventoryThunk(
                        ticketById?.id,
                        ticketById?.TICKET_EMPLOYEE?.employeeId
                      )
                    )
                  }
                  disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_APPROVE_INSPECTION)?.enabled}
                  className="btn-standard-green"
                  shape="round"
                  type="ghost"
                >
                  <span>APROBAR INVENTARIO</span>
                </Button>
              </CustomPopoverConfirm>
            </div>
          </Row>
        )}
        {(flags?.enabledButtonAssign || flags?.enabledButtonReassign) && (
          <Row justify="center">
            <div >
              <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_ASSIGN_TICKET)?.enabled ? 'No tienes permiso para realizar esta acción' : flags?.enabledButtonReassign ? "REASIGNAR" : "ASIGNAR"}`}>
                <Button
                  disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_ASSIGN_TICKET)?.enabled}
                  onClick={handleModalOpen}
                  className="btn-standard-green"
                  shape="round"
                  type="ghost"
                >
                  <span> {flags?.enabledButtonReassign ? "REASIGNAR" : "ASIGNAR"} </span>
                </Button>
              </CustomPopoverConfirm>
            </div>
          </Row>
        )}
      </Col>
      
      <AssignTicketModal
        isOpenModal={isOpenModal}
        onCancel={handleModalClose}
      />
    </Col>
  );
};

export default GeneralTicketInformation;