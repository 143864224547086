import axios from "axios"

const createTicketCategory = async (formData) => {
  try {
    const response = await axios.post('https://domo.topiaapp.com/api/ticket/category' , formData)

    return {
      success: true,
      ...response
    }
    
  } catch (error) {
    return {
      success: false,
      ...error.response
    }
  }
}

export default createTicketCategory
