import moment from 'moment'
import { Col, Row, Button } from 'antd'
import { useEffect, useState } from 'react'
import AccessTabs from './components/AccessTabs'
import locale from 'antd/es/date-picker/locale/es_ES'
import { CustomNavigate } from '../../UI/CustomNavigate'
import { CustomDatePicker } from '../../UI/CustomDatePicker'
import { useAppDispatch } from '../../../hooks/store'
import { getAccess, getScheduleVisitors } from '../../../store/access'
import { useFeatureRole } from '../../hooks/useFeatureRole'
import { ACCESS_CODES, ACCESS_CODES_NAMES } from '../../../constants/modules/access/accessCodes'
import { CustomSpin } from '../../UI/CustomSpin'

const AccessPage = () => {
  const dateFormat = 'DD/MM/YYYY'
  const customFormat = (value) => `${value.format(dateFormat)}`

  const [loadingBt, setLoadBt] = useState(false)
  const dispatch = useAppDispatch()
  const [currentKey, setCurrentKey] = useState('1')

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const onChangeFecha = (date, dateString) => {
    setStartDate(dateString[0])
    setEndDate(dateString[1])
  }

  const buscar = async (key) => {
    let newKey = null
    if (typeof key === 'string') {
      newKey = key
      setCurrentKey(key)
    }
    if (startDate === "") {
      const fechaI = new Date(moment(new Date(), 'DD/MM/YYYY'))
      const fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

      const fechaF = new Date(moment(new Date(), 'DD/MM/YYYY'))
      const fechaFi = new Date(fechaF.setHours(24, 0, 0, 0));

      await buscarFechas(fechaIn, fechaFi, newKey)
      return
    }

    const fechaI = new Date(moment(startDate, 'DD/MM/YYYY'))
    const fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

    const fechaF = new Date(moment(endDate, 'DD/MM/YYYY'))
    const fechaFi = new Date(fechaF.setHours(24, 0, 0, 0));
    await buscarFechas(fechaIn, fechaFi, newKey)
    setLoadBt(false)
  }

  useEffect(() => {
    const today = new Date();
    const fechaInicio = new Date(today.setHours(0, 0, 0, 0))
    const fechaFinal = new Date(today.setHours(24, 0, 0, 0))

    if (activeFeatures?.find(feature => feature.feature_code === ACCESS_CODES.ACCESS_VISITANTS)?.enabled) {
      dispatch(getAccess(fechaInicio, fechaFinal))
    }
    if (activeFeatures?.find(feature => feature.feature_code === ACCESS_CODES.ACCESS_SCHEDULE_VISITANTS)?.enabled) {
      dispatch(getScheduleVisitors(fechaInicio, fechaFinal))
    }
  }, []);
  const { loadingFeatures, activeFeatures } = useFeatureRole({ feature_codes: ACCESS_CODES, validate_code: ACCESS_CODES_NAMES.ACCESS_MODULE })

  const buscarFechas = async (fechaInicio, fechaFin, key) => {
    if ((key === '1' || (!key && currentKey === '1')) && activeFeatures?.find(feature => feature.feature_code === ACCESS_CODES.ACCESS_VISITANTS)?.enabled) {
      // console.log('LOL?')
      setLoadBt(true)
      await dispatch(getAccess(fechaInicio, fechaFin));
      setLoadBt(false)
    }
    if ((key === '2' || (!key && currentKey === '2')) && activeFeatures?.find(feature => feature.feature_code === ACCESS_CODES.ACCESS_SCHEDULE_VISITANTS)?.enabled) {
      setLoadBt(true)
      await dispatch(getScheduleVisitors(fechaInicio, fechaFin));
      setLoadBt(false)
    }
  }

  if (loadingFeatures) return <CustomSpin tip="Cargando módulo" />

  return (
    <section className="section-wrapper">
      <CustomNavigate title={'ACCESO RESIDENCIAL'}>
        <Row align="middle" gutter={16}>
          <Col>
            <CustomDatePicker
              type={'RANGER'}
              defaultValue={[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]}
              format={customFormat}
              locale={locale}
              onChange={onChangeFecha}
            />
          </Col>

          <Col>
            <Button
              type="text"
              shape="round"
              onClick={buscar}
              disabled={loadingBt}
              className="btn-standard-green"
              style={{ width: 150 }}
            >
              <span>BUSCAR</span>
            </Button>
          </Col>

        </Row>
      </CustomNavigate>

      <Row>
        <Col span={24}>
          <AccessTabs activeFeatures={activeFeatures} buscarFechas={buscar} />
        </Col>
      </Row>
    </section>
  )
}

export default AccessPage