//ACCESOS RESIDENCIALES ✅
export const accessModule = {
    feature_code: 'FEAT-281',
    enabled: false,
    children: [
        {
            feature_code: 'FEAT-79',
            enabled: false,
        },
        {
            feature_code: 'FEAT-80',
            enabled: false,
        },
        {
            feature_code: 'FEAT-83',
            enabled: false,
        },
    ]
}