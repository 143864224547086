import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { getResidentialInfo } from "../../../../store/detailContracts/thunks"
import { getPlaceAnalytics, getSvgsElementsToBuilMap, getTreeSelectLevels } from "../../../../store/malls/thunks"

const useGeneral = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)

  const { committee, currentRol } = useAppSelector(state =>  state.auth)

  useEffect(() => {
    getData()
  }, [])

  const handleLoading = () => setLoading(state => !state)

  const getData = async () => {
    setLoading(true)
    await dispatch(await getResidentialInfo(committee?.residential_id))
    
    const response = await dispatch(await getTreeSelectLevels(currentRol))
    
    if (response?.data?.data?.length) {
      dispatch(await getSvgsElementsToBuilMap((response?.data?.data[0]?.value)))
      await dispatch(await getPlaceAnalytics((response.data.data[0]?.value) ?? ''))
      handleLoading()
    }
    setLoading(false)
  }

  return {
    loading,
  }
}

export default useGeneral