import { createContext, useContext, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { summaryTaskType } from "../types/summaryTaskType";
import { getCategories } from "../services/general/GET/getCategories";
import { getEmployees } from "../services/general/GET/getEmployees";
import { verifyResponse } from "../../../../helpers/verifyResponse";
import { Form } from "antd";
import useRedirectTo from "../../../../hooks/useRedirectTo";
import useCurrentLocation from "../../../../hooks/useCurrentLocation";

const AdminCheckContext = createContext({
  Form: Form,
  form: null,
  formRef: null,
  detailsRef: null,
  eventsCalendar: null,
  generalSchedule: null,
  weekdays: null,
  calendarColors: [],
  employees: [],
  categories: [],
  activeKey: [{ collapseKey: "", value: "" }],
  schedule_deleted_ids: [],
  loading: false,
  scheduleLength: null,
  formData: null,
  auth: null,
  summary: null,
  tasks: null,
  activeCard: null,
  limitTable: null,
  quantityTable: null,
  lastSkip: null,
  currentParams: null,
  currentPageTable: null,
  taskStatus: null,
  param: null,
  loadingPage: null,
  employeeIndex: [],
  eventsColor: [],
  loadingEmployees: false,
  loadingCategories: false,
  columnsTasks: [], setColumnsTasks: () => { },
  columnsCategories: [], setColumnsCategories: () => { },
  setEmployeeIndex: () => { },
  setLoadingPage: () => { },
  setTaskStatus: () => { },
  setCurrentPageTable: () => { },
  setCurrentParams: () => { },
  setLastSkip: () => { },
  setMetaDataTable: () => { },
  setActiveCard: () => { },
  setTasks: () => { },
  setSummary: () => { },
  set_Schedule_Deleted_Ids: () => { },
  setFormData: () => { },
  setLoading: () => { },
  setActiveKey: () => { },
  setCategories: () => { },
  setEmployees: () => { },
  setGeneralSchedule: () => { },
  setEventsCalendar: () => { },
  getCategoriesService: async () => { },
  getEmployeesService: async () => { },
  handleRedirectTo: (url) => { },
  onMoreSelectItems: () => { },
  onSearchItems: () => { },
});

export const AdminCheckContextProvider = ({ children }) => {

  const currentCompanyId = useSelector((state) => state?.auth?.currentRol)

  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true)
  const [form] = Form.useForm();

  //Estados para el formulario
  const [taskStatus, setTaskStatus] = useState('inProgress');
  const [eventsCalendar, setEventsCalendar] = useState([]);
  const [generalSchedule, setGeneralSchedule] = useState(true);
  const [formData, setFormData] = useState([]);
  const [schedule_deleted_ids, set_Schedule_Deleted_Ids] = useState([]);
  const [activeKey, setActiveKey] = useState([
    { collapseKey: "generalInfo", collapseName: "generalInfo", value: "1" },
  ]);
  const [employeeIndex, setEmployeeIndex] = useState([]);

  /***Estados para la tabla ***/
  const [summary, setSummary] = useState(summaryTaskType);
  const [tasks, setTasks] = useState([])
  const [columnsTasks, setColumnsTasks] = useState([])
  const [{ limit: limitTable, quantity: quantityTable }, setMetaDataTable] = useState({});
  const [lastSkip, setLastSkip] = useState(1);
  const [currentParams, setCurrentParams] = useState({})
  const [currentPageTable, setCurrentPageTable] = useState(1)
  const [activeCard, setActiveCard] = useState('inProgress');

  /***Estados para los datos de empleado y categoria***/
  const [employees, setEmployees] = useState(localStorage.getItem("employees") ? JSON.parse(localStorage.getItem("employees")) : []);
  const [categories, setCategories] = useState(localStorage.getItem("categories") ? JSON.parse(localStorage.getItem("categories")) : []);
  const [columnsCategories, setColumnsCategories] = useState([])
  const scheduleLength = useRef(0);
  const formRef = useRef();
  const weekdays = useRef([]);
  const detailsRef = useRef();
  const calendarColors = useRef(['#FDE49E', '#FFBEBE', '#A4CDFD', '#B9E8DC']);
  const eventsColor = useRef({});

  const auth = useSelector((state) => state.auth);

  /***Funciones que se pueden utilizan en varios components****/
  const { handleRedirectTo } = useRedirectTo()
  const { param } = useCurrentLocation();

  const getEmployeesService = async ({ skip = 0, last, find_by, find_value, setLoading, limit }) => {
    setLoading && setLoading(true)
    const res = await getEmployees({ token: auth.idToken, company_id: currentCompanyId, find_by, find_value, skip: last ? 0 : skip, limit: last ? last : limit });
    const status = verifyResponse({
      dataResponse: res.data,
      statusResponse: res.status,
      showToast: false,
    });

    setLoading && setLoading(false)

    if (status) {
      setEmployees(res.data.data.dataSource)
      localStorage.setItem('employees', JSON.stringify(res.data.data.dataSource))
      return res;
    }
    throw new Error("Error");
  };

  const getCategoriesService = async ({ skip = 0, last, setLoading, limit, find_by, find_value }) => {
    setLoading(true)
    const res = await getCategories({ token: auth.idToken, company_id: currentCompanyId, skip: last ? 0 : skip, limit: last ? last : limit, find_by, find_value });
    const status = verifyResponse({
      dataResponse: res.data,
      statusResponse: res.status,
      showToast: false,
    });
    setLoading(false)
    if (status) {
      return res
    }
    throw new Error("Error");
  };


  return (
    <AdminCheckContext.Provider
      value={{
        Form,
        auth,
        form,
        formRef,
        eventsCalendar,
        generalSchedule,
        weekdays,
        employees,
        categories,
        activeKey,
        loading,
        scheduleLength,
        formData,
        detailsRef,
        schedule_deleted_ids,
        summary,
        tasks,
        activeCard,
        limitTable,
        quantityTable,
        lastSkip,
        currentPageTable,
        currentParams,
        taskStatus,
        param,
        loadingPage,
        employeeIndex,
        eventsColor,
        calendarColors,
        columnsTasks, setColumnsTasks,
        columnsCategories, setColumnsCategories,
        setEmployeeIndex,
        setLoadingPage,
        setTaskStatus,
        setCurrentPageTable,
        setCurrentParams,
        setLastSkip,
        setMetaDataTable,
        setActiveCard,
        setTasks,
        setSummary,
        set_Schedule_Deleted_Ids,
        setFormData,
        setLoading,
        setActiveKey,
        setCategories,
        setEmployees,
        setEventsCalendar,
        setGeneralSchedule,
        getCategoriesService,
        getEmployeesService,
        handleRedirectTo,
      }}
    >
      {children}
    </AdminCheckContext.Provider>
  );
};

export const useAdminCheckContext = () => useContext(AdminCheckContext);
